import React from 'react';
import { SpinnerLoader, SVGIcon } from '../custom-essentials';

export function renderSubmittingDefault(submissionStatus){
    return (
        <>
            {Object.keys(submissionStatus).map(k => {
                if(['errored', 'completed'].includes(k)) return null;
                
                const ssValues = submissionStatus[k];
                const icon = !ssValues.completed ? 
                    <SpinnerLoader/> :
                    ssValues.message === 'Success!' ?
                    <SVGIcon fileName="check"/> :
                    <SVGIcon fileName="times"/>;
                const message = `${ssValues.name}: ${ssValues.message}`;

                return(
                    <div className="flex flex-row gap-x-4" key={`availability-editor-submission-message-${k}`}>
                        {icon} {message}
                    </div>
                )
            })}
        </>
    )
}