import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

import AttendanceHistory from './AttendanceHistory';
import CheckIn from './CheckIn';
import TimesheetTables from './TimesheetTables';
import { BrowserTabTitle } from '../../../../components/display';
import { Socket } from '../../../../components/ws';

const pageTitle = 'Timesheets';

const cookies = new Cookies();

function Timesheets(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [refreshCount, setRefreshCount] = useState({
        attendanceHistory: 0,
        checkIn: 0,
        timesheetTables: 0,
    });
    const [loaded, setLoaded] = useState(false);
    const [userId, setUserId] = useState(-1);

    useEffect(() => {
        const aToken = cookies.get('aToken');
        let tokenPayload = {}
        try {
            tokenPayload = jwt_decode(aToken);
        } catch(e) {
            console.error(e);
        }
        const userId = tokenPayload.userId || -1;

        if(mounted.current){
            setUserId(userId);
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            <div className="flex flex-row gap-x-4">
                <div className="card w-2/3 h-full">
                    {loaded && 
                        <AttendanceHistory
                            refreshCount={refreshCount}
                            userId={userId}
                        />
                    }
                </div>
                <div className="card w-1/3 h-full">
                    {loaded &&                             
                        <CheckIn
                            refreshCount={refreshCount}
                            userId={userId}
                            setRefreshCount={setRefreshCount}
                        />
                    }
                </div>
            </div>

            <br/>

            <div className="card">
                <TimesheetTables
                    refreshCount={refreshCount}
                    setRefreshCount={setRefreshCount}
                />
            </div>

            <Socket
                refreshData={() => {
                    setRefreshCount({
                        attendanceHistory: refreshCount.attendanceHistory + 1,
                        checkIn: refreshCount.checkIn + 1,
                        timesheetTables: refreshCount.timesheetTables + 1,
                    })
                }}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    
})(Timesheets)