import React from "react";

import { BrowserTabTitle } from './index';

export default function NotFound() {
    return (
        <div className="page-box">
            <BrowserTabTitle content="Not Found"/>
            <div className="card">
                    <h4>The page you requested could not be found :(</h4>
            </div>
        </div>
    );
  }
  