import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl } from '../../form';
import { validationSchema, getInitStudentOption, getInitCenterOption } from './helpers';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function HoldsLogBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedLog, studentOptions, centerOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this holds log entry?</h4>
                            <div className="col">
                                <div>Student: {selectedLog.studentName}</div>
                                <div>Center: {selectedLog.centerName}</div>
                                <div>Content: {selectedLog.notes}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    onClick={handleClose}
                                    disabled={formik.isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    selectedStudent: getInitStudentOption(selectedLog.student, studentOptions),
                    selectedCenter: getInitCenterOption(selectedLog.center, centerOptions),
                    notes: selectedLog.notes || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            <div className="row no-mar">
                                <div className="col-12">
                                    <h4>Student</h4>
                                    <SelectSingle
                                        id="holds-log-form-student"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        onChange={(e) => {
                                            const newStudentOption = e.target.value;
                                            const newCenterOption = getInitCenterOption(newStudentOption.object.mp_primary_center, centerOptions);

                                            const newValues = { ...formik.values };
                                            newValues.selectedStudent = newStudentOption;
                                            newValues.selectedCenter = newCenterOption;

                                            formik.setValues(newValues);
                                        }}
                                        options={studentOptions}
                                    />
                                    {formik.errors.selectedStudent && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedStudent}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="row no-mar">
                                <div className="col-12">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="holds-log-form-center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                    {formik.errors.selectedCenter && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedCenter}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="row no-mar">
                                <div className="col-12">
                                    <h4>Notes</h4>
                                    <FormikControl
                                        id="holds-log-form-notes"
                                        name="notes"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        placeholder="Include dates and reason for hold. Required. Max 300 characters"
                                        as="textarea"
                                    />
                                    {formik.errors.notes && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.notes}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => formik.handleSubmit(formik)}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(HoldsLogBodyFooter);