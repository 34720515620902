import { rgb, StandardFonts } from 'pdf-lib';
import { formatDate } from '../functions';

export async function appendLogo(pdfDoc, logoData){
    const pngImage = await pdfDoc.embedPng(logoData);
    const pngDims = pngImage.scale(0.1);

    const pages = pdfDoc.getPages();
    for(let page of pages){
        page.drawImage(pngImage, {
            x: 20,
            y: 12,
            width: pngDims.width,
            height: pngDims.height
        });
    }

    return pdfDoc;
}

export async function appendStudentName(pdfDoc, studentName, fontObj = null){
    const helveticaFont = fontObj || await pdfDoc.embedFont(StandardFonts.Helvetica);
    const textToAppend = `${studentName}, ${formatDate(new Date())}`;

    const pages = pdfDoc.getPages();
    for(let page of pages){
        const { height } = page.getSize();

        page.drawText(textToAppend, {
            x: 25,
            y: height - 25,
            font: helveticaFont,
            size: 12
        });
    }

    return pdfDoc;
}

export async function appendSquare(pdfDoc, squareColor){
    const pages = pdfDoc.getPages();
    const {r, g, b} = squareColor;
    for(let page of pages){
        const { width, height } = page.getSize();

        const rectangleWidth = 30;
        const rectangleHeight = 30;

        page.drawRectangle({
            x: width - 25 - rectangleWidth,
            y: height - 15 - rectangleHeight,
            width: rectangleWidth,
            height: rectangleHeight,
            borderColor: rgb(r/256, g/256, b/256),
            borderWidth: 1
        });
    }

    return pdfDoc;
}

export async function addFlagWarningPage(pdfDoc, flagGraphic){
    const pngImage = await pdfDoc.embedPng(flagGraphic);
    const pngDims = pngImage.scale(0.1);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const newPage = pdfDoc.addPage();
    const { width, height } = newPage.getSize();

    const textSize = 20;
    const textFirstRow = 'Heads up! This student is almost out of pages.';
    const textFirstWidth = helveticaFont.widthOfTextAtSize(textFirstRow, textSize);
    const textSecondRow = 'Please create a flag on the admin portal.';
    const textHeight = helveticaFont.heightAtSize(textSize);

    const imageWidth = pngDims.width;
    const imageHeight = pngDims.height;

    let xPos = (width / 2) - (textFirstWidth / 2) - (imageWidth / 2);
    let yPos = height / 2 + textHeight;

    newPage.drawImage(pngImage, {
        x: xPos,
        y: yPos,
        width: imageWidth,
        height: imageHeight
    });

    xPos += imageWidth + 10;
    yPos += textHeight + 14;
    newPage.drawText(textFirstRow, {
        x: xPos,
        y: yPos,
        size: textSize,
        font: helveticaFont
    });

    yPos += (-textHeight - 10);
    newPage.drawText(textSecondRow, {
        x: xPos,
        y: yPos,
        size: textSize,
        font: helveticaFont
    });

    return pdfDoc;
}