import JSZip from 'jszip';
import { PDFDocument } from 'pdf-lib';
import { downloadFile } from '../functions';

export async function splitAndZip(pdfDoc, n, name = ''){
    if(name) name += ' - ';

    const zip = new JSZip();
    let setNameNumber = 1;

    const nPages = pdfDoc.getPages().length;
    const nFiles = Math.ceil(nPages / n);
    
    for(let i = 1; i <= nFiles; i++){
        const start = (i - 1) * n;
        const end = Math.min(i * n - 1, nPages);
        const pageIndices = [];
        for(let j = start; j <= end; j++) pageIndices.push(j);

        let currentSet = await PDFDocument.create();
        const currentPages = await currentSet.copyPages(pdfDoc, pageIndices);
        for(let page of currentPages) currentSet.addPage(page);

        const pdfBytes = currentSet.save();
        zip.file(`${name}${setNameNumber++}.pdf`, pdfBytes);   
    }

    const zipFile = await zip.generateAsync({ type : "uint8array" });

    downloadFile(zipFile, `${name || 'lessons'}.zip`)
}