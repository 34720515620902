import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { getColumnsReports } from './table/getColumns';
import { Table } from '../../../../components/table';
import { SelectSingle, DateRangeSelector, checkResponses } from '../../../../components/form';
import { TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import { formatDateApi } from '../../../../components/functions';

import {
    fetchDailyReportsDaterangeCenter
} from '../../../../actions';

const defaultSorted = { dataField: 'date', order: 'asc' };

const start = new Date();
start.setDate(start.getDate() - 1);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function ReportTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [apiError, setApiError] = useState(false);
    const [drsValid, setDrsValid] = useState(true);
    const [reports, setReports] = useState([]);

    const { loading, setLoading, parentApiError, centerOptions, employeeMap, fetchDailyReportsDaterangeCenter } = props;

    const columns = useMemo(() => {
        return getColumnsReports(employeeMap);
    }, [employeeMap])

    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading || !drsValid) return;
            setLoading(true);
    
            const { startDate, endDate, selectedCenter } = formRef.current.values;
    
            const dailyReportRes = await fetchDailyReportsDaterangeCenter({
                startDate: startDate,
                endDate: endDate,
                center: selectedCenter.value
            });
            const isApiError = checkResponses(dailyReportRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newReports = dailyReportRes.data || [];

            if(mounted.current){
                setReports(newReports);
                setLoading(false);
            }
    
        })();
    }, [loading, drsValid, setLoading, formRef, fetchDailyReportsDaterangeCenter]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <h2>View Daily Reports</h2>
            <br/>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...'},
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                        <div className="flex flex-row gap-x-4 items-center">
                            <div className="grid grid-cols-1 gap-y-2">
                                <DateRangeSelector
                                    id="daily-report-table-drs"
                                    startName="startDate"
                                    endName="endDate"
                                    startLabel="Start Date"
                                    endLabel="End Date"
                                    startValue={formik.values.startDate}
                                    endValue={formik.values.endDate}
                                    defaultValid={true}
                                    onStartChange={formik.handleChange}
                                    onEndChange={formik.handleChange}
                                    onChangeValidation={setDrsValid}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                <SelectSingle
                                    id="report-table-1"
                                    value={formik.values.selectedCenter}
                                    name="selectedCenter"
                                    label="Select Center"
                                    onChange={formik.handleChange}
                                    options={centerOptions}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2">
                                <Button
                                    color="lte-mpTeal"
                                    onClick={formik.handleSubmit}
                                    style={{ height: "auto" }}
                                >
                                    Search
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <TooltipWrapper
                                    tooltipText={
                                        <div>
                                            <div>
                                                What gets exported?
                                            </div>
                                            <br/>
                                            <div>
                                                All reports that are currently filtered ({reports.length} items).
                                            </div>
                                        </div>
                                    }
                                >
                                    <CSVExport
                                        title="ACD_Report"
                                        label="Export reports to CSV"
                                        data={reports}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>

            <br/>
            
            {parentApiError || apiError ? <div className="text-mpLRed">{parentApiError || apiError}</div> :
                <Table
                    tableName="dailyReports"
                    defaultSorted={defaultSorted}
                    columns={columns}
                    data={reports}
                    sizePerPageInitial={10}
                    sizePerPageList={[10]}
                    emptyMessage="No reports were found"
                />
            }
        </div>
    );
}

export default connect(null, {
    fetchDailyReportsDaterangeCenter
})(ReportTable);