import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import LeadsBodyFooter from './LeadsBF';
import { checkResponse } from '../../form';

import {
    fetchLeadsIds,
    fetchMpCentersAll,
    fetchRpCentersAll,
    createLead,
    updateLead,
    convertLead,
    deleteLead,
} from '../../../actions';

// Exact same form as the Employee Modal, with variables and actions changed slightly
function LeadForm(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [lead, setLead] = useState({});
    const [mpCenterOptions, setMpCenterOptions] = useState([]);
    const [rpCenterOptions, setRpCenterOptions] = useState([]);

    const { mode, selectedLead, onSubmitCallback, fetchLeadsIds, fetchMpCentersAll, fetchRpCentersAll,
        createLead, updateLead, convertLead, deleteLead } = props;

    useEffect(() => {
        async function init(){
            if(mode !== 'create'){
                const leadRes = await fetchLeadsIds({ ids: [selectedLead.id] });
                const newLead = leadRes.data?.[0];

                if(mounted.current) setLead(newLead);
            }

            const mpCentersRes = await fetchMpCentersAll();
            const rpCentersRes = await fetchRpCentersAll();
            const newMpCenters = mpCentersRes.data;
            const newRpCenters = rpCentersRes.data;
            const newMpCenterOptions = [...newMpCenters.map(c => ({ value: parseInt(c.id), label: c.name })), { value: -1, label: 'None' }];
            const newRpCenterOptions = [...newRpCenters.map(c => ({ value: parseInt(c.id), label: c.name })), { value: -1, label: 'None' }];

            if(mounted.current){
                setMpCenterOptions(newMpCenterOptions);
                setRpCenterOptions(newRpCenterOptions);
                setLoaded(true);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const mpCenterPermissionsInvalid = (
                    (values.mpPermissions.value !== 'None' && values.mpPrimaryCenter.value === -1) ||
                    (values.mpPermissions.value === 'None' && values.mpPrimaryCenter.value !== -1)
                );
                const rpCenterPermissionsInvalid = (
                    (values.rpPermissions.value !== 'None' && values.rpPrimaryCenter.value === -1) ||
                    (values.rpPermissions.value === 'None' && values.rpPrimaryCenter.value !== -1)
                );
                if(mpCenterPermissionsInvalid || rpCenterPermissionsInvalid){
                    setStatus(`For each center, Primary Center and Permissions must both be 'None' or both NOT be 'None'.`);
                    setSubmitting(false);
                    return;
                }
                if(mode === 'create'){
                    if(values.mpPermissions.value === 'None') values.mpActive = false;
                    else values.mpActive = true;
                    if(values.rpPermissions.value === 'None') values.rpActive = false;
                    else values.rpActive = true;
                }
                if((values.mpPermissions.value === 'None' && values.mpActive) || (values.rpPermissions.value === 'None' && values.rpActive)){
                    setStatus(`For each center, accounts must have a permissions role to be active.`);
                    setSubmitting(false);
                    return;
                }
                if(values.mpPermissions.value === 'None' && values.rpPermissions.value === 'None'){
                    setStatus(`User must have at least one permissions role.`);
                    setSubmitting(false);
                    return;
                }
                if((values.mpPermissions.value === 'Parent' && values.rpPermissions.value === 'Student') ||
                    (values.mpPermissions.value === 'Student' && values.rpPermissions.value === 'Parent')){
                    setStatus(`User cannot have both Parent and Student permissions.`);
                    setSubmitting(false);
                    return;
                }

                const leadParams = {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone.toString().replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
                    mpPrimaryCenter: values.mpPrimaryCenter.value,
                    rpPrimaryCenter: values.rpPrimaryCenter.value,
                    mpPermissions: values.mpPermissions.value,
                    rpPermissions: values.rpPermissions.value,
                    userNotes: values.userNotes,
                    mpActive: values.mpActive ? 1 : 0,
                    rpActive: values.rpActive ? 1 : 0,
                };
                
                if(mode === 'create'){
                    response = await createLead(leadParams);
                } else if(mode === 'edit') {
                    leadParams.id = lead.id;
                    response = await updateLead(leadParams);
                }
            } else if(mode === 'convert'){
                const convertParams = {
                    id: lead.id,
                    receiveEmails: values.receiveEmails ? 1 : 0,
                    receiveTexts: values.receiveTexts ? 1 : 0,
                    shouldSendWelcomeEmail: values.shouldSendWelcomeEmail ? 1 : 0,
                }
                response = await convertLead(convertParams);
            } else if(mode === 'delete') {
                response = await deleteLead({ id: lead.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, lead, createLead, updateLead, convertLead, deleteLead]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Lead Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <LeadsBodyFooter
                    mode={mode}
                    selectedLead={lead}
                    mpCenterOptions={mpCenterOptions}
                    rpCenterOptions={rpCenterOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchLeadsIds,
    fetchMpCentersAll,
    fetchRpCentersAll,
    createLead,
    updateLead,
    convertLead,
    deleteLead
})(LeadForm);