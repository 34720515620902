import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import StampsLogBodyFooter from './StampsLogBF';
import { checkResponse } from '../../form';

import {
    fetchStampsLogsIds,
    fetchStudentsAll,
    createStampsLog,
    updateStampsLog,
    deleteStampsLog,
} from '../../../actions';

function StampsLogModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [stampsLog, setStampsLog] = useState({});
    const [studentOptions, setStudentOptions] = useState([])

    const { mode, selectedLog, onSubmitCallback, fetchStampsLogsIds, 
        fetchStudentsAll, createStampsLog, updateStampsLog, deleteStampsLog } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            const newStudents = studentsRes.data || [];
            const newStudentOptions = newStudents.filter(s => {
                return (parseInt(s.is_mp_student) === 1 && parseInt(s.mp_active) === 1) || selectedLog?.student === s.user_id;
            }).map(ns => ({ value: ns.user_id, label: `${ns.first_name} ${ns.last_name}` }));

            if(mode !== 'create'){
                const stampsLogRes = await fetchStampsLogsIds({ ids: [selectedLog.id] });
                const newStampsLog = stampsLogRes.data?.[0] || {};
                
                const matchedStudent = newStudents.find(s => s.user_id === newStampsLog.student) || {};
                const name = matchedStudent.first_name || matchedStudent.last_name ? 
                    `${matchedStudent.first_name} ${matchedStudent.last_name}` : `Unknown Student (UID: ${newStampsLog.student})`;
                newStampsLog.studentName = name

                if(mounted.current) setStampsLog(newStampsLog);
            }

            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const stampsLogParams = {
                    date: values.selectedDate,
                    student: values.selectedStudent.value,
                    type: values.selectedType.value,
                    amount: values.amount,
                    notes: values.notes,
                };
                
                if(mode === 'create'){
                    response = await createStampsLog(stampsLogParams);
                } else if(mode === 'edit') {
                    stampsLogParams.id = stampsLog.id;
                    response = await updateStampsLog(stampsLogParams);
                }
            } else if(mode === 'delete') {
                response = await deleteStampsLog({ id: stampsLog.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, stampsLog, createStampsLog, updateStampsLog, deleteStampsLog]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Stamp Log Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <StampsLogBodyFooter
                    mode={mode}
                    selectedLog={stampsLog}
                    studentOptions={studentOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStampsLogsIds,
    fetchStudentsAll,
    createStampsLog,
    updateStampsLog,
    deleteStampsLog,
})(StampsLogModal);