import app from '../api/app';
import Cookies from 'universal-cookie';
import DOMPurify from 'dompurify';

import { formatStrings } from '../components/functions';
import { Wait } from '../components/wait';

export { fetchAssessmentQuestionsAssessmentIdsND } from './paths/assessmentQuestions';
export { fetchGraphicND } from './paths/graphics';
export { fetchMPLessonsSingleND, fetchMPLessonsMultiND } from './paths/mpLessons';
export { fetchLessonsAllND } from './paths/lessons';

const cookies = new Cookies();

// Attempts action without the dispatch parameter, allowing calls to be made when redux connect isn't used
export const attemptActionND = async (action, params = {}) => {
    // Sanitize and escape ' for SQL use
    const sanitizedParams = JSON.parse(DOMPurify.sanitize(JSON.stringify(formatStrings(params))));

    // Attempt action with currently stored acces token
    const aToken = cookies.get('aToken');
    sanitizedParams.aToken = aToken;

    let response = { status: 999 };
    if(aToken){
        try {
            response = await action(sanitizedParams);
        } catch(e) {
            console.log(e);    
        } finally {
            if(!response || !response.status) return response || {};

            // 401 will be used for expired or otherwise invalid tokens
            if([401, 999].includes(response.status)){
                let tokenResponse = await refreshToken();

                // Try one more time if the first request failed
                // This should hopefully limit premature signouts
                if(!tokenResponse?.data?.message || tokenResponse?.status === 401){
                    console.log(11);
                    await Wait(500);
                    tokenResponse = await refreshToken();
                }

                // Token response status should either be 200, 401, or 500
                if(!tokenResponse || tokenResponse.status === 500) return tokenResponse;

                // 401 should only be be returned if the refresh token has expired
                if(tokenResponse.status === 401){
                    return response;
                }
                
                // If new token received, retry action
                const aToken = tokenResponse.data?.aToken;
                if(!aToken) return {};

                cookies.set('aToken', tokenResponse.data.aToken, { path: '/' });
                sanitizedParams.aToken = aToken;
                
                // return await action(sanitizedParams);
                const response2 = await action(sanitizedParams);
                return response2;
            } else {
                return response || {};
            }
        }
    } else {
        return response;
    }
}

const refreshToken = async () => {
    const rToken = cookies.get('rToken');
    if(!rToken) return {};

    let response = null;
    try {
        response = await app.post(`/adminAuth/request-new-token`, {
            rToken,
            portal: 'mp'
        })
    } catch(e) {
        response = e.response;
        console.log(e);
    } finally {}

    return response || {};
}