import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';
import { generateAssessmentReport } from '../../../../../components/export';

import {
    fetchLessonsAllND,
    fetchAssessmentQuestionsAssessmentIdsND
} from '../../../../../actions-nd';

function AssessmentResultsButton(props){
    const { assessment } = props;

    const downloadReport = useCallback(() => {
        (async function refresh(){            
            const lessonsRes = await fetchLessonsAllND();
            const questionsRes = await fetchAssessmentQuestionsAssessmentIdsND({ assessmentIds: [assessment.id] });

            const newLessons = lessonsRes?.data || [];
            const newQuestions = questionsRes?.data || [];
            
            await generateAssessmentReport(assessment, newLessons, newQuestions);
        })();
    }, [assessment]);

    return (
        <>
            <TooltipWrapper
                tooltipText="Generate an Assessment Report"
            >
                <Button
                    color="lte-mpEGreen"
                    variant="icon"
                    style={{ marginRight: "10px" }}
                    onClick={() => downloadReport()}
                >
                    <SVGIcon fileName="chart-positive"/>
        </Button>
            </TooltipWrapper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
})(AssessmentResultsButton);