import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { BrowserTabTitle } from '../../../components/display';
import { FormikControl } from '../../../components/form';
import { Button, Alert, FormValidation } from '../../../components/custom-essentials';

import {
    requestPasswordReset
} from '../../../actions'

function ForgotPassword(props) {
    const [wasRequested, setWasRequested] = useState(false);

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        return () => (mounted.current = false);
    });

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const response = await props.requestPasswordReset(values.email);

            if(!response || !response.status){
                if(mounted.current) setStatus('Unexpected error (empty response)');
            } else if(response.status === 401){
                if(mounted.current) setStatus('Email has not been verified. Please use your verification link.');
            } else if(response.status === 403){
                if(mounted.current) setStatus('The account associated with this email has been deactivated.');
            } else if(response.status === 404){
                if(mounted.current) setStatus('Email not found.');
            } else if(response.status === 500){
                if(mounted.current) setStatus('Internal server error (500).');
            } else if(response.status === 201){
                if(mounted.current) {
                    setStatus('Success!');
                    setWasRequested(true);
                }
            } else {
                if(mounted.current) setStatus(`Unexpected error (${response.status}).`);
            }

            setSubmitting(false);
        },
    });

    return (
        <>
            <BrowserTabTitle content="Forgot Password"/>
            <div className="text-3xl">
                Forgot Password?
            </div>
            <div className="text-md text-center relative top-2 text-mpLGrey-3">
                Enter your email to get a password reset link.
            </div>
            <div className="relative top-12">
                <div className="relative">
                    {formik.status && formik.status !== 'Success!' ?
                        <Alert color="mpLRed" content={formik.status}/> : formik.status ?
                        <Alert color="mpLBlue" content={"Request successful! Please check your email for a password reset link."}/> : null
                    }
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <div className="grid place-items-center">
                        <div className="w-1/2 mt-10">
                            <FormikControl
                                    id="forgot-password-email"
                                    name="email"
                                    placeholder="Email"
                                    disabled={formik.isSubmitting}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                            {formik.touched.email && formik.errors.email ? (
                                <FormValidation color="mpLRed" content={formik.errors.email}/>
                            ) : null}
                        </div>
                        <div className="mt-5">
                            <Button
                                color="hol-mpLRed"
                                className="mr-4"
                                onClick={() => null}
                            >
                                <Link
                                    disabled={formik.isSubmitting}
                                    to="/auth/login"
                                >
                                    {wasRequested ? 'Back to Login' : 'Cancel'}
                                </Link>
                            </Button>
                            <Button
                                color="lte-mpLBlue"
                                onClick={formik.handleSubmit}
                                disabled={formik.isSubmitting || wasRequested}
                            >
                                {wasRequested ? 'Email Sent!' : formik.isSubmitting ? 'Submitting...' : ' Submit'}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default connect(null, {
    requestPasswordReset
})(ForgotPassword);
