import { connect } from 'react-redux';

import { LoadingWatermark } from '../display/LoadingWatermark';

// Placholder. Use WebSocket-new when ready to troubleshoot and test
function WebSocket(props){
    return <LoadingWatermark/>;
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        socket: state.socket,
    };
}

export default connect(mapStateToProps, {

})(WebSocket);