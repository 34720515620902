import React from 'react';
import { getColumnsEmails } from '../table/getColumns';
import { Table } from '../../../../../components/table';

const columns = getColumnsEmails();
const defaultSorted = { dataField: 'emailLogId', order: 'asc' };
export default function EmailLogsTable(props){
    return (
        <>  
            <Table
                tableName="emailLogs"
                data={props.data}
                columns={columns}
                defaultSorted={defaultSorted}
                sizePerPageInitial={50}
                emptyMessage="No email logs were found"
            />
        </>
    );
}