import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import LearningPlanBodyFooter from './LearningPlanBF';
import { checkResponse } from '../../form';
import { formatDate, formatDateApi } from '../../functions';

import {
    fetchLearningPlansId,
    fetchStudentsUserIds,
    fetchAssessmentsStudentIds,
    fetchLessonsAll,
    createLearningPlan,
    updateLearningPlan,
    deleteLearningPlan,
} from '../../../actions';

function LearningPlanModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [attemptingClose, setAttemptingClose] = useState(false);
    
    const [learningPlan, setLearningPlan] = useState({});
    const [selectedStudentOption, setSelectedStudentOption] = useState({});
    const [learningPlanItems, setLearningPlanItems] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [lessonOptions, setLessonOptions] = useState([]);
    const [assessmentOptions, setAssessmentOptions] = useState([]);

    const { mode, selectedLearningPlan, selectedStudent, onSubmitCallback, fetchLearningPlansId,
        fetchAssessmentsStudentIds, fetchStudentsUserIds, fetchLessonsAll, createLearningPlan,
        updateLearningPlan, deleteLearningPlan } = props;

    useEffect(() => {
        async function init(){           
            const studentsRes = await fetchStudentsUserIds({ userIds: [selectedStudent.value] });
            const newStudent = studentsRes.data?.[0] || {};
            const newStudentOption = newStudent.user_id ?
                { value: newStudent.user_id, label: `${newStudent.first_name} ${newStudent.last_name}` }
                : { value: -1, label: `Unknown student (UID ${selectedStudent.value})` };
            
            const lessonsRes = await fetchLessonsAll();
            const assessmentsRes = await fetchAssessmentsStudentIds({ studentIds: [newStudent.user_id] });

            const newLessons = lessonsRes.data || [];
            const newAssessments = assessmentsRes.data?.assessments || [];
            const newQuestions = assessmentsRes.data?.questions || [];

            const assessmentQuestionMap = {};
            newQuestions.forEach(q => {
                if(!assessmentQuestionMap[parseInt(q.assessment_id)]) assessmentQuestionMap[parseInt(q.assessment_id)] = [];
                assessmentQuestionMap[parseInt(q.assessment_id)].push(q);
            });
            newAssessments.forEach(a => a.questions = assessmentQuestionMap[parseInt(a.id)] || []);

            const lessonMap = {};
            const exceptedLessons = [];
            newLessons.forEach(l => lessonMap[parseInt(l.id)] = l);

            const newAssessmentOptions = [{ value: -1, label: 'No Assessment' }, ...newAssessments.sort((a, b) => {
                return new Date(b.date_taken) - new Date(a.date_taken);
            }).filter(a => {
                return a.date_taken;
            }).map(a => {
                const isInactive = parseInt(a.active) === 0 ? ' (Inactive)' : '';
                return ({ value: a.id, label: `${a.name} (${formatDate(a.date_taken)})${isInactive}`, obj: a });
            })];

            if(mode !== 'create'){
                const learningPlansRes = await fetchLearningPlansId({ id: selectedLearningPlan.id });

                const newLearningPlan = learningPlansRes.data?.learningPlans?.[0] || {};
                const newLearningPlanItems = learningPlansRes.data?.learningPlanItems || [];

                let lpiUniqueId = 0;
                const formattedLearningPlanItems = newLearningPlanItems.sort((a, b) => {
                    return parseInt(a.sort_order) - parseInt(b.sort_order);
                }).map(lpi => {
                    exceptedLessons.push(lpi.lesson_id);
                    const relevantLesson = lessonMap[parseInt(lpi.lesson_id)] || {};
                    return {
                        id: parseInt(lpi.lesson_id),
                        uniqueId: lpiUniqueId++, // Used in case two of the same lesson are assigned
                        lessonName: `${relevantLesson.lesson_id} ${relevantLesson.name}`,
                        dateAssigned: lpi.date_assigned ? formatDateApi(lpi.date_assigned) : '',
                        dateCompleted: lpi.date_completed ? formatDateApi(lpi.date_completed) : '',
                        sortOrder: parseInt(lpi.sort_order),
                        originallyMarkedComplete: lpi.date_completed ? true : false,
                    };
                });

                if(mounted.current){
                    setLearningPlan(newLearningPlan);
                    setLearningPlanItems(formattedLearningPlanItems);
                }
            }

            const newLessonOptions = [ { value: -1, label: 'None Selected' },
                ...newLessons.filter(l => parseInt(l.active) === 1 || exceptedLessons.includes(l.id))
                .map(l => {
                    const lessonId = parseInt(l.id);
                    const lessonOption = ({ value: lessonId, label: `${l.lesson_id}: ${l.name}`, obj: l });
                    return lessonOption;
                }
            )];
            
            if(mounted.current){
                setSelectedStudentOption(newStudentOption);
                setLessons(newLessons);
                setLessonOptions(newLessonOptions);
                setAssessmentOptions(newAssessmentOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes, force = false) => {
        if(!attemptingClose && !force && mode !== 'delete'){
            setAttemptingClose(true);
            return;
        }
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, setAttemptingClose, mode]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const { linkedAssessment, name, student, notes, isReview, sortOrder, active, learningPlanItems } = values;
                const learningPlanParams = {
                    assessmentId: linkedAssessment.value,
                    name,
                    student: student.value,
                    notes,
                    isReview: isReview ? 1 : 0,
                    sortOrder,
                    active: active ? 1 : 0
                };
                const learningPlanItemParams = [];

                learningPlanItems.forEach(lpi => {
                    learningPlanItemParams.push({
                        lessonId: lpi.id,
                        dateAssigned: lpi.dateAssigned || null,
                        dateCompleted: lpi.dateCompleted || null,
                        sortOrder: lpi.sortOrder
                    });
                })
                
                if(mode === 'create'){
                    response = await createLearningPlan({
                        learningPlan: learningPlanParams,
                        learningPlanItems: learningPlanItemParams
                    });
                } else if(mode === 'edit') {
                    learningPlanParams.id = learningPlan.id;
                    response = await updateLearningPlan({
                        learningPlan: learningPlanParams,
                        learningPlanItems: learningPlanItemParams
                    });
                }
            } else if(mode === 'delete') {
                response = await deleteLearningPlan({ id: learningPlan.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [mode, handleClose, learningPlan, createLearningPlan, updateLearningPlan, deleteLearningPlan]);

    return (
        <Modal className="w-2/3" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Learning Plan Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <LearningPlanBodyFooter
                    mode={mode}
                    selectedStudentOption={selectedStudentOption}
                    learningPlan={learningPlan}
                    learningPlanItems={learningPlanItems}
                    assessmentOptions={assessmentOptions}
                    lessons={lessons}
                    lessonOptions={lessonOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchLearningPlansId,
    fetchAssessmentsStudentIds,
    fetchStudentsUserIds,
    fetchLessonsAll,
    createLearningPlan,
    updateLearningPlan,
    deleteLearningPlan
})(LearningPlanModal);