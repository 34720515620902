import { formatTime } from '../../../../../../components/functions';

export function getSeriesOptions(assignmentMap, switches){
    // Series data
    const series = [];

    // Find starting and closing times (or first and last assigned)
    const times = [];
    let min = 1440;
    let max = 0;
    Object.values(assignmentMap).forEach(instructorSet => {
        Object.keys(instructorSet).forEach(timeKey => {
            const time = timeKey.split('-')[1];
            const timeInt = parseInt(time);
            if(timeInt < min) min = timeInt;
            if(timeInt > max) max = timeInt;
        });
    });
    // Add one buffer block before and after to make the chart edges look nice
    min = Math.max(min - 30, 0) 
    max = Math.min(max + 30, 1440);
    for(let i = parseInt(min); i <= parseInt(max); i += 30) times.push(i);

    // Parse seating info
    const instructorMap = {};
    Object.values(assignmentMap).forEach(instructorSet => {
        const { id, name } = instructorSet;
        instructorMap[id] = { id, name, seats: [], weights: [] };
        for(let t of times){
            const seatValue = instructorSet[`s-${t}`] || 0;
            const weightValue = instructorSet[`w-${t}`] || 0;

            instructorMap[id].seats.push(seatValue);
            instructorMap[id].weights.push(weightValue);
        }
    });

    const timesFormatted = times.map(t => formatTime(t));

    // Options data
    const seriesLength = Object.values(instructorMap).length * 2;

    const colorsBase = [];
    // LRed, Orange, Yellow, Green, Teal, LBlue, DBlue, Purple, DRed, LGrey, DGrey
    const colorsRef = ['#d80000', '#e26100', '#eabc00', '#028a0f', '#009285', '#007b9d', '#00366b', '#8950FC', '#990000', '#404449', '#091823'];
    const strokeCurvesBase = [];
    const strokeDashArraysBase = [];
    const strokeWidthsBase = [];
    for(let i = 0; i < seriesLength; i++){
        colorsBase.push(colorsRef[i % colorsRef.length]);
        strokeCurvesBase.push('smooth');
        strokeDashArraysBase.push(0);
        strokeWidthsBase.push(2);
    }

    const colors = [];
    const strokeCurves = [];
    const strokeDashArrays = [];
    const strokeWidths = [];

    let index = 0;
    const maxIndex = colorsRef.length;
    Object.values(instructorMap).forEach(instructorSet => {
        const { id, name, seats, weights } = instructorSet;
        if(switches[id]){
            if(!switches.weights){
                series.push({
                    name: `${name} (S)`,
                    data: seats
                });
            } else {
                series.push({
                    name: `${name} (W)`,
                    data: weights
                })
            }
            colors.push(colorsBase[index]);
            strokeCurves.push(strokeCurvesBase[index]);
            strokeDashArrays.push(strokeDashArraysBase[index]);
            strokeWidths.push(strokeWidthsBase[index]);
        }
        index = (index + 1) % maxIndex;
    })

    const options = {
        colors: colors,
        chart: {
            height: "400px",
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: strokeCurves,
            dashArray: strokeDashArrays,
            width: strokeWidths,
        },
        title: {
            text: `Today's Instructor Assignments (${switches.weights ? 'Weights' : 'Seats'})`,
            align: 'left'
        },
        grid: {
            row: {
                // ultraLightA class
                colors: ['#ffe7e7', '#fdfdfc'], // takes an array which will be repeated on columns
                opacity: 0.8
            },
        },
        xaxis: {
            categories: timesFormatted,
        }
    };

    return  { series, options };
}