import React from 'react';

import { Modal, ModalBodyFooter, Button, EllipsisLoader } from '../../custom-essentials';
import { getAlerts, formatTime } from '../../functions';
import { SelectMulti, FormikControl, renderSubmittingDefault } from '../../form';

export function renderSubmitting(submissionStatus, setSubmitting, setFieldValue){
    let completed = 0;
    Object.values(submissionStatus).forEach(ss => {
        if(ss.completed) completed++;
    });
    const total = Object.values(submissionStatus).length - 2;
    const lengthText = total > 0 ? ` (${completed}/${total})` : null;
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="grid grid-cols-1">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <div className="flex items-center">
                            <span className="mr-8"><EllipsisLoader/></span>
                            Processing{lengthText}...
                        </div>
                    }
                </h4>

                <br/>
                
                <div className="grid grid-cols-1 gap-y-2">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => {
                        setFieldValue('firstConfirm', false);
                        setSubmitting(false);
                    }}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}

export function BodyContent(props){
    const { formik, instructorOptions } = props;
    const appointments = formik.values.appointments;

    return (
        <>
            {Object.entries(appointments).map(([timeKey, appointments]) => {
                const appointmentGroup = Object.values(appointments).sort((a, b) => {
                    if(a.studentName < b.studentName) return -1;
                    else if(a.studentName > b.studentName) return 1;
                    return 0;
                })
return (
<div key={`quick-assign-assignments-and-notes-${timeKey}`}>
    <h4>{formatTime(timeKey)}</h4>
    {appointmentGroup.map(a => {
        const headerContent = <div className="flex flex-row">{a.studentName} {a.extraNotifs} &nbsp; {getAlerts(a, { binderLocation: true, noAlerts: true })}</div>;
        let assignedText = <span className="text-mpOrange">None</span>;
        if(a.assignments.length){
            assignedText = '';
            a.assignments.forEach(assignment => assignedText += `${assignment.label}, `);
            assignedText = assignedText.slice(0, -2);
        }
        const notesText = a.specialNotesSchedule ? (
            <div className="text-mpGreen">
                {a.specialNotesSchedule.length > 250 ? `${a.specialNotesSchedule.slice(0, 250)}...` : a.specialNotesSchedule}
            </div>
        ) : null;
        const warning = a.specialNotesSchedule.length > 250 ? (
            <div className="text-mpLRed">
                Max. 250 characters
            </div>
        ) : null;
        return(
            <div key={`quick-assign-assignments-and-notes-${timeKey}-appointment-${a.id}`}>
                {formik.values.showAppointments === parseInt(a.id) ? 
                    <>
                        <div className="h-2 clear-both"/>
                        <div className="flex flex-row gap-x-4">
                            <h4
                                className="text-mpLBlue table cursor-pointer"
                                onClick={() => {
                                    // Close group
                                    formik.setFieldValue('showAppointments', []);
                                }}
                            >
                                {headerContent}
                            </h4>
                        </div>
                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                <SelectMulti
                                    label="Select Instructors"
                                    id={`quick-assign-assign-instructors-${a.id}`}
                                    name={`quick-assign-assign-instructors-${a.id}`}
                                    value={a.assignments}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        newValues.appointments[parseInt(timeKey)][parseInt(a.id)].assignments = e.target.value;
                                        formik.setValues(newValues);
                                    }}
                                    options={instructorOptions}
                                />
                                <div className="h-2 clear-both"/>
                                <div className="text-mpLGrey-2 text-xs">Scheduling Notes</div>
                                <FormikControl
                                    id={`quick-assign-schedule-notes-${a.id}`}
                                    name={`quick-assign-schedule-notes-${a.id}`}
                                    value={a.specialNotesSchedule}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        newValues.appointments[parseInt(timeKey)][parseInt(a.id)].specialNotesSchedule = e.target.value;
                                        formik.setValues(newValues);
                                    }}
                                    placeholder="Scheduling Notes (Max. 250 characters)"
                                />
                                {warning}
                            </div>
                        </div>
                        <div className="h-2 clear-both"/>
                    </>
                :
                    <>
                        <div className="h-2 clear-both"/>
                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2">
                                <div
                                    className="text-mpLBlue table cursor-pointer"
                                    onClick={() => {
                                        // Open group
                                        formik.setFieldValue('showAppointments', parseInt(a.id));
                                    }}
                                >
                                    {headerContent}
                                </div>
                                <div>
                                    Assigned: {assignedText}
                                </div>
                                {notesText}
                                {warning}
                            </div>
                        </div>
                        <div className="h-2 clear-both"/>
                    </>
                }
            </div>
        );
    })}
    <div className="h-2 clear-both"/>
    <hr/>
    <div className="h-2 clear-both"/>
</div>
);
            })}
        </>
    );
}