import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import AssessmentTemplatesBodyFooter from './AssessmentTemplatesBF';
import { checkResponse } from '../../form';

import {
    fetchLessonsAll,
    fetchAssessmentTemplatesId,
    createAssessmentTemplate,
    updateAssessmentTemplate,
    deleteAssessmentTemplate
} from '../../../actions';

function AssessmentTemplateModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [attemptingClose, setAttemptingClose] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [lessonOptions, setLessonOptions] = useState([]);
    const [assessmentTemplate, setAssessmentTemplate] = useState({});
    const [questionTemplates, setQuestionTemplates] = useState([]);

    const { mode, selectedAssessmentTemplate, onSubmitCallback, fetchLessonsAll, fetchAssessmentTemplatesId,
    createAssessmentTemplate, updateAssessmentTemplate, deleteAssessmentTemplate } = props;

    useEffect(() => {
        async function init(){           
            const lessonsRes = await fetchLessonsAll();
            const newLessons = lessonsRes.data || [];
            const lessonMap = {};
            const newLessonOptions = [ { value: -1, label: 'None Selected' },
                ...newLessons.map(l => {
                    const lessonId = parseInt(l.id);
                    const lessonOption = ({ value: lessonId, label: `${l.lesson_id}: ${l.name}` });
                    lessonMap[lessonId] = lessonOption;
                    return lessonOption;
                }
            )];

            const newQuestionTemplates = {};
            if(mode !== 'create'){
                const assessmentTemplateRes = await fetchAssessmentTemplatesId({ id: selectedAssessmentTemplate.id });
                const newAssessmentTemplate = assessmentTemplateRes.data?.assessment || {};
                const newAssessmentQuestionTemplates = assessmentTemplateRes.data.questions || [];

                if(mode === 'edit'){
                    for(let i = 1; i < 200; i++){
                        if(i <= newAssessmentTemplate.n_questions){
                            // Take question number i by index. Check to make sure this actually matches question_number
                            let currentQuestion = newAssessmentQuestionTemplates[i] || {};
                            if(parseInt(currentQuestion.question_number) !== i){
                                currentQuestion = newAssessmentQuestionTemplates.find(q => parseInt(q.question_number) === i) || {};
                            }
    
                            // If not found, show a warning
                            if(!currentQuestion.question_number){
                                newQuestionTemplates[i] = {
                                    onIncorrect1: { value: -1, label: 'Failed to load question'},
                                    onIncorrect2: { value: -1, label: 'Failed to load question'},
                                    onIncorrect3: { value: -1, label: 'Failed to load question'}
                                };    
                            }
    
                            // If found, load data
                            const lesson1Option = lessonMap[parseInt(currentQuestion.on_incorrect_1)] ||
                                { value: -1, label: `Unknown lesson (ID: ${currentQuestion.on_incorrect_1})`};
                            const lesson2Option = lessonMap[parseInt(currentQuestion.on_incorrect_2)] || { value: -1, label: 'None Selected'};
                            const lesson3Option = lessonMap[parseInt(currentQuestion.on_incorrect_3)] || { value: -1, label: 'None Selected'};
                            newQuestionTemplates[i] = {
                                onIncorrect1: lesson1Option,
                                onIncorrect2: lesson2Option,
                                onIncorrect3: lesson3Option
                            }
                        } else {
                            newQuestionTemplates[i] = {
                                onIncorrect1: { value: -1, label: 'None Selected'},
                                onIncorrect2: { value: -1, label: 'None Selected'},
                                onIncorrect3: { value: -1, label: 'None Selected'}
                            };
                        }
                    }
                }

                if(mounted.current){
                    setAssessmentTemplate(newAssessmentTemplate);
                    setQuestionTemplates(newQuestionTemplates);
                }
            } else if(mode === 'create'){
                for(let i = 1; i <= 200; i++){
                    newQuestionTemplates[i] = {
                        onIncorrect1: { value: -1, label: 'None Selected'},
                        onIncorrect2: { value: -1, label: 'None Selected'},
                        onIncorrect3: { value: -1, label: 'None Selected'}
                    };
                }
                if(mounted.current){
                    setQuestionTemplates(newQuestionTemplates);
                }
            }
            
            if(mounted.current){
                setLessonOptions(newLessonOptions);
                setHasLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes, force = false) => {
        if(!attemptingClose && !force && mode !== 'delete'){
            setAttemptingClose(true);
            return;
        }
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, setAttemptingClose, mode]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            const {
                level,
                name,
                notes,
                nQuestions,
                fileName,
                questionTemplates,
                hasFile,
                hasAnswerKey,
                active
            } = values;

            if(mounted.current) setSubmitting(true);

            let response = { status: 999 };

            let isValid = true;
            const questionParams = [];
            for(let i = 1; i <= nQuestions; i++){
                const { onIncorrect1, onIncorrect2, onIncorrect3 } = questionTemplates[i];
                const val1 = onIncorrect1.value;
                const val2 = onIncorrect2.value;
                const val3 = onIncorrect3.value;

                if(val1 === -1 || val2 === val1 || val3 === val1 || (val2 === val3 && val2 !== -1 && val3 !== -1)){
                    isValid = false;
                    break;
                }

                if(val1 !== -1){
                    questionParams.push({
                        assessmentTemplateId: assessmentTemplate.id || -1,
                        questionNumber: i,
                        onIncorrect1: val1,
                        onIncorrect2: val2,
                        onIncorrect3: val3
                    })
                }
            }

            if(!isValid){
                setStatus('One or more fields needs to be corrected.');
                setSubmitting(false);
                return;
            }

            const assessmentParams = {
                level,
                name,
                notes,
                nQuestions,
                fileName,
                hasFile: hasFile ? 1 : 0,
                hasAnswerKey: hasAnswerKey ? 1 : 0,
                active: active ? 1 : 0
            };

            if(mode === 'create'){
                response = await createAssessmentTemplate({ assessment: assessmentParams, questions: questionParams });
            } else if(mode === 'edit'){
                assessmentParams.id = assessmentTemplate.id;
                response = await updateAssessmentTemplate({ assessment: assessmentParams, questions: questionParams });
            } else if(mode === 'delete'){
                response = await deleteAssessmentTemplate({ id: assessmentTemplate.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }

            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [mode, handleClose, createAssessmentTemplate, updateAssessmentTemplate, deleteAssessmentTemplate, assessmentTemplate]);

    return (
        <Modal className="w-3/4" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Assessment Template Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <AssessmentTemplatesBodyFooter
                    mode={mode}
                    hasLoaded={hasLoaded}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    submitted={submitted}
                    assessmentTemplate={assessmentTemplate}
                    questionTemplates={questionTemplates}
                    lessonOptions={lessonOptions}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchLessonsAll,
    fetchAssessmentTemplatesId,
    createAssessmentTemplate,
    updateAssessmentTemplate,
    deleteAssessmentTemplate
})(AssessmentTemplateModal);