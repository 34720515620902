import React from 'react';
import { getColumnsTexts } from '../table/getColumns';
import { Table } from '../../../../../components/table';

const columns = getColumnsTexts();
const defaultSorted = { dataField: 'textLogId', order: 'asc' };
export default function TextLogsTable(props){
    return (
        <>  
            <Table
                tableName="textLogs"
                data={props.data}
                columns={columns}
                defaultSorted={defaultSorted}
                sizePerPageInitial={50}
                emptyMessage="No text logs were found"
            />
        </>
    );
}