import app from '../api/app';
import { attemptActionND } from './index';

export const basicPathND = async(params, path, type) => {
    async function action(params){
        let response = null;
        try {
            response = await app[type](path, {
                params
            });
        } catch (e){
            response = e.response;
            console.log(e);
        } finally {
            if(response){
                return response;
            } else {
                return {};
            }
        }
    };
    return await attemptActionND(action, params);
}

export const basicPathDownloadND = async(params, path, type) => {
    async function action(params){
        let response = null;
        try {
            response = await app[type](path, {
                responseType: 'arraybuffer',
                params
            });
        } catch (e){
            response = e.response;
            console.log(e);
        } finally {
            if(response){
                return response;
            } else {
                return {};
            }
        }
    };
    return await attemptActionND(action, params);
}