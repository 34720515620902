import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../components/custom-essentials';
import { FormikControl } from '../../../components/form';
import { formatDateTimeFull } from '../../../components/functions';

function Multiply(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [problems, setProblems] = useState([]);
    const [lastGenerated, setLastGenerated] = useState(null);
    const [currentError, setCurrentError] = useState('');

    function generateProblems(values, shouldDistribute){
        const { nProblems, allowedNumbers1, allowedNumbers2, additionalLabels } = values;

        if(isNaN(parseInt(nProblems)) || parseInt(nProblems) <= 0 || parseInt(nProblems) > 100 || parseInt(nProblems) % 1 !== 0){
            setCurrentError('nProblems');
            return;
        } else if(!allowedNumbers1.trim().length){
            setCurrentError('allowedNumbers1');
            return;
        } else if(!allowedNumbers2.trim().length){
            setCurrentError('allowedNumbers2');
            return;
        } else setCurrentError('');
        const firstAllowedNumbers = allowedNumbers1.replace(/ /g, '').split(',');
        const secondAllowedNumbers = allowedNumbers2.replace(/ /g, '').split(',');
        const additionalLabelsList = additionalLabels.replace(/ /g, '').split(',').filter(l => l !== '');
        for(let n of firstAllowedNumbers){
            if(isNaN(n)){
                setCurrentError('allowedNumbers1');
                return;
            }
        }
        for(let n of secondAllowedNumbers){
            if(isNaN(n)){
                setCurrentError('allowedNumbers2');
                return;
            }
        }
        let allowedNumbersMap = {};
        function resetNumbersMap(){
            firstAllowedNumbers.forEach(n => allowedNumbersMap[n] = [...secondAllowedNumbers]);
        }

        resetNumbersMap();

        const newProblems = [];
        
        for(let i = 0; i < parseInt(nProblems); i++){
            let firstNumbersList = Object.keys(allowedNumbersMap);
            
            // Reset if out of numbers and in distribute mode
                if(!firstNumbersList.length){
                    resetNumbersMap();
                    firstNumbersList = Object.keys(allowedNumbersMap);
                }

            //

            const order = Math.floor(Math.random() * 2); // 0 or 1
            
            const firstIndex = Math.floor(Math.random() * firstNumbersList.length);
            const firstNumber = firstNumbersList[firstIndex];

            const secondNumbersList = allowedNumbersMap[firstNumber];
            const secondIndex = Math.floor(Math.random() * secondNumbersList.length);
            const secondNumber = secondNumbersList[secondIndex];

            const additionalIndex = Math.floor(Math.random() * additionalLabelsList.length);
            const additionalLabel = additionalLabelsList.length ? ` * ${additionalLabelsList[additionalIndex]}` : '';

            //
                if(shouldDistribute){
                    allowedNumbersMap[firstNumber].splice(secondIndex, 1);
                    if(!allowedNumbersMap[firstNumber].length) delete allowedNumbersMap[firstNumber];
                }
            //

            if(order === 0) newProblems.push(`${firstNumber} * ${secondNumber}${additionalLabel}`);
            else if(order === 1) newProblems.push(`${secondNumber} * ${firstNumber}${additionalLabel}`);
        }

        setLastGenerated(new Date());
        setProblems(newProblems);
    }

    function shuffleProblems() {
        const newProblems = [...problems];
        let currentIndex = newProblems.length,  randomIndex;
        
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
        
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [newProblems[currentIndex], newProblems[randomIndex]] = [
            newProblems[randomIndex], newProblems[currentIndex]];
        }
        
        setProblems(newProblems);
    }

    let index = 1;
    
    return (
        <div className="card">
            <h2>Inputs</h2>
            <div style={{ height: "0.7rem", clear: "both" }}/>
            <Formik
                initialValues={{
                    nProblems: 10,
                    allowedNumbers1: '',
                    allowedNumbers2: '0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12',
                    additionalLabels: ''
                }}
            >
                {formik => (
                    <>
                        <div className="row no-mar">
                            <div className="col-6 no-pad">
                                <h5>Number of Problems</h5>
                                <FormikControl
                                    id="multiply-n-problems"
                                    name="nProblems"
                                    placeholder="Number of problems to generate"
                                    value={formik.values.nProblems}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        {currentError === 'nProblems' ? <div className="text-mpLRed">Number of problems must be an integer between 1 and 100</div> : null}

                        <br/>
                        <br/>

                        <div className="row no-mar">
                            <div className="col-6 no-pad">
                                <h5>Allowed Factors 1</h5>
                                <FormikControl
                                    id="multiply-allowed-numbers-1"
                                    name="allowedNumbers1"
                                    placeholder="First set of factors to use"
                                    value={formik.values.allowedNumbers1}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="col- d-flex align-items-end" style={{ marginLeft: "2rem" }}>
                                <Button
                                    variant="lte-mpLRed"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers1', '')}
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant="lte-mpLBlue"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers1', '1, 3, 5, 7, 9, 11')}
                                >
                                    Odds
                                </Button>
                                <Button
                                
                                    variant="lte-mpLBlue"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers1', '0, 2, 4, 6, 8, 10, 12')}
                                >
                                    Evens
                                </Button>
                                <Button
                                    variant="lte-mpLBlue"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers1', '0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12')}
                                >
                                    All
                                </Button>
                            </div>
                        </div>
                        {currentError === 'allowedNumbers1' ? <div className="text-mpLRed">Allowed numbers 1 is missing, or an invalid value was entered</div> : null}

                        <br/>
                        <br/>

                        <div className="row no-mar">
                            <div className="col-6 no-pad">
                                <h5>Allowed Factors 2</h5>
                                <FormikControl
                                    id="multiply-allowed-numbers-2"
                                    name="allowedNumbers2"
                                    placeholder="Second set of factors to use"
                                    value={formik.values.allowedNumbers2}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="col- d-flex align-items-end" style={{ marginLeft: "2rem" }}>
                                <Button
                                    variant="lte-mpLRed"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers2', '')}
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant="lte-mpLBlue"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers2', '1, 3, 5, 7, 9, 11')}
                                >
                                    Odds
                                </Button>
                                <Button
                                
                                    variant="lte-mpLBlue"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers2', '0, 2, 4, 6, 8, 10, 12')}
                                >
                                    Evens
                                </Button>
                                <Button
                                    variant="lte-mpLBlue"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('allowedNumbers2', '0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12')}
                                >
                                    All
                                </Button>
                            </div>
                        </div> 
                        {currentError === 'allowedNumbers2' ? <div className="text-mpLRed">Allowed numbers 2 is missing, or an invalid value was entered</div> : null}

                        <br/>
                        <br/>

                        <div className="row no-mar">
                            <div className="col-6 no-pad">
                                <h5>Additional Labels</h5>
                                <FormikControl
                                    id="multiply-additional-labels"
                                    name="additionalLabels"
                                    placeholder="Extra labels to randomly assign to problems. Separate by commas"
                                    value={formik.values.additionalLabels}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="col- d-flex align-items-end" style={{ marginLeft: "2rem" }}>
                                <Button
                                    variant="lte-mpLRed"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => formik.setFieldValue('additionalLabels', '')}
                                >
                                    Clear
                                </Button>
                            </div>
                        </div> 

                        <br/>
                        <br/>

                        <div className="row no-mar">
                            <div className="col-6 d-flex align-items-end">
                                <Button
                                    variant="lte-mpTeal"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => generateProblems(formik.values, false)}
                                >
                                    Generate
                                </Button>
                                <Button
                                    variant="lte-mpTeal"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => generateProblems(formik.values, true)}
                                >
                                    Generate (Distributed)
                                </Button>
                                <Button
                                    variant="lte-mpOrange"
                                    style={{ marginRight: "1rem" }}
                                    onClick={shuffleProblems}
                                >
                                    Shuffle Existing
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>

            <br/>
            <br/>

            <h2>Problems:</h2>
            {lastGenerated ? <div className="text-mpOrange">Last generated on {formatDateTimeFull(lastGenerated)}</div> : null}
            <br/>
            {problems.length ? problems.map(p => {
                const [firstN, secondN, label] = p.split(' * ');
                return (
                    <div className="row no-mar" style={{ fontSize: "15pt" }} key={`${p}-${index++}`}>
                        {index}) {firstN} &#215; {secondN}{label ? ` (${label})` : null}
                    </div>
                )
            }) : <div className="text-mpOrange">None</div>}
        </div>
    );
}

export default connect(null, {

})(Multiply);