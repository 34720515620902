import { formatDateApi, getymdhms } from './index';

// Should be synced with the member portal's version of this function
export function getAvailabilities(appointments = [], availabilities = [], blocks = []){
    const groupedAvailability = {};
    const aToCMap = {};
    const aToDMap = {};

    // Set up groupedAvailability object and populate with block info
    availabilities.forEach(a => {
        const dateKey = formatDateApi(a.date).replace(/-/g, '_');
        const center = parseInt(a.center);
    
        if(!groupedAvailability[dateKey]) groupedAvailability[dateKey] = {};
        // Pretend as if "closed" availabilities don't exist
        if(parseInt(a.is_closed) === 0) groupedAvailability[dateKey][center] = { blockList: [] };
    
        aToCMap[parseInt(a.id)] = center;
        aToDMap[parseInt(a.id)] = dateKey;
    });

    blocks.sort((a, b) => {
        return a.time - b.time;
    }).forEach(b => {
        const availId = parseInt(b.availability_id);
        const availCenter = parseInt(aToCMap[availId]);
        const availDate = aToDMap[availId];

        if(!groupedAvailability[availDate] || !groupedAvailability[availDate][availCenter]) return;
        groupedAvailability[availDate][availCenter].blockList.push(b);
    });

    // Parse and append seating information from blocks to each item
    Object.values(groupedAvailability).forEach(centerList => {
        Object.values(centerList).forEach(availObject => {
            availObject.seatingInfo = {};

            const blocks = availObject.blockList;
            blocks.forEach(b => {
                const isClosed = parseInt(b.is_closed) === 1;
                availObject.seatingInfo[parseInt(b.time)] = {
                    maxS30: isClosed ? 0 : parseInt(b.students),
                    maxW30: isClosed ? 0 : parseInt(b.seats),
                    msS30: isClosed ? 0 : parseInt(b.start_students),
                    msW30: isClosed ? 0 : parseInt(b.start_seats),
                    // These last two will be calculated with every appointment's
                    // weight equal to 1
                    maxW30iw: isClosed ? 0 : parseInt(b.seats),
                    msW30iw: isClosed ? 0 : parseInt(b.start_seats),
                }
            })
        });
    });

    // Deduct necessary parameters for each scheduled appointment
    appointments.forEach(a => {
        if(['Missed', 'Cancelled'].includes(a.status)) return;

        const aptDateTime = new Date(a.date_time);
        const [year, month, date, hours, minutes] = getymdhms(aptDateTime);
        const dateKey = `${year}_${month}_${date}`;
        const centerKey = parseInt(a.center);
        const startTimeKey = hours * 60 + minutes * 1;
        const endTimeKey = startTimeKey + parseInt(a.duration);
        const weight = parseInt(a.weight);

        for(let i = startTimeKey; i < endTimeKey; i += 30){
            const infoBlock = groupedAvailability?.[dateKey]?.[centerKey]?.seatingInfo?.[i];
            if(!infoBlock) continue;

            infoBlock.maxS30 = Math.max(infoBlock.maxS30 - 1, 0);
            infoBlock.maxW30 = Math.max(infoBlock.maxW30 - weight, 0);
            infoBlock.maxW30iw = Math.max(infoBlock.maxW30iw - 1, 0);
            if(i === startTimeKey){
                infoBlock.msS30 = Math.max(infoBlock.msS30 - 1, 0);
                infoBlock.msW30 = Math.max(infoBlock.msW30 - weight, 0);
                infoBlock.msW30iw = Math.max(infoBlock.msW30iw - weight, 0);
            }
        }
    });

    // On second iteration, look forward from each block to find the maximum
    // seats and weight for each 60, 90, and 120 minute appointments
    Object.values(groupedAvailability).forEach(centerList => {
        Object.values(centerList).forEach(availObject => {
            const seatingInfo = availObject.seatingInfo;

            const firstTime = Math.min(...Object.keys(seatingInfo));
            const lastTime = Math.max(...Object.keys(seatingInfo));
            
            for(let i = firstTime; i <= lastTime; i += 30){
                const current = seatingInfo[i];
                if(!current) continue; // Could happen if no blocks are assigned, or if a block is missing

                const info60 = seatingInfo[i + 30] || {};
                const info90 = seatingInfo[i + 60] || {};
                const info120 = seatingInfo[i + 90] || {};

                current.maxS60 = Math.min(current.maxS30 || 0, info60.maxS30 || 0);
                current.maxW60 = Math.min(current.maxW30 || 0, info60.maxW30 || 0);
                current.msS60 = Math.min(current.msS30 || 0, info60.msS30 || 0);
                current.msW60 = Math.min(current.msW30 || 0, info60.msW30 || 0);
                current.maxW60iw = Math.min(current.maxW30iw || 0, info60.maxW30iw || 0);
                current.msW60iw = Math.min(current.msW30iw || 0, info60.msW30iw || 0);

                current.maxS90 = Math.min(current.maxS30 || 0, info60.maxS30 || 0, info90.maxS30 || 0);
                current.maxW90 = Math.min(current.maxW30 || 0, info60.maxW30 || 0, info90.maxW30 || 0);
                current.msS90 = Math.min(current.msS30 || 0, info60.msS30 || 0, info90.msS30 || 0);
                current.msW90 = Math.min(current.msW30 || 0, info60.msW30 || 0, info90.msW30 || 0);
                current.maxW90iw = Math.min(current.maxW30iw || 0, info60.maxW30iw || 0, info90.maxW30iw || 0);
                current.msW90iw = Math.min(current.msW30iw || 0, info60.msW30iw || 0, info90.msW30iw || 0);

                current.maxS120 = Math.min(current.maxS30 || 0, info60.maxS30 || 0, info90.maxS30 || 0, info120.maxS30 || 0);
                current.maxW120 = Math.min(current.maxW30 || 0, info60.maxW30 || 0, info90.maxW30 || 0, info120.maxW30 || 0);
                current.msS120 = Math.min(current.msS30 || 0, info60.msS30 || 0, info90.msS30 || 0, info120.msS30 || 0);
                current.msW120 = Math.min(current.msW30 || 0, info60.msW30 || 0, info90.msW30 || 0, info120.msW30 || 0);
                current.maxW120iw = Math.min(current.maxW30iw || 0, info60.maxW30iw || 0, info90.maxW30iw || 0, info120.maxW30iw || 0);
                current.msW120iw = Math.min(current.msW30iw || 0, info60.msW30iw || 0, info90.msW30iw || 0, info120.msW30iw || 0);
            }
        });
    });

    // Example of returned object
    // groupedAvailability = {
    //     2021_04_07: {
    //         1: {
    //             blockList: [array of availability blocks]
    //             seatingInfo: {
    //                 maxS30: 0,
    //                 maxW30: 0,
    //                 msS30: 0,
    //                 msW30: 0,

    //                 maxS60: 0
    //                 maxW60: 0
    //                 msS60: 0
    //                 msW60: 0
    
    //                 maxS90: 0
    //                 maxW90: 0
    //                 msS90: 0
    //                 msW90: 0
    
    //                 maxS120: 0
    //                 maxW120: 0
    //                 msS120: 0
    //                 msW120: 0
    //             }
    //         }
    //         2: {
    //             blockList: [...]
    //             seatingInfo: {...}
    //         }
    //     },
    //     2021_04_08: {
    //         1: { ... },
    //         2: { ... },
    //         3: { ... }
    //     }
    // }
    return groupedAvailability;
}