import React from 'react';

import { SVGIcon } from '../custom-essentials';
import { formatDateFull } from '../functions';
import { TooltipWrapper } from '../display';

export function getAlerts(appointment, hideAlerts = {}){
    const student = appointment.studentInfo;

    let weightIcon = null;
    let durationIcon = null;
    let specialIcon = null;
    let specialIcon2 = null;
    let specialIcon3 = null;
    let subjectIcon = null;
    let fingerCountingIcon = null;
    let strugglesWithComputationIcon = null;
    let advancedStudentIcon = null;
    let upcomingExamsIcon = null;
    let pendingFlagIcon = null;
    let notOnlineReady = null;
    let isSwitch = null;
    let binderNotReady = null;

    if(appointment.weight > 1 && !hideAlerts.weight){
        if(appointment.weight > 3){
            weightIcon = (
                <TooltipWrapper
                    tooltipText={'Weight: 4'}
                >
                    <div className="mr-1 flex flex-row">
                        <SVGIcon fileName="weight-red"/>
                        <SVGIcon fileName="weight-red"/>
                        <SVGIcon fileName="weight-red"/>
                        <SVGIcon fileName="weight-red"/>
                    </div>
                </TooltipWrapper>
            );
        } else if(appointment.weight > 2){
            weightIcon = (
                <TooltipWrapper
                    tooltipText={'Weight: 3'}
                >
                    <div className="mr-1 flex flex-row">
                        <SVGIcon fileName="weight-red"/>
                        <SVGIcon fileName="weight-red"/>
                        <SVGIcon fileName="weight-red"/>
                    </div>
                </TooltipWrapper>
            );
        } else if(appointment.weight > 1){
            weightIcon = (
                <TooltipWrapper
                    tooltipText={'Weight: 2'}
                >
                    <div className="mr-1 flex flex-row">
                        <SVGIcon fileName="weight-red"/>
                        <SVGIcon fileName="weight-red"/>
                    </div>
                </TooltipWrapper>
            );
        }
    }
    if(!hideAlerts.duration){
        if(appointment.duration === 30){
            durationIcon = (
                <TooltipWrapper
                    tooltipText={`Appointment Length: ${appointment.duration} minutes`}
                >
                    <div className="mr-1">
                        <SVGIcon fileName="clock-green"/>
                    </div>
                </TooltipWrapper>
            );
        } else if(appointment.duration > 60){
            if(appointment.duration > 90){
                durationIcon = (
                    <TooltipWrapper
                        tooltipText={`Appointment Length: ${appointment.duration} minutes`}
                    >
                        <div className="mr-1 flex flex-row">
                            <SVGIcon fileName="clock-red"/>
                            <SVGIcon fileName="clock-red"/>
                        </div>
                    </TooltipWrapper>
                );
            }
            else durationIcon = (
                <TooltipWrapper
                    tooltipText={`Appointment Length: ${appointment.duration} minutes`}
                >
                    <div className="mr-1">
                        <SVGIcon fileName="clock-red"/>
                    </div>
                </TooltipWrapper>
            );
        }
    }
    if(appointment.special_notes && !hideAlerts.specialNotes){
        specialIcon = (
            <TooltipWrapper
                tooltipText={`${appointment.special_notes}`}
            >
                <div className="mr-1">
                    <SVGIcon fileName="exclamation-red"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(appointment.special_notes_2 && !hideAlerts.specialNotes2){
        specialIcon2 = (
            <TooltipWrapper
                tooltipText={`${appointment.special_notes_2}`}
            >
                <div className="mr-1">
                    <SVGIcon fileName="exclamation-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(appointment.special_notes_schedule && !hideAlerts.specialNotesSchedule){
        specialIcon3 = (
            <TooltipWrapper
                tooltipText={`${appointment.special_notes_schedule}`}
            >
                <div className="mr-1">
                    <SVGIcon fileName="exclamation-green"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(appointment.center === 99 && student && !student.mp_online_ready && !hideAlerts.onlineReady){
        notOnlineReady = (
            <TooltipWrapper
                tooltipText="Student is scheduled for an online appointment but is not marked as online ready"
            >
                <div className="mr-1">
                    <SVGIcon fileName="computer-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(student && student.mp_is_switch && !hideAlerts.switch){
        notOnlineReady = (
            <TooltipWrapper
                tooltipText="Switch student"
            >
                <div className="mr-1">
                    <SVGIcon fileName="sync-red"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(student.mp_binder_location !== appointment.center && !hideAlerts.binderLocation){
        binderNotReady = (
            <TooltipWrapper
                tooltipText={`Appointment is scheduled at ${appointment.centerName || `No center name provided (ID ${appointment.center})`}
                    but t>
                    <div className="mr-1">
                    <SVGIcon fileName="folder-open-blue"/>
                </div></TooltipWrapper> student's binder is at ${student.binderLocationName || `No binder location name provided (ID ${student.mp_binder_location})`}`}
            />  
        );
    }
    if(student.mp_subject && !hideAlerts.subject){
        subjectIcon = (
            <TooltipWrapper
                tooltipText={student.mp_subject}
            >
                <div className="mr-1">
                    <SVGIcon fileName="shapes-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(parseInt(student.mp_finger_counting) && !hideAlerts.fingerCounting){
        fingerCountingIcon = (
            <TooltipWrapper
                tooltipText="Finger Counting"
            >
                <div className="mr-1">
                    <SVGIcon fileName="finger-counting-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(parseInt(student.mp_struggles_with_computation) && !hideAlerts.strugglesWithComputation){
        strugglesWithComputationIcon = (
            <TooltipWrapper
                tooltipText="Struggles with Computation"
            >
                <div className="mr-1">
                    <SVGIcon fileName="calculator-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(parseInt(student.mp_advanced_student) && !hideAlerts.advancedStudent){
        advancedStudentIcon = (
            <TooltipWrapper
                tooltipText="Advanced Student"
            >
                <div className="mr-1">
                    <SVGIcon fileName="chart-positive-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    const ue = student.upcomingExams || {};
    if(parseInt(ue.has_exams) === 1 && !hideAlerts.upcomingExams){
        upcomingExamsIcon = (
            <TooltipWrapper
                tooltipText={`Upcoming Exams: ${ue.notes || 'No notes recorded'} (Last Updated ${formatDateFull(ue.date_updated)})`}
            >
                <div className="mr-1">
                    <SVGIcon fileName="upcoming-exams-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(Object.keys(appointment.pendingFlag).length !== 0){
        const pf = appointment.pendingFlag;
        pendingFlagIcon = (
            <TooltipWrapper
                tooltipText={
                    <>
                        <div>This student currently has at least one pending flag. Details of the most recent pending flag are shown below.</div>
                        <br/>
                        <div><b>Tag:</b> {pf.tag || <span className="mp-text-mpOrange">None</span>}</div>
                        <div><b>Center:</b> {pf.centerName}</div>
                        <div><b>Notes:</b> {pf.notes || <span className="mp-text-mpOrange">None</span>}</div>
                        <div><b>Admin Notes:</b> {pf.admin_notes || <span className="mp-text-mpOrange">None</span>}</div>
                        <div><b>Created:</b> {formatDateFull(pf.date_created)} by {pf.createdByName}</div>
                        <div>
                            <b>Updated:</b> {
                            pf.updated_by ? `${formatDateFull(pf.date_updated)} by ${pf.updatedByName}`
                            : <span className="mp-text-mpOrange">Never</span>}
                        </div>
                    </>
                }
            >
                <div className="mr-1">
                    <SVGIcon fileName="flag-red"/>
                </div>
            </TooltipWrapper>
        );
    }

    if(weightIcon === null && durationIcon === null && specialIcon === null &&
        specialIcon2 === null && specialIcon3 === null && notOnlineReady === null &&
        isSwitch === null && binderNotReady === null && subjectIcon === null &&
        fingerCountingIcon === null && strugglesWithComputationIcon === null &&
        advancedStudentIcon === null && upcomingExamsIcon === null &&
        pendingFlagIcon === null && !hideAlerts.noAlerts) return 'None';
    return(
        <div className=" flex flex-row">
            {weightIcon} {durationIcon} {specialIcon} {specialIcon2} {specialIcon3}
            {subjectIcon} {fingerCountingIcon} {strugglesWithComputationIcon} {advancedStudentIcon}
            {upcomingExamsIcon} {pendingFlagIcon} {notOnlineReady} {isSwitch} {binderNotReady}
        </div>
    );
}