import React from 'react';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper, tableColors } from '../../../../../components/display';
import { formatDateFull } from '../../../../../components/functions';

export function getColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [
        {
            dataField: 'date_updated',
            text: 'Last Updated',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (bDate - aDate) * swap;
            },
            formatter: (row) => {
                if(!row.date_updated) return <div className="text-mpOrange">Never</div>
                return (
                    <TooltipWrapper
                        tooltipText={<div>by {row.updatedByName}</div>}
                    >
                        <div className="text-mpLBlue">{formatDateFull(row.date_updated)}</div>
                    </TooltipWrapper>
                )
            }
        },
        {
            dataField: 'studentName',
            text: 'Student Name',
            sort: true
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => {
                if(!row.notes) return <div className="text-mpOrange">None</div>;
                return <div>{row.notes}</div>;
            }
        },
        {
            dataField: 'has_exams',
            text: 'Has Exams?',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(b) - parseInt(a)) * swap;
            },
            formatter: (row) => parseInt(row.has_exams) === 1 ? 
                <Label color="mpLRed">Yes</Label>
                : <Label color="mpGreen">No</Label>,
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(colorMap[center]) centerColor = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    centerColor = colorMap[center];
                }
                return (
                    <div className="mr-2">
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <TooltipWrapper
                        tooltipText={`Edit entry`}
                    >
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            onClick={() => handleShowModal(row)}
                        >
                            <SVGIcon fileName="pencil"/>
                        </Button>
                    </TooltipWrapper>
                );
            }
        }
    ];
}