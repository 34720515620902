import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { formatDate, formatDateApi } from '../../functions';
import { TooltipWrapper } from '../../display';
import { DateRangeSelector, SelectSingle, FormikControl, Check } from '../../form';
import { validationSchema, getInitStudentOption, typeOptions, getInitTypeOption,
    paymentFreqOptions, getInitPaymentFreqOption, renderSubmitting } from './helpers';

const startApi = formatDateApi(new Date());
const endDate = new Date();
endDate.setMonth(endDate.getMonth() + 6);
endDate.setDate(endDate.getDate() - 1);
const endApi = formatDateApi(endDate);

function ContractsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, submissionStatus, selectedContract, selectedStudent, currentContractId,
        studentOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const sc = selectedContract;
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                }}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? renderSubmitting(submissionStatus, formik.setSubmitting) : 
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this contract?</h4>
                            <div className="text-mpLRed">
                                All linked payments will be deleted
                            </div>
                            <br/>
                            <h4 className="text-mpOrange">
                                This cannot be undone.
                            </h4>
                            <div className="h-2 clear-both"/>
                            <div className="grid grid-cols-1 gap-y-2">
                                <div>
                                    {sc.studentName}
                                </div>
                                <div>
                                    {formatDate(sc.start_date)} to&nbsp;
                                    {parseInt(sc.expires) === 1 ? formatDate(sc.end_date) : 
                                    'infinity and beyond (contract does not expire)'}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                { !formik.values.firstConfirm ? (
                                    <div className="flex flex-row gap-x-2">
                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            onClick={() => formik.setFieldValue('firstConfirm', true)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                    )
                                :
                                    (
                                        <div className="flex flex-row gap-x-2">
                                            <div className="mr-auto">
                                                <Button
                                                    color="hol-mpLRed"
                                                    disabled={formik.isSubmitting}
                                                    onClick={(e) => {
                                                        formik.setFieldValue('firstConfirm', false);
                                                        formik.handleSubmit(e)
                                                    }}
                                                >
                                                    Confirm Delete
                                                </Button>
                                                <Button
                                                    color="lte-mpLBlue"
                                                    disabled={formik.isSubmitting}
                                                    onClick={() => {
                                                        formik.setStatus('');
                                                        formik.setFieldValue('firstConfirm', false);
                                                    }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                            <div
                                                className="text-mpOrange flex items-center"
                                            >
                                                Are you sure?
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        const ccNotif = parseInt(selectedContract.id) === parseInt(currentContractId) ?
            <div>This contract is {selectedContract.studentName}'s current contract!</div> : null;
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    student: getInitStudentOption(selectedContract.student || selectedStudent.user_id, studentOptions),
                    setAsCurrent: ['create-special', 'create'].includes(mode) ? true : false,
                    type: getInitTypeOption(selectedContract.type),
                    startDate: formatDateApi(selectedContract.start_date || startApi),
                    endDate: formatDateApi(selectedContract.end_date || endApi),
                    expires: parseInt(selectedContract.expires) === 0 ? false : true,
                    paymentFrequency: getInitPaymentFreqOption(selectedContract.payment_frequency),
                    paymentDateOffset: selectedContract.payment_date_offset || 0,
                    initialPayment: selectedContract.initial_payment || 0,
                    // Need the first part since if selectedContract.amount_per_payment === 0, evaluates to false and sets amount to 300
                    amountPerPayment: isNaN(selectedContract?.amount_per_payment) ? 300 : selectedContract.amount_per_payment,
                    notes: selectedContract.notes || '',
                    showWarnings: selectedContract.show_warnings === 0 ? false : true,
                    warnMembers: selectedContract.warn_members === 1 ? true : false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? renderSubmitting(submissionStatus, formik.setSubmitting) : 
                        <ModalBodyFooter>
                            <Modal.Body>
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Student</h4>
                                        <SelectSingle
                                            id="contracts-student"
                                            name="student"
                                            disabled={!['create', 'create-special'].includes(mode)}
                                            value={formik.values.student}
                                            onChange={formik.handleChange}
                                            options={studentOptions}
                                        />
                                        {!['create', 'create-special'].includes(mode) && 
                                            <div className="text-mpOrange" style={{ fontSize: "10pt" }}>
                                                Student cannot be modified for existing contracts
                                            </div>
                                        }
                                        {formik.errors.student ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.student}
                                            </ErrorMessage>
                                        ) : null}
                                        {ccNotif}
                                        <TooltipWrapper
                                            tooltipText="If checked, this contract will be set as the student's current contract when the form is submitted. By default, this will always be unchecked when opening the form."
                                        >
                                            <Check
                                                id="contracts-set-as-current"
                                                name="setAsCurrent"
                                                color="mpLRed"
                                                label={<div className="text-mpLBlue">Set As Current Contract</div>}
                                                disabled={formik.values.type.value === 'Extra'}
                                                checked={formik.values.setAsCurrent}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                        {formik.values.setAsCurrent && 
                                            <div className="text-mpOrange text-xs">
                                                Warning! This will overwrite the student's existing current contract, if one exists.
                                            </div>
                                        }
                                        {formik.values.type.value === 'Extra' && 
                                            <div className="text-mpOrange">
                                                "Extra" contracts cannot be set as a student's current contract.
                                            </div>
                                        }
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Contract Type</h4>
                                        <SelectSingle
                                            id="contracts-type"
                                            name="type"
                                            value={formik.values.type}
                                            onChange={(e) => {
                                                const newValues = { ...formik.values };

                                                const typeOption = e.target.value;
                                                const isNewExtra = typeOption.value === 'Extra';
                                                const setAsCurrent = isNewExtra ? false : formik.values.setAsCurrent;
                                                const paymentFrequency = isNewExtra ? { value: 'One Time', label: 'One Time' } : formik.values.paymentFrequency;
                                                const paymentDateOffset = isNewExtra ? 0 : formik.values.paymentDateOffset;
                                                const amountPerPayment = isNewExtra ? 0 : formik.values.paymentDateOffset;

                                                newValues.type = typeOption;
                                                newValues.setAsCurrent = setAsCurrent;
                                                newValues.paymentFrequency = paymentFrequency;
                                                newValues.paymentDateOffset = paymentDateOffset;
                                                newValues.amountPerPayment = amountPerPayment;
                                                formik.setValues(newValues);
                                            }}
                                            options={typeOptions}
                                        />
                                        {formik.errors.type ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.type}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Payment Freq.</h4>
                                        <SelectSingle
                                            id="contracts-payment-frequency"
                                            name="paymentFrequency"
                                            value={formik.values.paymentFrequency}
                                            disabled={formik.values.type.value === 'Extra'}
                                            onChange={formik.handleChange}
                                            options={paymentFreqOptions}
                                        />
                                        {formik.errors.paymentFrequency ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.paymentFrequency}
                                            </ErrorMessage>
                                        ) : null}
                                        {formik.values.type.value === 'Extra' ? (
                                            <div className="text-mpOrange">
                                                Payment Frequency must be "One Time" for "Extra" contracts
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <DateRangeSelector
                                            id="contract-form-drs"
                                            startName="startDate"
                                            endName="endDate"
                                            startLabel="Start Date"
                                            endLabel="End Date"
                                            startValue={formik.values.startDate}
                                            endValue={formik.values.endDate}
                                            defaultValid={true}
                                            onStartChange={formik.handleChange}
                                            onEndChange={formik.handleChange}
                                            useCustomValidation={true}
                                        />
                                        {formik.errors.drsValid ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.drsValid}
                                            </ErrorMessage>
                                        ) : null}
                                        <Check
                                            id="contracts-expires"
                                            name="expires"
                                            color="mpLRed"
                                            label={
                                                <TooltipWrapper
                                                    tooltipText="If disabled, the contract end date will be ignored"
                                                >
                                                    <div className="text-mpLBlue">Should contract expire?</div>
                                                </TooltipWrapper>
                                            }
                                            checked={formik.values.expires}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>
                                                        Optional. Initial payment made at the start of the contract.
                                                        This will be added to the contract's expected total.
                                                    </div>
                                                </>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Initial Payment</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="contracts-initial-payment"
                                            name="initialPayment"
                                            value={formik.values.initialPayment}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 0, Max: 30000"
                                        />
                                        {formik.errors.initialPayment ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.initialPayment}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>Expected amount per payment</div>
                                                    <br/>
                                                    <div>For monthly contracts, this should be the monthly tuition.</div>
                                                    <br/>
                                                    <div>For up front contracts, this should be the lump sum.</div>
                                                </>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Amount Per Payment</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="contracts-amount-per-payment"
                                            name="amountPerPayment"
                                            value={formik.values.amountPerPayment}
                                            disabled={formik.values.type.value === 'Extra'}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 0, Max: 30000"
                                        />
                                        {formik.errors.amountPerPayment ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.amountPerPayment}
                                            </ErrorMessage>
                                        ) : null}
                                        {formik.values.type.value === 'Extra' ? (
                                            <div className="text-mpOrange">
                                                Amount Per Payment must be 0 for "Extra" contracts. Please use "Initial Payment" instead.
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>The number of days that the payment due date should be pushed back.</div>
                                                    <br/>
                                                    <div>
                                                        When calculating the expected payment amount on a contract,
                                                        the start date will effectively be pushed back by this many days.
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        For example, a contract starting on April 1
                                                        with an "Amount Per Payment" of $300 would be expected to
                                                        have $300 in payments between April 1-30, and $600 in payments
                                                        between May 1-31. If a payment offset of 10 days is added, $300
                                                        will be expected between April 11 to May 10, and $600 will be expected
                                                        between May 11 and June 10.
                                                    </div>
                                                </>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Payment Date Offset (Days)</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="contracts-payment-date-offset"
                                            name="paymentDateOffset"
                                            value={formik.values.paymentDateOffset}
                                            disabled={formik.values.type.value === 'Extra'}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 0, Max: 30000"
                                        />
                                        {formik.errors.paymentDateOffset ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.paymentDateOffset}
                                            </ErrorMessage>
                                        ) : null}
                                        {formik.values.type.value === 'Extra' ? (
                                            <div className="text-mpOrange">
                                                Payment Date Offset must be 0 for "Extra" contracts
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Notes</h4>
                                        <FormikControl
                                            id="contracts-notes"
                                            as="textarea"
                                            name="notes"
                                            value={formik.values.notes}
                                            onChange={formik.handleChange}
                                            placeholder="Max. 1000 characters."
                                        />
                                        {formik.errors.notes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.notes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <Check
                                            id="contracts-show-warnings"
                                            name="showWarnings"
                                            color="mpLRed"
                                            label="Show warnings 1 month before expiration"
                                            checked={formik.values.showWarnings}
                                            onChange={formik.handleChange}
                                        />
                                        <Check
                                            id="contracts-warn-members"
                                            name="warnMembers"
                                            color="mpLRed"
                                            label="Warn Parent/Student 1 month before expiration"
                                            checked={formik.values.warnMembers}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                        (
                                            <div className="text-mpLRed">
                                                One or more fields needs to be corrected.
                                            </div>
                                        ) : null
                                    }
                                    {formik.isSubmitting ?
                                        <ModalProcessing/> : null
                                    }
                                    {formik.status && !formik.isSubmitting ? 
                                        <div className="text-mpLRed">
                                            {formik.status}
                                        </div> : null
                                    }
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                        disabled={formik.isSubmitting}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={formik.handleSubmit}
                                        disabled={formik.isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(ContractsBodyFooter);