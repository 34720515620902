import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    binderLocation: Yup.mixed().test(
        'binderLocationIsSeleceted',
        'Select a valid center',
        (value) => value?.value !== -1
    ),
});

export function getInitBinderLocationOption(location, centerOptions){
    const centerOption = centerOptions.find(c => parseInt(c.value) === parseInt(location));

    return centerOption || { value: -1, label: 'Invalid center...' };
}