export function appendContractBalances(contracts, payments){
    if(!contracts || !payments) return [{}];

    // Get a list of all relevant payments for each contract for quick lookup
    const contractPaymentMap = {};
    payments.forEach(p => {
        const contractId = parseInt(p.contract);
        if(!contractPaymentMap[contractId]) contractPaymentMap[contractId] = [p];
        else contractPaymentMap[contractId].push(p);
    });

    const now = new Date();
    return contracts.map(c => {
        c.balanceOwed = c.initial_payment;

        // Calculate amount that should be paid
        if(c.payment_frequency === 'One Time'){
            c.balanceOwed += c.amount_per_payment;
        } else if(c.payment_frequency === 'Monthly'){
            const start = new Date(c.start_date);
            start.setDate(start.getDate() + c.payment_date_offset);
            const end = new Date(c.end_date);
            const latestDate = now > end ? end : now;
            let monthsDiff = 
                (latestDate.getFullYear() - start.getFullYear()) * 12 + 
                (latestDate.getMonth() - start.getMonth());
            if(latestDate.getDate() >= start.getDate()) monthsDiff += 1;

            c.balanceOwed += monthsDiff * c.amount_per_payment;
        } else if(c.payment_frequency === 'Weekly'){
            const start = new Date(c.start_date);
            start.setDate(start.getDate() + c.payment_date_offset);
            const end = new Date(c.end_date);
            const latestDate = now > end ? end : now;
            let weeksDiff = Math.ceil(((latestDate - start) / 7) / (1000 * 60 * 60 * 24));

            c.balanceOwed += weeksDiff * c.amount_per_payment;
        } else c.balanceOwed = 100000;

        // Calculate the amount that has been paid
        const relPayments = contractPaymentMap[parseInt(c.id)] || [];
        let newBalance = 0;
        relPayments.forEach(p => newBalance += parseInt(p.amount));
        c.balancePaid = newBalance;

        c.relevantPayments = relPayments.sort((a, b) => {
            let aDate = new Date(a.date);
            let bDate = new Date(b.date);
            return bDate - aDate;
        });

        return c;
    });
}