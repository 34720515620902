import React, { useRef, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import WarningsTable from './warnings/WarningsTable';
import { checkResponses } from '../../../../components/form';

import {
    fetchStudentsActive,
    fetchContractsActiveStudents,
} from '../../../../actions';

function Warnings(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { loading, setLoading, setNWarnings, fetchStudentsActive, fetchContractsActiveStudents } = props;

    const [hasLoaded, setHasLoaded] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [relevantStudents, setRelevantStudents] = useState([]);

    const refreshData = useCallback(() => {
        (async function(){
            if(loading.warnings) return;
            if(mounted.current) setLoading({ ...loading, warnings: true });

            const studentsRes = await fetchStudentsActive();
            const contractsRes = await fetchContractsActiveStudents();
            const isApiError = checkResponses(contractsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading({ ...loading, warnings: false });
                    setHasLoaded(true);
                }
                return;
            } else setApiError(false);
            
            const newStudents = studentsRes.data || [];
            const newContracts = contractsRes.data || [];

            const contractMap = {};
            newContracts.forEach(c => contractMap[parseInt(c.id)] = c);

            // Check for nonexistent or expired contracts
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            const inOneMonth = new Date();
            inOneMonth.setMonth(inOneMonth.getMonth() + 1);
            inOneMonth.setHours(0, 0, 0, 0);

            let messageSortIndex = 1;
            const studentsAppended = newStudents.map(s => {
                s.studentName = `${s.first_name} ${s.last_name}`

                const currentContract = contractMap[parseInt(s.mp_current_contract)];
                if(!currentContract){
                    s.noContract = true;
                } else {
                    s.currentContract = currentContract;
    
                    const ccEndDate = new Date(currentContract.end_date);
                    ccEndDate.setHours(0, 0, 0, 0);
                    s.currentContractEndDate = ccEndDate;
                    if(ccEndDate < now) s.contractExpired = true;
                    else if(parseInt(currentContract.show_warnings) === 1 && ccEndDate < inOneMonth) s.endingSoon = true;
                }

                return s.noContract || s.contractExpired || s.endingSoon ? s : -1;
            }).filter(s => {
                return parseInt(s.mp_active) === 1 && parseInt(s.is_mp_student) === 1;
            }).sort((a, b) => {
                if(!a.currentContractEndDate) return -1;
                if(!b.currentContractEndDate) return 1;
                else return a.currentContractEndDate - b.currentContractEndDate;
            }).map(s => {
                s.messageSortIndex = messageSortIndex;
                messageSortIndex++;
                return s;
            });

            if(mounted.current){
                setRelevantStudents(studentsAppended);
                setNWarnings(studentsAppended.length);
                setHasLoaded(true);
                setLoading({ ...loading, warnings: false });
            }
        })();
    }, [loading, setLoading, setNWarnings, fetchStudentsActive, fetchContractsActiveStudents]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2>Student Account Warnings</h2>

            <br/>
            
            {apiError ? <div className="text-mpLRed">{apiError}</div> :
            hasLoaded && 
                <WarningsTable
                    students={relevantStudents}
                    refreshData={refreshData}
                />
            }
        </div>
    );
}

export default connect(null, {
    fetchStudentsActive,
    fetchContractsActiveStudents,
})(Warnings);