import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, checkResponses } from '../../../../components/form';
import { Table } from '../../../../components/table';
import { LoadingOverlay } from '../../../../components/display';
import { PayPeriodSelector } from './PayPeriodSelector/PayPeriodSelector';
import { formatDateApi, getPayPeriod } from '../../../../components/functions/dates';
import { getColumnsAttendanceHistory } from './table/getColumns';
import { getHours } from './timesheetsTable/getHours';

import {
    fetchInstructorAttendanceDaterangeUserIds,
} from '../../../../actions';

const [startDate, endDate] = getPayPeriod(new Date());
const startApi = formatDateApi(startDate);
const endApi = formatDateApi(endDate);

const columns = getColumnsAttendanceHistory();
const defaultSorted = { dataField: 'date', order: 'desc' };

function AttendanceHistory(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // DateRangeSelector
    const [valid, setValid] = useState(true);
    // Data
    const [attendanceData, setAttendanceData] = useState([]);

    const { userId, refreshCount, fetchInstructorAttendanceDaterangeUserIds } = props;

    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading || !formRef.current.values || !valid) return;
            setLoading(true);

            const { startDate, endDate } = formRef.current.values;
            
            const singleDataRes = await fetchInstructorAttendanceDaterangeUserIds({
                startDate: startDate,
                endDate: endDate,
                userIds: [userId]
            });
            const isApiError = checkResponses(singleDataRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newSingleData = singleDataRes.data || [];
    
            if(mounted.current){
                // Never the "origin" since it doesn't create data
                setAttendanceData(newSingleData);
                setLoading(false);
            }
        })();
    }, [userId, fetchInstructorAttendanceDaterangeUserIds,
        formRef, valid, loading, setLoading]);
    useEffect(() => {
        // Monitor for changes on the other two components
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCount.attendanceHistory]);

    return (
        <div>
            {loading && <LoadingOverlay/>}
            <div className="flex flex-row gap-x-4 items-center">
                <h2>
                    Attendance History
                </h2>
                <div>
                    {getHours(attendanceData)}
                </div>
            </div>

            <br/>
            
            <Formik
                enableReinitialize
                initialValues={{
                    startDate: startApi,
                    endDate: endApi,
                    payPeriod: { value: -1, label: 'Select a pay period...' }
                }}
                onSubmit={refreshData}
                innerRef={formRef}
            >
                {formik => (
                    <div className="flex flex-row gap-x-4 items-center">
                        <div className="grid grid-cols-1 gap-y-2">
                            <DateRangeSelector
                                id="attendanceHistory-drs-1"
                                startName="startDate"
                                endName="endDate"
                                startLabel="Start Date"
                                endLabel="End Date"
                                startValue={formik.values.startDate}
                                endValue={formik.values.endDate}
                                defaultValid={true}
                                onStartChange={formik.handleChange}
                                onEndChange={formik.handleChange}
                                onChangeValidation={setValid}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-5/12">
                            <PayPeriodSelector
                                formik={formik}
                                refreshData={refreshData}
                                setValid={setValid}
                                id="attendanceHistory-payPeriodSelector"
                            />
                        </div>
                        <div>
                            <Button
                                color="lte-mpTeal"
                                onClick={formik.handleSubmit}
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                )}
            </Formik>

            <div className="h-8 clear-both"/>

            {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                <Table
                    tableName="attendanceHistory"
                    columns={columns}
                    defaultSorted={defaultSorted}
                    data={attendanceData}
                    emptyMessage="No attendance history was found"
                />
            }

        </div>
    )
}

export default connect(null, {
    fetchInstructorAttendanceDaterangeUserIds,
})(AttendanceHistory);