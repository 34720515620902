import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { checkResponse } from '../../form';
import HoldsLogBodyFooter from './HoldsLogBF';

import {
    fetchMpCentersAll,
    fetchStudentsActive,
    fetchAdminUsersIds,
    fetchHoldsLogsIds,
    createHoldsLog,
    updateHoldsLog,
    deleteHoldsLog
} from '../../../actions';

function HoldsLogModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [studentOptions, setStudentOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [holdsLog, setHoldsLog] = useState({});

    const { mode, selectedLog, onSubmitCallback, fetchMpCentersAll, fetchStudentsActive,
        fetchAdminUsersIds, fetchHoldsLogsIds, createHoldsLog, updateHoldsLog, deleteHoldsLog } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsActive();
            const centersRes = await fetchMpCentersAll();
            const newStudents = studentsRes.data || [];
            const newCenters = centersRes.data || [];

            const newStudentOptions = newStudents.filter(s => {
                return (parseInt(s.mp_active) === 1 && parseInt(s.is_mp_student) === 1) || selectedLog?.student === s.user_id;
            }).map(s => ({ value: s.user_id, label: `${s.first_name} ${s.last_name}`, object: s }));
            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));
            
            if(mode !== 'create'){
                const holdsLogRes = await fetchHoldsLogsIds({ ids: [selectedLog.id] });
                const newHoldsLog = holdsLogRes.data?.[0] || {};

                if(newHoldsLog.updated_by && newHoldsLog.date_updated){
                    const employeeRes = await fetchAdminUsersIds({ ids: [newHoldsLog.updated_by] });
                    const newEmployee = employeeRes.data?.[0] || {};

                    const student = newStudents.find(s => s.user_id === newHoldsLog.student) || {};
                    newHoldsLog.studentName = student.first_name ? `${student.first_name} ${student.last_name}` :
                        `Unknown student (UID: ${newHoldsLog.student})`;
                    const center = newCenters.find(c => parseInt(c.id) === parseInt(newHoldsLog.center)) || {};
                    newHoldsLog.centerName = center.name || `Unknown center (ID: ${newHoldsLog.center})`;
                    newHoldsLog.updatedByName = newEmployee ? `${newEmployee.first_name} ${newEmployee.last_name}` :
                        `Unknown admin user (ID: ${newHoldsLog.updated_by})`;
                }
                if(mounted.current) setHoldsLog(newHoldsLog);
            }
            
            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setCenterOptions(newCenterOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const holdsLogParams = {
                    center: values.selectedCenter.value,
                    student: values.selectedStudent.value,
                    notes: values.notes,
                };
                
                if(mode === 'create'){
                    response = await createHoldsLog(holdsLogParams);
                } else if(mode === 'edit') {
                    holdsLogParams.id = holdsLog.id;
                    response = await updateHoldsLog(holdsLogParams);
                }
            } else if(mode === 'delete') {
                response = await deleteHoldsLog({ id: holdsLog.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, holdsLog, createHoldsLog, updateHoldsLog, deleteHoldsLog]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Holds Log Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <HoldsLogBodyFooter
                    mode={mode}
                    selectedLog={holdsLog}
                    studentOptions={studentOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMpCentersAll,
    fetchStudentsActive,
    fetchAdminUsersIds,
    fetchHoldsLogsIds,
    createHoldsLog,
    updateHoldsLog,
    deleteHoldsLog
})(HoldsLogModal);