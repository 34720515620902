import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nPendingFlags: 0
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNPendingFlags(state, action){
            state.nPendingFlags = action.payload.nPendingFlags
        }
    }
});