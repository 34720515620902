import { basicPath } from '../basicPath';

export const fetchAssessmentsId = (params) => async (dispatch) => {
    const path = '/mp/assessments/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAssessmentsDaterangeCenter = (params) => async (dispatch) => {
    const path = '/mp/assessments/daterange-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAssessmentsStudentIds = (params) => async (dispatch) => {
    const path = '/mp/assessments/student-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createAssessment = (params) => async (dispatch) => {
    const path = '/mp/assessments';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAssessment = (params) => async (dispatch) => {
    const path = '/mp/assessments';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAssessment = (params) => async (dispatch) => {
    const path = '/mp/assessments';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}