import React, { useState, useRef, useEffect } from 'react';

import { Table } from '../../../../../components/table';
import { getColumnsTimesheets } from '../table/getColumns';

const defaultSorted = { dataField: 'date', order: 'desc' };

export function TimeSheetsTable(props){
    const columns = getColumnsTimesheets(props.handleShowModal);
    const [data, setData] = useState([...props.data]);

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    })

    useEffect(() => {
        setData(JSON.parse(JSON.stringify([...props.data])));
    }, [props]);

    return (
        <Table
            tableName="timesheets"
            columns={columns}
            defaultSorted={defaultSorted}
            data={data}
            sizePerPageInitial={10}
            sizePerPageList={[10, 20, 50, 100]}
            emptyMessage="No attendance entries were found"
        />
    );
}