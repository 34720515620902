import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    lessonId: Yup.string()
        .min(4, 'Min 4 characters')
        .max(10, 'Max 10 characters')
        .required('Lesson ID is required'),
    name: Yup.string()
        .max(200, 'Max 200 characteres')
        .required('Lesson name is required'),
    lessonLength: Yup.number()
        .min(1, 'Min 1')
        .max(50, 'Max 50'),
    defaultSortOrder: Yup.number()
        .min(1, 'Min 1')
        .max(1000000000, 'Max 1,000,000,000')
        .required('Sort Order is required')
});