import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalProcessing, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { SelectSingle } from '../../form';
import { validationSchema, getInitBinderLocationOption } from './helpers';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function TransitionBinderBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedStudent, centerOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        const studentName = selectedStudent.first_name ? `${selectedStudent.first_name} ${selectedStudent.last_name}` :
            `Unknown student (UID: ${selectedStudent.user_id})`;
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    binderLocation: getInitBinderLocationOption(selectedStudent.mp_binder_location, centerOptions),
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div>
                                <h2>{studentName}</h2>
                            </div>
                            <br/>
                            <div className="flex flex-row gap-x-2">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Binder Location</h4>
                                    <SelectSingle
                                        id="binder-transition-option"
                                        name="binderLocation"
                                        value={formik.values.binderLocation}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                    {formik.errors.binderLocation ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.binderLocation}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(TransitionBinderBodyFooter);