import React, { useRef, useEffect } from 'react';

// There's no other way.
const suppressedWarnings = ['child in a list should have a unique'];
const consoleWarn = console.error;
console.error = function filterErrors(msg, ...args){
    if(!suppressedWarnings.some((e) => typeof(msg) === 'string' && msg.includes(e))) consoleWarn(msg, ...args)
}

function TableHeaderCell(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { cellKey, content, sortDirection, shouldSort, dataField, handleRequestSort } = props;

    const sortArrow = (
        <svg className={`h-6 w-6 fill-mpDBlue-5 ${sortDirection === 'asc' ? 'rotate-180' : 'rotate-0'} duration-200`}>
            <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"/>
        </svg>
    );
    const filterIcon = (
        <svg className="flex h-6 w-6 fill-mpDBlue-5">
            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
        </svg>
        
    )

    return (
        <th
            key={cellKey}
            className={`text-left pl-4 ${sortDirection ? 'text-mpDBlue-5' : ''} py-3`}
        >
            <div
                className={`flex flex-row gap-x-1 inline-block ${shouldSort ? 'cursor-pointer' : ''}`}
                onClick={() => shouldSort ? handleRequestSort(dataField) : null}
            >
                {content} {sortDirection ? sortArrow : shouldSort ? filterIcon : null}
            </div>
        </th>
    );
}

export default TableHeaderCell