import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalProcessing, ModalLoading } from '../../custom-essentials';
import { validationSchema, renderBody } from './helpers';
import { formatDateTime, formatDateApi } from '../../functions';

function AssessmentsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, assessment, questions, assessmentTemplateOptions, studentOptions, lessonOptions,
        loaded, submitted, handleClose, attemptingClose, setAttemptingClose, handleSubmit } = props;
        
    const [deleteFirstConfirm, setDeleteFirstConfirm] = useState(false);

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this assessment?</h4>
                            <div className="grid grid-cols-1">
                                <div>Name: {assessment.name}</div>
                                <div>Level: {assessment.level}</div>
                                <div>Student: {assessment.studentName}</div>
                                <div>Created on: {formatDateTime(assessment.date_created)}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {formik.isSubmitting &&
                                <ModalProcessing/>
                            }
                            {formik.status && !formik.isSubmitting ? 
                                <div className="text-mpLRed text-xs">
                                    {formik.status}
                                </div> : null
                            }
                            { deleteFirstConfirm ?
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={formik.handleSubmit}
                                        >
                                        Confirm Delete
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        disabled={formik.isSubmitting}
                                        onClick={() => setDeleteFirstConfirm(false)}
                                    >
                                        Back
                                    </Button>
                                </div>
                                :
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="hol-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={() => setDeleteFirstConfirm(true)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            }
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    compactView: true,
                    letterMode: false,
                    viewMode: mode === 'edit' ? 2 : 1,
                    assessmentTemplate: mode === 'edit' ? { value: -1, label: 'Use existing data' } :
                        { value: -1, label: 'Custom assessment' },
                    name: assessment.name || '',
                    nQuestions: assessment.n_questions || '',
                    student: assessment.initStudentOption || { value: -1, label: `Please select...` },
                    notTaken: assessment.date_taken ? false : true,
                    dateTaken: formatDateApi(assessment.date_taken || new Date()),
                    notes: assessment.notes || '',
                    fileName: assessment.file_name || '',
                    hasFile: parseInt(assessment.has_file) === 1 || false,
                    hasAnswerKey: parseInt(assessment.has_answer_key) === 1 || false,
                    active: parseInt(assessment.active) === 0 ? false : true,
                    questions
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <Modal.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    ) :
                    <ModalBodyFooter>
                        {renderBody(formik, mode, handleClose, assessmentTemplateOptions,
                            studentOptions, lessonOptions)}
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(AssessmentsBodyFooter);