import React from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { getColumns } from './table/getColumns';

const columns = getColumns();
const defaultSorted=[{ dataField: 'cycleDates', order: 'asc' }];

function AttendanceTable(props){
    const { attendances } = props;

    return (
        <div>
            <Table
                tableName="attendance"
                data={attendances}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No attendances were found"
            />
        </div>
    );
}

export default connect(null, {

})(AttendanceTable);