import React from 'react';
import * as Yup from 'yup';

import { Modal, ModalBodyFooter, Button, ModalProcessing } from '../../custom-essentials';
import { TooltipWrapper } from '../../display';
import { FormikControl, renderSubmittingDefault } from '../../form';
import { formatTime, formatDate, formatDateFull } from '../../functions';

export const editorValidationSchema = Yup.object().shape({
    selectedOpenTime:  Yup.mixed().test(
        'openIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
    ).test(
        'openIsHalfHour',
        'Minutes must be either 00 or 30',
        (value) => value.raw % 30 === 0
    ),
    selectedLastStartTime:  Yup.mixed().test(
        'lastIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
    ).test(
        'lastIsHalfHour',
        'Minutes must be either 00 or 30',
        (value) => value.raw % 30 === 0
    ),
    selectedCloseTime:  Yup.mixed().test(
        'closeTimeIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
    ).test(
        'closeIsHalfHour',
        'Minutes must be either 00 or 30',
        (value) => value.raw % 30 === 0
    ),
    timeCheck: Yup.mixed().when(['selectedOpenTime', 'selectedLastStartTime', 'selectedCloseTime'],
        ([selectedOpenTime, selectedLastStartTime, selectedCloseTime]) => {
            const open = selectedOpenTime.raw;
            const lastStart = selectedLastStartTime.raw;
            const close = selectedCloseTime.raw;

            const isValid = open <= lastStart && lastStart < close;
            const errorText = `Last Start Time must be later than or equal to Start Time.
            Close Time must be later than Last Start Time.`
            if(isValid) return Yup.mixed().test('timeCheckValid', '', () => true);
            else return Yup.mixed().test('timeCheckInvalid', errorText, () => false);
        })
    });

export function editorGetInitialValues(relevantAvailability, availabilityBlocks, defaultAvailValues){
    const open = parseInt(relevantAvailability.open_time || defaultAvailValues.open);
    const lastStart = parseInt(relevantAvailability.last_start || defaultAvailValues.lastStart);
    const close = parseInt(relevantAvailability.close_time || defaultAvailValues.close);

    const extraInitialValues = {};
    for(let i = open; i <= lastStart; i += 30){
        const relBlock = availabilityBlocks.find(ab => parseInt(ab.time) === i) || {};
        extraInitialValues[`block${i}maxS`] = relBlock.students || 0;
        extraInitialValues[`block${i}maxW`] = relBlock.seats || 0;
        extraInitialValues[`block${i}msS`] = relBlock.start_students || 0;
        extraInitialValues[`block${i}msW`] = relBlock.start_seats || 0;
        extraInitialValues[`block${i}isOpen`] = true;
    }
    for(let i = lastStart + 30; i < close; i += 30){
        const relBlock = availabilityBlocks.find(ab => parseInt(ab.time) === i) || {};
        extraInitialValues[`block${i}maxS`] = relBlock.students || 0;
        extraInitialValues[`block${i}maxW`] = relBlock.seats || 0;
        extraInitialValues[`block${i}msS`] = 0;
        extraInitialValues[`block${i}msW`] = 0;
        extraInitialValues[`block${i}isOpen`] = true;
    }

    return extraInitialValues;
}

function setAll(formik, start, end, amount, type){
    const fieldsToUpdate = [];
    for(let i = start; i <= end; i += 30) fieldsToUpdate.push(`block${i}${type}`);

    const formikValues = { ...formik.values };
    fieldsToUpdate.forEach(f => formikValues[f] = amount);

    formik.setValues(formikValues);
}
export function editorGetBlockFields(formik){
    if(formik.values.isClosed) return null;

    const open = formik.values.selectedOpenTime.raw;
    const lastStart = formik.values.selectedLastStartTime.raw;
    const close = formik.values.selectedCloseTime.raw;
    const timeSlots = [];
    for(let i = open; i < close; i += 30) timeSlots.push(i);

    return (
        <>          
            <div className="flex flex-row gap-x-4 items-end">
                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                    <TooltipWrapper
                        tooltipText="Click a time to close it off"
                    >
                        <div className="text-mpLBlue">Time</div>
                    </TooltipWrapper>
                </div>
                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                    <div className="flex flex-row gap-x-4 items-end">
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <TooltipWrapper
                                tooltipText="Maximum Students"
                            >
                                <div className="text-mpLBlue">MaxS</div>
                            </TooltipWrapper>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <TooltipWrapper
                                tooltipText="Maximum Seats (Weight)"
                            >
                                <div className="text-mpLBlue">MaxW</div>
                            </TooltipWrapper>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <TooltipWrapper
                                tooltipText="Maximum Starting Students"
                            >
                                <div className="text-mpLBlue">MS (S)</div>
                            </TooltipWrapper>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <TooltipWrapper
                                tooltipText="Maximum Starting Seats (Weight)"
                            >
                                <div className="text-mpLBlue">MS (W)</div>
                            </TooltipWrapper>
                        </div>
                    </div>
                </div>
            </div>

            <br/>

            {timeSlots.map(ts => {
                return !formik.values[`block${ts}isOpen`] ?
                <div key={`availability-editor-custom-block-field-${ts}`} className="text-mpOrange flex flex-row items-end">
                    <div
                        className="table cursor-pointer py-2"
                        onClick={() => {
                            formik.handleChange({ target: { value: true, name: `block${ts}isOpen` } })}
                        }
                    >
                        {formatTime(ts)}: Closed
                    </div>
                    <br/>
                </div>
                :
                <div key={`availability-editor-custom-block-field-${ts}`}>
                    <div className="flex flex-row gap-x-4 py-0.5 items-end">
                        <div
                            className="grid grid-cols-1 gap-y-2 w-1/4 table cursor-pointer text-mpLBlue"
                            onClick={() => {
                                formik.handleChange({ target: { value: false, name: `block${ts}isOpen` } })}
                            }
                        >
                            {formatTime(ts)}:
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-3/4">
                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <FormikControl
                                        id={`availability-editor-custom-block-maxS-${ts}`}
                                        name={`block${ts}maxS`}
                                        value={formik.values[`block${ts}maxS`] === undefined ? 0
                                            : formik.values[`block${ts}maxS`]}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <FormikControl
                                        id={`availability-editor-custom-block-maxW-${ts}`}
                                        name={`block${ts}maxW`}
                                        value={formik.values[`block${ts}maxW`] === undefined ? 0
                                            : formik.values[`block${ts}maxW`]}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <FormikControl
                                        id={`availability-editor-custom-block-msS-${ts}`}
                                        name={`block${ts}msS`}
                                        disabled={ts > lastStart}
                                        value={formik.values[`block${ts}msS`] === undefined || ts > lastStart ? 0
                                            : formik.values[`block${ts}msS`]}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <FormikControl
                                        id={`availability-editor-custom-block-msW-${ts}`}
                                        name={`block${ts}msW`}
                                        disabled={ts > lastStart}
                                        value={formik.values[`block${ts}msW`] === undefined || ts > lastStart ? 0
                                            : formik.values[`block${ts}msW`]}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        formik.values[`block${ts}maxW`] < formik.values[`block${ts}maxS`] ||
                        formik.values[`block${ts}msW`] < formik.values[`block${ts}msS`] ?
                        <div className="text-mpOrange font-sm">
                            Warning! A weight value at {formatTime(ts)} is less than its corresponding student value.
                        </div>
                        :
                        null
                    }
                </div>
            })}

            <br/>
            {/* Last row rendered is used to quickly set ALL rows */}
            <div key={`availability-editor-custom-block-field-quickset`} className="flex flex-row gap-x-4 items-end">
                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                    <TooltipWrapper
                        tooltipText={`Type and amount and click "set" to change the entire column`}
                    >
                        <div className="text-mpLBlue">Quick Set:</div>
                    </TooltipWrapper>
                </div>
                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                    <div className="flex flex-row gap-x-4 items-end">
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <FormikControl
                                id="availability-editor-custom-block-maxS-quickset"
                                name="quicksetMaxS"
                                value={formik.values.quicksetMaxS}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <FormikControl
                                id="availability-editor-custom-block-maxW-quickset"
                                name="quicksetMaxW"
                                value={formik.values.quicksetMaxW}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <FormikControl
                                id="availability-editor-custom-block-msS-quickset"
                                name="quicksetMsS"
                                value={formik.values.quicksetMsS}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <FormikControl
                                id="availability-editor-custom-block-msW-quickset"
                                name="quicksetMsW"
                                value={formik.values.quicksetMsW}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br/>

            <div key={`availability-editor-custom-block-field-quickset-buttons`} className="flex flex-row gap-x-4 items-end">
                <div className="grid grid-cols-1 gap-y-2 w-1/4"/>
                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                    <div className="flex flex-row gap-x-4 items-end">
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <Button
                                className="w-min justify-self-center"
                                color="lte-mpTeal"
                                onClick={() => setAll(formik, open, close - 30, formik.values.quicksetMaxS, 'maxS')}
                            >
                                Set
                            </Button>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <Button
                                className="w-min justify-self-center"
                                color="lte-mpTeal"
                                onClick={() => setAll(formik, open, close - 30, formik.values.quicksetMaxW, 'maxW')}
                            >
                                Set
                            </Button>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <Button
                                className="w-min justify-self-center"
                                color="lte-mpTeal"
                                onClick={() => setAll(formik, open, lastStart, formik.values.quicksetMsS, 'msS')}
                            >
                                Set
                            </Button>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <Button
                                className="w-min justify-self-center"
                                color="lte-mpTeal"
                                onClick={() => setAll(formik, open, lastStart, formik.values.quicksetMsW, 'msW')}
                            >
                                Set
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <hr/>
            <br/>
        </>
    );
}

export function editorRenderSubmitting(submissionStatus, setSubmitting){
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>

                <div className="grid grid-cols-1 gap-y-2">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => setSubmitting(false)}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}

export const bulkValidationSchema = Yup.object().shape({
    selectedFromDate: Yup.mixed().test(
        'fromDateIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
    selectedToDate: Yup.mixed().test(
        'toDateIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
});

export function bulkGetSunSat(selectedDate){
    // Start on a Sunday, end on a Saturday
    const startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - selectedDate.getDay());
    const endDate = new Date(selectedDate);
    endDate.setDate(selectedDate.getDate() + (6 - selectedDate.getDay()));

    return [startDate, endDate];
}

export function bulkRenderToFrom(selectedDate){
    const [year, month, day] = selectedDate.split('-');
    selectedDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    const [startDate, endDate] = bulkGetSunSat(selectedDate);
    return `${formatDate(startDate)} to ${formatDate(endDate)}`
}

export function getDateKeys(sunday){
    const currentDay = new Date(sunday);
    const dateKeys = [];
    for(let i = 0; i < 7; i++){
        dateKeys.push(formatDate(currentDay).replace(/\//g, '_'));
        currentDay.setDate(currentDay.getDate() + 1);
    }
    return dateKeys;
}
export function bulkRenderAvailabilities(sunday, sortedAvails, sortedBlocks, mode, loaded, extraSunday = null){
    if(!loaded) return <div>Click "search" to load</div>;

    const dateKeys = getDateKeys(sunday);
    const extraDateKeys = extraSunday ? getDateKeys(extraSunday) : {};
    let i = 0;

    return (
        <div>
            {dateKeys.map(dk => {
                const avail = sortedAvails[dk];
                const blocks = sortedBlocks[dk];
                
                const extraTitle = extraDateKeys[i] ? 
                    ` -> ${formatDateFull(extraDateKeys[i++].replace(/_/g, '/'))}` : null;
                return(
                    <div key={`availability-bulk-update-${mode}-${dk}`}>
                        <h4>{formatDateFull(dk.replace(/_/g, '/'))}{extraTitle}</h4>
                        {   !avail.id ?
                            <div className="text-mpOrange">No availability set</div>
                            : parseInt(avail.is_closed) === 1 ?
                            <div className="text-mpOrange">Center marked as closed</div>
                            :
                            <>
                                <div className="flex flex-row gap-x-4 items-end">
                                    <div className="ml-4">
                                        Open: {formatTime(avail.open_time)} || Last Start: {formatTime(avail.last_start)} || Close: {formatTime(avail.close_time)}
                                    </div>
                                </div>

                                <div className="h-2 clear-both"/>

                                <div>
                                    {blocks?.length ?
                                        <div className="flex flex-row gap-x-4 items-end">
                                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                    Time
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                                                    <div className="flex flex-row gap-x-4 items-end">
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            MaxS
                                                        </div>
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            MaxW
                                                        </div>
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            MS (S)
                                                        </div>
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            MS (W)
                                                        </div>
                                                    </div>
                                                </div>
                                        </div> : null
                                    }
                                    {blocks?.length ? blocks.map(b => {
                                        return(
                                            <div
                                                key={`availability-bulk-update-${mode}-${dk}-${b.id}`}
                                                className="flex flex-row gap-x-4 items-end"
                                            >
                                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                    {formatTime(b.time)}:
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                                                    <div className="flex flex-row gap-x-4 items-end">
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            {b.students}
                                                        </div>
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            {b.seats}
                                                        </div>
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            {b.start_students}
                                                        </div>
                                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                            {b.start_seats}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    :
                                    <div className="text-mpOrange">
                                        No blocks exist on this day
                                    </div>
                                    }
                                </div>
                            </>
                        }
                        <br/>
                    </div>
                );
            })}
        </div>
    );
}

export function bulkRenderSubmitting(submissionStatus, setSubmitting){
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>
                
                <div className="grid grid-cols-1 gap-y-2">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => setSubmitting(false)}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}