import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { formatTime, getAvailabilities, formatDateApi } from '../../../../../components/functions';
import { SelectSingle, Switch } from '../../../../../components/form';
import { TooltipWrapper } from '../../../../../components/display';

const weightOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
];

const lsShowAvailableTimes = JSON.parse(localStorage.getItem('admin_overview_show_available_times')) === true;
const lsWeight = JSON.parse(localStorage.getItem('admin_overview_available_times_weight')) || weightOptions[0];
const lsIgnoreWeight = JSON.parse(localStorage.getItem('admin_overview_available_times_ignore_weight')) === true;
const lsIgnoreStart = JSON.parse(localStorage.getItem('admin_overview_available_times_ignore_start')) === true;

function getMaxTimes(availableTimes, weight, ignoreWeight, ignoreStart){
    if(!availableTimes) return {};

    const resultsObj = {};
    
    const weightInt = weight.value;
    Object.entries(availableTimes).forEach(([timeKey, seatingInfo]) => {        
        // maxS, maxW, msS, msW, maxWiw, msWiw (last two are for "ignore weight")
        // ignoreWeight ignores msS, msW; instead uses maxWiW, msWiw
        // iw values will always be equal to or greater than their standard counterparts
        const aln = 9999; // arbitrarily large number

        // maxW should be larger than normal (iw) if ignoreWeight is on
        const maxW120 = ignoreWeight ? seatingInfo.maxW120iw : seatingInfo.maxW120;
        // msW should be larger than normal (iw) if ignoreWeight is on
        // msW, msS should be infinitely large if ignoreStart is on
        // const msW120 = ignoreStart ? aln : ignoreWeight ? seatingInfo.msW120iw : seatingInfo.msW120;
        // const msS120 = ignoreStart ? aln : seatingInfo.msS120;
        const maxS120 = seatingInfo.maxS120;

        const maxW90 = ignoreWeight ? seatingInfo.maxW90iw : seatingInfo.maxW90;
        // const msW90 = ignoreStart ? aln : ignoreWeight ? seatingInfo.msW90iw : seatingInfo.msW90;
        // const msS90 = ignoreStart ? aln : seatingInfo.msS90;
        const maxS90 = seatingInfo.maxS90;

        const maxW60 = ignoreWeight ? seatingInfo.maxW60iw : seatingInfo.maxW60;
        // const msW60 = ignoreStart ? aln : ignoreWeight ? seatingInfo.msW60iw : seatingInfo.msW60;
        // const msS60 = ignoreStart ? aln : seatingInfo.msS60;
        const maxS60 = seatingInfo.maxS60;

        const maxW30 = ignoreWeight ? seatingInfo.maxW30iw : seatingInfo.maxW30;
        const msW30 = ignoreStart ? aln : ignoreWeight ? seatingInfo.msW30iw : seatingInfo.msW30;
        const msS30 = ignoreStart ? aln : seatingInfo.msS30;
        const maxS30 = seatingInfo.maxS30;

        if(maxW120 >= weightInt && msW30 >= weightInt && maxS120 >= 1 && msS30 >= 1) resultsObj[timeKey] = 120;
        else if(maxW90 >= weightInt && msW30 >= weightInt && maxS90 >= 1 && msS30 >= 1) resultsObj[timeKey] = 90;
        else if(maxW60 >= weightInt && msW30 >= weightInt && maxS60 >= 1 && msS30 >= 1) resultsObj[timeKey] = 60;
        else if(maxW30 >= weightInt && msW30 >= weightInt && maxS30 >= 1 && msS30 >= 1) resultsObj[timeKey] = 30;
        else resultsObj[timeKey] = 0;
    });

    return resultsObj;
}

function AvailableTimes(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { appointments, availability, availabilityBlocks } = props;

    const [showAvailableTimes, setShowAvailableTimes] = useState(lsShowAvailableTimes);
    const [availableTimes, setAvailableTimes] = useState({});
    const [weight, setWeight] = useState(lsWeight);
    const [ignoreWeight, setIgnoreWeight] = useState(lsIgnoreWeight);
    const [ignoreStart, setIgnoreStart] = useState(lsIgnoreStart);

    const setShowAtWrapper = (show) => {
        setShowAvailableTimes(show);
        localStorage.setItem('admin_overview_show_available_times', show);
    }

    useEffect(() => {
        if(!appointments || !availabilityBlocks) return;

        const groupedAvailability = getAvailabilities(appointments, [availability], availabilityBlocks);
    
        const dateKey = formatDateApi(availability.date).replace(/-/g, '_');
        const centerKey = parseInt(availability.center)
        const relevantInfo = groupedAvailability[dateKey]?.[centerKey]?.seatingInfo;

        setAvailableTimes(relevantInfo);
    }, [appointments, availability, availabilityBlocks])

    return (
        <div>
            <h4 className="text-mpLBlue cursor-pointer table" onClick={() => setShowAtWrapper(!showAvailableTimes)}>
                Find Appointments
            </h4>
            {showAvailableTimes ? 
                <>
                    <div className="h-2 clear-both"/>
                    <div className="grid grid-cols-1 gap-y-2 w-full">
                        <div>
                            <h5>Weight</h5>
                            <div className="h-2 clear-both"/>
                            <div className="w-1/2">
                                <SelectSingle
                                    id="available-times-select-1"
                                    name="weight"
                                    label="Weight"
                                    value={weight}
                                    onChange={(e) => {
                                        setWeight(e.target.value);
                                        localStorage.setItem('admin_overview_available_times_weight', JSON.stringify(e.target.value));
                                    }}
                                    options={weightOptions}
                                />
                            </div>
                        </div>
                        <div>
                            <Switch
                                id="available-times-switch-1"
                                name="ignoreWeight"
                                color="mpLBlue"
                                label={
                                    <div className="flex flex-row gap-x-4">
                                        <div>
                                            Ignore Weight
                                        </div>
                                        <TooltipWrapper
                                            tooltipText="Ignores the weights of other appointments (solid curvy lines), and only looks at the number of appointments scheduled. This does NOT ignore the weight you have selected."
                                        >
                                            <div className="text-mpLBlue">?</div>
                                        </TooltipWrapper>
                                    </div>
                                }
                                textPosition="after"
                                checked={ignoreWeight}
                                onChange={(e) => {
                                    const newValue = [true, 'true'].includes(e.target.value);
                                    setIgnoreWeight(newValue);
                                    localStorage.setItem('admin_overview_available_times_ignore_weight', JSON.stringify(newValue));
                                }}
                            />
                            <Switch
                                id="available-times-switch-2"
                                name="ignoreStart"
                                color="mpLBlue"
                                label={
                                    <div className="flex flex-row gap-x-4">
                                        <div>
                                            Ignore Start
                                        </div>
                                        <TooltipWrapper
                                            tooltipText="Ignores starting student and starting seat limitations (blue straight lines), and only looks at maximum simultaneous capacity (purple straight lines)."
                                        >
                                            <div className="text-mpLBlue">?</div>
                                        </TooltipWrapper>
                                    </div>
                                }
                                textPosition="after"
                                checked={ignoreStart}
                                onChange={(e) => {
                                    const newValue = [true, 'true'].includes(e.target.value);
                                    setIgnoreStart(newValue);
                                    localStorage.setItem('admin_overview_available_times_ignore_start', JSON.stringify(newValue));
                                }}
                            />
                        </div>
                    </div>
        
                    <br/>
        
                    {Object.entries(getMaxTimes(availableTimes, weight, ignoreWeight, ignoreStart)).map(([timeKey, maxTime]) => {
                            return <div key={`avail-apts-${timeKey}`}>{formatTime(timeKey)}: {maxTime} minutes</div>;
                    })}
                </> : null
            }
        </div>
    );
}

export default connect(null, {

})(AvailableTimes);