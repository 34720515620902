import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import DailyOverviewDisplay from './DailyOverviewDisplay';
import { SpinnerLoader } from '../../../../components/custom-essentials';
import { checkResponses, SelectSingle, Switch } from '../../../../components/form';
import { BrowserTabTitle, Clock, LoadingWatermark } from '../../../../components/display';
import { formatDateFull, formatTime } from "../../../../components/functions";
import { Socket } from '../../../../components/ws';
import { Wait } from '../../../../components/wait';

import {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAppointmentsDateCenter,
    fetchAdminUsersAll
} from '../../../../actions';

const pageTitle = 'Daily Overview';

const now = new Date();
now.setHours(0, 0, 0, 0);

async function startTimer(setCurrentTimeBlock, setRefreshCounter, setToday, mounted){
    while(true){
        await Wait(1000);
        if(mounted.current){
            const now = new Date();

            // refreshEvery = How often to refresh the page data, in seconds.
            // Values < 1 will refresh every second
            const refreshEvery = 60; 
            const time = now.getHours() * 60 + now.getMinutes() * 1 + Math.floor(now.getSeconds() / refreshEvery) * 0.01;
            const timeBlock = Math.floor(time / 30) * 30;
            setCurrentTimeBlock(timeBlock);
            setRefreshCounter(time);

            now.setHours(0, 0, 0, 0);
            setToday(now);
        } else break;
    }
}

function DailyOverview(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [today, setToday] = useState(now);
    const [centerOptions, setCenterOptions] = useState([]);
    const [studentMap, setStudentMap] = useState([]);
    const [instructorMap, setInstructorMap] = useState([]);
    const [appointments, setAppointments] = useState([]);

    const [refreshCounter, setRefreshCounter] = useState(0);
    const [currentTimeBlock, setCurrentTimeBlock] = useState(-1);

    const { fetchMpCentersAll, fetchStudentsAll, fetchAppointmentsDateCenter, fetchAdminUsersAll } = props;

    const refreshData = useCallback((selectedCenter, newStudentMap, newInstructorMap) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);
    
            const appointmentsRes = await fetchAppointmentsDateCenter({
                date: today,
                center: selectedCenter.value
            });
            const isApiError = checkResponses(appointmentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);
    
            const newAppointments = appointmentsRes.data?.appointments;
            const newInstructorAssignments = appointmentsRes.data?.assignments;

            const assignmentMap = {};
            newInstructorAssignments.forEach(ia => {
                const aptId = parseInt(ia.appointment_id);
                const instructorName = newInstructorMap[ia.instructor] || `Unknown user (UID: ${ia.instructor})`;
                if(!assignmentMap[aptId]) assignmentMap[aptId] = [];
                assignmentMap[aptId].push(instructorName);
            });

            const filteredAppointments = newAppointments.filter(a => {
                return !['Cancelled', 'Missed'].includes(a.status);
            }).map(a => {
                const aptDateTime = new Date(a.date_time);
                a.startTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
                a.startTimeFormatted = formatTime(a.startTime);
                a.endTime = a.startTime + parseInt(a.duration);
                a.endTimeFormatted = formatTime(a.endTime);

                const instructorList = assignmentMap[parseInt(a.id)] || [];
                a.instructorNames = instructorList.length ? instructorList.join(', ') : 'None';
                a.studentName = newStudentMap[a.student] || `Unknown student (UID: ${a.student})`;
                return a;
            });

            if(mounted.current){
                setLoading(false);
                setAppointments(filteredAppointments);
            }
        })();
    }, [loading, fetchAppointmentsDateCenter, today]);
    useEffect(() => {
        (async function(){
            if(mounted.current) setLoading(true);
            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const instructorsRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(centersRes, studentsRes, instructorsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                }
                return;
            }
            
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newInstructors = instructorsRes.data || [];

            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));
            const newInstructorMap = {};
            newInstructors.forEach(i => {
                newInstructorMap[i.id] = `${i.first_name} ${i.last_name}`;
            });
            const newStudentMap = {};
            newStudents.forEach(s => {
                newStudentMap[s.user_id] = `${s.first_name} ${s.last_name}`;
            });

            if(mounted.current){
                setCenterOptions(newCenterOptions);
                setInstructorMap(newInstructorMap);
                setStudentMap(newStudentMap);
                setLoading(false);
                refreshData(newCenterOptions[0], newStudentMap, newInstructorMap);

                startTimer(setCurrentTimeBlock, setRefreshCounter, setToday, mounted);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        refreshData(formRef?.current?.values?.selectedCenter, studentMap, instructorMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCounter, instructorMap, studentMap])

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingWatermark displacement={1} text="Refreshing..."/>}
            <Formik
                enableReinitialize
                initialValues={{
                    showOptions: true,
                    verticalMode: false,
                    selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' }
                }}
                innerRef={formRef}
                onSubmit={refreshData}
            >
                {formik => (
                    <>
                    <div className="card">
                        <h2
                            className="text-mpLBlue cursor-pointer table"
                            onClick={() => formik.setFieldValue('showOptions', !formik.values.showOptions)}
                        >
                            Daily Overview
                        </h2>

                        <br/>

                        <h3 className="flex flex-row">Today is {formatDateFull(new Date())}</h3>

                        <br/>

                        <h3 className="flex flex-row">The current time is <Clock skipSeconds/></h3>

                        {currentTimeBlock === -1 || loading ? (
                            <div>
                                <br/>
                                <h4>
                                    <div className="flex flex-row gap-x-4">
                                        <SpinnerLoader/> Retrieving Data...
                                    </div>
                                </h4>
                            </div>
                        ) : null}
                        {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                            formik.values.showOptions && currentTimeBlock !== -1 ?
                            (
                                <>
                                    <br/>

                                    <h4>Center</h4>

                                    <div className="flex flex-row gap-x-4 items-center">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <SelectSingle
                                                id="daily-overview-selectedCenter"
                                                name="selectedCenter"
                                                onChange={(e) => {
                                                    const selectedCenter = e.target.value;
                                                    formik.setFieldValue('selectedCenter', selectedCenter);
                                                    refreshData(selectedCenter, studentMap, instructorMap);
                                                }}
                                                options={centerOptions}
                                                value={formik.values.selectedCenter}
                                                disabled={loading}
                                            />
                                        </div>

                                        <div>
                                            <Switch
                                                id="daily-overview-vertical"
                                                color="mpLRed"
                                                name="verticalMode"
                                                textPosition="after"
                                                label={formik.values.verticalMode ? 'Vertical Mode' : 'Horizontal Mode'}
                                                checked={formik.values.verticalMode}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                    </div>

                    <br/>

                    {!apiError ? 
                        <DailyOverviewDisplay
                            appointments={appointments}
                            loading={loading}
                            currentTimeBlock={currentTimeBlock}
                            verticalMode={formik.values.verticalMode}
                        /> : null
                    }
                    </>
                )}
            </Formik>
            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAppointmentsDateCenter,
    fetchAdminUsersAll
})(DailyOverview);