/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { toAbsoluteUrl } from '../../../components/functions';
import { useSelector } from 'react-redux';

export function AuthPage(){
    const permissions = useSelector((state) => state.auth.permissions);
    if(permissions) return <Navigate to="/home"/>;
    return (
        <div>
            {/* Background */}
            <div className="h-screen absolute">
                <img
                    alt="background"
                    className="h-full"
                    src={toAbsoluteUrl("/media/bg/bg-2.jpg")}
                />
            </div>
            {/* Left */}
            <div className="w-1/2">
                <img
                    alt="Logo"
                    className="max-h-24 absolute z-10 top-12 left-8"
                    src={toAbsoluteUrl("/media/logos/darklong.png")}
                />
                <div className="text-3xl text-white absolute z-10 top-80 pt-4 left-12">
                    Welcome to the
                </div>
                <div className="text-3xl text-white absolute z-10 top-96 left-12">
                    MathPlex Admin Portal!
                </div>
            </div>
            {/* Right */}
            <div className="left-1/2 w-1/2 h-screen z-10 bg-white fixed overflow-y-auto">
                <div className="text-center top-64 relative">
                    <Routes>
                        <Route path="login" element={<Login/>}/>
                        <Route path="forgot-password" element={<ForgotPassword/>}/>
                        <Route path="reset-password/*" element={<ResetPassword/>}/>
                        <Route path="verify/*" element={<ResetPassword/>}/>
                        <Route path="*" element={<Navigate to="/login"/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}
