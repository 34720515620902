import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import AssessmentsBodyFooter from './AssessmentsBF';
import { checkResponse } from '../../form';

import {
    fetchAssessmentsId,
    fetchAssessmentTemplatesFull,
    fetchStudentsAll,
    fetchLessonsAll,
    createAssessment,
    updateAssessment,
    deleteAssessment,
} from '../../../actions';

function AssessmentsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [attemptingClose, setAttemptingClose] = useState(false);
    
    const [assessment, setAssessment] = useState({});
    const [assessmentTemplateOptions, setAssessmentTemplateOptions] = useState([]);
    const [lessonOptions, setLessonOptions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);

    const { mode, selectedAssessment, onSubmitCallback, fetchAssessmentsId, fetchStudentsAll,
        fetchAssessmentTemplatesFull, fetchLessonsAll, createAssessment, updateAssessment, deleteAssessment } = props;

    useEffect(() => {
        async function init(){     
            const studentsRes = await fetchStudentsAll();
            const newStudents = studentsRes.data || [];
            const newStudentOptions = [];
            newStudents.filter(s => {
                return (parseInt(s.mp_active) === 1 && parseInt(s.is_mp_student) === 1) || selectedAssessment?.student === s.user_id;
            }).sort((a, b) => {
                const aIsLead = parseInt(a.is_lead) ? 1 : 0;
                const bIsLead = parseInt(b.is_lead) ? 1 : 0;
                return bIsLead - aIsLead;
            }).forEach(s => {
                const leadNote = parseInt(s.is_lead) ? ' (Lead)' : '';
                newStudentOptions.push({ value: s.user_id, label: `${s.first_name} ${s.last_name}${leadNote}` });
            });

            const lessonsRes = await fetchLessonsAll();
            const newLessons = lessonsRes.data || [];
            const lessonMap = {};
            const newLessonOptions = [ { value: -1, label: 'None Selected' },
                ...newLessons.map(l => {
                    const lessonId = parseInt(l.id);
                    const lessonOption = ({ value: lessonId, label: `${l.lesson_id}: ${l.name}` });
                    lessonMap[lessonId] = lessonOption;
                    return lessonOption;
                }
            )];

            const newQuestionsFormatted = {};
            if(mode !== 'create'){
                const assessmentsRes = await fetchAssessmentsId({ id: selectedAssessment.id });
                const newAssessment = assessmentsRes.data?.assessment || {};
                const newQuestions = assessmentsRes.data?.questions || [];
                
                const currentStudent = newStudents.find(s => s.user_id === newAssessment.student);
                newAssessment.studentName = currentStudent ? `${currentStudent.first_name} ${currentStudent.last_name}` :
                    `Unknown student (UID: ${newAssessment.student})`;
                newAssessment.initStudentOption = currentStudent ?
                    { value: currentStudent.user_id, label: `${currentStudent.first_name} ${currentStudent.last_name}` } :
                    { value: -1, label: `Unknown student (UID: ${newAssessment.student})` }

                if(mode === 'edit'){
                    for(let i = 1; i < 200; i++){
                        if(i <= newAssessment.n_questions){
                            // Take question number i by index. Check to make sure this actually matches question_number
                            let currentQuestion = newQuestions[i] || {};
                            if(parseInt(currentQuestion.question_number) !== i){
                                currentQuestion = newQuestions.find(q => parseInt(q.question_number) === i) || {};
                            }
    
                            // If not found, show a warning
                            if(!currentQuestion.question_number){
                                newQuestionsFormatted[i] = {
                                    questionNumber: i,
                                    onIncorrect1: { value: -1, label: 'Failed to load question'},
                                    onIncorrect2: { value: -1, label: 'Failed to load question'},
                                    onIncorrect3: { value: -1, label: 'Failed to load question'},
                                    isCorrect: false
                                };    
                            }
    
                            // If found, load data
                            const lesson1Option = lessonMap[parseInt(currentQuestion.on_incorrect_1)] ||
                                { value: -1, label: `Unknown lesson (ID: ${currentQuestion.on_incorrect_1})`};
                            const lesson2Option = lessonMap[parseInt(currentQuestion.on_incorrect_2)] || { value: -1, label: 'None Selected'};
                            const lesson3Option = lessonMap[parseInt(currentQuestion.on_incorrect_3)] || { value: -1, label: 'None Selected'};
                            newQuestionsFormatted[i] = {
                                questionNumber: i,
                                onIncorrect1: lesson1Option,
                                onIncorrect2: lesson2Option,
                                onIncorrect3: lesson3Option,
                                isCorrect: parseInt(currentQuestion.is_correct) === 1
                            }
                        } else {
                            newQuestionsFormatted[i] = {
                                questionNumber: i,
                                onIncorrect1: { value: -1, label: 'None Selected'},
                                onIncorrect2: { value: -1, label: 'None Selected'},
                                onIncorrect3: { value: -1, label: 'None Selected'},
                                isCorrect: false
                            };
                        }
                    }
                }

                if(mounted.current){
                    setAssessment(newAssessment);
                    setQuestions(newQuestionsFormatted);
                }
            } else if(mode === 'create'){
                const assessmentTemplatesRes = await fetchAssessmentTemplatesFull();
                const newAssessmentTemplates = assessmentTemplatesRes.data.assessments || [];
                const newAssessmentTemplateQuestions = assessmentTemplatesRes.data.questions || [];

                const questionMap = {};
                newAssessmentTemplateQuestions.forEach(q => {
                    if(!questionMap[parseInt(q.assessment_template_id)]) questionMap[parseInt(q.assessment_template_id)] = [];
                    questionMap[parseInt(q.assessment_template_id)].push(q);
                })
                newAssessmentTemplates.sort((a, b) => {
                    if(a.level < b.level) return -1;
                    else if(a.level > b.level) return 1;
                    else return 0;
                }).forEach(at => {
                    at.questions = questionMap[parseInt(at.id)] || [];
                });

                const defaultTemplateOption = mode === 'edit' ? { value: -1, label: 'Use existing data' } :
                { value: -1, label: 'Custom assessment' };
                const newAssessmentTemplateOptions = [defaultTemplateOption, 
                    ...newAssessmentTemplates.map(at => ({ value: parseInt(at.id), label: `${at.name}`, obj: at }))
                ];

                for(let i = 1; i <= 200; i++){
                    newQuestionsFormatted[i] = {
                        questionNumber: i,
                        onIncorrect1: { value: -1, label: 'None Selected'},
                        onIncorrect2: { value: -1, label: 'None Selected'},
                        onIncorrect3: { value: -1, label: 'None Selected'},
                        isCorrect: false
                    };
                }

                if(mounted.current){
                    setAssessmentTemplateOptions(newAssessmentTemplateOptions);
                    setQuestions(newQuestionsFormatted);
                }
            }

            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setLessonOptions(newLessonOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes, force = false) => {
        if(!attemptingClose && !force && mode !== 'delete'){
            setAttemptingClose(true);
            return;
        }
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, setAttemptingClose, mode]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            const {
                name,
                nQuestions,
                student,
                notes,
                notTaken,
                dateTaken,
                fileName,
                hasFile,
                hasAnswerKey,
                active,
                questions
            } = values;

            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const assessmentParams = {
                    name,
                    nQuestions,
                    student: student.value,
                    dateTaken: notTaken ? null : dateTaken,
                    notes,
                    fileName,
                    hasFile: hasFile ? 1 : 0,
                    hasAnswerKey: hasAnswerKey ? 1 : 0,
                    active: active ? 1 : 0
                };

                let nCorrect = 0;
                const questionParams = [];
                for(let i = 1; i <= nQuestions; i++){
                    const cq = questions[i] || {};
                    questionParams.push({
                        questionNumber: cq.questionNumber,
                        onIncorrect1: cq.onIncorrect1.value,
                        onIncorrect2: cq.onIncorrect2.value,
                        onIncorrect3: cq.onIncorrect3.value,
                        isCorrect: cq.isCorrect ? 1 : 0
                    });
                    if(cq.isCorrect) nCorrect++;
                }

                assessmentParams.nCorrect = nCorrect;
                
                if(mode === 'create'){
                    response = await createAssessment({
                        assessment: assessmentParams,
                        questions: questionParams
                    });
                } else if(mode === 'edit') {
                    assessmentParams.id = assessment.id;
                    response = await updateAssessment({
                        assessment: assessmentParams,
                        questions: questionParams
                    });
                }
            } else if(mode === 'delete') {
                response = await deleteAssessment({ id: assessment.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [mode, handleClose, assessment, createAssessment, updateAssessment, deleteAssessment]);

    return (
        <Modal className="w-9/12" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Assessment Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <AssessmentsBodyFooter
                    mode={mode}
                    assessment={assessment}
                    questions={questions}
                    assessmentTemplateOptions={assessmentTemplateOptions}
                    studentOptions={studentOptions}
                    lessonOptions={lessonOptions}
                    loaded={loaded}
                    submitted={submitted}
                    attemptingClose={attemptingClose}
                    handleClose={handleClose}
                    setAttemptingClose={setAttemptingClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchAssessmentsId,
    fetchAssessmentTemplatesFull,
    fetchStudentsAll,
    fetchLessonsAll,
    createAssessment,
    updateAssessment,
    deleteAssessment
})(AssessmentsModal);