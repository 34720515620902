import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing } from '../../custom-essentials';
import { validationSchema, renderBody, getInitAssessmentOption } from './helpers';
import { formatDateTime } from '../../functions';

function LearningPlanBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedStudentOption, learningPlan, learningPlanItems, assessmentOptions,
        lessons, lessonOptions, loaded, submitted, handleClose,
        handleSubmit, attemptingClose, setAttemptingClose } = props;

    const [deleteFirstConfirm, setDeleteFirstConfirm] = useState(false);

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this learning plan?</h4>
                            <div className="grid grid-cols-1">
                                <div><b>Student:</b> {selectedStudentOption.label}</div>
                                <div><b>LP Name:</b> {learningPlan.name}</div>
                                <div><b>Created:</b> {formatDateTime(learningPlan.date_created)}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                { deleteFirstConfirm ?
                                    <div className="flex flex-row gap-x-2 flex-wrap">
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={formik.handleSubmit}
                                            >
                                            Confirm Delete
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(false)}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                    :
                                    <div className="flex flex-row gap-x-2 flex-wrap">
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(true)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                }
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    viewMode: 1,
                    stagedToRemoveIndex: -1,
                    student: selectedStudentOption,
                    notes: learningPlan.notes || '',
                    linkedAssessment: getInitAssessmentOption(learningPlan.assessment_id, assessmentOptions),
                    name: learningPlan.name || '',
                    hideCompleted: true,
                    learningPlanItems,
                    isReview: parseInt(learningPlan.is_review) === 1 ? true : false,
                    sortOrder: parseInt(learningPlan.sort_order) || 0,
                    active: parseInt(learningPlan.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <Modal.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    ) :
                    <ModalBodyFooter>
                        {renderBody(formik, mode, handleClose, assessmentOptions, lessons, lessonOptions)}
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(LearningPlanBodyFooter);