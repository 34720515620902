import { basicPath } from '../basicPath';

export const fetchLeadsAll = (params) => async (dispatch) => {
    const path = '/lead-users/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchLeadsIds = (params) => async (dispatch) => {
    const path = '/lead-users/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchLeadsMpPermissions = (params) => async (dispatch) => {
    const path = '/lead-users/mp-permissions';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createLead = (params) => async (dispatch) => {
    const path = '/lead-users';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateLead = (params) => async (dispatch) => {
    const path = '/lead-users/all';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const convertLead = (params) => async (dispatch) => {
    const path = '/lead-users/convert';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteLead = (params) => async (dispatch) => {
    const path = '/lead-users';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}