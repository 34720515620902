import { basicPath } from '../basicPath';

export const fetchDailyReportDateCenter = (params) => async (dispatch) => {
    const path = '/mp/daily-report/date-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchDailyReportsDaterangeCenter = (params) => async (dispatch) => {
    const path = '/mp/daily-report/daterange-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createDailyReport = (params) => async (dispatch) => {
    const path = '/mp/daily-report';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateDailyReport = (params) => async (dispatch) => {
    const path = '/mp/daily-report';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}