import React from 'react';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper, tableColors } from '../../../../../components/display';
import { formatDateTimeFull, formatDateTime } from '../../../../../components/functions';

export function getHoldsColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [
        {
            dataField: 'date_created',
            text: 'Date Created',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (bDate - aDate) * swap;
            },
            formatter: (row) => formatDateTimeFull(row.date_created)
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(colorMap[center]) centerColor = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    centerColor = colorMap[center];
                }
                return (
                    <div style={{ marginRight: "0.5rem" }}>
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        },
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => row.notes || <div className="text-mpOrange">None</div>
        },
        {
            dataField: 'updatedByName',
            text: 'Updated By',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                if(a < b) return -1 * swap;
                else if(a > b) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                if(!row.date_updated || !row.updated_by ||!row.updatedByName) return 'Never';
                return (
                    <TooltipWrapper
                        tooltipText={formatDateTime(row.date_updated)}
                    >
                        <div className="text-mpLBlue">{row.updatedByName}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit entry`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete entry`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}