import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import RelevantInfo from './RelevantInfo';
import { checkResponses } from '../../../../components/form';
import AssignedAppointments from './AssignedAppointments';
import { BrowserTabTitle, LoadingOverlay } from '../../../../components/display';
import { convertApiToDate, formatDateApi, processRelInfo } from '../../../../components/functions';
import { Socket } from '../../../../components/ws';

import { 
    fetchAdminUsersAll,
    fetchMpCentersAll,
    fetchMembersAll,
    fetchRelevantInfoMemberDaterangeStudentName
} from '../../../../actions';

const pageTitle = 'Instructor Dashboard';

// For relInfo search
const start = new Date();
start.setFullYear(start.getFullYear() - 1);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

async function toggleShow(setShow){
    await setShow(false);
    await setShow(true);
}

function InstructorDashboard(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [show, setShow] = useState(true);

    const [tagsData, setTagsData] = useState([]);
    const [searchTermTrigger, setSearchTermTrigger] = useState('');
    const [relInfo, setRelInfo] = useState({});
    const [employeeMap, setEmployeeMap] = useState({});
    const [centerMap, setCenterMap] = useState({});
    const [members, setMembers] = useState([]);

    const { fetchAdminUsersAll, fetchMpCentersAll, fetchMembersAll, fetchRelevantInfoMemberDaterangeStudentName } = props;

    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            
            const employeesRes = await fetchAdminUsersAll();
            const membersRes = await fetchMembersAll();
            const centersRes = await fetchMpCentersAll();
            const isApiError = checkResponses(employeesRes, membersRes, centersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newEmployees = employeesRes.data || [];
            const newMembers = membersRes.data || [];
            const newStudentUsers = newMembers.filter(u => u.mp_permissions === 'Student');
            const newCenters = centersRes.data || [];
    
            const newTagsData = newStudentUsers.map(user => {
                return ({ id: `u-${user.id}`, value: `${user.first_name} ${user.last_name}` })
            });
            
            const newEmployeeMap = {};
            newEmployees.forEach(e => newEmployeeMap[e.id] = `${e.first_name} ${e.last_name}`);
            
            const newCenterMap = {};
            newCenters.forEach(c => newCenterMap[c.id] = c.name);
    
            if(mounted.current){
                setTagsData(newTagsData);
                setEmployeeMap(newEmployeeMap);
                setCenterMap(newCenterMap);
                setMembers(newMembers)
                toggleShow(setShow);
                setLoading(false);
                setHasLoaded(true);
            }
        })();
    }, [loading, fetchAdminUsersAll, fetchMembersAll, fetchMpCentersAll]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshRelInfo = useCallback((studentName) => {
        (async function refresh(){
            // Could not get loading to update properly as a dependency, causing the buttons not to work
            // if(loading) return;
            setLoading(true);
    
            setSearchTermTrigger(`!~${studentName}~!`);
    
            const relInfoRes = await fetchRelevantInfoMemberDaterangeStudentName({
                startDate: convertApiToDate(formRef.current.values.startDate),
                endDate: convertApiToDate(formRef.current.values.endDate),
                searchTerm: studentName
            });
            const isApiError = checkResponses(relInfoRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newRelInfo = relInfoRes.data || { assignments: [] };

            // BEGIN - Process combined data and append necessary values //
            const mapsGiven = ['centers', 'employees'];
            const processedRelInfo = processRelInfo(newRelInfo, centerMap, employeeMap, studentName, mapsGiven);
            // END - Process combined data //
    
            if(mounted.current){
                setRelInfo(processedRelInfo);
                setLoading(false);
            }
        })();
    }, [fetchRelevantInfoMemberDaterangeStudentName, centerMap, employeeMap]);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <Formik
                // This Formik object handles the Relevant Info Search DRS
                enableReinitialize
                initialValues={{
                    startDate: startApi,
                    endDate: endApi,
                    showAll: false,
                }}
                innerRef={formRef}
            >
                {formik => (
                    <div className="flex flex-row gap-x-4">
                        <div className="card w-2/3 h-full">
                            {hasLoaded && show ? <AssignedAppointments
                                parentApiError={apiError}
                                loading={loading}
                                setLoading={setLoading}
                                refreshRelInfo={refreshRelInfo}
                                centerMap={centerMap}
                                members={members}
                            /> : null}
                        </div>
                        <div className="card w-1/3 h-full">
                            <RelevantInfo
                                parentApiError={apiError}
                                relInfo={relInfo}
                                refreshRelInfo={refreshRelInfo}
                                formik={formik}
                                tagsData={tagsData}
                                searchTermTrigger={searchTermTrigger}
                            />
                        </div>
                    </div>
                )}
            </Formik>
            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToPropos = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToPropos, {
    fetchAdminUsersAll,
    fetchMpCentersAll,
    fetchMembersAll,
    fetchRelevantInfoMemberDaterangeStudentName
})(InstructorDashboard);