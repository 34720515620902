import { basicPath } from '../basicPath';

export const fetchUpcomingExamsAll = (params) => async (dispatch) => {
    const path = '/mp/upcoming-exams/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchUpcomingExamsIds = (params) => async (dispatch) => {
    const path = '/mp/upcoming-exams/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchUpcomingExamsStudents = (params) => async (dispatch) => {
    const path = '/mp/upcoming-exams/student-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateUpcomingExams = (params) => async (dispatch) => {
    const path = '/mp/upcoming-exams';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}