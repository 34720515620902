import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { convertApiToDate, formatDateApi } from '../../functions';
import { DateRangeSelector, Check } from '../../form';
import { validationSchema, renderSubmitting, renderStudentInfo, getHoldLength,
    checkValuesAreSame, renderDateChanges } from './helpers';

function PlaceHoldsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { bodyMode, datesError, selectedStudent, relevantContract, loaded,
        submitted, submissionStatus, manualEntryMessage, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    originalHoldStart: formatDateApi(selectedStudent.mp_hold_start),
                    originalHoldEnd: formatDateApi(selectedStudent.mp_hold_end),
                    holdStart: formatDateApi(selectedStudent.mp_hold_start),
                    holdEnd: formatDateApi(selectedStudent.mp_hold_end),
                    holdLength: getHoldLength(selectedStudent.mp_hold_start, selectedStudent.mp_hold_end),
                    originalHoldLength: getHoldLength(selectedStudent.mp_hold_start, selectedStudent.mp_hold_end),
                    noHold: (new Date(selectedStudent.mp_hold_start)).getFullYear() >= 2100 && 
                        (new Date(selectedStudent.mp_hold_end)).getFullYear() >= 2100 ? true : false,
                    originalNoHold: (new Date(selectedStudent.mp_hold_start)).getFullYear() >= 2100 && 
                        (new Date(selectedStudent.mp_hold_end)).getFullYear() >= 2100 ? true : false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? 
                        renderSubmitting(submissionStatus, formik.setSubmitting, selectedStudent, manualEntryMessage) :
                    <ModalBodyFooter>
                        <Modal.Body>
                            {renderStudentInfo(selectedStudent, relevantContract)}
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <DateRangeSelector
                                        id="place-hold-form-drs"
                                        startName="holdStart"
                                        endName="holdEnd"
                                        startLabel="Hold Start"
                                        endLabel="Hold End"
                                        startValue={formik.values.holdStart}
                                        endValue={formik.values.holdEnd}
                                        defaultValid={true}
                                        onStartChange={(e) => {
                                            const holdStart = convertApiToDate(e.target.value);
                                            const holdEnd = convertApiToDate(formik.values.holdEnd);
                                            const holdLength = getHoldLength(holdStart, holdEnd);

                                            const newValues = { ...formik.values };
                                            newValues.holdStart = e.target.value;
                                            newValues.holdLength = holdLength;
                                            formik.setValues(newValues);
                                        }}
                                        onEndChange={(e) => {
                                            const holdStart = convertApiToDate(formik.values.holdStart);
                                            const holdEnd = convertApiToDate(e.target.value);
                                            const holdLength = getHoldLength(holdStart, holdEnd);

                                            const newValues = { ...formik.values };
                                            newValues.holdEnd = e.target.value;
                                            newValues.holdLength = holdLength;
                                            formik.setValues(newValues);
                                        }}
                                        disabled={formik.values.noHold}
                                        useCustomValidation={true}
                                    />
                                    {formik.errors.drsValid ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.drsValid}
                                        </ErrorMessage>
                                    ) : null}
                                    <Check
                                        id="place-hold-no-hold"
                                        name="noHold"
                                        label="No Hold"
                                        color="mpLRed"
                                        checked={formik.values.noHold}
                                        onChange={(e) => {
                                            const holdLength = e.target.value ? 0 : 1;

                                            const newValues = { ...formik.values };
                                            newValues.holdLength = holdLength;
                                            newValues.noHold = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                    />
                                </div>
                            </div>

                            {datesError && (
                                <>
                                    <div className="text-mpLRed">
                                        Fatal error: {datesError}. The form does not know how to properly process this entry.
                                        This problem must be fixed manually in the student form. Form submission is not possible.
                                    </div>
                                </>
                            )}

                            <br/>
                            <hr/>
                            <br/>

                            <div className="grid grid-cols-1">
                                {renderDateChanges(formik.values, selectedStudent, relevantContract)}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && formik.submitCount ?
                                    <>
                                        <div
                                            className="flex flex-row text-mpLRed mr-1"
                                        >
                                            One or more fields needs to be corrected.
                                        </div>
                                        <br/>
                                    </> : null
                                }
                                {formik.status && formik.submitCount ?
                                    <>
                                        <div
                                            className="flex flex-row mr-1 text-mpLRed"
                                        >
                                            {formik.status}
                                        </div>
                                        <br/>
                                    </> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => formik.handleSubmit(formik)}
                                    disabled={formik.isSubmitting || bodyMode === -1 || checkValuesAreSame(formik.values)}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(PlaceHoldsBodyFooter);