import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, SVGIcon, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { convertApiToDate, formatDateApi } from '../../functions';
import { TooltipWrapper } from '../../display';
import { DatePicker, DateRangeSelector, SelectSingle, FormikControl, Check } from '../../form';
import { validationSchema, getInitParent, getInitContract, getInitGradeOption, subjectOptions, getInitSubjectOption,
    getInitWeightOption, getInitBinderLocationOption, gradeOptions, weightOptions, getHoldLength,
    getSchoolYearOptions, getInitSchoolYearOption } from './helpers';

const today = new Date();
today.setHours(0, 0, 0, 0);

function StudentsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedStudent, attemptingClose, setAttemptingClose, parentOptions, contractOptions, centerOptions,
        loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    showContactInfo: true,
                    showSchedulerInfo: false,
                    showExtraInfo: false,

                    parent: getInitParent(selectedStudent.parent, parentOptions),
                    grade: getInitGradeOption(selectedStudent.grade),
                    schoolYear: getInitSchoolYearOption(selectedStudent.school_year),
                    school: selectedStudent.school || '',
                    teacher: selectedStudent.mp_teacher || '',
                    address: selectedStudent.address || '',
                    parentNotes: selectedStudent.parent_notes || '',
                    firstGuardianNotes: selectedStudent.first_guardian_notes || '',
                    secondGuardianNotes: selectedStudent.second_guardian_notes || '',
                    weight: getInitWeightOption(selectedStudent.mp_weight),
                    specialNotes: selectedStudent.mp_special_notes || '',
                    specialNotes2: selectedStudent.mp_special_notes_2 || '',
                    subject: getInitSubjectOption(selectedStudent.mp_subject),
                    fingerCounting: parseInt(selectedStudent.mp_finger_counting) === 1 ? true : false,
                    strugglesWithComputation: parseInt(selectedStudent.mp_struggles_with_computation) === 1 ? true : false,
                    advancedStudent: parseInt(selectedStudent.mp_advanced_student) === 1 ? true : false,
                    thisMonthStart: formatDateApi(selectedStudent.mp_this_month_start),
                    timeUsedThisMonth: (selectedStudent.mp_time_used_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    timeLeftThisMonth: (selectedStudent.mp_time_left_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    timeGivenThisMonth: (selectedStudent.mp_time_given_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    reserveUsedThisMonth: (selectedStudent.mp_reserve_used_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    nextMonthStart: formatDateApi(selectedStudent.mp_next_month_start || new Date()),
                    timeUsedNextMonth: (selectedStudent.mp_time_used_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    timeLeftNextMonth: (selectedStudent.mp_time_left_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    timeGivenNextMonth: (selectedStudent.mp_time_given_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    reserveUsedNextMonth: (selectedStudent.mp_reserve_used_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    defaultTimePerMonth: (selectedStudent.mp_default_time_per_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    thresholdTimePerMonth: (selectedStudent.mp_threshold_time_per_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    reserveTimeLeft: (selectedStudent.mp_reserve_time_left / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    neverInactive: (new Date(selectedStudent.mp_inactive_date || new Date())).getFullYear() >= 2100 ? true : false,
                    inactiveDate: formatDateApi(selectedStudent.mp_inactive_date || new Date()),
                        originalInactiveDate: formatDateApi(selectedStudent.mp_inactive_date || new Date()),
                    currentContract: getInitContract(selectedStudent.mp_current_contract, contractOptions),
                    currentCards: parseInt(selectedStudent.mp_current_cards),
                    totalCards: parseInt(selectedStudent.mp_total_cards),
                    onlineReady: parseInt(selectedStudent.mp_online_ready) === 1 ? true : false,
                    isSwitch: parseInt(selectedStudent.mp_is_switch) === 1 ? true : false,
                    binderLocation: getInitBinderLocationOption(selectedStudent.mp_binder_location, centerOptions),
                    monthlyReportLink: selectedStudent.mp_monthly_report_link || '',
                    monthlyReportText: selectedStudent.mp_monthly_report_text || '',
                    monthlyReportSent: parseInt(selectedStudent.mp_monthly_report_sent) === 1 ? true : false,
                    holdStart: formatDateApi(selectedStudent.mp_hold_start),
                    holdEnd: formatDateApi(selectedStudent.mp_hold_end),
                    holdLength: getHoldLength(selectedStudent.mp_hold_start, selectedStudent.mp_hold_end),
                    updateHold: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? 
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>
                                Are you sure you would like to close the form?
                            </h4>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2">
                                <Button
                                    color="lte-mpLRed"
                                    onClick={handleClose}
                                >
                                    Close Form
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => setAttemptingClose(false)}
                                >
                                    Back to Form
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                    :
                    <ModalBodyFooter>
                        <Modal.Body>
{/* CONTACT INFO */}
                            <div className="grid grid-cols-1 gap-y-2">
                                <h2
                                    className={`${formik.values.showContactInfo ? 'text-mpLBlue' : 'text-mpDBlue'} table cursor-pointer`}
                                    onClick={() => formik.setFieldValue('showContactInfo', !formik.values.showContactInfo)}
                                >
                                    <u>Contact</u>
                                </h2>
                            </div>
                            <br/>
    {/* SCHOOL */}
                            {formik.values.showContactInfo && 
                            <>
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Parent</h4>
                                        <SelectSingle
                                            id="student-form-parent"
                                            name="parent"
                                            value={formik.values.parent}
                                            onChange={formik.handleChange}
                                            options={parentOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Grade</h4>
                                        <SelectSingle
                                            id="student-form-grade"
                                            name="grade"
                                            value={formik.values.grade}
                                            onChange={formik.handleChange}
                                            options={gradeOptions}
                                        />
                                        {formik.errors.grade ? (
                                            <ErrorMessage color="mpLRed">
                                                    {formik.errors.grade}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>School Year</h4>
                                        <SelectSingle
                                            id="student-form-school-year"
                                            name="schoolYear"
                                            value={formik.values.schoolYear}
                                            onChange={formik.handleChange}
                                            options={getSchoolYearOptions()}
                                        />
                                        {formik.errors.schoolYear ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.schoolYear}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>School</h4>
                                        <FormikControl
                                            id="student-form-school"
                                            name="school"
                                            placeholder="School? Max 100 characters."
                                            value={formik.values.school}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.school ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.school}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Math Teacher</h4>
                                        <FormikControl
                                            id="student-form-teacher"
                                            name="teacher"
                                            placeholder="Teacher? Max 50 characters."
                                            value={formik.values.teacher}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.teacher ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.teacher}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>
    {/* CONTACTS */}
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Address</h4>
                                        <FormikControl
                                            id="student-form-address"
                                            name="address"
                                            placeholder="Home address? Max 200 characters."
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.address ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.address}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Parent Notes</h4>
                                        <FormikControl
                                            id="student-form-parent-notes"
                                            name="parentNotes"
                                            placeholder="Parent Notes? Max 500 characters."
                                            value={formik.values.parentNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.parentNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.parentNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>First Guardian Notes</h4>
                                        <FormikControl
                                            id="student-form-first-guardian-notes"
                                            name="firstGuardianNotes"
                                            placeholder="First guardian? Max 500 characters."
                                            value={formik.values.firstGuardianNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.firstGuardianNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.firstGuardianNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Second Guardian Notes</h4>
                                        <FormikControl
                                            id="student-form-second-guardian-notes"
                                            name="secondGuardianNotes"
                                            placeholder="Second guardian? Max 500 characters."
                                            value={formik.values.secondGuardianNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.secondGuardianNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.secondGuardianNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                <br/>
                            </>
                            }

                            <hr/>
{/* SCHEDULER INFO */}
                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="h-2 clear-both"/>
                                <h2
                                    className={`${formik.values.showSchedulerInfo ? 'text-mpLBlue' : 'text-mpDBlue'} table cursor-pointer`}
                                    onClick={() => formik.setFieldValue('showSchedulerInfo', !formik.values.showSchedulerInfo)}
                                >
                                    <u>Scheduler</u>
                                </h2>
                            </div>
                            <br/>
    {/* GENERAL CONFIG - Months and Weight */}
                            {formik.values.showSchedulerInfo && 
                            <>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <h4><u>General Configuration</u></h4>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={`The first date on which an active student may schedule an
                                            appointment. Must be before "Next Month Start"`}
                                        >
                                            <h4 className="text-mpLBlue">This Month Start</h4>
                                        </TooltipWrapper>
                                        <DatePicker
                                            id="student-form-this-month-start"
                                            name="thisMonthStart"
                                            value={formik.values.thisMonthStart}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.thisMonthStart ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.thisMonthStart}
                                            </ErrorMessage>
                                        ) : null}
                                        {convertApiToDate(formik.values.thisMonthStart) > today ? (
                                            <ErrorMessage color="mpLRed">
                                                "This Month Start" is after today. This student will not be able to schedule
                                                any appointments before "This Month Start".
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={`The cutoff date between "This Month" and "Next Month", which
                                            determines how hours are charged when appointments are scheduled. Must be
                                            after today's date and after "This Month Start"`}
                                        >
                                            <h4 className="text-mpLBlue">Next Month Start</h4>
                                        </TooltipWrapper>
                                        <DatePicker
                                            id="student-form-next-month-start"
                                            name="nextMonthStart"
                                            value={formik.values.nextMonthStart}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.nextMonthStart ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.nextMonthStart}
                                            </ErrorMessage>
                                        ) : null}
                                        {convertApiToDate(formik.values.nextMonthStart) < today ? (
                                            <ErrorMessage color="mpLRed">
                                                "Next Month Start" is before today. The student's hours will not update regularly
                                                until this is fixed.
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={`Default weight that will be used when booking appointments`}
                                        >
                                            <h4 className="text-mpLBlue">Weight</h4>
                                        </TooltipWrapper>
                                        <SelectSingle
                                            id="student-form-weight"
                                            name="weight"
                                            value={formik.values.weight}
                                            onChange={formik.handleChange}
                                            options={weightOptions}
                                        />
                                        {formik.errors.weight ? (
                                            <ErrorMessage color="mpLRed">
                                                    {formik.errors.weight}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                {formik.errors.checkMonthStarts ? (
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <ErrorMessage color="mpLRed">
                                                {formik.errors.checkMonthStarts}
                                        </ErrorMessage>
                                    </div>
                                ) : null}

                                <br/>
    {/* GENERAL CONFIG - Special Notes */}
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <TooltipWrapper
                                            tooltipText={<div>The default value that will appear as "Special Notes"
                                            on all booked appointments. Changing this value will not affect
                                            existing appointments. The icon for "Special Notes" is
                                            <SVGIcon fileName="exclamation-red"/></div>}
                                        >
                                            <h4 className="text-mpLBlue">Special Notes</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="student-special-notes"
                                            name="specialNotes"
                                            placeholder="This will update the special notes (red !) for this student's future appointments. Max 300 characters"
                                            value={formik.values.specialNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.specialNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.specialNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <TooltipWrapper
                                            tooltipText={<div>The default value that will appear as "Special Notes 2"
                                            on all booked appointments. Changing this value will not affect
                                            existing appointments. The icon for "Special Notes" is
                                            <SVGIcon fileName="exclamation-blue"/>. This field is
                                            intended to be used for items that need to stand out from the traiditional
                                            "Special Notes"</div>}
                                        >
                                            <h4 className="text-mpLBlue">Special Notes 2</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="student-special-notes-2"
                                            name="specialNotes2"
                                            placeholder="This will update the special notes 2 (blue !) for this student's future appointments. Max 300 characters"
                                            value={formik.values.specialNotes2}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.specialNotes2 ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.specialNotes2}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    Adds an extra icon for this student 
                                                    (<SVGIcon fileName="shapes-blue"/>)
                                                </div>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Subject</h4>
                                        </TooltipWrapper>
                                        <SelectSingle
                                            id="student-subject"
                                            name="subject"
                                            options={subjectOptions}
                                            value={formik.values.subject}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    Adds icon to appointments: <SVGIcon fileName="finger-coutning-blue"/>
                                                </div>
                                            }
                                        >
                                            <Check
                                                id="student-form-fingerCounting"
                                                name="fingerCounting"
                                                color="mpLRed"
                                                label={<div className="text-mpLBlue">Finger Counting</div>}
                                                checked={formik.values.fingerCounting}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    Adds icon to appointments: <SVGIcon fileName="calculator-blue"/>
                                                </div>
                                            }
                                        >
                                            <Check
                                                id="student-form-strugglesWithComputation"
                                                name="strugglesWithComputation"
                                                color="mpLRed"
                                                label={<div className="text-mpLBlue">Struggles w/Computation</div>}
                                                checked={formik.values.strugglesWithComputation}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    Adds icon to appointments: <SVGIcon fileName="chart-positive-blue"/>
                                                </div>
                                            }
                                        >
                                            <Check
                                                id="student-form-advancedStudent"
                                                name="advancedStudent"
                                                color="mpLRed"
                                                label={<div className="text-mpLBlue">Advanced Student</div>}
                                                checked={formik.values.advancedStudent}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>

                                <br/>
    {/* GENERAL CONFIG - Hours */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={`Default hours the student should start each month with`}
                                        >
                                            <h4 className="text-mpLBlue">Default Hours</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="student-form-default-hours"
                                            name="defaultTimePerMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.defaultTimePerMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.defaultTimePerMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.defaultTimePerMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <TooltipWrapper
                                            tooltipText={`At the end of "This Month", if the number of hours remaining
                                            for "This Month" is greater than "Threshold Hours", the difference
                                            will be automatically credited as "Reserve Hours". Set to 0 to disable
                                            automatic reserve hours crediting.`}
                                        >
                                            <h4 className="text-mpLBlue">Threshold Hours</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="student-form-threshold-hours"
                                            name="thresholdTimePerMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.thresholdTimePerMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.thresholdTimePerMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.thresholdTimePerMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <h4>Reserve Hours Left</h4>
                                        <FormikControl
                                            id="student-form-reserve-left"
                                            name="reserveTimeLeft"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.reserveTimeLeft}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.reserveTimeLeft ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.reserveTimeLeft}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                {formik.errors.checkGeneral ? (
                                    <div>
                                            <ErrorMessage color="mpLRed">
                                                    {formik.errors.checkGeneral}
                                            </ErrorMessage>
                                        </div>
                                ) : null}

                                <br/>

    {/* THIS MONTH */}

                                <h4><u>This Month's Hours</u></h4>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Used</h4>
                                        <FormikControl
                                            id="student-form-this-month-used"
                                            name="timeUsedThisMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.timeUsedThisMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.timeUsedThisMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.timeUsedThisMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Left</h4>
                                        <FormikControl
                                            id="student-form-this-month-left"
                                            name="timeLeftThisMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.timeLeftThisMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.timeLeftThisMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.timeLeftThisMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Total</h4>
                                        <FormikControl
                                            id="student-form-this-month-total"
                                            name="timeGivenThisMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.timeGivenThisMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.timeGivenThisMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.timeGivenThisMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Reserve Used</h4>
                                        <FormikControl
                                            id="student-form-this-month-used-reserve"
                                            name="reserveUsedThisMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.reserveUsedThisMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.reserveUsedThisMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.reserveUsedThisMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                {formik.errors.checkThisMonth ? (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.checkThisMonth}
                                    </ErrorMessage>
                                ) : null}

                                <br/>

    {/* NEXT MONTH */}
                                <h4><u>Next Month's Hours</u></h4>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Used</h4>
                                        <FormikControl
                                            id="student-form-next-month-used"
                                            name="timeUsedNextMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.timeUsedNextMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.timeUsedNextMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.timeUsedNextMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Left</h4>
                                        <FormikControl
                                            id="student-form-next-month-left"
                                            name="timeLeftNextMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.timeLeftNextMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.timeLeftNextMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.timeLeftNextMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Total</h4>
                                        <FormikControl
                                            id="student-form-next-month-total"
                                            name="timeGivenNextMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.timeGivenNextMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.timeGivenNextMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.timeGivenNextMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Reserve Used</h4>
                                        <FormikControl
                                            id="student-form-next-month-used-reserve"
                                            name="reserveUsedNextMonth"
                                            placeholder="Min: 0, Max: 500"
                                            value={formik.values.reserveUsedNextMonth}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.reserveUsedNextMonth ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.reserveUsedNextMonth}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                {formik.errors.checkNextMonth ? (
                                    <div className="col">
                                        <ErrorMessage color="mpLRed">
                                                {formik.errors.checkNextMonth}
                                        </ErrorMessage>
                                    </div>
                                ) : null}
                            </>
                            }
                            
                            <br/>
                            <hr/>

{/* EXTRA INFO */}
                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="h-2 clear-both cursor-pointer table"/>
                                <h2
                                    className={formik.values.showExtraInfo ? 'text-mpLBlue' : 'text-mpDBlue'}
                                    onClick={() => formik.setFieldValue('showExtraInfo', !formik.values.showExtraInfo)}
                                >
                                    <u>Extras</u>
                                </h2>
                            </div>
                            <br/>
                            {formik.values.showExtraInfo && 
                            <>
    {/* MISC SETTINGS - ROW 1 */}
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <h4>Inactive Date</h4>
                                        <DatePicker
                                            id="student-form-inactive-date"
                                            name="inactiveDate"
                                            value={formik.values.inactiveDate}
                                            onChange={formik.handleChange}
                                            disabled={formik.values.neverInactive}
                                        />
                                        <Check
                                            id="student-form-never-inactive"
                                            name="neverInactive"
                                            label="Never"
                                            color="mpLRed"
                                            checked={formik.values.neverInactive}
                                            onChange={(e) => {
                                                const neverInactive = e.target.value;
                                                const newValues = { ...formik.values };
                                                newValues.neverInactive = neverInactive;

                                                if(neverInactive){
                                                    const inactiveDate = new Date(3000, 0, 1);
                                                    inactiveDate.setHours(0, 0, 0, 0);
                                                    newValues.inactiveDate = formatDateApi(inactiveDate);
                                                } else {
                                                    newValues.inactiveDate = formik.values.originalInactiveDate;
                                                }

                                                formik.setValues(newValues);
                                            }}
                                        />
                                        {formik.errors.inactiveDate ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.inactiveDate}
                                            </ErrorMessage>
                                        ) : null}
                                        {convertApiToDate(formik.values.inactiveDate) <= today ? 
                                            <div className="text-mpOrange">
                                                Inactive date is on or before today. The student will be marked as
                                                inactive at midnight by the system. The student can be inactivated
                                                immediately using the form accessible through the Users tab.
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <h4>Switch Student?</h4>
                                        <Check
                                            id="student-form-switch-student"
                                            name="isSwitch"
                                            label="Is Switch"
                                            color="mpLRed"
                                            checked={formik.values.isSwitch}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <h4>Online Ready?</h4>
                                        <Check
                                            id="student-form-switch-student"
                                            name="onlineReady"
                                            label="Yes"
                                            color="mpLRed"
                                            checked={formik.values.onlineReady}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                <br/>

    {/* MISC SETTINGS - ROW 2 */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Current Cards</h4>
                                        <FormikControl
                                            id="student-form-current-cards"
                                            name="currentCards"
                                            placeholder="Min: 0, Max: 10000"
                                            value={formik.values.currentCards}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.currentCards ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.currentCards}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Total Cards</h4>
                                        <FormikControl
                                            id="student-form-total-cards"
                                            name="totalCards"
                                            placeholder="Min: 0, Max: 10000"
                                            value={formik.values.totalCards}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.totalCards ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.totalCards}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

    {/* MISC SETTINGS - ROW 3 */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>Current Contract</h4>
                                        <SelectSingle
                                            id="student-form-current-contract"
                                            name="currentContract"
                                            value={formik.values.currentContract}
                                            onChange={formik.handleChange}
                                            options={contractOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Binder Location</h4>
                                        <SelectSingle
                                            id="student-form-binder-location"
                                            name="binderLocation"
                                            value={formik.values.binderLocation}
                                            onChange={formik.handleChange}
                                            options={centerOptions}
                                        />
                                        {formik.errors.binderLocation ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.binderLocation}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                
                                <br/>
    {/* MISC SETTINGS - ROW 4 (Monthly Report Link) */}
                                
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                        <h4>Monthly Report Link</h4>
                                        <FormikControl
                                            id="student-form-monthly-report-link"
                                            name="monthlyReportLink"
                                            placeholder="Link to Monthly Report Google Sheet."
                                            value={formik.values.monthlyReportLink}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.monthlyReportLink ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.monthlyReportLink}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3 items-end">
                                        <TooltipWrapper
                                            tooltipText="Tracks whether a monthly report email was sent for this student. Resets at the start of each month."
                                        >
                                            <Check
                                                id="student-form-monthlyReportSent"
                                                name="monthlyReportSent"
                                                color="mpLRed"
                                                label={<div className="text-mpLBlue">Report Sent?</div>}
                                                checked={formik.values.monthlyReportSent}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Monthly Report Text</h4>
                                        <FormikControl
                                            id="student-form-monthlyReportText"
                                            as="textarea"
                                            name="monthlyReportText"
                                            placeholder="Additional text to be included in monthly report reminder emails."
                                            value={formik.values.monthlyReportText}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.monthlyReportText ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.monthlyReportText}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>
                                <hr/>
    {/* MISC SETTINGS - ROW 5 (Holds) */}
                                {formik.values.updateHold && <div className="text-mpOrange">
                                    Warning! Updating hold dates on this form will NOT update the student's next month start,
                                    inactive date, or contract end date. Holds log entries will not be created.
                                    Please only use this if the holds form shows a fatal error message.
                                </div>}
                                <br/>
                                <div className="flex flex-row gap-x-4 w-full">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <Check
                                            id="student-form-update-hold"
                                            name="updateHold"
                                            color="mpLRed"
                                            label="Update Hold?"
                                            checked={formik.values.updateHold}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    <DateRangeSelector
                                        id="student-form-hold-dates"
                                        startName="holdStart"
                                        endName="holdEnd"
                                        startLabel="Hold Start"
                                        endLabel="Hold End"
                                        startValue={formik.values.holdStart}
                                        endValue={formik.values.holdEnd}
                                        defaultValid={true}
                                        onStartChange={(e) => {
                                            const holdStart = convertApiToDate(e.target.value);
                                            const holdEnd = convertApiToDate(formik.values.holdEnd);
                                            const holdLength = getHoldLength(holdStart, holdEnd);

                                            const newValues = { ...formik.values };
                                            newValues.holdStart = e.target.value;
                                            newValues.holdLength = holdLength;
                                            formik.setValues(newValues);
                                        }}
                                        onEndChange={(e) => {
                                            const holdStart = convertApiToDate(formik.values.holdStart);
                                            const holdEnd = convertApiToDate(e.target.value);
                                            const holdLength = getHoldLength(holdStart, holdEnd);

                                            const newValues = { ...formik.values };
                                            newValues.holdEnd = e.target.value;
                                            newValues.holdLength = holdLength;
                                            formik.setValues(newValues);
                                        }}
                                        disabled={!formik.values.updateHold}
                                        useCustomValidation={true}
                                    />
                                    <div className="ml-auto">
                                        Hold Length: {formik.values.holdLength} days
                                    </div>
                                </div>
                                {formik.errors.drsValid ? (
                                    <>
                                        <br/>
                                        <div className="flex flex-row gap-x-4 w-full">
                                            <div className="ml-auto">
                                                <ErrorMessage color="mpLRed">
                                                        {formik.errors.drsValid}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(StudentsBodyFooter);