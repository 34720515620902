import React, { useCallback, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from '@mui/material';

import { TabPanel } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay } from '../../../../components/display';
import { checkResponses } from '../../../../components/form';
import DailyReportForm from './DailyReportForm';
import ExtraTags from './ExtraTags';
import ReportTable from './ReportTable';
import NotesSummary from './NotesSummary';
import { Socket } from '../../../../components/ws';

import {
    fetchAdminUsersActive,
    fetchAdminUsersAll,
    fetchMembersMpPermissions,
    fetchExtraTagsAll,
    fetchMpCentersAll,
} from '../../../../actions';

const pageTitle = 'ACD Report';

function ACDReport(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    const [employees, setEmployees] = useState([]);
    const [employeeMap, setEmployeeMap] = useState({});
    const [studentUsers, setStudentUsers] = useState([]);
    const [extraTags, setExtraTags] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const [activeTabKey, setActiveTabKey] = useState(0);

    const { fetchMpCentersAll, fetchAdminUsersAll, fetchAdminUsersActive, fetchMembersMpPermissions, fetchExtraTagsAll } = props;

    const refreshData = () => {
        (async function refresh(){
            setLoading(true);
    
            // For collecting tags data. Only need active users
            const employeesRes = await fetchAdminUsersActive();
            const allEmployeesRes = await fetchAdminUsersAll();
            const centersRes = await fetchMpCentersAll();
            const extraTagsRes = await fetchExtraTagsAll();
            const studentUsersRes = await fetchMembersMpPermissions({ permissions: 'Student' });
            const isApiError = checkResponses(employeesRes, allEmployeesRes, centersRes, extraTagsRes, studentUsersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            } else setApiError(false);

            const newEmployees = employeesRes.data || [];
            const allEmployees = allEmployeesRes.data || [];
            const newCenters = centersRes.data || [];
            const newExtraTags = extraTagsRes.data || [];
            const newStudentUsers = studentUsersRes.data || [];
    
            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));
    
            const newEmployeeMap = {};
            allEmployees.forEach(e => newEmployeeMap[e.id] = `${e.first_name} ${e.last_name}`);

            const newEmployeesFiltered = newEmployees.filter(e => {
                return e.mp_permissions !== 'None' && parseInt(e.account_active) === 1;
            })
    
            if(mounted.current){
                setEmployees(newEmployeesFiltered);
                setStudentUsers(newStudentUsers);
                setExtraTags(newExtraTags);
                setEmployeeMap(newEmployeeMap);
                setCenterOptions(newCenterOptions);
        
                setLoading(false);
                setHasLoaded(true);
            }
        })();
    };
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMpCentersAll, fetchAdminUsersActive, fetchMembersMpPermissions, fetchExtraTagsAll]);
    const refreshTags = useCallback(() => {
        (async function refresh(){
            if(loading) return;
            setLoading(true);
    
            const extraTagsRes = await fetchExtraTagsAll();
            const isApiError = checkResponses(extraTagsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newExtraTags = extraTagsRes.data || [];
            if(mounted.current){            
                setExtraTags(newExtraTags);
            }
    
            const newTagsData = [];
    
            studentUsers.forEach(s => newTagsData.push({ id: `u-${s.user_id}`, value: `${s.first_name} ${s.last_name}` }));
            employees.forEach(e => newTagsData.push({ id: `u-${e.id}`, value: e.name }));
            newTagsData.forEach(tag => newTagsData.push({ id: `e-${tag.id}`, value: tag.value }));
            
            if(mounted.current){
                setLoading(false);
                setExtraTags(newExtraTags);
            }
        })();
    }, [loading, mounted, studentUsers, employees, fetchExtraTagsAll]);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            { (loading || !hasLoaded) && <LoadingOverlay/>}
            { hasLoaded && 
                <>
                    <div className="flex flex-row gap-x-4">
                        <div className="card w-2/3 h-full">
                            <DailyReportForm
                                parentApiError={apiError}
                                loading={loading}
                                setLoading={setLoading}
                                refreshTags={refreshTags}
                                employees={employees}
                                employeeMap={employeeMap}
                                studentUsers={studentUsers}
                                extraTags={extraTags}
                                centerOptions={centerOptions}
                            />
                        </div>
                        <div className="card w-1/3 h-full">
                            <Tabs
                                id="acdReport"
                                value={activeTabKey}
                                onChange={(e, newValue) => setActiveTabKey(newValue)}
                            >
                                <Tab label={`Notes Summary`}/>
                                <Tab label={`Extra Tags`}/>
                            </Tabs>
                            <TabPanel activeKey={activeTabKey} index={0}>
                                <NotesSummary
                                    parentApiError={apiError}
                                    employees={employees}
                                    studentUsers={studentUsers}
                                    centerOptions={centerOptions}
                                />
                            </TabPanel>
                            <TabPanel activeKey={activeTabKey} index={1}>
                                <ExtraTags
                                    parentApiError={apiError}
                                    refreshTags={refreshTags}
                                    extraTags={extraTags}
                                />
                            </TabPanel>
                        </div>
                    </div>
                    <br/>
                    <div className="card">
                        <ReportTable
                            parentApiError={apiError}
                            employeeMap={employeeMap}
                            centerOptions={centerOptions}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </div>
                </>
            }
            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => { 
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchAdminUsersActive,
    fetchAdminUsersAll,
    fetchMembersMpPermissions,
    fetchExtraTagsAll,
    fetchMpCentersAll,
}) (ACDReport);