import { basicPath } from '../basicPath';

export const fetchMembersAll = (params) => async (dispatch) => {
    const path = '/member-users/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchMembersIds = (params) => async (dispatch) => {
    const path = '/member-users/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchMembersMpPermissions = (params) => async (dispatch) => {
    const path = '/member-users/mp-permissions';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createMember = (params) => async (dispatch) => {
    const path = '/member-users';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateMember = (params) => async (dispatch) => {
    const path = '/member-users/all';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateMemberPassword = (params) => async (dispatch) => {
    const path = '/member-users/password';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const resendMemberWelcome = (params) => async (dispatch) => {
    const path = '/member-users/resend-welcome';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteMember = (params) => async (dispatch) => {
    const path = '/member-users';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}