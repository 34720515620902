import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    selectedStudent: Yup.mixed().test(
        'studentIsSelected',
        'Please select a valid student',
        (value) => value.value !== -1
    ),
    selectedCenter: Yup.mixed().test(
        'centerIsSelected',
        'Please select a valid center',
        (value) => value.value !== -1
    ),
    notes: Yup.string()
        .required('Notes is required')
        .max(300, 'Max 300 characters'),
});

export function getInitStudentOption(student, studentOptions){
    if(!student) return { value: -1, label: 'Please select...' };
    return studentOptions.find(s => s.value === student) || { value: -1, label: `Unknown student (ID ${student})` };
}

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'Please select...' };
    return centerOptions.find(c => parseInt(c.value) === parseInt(center)) || { value: -1, label: `Unknown center (ID ${center})` };
}