export function getInitialValues(defaultSwitches, assignmentMap){
    const initialValues = {...defaultSwitches};

    Object.values(assignmentMap).forEach(instructorSet => {
        const { id } = instructorSet;
        if(initialValues[id] === undefined) initialValues[id] = true;
    });

    if(initialValues.weights === undefined) initialValues.weights = false;

    return initialValues;
}

export const colorOptions = ['mpLRed', 'mpOrange', 'mpYellow', 'mpGreen', 'mpTeal', 'mpLBlue', 'mpDBlue', 'mpPurple', 'mpDRed', 'mpLGrey', 'mpDGrey'];

export function parseSchedulingNotes(appointment, assignments, instructorMap){
    // This function tracks which instructors are working with which students at each 30 minute interval.
    // This is used for the Overview "Instructors" view mode
    // Special queries ("Move from xxx to yyy at zz:zz: am/pm") are needed to properly "move" students
    // between instructors. If no special query is present in "Scheduling Notes", all instructors assigned
    // to an appointment will be assigned for the full duration of the appointment.
    const notes = appointment.special_notes_schedule;
    const aptDateTime = new Date(appointment.date_time);
    const aptStartMinutes = (aptDateTime.getHours()) * 60 + aptDateTime.getMinutes() * 1;
    const aptStart = parseInt(aptStartMinutes);
    const aptEnd = parseInt(aptStartMinutes) + parseInt(appointment.duration);
    const assignedInstructors = assignments.map(a => a.instructor);

    // Return one item if no notes are provided (assume no switch)
    const defaultInstructor = assignments[0]?.instructor;
    const defaultOption = [];
    if(!defaultInstructor) return [];
    for(let assignment of assignments){
        defaultOption.push({
            instructor: assignment.instructor,
            start: aptStart,
            end: aptEnd
        });
    }
    if(!notes) return defaultOption;

    const queries = notes.split(';');
    const validQueries = [];
    const timesList = [aptStart]; // Holds the times of all events (apt start, transitions, apt end)
    const instructorMovements = [];

    // Check if any queries are in a valid format ("Move from xxx to yyy at zz:zz am/pm")
    const instructorNames = Object.keys(instructorMap);
    for(let q of queries){
        const query = q.toLowerCase();
        if(!query.includes('move from') || !query.includes(' to ') || !query.includes(' at ')) continue;

        // Parse instructors from the valid query
        const fromInstructor = query.split('move from ')[1].split(' to ')[0].trim().replace(/ /g, '');
        const toInstructor = query.split(' to ')[1].split(' at ')[0].trim().replace(/ /g, '');
        const moveTime = query.split(' at ')[1].trim().replace(/ /g, '');

        if(!fromInstructor || !toInstructor || !moveTime) continue;

        const fromInstructorName = instructorNames.find(i => i.includes(fromInstructor));
        const fromInstructorId = instructorMap[fromInstructorName];
        const toInstructorName = instructorNames.find(i => i.includes(toInstructor));
        const toInstructorId = instructorMap[toInstructorName];
        // Don't need to check if IDs are valid since this will be done in the final step when pushing into validQueries
        instructorMovements.push({ fromInstructor: fromInstructorId, toInstructor: toInstructorId });
        
        const colonIndex = moveTime.indexOf(':');
        const hours = moveTime.slice(0, colonIndex);
        const minutes = moveTime.slice(colonIndex + 1, colonIndex + 3);
        const ampm = moveTime.slice(colonIndex + 3);
        const apiTime = hours * 60 + minutes * 1 + (ampm === 'am' ? 0 : 720);
        if(apiTime % 30 === 0) timesList.push(apiTime);
    }
    if(!instructorMovements.length) return defaultOption
    timesList.push(aptEnd);

    // Ensure times are in ascending order
    for(let i = 0; i < timesList.length - 1; i++){
        if(timesList[i] >= timesList[i + 1]) return defaultOption;
    }

    // Process data
    for(let i = 0; i < timesList.length - 2; i++){
        const fromTime = timesList[i]; // Previous transition (start time if first transition)
        const toTime = timesList[i + 1]; // Current transition
        const toEndTime = timesList[i + 2]; // Next transition (end time if last transition)
        const { fromInstructor, toInstructor } = instructorMovements[i];
        
        // Only assigned instructors should be included
        // Only need to push the "from" instructor on the first transition. Otherwise, we have duplicates
        if(assignedInstructors.includes(fromInstructor) && i === 0){
            validQueries.push({
                instructor: fromInstructor,
                start: fromTime,
                end: toTime
            });
        }
        if(assignedInstructors.includes(toInstructor)){
            validQueries.push({
                instructor: toInstructor,
                start: toTime,
                end: toEndTime
            });
        }
    }

    if(!validQueries.length) return defaultOption;

    return validQueries;
}