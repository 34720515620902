import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import EmployeesBodyFooter from './EmployeesBF';
import { checkResponse } from '../../form';

import {
    fetchAdminUsersIds,
    fetchMpCentersAll,
    fetchRpCentersAll,
    createAdminUser,
    updateAdminUser,
    updateAdminUserPassword,
    deleteAdminUser
} from '../../../actions';

function EmployeeModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    
    const [employee, setEmployee] = useState({});
    const [mpCenterOptions, setMpCenterOptions] = useState([]);
    const [rpCenterOptions, setRpCenterOptions] = useState([]);
    const [showPass, setShowPass] = useState(false);

    const { mode, selectedEmployee, onSubmitCallback, fetchAdminUsersIds, fetchMpCentersAll,
        fetchRpCentersAll, createAdminUser, updateAdminUser, updateAdminUserPassword, deleteAdminUser } = props;

    useEffect(() => {
        async function init(){
            if(mode !== 'create'){
                const response = await fetchAdminUsersIds({ ids: [selectedEmployee.id] });
                const newEmployee = response.data?.[0] || {};

                if(mounted.current) setEmployee(newEmployee);
            }

            const mpCentersRes = await fetchMpCentersAll();
            const rpCentersRes = await fetchRpCentersAll();
            const newMpCenters = mpCentersRes.data || [];
            const newRpCenters = rpCentersRes.data || [];
            const newMpCenterOptions = [...newMpCenters.map(c => ({ value: parseInt(c.id), label: c.name })), { value: -1, label: 'None' }];
            const newRpCenterOptions = [...newRpCenters.map(c => ({ value: parseInt(c.id), label: c.name })), { value: -1, label: 'None' }];

            if(mounted.current){
                setMpCenterOptions(newMpCenterOptions);
                setRpCenterOptions(newRpCenterOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const passwordReset = useCallback((formik) => {
        async function submit(){
            const { setStatus, setSubmitting, values } = formik;
            const { passwordReset, passwordConfirm } = values;
            if(passwordReset !== passwordConfirm){
                setStatus('Passwords do not match.');
                return;
            }
    
            setSubmitting(true);
    
            let response = { status: 999 };
            const passwordParams = {
                id: employee.id,
                password: passwordReset
            };
            response = await updateAdminUserPassword(passwordParams);
    
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
    
    
            if(mounted.current){
                setResetSuccess(true);
                setSubmitting(false);
            }
        }
        submit()
    }, [updateAdminUserPassword, employee])
    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            let response = { status: 999 };

            if(['create', 'edit'].includes(mode)){
                const mpCenterPermissionsInvalid = (
                    !values.mpPermissions.value === 'Root' ||
                    ((values.mpPermissions.value !== 'None' && values.mpPrimaryCenter.value === -1) ||
                    (values.mpPermissions.value === 'None' && values.mpPrimaryCenter.value !== -1))
                );
                const rpCenterPermissionsInvalid = (
                    !values.rpPermissions.value === 'Root' ||
                    ((values.rpPermissions.value !== 'None' && values.rpPrimaryCenter.value === -1) ||
                    (values.rpPermissions.value === 'None' && values.rpPrimaryCenter.value !== -1))
                );
                if(mpCenterPermissionsInvalid || rpCenterPermissionsInvalid){
                    setStatus(`Within MP and RP, Primary Center and Permissions must both be 'None' or both NOT be 'None'.`);
                    setSubmitting(false);
                    return;
                }
                if(values.mpPermissions.value === 'None' && values.rpPermissions.value === 'None'){
                    setStatus(`User must have at least one permissions role.`);
                    setSubmitting(false);
                    return;
                }

                const employeeParams = {
                    email: values.email,
                    receiveEmails: values.receiveEmails ? 1 : 0,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone.toString().replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
                    receiveTexts: values.receiveTexts ? 1 : 0,
                    mpPrimaryCenter: values.mpPrimaryCenter.value,
                    rpPrimaryCenter: values.rpPrimaryCenter.value,
                    mpPermissions: values.mpPermissions.value,
                    rpPermissions: values.rpPermissions.value,
                    verified: values.verified ? 1 : 0,
                    accountActive: values.accountActive ? 1 : 0
                };
                
                if(mode === 'create'){
                    response = await createAdminUser(employeeParams);
                } else if(mode === 'edit') {
                    employeeParams.id = employee.id;
                    response = await updateAdminUser(employeeParams);
                }
            } else if(mode === 'delete') {
                response = await deleteAdminUser({ id: employee.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, employee, createAdminUser, updateAdminUser, deleteAdminUser]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Employee Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <EmployeesBodyFooter
                    mode={mode}
                    selectedEmployee={employee}
                    mpCenterOptions={mpCenterOptions}
                    rpCenterOptions={rpCenterOptions}
                    loaded={loaded}
                    passwordReset={passwordReset}
                    resetSuccess={resetSuccess}
                    showPass={showPass}
                    setShowPass={setShowPass}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchAdminUsersIds,
    fetchMpCentersAll,
    fetchRpCentersAll,
    createAdminUser,
    updateAdminUser,
    updateAdminUserPassword,
    deleteAdminUser
})(EmployeeModal);