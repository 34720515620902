import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import AppointmentModalBodyFooter from './AppointmentsModalBF';
import { checkResponse } from '../../form';
import { getymd, getymdhms } from '../../functions';

import {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchMembersMpPermissions,
    fetchAppointmentsIds,
    createAppointment,
    updateAppointmentCore,
    deleteAppointment,
    deleteAppointmentForce
} from '../../../actions';

function AppointmentModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // Default null to match 'undefined' from not passing selectedAppointment to begin with
    const [appointment, setAppointment] = useState({});
    const [parents, setParents] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);

    const { mode, fetchMpCentersAll, fetchStudentsAll, fetchMembersMpPermissions, fetchAppointmentsIds, 
        createAppointment, updateAppointmentCore, deleteAppointment, deleteAppointmentForce,
        selectedDate, selectedCenter, selectedAppointment, onSubmitCallback } = props;

    useEffect(() => {
        async function init(){
            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const studentUserRes = await fetchMembersMpPermissions({ permissions: 'Student' });
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newStudentUsers = studentUserRes.data || [];

            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));
            const newStudentOptions = newStudents.filter(s => {
                return parseInt(s.is_lead) === 0;
            }).filter(s => {
                return (
                    (parseInt(s.is_mp_student) === 1 && parseInt(s.mp_active) === 1)
                    || s.user_id === selectedAppointment?.student
                );
            }).sort((a, b) => {
                if(a.first_name < b.first_name) return -1;
                else if(a.first_name > b.first_name) return 1;
                return 0;
            }).map(s => {
                const relStudent = newStudentUsers.find(su => su.id === s.user_id) || {};
                s.email = relStudent.email;
                return { value: s.user_id, label: `${s.first_name} ${s.last_name}`, object: s };
            });

            const parentIds = [];
            for(let student of newStudents){
                if(!parentIds.includes(student.parent)) parentIds.push(student.parent);
            }
            const parentsRes = await fetchMembersMpPermissions({ permissions: 'Parent' });
            const newParents = parentsRes.data || [];

            if(mode !== 'create'){
                const appointmentsRes = await fetchAppointmentsIds({ ids: [selectedAppointment.id] });
                const newAppointment = appointmentsRes.data?.appointments?.[0] || {};
                const relCenter = newCenters.find(c => parseInt(c.id) === parseInt(newAppointment.center)) || {};
                newAppointment.centerName = relCenter.name || `Unable to find center (ID: ${newAppointment.center})`;

                const aptDateTime = new Date(newAppointment.date_time);
                const [year, month, date, hours, minutes] = getymdhms(aptDateTime);
                newAppointment.date = `${year}-${month}-${date} 00:00:00`;
                newAppointment.time = hours * 60 + minutes * 1;

                if(mounted.current) setAppointment(newAppointment);
            }

            if(mounted.current){
                setParents(newParents);
                setCenterOptions(newCenterOptions);
                setStudentOptions(newStudentOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const forceDelete = useCallback((formik) => {
        (async function(){
            const { setStatus, setSubmitting } = formik;

            if(mounted.current) setSubmitting(true);

            const response = await deleteAppointmentForce({ id: appointment.id });
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        })();
    }, [deleteAppointmentForce, appointment, handleClose])
    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);

            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const [year, month, date] = getymd(`${values.date} 00:00:00`);
                const hours = Math.floor(parseInt(values.time.raw) / 60);
                const minutes = parseInt(values.time.raw % 60);
                const dateTime = new Date(`${year}-${month}-${date} ${hours}:${minutes}:00`);
                
                const appointmentObject = {
                    dateTime,
                    parent: values.student.object.parent,
                    student: values.student.value,
                    weight: values.weight.value,
                    center: values.center.value,
                    duration: values.duration.value,
                    specialNotes: values.specialNotes,
                    specialNotes2: values.specialNotes2,
                    status: values.status.value,
                };
                if(mode === 'create'){
                    response = await createAppointment(appointmentObject);
                } else if(mode === 'edit') {
                    appointmentObject.id = appointment.id;
                    response = await updateAppointmentCore(appointmentObject);
                }
            } else if(mode === 'delete') {
                response = await deleteAppointment({ id: appointment.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, createAppointment, updateAppointmentCore, deleteAppointment, handleClose, appointment]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Appointment Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <AppointmentModalBodyFooter
                    mode={mode}
                    selectedDate={selectedDate}
                    selectedCenter={selectedCenter}
                    selectedAppointment={appointment}
                    centerOptions={centerOptions}
                    studentOptions={studentOptions}
                    parents={parents}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    forceDelete={forceDelete}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchMembersMpPermissions,
    fetchAppointmentsIds,
    createAppointment,
    updateAppointmentCore,
    deleteAppointment,
    deleteAppointmentForce
})(AppointmentModal);