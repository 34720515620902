import React from 'react';

export function ErrorMessage(props){
    const { children, size = 'sm', color } = props;

    return (
        <div className={`text-${size} text-${color}`}>
            {children}
        </div>
    );
}