import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumns } from './table/getColumns';
import { Table } from '../../../../components/table';
import { TransitionBinderModal } from '../../../../components/modal';

import {

} from '../../../../actions';

const defaultSorted = { dataField: 'status', order: 'desc' };

function TransitionsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { appointments, refreshData } = props;
    const [modalMode, setModalMode] = useState(null);
    const [selectedStudentId, setSelectedStudentId] = useState(null);

    const handleShowModal = useCallback((mode, studentId) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedStudentId(studentId);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedStudentId(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData])

    const columns = useMemo(() => getColumns(handleShowModal), [handleShowModal]);

    return (
        <div>
            {modalMode && 
                <TransitionBinderModal
                    selectedStudentId={selectedStudentId}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="transitions"
                data={appointments}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No transitions were found"
            />
        </div>
    );
}

export default connect(null, {

})(TransitionsTable);