import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { TooltipWrapper, renderPhone } from '../../../../../components/display';
import { formatDate, formatDateFull } from '../../../../../components/functions';

export function getUserColumns(handleShowMemberModal){
    const permissionsMap = {
        "parent": 1,
        "student": 2,
        "None": 3
    };
    const activeTag = <Label color="mpGreen">Active</Label>;
    const inactiveTag = <Label color="mpLRed">Inactive</Label>;
    return(
        [
            {
                dataField: 'id',
                text: 'UUID',
                sort: true,
                formatter: (row) => {
                    return(
                        <TooltipWrapper
                            tooltipText={row.id}
                        >
                            <div className="text-mpLBlue">
                                [ID]
                            </div>
                        </TooltipWrapper>
                    )
                }
            }, {
                dataField: 'name',
                text: 'Name',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const aName = `${rowA.first_name} ${rowA.last_name}`;
                    const bName = `${rowB.first_name} ${rowB.last_name}`;
                    const swap = order === 'asc' ? -1 : 1;
        
                    if(aName < bName) return -1 * swap;
                    else if(aName > bName) return 1 * swap;
                    return 0;
                },
                formatter: (row) => {
                    const name = `${row.first_name} ${row.last_name}`;
                    if(row.mp_permissions === 'Parent' || row.rp_permissions === 'Parent'){
                        return (
                            <TooltipWrapper
                                tooltipText={
                                    <>
                                        <div>Students:</div>
                                        {row.studentList ? 
                                            <div>{row.studentList.join(', ')}</div> :
                                            <div className="text-mpOrange">None</div>
                                        }
                                    </>
                                }
                            >
                                <div className="text-mpLBlue">{name}</div>
                            </TooltipWrapper>
                        );
                    } else if(row.mp_permissions === 'Student' || row.rp_permissions === 'Student'){
                        const parent = row.parentObject;
                        if(!parent.first_name && !parent.last_name) return name;
                        
                        return (
                            <TooltipWrapper
                                tooltipText={
                                    <>
                                        <div>Parent: {parent.first_name} {parent.last_name}</div>
                                    </>
                                }
                            >
                                <div className="text-mpLBlue">{name}</div>
                            </TooltipWrapper>
                        );
                    }
                }
            }, {
                dataField: 'email',
                text: 'Email',
                sort: true,
            }, {
                dataField: 'phone',
                text: 'Phone Number',
                sort: true,
                formatter: (row) => {
                    let number = row.phone.toString();
                    if(!number.length || number === '0' || number === '0000000000'){
                        return <Label color="mpLRed">None</Label>;
                    }
                    return renderPhone(number);
                }
            }, {
                dataField: 'primary_center',
                text: 'Primary Center',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const sortBy = order === 'asc' ? 'mp' : 'rp';
                    if(sortBy === 'mp'){
                        if(a.mpCenterName < b.mpCenterName) return -1;
                        else if(a.mpCenterName > b.mpCenterName) return 1;
                        else return 0;
                    } else if (sortBy === 'rp'){
                        if(a.rpCenterName < b.rpCenterName) return -1;
                        else if(a.rpCenterName > b.rpCenterName) return 1;
                        else return 0;
                    }
                },
                formatter: (row) => {
                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {row.mpCenterName}</div>
                            <div>RP: {row.rpCenterName}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'permissions',
                text: 'Permissions',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? -1 : 1;
                    const mpPermissionsA = rowA.mp_permissions;
                    const mpPermissionsB = rowB.mp_permissions;
                    const rpPermissionsA = rowA.rp_permissions;
                    const rpPermissionsB = rowB.rp_permissions;

                    const priorityA = permissionsMap[mpPermissionsA.toLowerCase()] || 99 + permissionsMap[rpPermissionsA.toLowerCase()] || 99;
                    const priorityB = permissionsMap[mpPermissionsB.toLowerCase()] || 99 + permissionsMap[rpPermissionsB.toLowerCase()] || 99;
                    return (priorityA - priorityB) * swap;
                },
                formatter: (row) => {
                    const { mp_permissions, rp_permissions } = row;

                    let mpColor = 'mpLGrey';
                    if(mp_permissions === 'Parent') mpColor = 'mpBlue';
                    else if(mp_permissions === 'Student') mpColor = 'mpOrange';
                    const mpPermissions = <Label color={mpColor}>{mp_permissions}</Label>;

                    let rpColor = 'mpLGrey';
                    if(rp_permissions === 'Parent') rpColor = 'mpBlue';
                    else if(rp_permissions === 'Student') rpColor = 'mpOrange';
                    const rpPermissions = <Label color={rpColor}>{rp_permissions}</Label>;

                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {mpPermissions}</div>
                            <div>RP: {rpPermissions}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'active',
                text: 'Active',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? -1 : 1;
                    const mpSort = rowB.mp_active - rowA.mp_active;
                    const rpSort = rowB.rp_active - rowA.rp_active;
                    return (mpSort || rpSort) * swap;
                },
                formatter: (row) => {
                    const mpActive = parseInt(row.mp_active) === 1 ? activeTag : inactiveTag;
                    const rpActive = parseInt(row.rp_active) === 1 ? activeTag : inactiveTag;
                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {mpActive}</div>
                            <div>RP: {rpActive}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'actions',
                text: 'Actions',
                formatter: (row) => {
                    return (
                        <div className="flex flex-row gap-x-2">
                            <TooltipWrapper
                                tooltipText={`Edit ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowMemberModal('edit', row)}
                                >
                                    <SVGIcon fileName="pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Delete ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowMemberModal('delete', row)}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                    );
                }
            }
        ]
    );
}

const gradeColorMap = {
    0: "mpGreen",
    1: "mpGreen",
    2: "mpGreen",
    3: "mpTeal",
    4: "mpTeal",
    5: "mpTeal",
    6: "mpDBlue",
    7: "mpDBlue",
    8: "mpDBlue",
    9: "mpOrange",
    10: "mpOrange",
    11: "mpOrange",
    12: "mpOrange",
    13: "mpPurple",
    "-1": "mpLGrey" // None
};

const gradeNameMap = {
    0: 'Pre-K/Kinder',
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
    5: '5th',
    6: '6th',
    7: '7th',
    8: '8th',
    9: '9th',
    10: '10th',
    11: '11th',
    12: '12th',
    13: 'HS Grad',
};

export function getStudentColumns(handleShowStudentModal, handleShowHoldsModal){
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return [
        {
            dataField: 'id',
            text: 'UUID',
            sort: true,
            formatter: (row) => {
                return(
                    <TooltipWrapper
                        tooltipText={row.user_id}
                    >
                        <div className="text-mpLBlue">[ID]</div>
                    </TooltipWrapper>
                )
            }
        }, {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const aName = `${rowA.first_name} ${rowA.last_name}`;
                const bName = `${rowB.first_name} ${rowB.last_name}`;
                const swap = order === 'asc' ? -1 : 1;
    
                if(aName < bName) return -1 * swap;
                else if(aName > bName) return 1 * swap;
                return 0;
            },
            formatter: (row) => {
                const isLead = parseInt(row.is_lead) === 1 ? ' (Lead)' : '';
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="grid grid-cols-1">
                                <div>Phone:</div>
                                <div>{renderPhone(row.userObject.phone)}</div>
                                <br/>
                                <div>Email:</div>
                                <div>{row.email || 'None'}</div>
                                <br/>
                                <div>Notes:</div>
                                <div> {row.userObject.user_notes || 'None'}</div>
                            </div>
                        }
                    >
                        <div className="text-mpLBlue">{row.first_name} {row.last_name}{isLead}</div>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'parentObject',
            text: 'Parent',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                if(a.first_name < b.first_name) return -1 * swap;
                else if(a.first_name > b.first_name) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const po = row.parentObject;
                if(!po.first_name && !po.last_name) return <div className="text-mpOrange">None</div>

                const isLead = po.isLead ? ' (Lead)' : '';
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="grid grid-cols-1">
                                <div>Phone:</div>
                                <div>{renderPhone(po.phone)}</div>
                                <br/>
                                <div>Email:</div>
                                <div>{po.email || 'None'}</div>
                                <br/>
                                <div>Notes:</div>
                                <div> {po.user_notes || 'None'}</div>
                            </div>
                        }
                    >
                        <div className="text-mpLBlue">{po.first_name} {po.last_name}{isLead}</div>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'grade',
            text: 'Grade',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aVal = parseInt(a);
                const bVal = parseInt(b);
                if(isNaN(aVal)) return 1 * swap;
                else if(isNaN(bVal)) return -1 * swap;
                return (aVal - bVal) * swap;
            },
            formatter: (row) => {
                const grade = row.grade;
                const color = gradeColorMap[parseInt(grade)] || "mpLRed";
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="grid grid-cols-1">
                                <div>Teacher: {row.mp_teacher || 'None'}</div>
                                <div>School: {row.school || 'None'}</div>
                            </div>
                        }
                    >
                        <Label color={color}>
                            {(color === "mpLRed" ? grade : gradeNameMap[parseInt(grade)]) || 'None'}
                        </Label>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'hours',
            text: 'Hours',
            sort: false,
            formatter: (row) => {
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="grid grid-cols-1">
                                <div>Hours left/used/total (reserve used) for...</div>
                                <br/>
                                <div>This month:</div>
                                <div>{row.mp_time_left_this_month / 60}/{row.mp_time_used_this_month / 60}/{row.mp_time_given_this_month / 60} ({row.mp_reserve_used_this_month / 60})</div>
                                <br/>
                                <div>Next month:</div>
                                <div>{row.mp_time_left_next_month / 60}/{row.mp_time_used_next_month / 60}/{row.mp_time_given_next_month / 60} ({row.mp_reserve_used_next_month / 60})</div>
                                <br/>
                                <div>Reserve hours available: {row.mp_reserve_time_left / 60}</div>
                            </div>
                        }
                    >
                        <div className="flex flex-row gap-x-4 text-mpLBlue">
                            T: {row.mp_time_left_this_month / 60}/{row.mp_time_given_this_month / 60},&nbsp;
                            N: {row.mp_time_left_next_month / 60}/{row.mp_time_given_next_month / 60},&nbsp;
                            R: {row.mp_reserve_time_left / 60}
                        </div>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'mp_next_month_start',
            text: 'Hours Reset On',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                if(a < b) return 1 * swap;
                else if(a > b) return -1 * swap;
                else return 0;
            },
            formatter: (row) => formatDate(row.mp_next_month_start),
        }, {
            dataField: 'mp_inactive_date',
            text: 'Inactive Date',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                if(a < b) return 1 * swap;
                else if(a > b) return -1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const inactiveDate = new Date(row.mp_inactive_date);
                if(inactiveDate.getFullYear() >= 2100) return <div className="text-mpGreen">Never</div>;
                return formatDate(row.mp_inactive_date);
            }    
        }, {
            dataField: 'contract',
            text: 'Contract',
            formatter: (row) => {
                const contract = row.contract;
                if(!contract.id) return <div className='text-mpLRed'>None</div>;
                const expiresContent = parseInt(contract.expires) === 1 ?
                    <>
                        <div>Start: {formatDate(contract.start_date)}</div>
                        <div>End: {formatDate(contract.end_date)}</div>
                    </> :
                    <div>Never Expires</div>
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="grid grid-cols-1">
                                <div>Type: {contract.type}</div>
                                {expiresContent}
                                <div>Payment Freq: {contract.payment_frequency}</div>
                                <div>PD Offset: {contract.payment_date_offset} days</div>
                                <div>$ Per Payment: ${contract.amount_per_payment}</div>
                                <div>Show Warnings: {parseInt(contract.show_warnings) === 1 ? 'Yes' : 'No'}</div>
                                <div>Warn Members: {parseInt(contract.warn_members) === 1 ? 'Yes' : 'No'}</div>
                                <br/>
                                <div>Notes: {contract.notes.length > 100 ? `${contract.notes.slice(0, 100)}...` : contract.notes}</div>
                            </div>
                        }
                    >
                        <SVGIcon fileName="contract"/>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(rowA.mp_active !== rowB.mp_active) return (rowA.mp_active - rowB.mp_active) * swap;
                return 0;
            },
            formatter: (row) => {
                const activeLabel = row.mp_active ? 
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                <div>Inactive Date:</div>
                                <div>
                                    {(new Date(row.mp_inactive_date)).getFullYear() >= 2100 ? 'Never' :
                                    formatDateFull(row.mp_inactive_date)}
                                </div>
                            </div>
                        }
                    >
                        <Label color="mpGreen">Active</Label>
                    </TooltipWrapper>
                    : <Label color="mpLRed">Inactive</Label>;
                const holdLabel = new Date(row.mp_hold_start) <= today && new Date(row.mp_hold_end) >= today ? 
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                <div>On hold:</div>
                                <div>{formatDateFull(row.mp_hold_start)}</div>
                                <div>to</div>
                                <div>{formatDateFull(row.mp_hold_end)}</div>
                            </div>
                        }
                    >
                        <Label color="mpYellow">On Hold</Label>
                    </TooltipWrapper>
                    : null;
                return <div className="flex flex-row">{activeLabel} &emsp; {holdLabel}</div>
            }
        }, {
            dataField: 'alerts',
            text: 'Special',
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                const currentContractExists = row.mp_current_contract && parseInt(row.mp_current_contract) !== -1;
                const isActive = parseInt(row.mp_active) === 1;
                const studentName = `${row.first_name} ${row.last_name}`;
                const tooltipText = currentContractExists && isActive ?
                `Place ${studentName} on hold`
                : isActive ?
                    `No contract is assigned to this student.  Only active students with contracts can be placed on hold.`
                : currentContractExists ?
                    `This student is inactive. Only active students with contracts can be placed on hold.`
                :  `This student is inactive with no contract. Only active students with contracts can be placed on hold.`
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit ${studentName}`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowStudentModal(row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        {row.mp_monthly_report_link ? 
                            <TooltipWrapper
                                tooltipText={`View ${studentName}'s monthly report sheet`}
                            >
                                <Button
                                    color="lte-mpEGreen"
                                    variant="icon"
                                    onClick={() => window.open(row.mp_monthly_report_link, '_blank')}
                                >
                                    <SVGIcon fileName="external-link"/>
                                </Button>
                            </TooltipWrapper>
                        :
                            <TooltipWrapper
                                tooltipText="No monthly report link provided"
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => null}
                                    disabled
                                >
                                    <SVGIcon fileName="unlink"/>
                                </Button>
                            </TooltipWrapper>
                        }
                        <TooltipWrapper
                            tooltipText={tooltipText}
                        >
                            <Button
                                color={'lte-mpYellow'}
                                disabled={!(currentContractExists && isActive)}
                                variant="icon"
                                onClick={currentContractExists && isActive ? () => handleShowHoldsModal(row)
                                    : () => null }
                            >
                                <SVGIcon fileName="hand"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            },
        }
    ];
}

export function getLeadColumns(handleShowLeadModal){
    const permissionsMap = {
        "parent": 1,
        "student": 2,
        "None": 3
    };
    const activeTag = <Label color="mpGreen">Active</Label>;
    const inactiveTag = <Label color="mpLRed">Inactive</Label>;
    return(
        [
            {
                dataField: 'id',
                text: 'UUID',
                sort: true,
                formatter: (row) => {
                    const cell = row.id;
                    return(
                        <TooltipWrapper
                            tooltipText={cell}
                        >
                            <div className="text-mpLBlue">
                                [ID]
                            </div>
                        </TooltipWrapper>
                    )
                }
            }, {
                dataField: 'name',
                text: 'Name',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const aName = `${rowA.first_name} ${rowA.last_name}`;
                    const bName = `${rowB.first_name} ${rowB.last_name}`;
                    const swap = order === 'asc' ? -1 : 1;
        
                    if(aName < bName) return -1 * swap;
                    else if(aName > bName) return 1 * swap;
                    return 0;
                },
                formatter: (row) => {
                    const name = `${row.first_name} ${row.last_name}`;
                    if(row.mp_permissions === 'Parent' || row.rp_permissions === 'Parent'){
                        return (
                            <TooltipWrapper
                                tooltipText={
                                    <>
                                        <div>Students:</div>
                                        {row.studentList ? 
                                            <div>{row.studentList.join(', ')}</div> :
                                            <div className="text-mpOrange">None</div>
                                        }
                                    </>
                                }
                            >
                                <div className="text-mpLBlue">{name}</div>
                            </TooltipWrapper>
                        );
                    } else if(row.mp_permissions === 'Student' || row.rp_permissions === 'Student'){
                        return name;
                    }
                }
            }, {
                dataField: 'email',
                text: 'Email',
                sort: true,
            }, {
                dataField: 'phone',
                text: 'Phone Number',
                sort: true,
                formatter: (row) => {
                    let number = row.phone.toString();
                    if(!number.length || number === '0' || number === '0000000000'){
                        return <Label color="mpLRed">None</Label>;
                    }
                    return renderPhone(number);
                }
            }, {
                dataField: 'primary_center',
                text: 'Primary Center',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const sortBy = order === 'asc' ? 'mp' : 'rp';
                    if(sortBy === 'mp'){
                        if(a.mpCenterName < b.mpCenterName) return -1;
                        else if(a.mpCenterName > b.mpCenterName) return 1;
                        else return 0;
                    } else if (sortBy === 'rp'){
                        if(a.rpCenterName < b.rpCenterName) return -1;
                        else if(a.rpCenterName > b.rpCenterName) return 1;
                        else return 0;
                    }
                },
                formatter: (row) => {
                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {row.mpCenterName}</div>
                            <div>RP: {row.rpCenterName}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'permissions',
                text: 'Permissions',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? -1 : 1;
                    const mpPermissionsA = rowA.mp_permissions;
                    const mpPermissionsB = rowB.mp_permissions;
                    const rpPermissionsA = rowA.rp_permissions;
                    const rpPermissionsB = rowB.rp_permissions;

                    const priorityA = permissionsMap[mpPermissionsA.toLowerCase()] || 99 + permissionsMap[rpPermissionsA.toLowerCase()] || 99;
                    const priorityB = permissionsMap[mpPermissionsB.toLowerCase()] || 99 + permissionsMap[rpPermissionsB.toLowerCase()] || 99;
                    return (priorityA - priorityB) * swap;
                },
                formatter: (row) => {
                    const { mp_permissions, rp_permissions } = row;

                    let mpColor = 'mpLGrey';
                    if(mp_permissions === 'Parent') mpColor = 'mpBlue';
                    else if(mp_permissions === 'Student') mpColor = 'mpOrange';
                    const mpPermissions = <Label color={mpColor}>{mp_permissions}</Label>;

                    let rpColor = 'mpLGrey';
                    if(rp_permissions === 'Parent') rpColor = 'mpBlue';
                    else if(rp_permissions === 'Student') rpColor = 'mpOrange';
                    const rpPermissions = <Label color={rpColor}>{rp_permissions}</Label>;

                    return (
                        <div className="grid grid-cols-2 gap-y-2">
                            <div>MP: {mpPermissions}</div>
                            <div>RP: {rpPermissions}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'active',
                text: 'Active',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? -1 : 1;

                    const mpSort = rowB.mp_active - rowA.mp_active;
                    const rpSort = rowB.rp_active - rowA.rp_active;
                    return (mpSort || rpSort) * swap;
                },
                formatter: (row) => {
                    const mpActive = parseInt(row.mp_active) === 1 ? activeTag : inactiveTag;
                    const rpActive = parseInt(row.rp_active) === 1 ? activeTag : inactiveTag;
                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {mpActive}</div>
                            <div>RP: {rpActive}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'actions',
                text: 'Actions',
                formatter: (row) => {
                    return (
                        <div className="flex flex-row gap-x-2">
                            <TooltipWrapper
                                tooltipText={`Edit ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowLeadModal('edit', row)}
                                    style={{ marginRight: "5px" }}
                                >
                                    <SVGIcon fileName="pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Delete ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowLeadModal('delete', row)}
                                    style={{ marginRight: "5px" }}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Convert ${row.first_name} ${row.last_name} into a Member User`}
                            >
                                <Button
                                    color="lte-mpYellow"
                                    variant="icon"
                                    onClick={() => handleShowLeadModal('convert', row)}
                                    style={{ marginRight: "5px" }}
                                >
                                    <SVGIcon fileName="convert"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                    );
                }
            }
        ]
    );
}