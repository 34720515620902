export function formatStrings(obj){
    obj = JSON.parse(JSON.stringify(obj));

    for(let key in obj){
        const val = obj[key];
        if(typeof(val) === 'string'){
            const str = formatString(val);
            obj[key] = str;
        } else if(typeof(val) === 'object'){
            obj[key] = formatStrings(val);
        }
    }

    return obj;
}

export function formatString(str){
    return str.replace(/'/g, `''`).trim();
}