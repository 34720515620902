import React from 'react';
import { formatDateFull, formatTimeFromDate, gethms } from '../../../../../components/functions';

import { Button, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumnsAttendanceHistory(){
    return [
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;

                if(!rowA.time_out) return -1;
                if(!rowB.time_out) return 1;

                return (new Date(rowB.time_in) - new Date(rowA.time_in)) * swap;
            },
            formatter: (row) => formatDateFull(row.time_in)
        }, {
            dataField: 'time_in',
            text: 'Start Time',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;

                const [aHour, aMinute, aSecond] = gethms(a);
                const [bHour, bMinute, bSecond] = gethms(b);
                const aTime = aHour * 3600 + aMinute * 60 + aSecond * 1;
                const bTime = bHour * 3600 + bMinute * 60 + bSecond * 1;
                return (aTime - bTime) * swap;
            },
            formatter: (row) => formatTimeFromDate(row.time_in)
        }, {
            dataField: 'time_out',
            text: 'End Time',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;

                const [aHour, aMinute, aSecond] = gethms(a);
                const [bHour, bMinute, bSecond] = gethms(b);
                const aTime = aHour * 3600 + aMinute * 60 + aSecond * 1;
                const bTime = bHour * 3600 + bMinute * 60 + bSecond * 1;
                return (aTime - bTime) * swap;
            },
            formatter: (row) => row.time_out ? formatTimeFromDate(row.time_out) : '-'
        }, {
            dataField: 'hoursMinutes',
            text: 'Hours (minutes)',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                
                const aDuration = rowA.time_out ? (new Date(rowA.time_out) - new Date(rowA.time_in)) : 0;
                const bDuration = rowB.time_out ? (new Date(rowB.time_out) - new Date(rowB.time_in)) : 0;
                if(isNaN(a)) return -1;
                else if(isNaN(b)) return 1;

                else return (bDuration - aDuration) * swap;
            },
            formatter: (row) => {
                const minutes = Math.floor((new Date(row.time_out) - new Date(row.time_in))/(60*1000));

                if(!row.time_out) return '-';

                const hours = (minutes / 60).toLocaleString(undefined, { maximumFractionDigits: 2 });

                return `${hours} (${minutes})`;
            }
        },
    ];
}

export function getColumnsTimesheets(handleShowModal){
    return [
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;

                if(!rowA.time_out) return -1;
                if(!rowB.time_out) return 1;

                return (new Date(rowB.time_in) - new Date(rowA.time_in)) * swap;
            },
            formatter: (row) => formatDateFull(row.time_in)
        }, {
            dataField: 'time_in',
            text: 'Start Time',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;

                const [aHour, aMinute, aSecond] = gethms(a);
                const [bHour, bMinute, bSecond] = gethms(b);
                const aTime = aHour * 3600 + aMinute * 60 + aSecond * 1;
                const bTime = bHour * 3600 + bMinute * 60 + bSecond * 1;
                return (aTime - bTime) * swap;
            },
            formatter: (row) => formatTimeFromDate(row.time_in)
        }, {
            dataField: 'time_out',
            text: 'End Time',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;

                const [aHour, aMinute, aSecond] = gethms(a);
                const [bHour, bMinute, bSecond] = gethms(b);
                const aTime = aHour * 3600 + aMinute * 60 + aSecond * 1;
                const bTime = bHour * 3600 + bMinute * 60 + bSecond * 1;
                return (aTime - bTime) * swap;
            },
            formatter: (row) => row.time_out ? formatTimeFromDate(row.time_out) : '-'
        }, {
            dataField: 'hoursMinutes',
            text: 'Hours (minutes)',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                
                const aDuration = (new Date(rowA.time_out) - new Date(rowA.time_in));
                const bDuration = (new Date(rowB.time_out) - new Date(rowB.time_in));
                if(isNaN(a)) return -1;
                else if(isNaN(b)) return 1;

                else return (bDuration - aDuration) * swap;
            },
            formatter: (row) => {
                const minutes = Math.floor((new Date(row.time_out) - new Date(row.time_in))/(60*1000));

                if(!row.time_out) return '-';

                const hours = (minutes / 60).toLocaleString(undefined, { maximumFractionDigits: 2 });

                return `${hours} (${minutes})`;
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit entry`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete entry`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}