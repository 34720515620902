import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl, Check } from '../../form';
import { renderPhone } from '../../display';
import { validationSchema, getInitCenterOption, mpPermissionsOptions,
    rpPermissionsOptions, getInitPermissionsOption } from './helpers';

// Exact same form as the Employee Modal, with variables and actions changed slightly
function MembersBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedMember, mpCenterOptions, rpCenterOptions, loaded, passwordReset, resetSuccess,
        resendWelcome, resendSuccess, showAdditional, setShowAdditional, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const memberName = `${selectedMember.first_name} ${selectedMember.last_name}`;
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    confirmName: ''
                }}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>
                                Delete this member?
                            </h4>
                            <div className="text-mpOrange">
                                All appointments and actions will be permanently unlinked.
                            </div>
                            <br/>
                            <h4 className="text-mpOrange">
                                This cannot be undone.
                            </h4>
                            <div className="h-2 clear-both"/>
                            <div className="grid grid-cols-1">
                                <div>Name: {memberName}</div>
                                <div>Email: {selectedMember.email}</div>
                                <div>Phone: {renderPhone(selectedMember.phone)}</div>
                                <div>MP Permissions: {selectedMember.mp_permissions}</div>
                                <div>RP Permissions: {selectedMember.rp_permissions}</div>
                            </div>

                            <br/>
                            <br/>

                            <h4>Enter this user's name to proceed:</h4>
                            <div className="grid grid-cols-1">
                                <FormikControl
                                    id="delete-member-confirmName"
                                    name="confirmName"
                                    value={formik.values.confirmName}
                                    onChange={formik.handleChange}
                                    placeholder={memberName}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 w-full flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                { !formik.values.firstConfirm ? (
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            onClick={() => formik.setFieldValue('firstConfirm', true)}
                                            disabled={formik.values.confirmName !== memberName}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="mr-auto flex flex-row gap-x-2 flex-wrap">
                                            <Button
                                                color="hol-mpLRed"
                                                disabled={formik.isSubmitting || formik.values.confirmName !== memberName}
                                                onClick={(e) => {
                                                    formik.setFieldValue('firstConfirm', false);
                                                    formik.handleSubmit(e)
                                                }}
                                            >
                                                Confirm Delete
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                disabled={formik.isSubmitting}
                                                onClick={() => {
                                                    formik.setStatus('');
                                                    formik.setFieldValue('firstConfirm', false);
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <div className="text-mpOrange">
                                            Are you sure?
                                        </div>
                                    </>
                                )}
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    email: selectedMember.email || '',
                    receiveEmails: parseInt(selectedMember.receive_emails) === 1 ? true : false,
                    firstName: selectedMember.first_name || '',
                    lastName: selectedMember.last_name || '',
                    phone: selectedMember.phone || '',
                    receiveTexts: parseInt(selectedMember.receive_texts) === 1 ? true : false,
                    mpPrimaryCenter: getInitCenterOption(selectedMember.mp_primary_center, mpCenterOptions),
                    rpPrimaryCenter: getInitCenterOption(selectedMember.rp_primary_center, rpCenterOptions),
                    mpPermissions: getInitPermissionsOption(selectedMember.mp_permissions, mpPermissionsOptions),
                    rpPermissions: getInitPermissionsOption(selectedMember.rp_permissions, rpPermissionsOptions),
                    userNotes: selectedMember.user_notes || '',
                    verified: parseInt(selectedMember.verified) === 1 ? true : false,
                    mpActive: parseInt(selectedMember.mp_active) === 0 ? false : true,
                    rpActive: parseInt(selectedMember.rp_active) === 0 ? false : true,
                    shouldSendWelcomeEmail: mode === 'create' ? true : false,

                    passwordReset: '',
                    passwordConfirm: '',
                    resendWelcomeConfirm: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>First Name</h4>
                                    <FormikControl
                                        id="members-first-name"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.firstName ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.firstName}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Last Name</h4>
                                    <FormikControl
                                        id="members-first-name"
                                        name="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.lastName ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.lastName}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                            <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Email</h4>
                                    <FormikControl
                                        id="members-email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        placeholder="riley@mathplextutoring.com"
                                    />
                                    {formik.errors.email ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.email}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <h4>Phone</h4>
                                    <FormikControl
                                        id="members-phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        placeholder="9493294040"
                                    />
                                    {formik.errors.phone ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.phone}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>MP Primary Center</h4>
                                    <SelectSingle
                                        id="employees-mpCenter"
                                        name="mpPrimaryCenter"
                                        value={formik.values.mpPrimaryCenter}
                                        onChange={formik.handleChange}
                                        options={mpCenterOptions}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.mpPrimaryCenter ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.mpPrimaryCenter}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>MP Permissions</h4>
                                    <SelectSingle
                                        id="employees-mpPermissions"
                                        name="mpPermissions"
                                        value={formik.values.mpPermissions}
                                        onChange={formik.handleChange}
                                        options={mpPermissionsOptions}
                                    />
                                    {formik.errors.mpPermissions ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.mpPermissions}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>RP Primary Center</h4>
                                    <SelectSingle
                                        id="employees-mpCenter"
                                        name="rpPrimaryCenter"
                                        value={formik.values.rpPrimaryCenter}
                                        onChange={formik.handleChange}
                                        options={rpCenterOptions}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.rpPrimaryCenter ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.rpPrimaryCenter}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>RP Permissions</h4>
                                    <SelectSingle
                                        id="employees-rpPermissions"
                                        name="rpPermissions"
                                        value={formik.values.rpPermissions}
                                        onChange={formik.handleChange}
                                        options={rpPermissionsOptions}
                                    />
                                    {formik.errors.rpPermissions ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.rpPermissions}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <h4>User Notes</h4>
                                <FormikControl
                                    id="members-user-notes"
                                    name="userNotes"
                                    as="textarea"
                                    value={formik.values.userNotes}
                                    onChange={formik.handleChange}
                                    placeholder="Max 5,000 characters"
                                />
                                {formik.errors.userNotes ? (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.userNotes}
                                    </ErrorMessage>
                                ) : null}
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="flex flex-row gap-x-4">
                                    <Check
                                        id="members-receive-emails"
                                        name="receiveEmails"
                                        label="Receive Emails"
                                        color="mpLRed"
                                        checked={formik.values.receiveEmails}
                                        onChange={formik.handleChange}
                                    />
                                    <Check
                                        id="members-receive-texts"
                                        name="receiveTexts"
                                        label="Receive Texts"
                                        color="mpLRed"
                                        checked={formik.values.receiveTexts}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                {mode === 'create' ? 
                                    <div className="flex flex-row gap-x-4">
                                        <Check
                                            id="members-shouldSendWelcomeEmail"
                                            name="shouldSendWelcomeEmail"
                                            label="Send Welcome Email"
                                            color="mpLRed"
                                            checked={formik.values.shouldSendWelcomeEmail}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                : 
                                    <>
                                        <div className="flex flex-row gap-x-4">
                                            <Check
                                                id="members-verified"
                                                name="verified"
                                                label="Verified"
                                                color="mpLRed"
                                                checked={formik.values.verified}
                                                onChange={formik.handleChange}
                                            />
                                            <Check
                                                id="members-mpActive"
                                                name="mpActive"
                                                label="MP Active"
                                                color="mpLRed"
                                                checked={formik.values.mpActive}
                                                onChange={formik.handleChange}
                                            />
                                            <Check
                                                id="members-rpActive"
                                                name="rpActive"
                                                label="RP Active"
                                                color="mpLRed"
                                                checked={formik.values.rpActive}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2">
                                                {formik.values.mpPermissions.value === 'None' && formik.values.mpActive ?
                                                    <div className="text-mpLRed">
                                                        If MP Permissions is None, MP Active must be off.
                                                    </div> : null
                                                }
                                                {formik.values.rpPermissions.value === 'None' && formik.values.rpActive ?
                                                    <div className="text-mpLRed">
                                                        If RP Permissions is None, RP Active must be off.
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            	
                            {mode !== 'create' ? 
                                <>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    {showAdditional ? 
                                        <>
                                            <h6
                                                className="text-mpLBlu table cursor-pointer"
                                                onClick={() => {
                                                    const newValues = formik.values;
                                                    newValues.passwordReset = '';
                                                    newValues.passwordConfirm = '';
                                                    formik.setValues(newValues);
                                                    setShowAdditional(false);
                                                }}
                                            >
                                                Hide Additional Fields
                                            </h6>
                                            
                                            <br/>

                                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                                <h4>Reset Password</h4>
                                                <FormikControl
                                                    id="members-password-reset"
                                                    name="passwordReset"
                                                    value={formik.values.passwordReset}
                                                    onChange={formik.handleChange}
                                                    // type="password"
                                                    placeholder="One lower case letter, one upper case letter, one number"
                                                />
                                                {formik.errors.passwordReset ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.passwordReset}
                                                    </ErrorMessage>
                                                ) : null}

                                                <br/>

                                                <h4>Confirm Password</h4>
                                                <FormikControl
                                                    id="members-password-reset"
                                                    name="passwordConfirm"
                                                    value={formik.values.passwordConfirm}
                                                    // type="password"å
                                                    onChange={formik.handleChange}
                                                />
                                                {formik.errors.passwordConfirm ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.passwordConfirm}
                                                    </ErrorMessage>
                                                ) : null}

                                                {resetSuccess ? 
                                                    <>
                                                        <div className="text-mpGreen">Password reset successfully!</div>
                                                        <div className="h-4 clear-both"/>
                                                    </>
                                                : null}

                                                <div className="flex flex-row ml-auto">
                                                    <Button
                                                        color="lte-mpLBlue"
                                                        disabled={resetSuccess || formik.isSubmitting || formik.errors.passwordReset ||
                                                            formik.errors.passwordConfirm || (formik.values.passwordReset !== formik.values.passwordConfirm)}
                                                        onClick={() => passwordReset(formik)}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                            
                                            <br/>
                                            
                                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                                <h4>Resend Welcome Email</h4>
                                                <div className="flex flex-row gap-x-2">
                                                    <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                                        <FormikControl
                                                            id="members-resend-welcome-confirm"
                                                            name="resendWelcomeConfirm"
                                                            value={formik.values.resendWelcomeConfirm}
                                                            onChange={formik.handleChange}
                                                            placeholder={`Type 'Resend Email' to proceed.`}
                                                        />
                                                    </div>
                                                    <Button
                                                        color="lte-mpLBlue"
                                                        disabled={resendSuccess || formik.isSubmitting || formik.values.resendWelcomeConfirm !== 'Resend Email'}
                                                        onClick={() => resendWelcome(formik)}
                                                    >
                                                        Send Email
                                                    </Button>
                                                </div>
                                                {resendSuccess ? 
                                                    <>
                                                        <div className="text-mpGreen">Welcome email resent successfully!</div>
                                                        <br/>
                                                    </>
                                                : null}
                                            </div>

                                        </>
                                        :
                                        <>
                                            <h5
                                                className="text-mpLBlue table cursor-pointer"
                                                onClick={() => setShowAdditional(true)}
                                            >
                                                Show Additional Fields
                                            </h5>
                                        </>
                                    }
                                </> : null
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(MembersBodyFooter);