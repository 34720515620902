import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatDateTime } from "../../../../../components/functions";
import { TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    const noneTag = <div className="text-mpOrange">None</div>
    return [
        {
            dataField: 'date_created',
            text: 'Date Created',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                return(
                    <TooltipWrapper
                        tooltipText={row.createdByName}
                    >
                        <div className="text-mpLBlue">{formatDateTime(row.date_created)}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            formatter: (row) =>{
                const centerName = row.centerName;
                let color = '';
                if(colorMap[centerName]) color = colorMap[centerName];
                else {
                    colorMap[centerName] = colors[(colorIndex++) % 5];
                    color = colorMap[centerName];
                }
                return (
                    <Label color={color}>
                        {centerName}
                    </Label>
                );
            }
        },
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true
        },
        {
            dataField: 'tag',
            text: 'Tag',
            sort: true
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => {
                return (
                    <>
                        <div className="flex flex-row"><b>General:</b>&nbsp;{row.notes || noneTag}</div>
                        <div className="flex flex-row"><b>Admin:</b>&nbsp;{row.admin_notes || noneTag}</div>
                    </>
                );
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: 'date_updated',
            text: 'Date Updated',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                if(!row.date_updated) return <div className="text-mpOrange">Never</div>;
                return(
                    <TooltipWrapper
                        tooltipText={row.updatedByName}
                    >
                        <div className="text-mpLBlue">{formatDateTime(row.date_updated)}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit flag`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete flag`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}