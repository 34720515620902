import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { formatDateFull, getymd, downloadFile, generateStringBlocks } from '../../components/functions';

export async function generateAssessmentReport(assessment, lessons, questions){
    const lessonMap = {};
    lessons.forEach(l => {
        lessonMap[parseInt(l.id)] = l;
    });
    
    let totalQuestions = 0;
    let totalCorrect = 0;
    const lessonScores = {};
    const defaultScoreItem = { nQuestions: 0, nCorrect: 0 };

    // Get results
    questions.forEach(q => {
        const on1 = parseInt(q.on_incorrect_1);
        const on2 = parseInt(q.on_incorrect_2);
        const on3 = parseInt(q.on_incorrect_3);
        if(on1 !== -1 && !lessonScores[on1]) lessonScores[on1] = { ...defaultScoreItem };
        if(on2 !== -1 && !lessonScores[on2]) lessonScores[on2] = { ...defaultScoreItem };
        if(on3 !== -1 && !lessonScores[on3]) lessonScores[on3] = { ...defaultScoreItem };

        const isCorrect = parseInt(q.is_correct) === 1;

        if(on1 !== -1){
            lessonScores[on1].nQuestions++;
            if(isCorrect) lessonScores[on1].nCorrect++;
        }
        if(on2 !== -1){
            lessonScores[on2].nQuestions++;
            if(isCorrect) lessonScores[on2].nCorrect++;
        }
        if(on3 !== -1){
            lessonScores[on3].nQuestions++;
            if(isCorrect) lessonScores[on3].nCorrect++;
        }

        totalQuestions++;
        if(isCorrect) totalCorrect++;
    });

    const totalPercentCorrect = (totalCorrect * 100 / totalQuestions).toLocaleString(undefined, { maximumFractionDigits: 2 }) * 1;

    // Calculate percentages
    Object.entries(lessonScores).forEach(([key, value]) => {
        value.percentCorrect = (value.nCorrect * 100 / value.nQuestions).toLocaleString(undefined, { maximumFractionDigits: 2 }) * 1;
        value.lessonObj = lessonMap[key] || {};
    });

    const lessonsToRender = [];
    Object.values(lessonScores).forEach(l => {
        lessonsToRender.push(l);
    });
    lessonsToRender.sort((a, b) => parseInt(a.lessonObj.sort_order) - parseInt(b.lessonObj.sort_order));

    // Set up PDF //
    const reportPdf = await PDFDocument.create();
    const helveticaFont = await reportPdf.embedFont(StandardFonts.Helvetica);
    let currentPage = reportPdf.addPage();

    // MASTER VARIABLES //
    const { width, height } = currentPage.getSize();

    // width 595.28 = 70.03 pt per inch, height 841.89 = 76.55 pt per inch
    // 44 quarter inches (11 inches) tall, 34 quarter inches (8 1/2 inches) wide
    const yMarginQuarterInches = 3;
    const yMin = height * (yMarginQuarterInches/44) + 60; // + 60 so that the logo on the bottom left does not collide with text
    const yMax = height - (height * (yMarginQuarterInches/44));
    const xMarginQuarterInches = 2;
    const xMin = width * (xMarginQuarterInches/34);
    const xMax = width - (width * (xMarginQuarterInches/34));

    let yPos = yMax;

    // PRE-HEADER//
    // Student Name - Written as a function since it needs to be drawn at the top of every page
    function drawPreheaderRow(currentPage){
        const preheaderText = `${assessment.studentName} -- ${assessment.name} (${formatDateFull(assessment.date_taken)})`;

        currentPage.drawText(preheaderText, {
            x: 30,
            y: height - 30,
            size: 10,
            font: helveticaFont
        });
    }
    
    // COLUMN ALIGNMENT //
    const lessonNameXOffset = 10;
    const qcpXOffset = (xMax - xMin) * 4/7 + lessonNameXOffset; // QCP = "Questions Correct (Percent)"

    // HEADER - Written as a function since it needs to be drawn at the top of every page //
    function drawHeaderRow(currentPage){
        const headerSize = 15;
        // 3/4 because .heightAtSize returns the full height of the text field, not the height of the text itself
        const headerHeight = helveticaFont.heightAtSize(headerSize) * 3/4; 

        // Red rectangle
        const headerRectangleYMargin = 7;
        const headerRectangleHeight = headerHeight + headerRectangleYMargin * 2;
        currentPage.drawRectangle({
            x: xMin,
            y: yMax - headerRectangleHeight,
            width: xMax - xMin,
            height: headerRectangleHeight,
            color: rgb(153/255, 0/255, 0/255)
        });

        yPos -= headerRectangleHeight;
        yPos += headerRectangleYMargin;

        // "Lesson"
        currentPage.drawText('Topic', {
            x: xMin + lessonNameXOffset,
            y: yPos,
            size: headerSize,
            font: helveticaFont,
            color: rgb(255/255, 255/255, 255/255)
        });

        // "Questions Correct (Percent)"
        currentPage.drawText('Questions Correct (Percent)', {
            x: xMin + qcpXOffset,
            y: yPos,
            size: headerSize,
            font: helveticaFont,
            color: rgb(255/255, 255/255, 255/255)
        });

        yPos -= headerRectangleYMargin;
    }

    drawPreheaderRow(currentPage);
    drawHeaderRow(currentPage);

    // CREATE ROWS
    const rowTextSize = 10;
    const lessonNameMaxWidth = qcpXOffset - xMin - 20;
    const lessonNameHeight = helveticaFont.heightAtSize(rowTextSize) * 3/4;
    const rectangleYMargin = 7;
    // const lessonQcpMaxWidth = xMax - qcpXOffset - 20; // We should never reach this length with two numbers and a percent

    let colorAlternator = 0;
    function getRectangleColor(n){
        if(n % 2 === 0) return rgb(245/255, 245/255, 245/255)
        else return rgb(244/255, 204/255, 204/255);
    }

    // Draw rows
    for(let lesson of lessonsToRender){
        const multipleBlocksOffsetAfter = 5;
        const lessonNameBlocks = generateStringBlocks(helveticaFont, rowTextSize, lesson.lessonObj?.name,
            lessonNameMaxWidth);
        // nRows * rowHeight + multiple block offset for each row beyond 1
        const lessonNameTotalHeight = lessonNameBlocks.length * lessonNameHeight + (lessonNameBlocks.length - 1) * multipleBlocksOffsetAfter;
        const rectangleHeight = lessonNameTotalHeight + rectangleYMargin * 2;

        // Draw a new page if needed
        if(yPos - rectangleHeight < yMin){
            currentPage = reportPdf.addPage();
            yPos = yMax;
            
            drawPreheaderRow(currentPage);
            drawHeaderRow(currentPage);
        }
        
        yPos -= rectangleHeight;

        // Rectangle
        currentPage.drawRectangle({
            x: xMin,
            y: yPos,
            width: xMax - xMin,
            height: rectangleHeight,
            color: getRectangleColor(colorAlternator),
        });
        
        yPos += (rectangleHeight - lessonNameHeight - rectangleYMargin);

        // Lesson Name
        let rowNumber = 1;
        for(let block of lessonNameBlocks){
            currentPage.drawText(block, {
                x: xMin + lessonNameXOffset,
                y: yPos,
                size: rowTextSize,
                font: helveticaFont
            });

            // Add spacing between text rows, except for the last row
            if(rowNumber !== lessonNameBlocks.length) yPos -= (lessonNameHeight + multipleBlocksOffsetAfter);

            rowNumber++;
        }

        const qcpText = `${lesson.nCorrect}/${lesson.nQuestions} (${lesson.percentCorrect}%)`;

        // QCP
        currentPage.drawText(qcpText, {
            x: xMin + qcpXOffset,
            y: yPos,
            size: rowTextSize,
            font: helveticaFont
        });

        yPos -= rectangleYMargin;

        colorAlternator++;
    }

    // Totals
    const footerSize = 15;
    // 3/4 because .heightAtSize returns the full height of the text field, not the height of the text itself
    const footerHeight = helveticaFont.heightAtSize(footerSize) * 3/4; 

    // Red rectangle
    const footerRectangleYMargin = 7;
    const footerRectangleHeight = footerHeight + footerRectangleYMargin * 2;
    yPos -= footerRectangleHeight;

    if(yPos < yMin){
        reportPdf.addPage();
        yPos = yMax;
    }

    currentPage.drawRectangle({
        x: xMin,
        y: yPos,
        width: xMax - xMin,
        height: footerRectangleHeight,
        color: rgb(153/255, 0/255, 0/255)
    });

    yPos += footerRectangleYMargin;

    // "Lesson"
    currentPage.drawText('Total', {
        x: xMin + lessonNameXOffset,
        y: yPos,
        size: footerSize,
        font: helveticaFont,
        color: rgb(255/255, 255/255, 255/255)
    });

    // "Questions Correct (Percent)"
    currentPage.drawText(`${totalCorrect}/${totalQuestions} (${totalPercentCorrect}%)`, {
        x: xMin + qcpXOffset,
        y: yPos,
        size: footerSize,
        font: helveticaFont,
        color: rgb(255/255, 255/255, 255/255)
    });

    yPos -= footerRectangleYMargin;

    const pdfBytes = await reportPdf.save();

    const [year, month, date] = getymd(assessment.date_taken);

    downloadFile(pdfBytes, `${assessment.studentName}_${assessment.name}_${year}-${month}-${date}.pdf`);
}