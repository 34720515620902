import React from 'react';

function renderAppointmentGroup(appointments, style){
    if(!appointments?.length) return <h6 className="text-mpOrange">None</h6>;
    function renderStart(a){
        const startTime = a.startTimeFormatted;
        if(style === 'start') return <span className="text-mpGreen">{startTime}</span>;
        else return startTime;
    }
    function renderEnd(a){
        const endTime = a.endTimeFormatted;
        if(style === 'end') return <span className="text-mpLRed">{endTime}</span>;
        else return endTime;
    }
    function getMainText(a){
        return <h6>{a.studentName} - {renderStart(a)} to {renderEnd(a)}</h6>;
    }
    function getSchedulingNotes(a){
        if(a.special_notes_schedule.trim()){
            return (
                <>
                    <div className="row no-mar" style={{ marginLeft: "1rem" }}>
                        Scheduling Notes: &nbsp;
                        <span className="text-mpOrange">{a.special_notes_schedule}</span>
                    </div>
                    <div style={{ height: "0.5rem", clear: "both" }}/>
                </>
            );
        } else return null;
    }
    return (
        <>
            {appointments.map(a => {
                return (
                    <div key={`daily-overview-appointment-${a.id}`}>
                        {getMainText(a)}{getSchedulingNotes(a)}
                    </div>
                );
            })}
        </>
    );
}
export function renderAppointments(relevantAppointments, verticalMode, blockName){
    if(!relevantAppointments) return null;

    const { starting, ending, continuing } = relevantAppointments;
    const spacerDiv = <div className="h-2 clear-both"/>;
    if(verticalMode){
        return (
            <div className="flex flex-row gap-x-4">
                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                    <h5><u>Students <span className="text-mpGreen">Starting</span></u> - {starting.length}</h5>
                    {spacerDiv}
                    <div className="ml-5">
                        {renderAppointmentGroup(starting, 'start')}
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                    <h5><u>Students <span className="text-mpLRed">Leaving</span></u> - {ending.length}</h5>
                    {spacerDiv}
                    <div className="ml-5">
                        {renderAppointmentGroup(ending, 'end')}
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                    {blockName === 'previous' ? null : 
                        <>
                            <h5><u>Ongoing Sessions</u> - {continuing.length}</h5>
                            {spacerDiv}
                            <div className="ml-5">
                                {renderAppointmentGroup(continuing, 'continuing')}
                            </div>
                        </>}
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <h5><u>Students <span className="text-mpGreen">Starting</span></u> - {starting.length}</h5>
                {spacerDiv}
                <div className="ml-5">
                    {renderAppointmentGroup(starting, 'start')}
                </div>
    
                {spacerDiv}
                <hr/>
                {spacerDiv}
    
                <h5><u>Students <span className="text-mpLRed">Leaving</span></u> - {ending.length}</h5>
                {spacerDiv}
                <div className="ml-5">
                    {renderAppointmentGroup(ending, 'end')}
                </div>
    
                {blockName === 'previous' ? null : 
                    <>
                        {spacerDiv}
                        <hr/>
                        {spacerDiv}
                        
                        <h5><u>Ongoing Sessions</u> - {continuing.length}</h5>
                        {spacerDiv}
                        <div className="ml-5">
                            {renderAppointmentGroup(continuing, 'continuing')}
                        </div>
                    </>}
            </div>
        );
    }
}