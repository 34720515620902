import { basicPath } from '../basicPath';

export const fetchExtraTagsAll = (params) => async (dispatch) => {
    const path = '/mp/extra-tags/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchExtraTagsIds = (params) => async (dispatch) => {
    const path = '/mp/extra-tags/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createExtraTag = (params) => async (dispatch) => {
    const path = '/mp/extra-tags';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateExtraTag = (params) => async (dispatch) => {
    const path = '/mp/extra-tags';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteExtraTag = (params) => async (dispatch) => {
    const path = '/mp/extra-tags';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}