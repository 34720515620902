import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { formatTime } from '../../../../components/functions';
import { renderAppointments } from './helpers';

import { SpinnerLoader } from '../../../../components/custom-essentials';
import {

} from '../../../../actions';

function DailyOverviewDisplay(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { appointments, currentTimeBlock, verticalMode } = props;

    const [processedData, setProcessedData] = useState({});
    const [minutesText, setMinutesText] = useState(null);

    // Process appointments by looking at their start and end times
    useEffect(() => {
        const newProcessedData = {};
        const npd = newProcessedData;

        // Find relevant time blocks
        const previousTBRaw = currentTimeBlock === 0 ? 'None' : currentTimeBlock - 30;
        npd.previousTimeBlock = {
            value: previousTBRaw,
            formatted: previousTBRaw === 'None' ? 'NA' : formatTime(previousTBRaw)
        };
        const nextTBRaw = currentTimeBlock === 1410 ? 'None' : currentTimeBlock + 30;
        npd.nextTimeBlock = {
            value: nextTBRaw,
            formatted: nextTBRaw === 'None' ? 'NA' : formatTime(nextTBRaw)
        };
        npd.currentTimeBlock = {
            value: currentTimeBlock,
            formatted: formatTime(currentTimeBlock)
        };

        // Process appointments
        npd.appointmentBlocks = {};
        for(let i = 0; i < 1440; i += 30){
            npd.appointmentBlocks[i] = {
                starting: [],
                continuing: [],
                ending: []
            };
        }

        // This will ignore appointments that go beyond midnight local time
        // Example: An appointment scheduled 11:30pm to 1:00am will only be
        // processed for the 11:30pm-11:59pm timeslot
        appointments.forEach(a => {
            const { startTime, endTime } = a;

            // Do not process anything that is scheduled at invalid times (not on the hour or half hour)
            if(startTime % 30 !== 0 || a.endTime % 30 !== 0) return;

            for(let i = startTime; i <= endTime; i += 30){
                if(i === startTime){
                    npd.appointmentBlocks[i].starting.push(a);
                } else if (i === endTime){
                    npd.appointmentBlocks[i].ending.push(a);
                } else {
                    npd.appointmentBlocks[i].continuing.push(a);
                }
            }
        });

        // Get the number of minutes until the next time block. Will render on the "Next" panel
        const now = new Date();
        const nowMinutes = now.getHours() * 60 + now.getMinutes() * 1;
        const newMintuesUntilNext = (currentTimeBlock + 30) - nowMinutes;

        if(mounted.current){
            setProcessedData(newProcessedData);
            setMinutesText(`In ${newMintuesUntilNext} minute${newMintuesUntilNext === 1 ? '' : 's'}`);
        }
    }, [appointments, currentTimeBlock]);

    const pd = processedData;
    return (
        <>
            {verticalMode ? 
                <div className="grid grid-cols-1 gap-y-2">
                    <div className="card">
                        {currentTimeBlock === -1 ? (
                            <div className="flex flex-row gap-x-4">
                                <SpinnerLoader/> Loading...
                            </div>
                        ) : 
                            <>
                                <h4>Previous Block: {pd.previousTimeBlock?.formatted}</h4>

                                <br/>

                                {renderAppointments(processedData.appointmentBlocks?.[pd.previousTimeBlock.value], verticalMode, 'previous')}
                            </>
                        }
                    </div>

                    <br/>

                    <div className="card">
                        {currentTimeBlock === -1 ? (
                            <div className="flex flex-row gap-x-4">
                                <SpinnerLoader/> Loading...
                            </div>
                        ) : 
                            <>
                                <h4>Current Block: {pd.currentTimeBlock?.formatted}</h4>

                                <br/>

                                {renderAppointments(processedData.appointmentBlocks?.[pd.currentTimeBlock.value], verticalMode, 'current')}
                            </>
                        }
                    </div>

                    <br/>

                    <div className="card">
                        {currentTimeBlock === -1 ? (
                            <div className="flex flex-row gap-x-4">
                                <SpinnerLoader/> Loading...
                            </div>
                        ) : 
                            <>
                                <div className="flex flex-row">
                                    <h4>Next Block: {pd.nextTimeBlock?.formatted} &nbsp;</h4>
                                    <div>({minutesText})</div>
                                </div>

                                <br/>

                                {renderAppointments(processedData.appointmentBlocks?.[pd.nextTimeBlock.value], verticalMode, 'next')}
                            </>
                        }
                    </div>
                </div>
                :
                <div className="flex flex-row gap-x-4">
                    <div className="card w-1/3">
                            {currentTimeBlock === -1 ? (
                                <div className="flex flex-row gap-x-4">
                                    <SpinnerLoader/> Loading...
                                </div>
                            ) : 
                                <>
                                    <h4>Previous Block: {pd.previousTimeBlock?.formatted}</h4>

                                    <br/>

                                    {renderAppointments(processedData.appointmentBlocks?.[pd.previousTimeBlock.value], verticalMode, 'previous')}
                                </>
                            }
                    </div>

                    <div className="card w-1/3">
                        {currentTimeBlock === -1 ? (
                            <div className="flex flex-row gap-x-4">
                                <SpinnerLoader/> Loading...
                            </div>
                        ) : 
                            <>
                                <h4>Current Block: {pd.currentTimeBlock?.formatted}</h4>

                                <br/>

                                {renderAppointments(processedData.appointmentBlocks?.[pd.currentTimeBlock.value], verticalMode, 'current')}
                            </>
                        }
                    </div>

                    <div className="card w-1/3">
                        {currentTimeBlock === -1 ? (
                            <div className="flex flex-row gap-x-4">
                                <SpinnerLoader/> Loading...
                            </div>
                        ) : 
                            <>
                                <div className="flex flex-row">
                                    <h4>Next Block: {pd.nextTimeBlock?.formatted} &nbsp;</h4>
                                    <div>({minutesText})</div>
                                </div>

                                <br/>

                                {renderAppointments(processedData.appointmentBlocks?.[pd.nextTimeBlock.value], verticalMode, 'next')}
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default connect(null, {

})(DailyOverviewDisplay);