import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getStampsLogColumns } from './table/getColumns';
import { Table } from '../../../../components/table';
import { StampsLogModal } from '../../../../components/modal';

import {

} from '../../../../actions';

const defaultSorted = { dataField: 'date_created', order: 'desc' };

function Component(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    
    const [modalMode, setModalMode] = useState(null);
    const [selectedLog, setSelectedLog] = useState(null);

    const { stampsLogs, refreshData } = props;

    function handleShowModal(mode, entry){
        setModalMode(mode);
        setSelectedLog(entry);
    }
    function onSubmitCallback(changes = false){
        setModalMode(null);
        setSelectedLog(null);
        if(changes) refreshData();
    }

    const columns = getStampsLogColumns(handleShowModal);

    return (
        <div>
            {modalMode && 
                <StampsLogModal
                    mode={modalMode}
                    selectedLog={selectedLog}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="stampsLogs"
                data={stampsLogs}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No stamps logs were found"
            />
        </div>
    );
}

export default connect(null, {

})(Component);