import { useState, useEffect } from 'react';

export function Button(props){
    const {
        color = '',
        onClick,
        children,
        className = '',
        variant = '',
        breakable = false,
        disabled = false,
        type = 'button'
    } = props;

    const [fullClassName, setFullClassName] = useState(className);
    
    useEffect(() => {
        let newFullClassName = `${className} self-center rounded-md duration-300 border disabled:bg-mpLGrey-5 disabled:text-mpLGrey`;

        // Add appropriate padding for different button variants
        if(variant === 'icon') newFullClassName += ' px-1 py-1';
        else newFullClassName += ' px-4 py-2';

        // Disable pointer events to prevent lingering tooltips
        if(disabled) newFullClassName += ' pointer-events-none';

        // Check for lte/hol colors
        if(color.includes('lte-')) newFullClassName += ' border-transparent hover:ring-2 ring-inset';
        else if(color.includes('hol-')) newFullClassName += ' bg-white hover:border-transparent';

        // Should line breaks within a button be allowed?
        if(!breakable) newFullClassName += ' whitespace-nowrap';

        // Apply color color classes
        switch(color){
            case 'lte-mpLRed':
                newFullClassName += ' text-mpLRed bg-mpLRed-4 hover:bg-mpLRed-7 hover:ring-mpLRed';
                break;
            case 'lte-mpOrange':
                newFullClassName += ' text-mpOrange bg-mpOrange-4 hover:bg-mpOrange-7 hover:ring-mpOrange';
                break;
            case 'lte-mpYellow':
                newFullClassName += ' text-black bg-mpYellow-4 hover:bg-mpYellow-7 hover:ring-mpYellow';
                break;
            case 'lte-mpGreen':
                newFullClassName += ' text-mpGreen bg-mpGreen-4 hover:bg-mpGreen-7 hover:ring-mpGreen';
                break;
            case 'lte-mpEGreen':
                newFullClassName += ' text-black bg-mpEGreen-4 hover:bg-mpEGreen-7 hover:ring-mpEGreen';
                break;
             case 'lte-mpTeal':
                newFullClassName += ' text-mpTeal bg-mpTeal-4 hover:bg-mpTeal-7 hover:ring-mpTeal';
                break;
            case 'lte-mpLBlue':
                newFullClassName += ' text-mpLBlue bg-mpLBlue-4 hover:bg-mpLBlue-7 hover:ring-mpLBlue';
                break;
            case 'lte-mpBlue':
                newFullClassName += ' text-mpBlue bg-mpBlue-4 hover:bg-mpBlue-7 hover:ring-mpBlue';
                break;
            case 'lte-mpDBlue':
                newFullClassName += ' text-black bg-mpDBlue-5 hover:bg-mpDBlue-7 hover:ring-mpDBlue';
                break;
            case 'lte-mpPurple':
                newFullClassName += ' text-mpPurple bg-mpPurple-4 hover:bg-mpPurple-7 hover:ring-mpPurple';
                break;
            case 'hol-mpLRed':
                newFullClassName += ' text-mpLRed border-mpLRed hover:bg-mpLRed-4';
                break;
            case 'hol-mpOrange':
                newFullClassName += ' text-mpOrange border-mpOrange hover:bg-mpOrange-4';
                break;
            case 'hol-mpYellow':
                newFullClassName += ' text-mpYellow border-mpYellow hover:bg-mpYellow-4 hover:text-black';
                break;
            case 'hol-mpGreen':
                newFullClassName += ' text-mpGreen border-mpGreen hover:bg-mpGreen-4';
                break;
            case 'hol-mpTeal':
                newFullClassName += ' text-mpTeal border-mpTeal hover:bg-mpTeal-4';
                break;
            case 'hol-mpLBlue':
                newFullClassName += ' text-mpLBlue border-mpLBlue hover:bg-mpLBlue-4';
                break;
            case 'hol-mpBlue':
                newFullClassName += ' text-mpBlue border-mpBlue hover:bg-mpBlue-4';
                break;
            case 'hol-mpDBlue':
                newFullClassName += ' text-mpDBlue border-mpDBlue hover:bg-mpDBlue-4';
                break;
            case 'hol-mpPurple':
                newFullClassName += ' text-mpPurple border-mpPurple hover:bg-mpPurple-4';
                break;
            case 'custom': break;
            default: break;
        }
        
        setFullClassName(newFullClassName.trim());
    }, [color, variant, breakable, disabled, className]);

    if(!color || !onClick){
        return(
            <div className="text-mpLRed">
                Button missing props: {!color && 'color'} {!onClick && 'onClick'}
            </div>
        );
    }

    return (
        <button
            onClick={onClick}
            className={fullClassName}
            disabled={disabled}
            type={type}
        >
            {children}
        </button>
    );
}