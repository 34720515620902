import React, { useCallback } from 'react';

const controlClassNames = 'py-3 px-4 rounded-md bg-white border border-mpLGrey-4 w-full disabled:bg-mpLGrey-7 h-min';
export function FormikControl(props){
    const {
        id,
        name, // name must match formik field name
        as = '',
        placeholder = '',
        label = '',
        type = '',
        additionalClassNames = '',
        value,
        onChange,
        onBlur = () => null,
        disabled = false,
        shouldHandleSubmit = false,
        onSubmit,
    } = props;

    const onChangeWrapper = useCallback((e) => {
        const newValue = e.target.value;
        onChange({ target: { value: newValue, name }});
    }, [onChange, name]);
    const onKeyDownWrapper = useCallback((e) => {
        if(shouldHandleSubmit && e.keyCode === 13){
            e.preventDefault();
            onSubmit();
        }
    }, [shouldHandleSubmit, onSubmit]);

    if(!id || !name || !onChange || value === undefined || (shouldHandleSubmit && !onSubmit)){
        return(
            <div className="text-mpLRed">
                FormikControl missing props: {!id && 'id'} {!name && 'name'}
                {value === undefined && 'value'} {!onChange && 'onChange'}
                {(shouldHandleSubmit && !onSubmit) && 'onSubmit is required if shouldHandleSubmit is true'}
            </div>
        );
    }

    if(as === 'textarea'){
        return (
            <>
                {label ? <div className="text-[#0000008A] text-[10pt]">{label}</div> : null}
                <textarea
                    className={`form-control ${controlClassNames} ${additionalClassNames}`}
                    name={name}
                    type={type}
                    value={value}
                    onBlur={onBlur}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChangeWrapper}
                    onSubmit={shouldHandleSubmit ? onSubmit : () => null}
                    onKeyDown={onKeyDownWrapper}
                />
            </>
        );
    } else {
        return(
            <>
                {label ? <div className="text-[#0000008A] text-[10pt]">{label}</div> : null}
                <input
                    className={`form-control ${controlClassNames} ${additionalClassNames}`}
                    name={name}
                    type={type}
                    value={value}
                    onBlur={onBlur}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChangeWrapper}
                    onSubmit={shouldHandleSubmit ? onSubmit : () => null}
                    onKeyDown={onKeyDownWrapper}
                />
            </>
        );
    }
}