import { basicPath } from '../basicPath';

export const fetchAssessmentTemplatesAll = (params) => async (dispatch) => {
    const path = '/mp/assessment-templates/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAssessmentTemplatesFull = (params) => async (dispatch) => {
    const path = '/mp/assessment-templates/full';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAssessmentTemplatesId = (params) => async (dispatch) => {
    const path = '/mp/assessment-templates/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createAssessmentTemplate = (params) => async (dispatch) => {
    const path = '/mp/assessment-templates';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAssessmentTemplate = (params) => async (dispatch) => {
    const path = '/mp/assessment-templates';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAssessmentTemplate = (params) => async (dispatch) => {
    const path = '/mp/assessment-templates';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}