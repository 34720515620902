import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { convertApiToDate, formatDateApi, formatDateFull } from '../../functions';
import { checkResponse } from '../../form';
import { getNewDates } from './helpers'
import PlaceHoldBodyFooter from './PlaceHoldBF';

import {
    fetchServerTime,
    fetchStudentsUserIds,
    fetchCurrentContractsStudentIds,
    createHoldsLog,
    updateStudentHold,
    updateContractEndDateOffset
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function PlaceHoldModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    const [manualEntryMessage, setManualEntryMessage] = useState('');
    
    const [today, setToday] = useState(initDate)
    const [student, setStudent] = useState({});
    const [contract, setContract] = useState({});
    const [bodyMode, setBodyMode] = useState(0);
    const [datesError, setDatesError] = useState(null);

    const { selectedStudent, onSubmitCallback, createHoldsLog, fetchServerTime,
        fetchStudentsUserIds, fetchCurrentContractsStudentIds, updateStudentHold, updateContractEndDateOffset } = props;

    useEffect(() => {
        async function init(){
            const timeRes = await fetchServerTime();
            const studentRes = await fetchStudentsUserIds({ userIds: [selectedStudent.user_id] });
            const contractRes = await fetchCurrentContractsStudentIds({ studentIds: [selectedStudent.user_id] });
            const timeObj = timeRes.data?.[0]?.CURRENT_TIMESTAMP;
            const newStudent = studentRes.data?.[0] || {};
            const newContract = contractRes.data?.[0] || {};

            const newToday = timeObj ? new Date(timeObj) : new Date();
            newToday.setHours(0, 0, 0, 0);

            const holdStart = new Date(newStudent.mp_hold_start);
            const holdEnd = new Date(newStudent.mp_hold_end);
            let newBodyMode = {};
            let newDatesError = null;
            
            if(holdStart > holdEnd){
                // Things that should not happen
                newBodyMode = -1;
                newDatesError = 'Hold Start is after Hold End';
            } else if(holdEnd <= today){
                // Things that should not happen
                newBodyMode = -1;
                newDatesError = `The student's hold should have ended already, but the hold end date is either today or in the past`;
            } else if(holdStart.getFullYear() >= 3000 && holdEnd.getFullYear() >= 3000){
                // No hold currently scheduled or set
                newBodyMode = 0;
            } else if(holdStart <= today && holdEnd >= today){
                // Hold currently scheduled or set
                newBodyMode = 1;
            } else if(holdStart > today){
                newBodyMode = 1;
            } else {
                // Things that should not happen
                newBodyMode = -1;
                newDatesError = 'Unexpected edge case';
            }

            if(mounted.current){
                setStudent(newStudent);
                setContract(newContract);
                setToday(newToday);
                setBodyMode(newBodyMode);
                setDatesError(newDatesError);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            // PREPARE DATA //
            let noHold = values.noHold;
            const holdStart = convertApiToDate(values.holdStart);
            const holdEnd = convertApiToDate(values.holdEnd);
            const originalHoldStart = convertApiToDate(values.originalHoldStart);
            const originalHoldEnd = convertApiToDate(values.originalHoldEnd);
            const shouldHold = new Date() > holdStart;

            // Prepare notes to enter into the holds log depending on current and original values
            let holdsLogNotes = '';
            let holdLengthDiff = 0;
            if(bodyMode === 0){
                // No hold currently scheduled or set
                if(holdStart.getFullYear() >= 3000 && holdEnd.getFullYear() >= 3000){
                    // No hold scheduled or hold removed
                    holdsLogNotes = `Form was submitted but nothing was changed (no hold --> no hold)`;
                    noHold = true;
                } if(holdEnd < today){
                    // Hold placed: Retroactive (in the past)
                    holdsLogNotes = `Hold retroactively placed from ${formatDateFull(holdStart)} to ${formatDateFull(holdEnd)} (${values.holdLength} days)`;
                    noHold = true;
                } else if(holdStart <= today){
                    // Hold placed: current
                    holdsLogNotes = `Hold placed from ${formatDateFull(holdStart)} to ${formatDateFull(holdEnd)} (${values.holdLength} days)`;
                } else {
                    // Hold scheduled
                    holdsLogNotes = `Hold scheduled from ${formatDateFull(holdStart)} to ${formatDateFull(holdEnd)} (${values.holdLength} days)`;
                }
            } else if(bodyMode === 1){
                // Hold currently scheduled or set
                if(holdStart - originalHoldStart === 0 && holdEnd - originalHoldEnd === 0){
                    // No changes
                    holdsLogNotes = `Form submitted, but hold was not changed. Original: ${formatDateFull(originalHoldStart)} to ${formatDateFull(originalHoldEnd)} (${values.originalHoldLength} days)`;
                } else if(holdStart.getFullYear() >= 3000 && holdEnd.getFullYear() >= 3000){
                    // Hold removed
                    holdLengthDiff = Math.min(values.holdLength, Math.floor((originalHoldEnd - today)/ (24 * 60 * 60 * 1000)) + 1)
                    holdsLogNotes = `Hold was removed. Original: ${formatDateFull(originalHoldStart)} to ${formatDateFull(originalHoldEnd)} (${values.originalHoldLength} days) (${holdLengthDiff} days remained).`;
                    noHold = true;
                } else {
                    // Hold changed
                    holdLengthDiff = values.holdLength - values.originalHoldLength;
                    holdsLogNotes = `Hold moved. Original: ${formatDateFull(originalHoldStart)} to ${formatDateFull(originalHoldEnd)} (${values.originalHoldLength} days). New: ${formatDateFull(holdStart)} to ${formatDateFull(holdEnd)} (${values.holdLength} days). Difference (new - old): ${holdLengthDiff} days`;
                    if(holdEnd < today) noHold = true;
                } 
            }
            setManualEntryMessage(holdsLogNotes);

            const {
                newNextMonthStart,
                newContractEndDate,
                newInactiveDate
            } = getNewDates(values, student, contract);

            const studentParams = {
                student: student.user_id,
                holdStart: noHold ? formatDateApi(new Date(3000, 0, 1)) : values.holdStart,
                holdEnd: noHold ? formatDateApi(new Date(3000, 0, 1)) : values.holdEnd,
                onHold: shouldHold ? 1 : 0,
                nextMonthStart: formatDateApi(newNextMonthStart),
                inactiveDate: formatDateApi(newInactiveDate)
            };

            const contractParams = {
                id: contract.id,
                endDate: formatDateApi(newContractEndDate),
                offset: parseInt(contract.payment_date_offset) + holdLengthDiff
            };

            const holdsLogParams = {
                student: student.user_id,
                center: student.mp_primary_center,
                notes: holdsLogNotes
            };  

            // END-PREPARE DATA //

            const nss = { errored: false, completed: false };
            nss.updateStudent = { name: 'Update student info', completed: false, message: '' };
            nss.updateContract = { name: 'Update contract end date', completed: false, message: '' };
            nss.createLog = { name: 'Create holds log entry', completed: false, message: '' };
            setSubmissionStatus({...nss});

            const usResponse = await updateStudentHold(studentParams);
            nss.updateStudent.completed = true;
            nss.errored = !checkResponse(usResponse, mounted, (resp) => nss.updateStudent.message = resp) || nss.errored;
            setSubmissionStatus({...nss});
            
            const ucResponse = await updateContractEndDateOffset(contractParams);
            nss.updateContract.completed = true;
            nss.errored = !checkResponse(ucResponse, mounted, (resp) => nss.updateContract.message = resp) || nss.errored;
            setSubmissionStatus({...nss});

            const clResponse = await createHoldsLog(holdsLogParams);
            nss.createLog.completed = true;
            nss.errored = !checkResponse(clResponse, mounted, (resp) => nss.createLog.message = resp) || nss.errored;
            setSubmissionStatus({...nss});

            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));
            
            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus(`An error occurred during submission`);
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [bodyMode, student, contract, today, handleClose, createHoldsLog,
        updateStudentHold, updateContractEndDateOffset, oneSuccess]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Place Hold Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <PlaceHoldBodyFooter
                    datesError={datesError}
                    bodyMode={bodyMode}
                    selectedStudent={student}
                    relevantContract={contract}
                    loaded={loaded}
                    submitted={submitted}
                    submissionStatus={submissionStatus}
                    manualEntryMessage={manualEntryMessage}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchServerTime,
    fetchStudentsUserIds,
    fetchCurrentContractsStudentIds,
    createHoldsLog,
    updateStudentHold,
    updateContractEndDateOffset
})(PlaceHoldModal);