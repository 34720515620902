import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function MenuItem(props){
    const { label, to, onClick } = props;

    return (
        <Link
            to={to}
            className="text-white self-center hover:text-mpDBlue-5
                hover:bg-mpDGrey-1 rounded-lg duration-300 px-3 py-2"
            onClick={onClick}
        >
            {label}
        </Link>
    );
}

export default connect(null, {

})(MenuItem);