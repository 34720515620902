import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import TemplatesTable from './TemplatesTable';
import { FormikControl, checkResponses, Switch } from '../../../../components/form';
import { AssessmentTemplateModal } from "../../../../components/modal";
import { BrowserTabTitle, LoadingOverlay } from "../../../../components/display";
import { Socket } from '../../../../components/ws';

import {
    fetchAssessmentTemplatesAll
} from '../../../../actions';

const pageTitle = 'Assessment Template Manager';

function TemplateManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    });
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [assessmentTemplates, setAssessmentTemplates] = useState([]);
    const [filteredAssessmentTemplates, setFilteredAssessmentTemplates] = useState([]);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedAssessmentTemplate, setSelectedAssessmentTemplate] = useState(null);
    
    // const urlParams = new URLSearchParams(window.location.search);

    const filterData = useCallback((newAssessmentTemplates = assessmentTemplates, filterQuery, showInactive) => {
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        showInactive = [true, 'true'].includes(showInactive);
        
        // cdc = 'Check Does Contain'
        function cdc(text){ return text.toString().toLowerCase().replace(/ /g, '').trim().includes(formattedFQ) };
        const newFilteredAssessmentTemplates = newAssessmentTemplates.filter(at => {
            if(!showInactive && parseInt(at.active) === 0) return false;
            return cdc(at.level) || cdc(at.name) || cdc(at.n_questions);
        })

        if(mounted.current) setFilteredAssessmentTemplates(newFilteredAssessmentTemplates);
    }, [setFilteredAssessmentTemplates, assessmentTemplates, mounted]);

    const { fetchAssessmentTemplatesAll } = props;
    const refreshData = useCallback((filterQuery = '', showInactive = false) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            const assessmentTemplatesRes = await fetchAssessmentTemplatesAll();
            const isApiError = checkResponses(assessmentTemplatesRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            
            const newAssessmentTemplates = assessmentTemplatesRes.data || [];
    
            if(mounted.current){
                setAssessmentTemplates(newAssessmentTemplates);
                filterData(newAssessmentTemplates, filterQuery, showInactive);
                setLoading(false);
            }
        })();
    }, [fetchAssessmentTemplatesAll, loading, mounted, setAssessmentTemplates, setLoading, filterData]);
    useEffect(() => {
        (async function init(){
            await refreshData('', false);
            setHasLoaded(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function onSubmitCallback(filterQuery, changes = true){
        setModalMode(null);
        setSelectedAssessmentTemplate(null);
        if(changes) refreshData(filterQuery);
    }

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        filterQuery: '',
                        showInactive: false
                    }}
                    handleSubmit={() => null}
                >
                    {formik => (
                        <>
                            <h2>Assessment Templates ({filteredAssessmentTemplates.length})</h2>
                            
                            <br/>
                            
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <FormikControl
                                        id="template-manager-query-1"
                                        name="filterQuery"
                                        placeholder="Filter assessment templates..."
                                        value={formik.values.filterQuery}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(assessmentTemplates, e.target.value, formik.values.showInactive);
                                        }}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                    >
                                        + New Template
                                    </Button>
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Switch
                                        id="template-manager-show-inactive"
                                        name="showInactive"
                                        label="Show Inactive"
                                        color="mpLRed"
                                        checked={formik.values.showInactive}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(assessmentTemplates, formik.values.filterQuery, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            {modalMode && <AssessmentTemplateModal
                                mode={modalMode}
                                selectedAssessmentTemplate={selectedAssessmentTemplate}
                                onSubmitCallback={(changes) => onSubmitCallback(formik.values.filterQuery, changes)}
                            />}
                            <Socket
                                refreshData={() => refreshData(formik.values.filterQuery)}
                                page={pageTitle}
                                setVersion={props.setVersion}
                            />

                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                hasLoaded && 
                                <TemplatesTable
                                    assessmentTemplates={filteredAssessmentTemplates}
                                    refreshData={() => refreshData(formik.values.filterQuery)}
                                />
                            }
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchAssessmentTemplatesAll
})(TemplateManager);