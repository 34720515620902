import React from 'react';
import * as Yup from 'yup';

import { Modal, ModalBodyFooter, Button, ModalProcessing } from '../../custom-essentials';
import { renderSubmittingDefault } from '../../form';
import { convertApiToDate } from '../../functions';

export const validationSchema = Yup.object().shape({
    student: Yup.mixed().test(
        'studentIsSelected',
        'Please select a valid student',
        (value) => value.value !== -1
    ),
    type: Yup.mixed().test(
        'typeIsSelected',
        'Please select a valid type',
        (value) => value.value !== -1
    ),
    paymentFrequency: Yup.mixed().test(
        'paymentFreqIsSelected',
        'Please select a valid option',
        (value) => value.value !== -1
    ),
    initialPayment: Yup.number()
        .typeError('Initial Payment must be a number')
        .min(0, 'Min 0')
        .max(30000, 'Max 30000')
        .integer('Whole Number Required'),
    amountPerPayment: Yup.number()
        .typeError('Amount must be a number')
        .min(0, 'Min 0')
        .max(30000, 'Max 30000')
        .integer('Whole Number Required'),
    paymentDateOffset: Yup.number()
        .typeError('Offset must be a number')
        .min(0, 'Min 0')
        .max(30000, 'Max 30000')
        .integer('Whole Number Required'),
    notes: Yup.string()
        .max(1000, 'Max 1000 characters'),
    drsValid: Yup.mixed().when(['startDate', 'endDate'],
        ([startDate, endDate]) => {
            const startDateObject = convertApiToDate(startDate);
            const endDateObject = convertApiToDate(endDate);

            const isValid1 = !isNaN(startDateObject.getTime()) && !isNaN(endDateObject.getTime());
            if(!isValid1) return Yup.mixed().test('drsValid', `At least one date is invalid`, () => false);
            
            const isValid2 = startDateObject < endDateObject;
            if(!isValid2) return Yup.mixed().test('drsValid', `Start Date must come before End Date`, () => false);

            return Yup.mixed().test('drsValid', '', () => true);
        })
});

export function getInitStudentOption(student, studentOptions){
    if(!student) return { value: -1, label: 'Please select...' };
    return studentOptions.find(s => s.value === student) || { value: -1, label: `Unknown student (${student})` };
}

export const typeOptions = ['Month to Month', 'Contract', 'Sessions', 'Up Front', 'Extra'].map(i => {
    return ({ value: i, label: i });
});
export function getInitTypeOption(type){
    if(!type) return { value: -1, label: 'Please select...' };
    return typeOptions.find(t => t.value === type) || { value: -1, label: `Unknown type (${type})` };
}

export const paymentFreqOptions = ['Monthly', 'Weekly', 'One Time'].map(i => {
    return ({ value: i, label: i });
});
export function getInitPaymentFreqOption(paymentFreq){
    if(!paymentFreq) return { value: -1, label: 'Please select...' };
    return paymentFreqOptions.find(t => t.value === paymentFreq) || { value: -1, label: `Unknown entry (${paymentFreq})` };
}

export function renderSubmitting(submissionStatus, setSubmitting){
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <>
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>
                
                <div className="grid grid-cols-1 gap-y-2">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => setSubmitting(false)}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}