import React, { useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, Button, ErrorMessage } from '../../custom-essentials';
import { formatDateApi, formatDate, convertApiToDate } from '../../functions';
import { DatePicker, checkResponse } from '../../form';
import { bulkValidationSchema, bulkGetSunSat, bulkRenderToFrom, getDateKeys,
    bulkRenderAvailabilities, bulkRenderSubmitting } from './helpers';

import {
    fetchAvailabilityDaterangeCenter,
    createAvailabilityBulk,
    deleteAvailabilityBulk,
} from '../../../actions';

const initFromDate = new Date();
initFromDate.setDate(initFromDate.getDate() - 14);
const initFromDateApi = formatDateApi(initFromDate);

function BulkUpdate(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedDate, selectedCenter, onSubmitCallback,
        fetchAvailabilityDaterangeCenter, createAvailabilityBulk, deleteAvailabilityBulk } = props;

    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searched, setSearched] = useState(false);
    const [sundays, setSundays] = useState({});
    const [fromAvailabilities, setFromAvailabilities] = useState([]);
    const [fromAvailabilityBlocks, setFromAvailabilityBlocks] = useState([]);
    const [toAvailabilities, setToAvailabilities] = useState([]);
    const [toAvailabilityBlocks, setToAvailabilityBlocks] = useState([]);
    
    const [firstConfirm, setFirstConfirm] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);
    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);
            
            // PREPARE DATA //
            // DST jump causes this difference to be 1 hour short, leading to a dateDiff that is one day too short.
            // Add two hours as a precaution
            const dateDiff = Math.floor((sundays.toSunday - sundays.fromSunday + 2 * 60 * 60 * 1000) / (1000 * 60 * 60 * 24));

            const toAvailabilityIds = [];
            Object.values(toAvailabilities).forEach(a => {
                if(a.id) toAvailabilityIds.push(a.id);
            });
            const bulkDeleteAvailParams = { ids: toAvailabilityIds };
            const bulkCreateAvailParams = [];
            Object.values(fromAvailabilities).forEach(a => {
                const availDate = new Date(a.date);
                availDate.setDate(availDate.getDate() + dateDiff);

                if(!Object.keys(a).length) bulkCreateAvailParams.push({});
                else {
                    bulkCreateAvailParams.push({
                        date: formatDateApi(availDate),
                        center: a.center,
                        isClosed: a.is_closed,
                        openTime: a.open_time,
                        lastStart: a.last_start,
                        closeTime: a.close_time,
                    });
                }
            });
            const bulkCreateBlocksParams = [];

            Object.entries(fromAvailabilityBlocks).forEach(([dateKey, blockList]) => {
                const availDate = new Date(`${dateKey.replace(/_/g, '/')} 00:00:00`);
                availDate.setDate(availDate.getDate() + dateDiff);
                blockList.forEach(block => {
                    bulkCreateBlocksParams.push({
                        date: formatDateApi(availDate),
                        time: block.time,
                        students: block.students || 0,
                        seats: block.seats || 0,
                        startStudents: block.start_students || 0,
                        startSeats: block.start_seats || 0,
                        isClosed: block.is_closed,
                    });
                });
            });

            const deleteParams = {
                availability: bulkDeleteAvailParams.ids,
            };
            const createParams = {
                availability: bulkCreateAvailParams,
                blocks: bulkCreateBlocksParams
            }
            // END-PREPARE DATA //
            
            // SEND DATA //
            // newSubmissionStatus to track the submission process
            const nss = { errored: false, completed: false };
            nss.deleteAvailability = { name: 'Delete Old Availabilities', completed: false, message: '' };
            nss.createAvailability = {name: 'Create New Availabilities', completed: false, message: '' };
            setSubmissionStatus({...nss});

            const daResponse = await deleteAvailabilityBulk(deleteParams);
            nss.deleteAvailability.completed = true;
            nss.errored = !checkResponse(daResponse, mounted, (resp) => nss.deleteAvailability.message = resp) || nss.errored;
            setSubmissionStatus({...nss});

            const caResponse = await createAvailabilityBulk(createParams);
            nss.createAvailability.completed = true;
            nss.errored = !checkResponse(caResponse, mounted, (resp) => nss.createAvailability.message = resp) || nss.errored;
            setSubmissionStatus({...nss});
            // END-SEND DATA //
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus('One or more errors occurred during submission');
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => {
                if(mounted.current) handleClose(true);
            }, 1000);
        }
        submit();
    }, [handleClose, sundays, fromAvailabilities, fromAvailabilityBlocks, toAvailabilities,
       createAvailabilityBulk, deleteAvailabilityBulk, oneSuccess]);
    const refreshData = useCallback((values) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            const { selectedFromDate, selectedToDate } = values;
    
            const fromDate = convertApiToDate(selectedFromDate);
            const toDate = convertApiToDate(selectedToDate);
            
            const [fromStartDate, fromEndDate] = bulkGetSunSat(fromDate);
            const [toStartDate, toEndDate] = bulkGetSunSat(toDate);
    
            // PROCESS 'FROM' WEEK //
            const fromAvailabilityRes = await fetchAvailabilityDaterangeCenter({
                startDate: formatDateApi(fromStartDate),
                endDate: formatDateApi(fromEndDate),
                center: selectedCenter.value
            });
            const newFromAvailabilities = fromAvailabilityRes.data?.availabilities || [];
            const newFromAvailabilityBlocks = fromAvailabilityRes.data?.blocks || [];
    
            const fromDateKeys = getDateKeys(fromStartDate);
            const sortedFromAvails = {};
            const sortedFromBlocks = {};
            for(let dk of fromDateKeys){
                sortedFromAvails[dk] = {};
                sortedFromBlocks[dk] = [];
            }
        
            const fromIdToDateKeyMap = {};
            newFromAvailabilities.forEach(a => {
                const dateKey = formatDate(a.date).replace(/\//g, '_');
                sortedFromAvails[dateKey] = a;
                fromIdToDateKeyMap[parseInt(a.id)] = dateKey;
            });
            newFromAvailabilityBlocks.forEach(b => {
                const dateKey = fromIdToDateKeyMap[parseInt(b.availability_id)];
                if(sortedFromBlocks[dateKey]) sortedFromBlocks[dateKey].push(b);
            });
            // END-PROCESS 'FROM' WEEK //
            
            // PROCESS 'TO' WEEK //
            const toAvailabilityRes = await fetchAvailabilityDaterangeCenter({
                startDate: formatDateApi(toStartDate),
                endDate: formatDateApi(toEndDate),
                center: selectedCenter.value
            });
            const newToAvailabilities = toAvailabilityRes.data?.availabilities || [];
            const newToAvailabilityBlocks = toAvailabilityRes.data?.blocks || [];
    
            const toDateKeys = getDateKeys(toStartDate);
            const sortedToAvails = {};
            const sortedToBlocks = {};
            for(let dk of toDateKeys){
                sortedToAvails[dk] = {};
                sortedToBlocks[dk] = [];
            }
        
            const toIdToDateKeyMap = {};
            newToAvailabilities.forEach(a => {
                const dateKey = formatDate(a.date).replace(/\//g, '_');
                sortedToAvails[dateKey] = a;
                toIdToDateKeyMap[parseInt(a.id)] = dateKey;
            });
            newToAvailabilityBlocks.forEach(b => {
                const dateKey = toIdToDateKeyMap[parseInt(b.availability_id)];
                if(sortedToBlocks[dateKey]) sortedToBlocks[dateKey].push(b);
            });
            // END-PROCESS 'FROM' WEEK //
    
            const newSundays = {
                toSunday: toStartDate,
                fromSunday: fromStartDate
            };
    
            if(mounted.current){
                setFromAvailabilities(sortedFromAvails);
                setToAvailabilities(sortedToAvails);
                setFromAvailabilityBlocks(sortedFromBlocks);
                setToAvailabilityBlocks(sortedToBlocks);
                setSundays(newSundays);
                setLoading(false);
                setSearched(true);
            }
        })();
    }, [loading, selectedCenter, fetchAvailabilityDaterangeCenter])

    if(submitted){
        return (
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <h2>Availability Bulk Update</h2>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                validationSchema={bulkValidationSchema}
                initialValues={{
                    selectedFromDate: initFromDateApi,
                    selectedToDate: selectedDate.api
                }}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        backdrop={formik.isSubmitting ? "static" : true}
                        className="w-3/4"
                    >
                        <Modal.Header>
                            <h2>Availability Bulk Update ({selectedCenter.label})</h2>
                        </Modal.Header>
                        { formik.isSubmitting ? bulkRenderSubmitting(submissionStatus, formik.setSubmitting) :
                            <>
                                <Modal.Body>
                                    {firstConfirm && 
                                        <h5 className="text-mpOrange">
                                            You clicked "Submit" below. Date editing is disabled.
                                            Scroll down and click "Back" to change the dates.
                                        </h5>
                                    }
                                    <div className="flex flex-row gap-x-4 items-end">
                                        <div className="w-1/2">
                                            <h4>From Date</h4>
                                            <DatePicker
                                                id="availability-bulk-update-from-date"
                                                name="selectedFromDate"
                                                value={formik.values.selectedFromDate}
                                                onChange={(e) => {
                                                    setSearched(false);
                                                    formik.handleChange(e);
                                                }}
                                                disabled={firstConfirm}
                                            />
                                            {formik.errors.selectedFromDate ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.selectedFromDate}
                                                </ErrorMessage>
                                            ) : null}
                                            <div className="h-4 clear-both"/>
                                            <div>Origin Week*:</div>
                                            <div>{bulkRenderToFrom(formik.values.selectedFromDate)}</div>
                                        </div>
                                        <div className="w-1/3">
                                            <h4>To Date</h4>
                                            <DatePicker
                                                id="availability-bulk-update-to-date"
                                                name="selectedToDate"
                                                value={formik.values.selectedToDate}
                                                onChange={(e) => {
                                                    setSearched(false);
                                                    formik.handleChange(e);
                                                }}
                                                disabled={firstConfirm}
                                            />
                                            {formik.errors.selectedToDate ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.selectedToDate}
                                                </ErrorMessage>
                                            ) : null}
                                            <div className="h-4 clear-both"/>
                                            <div>Target Week*:</div>
                                            <div>{bulkRenderToFrom(formik.values.selectedToDate)}</div>
                                        </div>
                                        <div className="w-1/6 self-center">
                                            <Button
                                                color="lte-mpTeal"
                                                disabled={firstConfirm}
                                                onClick={() => {
                                                    if(formik.isValid) refreshData(formik.values)
                                                }}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>*Weeks begin on a Sunday and end on a Saturday</div>

                                    <div className="h-2 clear-both"/>
                                    <hr/>
                                    <div className="h-2 clear-both"/>

                                    {searched && 
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <h5>
                                                What happens next? 
                                            </h5>
                                            <div>
                                                The "Current" availabilities and blocks will be deleted.
                                                They will be replaced with the "New" items.
                                            </div>
                                            <br/>
                                        </div>
                                    }

                                    <div className="flex flex-row gap-x-4 items-start">
                                        <div className="w-1/2">
                                            <h3><u>Current:</u></h3>
                                            <div className="h-2 clear-both"/>
                                            {bulkRenderAvailabilities(sundays.toSunday,
                                                toAvailabilities, toAvailabilityBlocks, 'to', searched)}
                                        </div>
                                        <div className="w-1/2">
                                            <h3><u>New:</u></h3>
                                            <div className="h-2 clear-both"/>
                                            {bulkRenderAvailabilities(sundays.fromSunday,
                                                fromAvailabilities, fromAvailabilityBlocks, 'from', searched, sundays.toSunday)}
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="flex flex-row gap-x-2 w-full flex-wrap">
                                        {!formik.isValid && formik.touched.controlQuery &&
                                            <div className="text-mpLRed">
                                                One or more fields needs to be corrected.
                                            </div>
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed">
                                                {formik.status}
                                            </div> : null
                                        }
                                        {!firstConfirm ? 
                                            <div className="flex ml-auto flex-row gap-x-2 flex-wrap">
                                                <Button
                                                    color="lte-mpLRed"
                                                    disabled={formik.isSubmitting}
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    color="lte-mpLBlue"
                                                    disabled={formik.isSubmitting || !searched}
                                                    onClick={() => setFirstConfirm(true)}
                                                >
                                                    {!searched ? 'Search for availabilities before submitting' : 'Submit'}
                                                </Button>
                                            </div>
                                            :
                                            <>
                                                <div className="flex flex-row gap-x-2 w-full mr-auto flex-wrap">
                                                    <Button
                                                        color="lte-mpLRed"
                                                        disabled={formik.isSubmitting}
                                                        onClick={() => setFirstConfirm(false)}
                                                        style={{ marginRight: "5px" }}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        color="lte-mpLBlue"
                                                        onClick={formik.handleSubmit}
                                                        disabled={formik.isSubmitting}
                                                    >
                                                        Confirm Submit
                                                    </Button>
                                                </div>
                                                <div className="text-mpLRed">
                                                    Submit? This cannot be undone.
                                                </div>
                                            </>
                                        }
                                    </div>
                                </Modal.Footer>
                            </>
                        }
                    </Modal>
                )}
            </Formik>
        );
    }
}

export default connect(null, {
    fetchAvailabilityDaterangeCenter,
    createAvailabilityBulk,
    deleteAvailabilityBulk,
})(BulkUpdate);