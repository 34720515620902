import React, { useState, useCallback } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, Table, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHeaderRow, TablePagination } from './components';
// import { FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPageIcon } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.mpDBlue.main,
        color: theme.palette.white.main,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.mpDBlue.light7,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.mpSand.light7,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function defaultSort(a, b, order){
    const swap = order === 'asc' ? -1 : 1;
    if(a < b) return -1 * swap;
    else if(a > b) return 1 * swap;
    else return 0;
}
function sortData(data, columns, order, orderBy){
    const matchedCol = columns.find(c => c.dataField === orderBy) || {};
    const sortFunc = matchedCol.sortFunc;
    
    const sortedData = data.sort((a, b) => {
        const aCell = a[orderBy];
        const bCell = b[orderBy];
        return sortFunc ? sortFunc(aCell, bCell, order, orderBy, a, b) : defaultSort(aCell, bCell, order);
    });

    return sortedData;
}

// TablePaginationActions.propTypes = {
//     count: PropTypes.number.isRequired,
//     onPageChange: PropTypes.func.isRequired,
//     page: PropTypes.number.isRequired,
//     rowsPerPage: PropTypes.number.isRequired,
// };

export function TableMUI(props){
    const {
        data,
        columns,
        defaultSorted,
        onTableChange,
        tableName,
        stickyHeader = true,
        remote = false,
        rowsPerPageList = [10, 20, 50, 100],
        rowsPerPageInitial = 20,
        isLoading = false,
        emptyMessage = 'No data was found',
    } = props;

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState({ value: rowsPerPageInitial, label: rowsPerPageInitial });
    const [order, setOrder] = useState(defaultSorted.order || 'asc');
    const [orderBy, setOrderBy] = useState(defaultSorted.dataField || columns[0]?.dataField || '');

    const setRowsPerPageWrapper = useCallback((e) => {
        const newRowsPerPage = e.target.value;

        setRowsPerPage({ value: newRowsPerPage, label: newRowsPerPage });
        setCurrentPage(Math.min(currentPage, Math.floor(data.length / newRowsPerPage)))
    }, [data, currentPage]);

    if(props.keyField) return <div className="text-mpLRed">Table: keyField is no longer required</div>; // REMOVE LATER ON once tables have been refactored
    if(!data || !columns || !defaultSorted || (remote && !onTableChange) || !tableName){
        const dataStr = `data: [{ key: val, key2: val2 }, {...}, ...]`;
        const colStr = `columns: [{ dataField: idKey, text: display text, sort: true, (opt function) formatter(content, row) }, {...}, ...]`;
        const defaultSortedStr = `defaultSorted: [{ dataField: "id", order: "asc" }]`;
        const remoteMsg = 'onTableChange is required if remote is provided';
        return (
            <div className="text-mpLRed">
                Table missing props: {!data && dataStr} {!columns && colStr} {!defaultSorted && defaultSortedStr} {remote && !onTableChange && remoteMsg}
                {!tableName && 'tableName'}
            </div>
        );
    } else if(isLoading){

    } else if(!data.length) return <div className="text-mpOrange">{emptyMessage}</div>;

    const handleRequestSort = (dataField) => {
        const isAsc = orderBy === dataField && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(dataField);
    };

    let rowIndex = 0;
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} stickyHeader={stickyHeader} aria-label="customized table">
                <TableHeaderRow
                    order={order}
                    orderBy={orderBy}
                    columns={columns}
                    handleRequestSort={handleRequestSort}
                    tableName={tableName}
                />
                <tbody>
                    {sortData(data, columns, order, orderBy)
                        .slice(currentPage * rowsPerPage.value, currentPage * rowsPerPage.value + rowsPerPage.value)
                        .map((row) => (
                            <StyledTableRow key={`table-NAME-r${++rowIndex}`}>
                                {columns.map(c => {
                                    const cell = row[c.dataField] === 'undefined' ? '' : row[c.dataField];
                                    return (
                                        <StyledTableCell key={`table-${tableName}-r${rowIndex}-${c.dataField}`}>
                                            {c.formatter ? c.formatter(row) : cell}
                                        </StyledTableCell>
                                    )
                                })}
                            </StyledTableRow>
                        )
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="100%">
                            <TablePagination
                                tableName={tableName}
                                count={data.length}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPageWrapper}
                                rowsPerPageList={rowsPerPageList}
                            />
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </TableContainer>
    );
}