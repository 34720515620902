import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal, Button, ModalProcessing } from '../../custom-essentials';
import { Clock } from '../../display';
import { checkResponse } from '../../form';

import {
    signIn,
    signOut
} from '../../../actions';

function CheckInModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const { mode, onSubmitCallback, signIn, signOut } = props;

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback(() => {
        async function submit(){
            if(submitting) return;
            if(mounted.current) setSubmitting(true);
            
            let response = { status: 999 };
            if(mode === 'in') response = await signIn();
            if(mode === 'out') response = await signOut();

            const responseValid = checkResponse(response, mounted, setError);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current){
                setSubmitting(false);
                handleClose(true);
            }
        }
        submit();
    }, [handleClose, mode, signIn, signOut, submitting]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Check {mode === 'in' ? 'In' : 'Out'}</h2>
            </Modal.Header>
            <Modal.Body>
                <h4>
                    Sign {mode === 'in' ? 'in' : 'out'}? The current time is <Clock skipSeconds={true}/>
                </h4>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex flex-row gap-x-2 flex-wrap">
                    {submitting &&
                        <ModalProcessing/>
                    }
                    {error && !submitting ? 
                        <div className="text-mpLRed">
                            {error}
                        </div> : null
                    }
                    <Button
                        color="lte-mpLRed"
                        disabled={submitting}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="lte-mpLBlue"
                        onClick={handleSubmit}
                        disabled={submitting}
                    >
                        {mode === 'in' ? 'Sign In' : 'Sign Out'}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, {
    signIn,
    signOut
})(CheckInModal);