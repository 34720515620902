import React, { useState } from 'react';
import { Popper, Fade, Paper } from '@mui/material';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function TooltipWrapper(props){
    const {
        tooltipText,
        children,
        placement = "top", // top, right, bottom, or left
        displayContent, // Deprecated; included for error reporting
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    if(!tooltipText){
        return(
            <div className="text-mpLRed">
                TooltipWrapper missing props: {!tooltipText && 'tooltipText'}
            </div>
        );
    }
    // Remove this warning once all code has been fully refactored
    if(displayContent){
        return (
            <div className="text-mpLRed">
                Tooltip has deprecated prop displayContent.
            </div>
        );
    }

    const handleHoverOn = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleHoverOff = () => {
        setOpen(false);
        setAnchorEl(null);
    }

    return (
        <>
            <span
                onMouseEnter={(e) => handleHoverOn(e)}
                onMouseLeave={() => handleHoverOff()}
            >
                {children}
            </span>
            <Popper
                placement={placement}
                open={open}
                anchorEl={anchorEl}
                transition
                style={{ zIndex: 5000 }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <div className="px-4 py-2">{tooltipText}</div>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
}