import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing } from '../../custom-essentials';
import { validationSchema, renderBody } from './helpers';

function AssessmentTemplatesBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [deleteFirstConfirm, setDeleteFirstConfirm] = useState(false);

    const { mode, attemptingClose, setAttemptingClose, assessmentTemplate, questionTemplates, lessonOptions,
        hasLoaded, submitted, handleClose, handleSubmit } = props;

    if(!hasLoaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this assessment template?</h4>
                            <div className="col">
                                <h6>Name: {assessmentTemplate.name}</h6>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                { deleteFirstConfirm ?
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={formik.handleSubmit}
                                            >
                                            Confirm Delete
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(false)}
                                        >
                                            Back
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(true)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                }
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={{
                    viewMode: 1,
                    letterMode: false,
                    firstConfirm: false,
                    level: assessmentTemplate.level || '',
                    name: assessmentTemplate.name || '',
                    notes: assessmentTemplate.notes || '',
                    nQuestions: assessmentTemplate.n_questions || '',
                    fileName: assessmentTemplate.file_name || '',
                    hasFile: parseInt(assessmentTemplate.has_file) === 1 || false,
                    hasAnswerKey: parseInt(assessmentTemplate.has_answer_key) === 1 || false,
                    questionTemplates,
                    active: parseInt(assessmentTemplate.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <Modal.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    ) :
                    <ModalBodyFooter>
                        {renderBody(formik, handleClose, lessonOptions)}
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(AssessmentTemplatesBodyFooter);