import React, { useState } from 'react';
import {
    TextField,
} from '@mui/material';

import {
    getTimeObject,
    getDateTimeObject,
} from '../../components/functions';

export function TimePicker(props){
    const {
        id,
        name,
        label = '',
        value,
        onChange,
        step = 60,
        classNames = '',
        shrinkLabel = true,
        disabled = false,
    } = props;

    function onChangeHelper(e){
        const [hours, minutes] = e.target.value.split(':');
        const timeInt = hours * 60 + minutes * 1;
        const timeObj = getTimeObject(timeInt);

        onChange({ target: { value: timeObj, name: name } });
    }

    if(!id || !name || value === undefined || !onChange){
        return(
            <div className="text-mpLRed">
                Datepicker missing props: {!id && 'id'} {!name && 'name'} {value === undefined && 'value (hh:mm)'} {!onChange && 'onChange'}
            </div>
        );
    }

    return (
        <div className="w-min">
            <TextField
                id={id}
                label={label}
                type="time"
                disabled={disabled}
                value={value}
                className={classNames}
                onChange={onChangeHelper} // 'yyyy-mm-ddThh:mm'
                InputLabelProps={{ shrink: shrinkLabel }}
                inputProps={{
                    step
                }}
            />
        </div>
    );
}

export function DatePicker(props){
    const {
        id,
        name,
        label = '',
        value,
        onChange,
        disabled = false,
        classNames = '',
        shrinkLabel = true,
    } = props;

    function onChangeHelper(e){
        onChange({ target: { value: e.target.value, name: name } });
    }

    if(!id || !name || value === undefined || !onChange){
        return(
            <div className="text-mpLRed">
                Datepicker missing props: {!id && 'id'} {!name && 'name'} {value === undefined && 'value (yyyy-mm-dd)'} {!onChange && 'onChange'}
            </div>
        );
    }

    return (
        <div className="w-min">
            <TextField
                id={id}
                label={label}
                type="date"
                disabled={disabled}
                value={value}
                className={classNames}
                onChange={onChangeHelper} // 'yyyy-mm-ddThh:mm'
                InputLabelProps={{ shrink: shrinkLabel }}
            />
        </div>
    );
}

export function DateTimePicker(props){
    const {
        id,
        name,
        label = '',
        value,
        onChange,
        classNames,
        disabled = false,
        shrinkLabel = true,
    } = props;

    function onChangeHelper(e){
        const date = e.target.value;
        const dateObj = getDateTimeObject(date);

        onChange({ target: { value: dateObj, name: name } });
    }

    if(!id || !name || value === undefined || !onChange){
        return(
            <div className="text-mpLRed">
                DateTimePicker missing props: {!id && 'id'} {!name && 'name'} {value === undefined && 'value (yyyy-mm-ddThh:mm)'} {!onChange && 'onChange'}
            </div>
        );
    }

    return (
        <div className="w-min">
            <TextField
                id={id}
                label={label}
                disabled={disabled}
                type="datetime-local"
                value={value}
                className={classNames}
                onChange={onChangeHelper} // 'yyyy-mm-ddThh:mm'
                InputLabelProps={{ shrink: shrinkLabel }}
            />
        </div>
    );
}

export function DateRangeSelector(props){
    const {
        id,
        startName,
        endName,
        startLabel = '',
        endLabel = '',
        startValue,
        endValue,
        onStartChange,
        onEndChange,
        onChangeValidation = () => null,
        classNames,
        disabled = false,
        shrinkLabel = true,
        useCustomValidation = false,
    } = props;
    
    const [startDate, setStartDate] = useState(new Date(startValue));
    const [endDate, setEndDate] = useState(new Date(endValue));
    const [valid, setValid] = useState(true);
    
    function onStartChangeHelper(e){
        setStartDate(e.target.value);
        onChangeValidator(e.target.value, endDate);
        onStartChange({ target: { value: e.target.value, name: startName } });
    }
    function onEndChangeHelper(e){
        setEndDate(e.target.value);
        onChangeValidator(startDate, e.target.value);
        onEndChange({ target: { value: e.target.value, name: endName }});
    }

    function onChangeValidator(startDate, endDate){
        const startDateNew = new Date(startDate);
        const endDateNew = new Date(endDate);
        const isValid = startDateNew <= endDateNew;
        onChangeValidation(isValid);
        setValid(isValid);
    }

    if(!id || !startName || !endName || startValue === undefined || endValue === undefined || !onStartChange || !onEndChange){
        return(
            <div className="text-mpLRed">
                DateRangeSelector missing props: {!id && 'id'} {!startName && 'startName'} {!endName && 'endName'}&nbsp;
                {startValue === undefined && 'startValue (yyyy-mm-ddThh:mm)'} {endValue === undefined && 'endValue (yyyy-mm-ddThh:mm)'}&nbsp;
                {!onStartChange && 'onStartChange'} {!onEndChange && 'onEndChange'}
            </div>
        );
    }

    return (
        <div className="w-min">
            <div className="flex flex-row gap-x-4">
                <TextField
                    id={`${id}-start`}
                    label={startLabel}
                    type="date"
                    disabled={disabled}
                    value={startValue}
                    className={classNames}
                    onChange={onStartChangeHelper} // 'yyyy-mm-ddThh:mm'
                    InputLabelProps={{ shrink: shrinkLabel }}
                />

                <TextField
                    id={`${id}-end`}
                    label={endLabel}
                    type="date"
                    disabled={disabled}
                    value={endValue}
                    className={classNames}
                    onChange={onEndChangeHelper} // 'yyyy-mm-ddThh:mm'
                    InputLabelProps={{ shrink: shrinkLabel }}
                />
            </div>

            {!valid && !useCustomValidation ? (
                <>
                    <br/>
                    <div className="text-mpLRed">Start Date cannot be after End Date</div>
                </>
            ) : null}
        </div>
    );
}