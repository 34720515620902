import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import LearningPlanCompleteBodyFooter from './LearningPlanCompleteBF';
import { checkResponse } from '../../form';

import {
    fetchLearningPlansId,
    fetchStudentsUserIds,
    updateLearningPlanComplete
} from '../../../actions';

function LearningPlanCompleteModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [attemptingClose, setAttemptingClose] = useState(false);
    
    const [learningPlan, setLearningPlan] = useState({});

    const { selectedLearningPlan, selectedStudent, onSubmitCallback,
        fetchLearningPlansId, fetchStudentsUserIds, updateLearningPlanComplete } = props;

    useEffect(() => {
        async function init(){           
            const studentsRes = await fetchStudentsUserIds({ userIds: [selectedStudent.value] });
            const newStudent = studentsRes.data?.[0] || {};
            const newStudentName = newStudent.user_id ?
                `${newStudent.first_name} ${newStudent.last_name}` : `Unknown student (UID: ${selectedStudent.value})`;

            const learningPlansRes = await fetchLearningPlansId({ id: selectedLearningPlan.id });
            const newLearningPlan = learningPlansRes.data?.learningPlans?.[0] || {};
            newLearningPlan.studentName = newStudentName;

            if(mounted.current){
                setLearningPlan(newLearningPlan);
            }
            
            if(mounted.current){
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes, force = false) => {
        if(!attemptingClose && !force){
            setAttemptingClose(true);
            return;
        }
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, setAttemptingClose]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            if(values.confirmText !== 'Confirm'){
                setSubmitting(false);
                setStatus('Confirmation text is required.')
                return;
            }
            
            let response = { status: 999 };
            
            response = await updateLearningPlanComplete({
                learningPlan,
                additionalMessage: values.additionalMessage
            })

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [handleClose, updateLearningPlanComplete, learningPlan]);

    return (
        <Modal className="w-2/3" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Learning Plan Complete Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <LearningPlanCompleteBodyFooter
                    learningPlan={learningPlan}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchLearningPlansId,
    fetchStudentsUserIds,
    updateLearningPlanComplete
})(LearningPlanCompleteModal);