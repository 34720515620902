import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import BookingAssistantBodyFooter from './BookingAssistantBF';
import { checkResponse } from '../../form';
import { getHoursInfo } from './helpers';

import {
    fetchServerTime,
    fetchAppointmentsDaterange,
    fetchAvailabilityDaterange,
    fetchStudentsActive,
    fetchMpCentersAll,
    createAppointmentsBulk
} from '../../../actions';
import { formatDateApi } from '../../functions';

const initDate = new Date(3000, 0, 1);
initDate.setHours(0, 0, 0, 0);

function BookingAssistantModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [oneMonthsAppointments, setOneMonthsAppointments] = useState([]);
    const [oneMonthsAvailabilities, setOneMonthsAvailabilities] = useState([]);
    const [oneMonthsBlocks, setOneMonthsBlocks] = useState([]);
    const [today, setToday] = useState(initDate);
    const [inFourWeeks, setInFourWeeks] = useState(initDate);
    const [studentOptions, setStudentOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const { selectedCenter, onSubmitCallback, fetchServerTime, fetchAppointmentsDaterange, fetchAvailabilityDaterange,
        fetchStudentsActive, fetchMpCentersAll, createAppointmentsBulk } = props;

    const refreshData = useCallback(() => {
        (async function(){
            if(refreshing) return;
            if(mounted.current) setRefreshing(true);

            const timeRes = await fetchServerTime();
            const timeObj = timeRes.data?.[0]?.CURRENT_TIMESTAMP;
            const newToday = timeObj ? new Date(timeObj) : new Date();
            newToday.setHours(0, 0, 0, 0);
            const newInFourWeeks = new Date(newToday);
            newInFourWeeks.setDate(newInFourWeeks.getDate() + 27);
            newInFourWeeks.setHours(0, 0, 0, 0);
            
            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsActive();
            const appointmentsRes = await fetchAppointmentsDaterange({
                startDate: newToday,
                endDate: newInFourWeeks
            });
            const aaRes = await fetchAvailabilityDaterange({
                startDate: formatDateApi(newToday),
                endDate: formatDateApi(newInFourWeeks)
            });
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newAppointments = appointmentsRes.data?.appointments || [];
            const newAppointmentsFiltered = newAppointments.filter(a => !['Cancelled', 'Missed'].includes(a.status));
            const newAvailabilities = aaRes.data?.availabilities || [];
            const newBlocks = aaRes.data?.blocks || [];

            const newStudentOptions = !newStudents.length ?
                [{ value: -1, label: 'No students were found...' }] :
                newStudents.filter(s => {
                    return parseInt(s.is_lead) === 0;
                }).filter(s => {
                    return parseInt(s.is_mp_student) === 1 && parseInt(s.mp_active) === 1;
                }).map(s => ({ value: s.user_id, object: s, label: `${s.first_name} ${s.last_name}` }));
            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));
            
            if(mounted.current){
                setToday(newToday);
                setInFourWeeks(newInFourWeeks);
                setOneMonthsAvailabilities(newAvailabilities);
                setOneMonthsBlocks(newBlocks);
                setOneMonthsAppointments(newAppointmentsFiltered);
                setStudentOptions(newStudentOptions);
                setCenterOptions(newCenterOptions)
                setRefreshing(false);
                setLoaded(true);
            }
        })();
    }, [mounted, refreshing, fetchAppointmentsDaterange, fetchAvailabilityDaterange,
        fetchMpCentersAll, fetchServerTime, fetchStudentsActive]);

    useEffect(() => {
        refreshData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { selectedStudents, selectedAppointments } = values;
            const { setStatus, setSubmitting } = actions;
            
            if(mounted.current) setSubmitting(true);

            // Reject if a student does not have enough hours left
            if(!getHoursInfo(selectedStudents, selectedAppointments)[1]){
                if(mounted.current){
                    setSubmitting(false);
                    setStatus('At least one student does not have enough hours.');
                    setSubmitting(false);
                }
                return;
            }

            // PREPARE DATA //
            const appointmentsParams = {};
            Object.entries(selectedAppointments).forEach(([dateKey, apt]) => {
                // appointmentBase contains some preset values for each selected day
                // Pushed appointments are a copy of this object
                const appointmentBase = { status: 'Scheduled' };
                const [year, month, date] = dateKey.split('_');
                const [center, time, duration] = apt.value.split('.');
                const hours = Math.floor(time / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
                const minutes = (time % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });;
                appointmentBase.dateTime = new Date(year, month - 1, date, hours, minutes, 0);
                appointmentBase.center = center;
                appointmentBase.duration = duration;
                appointmentBase.status = 'Scheduled';

                selectedStudents.forEach(s => {
                    const student = s.object;
                    const uid = student.user_id;
                    const appointment = {...appointmentBase};

                    appointment.student = uid;
                    appointment.parent = student.parent;
                    appointment.weight = student.mp_weight;
                    appointment.specialNotes = student.mp_special_notes;
                    appointment.specialNotes2 = student.mp_special_notes_2;

                    if(!appointmentsParams[uid]){
                        appointmentsParams[uid] = {
                            // The name is just used for the progress tracker when submitting
                            name: `${student.first_name} ${student.last_name}`, 
                            appointments: []
                        };
                    }
                    appointmentsParams[uid].appointments.push(appointment);
                });
            });
            // END-PREPARE DATA //

            // SEND DATA //
            const nss = { errored: false, completed: false };
            Object.entries(appointmentsParams).forEach(([uid, ap]) => {
                nss[uid] = { name: ap.name, completed: false, message: '' };
            });
            setSubmissionStatus({...nss});

            for(let [uid, ap] of Object.entries(appointmentsParams)){
                const response = await createAppointmentsBulk({ appointments: ap.appointments });
                nss[uid].completed = true;
                nss.errored = !checkResponse(response, mounted, (resp) => nss[uid].message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            };
            // END-SEND DATA //
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus(`One or more errors occurred during submission.
                    Please be careful! Resubmitting this form will attempt to book appointments for all selected students.
                    If there was a check mark next to a student's name on the submission page, all of their appointments
                    were submitted successfully. If there was an 'x', at least one of the student's appointments did not
                    book successfully. This means that some appointments may have booked successfully. Resubmitting
                    will double book any such appointments.`);
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [handleClose, createAppointmentsBulk, oneSuccess]);

    return (
        <Modal className="w-3/4" show={showModal} onHide={() => null} backdrop="static">
            <Modal.Header>
                <h2>Booking Assistant</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <BookingAssistantBodyFooter
                    loaded={loaded}
                    refreshing={refreshing}
                    submissionStatus={submissionStatus}
                    submitted={submitted}
                    refreshData={refreshData}
                    studentOptions={studentOptions}
                    centerOptions={centerOptions}
                    selectedCenter={selectedCenter}
                    appointments={oneMonthsAppointments}
                    availabilities={oneMonthsAvailabilities}
                    blocks={oneMonthsBlocks}
                    today={today}
                    inFourWeeks={inFourWeeks}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchServerTime,
    fetchAppointmentsDaterange,
    fetchAvailabilityDaterange,
    fetchStudentsActive,
    fetchMpCentersAll,
    createAppointmentsBulk
})(BookingAssistantModal);