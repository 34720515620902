import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalProcessing, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { validationSchema } from './helpers';
import { SelectSingle, FormikControl } from '../../form';

const hasExamsOptions = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }]

function UpcomingExamsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedUE, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    hasExams: parseInt(selectedUE.has_exams) === 1 ? hasExamsOptions[1] : hasExamsOptions[0],
                    upcomingExamsNotes: selectedUE.notes || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div>
                                <h3>{selectedUE.studentName}</h3>
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                <h4>Has Upcoming Exams?</h4>
                                <SelectSingle
                                    id="upcoming-exams-has-exams"
                                    name="hasExams"
                                    value={formik.values.hasExams}
                                    onChange={formik.handleChange}
                                    options={hasExamsOptions}
                                />
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                <h4>Upcoming Exams Notes</h4>
                                <FormikControl
                                    as="textarea"
                                    id="upcoming-exams-notes"
                                    name="upcomingExamsNotes"
                                    value={formik.values.upcomingExamsNotes}
                                    onChange={formik.handleChange}
                                    placeholder="Notes on upcoming exams the student may have. Limit 300 characters"
                                />
                                {formik.errors.upcomingExamsNotes && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.upcomingExamsNotes}
                                    </ErrorMessage>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed" style={{ marginRight: "5px" }}>
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed" style={{ marginRight: "2rem" }}>
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => formik.handleSubmit(formik)}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(UpcomingExamsBodyFooter);