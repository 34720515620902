import React from 'react';
import { formatDateTime } from '../functions';
import { TooltipWrapper } from '../display';
import { Label } from '../custom-essentials';

export function renderStatus(row){
    let color = 'mpLGrey';
    switch(row.status){
        case 'Scheduled':
            color = 'mpOrange';
            break;
        case 'In Progress':
            color = 'mpYellow';
            break;
        case 'Missed':
        case 'Cancelled':
        case 'Not Charged':
            color = 'mpLRed';
            break;
        case 'Completed':
            color = 'mpGreen';
            break;
        case 'Pending':
            color = 'mpYellow';
            break;
        case 'Approved':
            color = 'mpGreen';
            break;
        case 'Denied':
            color = 'mpLRed';
            break;
        default:
            break;
    }

    const createdByMeta = row.created_by ? (
        <>
            <div>Created: {formatDateTime(row.date_created)} by {row.createdByName}</div>
        </>
    ) : null;
    const updatedByMeta = row.updated_by ? (
        <>
            {createdByMeta ? <br/> : null}
            <div>Updated: {formatDateTime(row.date_updated)} by {row.updatedByName}</div>
        </>
    ) : null;
    if(!createdByMeta && !updatedByMeta){
        return <Label color={color}>{row.status}</Label>;
    } else {
        return (
            <TooltipWrapper
                tooltipText={
                    <>
                        {createdByMeta}
                        {updatedByMeta}
                    </>
                }
            >
                <Label color={color}>
                    {row.status}
                </Label>
            </TooltipWrapper>
        );
    }
}