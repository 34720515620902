import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Tab, Tabs } from '@mui/material';

import { Button, TabPanel } from '../../../../components/custom-essentials';
import { formatDateApi } from '../../../../components/functions';
import { DateRangeSelector, checkResponses } from '../../../../components/form';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import TextLogsTable from './textLogs/TextLogsTable';
import EmailLogsTable from './emailLogs/EmailLogsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchTextLogs,
    fetchEmailLogs
} from '../../../../actions';

const pageTitle = 'Text and Email Logs';

const start = new Date();
start.setDate(start.getDate() - 6);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function TextEmailLogs(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [drsValid, setDrsValid] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    // Data
    const [textLogsData, setTextLogsData] = useState([]);
    const [emailLogsData, setEmailLogsData] = useState([]);

    const [activeTabKey, setActiveTabKey] = useState(0);

    const { fetchTextLogs, fetchEmailLogs } = props;

    const refreshData = useCallback((values) => {
        (async function refresh(){
            if(loading || !drsValid) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate } = values;
    
            const textLogsRes = await fetchTextLogs({
                startDate: startDate,
                endDate: endDate
            });
            const emailLogsRes = await fetchEmailLogs({
                startDate: startDate,
                endDate: endDate
            });
            const isApiError = checkResponses(textLogsRes, emailLogsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newTextLogs = textLogsRes.data || [];
            newTextLogs.forEach(tl => {
                tl.textLogId = `tl-${tl.id}`;
                tl.textBody = tl.body;
            });
            const newEmailLogs = emailLogsRes.data || [];
            newEmailLogs.forEach(el => {
                el.emailLogId = `el-${el.id}`;
                el.emailBody = el.body;
            });
    
            if(mounted.current){
                setTextLogsData(newTextLogs);
                setEmailLogsData(newEmailLogs);
                setLoading(false);
                setHasLoaded(true);
            }
        })();
    }, [loading, drsValid, fetchTextLogs, fetchEmailLogs]);
    useEffect(() => {
        (async function(){
            refreshData({ startDate: startApi, endDate: endApi });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        showAll: false,
                        activeKey: 'textLogs'
                    }}
                    innerRef={formRef}
                    onSubmit={refreshData}
                >
                    {formik => (
                        <>
                            {formik.values.activeKey === 'textLogs' ? 
                                <h2>Text Log Entries</h2> :
                                <h2>Email Log Entries</h2>
                            }

                            <br/>

                            <div className="flex flex-row gap-x-4 items-center">
                                <div>
                                    <DateRangeSelector
                                        id="assigned-appointments-drs"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div>
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                                <div className="ml-auto">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All text logs that are currently filtered ({textLogsData.length} items).
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Text_Logs"
                                            label="Export logs to CSV"
                                            data={textLogsData}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>

                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div>:

                                <>
                                    <Tabs
                                        id="textEmailLogs"
                                        value={activeTabKey}
                                        onChange={(e, newValue) => setActiveTabKey(newValue)}
                                    >
                                        <Tab label={`Text Logs (${textLogsData.length})`}/>
                                        <Tab label={`Email Logs (${emailLogsData.length})`}/>
                                    </Tabs>
                                    <TabPanel activeKey={activeTabKey} index={0}>
                                        {hasLoaded ? 
                                            <TextLogsTable
                                                data={textLogsData}
                                            /> : null
                                        }
                                    </TabPanel>
                                    <TabPanel activeKey={activeTabKey} index={1}>
                                        {hasLoaded ? 
                                            <EmailLogsTable
                                                data={emailLogsData}
                                            /> : null
                                        }
                                    </TabPanel>
                                </>
                            }
                        </>
                    )}
                </Formik>
            </div>
            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchTextLogs,
    fetchEmailLogs
})(TextEmailLogs);