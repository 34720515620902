import React from 'react';

import { EllipsisLoader } from '../custom-essentials';
import { findComponentByType } from '../functions';

const modalHRule = <hr className="text-mpLGrey-4 mx-3"/>;
export function ModalBodyFooter(props){
    const { children } = props;
    const body = findComponentByType(children, 'Body');
    const footer = findComponentByType(children, 'Footer');

    return (
        <>
            <div className="px-8 pt-5 pb-4">
                {body}
            </div>
            {modalHRule}
            <div className="px-8 py-3 flex flex-row-reverse w-full">
                {footer}
            </div>
        </>
    )
}

function BodyAndFooter(props){
    const { body, footer } = props;
    let errorText = '';
    if(!body && !footer) errorText = 'Modal needs a BodyFooter subcomponent, or both Body and Footer subcomponents';
    if(body && !footer) errorText = 'Modal missing footer subcomponent';
    if(!body && footer) errorText = 'Modal missing body subcomponent';
    if(errorText) return <div className="text-mpLRed">{errorText}</div>;
    return (
        <>
            <div className="px-8 pt-5 pb-4">
                {body}
            </div>
            {modalHRule}
            <div className="px-8 py-3 flex flex-row-reverse w-full">
                {footer}
            </div>
        </>
    );
}
export function Modal(props){
    const { children, show, onHide, preventClickout, className } = props;

    const header = findComponentByType(children, 'Header');
    const body = findComponentByType(children, 'Body');
    const footer = findComponentByType(children, 'Footer');
    const bodyFooter = findComponentByType(children, 'BodyFooter'); // For compatibility with old modals
    
    return (
        <div className={`relative z-[3000] ${show ? '' : 'hidden'} duration-300`}>
            <div className="fixed inset-0 bg-mpLGrey-1 bg-opacity-30"/>
            <div className="fixed inset-0 overflow-y-auto py-10" onClick={() => preventClickout ? null : onHide()}>
                <div className="relative flex min-h-full justify-center items-center">
                    <div
                        className={`relative transform rounded-lg bg-white
                            text-left shadow-xl transition-all ${className || 'w-1/3'} min-w-[45vw]`}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="px-8 pt-5 pb-4">
                            {header}
                        </div>
                        {modalHRule}
                        {/* Need to use the ModalBodyFooter wrapper if a custom component is used to render */}
                        {/* Modal.Body and Modal.Footer (this is the case for all v3 modals) */}
                        {/* Otherwise, use the private BodyAndFooter component */}
                        {bodyFooter.length ? bodyFooter
                            : <BodyAndFooter body={body} footer={footer}/>}
                    </div>
                </div>
            </div>
        </div>
    );
}
export const ModalLoading = () => (
    <div className="flex flex-row gap-x-4 items-center">
        <EllipsisLoader/>
        <div className="text-mpLGrey-4">Loading...</div>
    </div>
);
export const ModalProcessing = () => (
    <div className="flex flex-row gap-x-4 items-center">
        <span className="mr-8"><EllipsisLoader/></span>
        Processing...
    </div>
);

const Header = (props) => <>{props.children}</>;
Header.displayName = "Header";
Modal.Header = Header;

const Body = (props) => <>{props.children}</>;
Body.displayName = "Body";
ModalBodyFooter.Body = Body;
Modal.Body = Body;

const Footer = (props) => <>{props.children}</>;
Footer.displayName = "Footer";
ModalBodyFooter.Footer = Footer;
Modal.Footer = Footer;

const BodyFooter = (props) => <>{props.children}</>;

BodyFooter.displayName = "BodyFooter";
Modal.BodyFooter = BodyFooter;