import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { FormikControl, Check } from '../../form';
import { validationSchema } from './helpers';

import {

} from '../../../actions';
import { TooltipWrapper } from '../../display';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function LessonsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedLesson, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this lesson?</h4>
                            <div className="grid grid-cols-1">
                                <div><b>ID:</b> {selectedLesson.lesson_id}</div>
                                <div><b>Name:</b> {selectedLesson.name}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-s">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    lessonId: selectedLesson.lesson_id || '',
                    name: selectedLesson.name || '',
                    lessonLength: parseInt(selectedLesson.lesson_length) || 0,
                    defaultSortOrder: parseInt(selectedLesson.default_sort_order) || 0,
                    hasFile: parseInt(selectedLesson.has_file) === 1 || false,
                    hasAnswerKey: parseInt(selectedLesson.has_answer_key) === 1 || false,
                    active: parseInt(selectedLesson.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <TooltipWrapper
                                        placement="bottom"
                                        tooltipText={
                                            <div>
                                                ID as listed in the table of contents.
                                                Usually a 4 digit number. Only one active lesson may exist per ID.
                                                This is used to identify different versions of a lesson across name
                                                changes and other updates.
                                            </div>
                                        }
                                    >
                                        <h4 className="text-mpLBlue">Lesson ID</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="lessons-lesson-id"
                                        name="lessonId"
                                        value={formik.values.lessonId}
                                        onChange={formik.handleChange}
                                        placeholder="4-10 characters."
                                    />
                                    {formik.errors.lessonId ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.lessonId}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <TooltipWrapper
                                        tooltipText="Number of pages"
                                    >
                                        <h4 className="text-mpLBlue">Lesson Length</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="lessons-lessonLength"
                                        name="lessonLength"
                                        value={formik.values.lessonLength}
                                        onChange={formik.handleChange}
                                        placeholder="Min: 1, Max: 50"
                                    />
                                    {formik.errors.lessonLength ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.lessonLength}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <h4>Sort Order</h4>
                                    <FormikControl
                                        id="lessons-sortOrder"
                                        name="defaultSortOrder"
                                        value={formik.values.defaultSortOrder}
                                        onChange={formik.handleChange}
                                        placeholder="Min: 1, Max: 1,000,000,000"
                                    />
                                    {formik.errors.defaultSortOrder ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.defaultSortOrder}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Lesson Name</h4>
                                    <FormikControl
                                        id="lessons-name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        placeholder="Max. 200 characters."
                                    />
                                    {formik.errors.name ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.name}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="flex flex-row gap-x-4">
                                    <TooltipWrapper
                                        tooltipText={
                                            <>
                                                <div>
                                                    Check true if a file has been uploaded for this lesson.
                                                </div>
                                            </>
                                        }
                                    >
                                        <Check
                                            id="lessons-hasFile"
                                            name="hasFile"
                                            color="mpLRed"
                                            label={<div className="text-mpLBlue">File?</div>}
                                            checked={formik.values.hasFile}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                    <TooltipWrapper
                                        tooltipText={
                                            <>
                                                <div>
                                                    Check true if an answer key has been uploaded for this lesson.
                                                </div>
                                            </>
                                        }
                                    >
                                        <Check
                                            id="lessons-hasAnswerKey"
                                            name="hasAnswerKey"
                                            color="mpLRed"
                                            label={<div className="text-mpLBlue">Answer Key?</div>}
                                            checked={formik.values.hasAnswerKey}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                    <div className="ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>
                                                        Inactive lessons are hidden by default.
                                                    </div>
                                                </>
                                            }
                                        >
                                            <Check
                                                id="lessons-active"
                                                name="active"
                                                color="mpLRed"
                                                label={<div className="text-mpLBlue">Active</div>}
                                                checked={formik.values.active}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(LessonsBodyFooter);