import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import MembersBodyFooter from './MembersBF';
import { checkResponse } from '../../form';

import {
    fetchMembersIds,
    fetchMpCentersAll,
    fetchRpCentersAll,
    createMember,
    updateMember,
    updateMemberPassword,
    resendMemberWelcome,
    deleteMember
} from '../../../actions';

// Exact same form as the Employee Modal, with variables and actions changed slightly
function MemberForm(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    
    const [member, setMember] = useState({});
    const [mpCenterOptions, setMpCenterOptions] = useState([]);
    const [rpCenterOptions, setRpCenterOptions] = useState([]);
    const [showAdditional, setShowAdditional] = useState(false);

    const { mode, selectedMember, onSubmitCallback, fetchMembersIds, fetchMpCentersAll, fetchRpCentersAll,
        createMember, updateMember, updateMemberPassword, resendMemberWelcome, deleteMember } = props;

    useEffect(() => {
        async function init(){
            if(mode !== 'create'){
                const memberRes = await fetchMembersIds({ ids: [selectedMember.id] });
                const newMember = memberRes.data?.[0];

                if(mounted.current) setMember(newMember);
            }

            const mpCentersRes = await fetchMpCentersAll();
            const rpCentersRes = await fetchRpCentersAll();
            const newMpCenters = mpCentersRes.data;
            const newRpCenters = rpCentersRes.data;
            const newMpCenterOptions = [...newMpCenters.map(c => ({ value: parseInt(c.id), label: c.name })), { value: -1, label: 'None' }];
            const newRpCenterOptions = [...newRpCenters.map(c => ({ value: parseInt(c.id), label: c.name })), { value: -1, label: 'None' }];

            if(mounted.current){
                setMpCenterOptions(newMpCenterOptions);
                setRpCenterOptions(newRpCenterOptions);
                setLoaded(true);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const passwordReset = useCallback((formik) => {
        async function submit(){
            const { setStatus, setSubmitting, values } = formik;
            const { passwordReset, passwordConfirm } = values;
            if(passwordReset !== passwordConfirm){
                setStatus('Passwords do not match.');
                return;
            }
    
            setSubmitting(true);
    
            let response = { status: 999 };
            const passwordParams = {
                id: member.id,
                password: passwordReset
            };
            response = await updateMemberPassword(passwordParams);
    
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
    
            if(mounted.current){
                setResetSuccess(true);
                setSubmitting(false);
            }
        }
        submit()
    }, [updateMemberPassword, member])
    const resendWelcome = useCallback((formik) => {
        (async function submit(){
            const { setStatus, setSubmitting, values } = formik;
            const { resendWelcomeConfirm } = values;
            if(resendWelcomeConfirm !== 'Resend Email'){
                setStatus('Verification field mismatch.');
                return;
            }
    
            setSubmitting(true);
    
            let response = { status: 999 };
            response = await resendMemberWelcome({ id: member.id });
    
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }

            if(mounted.current){
                formik.setFieldValue('resendWelcomeConfirm', '');
                setResendSuccess(true);
                setSubmitting(false);
            }
        })();
    }, [resendMemberWelcome, member]);
    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const mpCenterPermissionsInvalid = (
                    (values.mpPermissions.value !== 'None' && values.mpPrimaryCenter.value === -1) ||
                    (values.mpPermissions.value === 'None' && values.mpPrimaryCenter.value !== -1)
                );
                const rpCenterPermissionsInvalid = (
                    (values.rpPermissions.value !== 'None' && values.rpPrimaryCenter.value === -1) ||
                    (values.rpPermissions.value === 'None' && values.rpPrimaryCenter.value !== -1)
                );
                if(mpCenterPermissionsInvalid || rpCenterPermissionsInvalid){
                    setStatus(`For each center, Primary Center and Permissions must both be 'None' or both NOT be 'None'.`);
                    setSubmitting(false);
                    return;
                }
                if(mode === 'create'){
                    if(values.mpPermissions.value === 'None') values.mpActive = false;
                    else values.mpActive = true;
                    if(values.rpPermissions.value === 'None') values.rpActive = false;
                    else values.rpActive = true;
                }
                if((values.mpPermissions.value === 'None' && values.mpActive) || (values.rpPermissions.value === 'None' && values.rpActive)){
                    setStatus(`For each center, accounts must have a permissions role to be active.`);
                    setSubmitting(false);
                    return;
                }
                if(values.mpPermissions.value === 'None' && values.rpPermissions.value === 'None'){
                    setStatus(`User must have at least one permissions role.`);
                    setSubmitting(false);
                    return;
                }
                if((values.mpPermissions.value === 'Parent' && values.rpPermissions.value === 'Student') ||
                    (values.mpPermissions.value === 'Student' && values.rpPermissions.value === 'Parent')){
                    setStatus(`User cannot have both Parent and Student permissions.`);
                    setSubmitting(false);
                    return;
                }

                const memberParams = {
                    email: values.email,
                    receiveEmails: values.receiveEmails ? 1 : 0,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone.toString().replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
                    receiveTexts: values.receiveTexts ? 1 : 0,
                    mpPrimaryCenter: values.mpPrimaryCenter.value,
                    rpPrimaryCenter: values.rpPrimaryCenter.value,
                    mpPermissions: values.mpPermissions.value,
                    rpPermissions: values.rpPermissions.value,
                    userNotes: values.userNotes,
                    verified: values.verified ? 1 : 0,
                    mpActive: values.mpActive ? 1 : 0,
                    rpActive: values.rpActive ? 1 : 0,
                    shouldSendWelcomeEmail: values.shouldSendWelcomeEmail ? 1 : 0
                };
                
                if(mode === 'create'){
                    response = await createMember(memberParams);
                } else if(mode === 'edit') {
                    memberParams.id = member.id;
                    response = await updateMember(memberParams);
                }
            } else if(mode === 'delete') {
                response = await deleteMember({ id: member.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, member, createMember, updateMember, deleteMember]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Member Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <MembersBodyFooter
                    mode={mode}
                    selectedMember={member}
                    mpCenterOptions={mpCenterOptions}
                    rpCenterOptions={rpCenterOptions}
                    loaded={loaded}
                    passwordReset={passwordReset}
                    resetSuccess={resetSuccess}
                    resendWelcome={resendWelcome}
                    resendSuccess={resendSuccess}
                    showAdditional={showAdditional}
                    setShowAdditional={setShowAdditional}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMembersIds,
    fetchMpCentersAll,
    fetchRpCentersAll,
    createMember,
    updateMember,
    updateMemberPassword,
    resendMemberWelcome,
    deleteMember
})(MemberForm);