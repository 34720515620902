import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { formatDate, getTimeObject } from '../../functions';
import { SelectSingle, SelectMulti, Check, TimePicker } from '../../form';
import { validationSchema, renderStudentHours, renderSubmitting, getInitCenterOption,
    getAdditionalInitialValues, onChangeResetValues } from './helpers';
import BodyLowerHalf from './BodyLowerHalf';
import { TooltipWrapper } from '../../display';

function BookingAssistantBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [firstConfirm, setFirstConfirm] = useState(false);

    const { loaded, refreshing, submissionStatus, submitted,
        refreshData, studentOptions, centerOptions, selectedCenter, appointments,
        availabilities, blocks, today, inFourWeeks, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Appointments created!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    selectedStudents: [],
                    selectedCenter: getInitCenterOption(selectedCenter?.value, centerOptions),
                    showAvailable: true,
                    showSelected: true,
                    overrideMode: false,
                    minimumTime: getTimeObject(720),
                    maximumTime: getTimeObject(1080),
                    reloadCount: 0,
                    selectedAppointments: {},
                    ...getAdditionalInitialValues(today, selectedCenter)
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? 
                        renderSubmitting(submissionStatus, formik.setSubmitting, setFirstConfirm) :
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div>Use this form to book appointments up to four weeks in advance.</div>
                            <div>Appointment dates will only show up if their availability has been released and at least one center is open.</div>
                            <div>Days searched: {formatDate(today)} to {formatDate(inFourWeeks)}</div>

                            <div className="h-8 clear-both"/>

                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                Use this to ignore availabilities and view all dates and specified times.
                                                Students with insufficient hours will still be blocked from booking.
                                            </div>
                                        }
                                    >
                                        <Check
                                            id="booking-assistant-override-mode"
                                            name="overrideMode"
                                            color="mpLRed"
                                            label={<div className="text-mpLBlue">Override Mode</div>}
                                            checked={formik.values.overrideMode}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <div className="flex flex-row gap-x-4 items-end">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                            <h4>Minimum Time</h4>
                                            <TimePicker
                                                id="booking-assistant-minimumTime"
                                                name="minimumTime"
                                                step={1800}
                                                value={formik.values.minimumTime?.formatted24 || ''}
                                                onChange={formik.handleChange}
                                                disabled={!formik.values.overrideMode}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                            <h4>Maximum Time</h4>
                                            <TimePicker
                                                id="booking-assistant-maximumTime"
                                                name="maximumTime"
                                                step={1800}
                                                value={formik.values.maximumTime?.formatted24 || ''}
                                                onChange={formik.handleChange}
                                                disabled={!formik.values.overrideMode}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => formik.setFieldValue('reloadCount', formik.values.reloadCount + 1)}
                                                disabled={!formik.values.overrideMode}
                                            >
                                                Refresh Options
                                            </Button>
                                        </div>
                                    </div>
                                    {formik.errors.checkTimesValid ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.checkTimesValid}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <div className="h-8 clear-both"/>
                            <hr/>
                            <div className="h-6 clear-both"/>

                            {/* BEGIN TOP SECTION */}
                            <div className="flex flex-row gap-x-4">
                                <div className="w-1/2">
                                    <div className="flex flex-row gap-x-4 items-end">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <h4>Students</h4>
                                            <SelectMulti
                                                id="booking-assistant-main-students"
                                                name="selectedStudents"
                                                value={formik.values.selectedStudents}
                                                onChange={(e) => {
                                                    // Length cannot exceed 5
                                                    // Do not allow additions if appointments are selected
                                                    // But allow removals in all cases
                                                    if(e.target.value?.length <= 5 && 
                                                        (!Object.keys(formik.values.selectedAppointments).length ||
                                                        e.target.value?.length < formik.values.selectedStudents.length)
                                                    ){
                                                        // Reset selectedAppointments if all students are removed,
                                                        // so that the form doesn't lock up (can't add students
                                                        // if appointments are selected)
                                                        const changes = [e]
                                                        if(!e.target.value.length){
                                                            changes.push({target: {value: {}, name: 'selectedAppointments'}});
                                                        }
                                                        onChangeResetValues(
                                                            today,
                                                            formik.setValues,
                                                            formik.values,
                                                            changes,
                                                        );
                                                    }
                                                }}
                                                options={studentOptions}
                                            />
                                            {formik.errors.selectedStudents ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.selectedStudents}
                                                </ErrorMessage>
                                            ) : null}
                                            {formik.values.selectedStudents.length >= 5 ? 
                                                <>
                                                    <br/>
                                                    <div className="text-mpOrange">
                                                        Maximum 5 students
                                                    </div>
                                                </> : null
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {Object.keys(formik.values.selectedAppointments).length ? 
                                            <>
                                                <br/>
                                                <div className="text-mpLBlue">
                                                    Heads up! While appointments are selected, students can only be removed.
                                                </div>
                                            </>
                                                : null
                                        }
                                    </div>
                                    <div className="h-4 clear-both"/>
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Desired Center</h4>
                                        <SelectSingle
                                            id="booking-assistant-main-center"
                                            name="selectedCenter"
                                            value={formik.values.selectedCenter}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                onChangeResetValues(
                                                    today,
                                                    formik.setValues,
                                                    formik.values,
                                                    [e],
                                                );
                                            }}
                                            options={centerOptions}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Hours Overview</h4>
                                    <div className="max-h-[40vh] overflow-y-auto">
                                        {renderStudentHours(formik.values.selectedStudents,
                                            formik.values.selectedAppointments, formik.setStatus)}
                                    </div>
                                </div>
                            </div>
                            {/* END TOP SECTION */}

                            <div className="h-4 clear-both"/>
                            <hr/>
                            <div className="h-4 clear-both"/>

                            {/* BEGIN BOTTOM SECTION */}
                            { formik.values.selectedStudents.length ? 
                                <BodyLowerHalf
                                    formik={formik}
                                    today={today}
                                    appointments={appointments}
                                    availabilities={availabilities}
                                    centerOptions={centerOptions}
                                    blocks={blocks}
                                /> : null
                            }
                            {/* END BOTTOM SECTION */}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 w-full flex-wrap">
                                {!formik.isValid && formik.submitCount && firstConfirm ?
                                    <>
                                        <div
                                            className="text-mpLRed flex flex-row items-end"
                                            style={{ marginRight: "5px" }}
                                        >
                                            One or more fields needs to be corrected.
                                        </div>
                                        <br/>
                                    </> : null
                                }
                                {formik.status && formik.submitCount ?
                                    <>
                                        <div
                                            className="text-mpLRed flex flex-row items-end"
                                            style={{ marginRight: "5px" }}
                                        >
                                            {formik.status}
                                        </div>
                                        <br/>
                                    </> : null
                                }
                                <div className="flex flex-row gap-x-2 w-full flex-wrap">
                                    <div className="mr-auto">
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting || !loaded || refreshing}
                                            onClick={() => {
                                                if(!firstConfirm) handleClose();
                                                else {
                                                    setFirstConfirm(false);
                                                    formik.setStatus('');
                                                }
                                            }}
                                        >
                                            {!firstConfirm ? 'Close' : 'Back'}
                                        </Button>
                                    </div>
                                    <Button
                                        color="hol-mpLBlue"
                                        onClick={refreshData}
                                        disabled={formik.isSubmitting || !loaded || refreshing}
                                    >
                                        {refreshing ? 
                                            (
                                                <ModalLoading/>
                                            )
                                            :
                                            <div>Refresh Data</div>
                                        }
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        disabled={formik.isSubmitting || !loaded || refreshing ||
                                            !Object.keys(formik.values.selectedAppointments).length ||
                                            !formik.values.selectedStudents.length}
                                        onClick={(e) => {
                                            if(!firstConfirm){
                                                setFirstConfirm(true);
                                                formik.setStatus('');
                                            }
                                            else formik.handleSubmit(e);
                                        }}
                                    >
                                        {!firstConfirm ? 'Next' : 'Submit'}
                                    </Button>
                                    {firstConfirm ? 
                                        <div className="text-mpLRed flex items-end ml-8">
                                            Submit? This cannot be undone.
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(BookingAssistantBodyFooter);