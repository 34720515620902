import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    student: Yup.mixed().test(
        'studentIsSelected',
        'Student is required',
        (value) => value.value !== -1
    ),
    weight: Yup.mixed().test(
        'weightIsSelected',
        'Weight is required',
        (value) => value.value !== -1
    ),
    center: Yup.mixed().test(
        'centerIsSelected',
        'Center is required',
        (value) => value.value !== -1
    ),
    time: Yup.mixed().test(
        'timeIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
    ).test(
        'isHalfHour',
        'Minutes must be either 00 or 30',
        (value) => value.raw % 30 === 0
    ),
    dow: Yup.mixed().test(
        'dayOfWeekIsSelected',
        'Day of Week is required',
        (value) => value.value !== -1
    ),
    duration: Yup.mixed().test(
        'durationIsSelected',
        'Duration is required',
        (value) => value.value !== -1
    ),
});

export const dowOptions = [{ value: 0, label: 'Sunday' }, { value: 1, label: 'Monday' }, { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' }, { value: 4, label: 'Thursday' }, { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },]
export function getInitDoWOption(dow){
    if(!dow && dow !== 0) return { value: -1, label: 'Please select...' };
    return dowOptions.find(s => s.value === dow) || { value: -1, label: `Unknown day of week (${dow})` };
}

export function getInitStudentOption(student, studentOptions){
    if(!student) return { value: -1, label: 'Please select...' };
    return studentOptions.find(s => s.value === student) || { value: -1, label: `Unknown student (${student})` };
}

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'Please select...' };
    return centerOptions.find(s => s.value === center) || { value: -1, label: `Unknown center (${center})` };
}

export const durationOptions = [30, 60, 90, 120].map(i => ({ value: i, label: `${i} minutes` }));
export function getInitDuratonOption(duration){
    if(!duration) return { value: -1, label: 'Please select...' };
    return durationOptions.find(s => s.value === duration) || { value: -1, label: `Unknown duration (${duration})` };
}

export const weightOptions = [1, 2, 3, 4].map(i => ({ value: i, label: i }));
export function getInitWeightOption(weight){
    if(!weight) return { value: -1, label: 'Please select...' };
    return weightOptions.find(s => s.value === weight) || { value: -1, label: `Unknown weight (${weight})` };
}