import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { getColumns } from './table/getColumns';

import {
    fetchMPLessonsSingleND
} from '../../../../actions-nd';

const defaultSorted = { dataField: 'combinedSortOrder', order: 'desc' };

function LearningPlanTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedLearningPlans, studentName } = props;

    const [loading, setLoading] = useState(false);

    const downloadFile = useCallback((fileInfo, isReview, appendLogo) => {
        const squareColor = { r: 0, g: 0, b: 0 };
        if(fileInfo.type === 'epLesson' || isReview){
            squareColor.g = 122;
            squareColor.b = 157;
        } else if(fileInfo.type === 'lesson'){
            squareColor.r = 215;
        }

        fileInfo.options = {
            studentName,
            appendName: studentName && appendLogo ? true : false,
            appendLogo,
            appendSquare: appendLogo,
            squareColor
        };
        (async function doDownload(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            await fetchMPLessonsSingleND(fileInfo);
            if(mounted.current) setLoading(false);
        })();
    }, [loading, setLoading, studentName]);
    const columns = useMemo(() => {
        return getColumns(downloadFile);
    }, [downloadFile]);
    const learningPlanItems = useMemo(() => {
        const lpItems = [];
        let lpIndex = 1;
        selectedLearningPlans.forEach(lp => {
            const items = lp.obj?.items || [];
            items.forEach(i => {
                i.combinedSortOrder = `${lpIndex}_${i.sort_order}`;
                lpItems.push(i);
            });
            lpIndex++;
        });
        return lpItems;
    },[selectedLearningPlans]);

    return (
        <div>
            <Table
                tableName="learningPlanItems"
                data={learningPlanItems}
                columns={columns}
                defaultSorted={defaultSorted}
                sizePerPageInitial={50}
                emptyMessage="No items were found"
            />
        </div>
    );
}

export default connect(null, {

})(LearningPlanTable);