import React, { useState } from "react";
import { connect } from 'react-redux';

import { SelectSingle } from '../../components/form';
import { BrowserTabTitle, VersionHistory } from '../../components/display';
import { Socket } from '../../components/ws';

const versionOptions = [
    { value: 'Admin 4.1', label: 'Admin 4.1'},
    { value: 'Admin 4.0', label: 'Admin 4.0'},
];

const pageTitle = 'Home';

function Home(props){
    const [viewVersion, setViewVersion] = useState(versionOptions[0]);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {/* <div className="row no-mar">
                <Card style={{ width: "32%", marginRight: "2%" }}>
                    <Card.Body>
                        <Card.Title>
                            <div className="row no-mar" styl={{ display: "flex" }}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Contracts with overdue payments.
                                        </Tooltip>
                                    }
                                >
                                    <img width="10%" src={toAbsoluteUrl('/media/svg/mathplex/money.svg')} alt="money.svg"/>
                                </OverlayTrigger>&ensp;
                                <h2 style={{ alignSelf: "center" }}>Overdue Payments</h2>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            A list of contracts with payment dates set to today.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{ width: "32%", marginRight: "2%" }}>
                    <Card.Body>
                        <Card.Title>
                            <div className="row no-mar" styl={{ display: "flex" }}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Contracts ending within the specified date range. Set a contract's "warn" property to false to prevent a contract from showing up here.
                                        </Tooltip>
                                    }
                                >
                                    <img width="10%" src={toAbsoluteUrl('/media/svg/mathplex/contract.svg')} alt="contract.svg"/>
                                </OverlayTrigger>&ensp;
                                <h2 style={{ alignSelf: "center" }}>Contract Watch</h2>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            A list of contracts with the warning property set to true that are ending within the next month. Also: active students without an active contract.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{ width: '32%' }}>
                    <Card.Body>
                        <Card.Title>
                            <div className="row no-mar" styl={{ display: "flex" }}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Flags that were created two or more days ago.
                                        </Tooltip>
                                    }
                                >
                                    <img width="10%" src={toAbsoluteUrl('/media/svg/mathplex/flag.svg')} alt="flag.svg"/>
                                </OverlayTrigger>&ensp;
                                <h2 style={{ alignSelf: "center" }}>Outstanding Flags</h2>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            A list of flags created two or more days ago.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            <br/> */}

            <div className="flex flex-row">
                <div className="card w-full">
                    <div className="flex flex-row w-full">
                        <h2>Version History</h2>

                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                            <div style={{ marginLeft: "2rem" }}>
                                <SelectSingle
                                    id="home-view-version"
                                    name="viewVersion"
                                    value={viewVersion}
                                    options={versionOptions}
                                    onChange={(e) => setViewVersion(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <br/>

                    <VersionHistory
                        version={viewVersion.value}
                    />
                </div>
            </div>

            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {

})(Home);