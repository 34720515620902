import React from 'react';

const versionHistory = {
    "Admin 4.1": (
        <>
            <h5>v4.1.0</h5>
            <h6>Release Date: 5/24/23</h6>
            <ul>
                <li>Scheduling: Added "all" centers view</li>
                <li>Members: Fixed weight icons not displaying properly in Students tab</li>
                <li>Members: Fixed disabled hold button not displaying properly</li>
                <li>LP Manager: Fixed default sort order not working</li>
                <li>Scheduling: Fixed errant "appointment scheduled outside of opening hours" messages</li>
                <li>Auto Scheduler: Inactive items will not cause appointments to be created, even if they are shown in the table</li>
                <li>Refactored some leftover elements from the v2/3 template</li>
                <li>Fixed table pagination issue causing items per page to not display properly when near the bottom of a component</li>
                <li>Fixed wrapping of Modal footers for smaller screens</li>
                <li>Reformatted all forms to display rows more consistently</li>
                <li>Instructor Dashboard added tab scrolling for when many tabs are present</li>
                <li>Fixed select menus cropping at the bottom of modals</li>
                <li>Login page will now submit form when enter key is pressed</li>
            </ul>
        </>
    ),
    "Admin 4.0": (
        <>
            <h5>v4.0.3</h5>
            <h6>Release Date: 5/1/23</h6>
            <ul>
                <li>Fixed issue with report generator's appointment dates displaying incorrectly.</li>
            </ul>

            <h5>v4.0.2</h5>
            <h6>Release Date: 4/3/23</h6>
            <ul>
                <li>Fixed Assessment Template buttons displaying incorrectly when disabled</li>
                <li>Custom Download modal changed title</li>
                <li>Availability Bulk Update adjusted form layout</li>
                <li>Assessment Templates fixed placeholder for # questions</li>
                <li>Assessments fixed incorrect date taken sorting</li>
                <li>Details form adjusted layout</li>
                <li>Added potential fix to Auto Schedule items not showing up</li>
                <li>Fixed auth issue causing users' RP permissions to be used instead of MP permissions</li>
            </ul>

            <h5>v4.0.1</h5>
            <h6>Release Date: 3/31/23</h6>
            <ul>
                <li>Fixed issue with Report Generator first line of notes being a bit too long</li>
            </ul>

            <h5>v4.0.0</h5>
            <h6>Release Date: 3/29/23</h6>
            <ul>
                <li>Major visual update. Many components have been rewritten, allowing for better control of site functionality.</li>
                <li>Auto Scheduler changed confirm text to be the week's start date rather than the user's name</li>
                <li>Auto Scheduler disabled add appointments button if no items are selected</li>
                <li>Auto Schedule Form fixed "Active" always defaulting to true</li>
                <li>Instructor Attendance Form fixed errant "selected date must be in the future" when the page was left on overnight, and the (new) current date was used on the form</li>
                <li>Changed "HideCM" (cancelled/missed) to Hide Cancelled</li>
                <li>Custom Download changed "Add Flag Page" default to true</li>
                <li>Custom Download fixed an issue with "Add Flag Page" causing some lessons to not be added to the PDF</li>
                <li>LP Form fixed issue with form not loading if a student had an assessment marked "Not Taken"</li>
                <li>Assessment Manager renamed "Date Updated" to "Created"</li>
                <li>Members, Leads Form: Fixed issue preventing new members/leads from being created</li>
                <li>Availability Bulk Update fixed DST jump compatibility issue that caused all availabilities to get shifted by a day</li>
                <li>Report Generator quick download set to off by default</li>
                <li>Auto Scheduler added 5th week, as long as Saturday of that week is less than one month in the future</li>
                <li>Daily Overview added current date</li>
                <li>Details Form fixed lessons not updating when being marked as "uncompleted"</li>
                <li>Custom Download added "Split In Fours" option, which will download a zip file containing multiple PDFs, with each PDF containing 4 pages</li>
                <li>Inactive employees will still show up on timesheets if they worked in the selected date range. Attendances can still be edited and deleted, but new attendances cannot be created</li>
            </ul>
        </>
    )
};

export function VersionHistory(props){
    return versionHistory[props.version] || <h4>Unknown version</h4>
}