import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import LessonsTable from './LessonsTable';
import { BrowserTabTitle, LoadingOverlay } from "../../../../components/display";
import { FormikControl, Check, checkResponses, SelectSingle } from "../../../../components/form";
import { LessonsModal } from '../../../../components/modal';
import { Socket } from '../../../../components/ws';

import { 
    fetchLessonsAll
} from '../../../../actions';
import { Wait } from "../../../../components/wait";

const pageTitle = 'Lesson Manager';

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'lessonID', label: 'Lesson ID' },
    { value: 'lessonIDBegins', label: 'Lesson ID Begins With' },
    { value: 'name', label: 'Lesson Name' }
];

function LessonManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [firstSearch, setFirstSearch] = useState(false);
    const [apiError, setApiError] = useState(false);

    const [lessons, setLessons] = useState([]);
    const [filteredLessons, setFilteredLessons] = useState([]);

    const [modalMode, setModalMode] = useState(null);

    const { fetchLessonsAll } = props;

    const filterData = useCallback((filterQuery, filterType, showRegular, excludeEP, showInactive, newLessons) => {
        if(showRegular && showRegular !== true && showRegular !== 'true') showRegular = false;
        if(excludeEP && excludeEP !== true && excludeEP !== 'true') excludeEP = false;
        if(showInactive && showInactive !== true && showInactive !== 'true') showInactive = false;
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        
        const checkID = (lesson) => lesson.lesson_id.toLowerCase().replace(/ /g, '').includes(formattedFQ);
        const checkIDBegins = (lesson) => {
            const regex = new RegExp(`^${formattedFQ}`);

            return lesson.lesson_id.toLowerCase().replace(/ /g, '').match(regex);
        }
        const checkName = (lesson) => lesson.name.toLowerCase().replace(/ /g, '').includes(formattedFQ);

        function runFilter(lessonList){
            return lessonList.filter(l => {
                if((!showRegular && !l.lesson_id.includes('EP')) ||
                    !(showInactive || parseInt(l.active) === 1) ||
                    (excludeEP && l.lesson_id.includes('EP'))) return false;
                switch(filterType.value){
                    case 'all':
                        return checkID(l) || checkName(l);
                    case 'lessonID':
                        return checkID(l);
                    case 'lessonIDBegins':
                        return checkIDBegins(l);
                    case 'name':
                        return checkName(l);
                    default:
                        return false;
                }
            });
        }

        const newFilteredLessons = runFilter(newLessons);

        if(mounted.current){
            setFilteredLessons(newFilteredLessons);
        }
    }, []);
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);

            const lessonsRes = await fetchLessonsAll();
            const isApiError = checkResponses(lessonsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newLessons = lessonsRes.data || [];

            if(mounted.current){
                setLessons(newLessons);
                filterData(formRef?.current?.values?.filterTerm, formRef?.current?.values?.filterType,
                    formRef?.current?.values?.showRegular, formRef?.current?.values?.excludeEP,
                    formRef?.current?.values?.showInactive, newLessons);
                setFirstSearch(true);

                await Wait(500);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, fetchLessonsAll, filterData]);
    useEffect(() => {
        (async function init(){
            await refreshData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            { modalMode === 'create' ? 
                <LessonsModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
                : null
            }
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        filterTerm: '',
                        filterType: filterTypeOptions[0],
                        showRegular: true,
                        showInactive: false,
                        excludeEP: false,
                        activeKey: 'lessons'
                    }}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            <>
                                <h2>Lessons ({filteredLessons.length})</h2>

                                <br/>
                                
                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <SelectSingle
                                            id="lesson-manager-filterType"
                                            name="filterType"
                                            label="Filter by"
                                            value={formik.values.filterType}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterData(formik.values.filterTerm, e.target.value,
                                                    formik.values.showRegular, formik.values.excludeEP,
                                                    formik.values.showInactive, lessons);
                                            }}
                                            options={filterTypeOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <FormikControl
                                            id="lesson-manager-filterTerm"
                                            name="filterTerm"
                                            placeholder="Search for lessons..."
                                            value={formik.values.filterTerm}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterData(e.target.value, formik.values.filterType,
                                                    formik.values.showRegular, formik.values.excludeEP,
                                                    formik.values.showInactive, lessons);
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Button
                                            color="lte-mpTeal"
                                            onClick={() => refreshData()}
                                        >
                                            Refresh
                                        </Button>
                                    </div>
                                    {['Root', 'Admin'].includes(props.auth.permissions) ? (
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => setModalMode('create')}
                                            >
                                                + Add Lesson
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Check
                                            id="lesson-manager-showRegular"
                                            name="showRegular"
                                            label="Show Regular Lessons"
                                            color="mpLRed"
                                            checked={formik.values.showRegular}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterData(formik.values.filterTerm, formik.values.filterType,
                                                    e.target.value, formik.values.excludeEP,
                                                    formik.values.showInactive, lessons);
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Check
                                            id="lesson-manager-hide-ep"
                                            name="excludeEP"
                                            label="Hide EP"
                                            color="mpLRed"
                                            checked={formik.values.excludeEP}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterData(formik.values.filterTerm, formik.values.filterType,
                                                    formik.values.showRegular, e.target.value,
                                                    formik.values.showInactive, lessons);
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Check
                                            id="lesson-manager-show-inactive"
                                            name="showInactive"
                                            label="Show Inactive"
                                            color="mpLRed"
                                            checked={formik.values.showInactive}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterData(formik.values.filterTerm, formik.values.filterType,
                                                    formik.values.showRegular, formik.values.excludeEP,
                                                    e.target.value, lessons);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                <>
                                    <hr/>

                                    { firstSearch &&
                                        <LessonsTable
                                            lessons={filteredLessons}
                                            permissions={props.auth.permissions}
                                            refreshData={refreshData}
                                        />
                                    }
                                </>
                            }
                        </>
                    )}
                </Formik>
            </div>
            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchLessonsAll
})(LessonManager)