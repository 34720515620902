import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

import NavBar from '../components/layout/navbar/_NavBar';
import Footer from '../components/layout/footer/_Footer';

import Home from './pages/_Home';

import Scheduling from './pages/appointments/scheduling/_Scheduling';
import AppointmentSearch from './pages/appointments/appointment-search/_AppointmentSearch';
import StudentAttendance from './pages/appointments/student-attendance/_StudentAttendance';
import SessionSummaries from './pages/appointments/session-summaries/_SessionSummaries';
import AutoScheduler from './pages/appointments/auto-scheduler/_AutoScheduler';
import SchedulerSettings from './pages/appointments/settings/_SchedulerSettings';

import UpcomingExams from './pages/students/upcoming-exams/_UpcomingExams';
import ReportGenerator from './pages/students/report-generator/_ReportGenerator';
import ContractsPayments from './pages/students/contracts-payments/_ContractsPayments';
import HoldsLogs from './pages/students/holds-logs/_HoldsLogs';
import StampsLogs from './pages/students/stamps-logs/_StampsLogs';
import Transitions from './pages/students/transitions/_Transitions';

import FlagManager from './pages/curriculum/flag-manager/_FlagManager';
import AssessmentManager from './pages/curriculum/assessment-manager/_AssessmentManager';
import LPManager from './pages/curriculum/lp-manager/_LPManager';
import LessonManager from './pages/curriculum/lesson-manager/_LessonManager.js';
import TemplateManager from './pages/curriculum/template-manager/_TemplateManager.js';

import Employees from './pages/accounts/employees/_Employees';
import Members from './pages/accounts/members/_Members';
// import Customers from './pages/accounts/customers/_Customers';

import TimesheetsA from './pages/admin/timesheets/_TimesheetsA';
import TimesheetsB from './pages/admin/timesheets/_TimesheetsB'; // Non-admin version
import InstructorDashboard from './pages/admin/instructor-dashboard/_InstructorDashboard';
import ACDReport from './pages/admin/acd-report/_ACDReport';
import DailyOverview from './pages/admin/daily-overview/_DailyOverview';
import TextEmailLogs from './pages/admin/text-email-logs/_TextEmailLogs';
// import SiteAnnouncements from './pages/admin/site-announcements/_SiteAnnouncements';

import Multiply from './pages/misc/Multiply';

import Logout from './pages/_auth/Logout';

// import AdminProfilePage from './pages/accounts/admin-user-profile/_AdminProfilePage';

import { NotFound, OutOfDate, Watermark } from '../components/display';

import { fetchServerTime, forceSignout } from '../actions';

// App > Routes > BasePage

const currentVersion = '4.1.0';

const cookies = new Cookies();

function BasePage(props){
    const { fetchServerTime, forceSignout} = props;

    const [permissions, setPermissions] = useState(props.auth.permissions);
 
   // This performs a simple server call (fetchServerTime) to force the browser to check the status of
   // the current token, and either do nothing (if aToken is valid), refersh it (if rToken is valid),
   // or sign the user out
    useEffect(() => {
        (async function getPermissions(){
            if(!props.auth?.sessionId) return; // User should be signed out in this case
        
            await fetchServerTime();
            // Get token and attempt verification
            const aToken = cookies.get('aToken');
        
            if(!props.auth?.sessionId) return; // User should be signed out in this case
            let permissions = null;
        
            try {
                const token = jwt_decode(aToken);
                permissions = token.mpPermissions;
            } catch(err) {
                console.error(err);
                forceSignout();
            } finally {
                setPermissions(permissions);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.auth.sessionId, fetchServerTime]);
    
    const [version, setVersion] = useState(currentVersion);

    const isRoot = permissions === 'Root';
    const isAdmin = permissions === 'Admin';
    const isLead = permissions === 'Lead Instructor';
    const isInstructor = permissions === 'Instructor';
    
    if(!permissions) return <Navigate replace={true} to="auth/login"/>;
    return (
        <div className="bg-mpLYellow-5">
            <div className="h-full min-h-screen">
                {version !== currentVersion ? <OutOfDate latest={version} current={currentVersion}/> : null}
                <NavBar permissions={permissions}/>
                <Watermark database={props.server.database}/>
                <Routes>

                    {['Root', 'Admin', 'Lead Instructor', 'Instructor'].includes(permissions) ? 
                        <>
                        <Route path="/" element={<Navigate replace={true} to="home"/>}/>
                        <Route path="home" element={<Home setVersion={setVersion}/>}/>

                        {/* <Route path="/user-profile" element={<AdminProfilePage setVersion={setVersion}/>} /> */}

                        {isInstructor || isLead || isAdmin || isRoot ? 
                          <>
                            <Route path="/appointments/scheduling" element={<Scheduling setVersion={setVersion}/>} />
                            <Route path="/appointments/appointment-search" element={<AppointmentSearch setVersion={setVersion}/>} />
                            <Route path="/appointments/student-attendance" element={<StudentAttendance setVersion={setVersion}/>} />
                            <Route path="/appointments/session-summaries" element={<SessionSummaries setVersion={setVersion}/>} />

                            <Route path="/curriculum/flag-manager" element={<FlagManager setVersion={setVersion}/>} />
                            <Route path="/students/upcoming-exams" element={<UpcomingExams setVersion={setVersion}/>} />
                            <Route path="/students/stamps-logs" element={<StampsLogs setVersion={setVersion}/>} />

                            <Route path="/admin/instructor-dashboard" element={<InstructorDashboard setVersion={setVersion}/>} />
                            <Route path="/admin/daily-overview" element={<DailyOverview setVersion={setVersion}/>} />
                            {isInstructor ? <Route path="/admin/timesheets" element={<TimesheetsB setVersion={setVersion}/>} /> :
                              isLead || isAdmin || isRoot ? <Route path="/admin/timesheets" element={<TimesheetsA setVersion={setVersion}/>} /> : null}
                          </> : null
                        }
                        {isLead || isAdmin || isRoot ? 
                          <>
                            <Route path="/appointments/auto-scheduler" element={<AutoScheduler setVersion={setVersion}/>} />

                            <Route path="/students/contracts-payments" element={<ContractsPayments setVersion={setVersion}/>} />
                            <Route path="/students/report-generator" element={<ReportGenerator setVersion={setVersion}/>} />
                            <Route path="/students/holds-logs" element={<HoldsLogs setVersion={setVersion}/>} />
                            <Route path="/students/transitions" element={<Transitions setVersion={setVersion}/>} />

                            <Route path="/curriculum/assessment-manager" element={<AssessmentManager setVersion={setVersion}/>} />
                            <Route path="/curriculum/lesson-manager" element={<LessonManager setVersion={setVersion}/>} />
                            <Route path="/curriculum/lp-manager" element={<LPManager setVersion={setVersion}/>} />

                            <Route path="/accounts/members" element={<Members setVersion={setVersion}/>} />

                            <Route path="/admin/text-email-logs" element={<TextEmailLogs setVersion={setVersion}/>} />
                            <Route path="/admin/acd-report" element={<ACDReport setVersion={setVersion}/>} />

                            <Route path="/misc/multiply" element={<Multiply setVersion={setVersion}/>} />
                            {/* <Route path="/misc/assessment" element={<Assessment setVersion={setVersion}/>} /> */}
                          </> : null
                        }
                        {isAdmin || isRoot ? 
                          <>
                            <Route path="/appointments/settings" element={<SchedulerSettings setVersion={setVersion}/>} />
                            
                            <Route path="/curriculum/assessment-template-manager" element={<TemplateManager setVersion={setVersion}/>} />

                            <Route path="/accounts/employees" element={<Employees setVersion={setVersion}/>} />
                            {/* <Route path="/accounts/customers" element={<Customers setVersion={setVersion}/>} /> */}

                            {/* <Route path="/admin/site-announcements" element={<SiteAnnouncements setVersion={setVersion}/>} /> */}
                          </> : null
                        }

                        {/* <Route path='/' element={<NotFound/>}/> */}

                        {/* {permissions === 'Root' ? <Route path="/root/action-tracker" element={<ActionTracker setVersion={setVersion}/>} />: null} */}
                        </> : null
                    }
                    <Route path="logout/*" element={<Logout/>}/> 
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </div>
            <Footer version={currentVersion}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        server: state.server
    }
}

export default connect(mapStateToProps, {
    fetchServerTime,
    forceSignout
})(BasePage);