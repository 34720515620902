import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, SelectMulti, FormikControl, Check } from '../../form';
import { renderChanges } from '../stamps/helpers';
import { TooltipWrapper } from '../../display';
import { validationSchema, renderRecommendedStamps, getSelectedInstructors,
    statusOptions, getInitSelectedStatus, getCenterOptions,
    getInitSelectedCenter, renderSubmitting, renderRelevantNotes,
    renderLPFields, renderPastFlags } from './helpers';
import { tagOptions as flagTags } from '../flags/helpers';
import { formatDateFull, formatTime } from '../../functions';

function DetailsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { loaded, attemptingClose, setAttemptingClose, centers, stampParams,
        instructorOptions, selectedAppointment, relInfo, learningPlanOptions, upcomingExamsItem,
        relevantAssignments, submitted, submissionStatus, handleClose, handleSubmit } = props;

    useEffect(() => {
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    instructors: getSelectedInstructors(relevantAssignments, instructorOptions),
                    specialNotesSchedule: selectedAppointment.special_notes_schedule,
                    nPages: selectedAppointment.n_pages,
                    nLessons: selectedAppointment.n_lessons,
                    learningPlanOptions, // Will be used in handleSubmit to check what has changed
                    selectedLearningPlan: learningPlanOptions[0] || { value: -1, label: 'No learning plans found', obj: {} },
                    hideCompleted: true,
                    stampsAmount: 0,
                    stampsGiven: parseInt(selectedAppointment.stamps_given) === 1 ? true : false,
                    stampsNotes: '',
                    hasExams: parseInt(upcomingExamsItem.has_exams) === 1 ? true : false,
                    upcomingExamsNotes: upcomingExamsItem.notes,
                    sessionNotes: selectedAppointment.session_notes,
                    hwNotes: selectedAppointment.hw_notes,
                    status: getInitSelectedStatus(selectedAppointment.status),
                    createFlag: false,
                    flagTag: { value: -1, label: 'Please select...' },
                    flagCenter: getInitSelectedCenter(selectedAppointment.center, centers),
                    flagCenterUnlock: false,
                    flagNotes: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? 
                        renderSubmitting(submissionStatus, formik.setSubmitting) :
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <Modal.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                        style={{ marginRight: "5px" }}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    ) :
                    <ModalBodyFooter>
                        <Modal.Body>

                            <h2>Scheduling</h2>

                            <br/>

                        {/* SECTION 1 */}
                            <div className="flex flex-row gap-x-4">
                            {/* S1 COLUMN 1 */}
                                <div className="w-1/2">
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <h4>Appointment Info</h4>
                                        <div className="ml-1 text-sm">
                                            <div><b>Student:</b> {selectedAppointment.studentName}</div>
                                            <div><b>Time:</b>&nbsp;
                                                {formatDateFull(selectedAppointment.date_time)}&nbsp;
                                                from {formatTime(selectedAppointment.startTime)}&nbsp;
                                                to {formatTime(parseInt(selectedAppointment.endTime))}
                                            </div>
                                            <div><b>Center:</b> {selectedAppointment.centerName}</div>
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>Status</h4>
                                            <SelectSingle
                                                id="details-status"
                                                name="status"
                                                onChange={formik.handleChange}
                                                value={formik.values.status}
                                                options={statusOptions}
                                            />
                                            {formik.errors.status && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.status}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            {/* END S1 COLUMN 1 */}
                            {/* S1 COLUMN 2 */}
                                <div className="w-1/2">
                                    <h4>Instructors</h4>
                                    <SelectMulti
                                        id="details-instructors"
                                        name="instructors"
                                        onChange={(e) => {
                                            if(e.target.value.length <= 5) formik.handleChange(e);
                                        }}
                                        value={formik.values.instructors}
                                        options={instructorOptions}
                                    />
                                    {formik.values.instructors.length >= 5 ? 
                                        <>
                                            <br/>
                                            <div className="text-mpOrange">
                                                Maximum 5 instructors
                                            </div>
                                        </> : null
                                    }

                                    <br/>

                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                This will update the scheduling notes for the selected appointment.
                                                The icon for scheduling notes is <i className="fas fa-exclamation text-mpGreen"/>
                                            </div>
                                        }
                                    >
                                        <h4 className="text-mpLBlue">Scheduling Notes</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="details-special-notes-schedule"
                                        as="textarea"
                                        name="specialNotesSchedule"
                                        onChange={formik.handleChange}
                                        value={formik.values.specialNotesSchedule}
                                        placeholder="This will update the scheduling notes (green !) for this appointment. Max 250 characters"
                                    />
                                    {formik.errors.specialNotesSchedule && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.specialNotesSchedule}
                                        </ErrorMessage>
                                    )}
                                </div>
                            {/* END S1 COLUMN 2 */}
                            </div>
                        {/* END SECTION 1 */}

                            <br/>
                            <hr/>
                            <br/>

                            <h2>Work Completed</h2>
                            <br/>

                        {/* SECTION 2 */}
                            <div className="flex flex-row gap-x-4">
                            {/* S2 COLUMN 1 */}
                                <div className="w-1/2">
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <h4>Active Learning Plans</h4>
                                            <SelectSingle
                                                id="details-selected-lp"
                                                name="selectedLearningPlan"
                                                onChange={formik.handleChange}
                                                value={formik.values.selectedLearningPlan}
                                                options={learningPlanOptions}
                                            />
                                            <div className="flex flex-row gap-x-4">
                                                <TooltipWrapper
                                                    tooltipText={"Hides lessons that were marked as completed when the form was opened."}
                                                >
                                                    <Check
                                                        id="details-hideCompleted"
                                                        name="hideCompleted"
                                                        color="mpLRed"
                                                        label={
                                                            <div className="text-mpLBlue">
                                                                Hide Completed
                                                            </div>
                                                        }
                                                        checked={formik.values.hideCompleted}
                                                        onChange={formik.handleChange}
                                                    />
                                                </TooltipWrapper>
                                            </div>
                                            {renderLPFields(formik)}
                                        </div>
                                    </div>
                                </div>
                            {/* END S2 COLUMN 1 */}
                            {/* S2 COLUMN 2 */}
                                <div className="w-1/2">
                                    <div className="flex flex-row gap-x-4 items-start">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h6># Pages Completed</h6>
                                            <FormikControl
                                                id="details-n-pages"
                                                name="nPages"
                                                onChange={formik.handleChange}
                                                value={formik.values.nPages}
                                                placeholder="Min 0, Max 50"
                                            />
                                            {formik.errors.nPages && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.nPages}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h6># Lessons Completed</h6>
                                            <FormikControl
                                                id="details-n-lessons"
                                                name="nLessons"
                                                onChange={formik.handleChange}
                                                value={formik.values.nLessons}
                                                placeholder="Min 0, Max 10"
                                            />
                                            {formik.errors.nLessons && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.nLessons}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="w-1/2">
                                            <TooltipWrapper
                                                tooltipText={
                                                    <>
                                                        <div>Heads up! This will give stamps on <u>each</u> form submission.</div>
                                                        <br/>
                                                        <div>If you are just updating other fields, leave this at 0.</div>
                                                    </>
                                                }
                                            >
                                                <h4 className="text-mpLBlue">Stamps To Give*</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="details-form-stamps"
                                                name="stampsAmount"
                                                value={formik.values.stampsAmount}
                                                onChange={formik.handleChange}
                                                placeholder="Min 0, Max 100"
                                            />
                                            {formik.errors.stampsAmount && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.stampsAmount}
                                                </ErrorMessage>
                                            )}
                                            <Check
                                                id="details-form-stamps-given-bool"
                                                name="stampsGiven"
                                                color="mpLRed"
                                                checked={formik.values.stampsGiven}
                                                onChange={formik.handleChange}
                                                label={<TooltipWrapper
                                                    tooltipText={
                                                        <>
                                                            <div>
                                                                Have stamps been given for this appointment?
                                                            </div>
                                                            <br/>
                                                            <div>
                                                                Will be marked as true if the form is submitted and "Stamps to Give" is not zero.
                                                            </div>
                                                                <br/>
                                                            <div>
                                                                When this is false, the stamps button appears purple on the
                                                                appointments table. When true, it appears light blue.
                                                            </div>
                                                        </>
                                                    }
                                                >
                                                    <div className="text-mpLBlue">Stamps Given?</div>
                                                </TooltipWrapper>}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            {renderRecommendedStamps(formik.values)}
                                        </div>
                                    </div>

                                    {parseInt(formik.values.nPages) + parseInt(formik.values.nLessons) !== 0 && 
                                        parseInt(formik.values.stampsAmount) === 0 &&
                                        !formik.values.stampsGiven ? (
                                            <div className="row no-mar">
                                                <div className="col text-mpOrange">
                                                    Warning! Student has completed work, but has not yet received stamps!
                                                    Are you sure "Stamps To Give" should be 0?
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    
                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <TooltipWrapper
                                                tooltipText="Notes will be added to the Stamps Log entry"
                                            >
                                                <h4 className="text-mpLBlue">Stamps Notes</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="stamps-notes"
                                                name="stampsNotes"
                                                value={formik.values.stampsNotes}
                                                onChange={formik.handleChange}
                                                placeholder="Max. 100 characters"
                                            />
                                            {formik.errors.stampsNotes && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.stampsNotes}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            {/* The arguments needed to be adjusted since this function is
                                                adopted from the StampsModal helpers */}
                                            {renderChanges({ object: selectedAppointment.studentObject },
                                                { value: 'Give Stamps' },
                                                formik.values.stampsAmount, !formik.errors.stampsAmount,
                                                stampParams)}
                                        </div>
                                    </div>
                                </div>
                            {/* END S2 COLUMN 2 */}
                            </div>
                        {/* END SECTION 2 */}

                            <br/>
                            <hr/>
                            <br/>

                            <h2>Notes</h2>
                            <br/>

                        {/* SECTION 3 */}
                            <div className="flex flex-row gap-x-4">
                            {/* S3 COLUMN 1 */}
                                <div className="w-1/2">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <TooltipWrapper
                                            tooltipText="Session notes can be viewed by the parent."
                                        >
                                            <h4 className="text-mpLBlue">Session Notes</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="details-session-notes"
                                            name="sessionNotes"
                                            onChange={formik.handleChange}
                                            value={formik.values.sessionNotes}
                                            as="textarea"
                                            placeholder="Notes to be shared with parents. Max 1,500 characters"
                                        />
                                        {formik.errors.sessionNotes && (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.sessionNotes}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <br/>

                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Homework Notes</h4>
                                        <FormikControl
                                            id="details-homework-notes"
                                            name="hwNotes"
                                            onChange={formik.handleChange}
                                            value={formik.values.hwNotes}
                                            as="textarea"
                                            placeholder="To be used if the student worked on homework during their session. Max 300 characters"
                                        />
                                        {formik.errors.hwNotes && (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.hwNotes}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <br/>

                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Upcoming Exams</h4>
                                        <div className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2">
                                                <Check
                                                    id="details-has-exams"
                                                    name="hasExams"
                                                    color="mpLRed"
                                                    label="Has Exams?"
                                                    checked={formik.values.hasExams}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                        {formik.values.hasExams && 
                                            <div className="flex flex-row gap-x-4">
                                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                                <FormikControl
                                                    id="details-upcoming-exams-notes"
                                                    name="upcomingExamsNotes"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.upcomingExamsNotes}
                                                    as="textarea"
                                                    placeholder="Any notes pertaining to a studen'ts upcoming exams (if applicable). Max 300 characters"
                                                />
                                                {formik.errors.upcomingExamsNotes && (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.upcomingExamsNotes}
                                                    </ErrorMessage>
                                                )}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <br/>
                                </div>
                            {/* END S3 COLUMN 1 */}
                            {/* S3 COLUMN 2 */}
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    {renderRelevantNotes(relInfo.combinedNotes)}
                                </div>
                            {/* END S3 COLUMN 3 */}
                            </div>
                        {/* END SECTION 3 */}

                            <br/>
                            <hr/>
                            <br/>
                        
                            <h2>Flags</h2>
                            <br/>
                        {/* SECTION 4 */}
                            <div className="flex flex-row gap-x-4">
                            {/* S4 COLUMN 1 */}
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <Check
                                            id="details-create-flag"
                                            name="createFlag"
                                            color="mpLRed"
                                            label="Create Flag"
                                            checked={formik.values.createFlag}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    {formik.values.createFlag && (
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <h4>Tag</h4>
                                            <SelectSingle
                                                id="details-flag-tag"
                                                name="flagTag"
                                                onChange={formik.handleChange}
                                                value={formik.values.flagTag}
                                                options={flagTags}
                                            />
                                            {formik.errors.flagTagValid && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagTagValid}
                                                </ErrorMessage>
                                            )}

                                            <br/>

                                            <h4>Center</h4>
                                            <SelectSingle
                                                id="details-flag-center"
                                                name="flagCenter"
                                                disabled={!formik.values.flagCenterUnlock}
                                                onChange={formik.handleChange}
                                                value={formik.values.flagCenter}
                                                options={getCenterOptions(centers)}
                                            />
                                            <div className="text-mpOrange">
                                                Center defaults to the center that this appointment was at.
                                            </div>
                                            {formik.errors.flagCenterValid && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagCenterValid}
                                                </ErrorMessage>
                                            )}
                                            <Check
                                                id="details-flag-center-unlocked"
                                                name="flagCenterUnlock"
                                                color="mpLRed"
                                                label="Change Center"
                                                checked={formik.values.flagCenterUnlock}
                                                onChange={formik.handleChange}
                                            />

                                            <br/>

                                            <h4>Flag Notes</h4>
                                            <FormikControl
                                                as="textarea"
                                                id="details-flag-notes"
                                                name="flagNotes"
                                                onChange={formik.handleChange}
                                                value={formik.values.flagNotes}
                                                placeholder="Extra comments about the flag in case the tag doesn't suffice. Max 200 characters"
                                            />
                                            {formik.errors.flagNotes && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagNotes}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    )}
                                </div>
                            {/* END S4 COLUMN 1 */}
                            {/* S4 COLUMN 2 */}
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    {formik.values.createFlag && renderPastFlags(relInfo.flags)}
                                </div>
                            {/* END S4 COLUMN 2 */}
                            </div>
                        {/* END SECTION 4 */}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={(e) => {
                                        formik.setFieldValue('status', { value: 'Completed', label: 'Completed' });
                                        formik.handleSubmit(e);
                                    }}
                                    disabled={formik.isSubmitting}
                                >
                                    Mark Completed &amp; Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(DetailsBodyFooter);