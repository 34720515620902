import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { SelectSingle, FormikControl, checkResponses } from '../../../../components/form';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import UpcomingExamsTable from './UpcomingExamsTable';
import { Socket } from '../../../../components/ws';

import { 
    fetchMpCentersAll,
    fetchStudentsActive,
    fetchAdminUsersAll,
    fetchUpcomingExamsAll,
} from '../../../../actions';

const pageTitle = 'Upcoming Exams';

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'studentName', label: 'Student Name' },
    { value: 'notes', label: 'Notes' },
    { value: 'updatedByName', label: 'Updated By' },
];

function UpcomingExams(props){
    const mounted = useRef();
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [employees, setEmployees] = useState([]);
    const [upcomingExams, setUpcomingExams] = useState([]);
    const [filteredUE, setFilteredUE] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const { fetchMpCentersAll, fetchStudentsActive, fetchAdminUsersAll, fetchUpcomingExamsAll } = props

    function filterUpcomingExams(unfilteredUpcomingExams, selectedCenter, filterType, filterQuery){
        filterQuery = filterQuery.toLowerCase();
        const checkCenter = (ue) => selectedCenter.value === 'all' || parseInt(ue.center) === parseInt(selectedCenter.value);
        const checkStudentName = (ue) => ue.studentName.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkNotes = (ue) => ue.notes.toLowerCase().includes(filterQuery);
        const checkUpdatedBy = (ue) => ue.updatedByName.toLowerCase().replace(/ /g, '').includes(filterQuery);

        const newUpcomingExams = unfilteredUpcomingExams.filter(ue => {
            if(!checkCenter(ue)) return false;
            switch(filterType.value){
                case 'all':
                    return checkStudentName(ue) || checkNotes(ue) || checkUpdatedBy(ue);
                case 'studentName':
                    return checkStudentName(ue);
                case 'notes': 
                    return checkNotes(ue);
                case 'updatedByName':
                    return checkUpdatedBy(ue);
                default:
                    return false;
            }
        });

        setFilteredUE(newUpcomingExams);
    };

    // INITIALIZE
    const refreshData = useCallback((init = false) => {
        (async function refresh(){
            if(loading || !formRef.current?.values) return;
            if(mounted.current) setLoading(true);

            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsActive();
            const employeesRes = await fetchAdminUsersAll();
            const upcomingExamsRes = await fetchUpcomingExamsAll();
            const isApiError = checkResponses(centersRes, studentsRes, employeesRes, upcomingExamsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            } else setApiError(false);

            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newEmployees = employeesRes.data || [];
            const newUpcomingExams = upcomingExamsRes.data || [];
    
            const newCenterOptions = [ { value: 'all', label: 'All' },
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name }))];
    
            // Map out centers for quick searching
            const centerNameMap = {};
            newCenters.forEach(c => centerNameMap[parseInt(c.id)] = c.name);
            // From student users object, figure out which primary center each student is assigned to
            // This will be attached to the students objects, for later transfer onto upcomingExams objects.
            const studentCenterMap = {};
            const doNotRenderList = [];
            newStudents.forEach(s => {
                studentCenterMap[s.user_id] = parseInt(s.mp_primary_center)
                if(parseInt(s.is_mp_student) !== 1 || parseInt(s.mp_active) !== 1) doNotRenderList.push(s.user_id);
            });
    
            // Append center data to student object.
            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            // Get employee names for quick searching
            const employeeMap = {};
            newEmployees.forEach(e => employeeMap[e.id] = `${e.first_name} ${e.last_name}`);
            // Append student names, centers, and employee names
            const upcomingExamsAppended = newUpcomingExams.filter(ue => {
                return !doNotRenderList.includes(ue.student);
            }).map(ue => {
                ue.studentName = studentMap[ue.student] || `Unknown student (UID: ${ue.student})`;
                ue.center = studentCenterMap[ue.student] || -1;
                ue.centerName = studentMap[ue.student] ? 
                    centerNameMap[studentCenterMap[ue.student]] || `Unknown center (ID: ${studentCenterMap[ue.student]})`
                    : `NA (no student)`;
                ue.updatedByName = ue.updated_by ? employeeMap[ue.updated_by] ||
                    `Unknown user (ID: ${ue.updated_by})`: 'No instructor found';
                return ue;
            });
    
            const { selectedCenter, filterType, filterQuery } = formRef.current.values;
    
            if(mounted.current){
                setCenterOptions(newCenterOptions);
                setUpcomingExams(upcomingExamsAppended);
                setEmployees(newEmployees);
                if(init) setFilteredUE(upcomingExamsAppended);
                else filterUpcomingExams(upcomingExamsAppended, selectedCenter, filterType, filterQuery);
                setHasLoaded(true);
                setLoading(false);
            }
        })();
    }, [mounted, loading, fetchMpCentersAll, fetchStudentsActive, fetchAdminUsersAll, fetchUpcomingExamsAll]);
    useEffect(() => {
        refreshData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        filterType: { value: 'all', label: 'All' },
                        filterQuery: '',
                    }}
                    innerRef={formRef}
                    onSubmit={filterUpcomingExams}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-row gap-x-4">
                                <h2>Upcoming Exams ({filteredUE.length})</h2>
                                <div className="flex items-end text-sm">
                                    (Active students only)
                                </div>
                            </div>

                            <br/>
                            
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                    <SelectSingle
                                        id="upcomingExams-searchCenter"
                                        name="selectedCenter"
                                        label="Center"
                                        value={formik.values.selectedCenter}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterUpcomingExams(upcomingExams, e.target.value,
                                                formik.values.filterType, formik.values.filterQuery);
                                        }}
                                        options={centerOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                    <SelectSingle
                                        id="upcomingExams-searchType"
                                        name="filterType"
                                        label="Filter by"
                                        value={formik.values.filterType}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterUpcomingExams(upcomingExams, formik.values.selectedCenter,
                                                e.target.value, formik.values.filterQuery);
                                        }}
                                        options={filterTypeOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3 items-end">
                                    <FormikControl
                                        id="upcomingExams-searchQuery"
                                        name="filterQuery"
                                        placeholder="Enter a filter query..."
                                        value={formik.values.filterQuery}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterUpcomingExams(upcomingExams, formik.values.selectedCenter, 
                                                formik.values.filterType, e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="flex items-end ml-auto">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All items that are currently filtered ({upcomingExams.length} items).
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Upcoming_Exams"
                                            label="Export Upcoming Exams to CSV"
                                            data={upcomingExams}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>

                <br/>

                {apiError ? <div className="text-mpLRed">{apiError}</div> :
                    hasLoaded &&
                    <UpcomingExamsTable
                        users={employees}
                        refreshData={refreshData}
                        upcomingExams={filteredUE}
                    />
                }
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsActive,
    fetchAdminUsersAll,
    fetchUpcomingExamsAll
})(UpcomingExams);