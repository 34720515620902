import React from 'react';

function ListGroup(props){
    const {
        children,
        key,
        assignFunction, // Should return a number that corresponds to a key in lgiClassNames
        repeatAfter,    // If provided, lgiClassNames will be used with keys 1 to n, and the items will repeat classNames
                        // every n items. Does not apply if assignFunction is provided
        lgClassName = '',
        lgiClassNames = {} // Should contain numerical keys that can be used to assign class names
    } = props;
    
    let isFirst = false; // To determine when to draw hr components
    let index = 0;
    return (
        <div className={`border border-mpLGrey-4 rounded-lg`}>
            {children.map(lgi => {
                if(!lgi) return null;
                let lgiClassName = '';
                if(assignFunction) lgiClassName = lgiClassNames[assignFunction(lgi)];
                else if(repeatAfter) lgiClassName = lgiClassNames[(index % repeatAfter) + 1] || '';
                return (
                    <div key={`${key}-lgi-base-${++index}`} className={lgClassName}>
                        <div className={`px-4 py-2 ${lgiClassName}`}>
                            {lgi}
                        </div>
                        {isFirst ? null : <hr className="text-mpLGrey-4"/>}
                    </div>
                );
            })}
        </div>
    );
}

export default ListGroup;