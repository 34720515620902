import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';

import { Modal, ModalBodyFooter, Button, ModalLoading } from '../../custom-essentials';
import { getSeriesOptions } from '../../../app/pages/appointments/scheduling/overview/appointments/getSeries';
import { formatDateFull, formatTime } from '../../functions';
import { TooltipWrapper } from '../../display';
import EditAvailability from './EditAvailability';
import BulkUpdate from './BulkUpdate';

import {
    fetchAvailabilityDateCenter
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function AvailabilityModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [relevantAvailability, setRelevantAvailability] = useState({});
    const [availabilityBlocks, setAvailabilityBlocks] = useState([]);

    const [showBulkUpdate, setShowBulkUpdate] = useState(false);
    const [showEditor, setShowEditor] = useState(false);

    const chartOptions = useMemo(() => {
        const appointments = [];
        const switches = {
            maxW: true,
            maxS: true,
            maxStartW: true,
            maxStartS: true,
        };
        const returnVals = getSeriesOptions(availabilityBlocks,
            appointments, switches);

        returnVals.options.title.text = '';

        return { series: returnVals.series, options: returnVals.options };
    }, [availabilityBlocks]);

    const { selectedDate, selectedCenter, onSubmitCallback, fetchAvailabilityDateCenter } = props;

    const refreshData = useCallback(() => {
        (async function refresh(){
            if(mounted.current) setLoading(true);
    
            const aaRes = await fetchAvailabilityDateCenter({
                date: selectedDate.api,
                center: selectedCenter.value
            });
            const newAvailability = aaRes.data?.availabilities?.[0] || {};
            const newAvailabilityBlocks = aaRes.data?.blocks || [];
            if(Object.keys(newAvailability).length) newAvailability.centerName = selectedCenter.label;
    
            if(mounted.current){
                setLoading(false);
                setRelevantAvailability(newAvailability);
                setAvailabilityBlocks(newAvailabilityBlocks);
            }
        })();
    }, [setLoading, selectedCenter, selectedDate, fetchAvailabilityDateCenter]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes = true) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);
    const secondaryOnSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setShowBulkUpdate(false);
            setShowEditor(false);
            if(changes) refreshData();
        }
    }, [setShowBulkUpdate, setShowEditor, refreshData]);

    if(showEditor){
        return(
            <EditAvailability
                mode={Object.keys(relevantAvailability).length ? 'edit' : 'create'}
                selectedDate={selectedDate}
                selectedCenter={selectedCenter}
                relevantAvailability={relevantAvailability}
                availabilityBlocks={availabilityBlocks}
                onSubmitCallback={secondaryOnSubmitCallback}
            />
        );
    } else if(showBulkUpdate){
        return(
            <BulkUpdate
                selectedCenter={selectedCenter}
                selectedDate={selectedDate}
                onSubmitCallback={secondaryOnSubmitCallback}
            />
        );
    } else {
        return (
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <h2>Availability Form</h2>
                </Modal.Header>
                {loading ?
                    <ModalBodyFooter>
                        <Modal.Body>
                            <ModalLoading/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color="lte-mpLRed"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </ModalBodyFooter>
                : 
                    
                    <ModalBodyFooter>
                        <Modal.Body
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            <h4>{selectedCenter.label}, {formatDateFull(selectedDate.date)}</h4>
                            
                            <br/>

                            { !Object.keys(relevantAvailability).length ? 
                                
                                <div className="text-mpLRed">
                                    No availability is currently set for this date.
                                </div>
                                :
                                parseInt(relevantAvailability.is_closed) === 1 ? 
                                
                                <h6>Center marked as closed.</h6>
                                : 

                                <>
                                    <h4>Availability by the Numbers</h4>
                                    <div className="h-2 clear-both"/>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">Time</div>
                                        <div className="grid grid-cols-1 gap-y-2 w-3/4">
                                            <div className="flex flex-row gap-x-2">
                                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                    <TooltipWrapper
                                                        tooltipText="Maximum Students"
                                                    >
                                                        <div className="text-mpLBlue">MaxS</div>
                                                    </TooltipWrapper>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                    <TooltipWrapper
                                                        tooltipText="Maximum Seats (Weight)"
                                                    >
                                                        <div className="text-mpLBlue">MaxW</div>
                                                    </TooltipWrapper>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                    <TooltipWrapper
                                                        tooltipText="Maximum Starting Students"
                                                    >
                                                        <div className="text-mpLBlue">MS (S)</div>
                                                    </TooltipWrapper>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                    <TooltipWrapper
                                                        tooltipText="Maximum Starting Seats (Weight)"
                                                    >
                                                        <div className="text-mpLBlue">MS (W)</div>
                                                    </TooltipWrapper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-4 clear-both"/>

                                    {availabilityBlocks.map(ab => {
                                        return parseInt(ab.is_closed) === 1 ?
                                        <div key={ab.id} className="text-mpOrange row-no-mar">
                                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                {formatTime(ab.time)}:
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                                Closed
                                            </div>
                                        </div>
                                        :
                                        <div key={ab.id} className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                {formatTime(ab.time)}:
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2 w-3/4">
                                                <div className="flex flex-row gap-x-2">
                                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                        {ab.students}
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                        {ab.seats}
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                        {ab.start_students}
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                                        {ab.start_seats}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}

                                    <div className="h-2 clear-both"/>
                                    <hr/>
                                    <div className="h-2 clear-both"/>

                                    <h4>Availability Visualizer</h4>
                                    <Chart series={chartOptions.series} options={chartOptions.options} type="line"/>
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 w-full">
                                <div className="mr-auto">
                                    <Button
                                        color="lte-mpLBlue"
                                        disabled={loading}
                                        onClick={() => setShowBulkUpdate(true)}
                                    >
                                        Bulk Update
                                    </Button>    
                                </div>
                                <Button
                                    color="lte-mpLRed"
                                    onClick={() => handleClose(true)}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    disabled={loading}
                                    onClick={() => setShowEditor(true)}
                                >
                                    {Object.keys(relevantAvailability).length ? 'Edit' : 'Create'} Availability
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                }
            </Modal>
        );
    }
}

export default connect(null, {
    fetchAvailabilityDateCenter
})(AvailabilityModal);