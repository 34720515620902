import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, SpinnerLoader } from '../../../../components/custom-essentials';
import { BrowserTabTitle } from '../../../../components/display';
import { SelectSingle, checkResponse, checkResponses } from '../../../../components/form';
import { formatTime } from '../../../../components/functions';
import { Socket } from '../../../../components/ws';

import {
    fetchSchedulerSettings,
    updateSchedulerSettings
} from '../../../../actions';

const pageTitle = 'Scheduler Settings';

const cancellationCutoffTimeOptions = [480, 510, 540, 570, 600, 630, 660, 690, 720, 750, 780, 810, 840].map(t => {
    return { value: t, label: formatTime(t) };
});
const schedulerCutoffTimeOptions = [480, 510, 540, 570, 600, 630, 660, 690, 720, 750, 780, 810, 840].map(t => {
    return { value: t, label: formatTime(t) };
});
const sendReminderTimeOptions = [1140, 1170, 1200, 1230, 1260, 1290, 1320, 1350].map(t => {
    return { value: t, label: formatTime(t) };
});
const defaultInstructorsOptions = [1, 2, 3, 4, 5, 6, 6, 7, 8, 9, 10].map(t => {
    return { value: t, label: t };
});
const defaultPiOptions = [1, 2, 3, 4, 5, 6].map(t => {
    return { value: t, label: t };
});

function SchedulerSettings(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [initialValues, setInitialValues] = useState({
        defaultInstructors: defaultInstructorsOptions[5],
        defaultSeatsPi: defaultPiOptions[4],
        defaultStudentsPi: defaultPiOptions[4],
        cancellationCutoff: cancellationCutoffTimeOptions[5],
        schedulerCutoff: schedulerCutoffTimeOptions[5],
        sendReminderTime: sendReminderTimeOptions[2],
    });

    const { fetchSchedulerSettings, updateSchedulerSettings } = props;

    const handleSubmit = useCallback((values, actions) => {
        (async function submit(){
            if(loading || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
            
            const {
                defaultInstructors,
                defaultSeatsPi,
                defaultStudentsPi,
                cancellationCutoff,
                schedulerCutoff,
                sendReminderTime,
            } = values;
            const { setSubmitting, setStatus } = actions;
            const settingsParams = {
                defaultInstructors: defaultInstructors.value,
                defaultSeatsPi: defaultSeatsPi.value,
                defaultStudentsPi: defaultStudentsPi.value,
                cancellationCutoff: cancellationCutoff.value,
                schedulerCutoff: schedulerCutoff.value,
                sendReminderTime: sendReminderTime.value,
            };

            const response = await updateSchedulerSettings(settingsParams);

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setApiError('Error fetching data from the server. Please try again later.');
                setLoading(false);

                setSubmitting(false);
                return;
            } else {
                setApiError(false);
                setStatus('');
            }
    
            if(mounted.current){
                setSuccess(true);
                setSubmitting(false);
                setLoading(false);
                setTimeout(() => setSuccess(false), 2500);
            }
        })()
    }, [mounted, loading, updateSchedulerSettings])

    useEffect(() => {
        async function init(){
            setLoading(true);
            const settingsRes = await fetchSchedulerSettings();
            const isApiError = checkResponses(settingsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                }
                return;
            }

            const newSettings = settingsRes.data?.[0] || {};

            const { default_instructors, default_seats_pi, default_students_pi, 
                send_reminder_time, cancellation_cutoff, scheduler_cutoff } = newSettings;
            const defaultInstructors = default_instructors ? 
                { value: parseInt(default_instructors), label: parseInt(default_instructors) } :
                defaultInstructorsOptions[1];
            const defaultSeatsPi = default_seats_pi ? 
                { value: parseInt(default_seats_pi), label: parseInt(default_seats_pi) } :
                defaultPiOptions[3];
            const defaultStudentsPi = default_students_pi ? 
                { value: parseInt(default_students_pi), label: parseInt(default_students_pi) } :
                defaultPiOptions[3];
            const cancellationCutoff = cancellation_cutoff ? 
                { value: parseInt(cancellation_cutoff), label: formatTime(cancellation_cutoff) } :
                cancellationCutoffTimeOptions[0];
            const schedulerCutoff = scheduler_cutoff ?
                { value: parseInt(scheduler_cutoff), label: formatTime(scheduler_cutoff) } :
                schedulerCutoffTimeOptions[0];
            const sendReminderTime = send_reminder_time ? 
                { value: parseInt(send_reminder_time), label: formatTime(send_reminder_time) } :
                sendReminderTimeOptions[0];

            const warning = !default_instructors || !default_seats_pi || !default_students_pi ||
                !send_reminder_time || !cancellation_cutoff || !scheduler_cutoff;

            const newInitialValues = {
                defaultInstructors,
                defaultSeatsPi,
                defaultStudentsPi,
                cancellationCutoff,
                schedulerCutoff,
                sendReminderTime,
            };

            if(mounted.current){
                setInitialValues(newInitialValues)
                if(warning) formRef.current.setStatus('There was an issue loading the scheduler settings.');
                setLoading(false);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            <h2>Capacity</h2>
                            <br/>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="aptSettings-1"
                                        name="defaultInstructors"
                                        label="Default Instructors"
                                        value={formik.values.defaultInstructors}
                                        onChange={formik.handleChange}
                                        options={defaultInstructorsOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="aptSettings-2"
                                        name="defaultSeatsPi"
                                        label="Default Seats Per Instructor"
                                        value={formik.values.defaultSeatsPi}
                                        onChange={formik.handleChange}
                                        options={defaultPiOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="aptSettings-3"
                                        name="defaultStudentsPi"
                                        label="Default Students Per Instructor"
                                        value={formik.values.defaultStudentsPi}
                                        onChange={formik.handleChange}
                                        options={defaultPiOptions}
                                    />
                                </div>
                            </div>

                            <br/>
                            <br/>

                            <h2>Recurring</h2>
                            <br/>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="aptSettings-5"
                                        name="cancellationCutoff"
                                        label="Cancellation Cutoff Time"
                                        value={formik.values.cancellationCutoff}
                                        onChange={formik.handleChange}
                                        options={cancellationCutoffTimeOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="aptSettings-6"
                                        name="schedulerCutoff"
                                        label="Scheduling Cutoff Time"
                                        value={formik.values.schedulerCutoff}
                                        onChange={formik.handleChange}
                                        options={schedulerCutoffTimeOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="aptSettings-4"
                                        name="sendReminderTime"
                                        label="Send Email Reminders At"
                                        value={formik.values.sendReminderTime}
                                        onChange={formik.handleChange}
                                        options={sendReminderTimeOptions}
                                    />
                                </div>
                            </div>

                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> : null}

                            <div className="flex flex-row gap-x-4">
                                {formik.isSubmitting &&
                                    <div>
                                        <SpinnerLoader/> Processing...
                                    </div>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                {success ? 
                                    <div className="text-mpGreen d-flex align-self-center">
                                        Successfully updated settings!
                                    </div> : null
                                }
                                <div className="ml-auto">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={formik.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>

            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchSchedulerSettings,
    updateSchedulerSettings
})(SchedulerSettings);