import React from 'react';
import * as Yup from 'yup';

import { Modal, ModalBodyFooter, Button, ListGroup, ModalProcessing } from '../../custom-essentials';
import { formatDate, getymd } from '../../functions';
import { renderSubmittingDefault } from '../../form';

export const validationSchema = Yup.object().shape({
    selectedStudents: Yup.mixed().test(
        'studentIsSelected',
        'At least one student must be selected',
        (value) => value.length
    ),
    checkTimesValid: Yup.mixed().when(['minimumTime', 'maximumTime'],
        ([minimumTime, maximumTime]) => {
            const isValid1 = !isNaN(minimumTime.raw) && !isNaN(maximumTime.raw);
            if(!isValid1) return Yup.mixed().test('checkTimesValid', `At least one time is invalid`, () => false);
            
            const isValid2 = (minimumTime.raw <= maximumTime.raw);
            if(!isValid2) return Yup.mixed().test('checkTimesValid', `Time in must come before time out`, () => false);
            
            return Yup.mixed().test('checkTimesValid', '', () => true);
        }
    ),
});

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'Please select...' };
    return centerOptions.find(s => s.value === center) || { value: -1, label: `Unknown center (${center})` };
}

export function getHoursInfo(selectedStudents, selectedAppointments){
    const formattedSelectedAppointments = Object.entries(selectedAppointments).map(([dateKey, option]) => {
        const date = new Date(dateKey.replace(/_/g, '/'));
        const duration = parseInt(option.value.split('.')[2]);
        return {
            date,
            duration
        };
    });

    const studentsInfo = [];
    let valid = true;
    selectedStudents.forEach(s => {
        const student = s.object;
        const sInfo = {};

        sInfo.userId = student.user_id;
        sInfo.name = `${student.first_name} ${student.last_name}`;
        sInfo.thisMonthMinutes = parseInt(student.mp_time_left_this_month);
        sInfo.nextMonthMinutes = parseInt(student.mp_time_left_next_month);
        sInfo.reserveMinutes = parseInt(student.mp_reserve_time_left);
        sInfo.nextMonthStart = new Date(student.mp_next_month_start);

        sInfo.thisMinutesWillDeduct = 0;
        sInfo.nextMinutesWillDeduct = 0;
        sInfo.reserveMinutesWillDeduct = 0;

        formattedSelectedAppointments.forEach(appointment => {
            const duration = appointment.duration;
            const dateTime = new Date(appointment.date_time);
            // appointment falls in the 'this month' category
            if(dateTime < sInfo.nextMonthStart){
                // enough minutes remain
                if(sInfo.thisMinutesWillDeduct + duration <= sInfo.thisMonthMinutes){
                    sInfo.thisMinutesWillDeduct += duration;
                // not enough minutes remain
                } else {
                    // Deduct as much as possible from the standard minutes and the remaidner from reserve
                    const diff = sInfo.thisMonthMinutes - sInfo.thisMinutesWillDeduct;
                    sInfo.thisMinutesWillDeduct += diff;
                    sInfo.reserveMinutesWillDeduct += (duration - diff);
                }
            // appointment falls in the 'next month' category
            } else {
                // enough minutes remain
                if(sInfo.nextMinutesWillDeduct + duration <= sInfo.nextMonthMinutes){
                    sInfo.nextMinutesWillDeduct += duration;
                // not enough minutes remain
                } else {
                    // Deduct as much as possible from the standard minutes and the remaidner from reserve
                    const diff = sInfo.nextMonthMinutes - sInfo.nextMinutesWillDeduct;
                    sInfo.nextMinutesWillDeduct += diff;
                    sInfo.reserveMinutesWillDeduct += (duration - diff);
                }
            }
        });

        sInfo.valid = sInfo.reserveMinutes >= sInfo.reserveMinutesWillDeduct;
        if(!sInfo.valid) valid = false;

        studentsInfo.push(sInfo);
    });

    return [studentsInfo, valid];
}

export function renderStudentHours(selectedStudents, selectedAppointments){
    const [studentsInfo] = getHoursInfo(selectedStudents, selectedAppointments);

    return (
        <ListGroup>
            {studentsInfo.map(s => {
                const validClassName = s.valid ? '' : 'text-mpLRed'
                return(
                    <div key={`booking-assistant-selected-students-${s.userId}`}>
                        <h4><u>{s.name}</u></h4>

                        <br/>
                    
                        <div>Hours remaining:</div>
                        <div className="grid grid-cols-1">
                            <div>This Month: {s.thisMonthMinutes / 60}</div>
                            <div>Next Month: {s.nextMonthMinutes / 60}</div>
                            <div className={validClassName}>Reserve Hours: {s.reserveMinutes / 60}</div>
                        </div>

                        <div className="h-4 clear-both"/>

                        <div>Next Month Starts On: {formatDate(s.nextMonthStart)}</div>

                        <div className="h-4 clear-both"/>

                        <div>Hours to be deducted:</div>
                        <div className="grid grid-cols-1">
                            <div>This Month: {s.thisMinutesWillDeduct / 60}</div>
                            <div>Next Month: {s.nextMinutesWillDeduct / 60}</div>
                            <div className={validClassName}>Reserve: {s.reserveMinutesWillDeduct / 60}</div>
                        </div>

                        {s.valid ? null : 
                            <>
                                <br/>
                                <div className="text-mpLRed">
                                    This student does not have enough hours left!
                                </div>
                            </>
                        }
                    </div>
                );
            })}
        </ListGroup>
    )
}

export function renderSubmitting(submissionStatus, setSubmitting, setFirstConfirm){
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="col">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>
                
                <div className="col">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => {
                        setFirstConfirm(false);
                        setSubmitting(false)
                    }}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}

export function getAdditionalInitialValues(today, selectedCenter){
    const initialValues = {};

    const initialTime = { value: -1, label: 'Please select...' };

    const newToday = new Date(today);
    for(let i = 0; i < 28; i ++){
        const [year, month, date] = getymd(newToday);
        const dateKey = `${year}_${month}_${date}`;
        const centerKey = `${dateKey}_center`;
        const timeKey = `${dateKey}_time_duration`;
        initialValues[centerKey] = selectedCenter;
        initialValues[timeKey] = initialTime;

        newToday.setDate(newToday.getDate() + 1);
    }

    return initialValues;
}

// Resets lowerHalf values when the student list or selected center is changed
export function onChangeResetValues(today, setValues, currentValues, changes = []){
    const newValues = {};
    const defaultTime = { value: -1, label: 'Please select...' };

    // Append value of the changes that called this function
    const newFormikValues = { ...currentValues };
    if(changes){
        changes.forEach(event => {
            newFormikValues[event.target.name] = event.target.value
        });
    }

    // Gather keys and new values
    const newToday = new Date(today);
    for(let i = 0; i < 28; i ++){
        const [year, month, date] = getymd(newToday);
        const dateKey = `${year}_${month}_${date}`;
        const centerKey = `${dateKey}_center`;
        const timeKey = `${dateKey}_time_duration`;
        newValues[centerKey] = newFormikValues.selectedCenter;
        newValues[timeKey] = defaultTime;

        newToday.setDate(newToday.getDate() + 1);
    }

    // Update values object with new values, then set values
    Object.entries(newValues).forEach(([formikFieldKey, value]) => newFormikValues[formikFieldKey] = value);

    setValues(newFormikValues);
}