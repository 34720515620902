import React, { useState, useMemo, useEffect, useRef } from "react";

import { Table } from '../../../../../components/table';
import { getStudentColumns } from '../table/getColumns';
import { SVGIcon } from '../../../../../components/custom-essentials';
import { StudentModal, PlaceHoldModal } from '../../../../../components/modal';
import { TooltipWrapper } from "../../../../../components/display";

function getAppended(students){
    return students.map(student => {
        // APPEND ICONS
        let weightIcon = null;
        let isSwitch = null;
    
        if(student.mp_weight > 3){
            weightIcon = (
                <div className="flex flex-row mr-1">
                    <SVGIcon fileName="weight-red"/>
                    <SVGIcon fileName="weight-red"/>
                    <SVGIcon fileName="weight-red"/>
                    <SVGIcon fileName="weight-red"/>
                </div>
            );
        } else if(student.mp_weight > 2){
            weightIcon = (
                <div className="flex flex-row mr-1">
                    <SVGIcon fileName="weight-red"/>
                    <SVGIcon fileName="weight-red"/>
                    <SVGIcon fileName="weight-red"/>
                </div>
            );
        } else if(student.mp_weight > 1){
            weightIcon = (
                <div className="flex flex-row mr-1">
                    <SVGIcon fileName="weight-red"/>
                    <SVGIcon fileName="weight-red"/>
                </div>
            );
        }
        else weightIcon = <div className="mr-1"><SVGIcon fileName="weight-green"/></div>;
        weightIcon = weightIcon ? (
            <TooltipWrapper
                tooltipText={`Weight: ${student.mp_weight}`}
            >
                {weightIcon}
            </TooltipWrapper>
        ) : null;
    
        if(student.mp_is_switch){
            isSwitch = (
                <TooltipWrapper
                    tooltipText="Switch Student"
                >
                    <div className="mr-1">
                        <SVGIcon fileName="sync-blue"/>
                    </div>
                </TooltipWrapper>
            );            
        }
        if(weightIcon === null && isSwitch === null) student.alerts = 'None';
        else {
            student.alerts = (
                <div className="flex flex-row gap-x-2">
                    {weightIcon} {isSwitch}
                </div>
            );
        }

        return student;
    });
}

const studentsDefaultSorted = { dataField: 'name', order: 'desc' };

export default function StudentTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { studentData, refreshData } = props;

    const studentDataAppended = useMemo(() => {
        return getAppended(studentData);
    }, [studentData]);

    // MODAL
    const [showModal, setShowModal] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    function handleShowStudentModal(student){
        setShowModal('student');
        setSelectedStudent(student);
    }
    function handleShowHoldsModal(student){
        setShowModal('holds');
        setSelectedStudent(student);
    }
    
    // END MODAL
    const studentColumns = getStudentColumns(handleShowStudentModal, handleShowHoldsModal);
    function onSubmitCallback(changes = true){
        if(changes) refreshData();
        setShowModal(null);
        setSelectedStudent(null);
    }

    return(
        <>
            {showModal === 'student' ?
                <StudentModal
                    selectedStudent={selectedStudent}
                    onSubmitCallback={onSubmitCallback}
                /> : null
            }
            {showModal === 'holds' ?
                <PlaceHoldModal
                    selectedStudent={selectedStudent}
                    onSubmitCallback={onSubmitCallback}
                /> : null
            }
            <Table
                tableName="students"
                data={studentDataAppended}
                columns={studentColumns}
                defaultSorted={studentsDefaultSorted}
                sizePerPageInitial={20}
                sizePerPageList={[10, 20, 40]}
                emptyMessage="No students were found"
            />
        </>
    );
}