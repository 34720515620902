import React from 'react';

import { SelectSingle } from '../../../../../components/form';
import { formatDateApi, getPayPeriod } from '../../../../../components/functions';

const payPeriodOptions = ['Previous Pay Period', 'Current Pay Period']
    .map(i => ({ value: i, label: i }));

async function handleChange(formik, refreshData, setValid, e){
    const selectedPeriod = e.target.value.value;

    const today = new Date();
    let startDate, endDate = null;

    const newValues = { ...formik.values };

    if(selectedPeriod === payPeriodOptions[0].value){
        // Previous Pay Period
        const previous = new Date();
        if(today.getDate() < 16){
            previous.setMonth(previous.getMonth() - 1);
            previous.setDate(16);
        } else {
            previous.setDate(1);
        }
        [startDate, endDate] = getPayPeriod(previous);
    } else if(selectedPeriod === payPeriodOptions[1].value){
        // Current Pay Period
        [startDate, endDate] = getPayPeriod(today);
    }

    const startApi = formatDateApi(startDate);
    const endApi = formatDateApi(endDate);

    const datesChanged = startApi !== formik.values.startDate || endApi !== formik.values.endDate;
    if(datesChanged){
        newValues.startDate = startApi;
        newValues.endDate = endApi;
    }

    newValues.payPeriod = e.target.value;
    await formik.setValues(newValues);
    await setValid(true);

    if(datesChanged){
        await refreshData();
    }
}

export function PayPeriodSelector(props){
    const { formik, refreshData, setValid, id } = props;
    return (
        <SelectSingle
            id={id}
            name="payPeriod"
            options={payPeriodOptions}
            onChange={async (e) => await handleChange(formik, refreshData, setValid, e)}
            value={formik.values.payPeriod}
        />
    );
};