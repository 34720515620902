import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    date: Yup.mixed().test(
        'dateIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
    contract: Yup.mixed().test(
        'contractIsSelected',
        'Please select a valid contract',
        (value) => value.value !== -1
    ),
    method: Yup.mixed().test(
        'methodIsSelected',
        'Please select a valid method',
        (value) => value.value !== -1
    ),
    amount: Yup.number()
        .typeError('Value must be a number')
        .min(-30000, 'Min -30000')
        .max(30000, 'Max 30000')
        .integer('Whole Number Required')
        .required('Amount is required'),
    notes: Yup.string()
        .max(500, 'Max 500 characters'),
});

export const defaultContractOption = { value: -1, label: 'Please select... (search to view more items)' };

export const methodOptions = ['Credit', 'Venmo', 'Zelle', 'Other'].map(i => {
    return ({ value: i, label: i });
});
export function getInitMethodOption(type){
    if(!type) return { value: -1, label: 'Please select...' };
    return methodOptions.find(t => t.value === type) || { value: -1, label: `Unknown type (${type})` };
}
