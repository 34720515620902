import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { AssessmentTemplateModal } from '../../../../components/modal';
import { getColumns } from './table/getColumns';

import {
    fetchMPLessonsSingleND
} from '../../../../actions-nd';

const defaultSorted = { dataField: 'level', order: 'desc' };

function TemplatesTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { assessmentTemplates, refreshData, searchTerm } = props;

    const [loading, setLoading] = useState(false);
    const [modalMode, setModalMode] = useState(null);
    const [selectedAssessmentTemplate, setSelectedAssessmentTemplate] = useState(null);

    const handleShowModal = useCallback((mode, assessmentTemplate) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedAssessmentTemplate(assessmentTemplate);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedAssessmentTemplate(null);
            if(changes) refreshData(searchTerm);
        }
    }, [mounted, refreshData, searchTerm]);
    const downloadFile = useCallback((fileInfo, fileType, appendLogo) => {
        const options = {
            appendName: false,
            appendLogo,
            appendSquare: false
        };
        fileInfo.type = fileType;
        fileInfo.options = options;
        (async function doDownload(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            await fetchMPLessonsSingleND(fileInfo);
            if(mounted.current) setLoading(false);
        })();
    }, [loading, setLoading])

    const columns = useMemo(() => {
        return getColumns(handleShowModal, downloadFile);
    }, [handleShowModal, downloadFile]);

    return (
        <div>
            {modalMode &&
                <AssessmentTemplateModal
                    mode={modalMode}
                    selectedAssessmentTemplate={selectedAssessmentTemplate}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="assessmentTemplates"
                data={assessmentTemplates}
                columns={columns}
                defaultSorted={defaultSorted}
                sizePerPageInitial={50}
                emptyMessage="No assessment templates were found"
            />
        </div>
    );
}

export default connect(null, {

})(TemplatesTable);