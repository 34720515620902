import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import { Formik } from 'formik';

import { Switch } from '../../../../../../components/form';
import { getInitialValues, colorOptions } from './helpers';

import { getSeriesOptions } from './getSeries';

const lsSwitches = JSON.parse(localStorage.getItem('admin_scheduling_overview_instructors_switches'));
const defaultSwitches = ['null', null].includes(lsSwitches) ? {} : lsSwitches;

function OverviewInstructors(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { show, assignmentMap } = props;
    
    const [switchesInstructors, setSwitchesInstructors] = useState(getInitialValues(defaultSwitches, assignmentMap));
    
    const chartOptions = useMemo(() => {
        return getSeriesOptions(assignmentMap, switchesInstructors);
    }, [assignmentMap, switchesInstructors]);
    
    const handleSwitchesInstructors = useCallback((formik, event) => {
        let newSwitchesInstructors = {...switchesInstructors};
        
        const { name, value } = event.target;
        const trueValue = [true, 'true'].includes(value);

        newSwitchesInstructors[name] = trueValue;

        localStorage.setItem('admin_scheduling_overview_instructors_switches', JSON.stringify(newSwitchesInstructors));

        if(mounted.current){
            setSwitchesInstructors(newSwitchesInstructors);
            formik.setValues(newSwitchesInstructors);
        }
    }, [mounted, switchesInstructors]);

    if(show && !Object.keys(assignmentMap).length) return <h4 className="col text-mpOrange">No assignments have been made yet</h4>;

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={getInitialValues(defaultSwitches, assignmentMap)}
            >
                
                {formik => (
                    <>
                        {show ? 
                            <>
                                <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                    <Chart series={chartOptions.series} options={chartOptions.options} type="line"/>
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                    <div className="col">
                                        <h4>Instructors:</h4>
                                        {Object.values(assignmentMap).map(instructorSet => {
                                            const { id, name, colorIndex } = instructorSet;
                                            return (
                                                <Switch
                                                    name={id}
                                                    key={id}
                                                    variant={colorOptions[colorIndex % colorOptions.length]}
                                                    checked={formik.values[id]}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        handleSwitchesInstructors(formik, e);
                                                    }}
                                                    textPosition="after"
                                                    label={`${name}`}
                                                />
                                            )
                                        })}

                                        <br/>

                                        <h4>Seats/Weights:</h4>
                                        <Switch
                                            name="weights"
                                            variant="mpDGrey"
                                            checked={formik.values.weights}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesInstructors(formik, e);
                                            }}
                                            textPosition="after"
                                            label={formik.values.weights ? 'Weights' : 'Seats'}
                                        />
                                    </div>
                                </div>
                            </> : null
                        }
                    </>
                )}
            </Formik>
        </>
    );
}

export default connect(null, {

})(OverviewInstructors);