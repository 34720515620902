import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, SelectSingle, FormikControl, Switch, checkResponses, Check } from '../../../../components/form';
import { formatDateApi, convertApiToDate, getDateObject } from '../../../../components/functions';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import SummariesTable from './SummariesTable';
import { Socket } from '../../../../components/ws';

import {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAdminUsersAll,
    fetchMembersAll,
    fetchAppointmentsDaterangeCenterStudentName,
} from '../../../../actions';

const pageTitle = 'Session Summaries';

const start = new Date();
start.setDate(start.getDate() - 7);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function SessionSummaries(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    const formRef2 = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Form
    const [drsValid, setDrsValid] = useState(true);
    const [switches, setSwitches] = useState({
        showAll: true,
        showWorkCompleted: false,
        showInstructors: false,
        showNotes: false,
        showHomeworkNotes: false,
        showStatus: false,
    });
    // Data
    const [students, setStudents] = useState([]);
    const [members, setMembers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [centers, setCenters] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);

    const { fetchMpCentersAll, fetchStudentsAll, fetchMembersAll, fetchAdminUsersAll,
        fetchAppointmentsDaterangeCenterStudentName } = props;

    const filterAppointments = useCallback((newAppointments = appointments, hideCancelled = true) => {
        let newFilteredAppointments = [];
        if(hideCancelled === false) newFilteredAppointments = [...newAppointments];
        else newFilteredAppointments = newAppointments.filter(a => !['Cancelled'].includes(a.status));

        if(mounted.current) setFilteredAppointments(newFilteredAppointments);
    }, [appointments]);
    const refreshData = useCallback(() => {
        (async function refreshData(){
            if(loading || !drsValid || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate, selectedCenter, searchQuery, hideCancelled } = formRef.current.values;
        
            const newStartDate = getDateObject(convertApiToDate(startDate));
            const newEndDate = getDateObject(convertApiToDate(endDate));
    
            const aaRes = await fetchAppointmentsDaterangeCenterStudentName({
                startDate: newStartDate.raw,
                endDate: newEndDate.raw,
                center: selectedCenter.value,
                searchQuery
            });
            const isApiError = checkResponses(aaRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointments = aaRes.data?.appointments || [];
            const newInstructorAssignments = aaRes.data?.assignments || [];
    
            const appointmentToIaMap = {};
            newInstructorAssignments.forEach(ia => {
                if(!appointmentToIaMap[parseInt(ia.appointment_id)]) appointmentToIaMap[parseInt(ia.appointment_id)] = [];
                appointmentToIaMap[parseInt(ia.appointment_id)].push(ia.instructor);
            });
    
            // Add this to the 'appendedAppointments' section below
            const studentToNameMap = {};
            students.forEach(s => studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const userToNameMap = {};
            employees.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            members.forEach(m => userToNameMap[m.id] = `${m.first_name} ${m.last_name}`);
    
            newAppointments.forEach(a => {
                const aptDateTime = new Date(a.date_time);
                a.time = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
                
                a.studentName = studentToNameMap[a.student] || `Unknown student (UID: ${a.student})`;
    
                const assignmentList = appointmentToIaMap[parseInt(a.id)] || [];
                if(!assignmentList.length) a.instructorNames = 'None';
                else {
                    let names = '';
                    assignmentList.forEach(a => {
                        if(names.length) names += ', ';
                        const newName = userToNameMap[a];
                        names += newName || '??';
                    });
                    a.instructorNames = names;
                }

                a.createdByName = userToNameMap[a.created_by] || `Unknown user (UUID: ${a.created_by})`;
                a.updatedByName = userToNameMap[a.updated_by] || `Unknown user (UUID: ${a.updated_by})`;
            });
    
            const centerMap = {};
            centers.forEach(c => centerMap[parseInt(c.id)] = c.name);
            const appendedAppointments = newAppointments.map(a => {
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (${a.center})`;
                return a;
            });
            
            if(mounted.current){
                setAppointments(appendedAppointments);
                filterAppointments(newAppointments, hideCancelled);
                setLoading(false);
            }
        })();
    }, [mounted, loading, centers, members, employees, students, drsValid,
        filterAppointments, fetchAppointmentsDaterangeCenterStudentName]);
    useEffect(() => {
        async function init(){
            if(mounted.current) setLoading(true);

            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const membersRes = await fetchMembersAll();
            const instructorsRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(centersRes, studentsRes, membersRes, instructorsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newMembers = membersRes.data || [];
            const newEmployees = instructorsRes.data || [];

            const newCenterOptions = [
                { value: 'all', label: 'All'},
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name })) 
            ];            

            if(mounted.current){
                await setLoading(false);

                await setCenters(newCenters);
                await setCenterOptions(newCenterOptions);
                await setStudents(newStudents);
                await setMembers(newMembers);
                await setEmployees(newEmployees);
            
                setHasLoaded(true);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSwitches = useCallback((e, formikRef) => {
        const { name, value } = e.target;
        const { values } = formikRef;
        const switchKeys = ['showAll', 'showWorkCompleted', 'showInstructors', 'showSessionNotes',
            'showHomeworkNotes', 'showStatus'];
        
        if(mounted.current){
            const boolValue = value === 'false' || value === false ? false : true;
            if(name === 'showAll'){
                // Toggle other properties on or off
                if(boolValue === true){
                    switchKeys.forEach(k => values[k] = false);
                    values.showAll = true;
                } else {
                    values.showAll = false;

                    let index = 0;
                    switchKeys.forEach(key => {
                        if(key === 'showAll' || index >= 3) return;
                        values[key] = true;
                        index++;
                    });
                }
            } else {
                values.showAll = false;
                
                const newValue = value === 'false' || value === false ? false : true;

                // Maximum of 5 columns selected
                let columnsSelected = 0;
                switchKeys.forEach(v => {
                    if(values[v] & values[v] !== 'false') columnsSelected++
                });

                // Unchecking the final switch causes 'show all' to be checked
                const isUncheckingFinal = !newValue && columnsSelected === 1;
                if(isUncheckingFinal){
                    values.showAll = true;
                    values[name] = false;
                } else if(columnsSelected < 5 || newValue === false){
                    values[name] = newValue;
                }
            }

            formikRef.setValues(values);
            setSwitches({...values});
        }

    }, [mounted]);

    // Add student contact and account info to a tooltip.
    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <h2>Session Summaries ({filteredAppointments.length})</h2>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        searchQuery: '',
                        hideCancelled: true
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div>
                                    <DateRangeSelector
                                        id="advancedSearch-drs-1"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <SelectSingle
                                        id="advancedSearch-center"
                                        label="Center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <FormikControl
                                        id="advancedSearch-query"
                                        name="searchQuery"
                                        placeholder="Search by student name..."
                                        value={formik.values.searchQuery}
                                        onChange={formik.handleChange}
                                        shouldHandleSubmit={true}
                                        onSubmit={formik.handleSubmit}
                                    />
                                </div>
                                <div className="flex items-end">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                                <div className="flex items-end ml-auto">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All appointments that have been filtered ({filteredAppointments.length} items).
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Appointments"
                                            label="Export Appointments to CSV"
                                            data={filteredAppointments}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>

                            <br/>

                            <div className="row  no-mar">
                                <div className="col-">
                                    <Check
                                        id="appointmentSearch-hideCancelled"
                                        name="hideCancelled"
                                        label="Hide Cancelled"
                                        color="mpLRed"
                                        checked={formik.values.hideCancelled}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterAppointments(appointments, e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>

                {
                    appointments.length >= 2000 ? 
                    <>
                        <br/>
                        <div className="text-mpLRed">Only the first 2000 results were returned.</div>
                    </>
                    : null
                }
                
                <br/>

                { apiError ? <div className="text-mpLRed">{apiError}</div> :
                    hasLoaded && 
                    <>
                        <Formik
                            enableReinitialize={!hasLoaded}
                            initialValues={{
                                showAll: true,
                                showWorkCompleted: false,
                                showInstructors: false,
                                showSessionNotes: false,
                                showHomeworkNotes: false,
                                showStatus: false,
                            }}
                            innerRef={formRef2}
                            onSubmit={() => null}
                        >
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <h4>View Columns:</h4>
                                    <h6>(Max. 5)</h6>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Switch
                                                name="showAll"
                                                color="mpTeal"
                                                onChange={(e) => handleSwitches(e, formik)}
                                                checked={formik.values.showAll}
                                                textPosition="after"
                                                label="Show All"
                                            />
                                            <Switch
                                                name="showWorkCompleted"
                                                color="mpTeal"
                                                onChange={(e) => handleSwitches(e, formik)}
                                                checked={formik.values.showWorkCompleted}
                                                textPosition="after"
                                                label="Work Completed"
                                            />
                                            <Switch
                                                name="showInstructors"
                                                color="mpTeal"
                                                onChange={(e) => handleSwitches(e, formik)}
                                                checked={formik.values.showInstructors}
                                                textPosition="after"
                                                label="Instructors"
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Switch
                                                name="showSessionNotes"
                                                color="mpTeal"
                                                onChange={(e) => handleSwitches(e, formik)}
                                                checked={formik.values.showSessionNotes}
                                                textPosition="after"
                                                label="Session Notes"
                                            />
                                            <Switch
                                                name="showHomeworkNotes"
                                                color="mpTeal"
                                                onChange={(e) => handleSwitches(e, formik)}
                                                checked={formik.values.showHomeworkNotes}
                                                textPosition="after"
                                                label="Homework Notes"
                                            />
                                            <Switch
                                                name="showStatus"
                                                color="mpTeal"
                                                onChange={(e) => handleSwitches(e, formik)}
                                                checked={formik.values.showStatus}
                                                textPosition="after"
                                                label="Status"
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>

                        <br/>

                        <SummariesTable
                            appointments={filteredAppointments}
                            switches={switches}
                            refreshData={refreshData}
                        />
                    </>
                }
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAdminUsersAll,
    fetchMembersAll,
    fetchAppointmentsDaterangeCenterStudentName,
})(SessionSummaries)