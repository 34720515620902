import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatTime, formatDateFull } from '../../../../../components/functions';
import { renderStatus, TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(handleShowAppointmentModal, handleShowDetailsModal, permissions){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [

        {
            dataField: 'date_time',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                if(aDate - bDate !== 0) return (bDate - aDate) * swap;
                else {
                    const aTime = parseInt(rowA.startTime);
                    const bTime = parseInt(rowB.startTime);
                    return (bTime - aTime) * swap;
                }
            },
            formatter: (row) => formatDateFull(row.date_time),
        },
        {
            dataField: 'time',
            text: 'Time',
            sort: true,
            sortFunc:  (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aTime = parseInt(rowA.startTime);
                const bTime = parseInt(rowB.startTime);
                if(aTime - bTime !== 0) return (bTime - aTime) * swap;
                else {
                    const aDate = new Date(rowA.date);
                    const bDate = new Date(rowB.date);
                    return (bDate - aDate) * swap;
                }
            },
            formatter: (row) => {
                return `${formatTime(row.startTime)} to ${formatTime(row.endTime)}`;
            }
        },
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(!colorMap[center]) colorMap[center] = colors[(colorIndex++) % 5];
                centerColor = colorMap[center];
                return (
                    <div className="mr-2">
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (row) => renderStatus(row)

        },
        {
            dataField: 'instructorNames',
            text: 'Instructors'
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit appointment information`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowAppointmentModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Edit appointment details`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowDetailsModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="plus"/>
                            </Button>
                        </TooltipWrapper>
                        { ['Root', 'Admin', 'Lead Instructor'].includes(permissions) && 
                            <TooltipWrapper
                                tooltipText={`Delete appointment`}
                            >
                                <Button
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowAppointmentModal('delete', row)}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                        }
                    </div>
                );
            }
        }
    ];
}