import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import OverviewAppointments from './overview/appointments/OverviewAppointments';
import OverviewInstructors from './overview/instructors/OverviewInstructors';
import { formatAvailability } from './overview/formatAvailability';
import AvailableTimes from './overview/AvailableTimes';
import { Switch } from '../../../../components/form';

const showInstructors = [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_overview_view_instructors')));

function Overview(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    
    const [instructorMode, setInstructorMode] = useState(showInstructors);

    const { show, availability, availabilityBlocks, appointments, assignmentMap } = props;

    if(!availability || !Object.keys(availability).length){
        return(
            <h4>No availability has been set for this date and center.</h4>
        );
    } else if(parseInt(availability.is_closed)){
        return(
            <h4 className="text-mpOrange">This center is marked as closed.</h4>
        );
    }

    return (
        <div>
            <div className="flex flex-row gap-x-4">
                <div className="w-1/4">
                    {formatAvailability(availability)}

                    <div className="h-2 clear-both"/>
                    <hr/>
                    <div className="h-2 clear-both"/>

                    <h4>Chart Mode</h4>

                    <div className="table">
                        <Switch
                            name="overviewShowInstructors"
                            color="mpLBlue"
                            checked={instructorMode}
                            onChange={(e) => {
                                const newValue = [true, 'true'].includes(e.target.value);
                                setInstructorMode(newValue);
                                localStorage.setItem('admin_scheduling_overview_view_instructors', JSON.stringify(newValue));
                            }}
                            textPosition="after"
                            label={instructorMode ? 'Instructors' : 'Appointments'}
                        />
                    </div>

                    <div className="h-2 clear-both"/>
                    <hr/>
                    <div className="h-2 clear-both"/>
                    
                    <AvailableTimes
                        appointments={appointments}
                        availability={availability}
                        availabilityBlocks={availabilityBlocks}
                    />
                    <div className="h-full overflow-auto"/>
                </div>
                {show ?
                    <>
                        <OverviewInstructors
                            show={instructorMode}
                            assignmentMap={assignmentMap}
                        />
                        <OverviewAppointments
                            show={!instructorMode}
                            availabilityBlocks={availabilityBlocks}
                            appointments={appointments}
                        />
                    </> : null
                }
            </div>
        </div>
    );
}

export default connect(null, {

})(Overview);