import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Tabs, Tab } from '@mui/material';

import { Button, TabPanel } from '../../../../components/custom-essentials';
import { SelectSingle, FormikControl, Switch, checkResponses } from '../../../../components/form';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import StudentTable from './components/StudentTable';
import MemberTable from './components/MemberTable';
import LeadTable from './components/LeadTable';
import { LeadsModal, MemberModal } from '../../../../components/modal';
import { Socket } from '../../../../components/ws';

import { 
    fetchContractsIds,
    fetchMembersAll,
    fetchStudentsAll,
    fetchLeadsAll,
    fetchMpCentersAll,
    fetchRpCentersAll
} from '../../../../actions';

const pageTitle = 'Member Accounts';

const userFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'id', label: 'UUID' },
    { value: 'name', label: 'Name' },
    { value: 'mpCenter', label: 'MP Center' },
    { value: 'rpCenter', label: 'RP Center' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'mpPermissions', label: 'MP Permissions' },
    { value: 'rpPermissions', label: 'RP Permissions' },
    { value: 'user_notes', label: 'Notes' },
];

const studentFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'name', label: 'Name' },
    { value: 'mpCenter', label: 'MP Center' },
    { value: 'rpCenter', label: 'RP Center' },
    { value: 'email', label: 'Email' },
];

const leadFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'user_notes', label: 'Notes' },
];

function Members(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // DATA
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [leads, setLeads] = useState([]);
    const [filteredLeads, setFilteredLeads] = useState([]);
    // MODAL
    const [modalMode, setModalMode] = useState(null);

    const { fetchMembersAll, fetchStudentsAll, fetchLeadsAll, fetchMpCentersAll, fetchRpCentersAll, fetchContractsIds } = props;

    const filterData = useCallback((users, students, leads, values) => {
        const { filterType, filterQuery, showMathMembers, showReadingMembers, showInactive } = values;

        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        function includesText(text){
            return text.toLowerCase().trim().replace(/ /g, '').includes(formattedFQ);
        }
        const checkId = (u) => {
            if(u.id) return includesText(u.id);
            else if(u.user_id) return includesText(u.user_id);
            else return false;
        }
        const checkName = (u) => {
            const po = u.parentObject || {};
            const sl = u.studentList || [];
            return (
                includesText(`${u.first_name}${u.last_name}`)
                || includesText(`${po.first_name}${po.last_name}`)
                || (sl.some(s => includesText(s)))
            );
        }
        const checkMPCenter = (u) => {
            if(formattedFQ === '') return true;
            if(!u.mpCenterName) return false;
            includesText(u.mpCenterName);
        }
        const checkRPCenter = (u) => {
            if(formattedFQ === '') return true;
            if(!u.rpCenterName) return false;
            includesText(u.rpCenterName);
        }
        const checkEmail = (u) => includesText(u.email);
        const checkPhone = (u) => includesText(u.phone.toString());
        const checkMPPermissions = (u) => {
            if(formattedFQ === '') return true;
            if(!u.mp_permissions) return false;
            includesText(u.mp_permissions);
        }
        const checkRPPermissions = (u) => {
            if(formattedFQ === '') return true;
            if(!u.rp_permissions) return false;
            includesText(u.rp_permissions);
        }
        const checkNotes = (u) => {
            if(formattedFQ === '') return true;
            if(!u.notes) return false;
            includesText(u.notes);
        }

        const showInactiveTrue = showInactive === 'true' || showInactive === true;
        const showMathTrue = showMathMembers === 'true' || showMathMembers === true;
        const showReadingTrue = showReadingMembers === 'true' || showReadingMembers === true;

        const runFilter = (data) => (data.filter(u => {
            switch(filterType.value){
                 case 'all':
                    return checkId(u) || checkName(u) || checkMPCenter(u) || checkRPCenter(u) ||
                    checkEmail(u) || checkPhone(u) || checkMPPermissions(u) || checkRPPermissions(u) || checkNotes(u);
                case 'id':
                    return checkId(u);
                case 'name':
                    return checkName(u);
                case 'mpCenter':
                    return checkMPCenter(u);
                case 'rpCenter':
                    return checkRPCenter(u);
                case 'email':
                    return checkEmail(u);
                case 'phone':
                    return checkPhone(u);
                case 'mpPermissions':
                    return checkMPPermissions(u);
                case 'rpPermissions':
                    return checkRPPermissions(u);
                case 'user_notes':
                    return checkNotes(u);
                default:
                    return false;
            }
        }).filter(u => {
            const isMpUser = (u.mp_permissions && u.mp_permissions?.toLowerCase() !== 'none') || parseInt(u.is_mp_student) === 1;
            const isRpUser = (u.rp_permissions && u.rp_permissions?.toLowerCase() !== 'none') || parseInt(u.is_rp_student) === 1;
            // Check against showInactive filter
            // Only check rp_active if we are looking at a member user, and not a student (user_id prop will not exist for members)
            const isValid1 = showInactiveTrue || parseInt(u.mp_active) === 1 || (!u.user_id && parseInt(u.rp_active) === 1);
            // Check against showMath and showReading filters
            const isValid2 = showMathTrue && isMpUser;
            const isValid3 = showReadingTrue && isRpUser;
            return isValid1 && (isValid2 || isValid3);
        }));
        
        const newFilteredUsers = runFilter(users);
        const newFilteredStudents = runFilter(students);
        const newFilteredLeads = runFilter(leads);

        if(mounted.current){
            setFilteredUsers(newFilteredUsers);
            setFilteredStudents(newFilteredStudents);
            setFilteredLeads(newFilteredLeads);
        }
    }, [setFilteredUsers, setFilteredStudents, mounted]);
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
            const usersRes = await fetchMembersAll();
            const studentsRes = await fetchStudentsAll();
            const leadsRes = await fetchLeadsAll();
            const mpCentersRes = await fetchMpCentersAll();
            const rpCentersRes = await fetchRpCentersAll();
            const isApiError = checkResponses(usersRes, studentsRes, mpCentersRes, rpCentersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newUsers = usersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newLeads = leadsRes.data || [];
            const newMpCenters = mpCentersRes.data || [];
            const newRpCenters = rpCentersRes.data || [];
    
            const mpCenterMap = {};
            mpCenterMap[-1] = 'None';
            const rpCenterMap = {};
            rpCenterMap[-1] = 'None';
            newMpCenters.forEach(c => mpCenterMap[parseInt(c.id)] = c.name);
            newRpCenters.forEach(c => rpCenterMap[parseInt(c.id)] = c.name);

            const memberLeadMap = {};
            newUsers.forEach(u => memberLeadMap[u.id] = u);
            newLeads.forEach(l => memberLeadMap[l.id] = l);
            newUsers.forEach(u => {
                u.mpCenterName = mpCenterMap[u.mp_primary_center] || `Unable to find center (ID :${u.mp_primary_center})`;
                u.rpCenterName = rpCenterMap[u.rp_primary_center] || `Unable to find center (ID :${u.rp_primary_center})`;
            });
            newLeads.forEach(l => {
                l.mpCenterName = mpCenterMap[l.mp_primary_center] || `Unable to find center (ID :${l.mp_primary_center})`;
                l.rpCenterName = rpCenterMap[l.rp_primary_center] || `Unable to find center (ID :${l.rp_primary_center})`;
                l.isLead = true;
            });
    
            const contractIds = newStudents.map(s => parseInt(s.mp_current_contract));
            const contractsRes = await fetchContractsIds({ ids: contractIds });
            const isApiError2 = checkResponses(contractsRes);
            if(isApiError2){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newContracts = contractsRes.data || [];
            const contractMap = {};
            newContracts.forEach(c => contractMap[parseInt(c.id)] = c);
    
            const studentParentMap = {};
            newStudents.forEach(s => {
                const relUser = memberLeadMap[s.user_id] || {};
                s.id = s.user_id;
                s.email = relUser.email || '';
                s.phone = relUser.phone || '';
                s.mp_permissions = relUser.mp_permissions.toLowerCase();
                s.rp_permissions = relUser.rp_permissions.toLowerCase();
                s.contract = contractMap[parseInt(s.mp_current_contract)] || {};
                s.userObject = memberLeadMap[s.user_id] || {};
                const parent = memberLeadMap[s.parent];
                s.parentObject = parent || {};
                s.mpCenterName = mpCenterMap[s.mp_primary_center] || `Unable to find center (ID :${s.mp_primary_center})`;
                s.rpCenterName = rpCenterMap[s.pr_primary_center] || `Unable to find center (ID :${s.pr_primary_center})`;

                studentParentMap[s.user_id] = parent;

                if(parent){
                    if(!parent.studentList) parent.studentList = [];
                    parent.studentList.push(`${s.first_name} ${s.last_name}`);
                }
            });

            newUsers.forEach(u => {
                u.parentObject = studentParentMap[u.id] || {};
            });
            newLeads.forEach(l => {
                l.parentObject = studentParentMap[l.id] || {};
            });
    
            if(mounted.current){
                setUsers(newUsers);
                setStudents(newStudents);
                setLeads(newLeads);
                filterData(newUsers, newStudents, newLeads, formRef.current.values);
                setLoading(false);
            }
        })();
    }, [loading, formRef, mounted, fetchMpCentersAll, fetchRpCentersAll, fetchMembersAll, fetchStudentsAll,
        fetchLeadsAll, fetchContractsIds, setUsers, setStudents, filterData, setLoading]);
    useEffect(() => {

        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // For the CREATE button
    function handleShowModal(mode){
        setModalMode(mode);
    }
    function onSubmitCallback(changed = true){
        if(changed) refreshData();
        setModalMode(null);
    }

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {/* CREATE mode only (edit/delete handled in table) */}
            {modalMode === 'member' && 
                <MemberModal
                    mode="create"
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {modalMode === 'lead' &&
                <LeadsModal
                    mode="create"
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <div className="card">
                <h2>Member Accounts</h2>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        tabActiveKey: 0,
                        filterType: { value: 'all', label: 'All' },
                        filterQuery: '',
                        showMathMembers: true,
                        showReadingMembers: false,
                        showInactive: false,
                    }}
                    innerRef={formRef}
                    handleSubmit={() => null}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="members-type-1"
                                        value={formik.values.filterType}
                                        name="filterType"
                                        label="Filter Type"
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.filterType = e.target.value;
                                            formik.setValues(newValues);
                                            filterData(users, students, leads, newValues);
                                        }}
                                        options={formik.values.tabActiveKey === 0 ? userFilterOptions : studentFilterOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <FormikControl
                                        id="members-query-1"
                                        name="filterQuery"
                                        placeholder="Enter a filter query..."
                                        value={formik.values.filterQuery}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.filterQuery = e.target.value;
                                            formik.setValues(newValues);
                                            filterData(users, students, leads, newValues);
                                        }}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Switch
                                        name="showInactive"
                                        label="Show Inactive"
                                        color="mpLRed"
                                        checked={formik.values.showInactive}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.showInactive = e.target.value;
                                            formik.setValues(newValues);
                                            filterData(users, students, leads, newValues);
                                        }}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Switch
                                        name="showMathMembers"
                                        label="Show MP"
                                        color="mpLRed"
                                        checked={formik.values.showMathMembers}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.showMathMembers = e.target.value;
                                            formik.setValues(newValues);
                                            filterData(users, students, leads, newValues);
                                        }}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Switch
                                        name="showReadingMembers"
                                        label="Show RP"
                                        color="mpLRed"
                                        checked={formik.values.showReadingMembers}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.showReadingMembers = e.target.value;
                                            formik.setValues(newValues);
                                            filterData(users, students, leads, newValues);
                                        }}
                                    />
                                </div>
                            </div> 

                            <br/>

                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Button
                                        onClick={() => handleShowModal('member')}
                                        color="lte-mpLBlue"
                                    >
                                        + New User
                                    </Button>
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Button
                                        onClick={() => handleShowModal('lead')}
                                        color="lte-mpLBlue"
                                    >
                                        + New Lead
                                    </Button>
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 ml-auto">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All users that are currently filtered
                                                    ({formik.values.tabActiveKey === 0 ?
                                                        filteredUsers.length : filteredStudents.length} items).
                                                    This depends on the tab that is selected.
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Members"
                                            label="Export users to CSV"
                                            data={formik.values.tabActiveKey === 0 ? filteredUsers : filteredStudents}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>

                            <div className="h-8 clear-both"/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div>
                                :
                                <>
                                    <Tabs
                                        id="members"
                                        value={formik.values.tabActiveKey}
                                        onChange={(e, newValue) => {
                                            // Set the key
                                            const key = parseInt(newValue);
                                            const newValues = { ...formik.values };
                                            newValues.tabActiveKey = key;

                                            // Update the selected type option if the currently selected option
                                            // is not included in the new options
                                            // 0 = users, 1 = students, 2 = leads
                                            const typeOptions = key === 0 ? userFilterOptions :
                                            key === 1 ?  studentFilterOptions :
                                            key === 2 ? leadFilterOptions : [{ value: -1, label: 'Error loading tab...' }];
                                            const currentType = formik.values.filterType.value
                                            newValues.filterType = typeOptions.find(o => o.value === currentType) || { value: 'all', label: 'All' };
                                            formik.setValues(newValues);

                                            // Refresh data with updated filterType, if a change was made
                                            if(currentType !== newValues.filterType.value){
                                                filterData(users, students, leads, newValues.filterType,
                                                    formik.values.filterQuery, formik.values.showInactive)
                                            }
                                        }}
                                    >
                                        <Tab label={`Users (${filteredUsers.length})`}/>
                                        <Tab label={`Students (${filteredStudents.length})`}/>
                                        <Tab label={`Leads (${filteredLeads.length})`}/>
                                    </Tabs>
                                    <TabPanel activeKey={formik.values.tabActiveKey} index={0}>
                                        <MemberTable
                                            refreshData={refreshData}
                                            memberData={filteredUsers}
                                        />
                                    </TabPanel>
                                    <TabPanel activeKey={formik.values.tabActiveKey} index={1}>
                                        <StudentTable
                                            refreshData={refreshData}
                                            studentData={filteredStudents}
                                        />
                                    </TabPanel>
                                    <TabPanel activeKey={formik.values.tabActiveKey} index={2}>
                                        <LeadTable
                                            refreshData={refreshData}
                                            leadData={filteredLeads}
                                        />
                                    </TabPanel>
                                </>
                            }

                        </form>
                    )}
                </Formik>
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />

            {loading && <LoadingOverlay/>}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchContractsIds,
    fetchMembersAll,
    fetchStudentsAll,
    fetchLeadsAll,
    fetchMpCentersAll,
    fetchRpCentersAll
})(Members);