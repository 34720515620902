import React from 'react';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    operationNotes: Yup.string()
        .max(2500, 'Max 2500 characters'),
    issues: Yup.string()
        .max(2500, 'Max 2500 characters'),
    studentNotes: Yup.string()
        .max(2500, 'Max 2500 characters'),
    adminNotes: Yup.string()
        .max(2500, 'Max 2500 characters'),
});

export function renderCharacterCount(current, limit){
    if(current > limit) return <div className="text-mpLRed">{current}/{limit} characters</div>;
    return <div className="text-mpGreen">{current}/{limit} characters</div>;
}