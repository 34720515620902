import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import ExtraTagsBodyFooter from './ExtraTagsBF';
import { checkResponse } from '../../form';

import {
    fetchExtraTagsIds,
    createExtraTag,
    updateExtraTag,
    deleteExtraTag
} from '../../../actions';

function ExtraTagsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [extraTag, setExtraTag] = useState({});

    const { mode, selectedTag, onSubmitCallback, fetchExtraTagsIds,
        createExtraTag, updateExtraTag, deleteExtraTag } = props;

    useEffect(() => {
        async function init(){           
            if(mode !== 'create'){
                const extraTagRes = await fetchExtraTagsIds({ ids: [selectedTag.id] });
                const newExtraTag = extraTagRes.data?.[0] || {};

                if(mounted.current) setExtraTag(newExtraTag);
            }
            
            if(mounted.current){
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const extraTagsParams = {
                    value: values.value
                };
                
                if(mode === 'create'){
                    response = await createExtraTag(extraTagsParams);
                } else if(mode === 'edit') {
                    extraTagsParams.id = extraTag.id;
                    response = await updateExtraTag(extraTagsParams);
                }
            } else if(mode === 'delete') {
                response = await deleteExtraTag({ id: extraTag.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, extraTag, createExtraTag, updateExtraTag, deleteExtraTag]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Extra Tags Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <ExtraTagsBodyFooter
                    mode={mode}
                    selectedTag={extraTag}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchExtraTagsIds,
    createExtraTag,
    updateExtraTag,
    deleteExtraTag
})(ExtraTagsModal);