import React from 'react';

import { Button, SVGIcon } from '../../../../../components/custom-essentials';
import { renderStatus, TooltipWrapper } from '../../../../../components/display';
import { formatDateFull, formatDateTimeFull } from '../../../../../components/functions';

const noneTag = <span className="text-mpOrange">None</span>;

export function getColumnsExtraTags(handleShowModal){
    return [
        {
            dataField: 'value',
            text: 'Value',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit tag`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete tag`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}

function renderNewLines(text){
    let index = 0;
    return text.split('\n').map(str => <p key={`${str}-${index++}`}>{str}</p>);
}

export function getColumnsReports(employeeMap){
    return [
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => {
                return (
                    <>
                        {formatDateFull(row.date)}

                        <br/>
                        <br/>

                        <TooltipWrapper
                            tooltipText={
                                <>
                                    Written on {formatDateTimeFull(row.date_assigned)} by {employeeMap[row.original_author] || `Unknown user (ID: ${row.original_author})`}

                                    { row.updated_by &&
                                        <>
                                            <br/>
                                            <br/>
                                            Updated on {formatDateTimeFull(row.date_updated)} by {employeeMap[row.updated_by] || `Unknown user (ID: ${row.updated_by})`}
                                        </>
                                    }
                                </>
                            }
                        >
                            <div className="text-mpLBlue">(meta)</div>
                        </TooltipWrapper>
                    </>
                );
            },
            style: { verticalAlign: 'top' }
        },
        {
            dataField: 'report',
            text: 'Report',
            formatter: (row) => {
                const { operation_notes, issues, student_notes, admin_notes } = row;
                return (
                    <div className="grid grid-cols-1">
                        <h4>Assigned to: {employeeMap[row.assigned_to] || `Unknown user (ID: ${row.assigned_to})`}</h4>
                        <br/>
                        <h4>Operation Notes</h4>
                        <div>
                            {operation_notes ? renderNewLines(operation_notes) : noneTag}
                        </div>
                        <br/>
                        <h4>Issues</h4>
                        <div>
                            {issues ? renderNewLines(issues) : noneTag}
                        </div>
                        <br/>
                        <h4>Student Notes</h4>
                        <div>
                            {student_notes ? renderNewLines(student_notes) : noneTag}
                        </div>
                        <br/>
                        <h4>Admin Notes</h4>
                        <div>
                            {admin_notes ? renderNewLines(admin_notes) : noneTag}
                        </div>
                        <div className="h-2 clear-both"/>
                    </div>
                );
            }
        }
    ];
}

export function getColumnsNotes(){
    return [
        {
            dataField: 'studentName',
            text: 'Name',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => {
                return (
                    <>
                        <div>{row.studentName}</div>
                    </>
                );
            },
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => {
                return (
                    <div className="grid grid-cols-1">
                        <div><b>Instructors:</b> {row.instructorNames || noneTag}</div>
                        <div>
                            <b>Pages/Lessons:</b> {row.n_pages} / {row.n_lessons}
                        </div>
                        <div>
                            <b>Session Notes:</b> {row.session_notes || noneTag}
                        </div>
                        <div>
                            <b>Homework Notes:</b> {row.hw_notes || noneTag}
                        </div>
                        <div className="row no-mar">
                            <b>Status:</b>&nbsp;{renderStatus(row)}
                        </div>
                    </div>
                );
            }
        }
    ];
}