import React, { useRef, useEffect } from 'react';

import TableHeaderCell from './TableHeaderCell';

function TableHeaderRow(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { tableName, order, orderBy, columns, handleRequestSort } = props;

    return (
        <thead className="bg-mpDBlue text-white">
            <tr>
                {columns.map(c => {
                    const dataField = c.dataField;
                    const label = c.text;
                    return (
                        <TableHeaderCell
                            cellKey={`table-${tableName}-header-col-${dataField}`}
                            content={label}
                            dataField={dataField}
                            sortDirection={orderBy === dataField ? order : null}
                            shouldSort={c.sort}
                            handleRequestSort={handleRequestSort}
                        />
                    );
                })}
            </tr>
        </thead>
    );
}

export default TableHeaderRow;