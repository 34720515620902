import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { getAlerts, formatTime, formatDateFull } from '../../../../../components/functions';
import { renderPhone, renderStatus, TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(loading, handleShowModal, handleStatusButtonClick, selectedCenter, nextTime, permissions){
    const isAll = selectedCenter === 'all';
    const statusSortObject = {
        'Not Charged': 1,
        'Missed': 2,
        'Cancelled': 3,
        'Scheduled': 4,
        'In Progress': 5,
        'Completed': 6
    };

    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    const columns = [
        {
            dataField: 'student',
            text: 'Student',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(rowA.studentName < rowB.studentName) return -1 * swap;
                else if(rowA.studentName > rowB.studentName) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const student = row.studentInfo || {};
                if(!student.first_name && !student.last_name) return `Unknown student (UID ${row.student})`;

                const studentName = `${student.first_name} ${student.last_name}`;
                const parent = row.parentObject || {};
                const firstGuardianContent = student.first_guardian_notes ? (
                    <>
                        <br/>
                        <u>Additional Contacts</u>
                        <div>First Guardian Notes: {student.first_guardian_notes}</div>
                    </>
                ) : null;
                const secondGuardianContent = student.second_guardian_notes ? (
                    <>
                        <br/>
                        <div>Second Guardian Notes: {student.second_guardian_notes}</div>
                    </>
                ) : null;
                return (
                    <TooltipWrapper
                        placement="right"
                        tooltipText={
                            <>
                                <u>School Info</u>
                                <div>School: {student.school || 'Not Specified'}</div>
                                <div>Teacher: {student.mp_teacher || 'Not Specified'}</div>
                                <div>Grade: {student.grade || 'Not Specified'}</div>
                                <div>School Year: {student.school_year || 'Not Specified'}</div>
            
                                <br/>
            
                                <u>Account Info</u>
                                <div>Weight: {student.mp_weight}</div>
                                <div>Next Month Begins:</div>
                                <div>{formatDateFull(student.mp_next_month_start)}</div>
                                <div>
                                    Hours This Month:&nbsp;
                                    {(student.mp_time_left_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Hours Next Month:&nbsp;
                                    {(student.mp_time_left_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Reserve Hours Left:&nbsp;
                                    {(student.mp_reserve_time_left / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Default Hours:&nbsp;
                                    {(student.mp_default_time_per_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
            
                                <br/>
            
                                <u>Student Contact:</u>
                                <div>Phone: {renderPhone(student.phone)}</div>
                                <div>Email: {student.email}</div>
            
                                <br/>
            
                                <u>Parent Contact:</u>
                                <div>Name: {parent.first_name} {parent.last_name}</div>
                                <div>Phone: {renderPhone(parent.phone)}</div>
                                <div>Email: {parent.email}</div>
            
                                {firstGuardianContent}
                                {secondGuardianContent}
                            </>
                        }
                    >
                        <div className="text-mpLBlue">{studentName}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'startTime',
            text: 'Start Time',
            sort: true,
            formatter: (row) => {
                const { startTime } = row;
                const timeFormatted = formatTime(startTime);
                if(parseInt(startTime) === nextTime){
                    return <Label color="mpLRed">{timeFormatted}</Label>;
                }
                return timeFormatted;
            }
        },
        {
            dataField: 'endTime',
            text: 'End Time',
            sort: true,
            formatter: (row) => {
                const { endTime } = row;
                const timeFormatted = formatTime(endTime);
                if(parseInt(endTime) === nextTime){
                    return <Label color="mpLRed">{timeFormatted}</Label>;
                }
                return timeFormatted;
            }
        },
        {
            dataField: 'instructorNames',
            text: 'Instructors',
        },
        {
            dataField: 'alerts',
            text: 'Alerts',
            formatter: (row) => getAlerts(row)
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return (statusSortObject[a] - statusSortObject[b]) * swap;
            },
            formatter: (row) => renderStatus(row)
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                const isRootAdminLead = ['Root', 'Admin', 'Lead Instructor'].includes(permissions);
                return (
                    <>
                        <div className="flex flex-row gap-x-2 mb-2">
                            { isRootAdminLead && 
                                <TooltipWrapper
                                    tooltipText={`Move Binder`}
                                >
                                    <Button
                                        color="lte-mpLBlue"
                                        variant="icon"
                                        onClick={() => handleShowModal('transition-binder', row)}
                                        style={{ marginRight: "10px" }}
                                    >
                                        <SVGIcon fileName="folder-open"/>
                                    </Button>
                                </TooltipWrapper>
                            }
                            <TooltipWrapper
                                tooltipText={`Edit this appointment`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowModal('appointment-edit', row)}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Update appointment details`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowModal('details', row)}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="plus"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={row.stamps_given ? 'Give stamps' : 'Give stamps (not yet given)'}
                            >
                                <Button
                                    color={row.stamps_given ? 'lte-mpLBlue' : 'lte-mpPurple'}
                                    variant="icon"
                                    onClick={() => handleShowModal('stamps', row)}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="star"/>
                                </Button>
                            </TooltipWrapper>
                            { isRootAdminLead && 
                                <TooltipWrapper
                                    tooltipText={`Delete appointment`}
                                >
                                    <Button
                                        color="lte-mpLRed"
                                        variant="icon"
                                        onClick={() => handleShowModal('appointment-delete', row)}
                                        style={{ marginRight: "10px" }}
                                        disabled={loading}
                                    >
                                        <SVGIcon fileName="trash"/>
                                    </Button>
                                </TooltipWrapper>
                            }
                        </div>
                        <div className="flex flex-row gap-x-2 ml-5">
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "In Progress"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'In Progress')}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="ellipsis"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "Completed"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'Completed')}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="check"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "Missed"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'Missed')}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="question"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "Cancelled"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'Cancelled')}
                                    style={{ marginRight: "10px" }}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="times"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                    </>
                );
            }
        }
    ];

    if(isAll){
        columns.splice(6, 0, {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(!colorMap[center])  colorMap[center] = colors[(colorIndex++) % 5];
                centerColor = colorMap[center];
                return (
                    <div className="mr-2">
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        });
    }
    return columns;
}