export function openPopup(fileBytes){
    const file = new Blob([fileBytes], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
}

export function downloadFile(fileBytes, fileName){
    const url = window.URL.createObjectURL(
        new Blob([fileBytes]),
    );
    // const url = fileBytes;
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.setAttribute(
        'download',
        `${fileName}`,
    );
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}