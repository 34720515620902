import React from 'react';
import { formatDateFull, formatTime } from '../../../../../components/functions';

export function formatAvailability(availability){
    const { date, centerName, open_time, last_start, close_time } = availability;

    return (
        <div>
            <h4>Availability Details</h4>
            <div className="ml-2">
                <div>Date: {formatDateFull(date)}</div>
                <div>Center: {centerName}</div>
                <div>Open Time: {formatTime(open_time)}</div>
                <div>Last Start: {formatTime(last_start)}</div>
                <div>Close Time: {formatTime(close_time)}</div>
            </div>
        </div>
    )
}