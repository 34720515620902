import React from "react";

export function findComponentByType(children, displayName){
    if(!children) return null;
    return React.Children.map(children, (child) => {
        if(!child) return null;

        const grandChildren = child.props?.children;
        if(child.type?.displayName === displayName) return child;
        else if(grandChildren) return findComponentByType(grandChildren, displayName);
        else return null;
    });
    // return children.filter(child => child.type.displayName === displayName);
}

export function doesComponentContainContents(element, contents){
    if(!element) return false;
    else if(typeof(element) === 'string' && element.includes(contents)) return true;
    else {
        const grandChildren = element.props?.children;
        if(typeof(grandChildren) === 'string') return grandChildren.includes(contents);
        else if(grandChildren) return grandChildren.some(gc => doesComponentContainContents(gc, contents));
        else return false;
    }
}