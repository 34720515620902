import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REGISTER, PAUSE, PERSIST, PURGE, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { authSlice } from './slices/authSlice';
import { notificationsSlice } from './slices/notificationSlice';
import { serverSlice } from './slices/serverSlice';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    server: serverSlice.reducer
})

const persistedAuth = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedAuth,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REGISTER, PAUSE, PERSIST, PURGE, REHYDRATE] // This silences a warning that results from using reduxjs/toolkit and redux-persist
        }
    })
});
const persistor = persistStore(store);

export { store, persistor };
export const { setUserInfo, clearUserInfo } = authSlice.actions;
export const { setNPendingFlags } = notificationsSlice.actions;
export const { setServerDatabase } = serverSlice.actions;