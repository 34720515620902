import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import AssessmentsTable from './AssessmentsTable';
import { Button } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay } from '../../../../components/display';
import { SelectSingle, FormikControl, DateRangeSelector, checkResponses } from '../../../../components/form';
import { formatDateApi } from '../../../../components/functions';
import { Socket } from '../../../../components/ws';
import { AssessmentsModal } from '../../../../components/modal';

import {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAssessmentsDaterangeCenter
} from '../../../../actions';

const pageTitle = 'Assessment Manager';

const start = new Date();
start.setMonth(start.getMonth() - 6);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function AssessmentManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [drsValid, setDrsValid] = useState(true);
    const [apiError, setApiError] = useState(false);

    // DATA
    const [assessments, setAssessments] = useState([]);
    const [students, setStudents] = useState([]);
    const [assessmentsFiltered, setAssessmentsFiltered] = useState([]);
    const [centerOptions, setCenterOptions] = useState([{ value: -1, label: 'Loading centers...' }]);

    const [modalMode, setModalMode] = useState(null);

    const { fetchMpCentersAll, fetchStudentsAll, fetchAssessmentsDaterangeCenter } = props;

    const filterAssesments = useCallback((unfilteredAssessments, filterQuery) => {
        const formattedFQ = filterQuery.replace(/ /g, '').toLowerCase();
        const checkStudent = (assessment) => assessment.studentName.toLowerCase().replace(/ /g, '').includes(formattedFQ);
        const checkName = (assessment) => assessment.name.toLowerCase().replace(/ /g, '').includes(formattedFQ);

        const newAssessmentsFiltered = unfilteredAssessments.filter(f => checkStudent(f) || checkName(f));
        
        setAssessmentsFiltered(newAssessmentsFiltered);
    }, []);
    const refreshData = useCallback((newStudents = students) => {
        (async function refresh(){
            if(loading || !drsValid || !formRef.current?.values) return;
            if(mounted.current) setLoading(true);
    
            const { selectedCenter, filterQuery, startDate, endDate } = formRef.current.values;
    
            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);
    
            const assessmentsRes = await fetchAssessmentsDaterangeCenter({
                startDate: startDate,
                endDate: endDate,
                center: selectedCenter.value
            });
            const isApiError = checkResponses(assessmentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAssessments = assessmentsRes?.data || [];

            const assessmentsAppended = newAssessments.map(a => {
                const relStudentName = studentMap[a.student];
                a.studentName = relStudentName || `Unknown student (UID: ${a.student})`;

                return a;
            });
    
            if(mounted.current){
                setAssessments(assessmentsAppended);
                filterAssesments(assessmentsAppended, filterQuery);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, filterAssesments, formRef, drsValid, students, fetchAssessmentsDaterangeCenter]);
    useEffect(() => {
        async function init(){
            setLoading(true);
            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const isApiError = checkResponses(centersRes, studentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }
            
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];

            const newCenterOptions = [{ value: 'all', label: 'All' },
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name }))];

            if(mounted.current){
                setStudents(newStudents);
                setCenterOptions(newCenterOptions);
                setLoading(false);
                setHasLoaded(true);
                refreshData(newStudents);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            { modalMode && // 'create' only
                <AssessmentsModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {hasLoaded &&
                <div className="card">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            startDate: startApi,
                            endDate: endApi,
                            selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                            filterQuery: '',
                            activeKey: 'pending'
                        }}
                        innerRef={formRef}
                        onSubmit={() => refreshData(students)}
                    >
                        {formik => (
                            <form onSubmit={formik.handleSubmit}>
                                <h2>Assessments</h2>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <DateRangeSelector
                                            id="assessment-manager-drs"
                                            startName="startDate"
                                            endName="endDate"
                                            startLabel="Created Start Date"
                                            endLabel="Created End Date"
                                            startValue={formik.values.startDate}
                                            endValue={formik.values.endDate}
                                            defaultValid={true}
                                            onStartChange={formik.handleChange}
                                            onEndChange={formik.handleChange}
                                            onChangeValidation={setDrsValid}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <SelectSingle
                                            id="assessment-manager-selectedCenter"
                                            name="selectedCenter"
                                            label="Center"
                                            value={formik.values.selectedCenter}
                                            onChange={formik.handleChange}
                                            options={centerOptions}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpTeal"
                                            onClick={formik.handleSubmit}
                                            disabled={formik.values.selectedCenter.value === -1}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setModalMode('create')}
                                        >
                                            + Add Assessment
                                        </Button>
                                    </div>
                                </div>
                                
                                <br/>
                                
                                { apiError ? <div className="text-mpLRed">{apiError}</div> : 
                                    <>
                                        <hr/>
                                        <br/>

                                        <h2>Filter Results</h2>

                                        <br/>
                                        
                                        <div className="flex flex-row gap-x-4 items-center">
                                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                                <FormikControl
                                                    id="assessment-manager-searchQuery"
                                                    name="filterQuery"
                                                    placeholder="Enter a filter query..."
                                                    value={formik.values.filterQuery}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        filterAssesments(assessments, e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <AssessmentsTable
                                            refreshData={refreshData}
                                            assessments={assessmentsFiltered}
                                        />
                                    </>
                                }
                                
                            </form>
                        )}
                    </Formik>
                </div>
            }
            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAssessmentsDaterangeCenter
})(AssessmentManager);