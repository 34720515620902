import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl, Check } from '../../form';
import { renderPhone } from '../../display';
import { validationSchema, getInitCenterOption, mpPermissionsOptions,
    rpPermissionsOptions, getInitPermissionsOption } from './helpers';

// Exact same form as the Employee Modal, with variables and actions changed slightly
function LeadsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedLead, mpCenterOptions, rpCenterOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const leadName = `${selectedLead.first_name} ${selectedLead.last_name}`;
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    confirmName: ''
                }}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>
                                Delete this lead?
                            </h4>
                            <div className="text-mpOrange">
                                All appointments and actions will be permanently unlinked.
                            </div>
                            <br/>
                            <h4 className="text-mpOrange">
                                This cannot be undone.
                            </h4>
                            <div style={{ height: "0.5rem", clear: "both" }}/>
                            <div className="col">
                                <div>Name: {leadName}</div>
                                <div>Email: {selectedLead.email}</div>
                                <div>Phone: {renderPhone(selectedLead.phone)}</div>
                                <div>MP Permissions: {selectedLead.mp_permissions}</div>
                                <div>RP Permissions: {selectedLead.rp_permissions}</div>
                            </div>

                            <br/>
                            <br/>
                            
                            <h4>Enter this user's name to proceed:</h4>
                            <div className="col">
                                <FormikControl
                                    id="delete-member-confirmName"
                                    name="confirmName"
                                    value={formik.values.confirmName}
                                    onChange={formik.handleChange}
                                    placeholder={leadName}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                { !formik.values.firstConfirm ? (
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            disabled={formik.values.confirmName !== leadName}
                                            onClick={() => formik.setFieldValue('firstConfirm', true)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div style={{ marginRight: "auto" }}>
                                            <Button
                                                color="hol-mpLRed"
                                                disabled={formik.isSubmitting || formik.values.confirmName !== leadName}
                                                onClick={(e) => {
                                                    formik.setFieldValue('firstConfirm', false);
                                                    formik.handleSubmit(e)
                                                }}
                                                style={{ marginRight: "5px" }}
                                            >
                                                Confirm Delete
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                disabled={formik.isSubmitting}
                                                onClick={() => {
                                                    formik.setStatus('');
                                                    formik.setFieldValue('firstConfirm', false);
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <div
                                            className="text-mpOrange d-flex align-items-center"
                                            style={{ marginLeft: "20px"}}
                                        >
                                            Are you sure?
                                        </div>
                                    </>
                                )}
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    firstName: selectedLead.first_name || '',
                    lastName: selectedLead.last_name || '',
                    email: selectedLead.email || '',
                    phone: selectedLead.phone || '',
                    mpPrimaryCenter: getInitCenterOption(selectedLead.mp_primary_center, mpCenterOptions),
                    rpPrimaryCenter: getInitCenterOption(selectedLead.rp_primary_center, rpCenterOptions),
                    mpPermissions: getInitPermissionsOption(selectedLead.mp_permissions, mpPermissionsOptions),
                    rpPermissions: getInitPermissionsOption(selectedLead.rp_permissions, rpPermissionsOptions),
                    userNotes: selectedLead.user_notes || '',
                    mpActive: parseInt(selectedLead.mp_active) === 0 ? false : true,
                    rpActive: parseInt(selectedLead.rp_active) === 0 ? false : true,

                    firstConfirm: false,
                    receiveEmails: !((selectedLead.email || '').includes('m4mph')),
                    receiveTexts: !!parseInt(selectedLead.phone),
                    shouldSendWelcomeEmail: !((selectedLead.email || '').includes('m4mph')),
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            {['create', 'edit'].includes(mode) ? 
                                <>
                                    <div className="flex flex-row gap-x-4 items-start">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>First Name</h4>
                                            <FormikControl
                                                id="leads-first-name"
                                                name="firstName"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                placeholder="Max 30 characters"
                                            />
                                            {formik.errors.firstName ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.firstName}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>Last Name</h4>
                                            <FormikControl
                                                id="leads-first-name"
                                                name="lastName"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                placeholder="Max 30 characters"
                                            />
                                            {formik.errors.lastName ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.lastName}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4 items-start">
                                        <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                            <h4>Email</h4>
                                            <FormikControl
                                                id="leads-email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                placeholder="riley@mathplextutoring.com"
                                            />
                                            {formik.errors.email ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.email}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                            <h4>Phone</h4>
                                            <FormikControl
                                                id="leads-phone"
                                                name="phone"
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                placeholder="9493294040"
                                            />
                                            {formik.errors.phone ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.phone}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4 items-start">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>MP Primary Center</h4>
                                            <SelectSingle
                                                id="leads-mpCenter"
                                                name="mpPrimaryCenter"
                                                value={formik.values.mpPrimaryCenter}
                                                onChange={formik.handleChange}
                                                options={mpCenterOptions}
                                                placeholder="Max 30 characters"
                                            />
                                            {formik.errors.mpPrimaryCenter ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.mpPrimaryCenter}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>MP Permissions</h4>
                                            <SelectSingle
                                                id="leads-mpPermissions"
                                                name="mpPermissions"
                                                value={formik.values.mpPermissions}
                                                onChange={formik.handleChange}
                                                options={mpPermissionsOptions}
                                            />
                                            {formik.errors.mpPermissions ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.mpPermissions}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4 items-start">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>RP Primary Center</h4>
                                            <SelectSingle
                                                id="leads-mpCenter"
                                                name="rpPrimaryCenter"
                                                value={formik.values.rpPrimaryCenter}
                                                onChange={formik.handleChange}
                                                options={rpCenterOptions}
                                                placeholder="Max 30 characters"
                                            />
                                            {formik.errors.rpPrimaryCenter ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.rpPrimaryCenter}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>RP Permissions</h4>
                                            <SelectSingle
                                                id="leads-rpPermissions"
                                                name="rpPermissions"
                                                value={formik.values.rpPermissions}
                                                onChange={formik.handleChange}
                                                options={rpPermissionsOptions}
                                            />
                                            {formik.errors.rpPermissions ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.rpPermissions}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="grid grid-cols-1 gap-y-2">
                                        <h4>User Notes</h4>
                                        <FormikControl
                                            id="leads-user-notes"
                                            name="userNotes"
                                            as="textarea"
                                            value={formik.values.userNotes}
                                            onChange={formik.handleChange}
                                            placeholder="Max 5,000 characters"
                                        />
                                        {formik.errors.userNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.userNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Check
                                                id="leads-mpActive"
                                                name="mpActive"
                                                label="MP Active"
                                                color="mpLRed"
                                                checked={formik.values.mpActive}
                                                onChange={formik.handleChange}
                                            />  
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Check
                                                id="leads-rpActive"
                                                name="rpActive"
                                                label="RP Active"
                                                color="mpLRed"
                                                checked={formik.values.rpActive}
                                                onChange={formik.handleChange}
                                            />  
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            {formik.values.mpPermissions.value === 'None' && formik.values.mpActive ?
                                                <div className="text-mpLRed">
                                                    If MP Permissions is None, MP Active must be off.
                                                </div> : null
                                            }
                                            {formik.values.rpPermissions.value === 'None' && formik.values.rpActive ?
                                                <div className="text-mpLRed">
                                                    If RP Permissions is None, RP Active must be off.
                                                </div> : null
                                            }
                                        </div>
                                    </div>

                                </> : mode === 'convert' ?
                                    (formik.values.firstConfirm ?
                                        <>
                                            <h4>Are you sure? This cannot be undone.</h4>
                                        </>
                                        :
                                        <>
                                            <h4>Convert {selectedLead.first_name} {selectedLead.last_name}?</h4>
                                            <div>
                                                This lead account will be deleted and converted into a Member account.
                                                No data will be lost, but the process cannot be undone.
                                            </div>

                                            <br/>

                                            <div className="flex flex-row">
                                                <Check
                                                    id="leads-shouldSendWelcomeEmail"
                                                    name="shouldSendWelcomeEmail"
                                                    label="Send Welcome Email"
                                                    color="mpLRed"
                                                    checked={formik.values.shouldSendWelcomeEmail}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>

                                            <div className="flex flex-row">
                                                <Check
                                                    id="leads-receive-emails"
                                                    name="receiveEmails"
                                                    label="Receive Emails"
                                                    color="mpLRed"
                                                    checked={formik.values.receiveEmails}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>

                                            <div className="flex flex-row">
                                                <Check
                                                    id="leads-receive-textx"
                                                    name="receiveTexts"
                                                    label="Receive Texts"
                                                    color="mpLRed"
                                                    checked={formik.values.receiveTexts}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </>
                                    )
                                : 
                                <>
                                    <h4>Invalid modal mode.</h4>
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                {mode === 'convert' ?
                                    (formik.values.firstConfirm ?
                                        <>
                                            <Button
                                                color="lte-mpLRed"
                                                disabled={formik.isSubmitting}
                                                onClick={() => {
                                                    formik.setFieldValue('firstConfirm', false);
                                                }}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={formik.handleSubmit}
                                                disabled={formik.isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <Button
                                                color="lte-mpLRed"
                                                disabled={formik.isSubmitting}
                                                onClick={handleClose}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => {
                                                    formik.setFieldValue('firstConfirm', true);
                                                }}
                                                disabled={formik.isSubmitting}
                                            >
                                                Next
                                            </Button>
                                        </>
                                    )
                                :
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={formik.handleSubmit}
                                            disabled={formik.isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </>
                                }
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(LeadsBodyFooter);