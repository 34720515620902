import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading } from '../../custom-essentials';
import { renderSubmitting, BodyContent }from './helpers';
import OverviewInstructors from './overview/OverviewInstructors';

function QuickAssignBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { attemptingClose, setAttemptingClose, instructorOptions, appointments, chartMaps,
        loaded, submitted, submissionStatus, handleClose, handleSubmit } = props;
    const zeroLength = !Object.entries(appointments).length;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    showAppointments: [],
                    appointments,
                }}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? renderSubmitting(submissionStatus, formik.setSubmitting, formik.setFieldValue) : 
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <Modal.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    ) :
                        <ModalBodyFooter>
                            <Modal.Body>
                                {zeroLength ? 
                                <h4>No appointments exist</h4>
                                :
                                    <>
                                        <div>
                                            <OverviewInstructors show={true} formik={formik} chartMaps={chartMaps}/>
                                        </div>
                                        <div className="max-h-[50rem] overflow-y-auto">
                                            <BodyContent
                                                formik={formik}
                                                instructorOptions={instructorOptions}
                                            />
                                        </div>
                                    </>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                            { !formik.values.firstConfirm ?
                                (
                                    <div className="flex flex-row gap-x-2 flex-wrap">
                                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                            (
                                                <div className="text-mpLRed">
                                                    One or more fields needs to be corrected.
                                                </div>
                                            ) : null
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed">
                                                {formik.status}
                                            </div> : null
                                        }

                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                            style={{ marginRight: "auto" }}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            disabled={zeroLength}
                                            onClick={() => formik.setFieldValue('firstConfirm', true)}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                )
                                :
                                (
                                    <div className="flex flex-row gap-x-2 w-full flex-wrap">
                                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                            (
                                                <div className="text-mpLRed">
                                                    One or more fields needs to be corrected.
                                                </div>
                                            ) : null
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed">
                                                {formik.status}
                                            </div> : null
                                        }
                                        <div className="flex flex-row gap-x-2 mr-auto">
                                            <Button
                                                color="lte-mpLRed"
                                                onClick={() => formik.setFieldValue('firstConfirm', false)}
                                                style={{ marginRight: "5px" }}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                disabled={zeroLength}
                                                onClick={formik.handleSubmit}
                                            >
                                                Confirm Submit
                                            </Button>
                                        </div>
                                        <div className="text-mpOrange">
                                            Are you sure?
                                        </div>
                                    </div>
                                )
                            }
                            </Modal.Footer>
                        </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(QuickAssignBodyFooter);