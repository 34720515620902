import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthPage } from './pages/_auth';
import BasePage from './BasePage';

function App(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="auth/*" element={<AuthPage/>}/>
                <Route path="*" element={<BasePage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;