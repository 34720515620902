import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { checkResponse } from '../../form';
import QuickAssignBodyFooter from './QuickAssignBF';

import {
    fetchAdminUsersAll,
    fetchAppointmentsDateCenter,
    fetchStudentsUserIds,
    fetchFlagsStatus,
    fetchMpCentersAll,
    updateAppointmentInstructors
} from '../../../actions';

function QuickAssignModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [attemptingClose, setAttemptingClose] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [closureSubmitting, setClosureSubmitting] = useState(false); // Prevents form from closing while submitting (only forms with accidental closure prevention)
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [chartMaps, setChartMaps] = useState({});

    const { selectedDate, selectedCenter, onSubmitCallback, fetchAdminUsersAll, fetchAppointmentsDateCenter,
        fetchStudentsUserIds, fetchFlagsStatus, fetchMpCentersAll, updateAppointmentInstructors } = props;
        
    useEffect(() => {
        async function init(){
            const centersRes = await fetchMpCentersAll();
            const instructorsRes = await fetchAdminUsersAll();
            const appointmentsRes = await fetchAppointmentsDateCenter({
                date: new Date(`${selectedDate} 00:00:00`),
                center: selectedCenter.value
            });
            const pendingFlagsRes = await fetchFlagsStatus({ status: 'Pending' });
            const newCenters = centersRes.data || [];
            const newInstructors = instructorsRes.data || [];
            const newAppointments = appointmentsRes.data?.appointments || [];
            const newInstructorAssignments = appointmentsRes.data?.assignments || [];
            const newFlags = pendingFlagsRes.data || [];

            const relevantStudentIds = newAppointments.map(a => a.student);
            const studentsRes = await fetchStudentsUserIds({ userIds: relevantStudentIds });
            const newStudents = studentsRes.data || [];

            const instructorAssignmentIds = {};
            for(let assignment of newInstructorAssignments){
                if(!instructorAssignmentIds[assignment.instructor]) instructorAssignmentIds[assignment.instructor] = true;
            }
            const newInstructorOptions = newInstructors.filter(i => {
                return (instructorAssignmentIds[i.id] ||
                    (i.mp_permissions !== 'None' && parseInt(i.account_active) === 1));
            }).map(i => ({ value: i.id, label: `${i.first_name} ${i.last_name}`, instructor: i.id}));

            const studentIdToNameMap = {};
            newStudents.forEach(s => studentIdToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const instructorIdToNameMap = {};
            newInstructors.forEach(i => instructorIdToNameMap[i.id] = `${i.first_name} ${i.last_name}`);
            const centerToNameMap = {};
            newCenters.forEach(c => centerToNameMap[parseInt(c.id)] = c.name);
            const appointmentToFlagMap = {};
            newFlags.forEach(f => {
                f.createdByName = instructorIdToNameMap[f.created_by];
                f.updatedByName = instructorIdToNameMap[f.updated_by];
                f.centerName = centerToNameMap[f.center];
                // Only append the most recent flag (already sorted by date desc)
                if(!appointmentToFlagMap[f.student]) appointmentToFlagMap[f.student] = f;
            });
            const appointmentsFiltered = newAppointments.filter(a => {
                return !['Cancelled', 'Missed'].includes(a.status);
            }).map(a => {
                const aptDateTime = new Date(a.date_time);
                a.startTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
                a.endTime = a.startTime + parseInt(a.duration);

                // Weight and Duration indicators
                const duration = parseInt(a.duration) !== 60 ? `(${a.duration} minutes)` : null;
                const weight = parseInt(a.weight) !== 1 ? `(Weight ${a.weight})` : null;
                a.extraNotifs = duration && weight ? `${duration} ${weight}` :
                duration && !weight ? `${duration}` :
                !duration && weight ? `${weight}` :
                ``;

                // Get student info and instructor assignment items
                a.studentInfo = newStudents.find(s => s.user_id === a.student) || {};
                a.assignments = newInstructorAssignments.filter(ia => parseInt(ia.appointment_id) === parseInt(a.id))
                    .map(ia => {
                        const instructorName = instructorIdToNameMap[ia.instructor] || `Unknown instructor (ID: ${ia.instructor})`;
                        return { value: ia.instructor, label: instructorName, instructor: ia.instructor };
                    });
                a.specialNotesSchedule = a.special_notes_schedule;
                a.studentName = `${a.studentInfo.first_name} ${a.studentInfo.last_name}`;
                a.pendingFlag = appointmentToFlagMap[a.student] || {};

                return a;
            }).sort((a, b) => {
                if(a.studentName < b.studentName) return -1;
                else if(a.studentName > b.studentName) return 1;
                else return 0; 
            });
            const formattedAppointments = {};
            appointmentsFiltered.forEach(a => {
                const aptId = parseInt(a.id);
                const timeKey = parseInt(a.startTime);
                if(!formattedAppointments[timeKey]) formattedAppointments[timeKey] = {};
                formattedAppointments[timeKey][aptId] = a;
            });

            // For chart //
                const userToNameMap = {};
                newInstructors.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
                newStudents.forEach(s => userToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
                const appointmentToAssignmentObjectMap = {};
                newInstructorAssignments.forEach(ia => {
                    const iaApt = parseInt(ia.appointment_id);
                    if(appointmentToAssignmentObjectMap[iaApt]) appointmentToAssignmentObjectMap[iaApt].push(ia);
                    else appointmentToAssignmentObjectMap[iaApt] = [ia];
                });

                // To use when parsing scheduling notes
                const instructorNameToIdMap = {};
                newInstructors.forEach(u => {
                    const name = `${u.first_name}${u.last_name}`.trim().toLowerCase();
                    instructorNameToIdMap[name] = u.id;
                });

                const newChartMaps = {
                    userToNameMap,
                    instructorNameToIdMap
                };
            // End - For chart //

            if(mounted.current){
                setInstructorOptions(newInstructorOptions);
                setAppointments(formattedAppointments);
                setChartMaps(newChartMaps);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes, force = false) => {
        if(!force){
            if(changes !== true) changes = false;
            if(closureSubmitting) return;
            if(!attemptingClose){
                setAttemptingClose(true);
                return;
            }
        }
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, closureSubmitting, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current){
                setSubmitting(true);
                setClosureSubmitting(true);
            }

            // PREPARE DATA //
            const nss = { errored: false, completed: false };
            const appointmentsToUpdate = [];

            let isValid = true;
            Object.values(values.appointments).forEach(appointmentSet => {
                Object.values(appointmentSet).forEach(appointment => {
                    if(appointment.specialNotesSchedule.length > 250) isValid = false;
                    appointmentsToUpdate.push({
                        id: appointment.id,
                        scheduleNotes: appointment.specialNotesSchedule,
                        blocksToCreate: appointment.assignments
                    });
                    nss[parseInt(appointment.id)] = { name: appointment.studentName, completed: false, message: '' };
                });
            });
            setSubmissionStatus(nss);


            if(!isValid){
                setStatus('One or more notes fields exceeds the maximum length');
                setSubmitting(false);
                return;
            }
            // END-PREPARE DATA//

            // SEND DATA //
            for(let appointment of appointmentsToUpdate){
                const response = await updateAppointmentInstructors(appointment);
                nss[parseInt(appointment.id)].completed = true;
                nss.errored = !checkResponse(response, mounted, (resp) => nss[parseInt(appointment.id)].message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }
            // END-SEND DATA //
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setClosureSubmitting(false);
                setStatus(
                    `One or more appointments did not update properly.
                    Please be careful! Resubmitting this form will re-attempt all updates.`
                );
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [handleClose, updateAppointmentInstructors, oneSuccess]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Quick Assign</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <QuickAssignBodyFooter
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    instructorOptions={instructorOptions}
                    appointments={appointments}
                    chartMaps={chartMaps}
                    loaded={loaded}
                    submissionStatus={submissionStatus}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchAdminUsersAll,
    fetchAppointmentsDateCenter,
    fetchStudentsUserIds,
    fetchFlagsStatus,
    fetchMpCentersAll,
    updateAppointmentInstructors
})(QuickAssignModal);