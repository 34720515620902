import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button } from '../../../../components/custom-essentials';
import { Table } from '../../../../components/table';
import { getColumnsExtraTags } from './table/getColumns';
import { ExtraTagsModal } from '../../../../components/modal';

const defaultSorted = { dataField: 'value', order: 'desc' };
function ExtraTags(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);

    const { parentApiError, extraTags, refreshTags } = props;

    function handleShowModal(mode, tag){
        setModalMode(mode);
        setSelectedTag(tag);
    }
    function onSubmitCallback(changes = false){
        setModalMode(null);
        setSelectedTag(null);
        if(changes) refreshTags();
    }

    const columns = getColumnsExtraTags(handleShowModal);


    return (
        <>
            {modalMode && 
                <ExtraTagsModal
                    mode={modalMode}
                    selectedTag={selectedTag}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <div className="flex flex-row gap-x-4 items-center">
                <h2>Extra Tags</h2>
                <div>
                    <Button
                        color="lte-mpLBlue"
                        onClick={() => handleShowModal('create')}
                        style={{ marginLeft: "10px" }}
                    >
                        + New Tag
                    </Button>
                </div>
            </div>

            <br/>

            {parentApiError ? <div className="text-mpLRed">{parentApiError}</div> : 
                <Table
                    tableName="extraTags"
                    columns={columns}
                    data={extraTags}
                    defaultSorted={defaultSorted}
                    sizePerPageInitial={10}
                    sizePerPageList={[10]}
                    emptyMessage="No extra tags were found"
                />
            }
        </>
    );
}

export default connect(null, {

})(ExtraTags);