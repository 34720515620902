export function renderPhone(phone){
    if(!phone) return 'None';
    if(typeof(phone) !== 'string') phone = phone.toString();
    if(phone === '0' || phone === '0000000000') return 'None';

    if(phone.length === 12){
        const [first, second, third] = phone.split('-');
        return `(${first}) ${second}-${third}`
    } else if(phone.length === 10){
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
    } else {
        return `Unrecognized format.`
    }
}