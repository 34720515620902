import { basicPath } from '../basicPath';

export const fetchLearningPlansId = (params) => async (dispatch) => {
    const path = '/mp/learning-plans/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchLearningPlansStudent = (params) => async (dispatch) => {
    const path = '/mp/learning-plans/student';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createLearningPlan = (params) => async (dispatch) => {
    const path = '/mp/learning-plans';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateLearningPlan = (params) => async (dispatch) => {
    const path = '/mp/learning-plans';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateLearningPlanComplete = (params) => async (dispatch) => {
    const path = '/mp/learning-plan-complete';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteLearningPlan = (params) => async (dispatch) => {
    const path = '/mp/learning-plans';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}