import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { LessonsModal } from '../../../../components/modal';
import { getColumns } from './table/getColumns';

import {
    fetchMPLessonsSingleND
} from '../../../../actions-nd';

const defaultSorted = { dataField: 'default_sort_order', order: 'desc' };

function LessonsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { lessons, permissions, refreshData } = props;

    const [loading, setLoading] = useState(false);
    const [modalMode, setModalMode] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);

    const handleShowModal = useCallback((mode, lesson) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedLesson(lesson);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedLesson(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);
    const downloadFile = useCallback((fileInfo, appendLogo) => {
        const squareColor = { r: 0, g: 0, b: 0 };
        if(fileInfo.type === 'lesson'){
            squareColor.r = 215;
        } else if(fileInfo.type === 'epLesson'){
            squareColor.g = 122;
            squareColor.b = 157;
        }
        fileInfo.options = {
            appendName: false,
            appendLogo,
            appendSquare: appendLogo,
            squareColor
        };
        (async function doDownload(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            await fetchMPLessonsSingleND(fileInfo);
            if(mounted.current) setLoading(false);
        })();
    }, [loading, setLoading]);

    const columns = useMemo(() => {
        return getColumns(permissions, handleShowModal, downloadFile);
    }, [permissions, handleShowModal, downloadFile]);

    return (
        <div>
            {modalMode &&
                <LessonsModal
                    mode={modalMode}
                    selectedLesson={selectedLesson}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="lessons"
                data={lessons}
                columns={columns}
                defaultSorted={defaultSorted}
                sizePerPageInitial={50}
                emptyMessage="No lessons were found"
            />
        </div>
    );
}

export default connect(null, {

})(LessonsTable);