import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import TransitionBinderBodyFooter from './TransitionBinderBF';
import { checkResponse } from '../../form';

import {
    fetchStudentsUserIds,
    fetchMpCentersAll,
    updateStudentBinder
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function TransitionBinderModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [student, setStudent] = useState({});
    const [centerOptions, setCenterOptions] = useState(false);

    const { selectedStudentId, onSubmitCallback, fetchStudentsUserIds,
        fetchMpCentersAll, updateStudentBinder } = props;

    useEffect(() => {
        async function init(){
            const studentRes = await fetchStudentsUserIds({ userIds: [selectedStudentId] });
            const centersRes = await fetchMpCentersAll();
            const newStudent = studentRes.data?.[0] || {};
            const newCenters = centersRes.data || [];

            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));

            if(mounted.current){
                setStudent(newStudent);
                setCenterOptions(newCenterOptions);
                setLoaded(true);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            const transitionParams = {
                student: student.user_id,
                center: values.binderLocation.value
            };

            const response = await updateStudentBinder(transitionParams);

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            };
            
            if(mounted.current){
                setSubmitting(false);
                setSubmitted(true);
            }
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [handleClose, student, updateStudentBinder]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Transition Binder Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <TransitionBinderBodyFooter
                    selectedStudent={student}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsUserIds,
    fetchMpCentersAll,
    updateStudentBinder
})(TransitionBinderModal);