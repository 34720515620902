export function generateStringBlocks(fontObj, fontSize, text, textMaxWidth, additionalArgs = {}){
    // IMPORTANT NOTE //
    // Cannot just combine beforeText + text + afterText, since before/after items may require special styling
    const {
        beforeText = '',
        afterText = ''
    } = additionalArgs;

    const getWidth = (text) => fontObj.widthOfTextAtSize(text, fontSize);

    const beforeTextWidth = getWidth(beforeText);
    const afterTextWidth = getWidth(afterText);

    const stringBlocks = []; // Blocks of text with width < textMaxWidth
    let currentString = ''; // Current block we are tracking the width of

    const cleanString = text.replace(/\n/g, ' ');
    
    // Iterate through each word of text
    const words = cleanString.split(' ');
    for(let word of words){
        const currentStringPlusOne = currentString ? currentString + ` ${word}` : word; // Avoid adding an extra space at the front of each line
        let stringWidth = getWidth(currentStringPlusOne);

        if(!stringBlocks.length) stringWidth += beforeTextWidth; // Add beforeText if on first line

        const isTooWide = stringWidth > textMaxWidth;
        if(isTooWide){
            stringBlocks.push(currentString);
            currentString = word;
        } else {
            currentString = currentStringPlusOne;
        }
    }

    stringBlocks.push(currentString);
    // Final blank string will be added if the afterText causes the final line to be longer than textMaxWidth
    // This will indicate that afterText needs to be added on its own line
    if(getWidth(currentString) + afterTextWidth > textMaxWidth) stringBlocks.push('');

    return stringBlocks;
}