import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import StudentsBodyFooter from './StudentsBF';
import { getContractOption } from './helpers';
import { checkResponse } from '../../form';

import {
    fetchStudentsUserIds,
    fetchLeadsMpPermissions,
    fetchMembersMpPermissions,
    fetchContractsStudentIds,
    fetchMpCentersAll,
    updateStudentAttributes,
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function StudentForm(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [attemptingClose, setAttemptingClose] = useState(false);
    const [closureSubmitting, setClosureSubmitting] = useState(false); // Prevents form from closing while submitting (only forms with accidental closure prevention)
    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [student, setStudent] = useState({});
    const [parentOptions, setParentOptions] = useState([]);
    const [contractOptions, setContractOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const { selectedStudent, onSubmitCallback, fetchStudentsUserIds, fetchLeadsMpPermissions, fetchMembersMpPermissions,
        fetchContractsStudentIds, fetchMpCentersAll, updateStudentAttributes } = props;

    useEffect(() => {
        async function init(){
            const studentRes = await fetchStudentsUserIds({ userIds: [selectedStudent.user_id] });
            const parentsRes = await fetchMembersMpPermissions({ permissions: 'Parent' });
            const leadsRes = await fetchLeadsMpPermissions({ permissions: 'Parent' });
            const contractsRes = await fetchContractsStudentIds({ studentIds: [selectedStudent.user_id] });
            const centersRes = await fetchMpCentersAll();
            
            const newStudent = studentRes.data?.[0] || {};
            const newParents = parentsRes.data || [];
            const newLeads = leadsRes.data || [];
            const newContracts = contractsRes.data || [];
            const newCenters = centersRes.data || [];

            const newParentsFiltered = newParents.filter(p => parseInt(p.mp_active) === 1);
            const newLeadsFiltered = newLeads.filter(l => parseInt(l.mp_active) === 1);

            const newParentOptions = [{ value: -1, label: 'None selected' },
                ...newLeadsFiltered.map(p => ({ value: p.id, label: `${p.first_name} ${p.last_name} (Lead)` })),
                ...newParentsFiltered.map(p => ({ value: p.id, label: `${p.first_name} ${p.last_name}` }))];
            const newContractOptions = [{ value: -1, label: 'None selected' },
                ...newContracts.map(c => getContractOption(c))];
            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));

            if(mounted.current){
                setStudent(newStudent);
                setParentOptions(newParentOptions);
                setContractOptions(newContractOptions);
                setCenterOptions(newCenterOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes, force = false) => {
        if(!force){
            if(changes !== true) changes = false;
            if(closureSubmitting) return;
            if(!attemptingClose){
                setAttemptingClose(true);
                return;
            }
        }
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, closureSubmitting]);

    const handleSubmit = useCallback((values, actions) => {
        (async function submit(){
            const { setStatus, setSubmitting } = actions;
            if(mounted.current){
                setSubmitting(true);
                setClosureSubmitting(true);
            }
            // parent,grade,schoolYear,school,teacher,address,parentNotes,firstGuardianNotes,secondGuardianNotes,weight,
            //specialNotes,specialNotes2,subject,thisMonthStart,timeUsedThisMonth,timeLeftThisMonth,timeGivenThisMonth,
            //reserveUsedThisMonth,nextMonthStart,timeUsedNextMonth,timeLeftNextMonth,timeGivenNextMonth,
            //reserveUsedNextMonth,defaultTimePerMonth,thresholdTimePerMonth,reserveTimeLeft,neverInactive,
            //inactiveDate,originalInactiveDate,currentContract,currentCards,totalCards,onlineReady,isSwitch,binderLocation,
            const v = values;

            const studentParams = {
                userId: student.user_id,
                parent: v.parent.value,
                grade: v.grade.value,
                schoolYear: v.schoolYear.value,
                school: v.school,
                teacher: v.teacher,
                address: v.address,
                parentNotes: v.parentNotes,
                firstGuardianNotes: v.firstGuardianNotes,
                secondGuardianNotes: v.secondGuardianNotes,
                weight: v.weight.value,
                specialNotes: v.specialNotes,
                specialNotes2: v.specialNotes2,
                subject: v.subject.value,
                fingerCounting: v.fingerCounting ? 1 : 0,
                strugglesWithComputation: v.strugglesWithComputation ? 1 : 0,
                advancedStudent: v.advancedStudent ? 1 : 0,
                thisMonthStart: v.thisMonthStart,
                timeUsedThisMonth: Math.floor(v.timeUsedThisMonth * 60),
                timeLeftThisMonth: Math.floor(v.timeLeftThisMonth * 60),
                timeGivenThisMonth: Math.floor(v.timeGivenThisMonth * 60),
                reserveUsedThisMonth: Math.floor(v.reserveUsedThisMonth * 60),
                nextMonthStart: v.nextMonthStart,
                timeUsedNextMonth: Math.floor(v.timeUsedNextMonth * 60),
                timeLeftNextMonth: Math.floor(v.timeLeftNextMonth * 60),
                timeGivenNextMonth: Math.floor(v.timeGivenNextMonth * 60),
                reserveUsedNextMonth: Math.floor(v.reserveUsedNextMonth * 60),
                defaultTimePerMonth: Math.floor(v.defaultTimePerMonth * 60),
                thresholdTimePerMonth: Math.floor(v.thresholdTimePerMonth * 60),
                reserveTimeLeft: Math.floor(v.reserveTimeLeft * 60),
                inactiveDate: v.inactiveDate,
                currentContract: v.currentContract.value,
                currentCards: v.currentCards,
                totalCards: v.totalCards,
                onlineReady: v.onlineReady ? 1 : 0,
                isSwitch: v.isSwitch ? 1 : 0,
                binderLocation: v.binderLocation.value,
                monthlyReportLink: v.monthlyReportLink,
                monthlyReportText: v.monthlyReportText,
                monthlyReportSent: v.monthlyReportSent ? 1 : 0,
                updateHold: v.updateHold ? 1 : 0,
                holdStart: v.holdStart,
                holdEnd: v.holdEnd,
            };

            const response = await updateStudentAttributes(studentParams);

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            };
            
            if(mounted.current){
                setSubmitting(false);
                setClosureSubmitting(false);
                setSubmitted(true);
            }
            setTimeout(() => handleClose(true, true), 1000);
        })()
    }, [handleClose, updateStudentAttributes, student]);

    return (
        <Modal className="w-2/3" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>
                    Student Form ({student.first_name ?
                        `${student.first_name} ${student.last_name}` : 
                        `${selectedStudent.first_name} ${selectedStudent.last_name}`})
                </h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <StudentsBodyFooter
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    selectedStudent={student}
                    parentOptions={parentOptions}
                    contractOptions={contractOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsUserIds,
    fetchLeadsMpPermissions,
    fetchMembersMpPermissions,
    fetchContractsStudentIds,
    fetchMpCentersAll,
    updateStudentAttributes,
})(StudentForm);