import { basicPath } from '../basicPath';

export const fetchFlagsDaterange = (params) => async (dispatch) => {
    const path = '/mp/flags/daterange';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchFlagsIds = (params) => async (dispatch) => {
    const path = '/mp/flags/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchFlagsStatus = (params) => async (dispatch) => {
    const path = '/mp/flags/status';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createFlag = (params) => async (dispatch) => {
    const path = '/mp/flags';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateFlag = (params) => async (dispatch) => {
    const path = '/mp/flags';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteFlag = (params) => async (dispatch) => {
    const path = '/mp/flags';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}