import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, SpinnerLoader, ErrorMessage } from '../../../../components/custom-essentials';
import { DatePicker, MentionsInput, SelectSingle, checkResponse } from '../../../../components/form';
import { TooltipWrapper } from '../../../../components/display';
import { formatDateTimeFull, formatDateApi } from '../../../../components/functions';
import { Wait } from '../../../../components/wait';
import { validationSchema, renderCharacterCount } from './helpers';

import {
    fetchDailyReportDateCenter, 
    createDailyReport,
    updateDailyReport,
} from '../../../../actions';

const defaultDate = formatDateApi(new Date());

function DailyReportForm(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [submitted, setSubmitted] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [currentReport, setCurrentReport] = useState({});
    const [attemptingRevert, setAttemptingRevert] = useState(false);

    const { loading, setLoading, parentApiError, employees, employeeMap, studentUsers, extraTags, refreshTags,
        centerOptions, fetchDailyReportDateCenter, createDailyReport, updateDailyReport } = props;

    // Data fetching
    const refreshData = useCallback((selectedDate, selectedCenter) => {
        (async function refresh(){
            if(loading) return;
            setLoading(true);

            selectedDate = selectedDate || formRef.current.values.selectedDate;
            selectedCenter = selectedCenter || formRef.current.values.selectedCenter;
    
            const reportRes = await fetchDailyReportDateCenter({
                date: selectedDate,
                center: selectedCenter.value
            });
            const newCurrentReport = reportRes.data?.[0] || {};
    
            // Certain actions will be restricted if the report does not yet exist
            if(mounted.current){
                if(newCurrentReport.original_author) setCurrentReport(newCurrentReport);
                else setCurrentReport({});
                setLoading(false);
            }
        })();
    }, [loading, setLoading, fetchDailyReportDateCenter]);
    const setTags = useCallback(() => {
        if(mounted.current) setLoading(true);

        let newTagsData = [];
        studentUsers.forEach(s => newTagsData.push({ id: `u-${s.id}`, value: `${s.first_name} ${s.last_name}` }));
        employees.forEach(e => newTagsData.push({ id: `u-${e.id}`, value: `${e.first_name} ${e.last_name}` }));
        extraTags.forEach(tag => newTagsData.push({ id: `e-${tag.id}`, value: tag.value }));

        if(mounted.current){
            setTagsData(newTagsData);
            setLoading(false);
            refreshData();
        }

    }, [refreshData, setLoading, employees, studentUsers, extraTags]);
    useEffect(() => {
        setTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraTags]);

    // Form
    const showSubmittedScreen = useCallback(() => {
        async function showSubmittedMini(){
            setSubmitted(true);
            refreshData();
            await Wait(1500);
            setSubmitted(false);
        }
        refreshData();
        showSubmittedMini();
    }, [refreshData]);
    const handleSubmit = useCallback((values, actions) => {
        const { selectedDate, selectedCenter } = formRef.current.values;
        const { setStatus, setSubmitting } = actions;

        if(isNaN(new Date(selectedDate).getTime())){
            setStatus('Invalid date');
            setSubmitting(false);
            return;
        } else {
            setStatus('')
        }

        const reportParams = {
            date: selectedDate,
            center: selectedCenter.value,
            operationNotes: values.operationNotes,
            issues: values.issues,
            studentNotes: values.studentNotes,
            adminNotes: values.adminNotes,
        };
        (async function submit(){            
            let response = { status: 999 };
            
            if(currentReport.original_author){
                // UPDATE mode //
                reportParams.id = currentReport.id;
                reportParams.originalAuthor = currentReport.original_author;
                reportParams.assignedTo = values.assignToMe ? props.auth?.userId : currentReport.assigned_to;
                reportParams.updatedBy = props.auth?.userId;
                response = await updateDailyReport(reportParams);
            } else {
                // CREATE mode //
                response = await createDailyReport(reportParams);
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current){
                showSubmittedScreen();
                setSubmitting(false);
                setSubmitted(true);
            }
        })();
    }, [currentReport, props.auth, showSubmittedScreen, createDailyReport, updateDailyReport]);

    return (
        <div>
            <h2>Daily Report Form</h2>
            <br/>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedDate: defaultDate,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...'},
                    }}
                    onSubmit={() => null}
                    innerRef={formRef}
                >
                    {formik => (
                        <div className="flex flex-row gap-x-4 items-center">
                            <div className="grid grid-cols-1 gap-y-2">
                                <DatePicker
                                    id="daily-report-datepicker-1"
                                    name="selectedDate"
                                    label="Select a date"
                                    value={formik.values.selectedDate}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        refreshData(e.target.value, formik.values.selectedCenter);
                                    }}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <SelectSingle
                                    id="report-form-1"
                                    name="selectedCenter"
                                    label="Center"
                                    value={formik.values.selectedCenter}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        refreshData(formik.values.selectedDate, e.target.value);
                                    }}
                                    options={centerOptions}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
            
            <br/>

            { submitted ? <h3 className="text-mpGreen">Report Submitted!</h3> :
            parentApiError ? <div className="text-mpLRed">{parentApiError}</div> :
                <Formik
                    enableReinitialize
                    initialValues={{
                        operationNotes: currentReport.operation_notes || '',
                        issues: currentReport.issues || '',
                        studentNotes: currentReport.student_notes || '',
                        adminNotes: currentReport.admin_notes || '',
                        assignToMe: currentReport.assigned_to === props.auth?.userId ? true : false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >                
                { formik => (
                    <form onSubmit={formik.handleSubmit}>
                        {!currentReport.original_author && 
                            <>
                                <div>No report exists yet for this date and center</div>
                                <br/>
                            </>
                        }
                        {
                            currentReport.original_author &&
                            <>
                                <div>
                                    Original author: {employeeMap[currentReport.original_author] || `Unknown user (ID: ${currentReport.original_author})`}&nbsp;
                                    ({formatDateTimeFull(currentReport.date_assigned)})
                                </div>
                                <div>Assigned to: {employeeMap[currentReport.assigned_to] || `Unknown user (ID: ${currentReport.assigned_to})`}</div>
                                <br/>
                            </>
                        }
                        {
                            currentReport.updated_by &&
                            <>
                                <div>
                                    Last updated: {employeeMap[currentReport.updated_by] || `Unknown user (ID: ${currentReport.updated_by})`}&nbsp;
                                    ({formatDateTimeFull(currentReport.date_updated)})
                                </div>
                                <br/>
                            </>
                        }
                        <h3>Operation Notes</h3>
                        <MentionsInput
                            id="operationNotes"
                            as="textarea"
                            name="operationNotes"
                            placeholder="What happened at the center today?"
                            tags={tagsData}
                            triggerKeyCode={50}
                            shiftKey={true}
                            value={formik.values.operationNotes}
                            onChange={formik.handleChange}
                        />
                        {renderCharacterCount(formik.values.operationNotes.length, 2500)}
                        {formik.errors.operationNotes ? (
                            <ErrorMessage color="mpLRed">
                                {formik.errors.operationNotes}
                            </ErrorMessage>
                        ) : null}

                        <br/>

                        <h3>Issues</h3>
                        <MentionsInput
                            id="issues"
                            as="textarea"
                            name="issues"
                            placeholder="Did anything happen that shouldn't have?"
                            tags={tagsData}
                            triggerKeyCode={50}
                            shiftKey={true}
                            value={formik.values.issues}
                            onChange={formik.handleChange}
                        />
                        {renderCharacterCount(formik.values.issues.length, 2500)}
                        {formik.errors.issues ? (
                            <ErrorMessage color="mpLRed">
                                {formik.errors.issues}
                            </ErrorMessage>
                        ) : null}

                        <br/>

                        <h3>Student Notes</h3>
                        <MentionsInput
                            id="studentNotes"
                            as="textarea"
                            name="studentNotes"
                            placeholder="Any students that stood out today (good or bad)?"
                            tags={tagsData}
                            triggerKeyCode={50}
                            shiftKey={true}
                            value={formik.values.studentNotes}
                            onChange={formik.handleChange}
                        />
                        {renderCharacterCount(formik.values.studentNotes.length, 2500)}
                        {formik.errors.studentNotes ? (
                            <ErrorMessage color="mpLRed">
                                {formik.errors.studentNotes}
                            </ErrorMessage>
                        ) : null}

                        <br/>

                        <h3>Admin Notes</h3>
                        <MentionsInput
                            id="adminNotes"
                            as="textarea"
                            name="adminNotes"
                            placeholder="What admin work was done during operating hours?"
                            tags={tagsData}
                            triggerKeyCode={50}
                            shiftKey={true}
                            value={formik.values.adminNotes}
                            onChange={formik.handleChange}
                        />
                        {renderCharacterCount(formik.values.adminNotes.length, 2500)}
                        {formik.errors.adminNotes ? (
                            <ErrorMessage color="mpLRed">
                                {formik.errors.adminNotes}
                            </ErrorMessage>
                        ) : null}

                        <br/>

                        {formik.values.assignToMe &&
                            <>
                                <h5 className="text-mpGreen">Report will be assigned to you upon submission.</h5>
                                <br/>
                            </>
                        }

                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <>
                                    <div className="text-mpLRed mr-1">
                                        One or more fields needs to be corrected.
                                    </div>
                                    <br/>
                                </>
                            ) : null
                        }

                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="flex flex-row gap-x-2">
                                    { currentReport.original_author &&
                                        (
                                            formik.values.assignToMe ?
                                            <TooltipWrapper
                                                tooltipText={`Designate yourself as the person this appointment is "assigned to".
                                                This won't be saved until you submit the report.`}
                                            >
                                                <Button
                                                    color="lte-mpLRed"
                                                    onClick={() => formik.setFieldValue('assignToMe', false)}
                                                >
                                                    Don't Assign
                                                </Button>
                                            </TooltipWrapper>
                                            :
                                            <TooltipWrapper
                                                tooltipText={`Do not designate yourself as the person this appointment is "assigned to".
                                                This won't be saved until you submit the report.`}
                                            >
                                                <Button
                                                    color="lte-mpLBlue"
                                                    onClick={() => formik.setFieldValue('assignToMe', true)}
                                                >
                                                    Assign to Me
                                                </Button>
                                            </TooltipWrapper>
                                        )
                                    }
                                    { attemptingRevert ?
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setAttemptingRevert(false)}
                                        >
                                            Cancel Revert
                                        </Button>
                                        :
                                        <TooltipWrapper
                                            tooltipText="Revert form to the last saved state."
                                        >
                                            <Button
                                                color="lte-mpLRed"
                                                onClick={() => setAttemptingRevert(true)}
                                            >
                                                Revert Changes
                                            </Button>
                                        </TooltipWrapper>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-row gap-x-2 ml-auto">
                                { attemptingRevert && 
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={() => {
                                            formik.handleReset();
                                            setAttemptingRevert(false);
                                        }}
                                    >
                                        Confirm Revert
                                    </Button>
                                }
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={refreshTags}
                                >
                                    Refresh Tags
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                >
                                    Submit Report
                                </Button>
                            </div>
                        </div>

                        <br/>

                        {formik.status && !formik.isSubmitting ? 
                            <>
                                <br/>
                                <div className="text-mpLRed" style={{ marginRight: "2rem" }}>
                                    {formik.status}
                                </div>
                            </>
                        : null}
                        {formik.isSubmitting &&
                            <div className="flex flex-row">
                                <SpinnerLoader/> Submitting...
                            </div>
                        }
                    </form>
                )}
                </Formik>
            }
        </div>
    );
}

export default connect(null, {
    fetchDailyReportDateCenter, 
    createDailyReport,
    updateDailyReport,
})(DailyReportForm);