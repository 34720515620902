import React from 'react';
import * as Yup from 'yup';

import { Modal, ModalBodyFooter, Button, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { TooltipWrapper } from '../../display';
import { FormikControl, SelectSingle, Check, Switch } from '../../form';
import { formatDateTime } from '../../functions';

const letterMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export const validationSchema = Yup.object().shape({
    level: Yup.string()
        .max(5, 'Max 5 characters')
        .required('Level is required'),
    name: Yup.string()
        .max(50, 'Max 50 characteres')
        .required('Name is required'),
    notes: Yup.string()
        .max(500, 'Max 500 characters'),
    nQuestions: Yup.number()
        .min(1, 'Min 1')
        .max(200, 'Max 200')
        .typeError('Number of Questions must be a number')
        .required('Number of Questions is required'),
    fileName: Yup.string()
        .required('File Name is required')
        .max(20, 'Max 20 characters'),
    answerKeyPath: Yup.string()
        .max(300, 'Max 300 characters'),
});

export function renderMeta(assessmentTemplate){
    let created = assessmentTemplate.date_created ? (
        <div>Created on {formatDateTime(assessmentTemplate.date_created)}</div>
    ) : null;
    let updated = assessmentTemplate.date_updated ? (
        <div>Updated on {formatDateTime(assessmentTemplate.date_updated)}</div>
    ) : null;

    if(created || updated){
        return(
            <div className="col">
                <br/>
                {created}
                {updated}
            </div>
        )
    } else return null;
}

function renderConfirmation(formik){
    const { name, level, version, nQuestions, questionTemplates } = formik.values;

    const relevantQuestions = {};
    for(let i = 1; i <= nQuestions; i++){
        relevantQuestions[i] = questionTemplates[i];
    }
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h5>Please confirm your selections.</h5>

                <div className="h-4 clear-both"/>

                <h2>Assessment</h2>
                <div className="h-2 clear-both"/>

                <div className="grid grid-cols-1">
                    <h5>Name: {name}</h5>
                    <h5>Level: {level}</h5>
                    <h5>Version: {version}</h5>
                    <h5>Total Questions: {nQuestions}</h5>
                </div>

                <div className="h-4 clear-both"/>
                <hr/>
                <div className="h-4 clear-both"/>

                <h2>Questions</h2>

                <div className="h-4 clear-both"/>

                {Object.keys(relevantQuestions).map(q => {
                    const { onIncorrect1, onIncorrect2, onIncorrect3 } = questionTemplates[q];
                    const row1 = <div>On Incorrect (1): {onIncorrect1.label}</div>;
                    const row2 = onIncorrect2.value === -1 ? null : <div>On Incorrect (2): {onIncorrect2.label}</div>;
                    const row3 = onIncorrect3.value === -1 ? null : <div>On Incorrect (3): {onIncorrect3.label}</div>;
                    return (
                        <div key={`assessment-templates-confirm-question${q}`}>
                            <h4>Question {q}</h4>
                            <div className="grid grid-cols-1 ml-8">
                                {row1}
                                {row2}
                                {row3}
                            </div>
                            <br/>
                        </div>
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <div className="flex flex-row gap-x-2 flex-wrap">
                    {formik.status && !formik.isSubmitting ? 
                        <div className="text-mpLRed">
                            {formik.status}
                        </div> : null
                    }
                    <Button
                        color="lte-mpLRed"
                        onClick={() => {
                            formik.setFieldValue('firstConfirm', false);
                            formik.setSubmitting(false);
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        color="lte-mpLBlue"
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}

function checkFieldsValid(formik){
    const viewMode = formik.values.viewMode;
    if(viewMode === 1){
        if(Object.keys(formik.errors).length){
            formik.setStatus('One or more fields needs to be corrected.');
            return;
        } else {
            formik.setStatus('');
            formik.setFieldValue('viewMode', 2);
            return;
        }
    } else if(viewMode === 2){
        const { questionTemplates, nQuestions } = formik.values;
    
        let isValid = true;
        for(let i = 1; i <= nQuestions; i++){
            const { onIncorrect1, onIncorrect2, onIncorrect3 } = questionTemplates[i];
            const val1 = onIncorrect1.value;
            const val2 = onIncorrect2.value;
            const val3 = onIncorrect3.value;

            if(val1 === -1 || val2 === val1 || val3 === val1 || (val2 === val3 && val2 !== -1 && val3 !== -1)){
                isValid = false;
                break;
            }
        }
    
        if(!isValid){
            formik.setStatus('One or more fields needs to be corrected.');
            return;
        } else {
            formik.setStatus('');
            formik.setFieldValue('firstConfirm', true);
        }
    }
}

export function renderBody(formik, handleClose, lessonOptions){
    if(formik.values.firstConfirm) return renderConfirmation(formik);

    const viewMode = formik.values.viewMode;
    if(viewMode === 1){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <>
                        <div className="flex flex-row gap-x-4 items-start">
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h4>Level</h4>
                                <FormikControl
                                    id="assessment-template-level"
                                    name="level"
                                    value={formik.values.level}
                                    onChange={formik.handleChange}
                                    placeholder="Required. Max 5 characters."
                                />
                                {formik.errors.level && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.level}
                                    </ErrorMessage>
                                )}
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h4>Name</h4>
                                <FormikControl
                                    id="assessment-template-name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder="Required. Max 50 characters."
                                />
                                {formik.errors.name && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.name}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>

                        <br/>

                        <div className="flex flex-row gap-x-4 items-start">
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h4># Questions</h4>
                                <FormikControl
                                    id="assessment-template-nQuestions"
                                    name="nQuestions"
                                    value={formik.values.nQuestions}
                                    onChange={formik.handleChange}
                                    placeholder="Required. Max 200."
                                />
                                {formik.errors.nQuestions && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.nQuestions}
                                    </ErrorMessage>
                                )}
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h4>File Name</h4>
                                <FormikControl
                                    id="assessment-template-fileName"
                                    name="fileName"
                                    value={formik.values.fileName}
                                    onChange={formik.handleChange}
                                    placeholder="Server file name. Required. Max 20 characters. Do not include .pdf"
                                />
                                {formik.errors.fileName && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.fileName}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>

                        <br/>

                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                <h4>Notes</h4>
                                <FormikControl
                                    id="learning-plan-notes"
                                    name="notes"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    placeholder="Max. 500 characters."
                                    as="textarea"
                                />
                                {formik.errors.notes && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.notes}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>

                        <br/>

                        <div className="grid grid-cols-1 gap-y-2 w-full">
                            <div className="flex flex-row gap-x-4 items-end">
                                <TooltipWrapper
                                    tooltipText={
                                        <>
                                            <div>
                                                Check true if a file has been uploaded for this template.
                                            </div>
                                        </>
                                    }
                                >
                                    <Check
                                        id="assessment-templates-hasFile"
                                        name="hasFile"
                                        color="mpLRed"
                                        label={<div className="text-mpLBlue">File?</div>}
                                        checked={formik.values.hasFile}
                                        onChange={formik.handleChange}
                                    />
                                </TooltipWrapper>
                                <TooltipWrapper
                                    tooltipText={
                                        <>
                                            <div>
                                                Check true if an answer key has been uploaded for this template.
                                            </div>
                                        </>
                                    }
                                >
                                    <Check
                                        id="assessment-templates-hasAnswerKey"
                                        name="hasAnswerKey"
                                        color="mpLRed"
                                        label={<div className="text-mpLBlue">Answer Key?</div>}
                                        checked={formik.values.hasAnswerKey}
                                        onChange={formik.handleChange}
                                    />
                                </TooltipWrapper>
                                <div className="ml-auto">
                                    <Check
                                        id="assessment-templates-active"
                                        name="active"
                                        color="mpLRed"
                                        label="Active"
                                        checked={formik.values.active}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed">
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => checkFieldsValid(formik)}
                            disabled={formik.isSubmitting}
                        >
                            Next
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(viewMode === 2){
        const numList = [];
        for(let i = 1; i <= formik.values.nQuestions; i++) numList.push(i);
        
        const questionTemplates = formik.values.questionTemplates;

        const additionalFields = (
            <div className="max-h-[80vh] overflow-y-auto">
                {numList.map(q => {
                    const questionGroup = questionTemplates[q];
                    const val1 = questionGroup.onIncorrect1.value;
                    const val2 = questionGroup.onIncorrect2.value;
                    const val3 = questionGroup.onIncorrect3.value;
    
                    const firstIsRequired = val1 === -1 ?  <div className="text-mpLRed">First lesson is required</div> : null;
                    const secondIsRepeat = val2 === -1 ? null : val2 === val1 ? 
                        <div className="text-mpLRed">Second lesson is the same as first lesson</div> : 
                        val2 === val3 ? 
                        <div className="text-mpLRed">Second lesson is the same as third lesson</div> : null;
                    const thirdIsRepeat = val3 === -1 ? null : val3 === val1 ? 
                        <div className="text-mpLRed">Third lesson is the same as first lesson</div> : 
                        val3 === val2 ? 
                        <div className="text-mpLRed">Third lesson is the same as second lesson</div> : null;
                    return (
                        <div key={`assessment-templates-additional-fields-${q}`}>
                            <h4>Question {formik.values.letterMode ? letterMap[q % 52 - 1] : q}</h4>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <TooltipWrapper
                                        tooltipText="Lesson to assign if this questions is missed"
                                    >
                                        <h5 className="text-mpLBlue">On Incorrect - 1</h5>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id={`assessment-templates-additional-question${q}-onIncorrect1`}
                                        name={`question${q}_onIncorrect1`}
                                        value={questionGroup.onIncorrect1}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            questionTemplates[q].onIncorrect1 = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                        options={lessonOptions}
                                    />
                                    {firstIsRequired}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <TooltipWrapper
                                        tooltipText="Lesson to assign if this questions is missed"
                                    >
                                        <h5 className="text-mpLBlue">On Incorrect - 2</h5>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id={`assessment-templates-additional-question${q}-onIncorrect2`}
                                        name={`question${q}_onIncorrect2`}
                                        value={questionGroup.onIncorrect2}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            questionTemplates[q].onIncorrect2 = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                        options={lessonOptions}
                                    />
                                    {secondIsRepeat}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <TooltipWrapper
                                        tooltipText="Lesson to assign if this questions is missed"
                                    >
                                        <h5 className="text-mpLBlue">On Incorrect - 3</h5>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id={`assessment-templates-additional-question${q}-onIncorrect3`}
                                        name={`question${q}_onIncorrect3`}
                                        value={questionGroup.onIncorrect3}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            questionTemplates[q].onIncorrect3 = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                        options={lessonOptions}
                                    />
                                    {thirdIsRepeat}
                                </div>
                            </div>
                            <br/>
                        </div>
                    );
                })}
            </div>
        );
        
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <div className="flex flex-row gap-x-4 items-end">
                        <Switch
                            id="assessment-templates-letterMode"
                            name="letterMode"
                            label="Letter Mode"
                            color="mpLRed"
                            checked={formik.values.letterMode}
                            onChange={formik.handleChange}
                        />
                    </div>
                    {additionalFields}
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed">
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={() => formik.setFieldValue('viewMode', 1)}
                        >
                            Back
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => checkFieldsValid(formik)}
                            disabled={formik.isSubmitting || Object.keys(formik.errors).length}
                        >
                            Review
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    }
}