import React, { useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Chart from 'react-apexcharts';

import { Modal, Button, ModalBodyFooter, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { getSeriesOptions } from '../../../app/pages/appointments/scheduling/overview/appointments/getSeries';
import { formatDate, getTimeObject, formatDateFull } from '../../functions';
import { checkResponse, TimePicker, Check } from '../../form';
import { TooltipWrapper } from '../../display';
import { editorValidationSchema, editorGetInitialValues, editorGetBlockFields, editorRenderSubmitting } from './helpers';

import {
    createAvailability,
    updateAvailability,
    deleteAvailability,
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

const defaultAvailValues = {
    open: 840,
    lastStart: 1080,
    close: 1140
};

function AvailabilityBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedDate, selectedCenter, relevantAvailability, availabilityBlocks, onSubmitCallback,
        createAvailability, updateAvailability, deleteAvailability } = props;

    const [showModal, setShowModal] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    // Renamed because formik uses 'setSubmitting'
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);
    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            // Form validation for the variable portion
            let allValid = true;
            Object.entries(values).forEach(([key, value]) => {
                if(!key.includes('block') || key.includes('quickset')) return;
                else if(value > 100 || isNaN(value) || value < 0) allValid = false;
            });
            if(!allValid) {
                setStatus('Block entries must be a number between 0 and 100');
                setSubmitting(false);
                return;
            };

            if(mounted.current) setSubmitting(true);
            
            // newSubmissionStatus to track the submission process
            const nss = { errored: false, completed: false }; // name, completed, message
            if((mode === 'create' || mode === 'edit') && !showDelete){
                const open = values.selectedOpenTime.raw;
                const close = values.selectedCloseTime.raw;

                const availabilityParams = {
                    date: selectedDate.api,
                    center: selectedCenter.value,
                    isClosed: values.isClosed ? 1 : 0,
                    openTime: open,
                    lastStart: values.selectedLastStartTime.raw,
                    closeTime: close,
                }

                const availabilityBlocks = [];
                for(let i = open; i < close; i += 30){
                    const isOpen = values[`block${i}isOpen`] && !availabilityParams.isClosed;
                    
                    availabilityBlocks.push({
                        date: selectedDate.api,
                        center: selectedCenter.value,
                        time: i,
                        students: isOpen ? values[`block${i}maxS`] || 0 : 0,
                        seats: isOpen ? values[`block${i}maxW`] || 0 : 0,
                        startStudents: isOpen ? values[`block${i}msS`] || 0 : 0,
                        startSeats: isOpen ? values[`block${i}msW`] || 0 : 0,
                        isClosed: isOpen ? 0 : 1,
                    });
                }

                if(mode === 'create'){
                    nss.createAvailability = { name: 'Create Availability', completed: false, message: '' };
                    setSubmissionStatus(nss);

                    const availabilityParamsGroup = {
                        availability: availabilityParams,
                        blocks: availabilityBlocks
                    };

                    const caResponse = await createAvailability(availabilityParamsGroup);
                    nss.createAvailability.completed = true;
                    nss.errored = !checkResponse(caResponse, mounted, (resp) => nss.createAvailability.message = resp) || nss.errored;
                    setSubmissionStatus({...nss});
                } else if(mode === 'edit'){
                    nss.updateAvailability = { name: 'Update Availability', completed: false, message: '' };
                    setSubmissionStatus({...nss});

                    availabilityParams.id = relevantAvailability.id;
                    const availabilityParamsGroup = {
                        availability: availabilityParams,
                        blocks: availabilityBlocks
                    };

                    const uaResponse = await updateAvailability(availabilityParamsGroup);
                    nss.updateAvailability.completed = true;
                    nss.errored = !checkResponse(uaResponse, mounted, (resp) => nss.updateAvailability.message = resp) || nss.errored;
                    setSubmissionStatus({...nss});
                }
            } else if(showDelete){
                nss.deleteAvailability = { name: 'Deleting Availability', completed: false, message: ''};
                setSubmissionStatus({...nss});

                const daResponse = await deleteAvailability({ id: relevantAvailability.id });
                nss.deleteAvailability.completed = true;
                nss.errored = !checkResponse(daResponse, mounted, (resp) => nss.deleteAvailability.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            } else {
                console.error(`We shoudln't have gotten to this point`);
            }
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus('One or more errors occurred during submission');
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true), 500);
        }
        submit();
    }, [mode, handleClose, showDelete, relevantAvailability, selectedCenter, selectedDate,
        createAvailability, updateAvailability, deleteAvailability, oneSuccess]);

    if(submitted){
        return (
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <h2>Availability Manager</h2>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if(showDelete) {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header>
                            <h2>Availability Manager</h2>
                        </Modal.Header>
                        {formik.isSubmitting ? 
                            editorRenderSubmitting(submissionStatus, formik.setSubmitting) :
                            <Modal.BodyFooter><ModalBodyFooter>
                                <Modal.Body>
                                    <h5>Delete this availability and its blocks?</h5>
                                    <div className="col">
                                        <div>Center: {relevantAvailability.centerName}</div>
                                        <div>Date: {formatDate(relevantAvailability.date)}</div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="flex flex-row gap-x-2 flex-wrap">
                                        {formik.isSubmitting &&
                                            <ModalProcessing/>
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed">
                                                {formik.status}
                                            </div> : null
                                        }
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setShowDelete(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={formik.handleSubmit}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </ModalBodyFooter></Modal.BodyFooter>
                        }
                    </Modal>
                )}
            </Formik>
        );
    } else {
        function getChartOptions(formik){
            const values = formik.values;
            if(!values) return null;
        
            const availabilityBlocks = [];
            for(let i = values.selectedOpenTime.raw; i < values.selectedCloseTime.raw; i += 30){
                const isOpen = values[`block${i}isOpen`];
        
                availabilityBlocks.push({
                    time: i,
                    students: isOpen ? values[`block${i}maxS`] : 0,
                    seats: isOpen ? values[`block${i}maxW`] : 0,
                    start_students: isOpen ? values[`block${i}msS`] : 0,
                    start_seats: isOpen ? values[`block${i}msW`] : 0,
                });
            }
        
            const appointments = [];
            const switches = {
                maxW: true,
                maxS: true,
                maxStartW: true,
                maxStartS: true,
            };
            const returnVals = getSeriesOptions(availabilityBlocks,
                appointments, switches);
        
            returnVals.options.title.text = '';
        
            return { series: returnVals.series, options: returnVals.options };
        };
        return(
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <h2>Availability Manager</h2>
                </Modal.Header>
                <Modal.BodyFooter><Formik
                    enableReinitialize
                    initialValues={{
                        selectedOpenTime: getTimeObject(relevantAvailability?.open_time || defaultAvailValues.open),
                        selectedLastStartTime: getTimeObject(relevantAvailability?.last_start || defaultAvailValues.lastStart),
                        selectedCloseTime: getTimeObject(relevantAvailability?.close_time || defaultAvailValues.close),
                        isClosed: parseInt(relevantAvailability?.is_closed) === 0 ? false : true,
                        ...editorGetInitialValues(relevantAvailability, availabilityBlocks, defaultAvailValues),
                        quicksetMaxS: 0,
                        quicksetMaxW: 0,
                        quicksetMsS: 0,
                        quicksetMsW: 0
                    }}
                    validationSchema={editorValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        formik.isSubmitting ? editorRenderSubmitting(submissionStatus, formik.setSubmitting) :
                        <ModalBodyFooter>
                            <Modal.Body>
                                <h4><u>Set Availabilities</u></h4>
                                <div>{selectedCenter.label}, {formatDateFull(selectedDate.date)}</div>
                                {!Object.keys(relevantAvailability).length ?
                                    <div className="text-mpOrange">
                                        <div>
                                            No availability is currently set for this date.
                                        </div>
                                    </div> : null
                                }
                            
                                <div className="h-2 clear-both"/>

                                <Check
                                    id="availability-editor-open-for-day"
                                    name="isClosed"
                                    label="Center Closed"
                                    color="mpLRed"
                                    checked={formik.values.isClosed}
                                    onChange={formik.handleChange}
                                />

                                <div className="h-2 clear-both"/>

                                { !formik.values.isClosed && 
                                    <>
                                        <div className="flex flex-row gap-x-4 items-end">
                                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                                <h4>Open</h4>
                                                <TimePicker
                                                    id="availability-editer-open-time"
                                                    name="selectedOpenTime"
                                                    value={formik.values.selectedOpenTime?.formatted24 || ''}
                                                    onChange={formik.handleChange}
                                                    step={1800} // 30 min
                                                />
                                                {formik.errors.selectedOpenTime ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.selectedOpenTime}
                                                    </ErrorMessage>
                                                ) : null}
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                                <TooltipWrapper
                                                    tooltipText="The latest time at which an appointment can start"
                                                >
                                                    <h4 className="text-mpLBlue">Last Start</h4>
                                                </TooltipWrapper>
                                                <TimePicker
                                                    id="availability-editer-last-start"
                                                    name="selectedLastStartTime"
                                                    value={formik.values.selectedLastStartTime?.formatted24 || ''}
                                                    onChange={formik.handleChange}
                                                    step={1800} // 30 min
                                                />
                                                {formik.errors.selectedLastStartTime ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.selectedLastStartTime}
                                                    </ErrorMessage>
                                                ) : null}
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                                <h4>Close</h4>
                                                <TimePicker
                                                    id="availability-editer-close-time"
                                                    name="selectedCloseTime"
                                                    value={formik.values.selectedCloseTime?.formatted24 || ''}
                                                    onChange={formik.handleChange}
                                                    step={1800} // 30 min
                                                />
                                                {formik.errors.selectedCloseTime ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.selectedCloseTime}
                                                    </ErrorMessage>
                                                ) : null}
                                            </div>
                                        </div>
                                        <br/>
                                        {formik.errors.timeCheck && 
                                            <div className="text-mpLRed">
                                                {formik.errors.timeCheck}
                                            </div>
                                        }

                                        <>
                                            <hr/>
                                            <div className="h-2 clear-both"/>

                                            <h4><u>Set Blocks</u></h4>
                                            <br/>

                                            {editorGetBlockFields(formik)}
    
                                            {/* Need to use the following workaround to get the */}
                                            {/* chartOptions to re-calculate whenever the form changes */}
                                            {[1].map(i => {
                                                const chartOptions = getChartOptions(formik);
                                                return (
                                                    <div key="this-map-function-was-needed-to-declare-the-variable">
                                                        {chartOptions && 
                                                        <Chart
                                                            series={chartOptions.series}
                                                            options={chartOptions.options}
                                                            type="line"
                                                        />}
                                                    </div>
                                                );    
                                            })}
                                        </>
                                    </>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="grid grid-cols-1 gap-y-2 w-full flex-wrap">
                                    <div className="flex flex-row gap-x-2 items-end">
                                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                            (
                                                <div className="text-mpLRed">
                                                    One or more fields needs to be corrected.
                                                </div>
                                            ) : null
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed">
                                                {formik.status}
                                            </div> : null
                                        }
                                    </div>
                                    <div className="flex flex-row gap-x-2 items-end">
                                        <div className="mr-auto flex flex-row gap-x-2 flex-wrap">
                                            <Button
                                                color="hol-mpLRed"
                                                onClick={() => setShowDelete(true)}
                                                disabled={!Object.keys(relevantAvailability).length}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                color="lte-mpLRed"
                                                disabled={formik.isSubmitting}
                                                onClick={handleClose}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={formik.handleSubmit}
                                            disabled={formik.isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    )}
                </Formik></Modal.BodyFooter>
            </Modal>
        );
    }
}

export default connect(null, {
    createAvailability,
    updateAvailability,
    deleteAvailability,
})(AvailabilityBodyFooter);