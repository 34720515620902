import * as Yup from 'yup';
import { FormikControl, SelectSingle, Check } from '../../form';

import { getLessons, checkLessonDatesValid } from '../../functions';
import { Modal, ModalBodyFooter, Button, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { renderLpi, modifyLessonList } from './helpers2';
import { TooltipWrapper } from '../../display';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .max(50, 'Max 50 characters'),
    notes: Yup.string()
        .max(1000, 'Max 1000 characters'),
    sortOrder: Yup.number()
        .integer('Must be an integer')
        .min(0, 'Minimum 0')
        .max(30000, 'Maximum 30,000')
        .required('Sort Order is required'),
});

export function getInitAssessmentOption(assessment, assessmentOptions){
    if(!assessment) return { value: -1, label: 'No Assessment' };
    return assessmentOptions.find(a => a.value === assessment) || { value: -1, label: `Assessment may have been deleted (ID: ${assessment})`}
}

function checkFieldsValid(formik, mode, lessons){
    const viewMode = formik.values.viewMode;

    if(viewMode === 1){
        if(Object.keys(formik.errors).length){
            formik.setStatus('One or more fields needs to be corrected.');
            return;
        } else {
            if(mode === 'create'){
                let newLearningPlanItems = [];
                if(formik.values.linkedAssessment.value !== -1){
                    const { lessonsMissed } = getLessons(formik.values.linkedAssessment.obj.questions, lessons);
                    let sortIndex = 1;
                    let uniqueId = 0;
                    lessonsMissed.forEach(l => {
                        newLearningPlanItems.push({
                            id: parseInt(l.id),
                            uniqueId: uniqueId++,
                            lessonName: `${l.lesson_id}: ${l.name}`,
                            dateAssigned: '',
                            dateCompleted: '',
                            sortOrder: sortIndex++
                        });
                    });
                }

                formik.setFieldValue('learningPlanItems', newLearningPlanItems);
            }

            formik.setStatus('');
            formik.setFieldValue('viewMode', 2);
            return;
        }
    } else if(viewMode === 2){
        let isValid = true;
        const lpItems = formik.values.learningPlanItems;
        for(let i = 0; i < lpItems.length; i++){
            const lpi = lpItems[i];
            const assignedDate = lpi.dateAssigned || '';
            const completedDate = lpi.dateCompleted || '';
            
            if(!checkLessonDatesValid(assignedDate, completedDate)){
                isValid = false;
                break;
            }
        }

        if(!isValid){
            formik.setStatus('One or more fields needs to be corrected. Uncheck "Hide Completed Lessons" and ensure that all dates are valid.');
            return;
        } else {
            formik.handleSubmit();
        }
    }
}


export function renderBody(formik, mode, handleClose, assessmentOptions, lessons, lessonOptions){
    const viewMode = formik.values.viewMode;
    if(viewMode === 1){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <div className="row no-mar">
                        <h3><u>Learning Plan for {formik.values.student.label}</u></h3>
                    </div>
                    <br/>
                    <div className="flex flex-row gap-x-4">
                        <div className="w-1/2">
                            <h4>LP Name</h4>
                            <div className="h-2 clear-both"/>
                            <FormikControl
                                id="learning-plan-name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                placeholder="Required. Max 50 characters."
                            />
                            {formik.errors.name && (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.name}
                                </ErrorMessage>
                            )}
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                            <h4>Linked Assessment</h4>
                            <SelectSingle
                                id="learning-plan-linked-assessment"
                                name="linkedAssessment"
                                value={formik.values.linkedAssessment}
                                onChange={(e) => {
                                    const assessment = e.target.value;
                                    const newValues = { ...formik.values };
                                    newValues.linkedAssessment = assessment;
                                    newValues.name = assessment.obj?.name || '';
                                    formik.setValues(newValues);
                                }}
                                options={assessmentOptions}
                                disabled={mode !== 'create'}
                            />
                            {mode !== 'create' ?
                                <div className="text-mpOrange">
                                    Assessment can't be modified.
                                </div>
                            : <div className="text-mpOrange">
                                    Warning! Assessment can't be changed later.
                                </div>
                            }
                            {formik.errors.linkedAssessment && (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.linkedAssessment}
                                </ErrorMessage>
                            )}
                        </div>
                    </div>

                    <br/>

                    <div className="flex flex-row gap-x-4">
                        <div className="grid grid-cols-1 gap-y-2 w-full">
                            <h4>Notes</h4>
                            <FormikControl
                                id="learning-plan-notes"
                                name="notes"
                                value={formik.values.notes}
                                onChange={formik.handleChange}
                                placeholder="Max. 1000 characters."
                                as="textarea"
                            />
                            {formik.errors.notes && (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.notes}
                                </ErrorMessage>
                            )}
                        </div>
                    </div>

                    <br/>

                    <div className="flex flex-row gap-x-4 items-start">
                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                            <TooltipWrapper
                                tooltipText={"This will affect the sorting of LPs on the LP Manager and Details Form (smallest first)"}
                            >
                                <h4 className="text-mpLBlue">Sort Order</h4>
                            </TooltipWrapper>
                            <FormikControl
                                id="learning-plan-sortOrder"
                                name="sortOrder"
                                value={formik.values.sortOrder}
                                onChange={formik.handleChange}
                                placeholder="Min: 0, Max: 30,000"
                            />
                            {formik.errors.sortOrder && (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.sortOrder}
                                </ErrorMessage>
                            )}
                        </div>
                        <div className="w-1/4">
                            <div className="h-8 clear-both"/>
                            <TooltipWrapper
                                tooltipText="Check if this is the student's second time doing a LP at this level. Pages will be notated with a blue corner box to indicate half stamps should be given for each page."
                            >
                                <Check
                                    id="learning-plan-review"
                                    name="isReview"
                                    color="mpLRed"
                                    label={<div className="text-mpLBlue">Is Review?</div>}
                                    checked={formik.values.isReview}
                                    onChange={formik.handleChange}
                                />
                            </TooltipWrapper>
                        </div>
                        <div className="w-1/4">
                            <div className="h-8 clear-both"/>
                            <Check
                                id="learning-plan-active"
                                name="active"
                                color="mpLRed"
                                label={`Active?`}
                                checked={formik.values.active}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed">
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={() => handleClose(true, true)}
                        >
                            Close
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => checkFieldsValid(formik, mode, lessons)}
                            disabled={formik.isSubmitting || formik.values.name === ''}
                        >
                            Next
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(viewMode === 2){
        const currentLessons = { };
        currentLessons[-1] = true; // Block out default option
        formik.values.learningPlanItems.forEach(lpi => currentLessons[parseInt(lpi.id)] = true);
        const unassignedLessonOptions = [];
        const assignedLessonOptions = [];
        lessonOptions.forEach(l => {
            if(!currentLessons[parseInt(l.value)]) unassignedLessonOptions.push(l);
            else assignedLessonOptions.push(l);
        });

        const lessonsToRender = formik.values.learningPlanItems.filter(lpi => {
            return !(formik.values.hideCompleted && lpi.originallyMarkedComplete);
        });
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h3>Learning Plan for {formik.values.student.label}</h3>

                    <div className="h-4 clear-both"/>

                    <div className="flex flex-row gap-x-4 items-center">
                        <div className="ml-4">
                            <h4>LP Name: {formik.values.name}</h4>
                        </div>
                        <div className="ml-8">
                            <Check
                                id="learning-plan-hideCompleted"
                                name="hideCompleted"
                                color="mpLRed"
                                label={`Hide Completed Lessons`}
                                checked={formik.values.hideCompleted}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>

                    <div className="h-4 clear-both"/>
                    <hr/>
                    <div className="h-4 clear-both"/>

                    <div className="max-h-[70vh] overflow-y-auto">
                        {lessonsToRender.map(lpi => {
                            return renderLpi(lpi, formik, lessonsToRender);
                        })}
                    </div>

                    <br/>

                    <div className="flex flex-row gap-x-4">
                        <h4>Add Lesson:</h4>
                        <div className="ml-4 w-[30rem]">
                            <SelectSingle
                                id="learning-plan-add-lesson"
                                name="addLesson"
                                value={{ value: -1, label: 'Please select...' }}
                                onChange={(e) => {
                                    modifyLessonList(formik, e.target.value.obj, 'add')
                                }}
                                options={unassignedLessonOptions}
                            />
                        </div>
                    </div>

                    <br/>

                    <div className="flex flex-row gap-x-4">
                        <h4>Reassign Lesson:</h4>
                        <div className="ml-4 w-[30rem]">
                            <SelectSingle
                                id="learning-plan-add-lesson"
                                name="addLesson"
                                value={{ value: -1, label: 'Please select...' }}
                                onChange={(e) => {
                                    modifyLessonList(formik, e.target.value.obj, 'add')
                                }}
                                options={assignedLessonOptions}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed">
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={() => formik.setFieldValue('viewMode', 3)}
                        >
                            Back
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            disabled={formik.isSubmitting}
                            onClick={() => checkFieldsValid(formik, mode)}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(viewMode === 3){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h5>Go back to the first page? <span className="text-mpLRed">All changes will be lost.</span></h5>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        <Button
                            color="lte-mpLBlue"
                            disabled={formik.isSubmitting}
                            onClick={() => formik.setFieldValue('viewMode', 2)}
                        >
                            No
                        </Button>
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={() => formik.setFieldValue('viewMode', 1)}
                        >
                            Yes
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBodyFooter>
        )
    }
}