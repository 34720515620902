import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumns(handleShowModal, downloadFile){
    return [
        {
            dataField: 'level',
            text: 'Level',
            sort: true
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true
        },
        {
            dataField: 'n_questions',
            text: '# Questions',
        },
        {
            dataField: 'filePath',
            text: 'Download',
            formatter: (row) => {
                if(row.has_file){
                    return(
                        <TooltipWrapper
                            tooltipText="View/Download this assessment"
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => downloadFile(row, 'assessment', true)}
                            >
                                <SVGIcon fileName="print"/>
                            </Button>
                        </TooltipWrapper>
                    );
                } else {
                    return(
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            disabled
                            onClick={() => null}
                        >
                            <TooltipWrapper
                                tooltipText="No link provided"
                            >
                                <SVGIcon fileName="unlink"/>
                            </TooltipWrapper>
                        </Button>
                    );
                }
            }
        },
        {
            dataField: 'answer_key_path',
            text: 'Answer Key',
            formatter: (row) => {
                if(row.has_answer_key){
                    return(
                        <TooltipWrapper
                            tooltipText="View/Download assessment answer key"
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => downloadFile(row, 'assessmentAnswerKey', false)}
                            >
                                <SVGIcon fileName="answer-key"/>
                            </Button>
                        </TooltipWrapper>
                    );
                } else {
                    return(
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            disabled
                            onClick={() => null}
                        >
                            <TooltipWrapper
                                tooltipText="No link provided"
                            >
                                <SVGIcon fileName="unlink"/>
                            </TooltipWrapper>
                        </Button>
                    );
                }
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(a) - parseInt(b)) * swap;
            },
            formatter: (row) => {
                const isActive = parseInt(row.active);
                if(isActive) return <Label color="mpGreen">Yes</Label>;
                else return <Label color="mpLRed">No</Label>;
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit this assessment template`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete this assessment template`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}