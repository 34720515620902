import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    selectedDate: Yup.mixed().test(
        'dateIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
    selectedStudent: Yup.mixed().test(
        'studentIsSeleceted',
        'Select a valid student',
        (value) => value?.value !== -1
    ),
    selectedType: Yup.mixed().test(
        'typeIsSelected',
        'Select a valid type',
        (value) => value?.value !== -1
    ),
    amount: Yup.number()
        .typeError('Amount must be a number')
        .min(0, 'Min 0')
        .max(1000, 'Max 1000')
        .required('Amount is required'),
    notes: Yup.string()
        .max(100, 'Max 100 characters'),
});

export function getInitStudentOption(studentId, studentOptions){
    if(!studentId) return { value: -1, label: 'Please select a student...' };
    return studentOptions.find(s => s.value === studentId) || { value: -1, label: `Unknown student (UID ${studentId})`};
}

export const typeOptions = [
    'Give Stamps',
    'Give Cards',
    'Spend Cards',
    'Ungive Stamps',
    'Ungive Cards',
    'Give Total Cards',
    'Take Total Cards',
].map(i => ({ value: i, label: i }));
export function getInitTypeOption(type){
    if(!type) return typeOptions[0];
    return typeOptions.find(t => t.value === type) || { value: -1, label: `Invalid type (${type})` };
}