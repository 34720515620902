import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Button } from '../../../../components/custom-essentials';
import { CSVExport, renderAppointmentsAsPDF } from '../../../../components/export';
import { TooltipWrapper } from '../../../../components/display';
import { StampsModal, QuickAssignModal,
    BookingAssistantModal, AppointmentModal } from '../../../../components/modal';

import {

} from '../../../../actions';

function ActionButtons(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [modalMode, setModalMode] = useState(null);

    const { refreshData, selectedDate, selectedCenter, searchedDate, appointments, permissions } = props;

    const handleShowModal = useCallback((mode) => {
        if(mounted.current) setModalMode(mode);
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current) setModalMode(null);
        if(changes) refreshData();
    }, [mounted, refreshData]);

    return (
        <div className="flex flex-row justify-center">
            <div>
                {modalMode === 'stamps' && 
                    <StampsModal
                        onSubmitCallback={onSubmitCallback}
                    />
                }
                {/* {modalMode === 'autoSchedule' &&
                    <AutoScheduleModal
                        selectedDate={selectedDate}
                        selectedCenter={selectedCenter}
                        onSubmitCallback={onSubmitCallback}
                    />
                } */}
                {modalMode === 'quickAssign' &&
                    <QuickAssignModal
                        selectedDate={selectedDate}
                        selectedCenter={selectedCenter}
                        onSubmitCallback={onSubmitCallback}
                    />
                }
                {modalMode === 'bookingAssistant' && 
                    <BookingAssistantModal
                        selectedCenter={selectedCenter}
                        onSubmitCallback={onSubmitCallback}
                    />
                }
                {modalMode === 'appointment' && 
                    <AppointmentModal
                        mode="create"
                        selectedDate={searchedDate}
                        selectedCenter={selectedCenter}
                        onSubmitCallback={onSubmitCallback}
                    />
                }
                <div className="flex flex-row gap-x-4 justify-items-center w-full">
                    <div>
                        <TooltipWrapper
                            tooltipText={
                                <div>
                                    <div>
                                        What gets exported?
                                    </div>
                                    <br/>
                                    <div>
                                        All appointments that are shown here ({appointments.length} items).
                                    </div>
                                </div>
                            }
                        >
                            <CSVExport
                                title="Appointments"
                                label="Export Appointments to CSV"
                                data={appointments}
                            />
                        </TooltipWrapper>
                    </div>
                    <Button
                        color="lte-mpEGreen"
                        disabled={!appointments.length}
                        style={{ marginRight: "2rem" }}
                        onClick={() => renderAppointmentsAsPDF(appointments, selectedCenter.label, `${selectedDate} 00:00:00`)}
                    >
                        Download Schedule
                    </Button>
                    <Button
                        color="lte-mpPurple"
                        style={{ marginRight: "2rem" }}
                        onClick={() => handleShowModal('stamps')}
                    >
                        Stamp Manager
                    </Button>
                    {/* <Button
                        color="lte-mpOrange"
                        style={{ marginRight: "2rem" }}
                        disabled={!appointments.length}
                        onClick={() => handleShowModal('autoSchedule')}
                    >
                        Auto Schedule
                    </Button> */}
                    { ['Root', 'Admin', 'Lead Instructor'].includes(permissions) &&
                        <Button
                            color="lte-mpOrange"
                            style={{ marginRight: "2rem" }}
                            disabled={!appointments.length}
                            onClick={() => handleShowModal('quickAssign')}
                        >
                            Quick Assign
                        </Button>
                    }
                    <Button
                        color="lte-mpLBlue"
                        style={{ marginRight: "2rem" }}
                        onClick={() => handleShowModal('bookingAssistant')}
                    >
                        Booking Assistant
                    </Button>
                    <Button
                        color="lte-mpLBlue"
                        onClick={() => handleShowModal('appointment')}
                    >
                        Add Appointment
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default connect(null, {

})(ActionButtons);