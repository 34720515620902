import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { checkResponse } from '../../form';
import { formatDate, formatDateApi, processRelInfo, checkLessonDatesValid } from '../../functions';
import { getStampChanges } from '../stamps/helpers';
import DetailsModalBodyFooter from './DetailsModalBF';

import {
    fetchMpCentersAll,
    fetchStampParams,
    fetchAdminUsersAll,
    fetchUpcomingExamsStudents,
    fetchAppointmentsIds,
    fetchStudentsUserIds,
    fetchRelevantInfoMemberDaterangeStudentName,
    fetchLessonsAll,
    fetchLearningPlansStudent,
    updateAppointmentDetails,
    updateLearningPlanItems,
    updateUpcomingExams,
    createFlag,
    updateStudentStamps,
    createStampsLog
} from '../../../actions';

function DetailsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [attemptingClose, setAttemptingClose] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // Prevents form from closing while submitting (only forms with accidental closure prevention)
    const [closureSubmitting, setClosureSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    const [stampParams, setStampParams] = useState([]);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [centers, setCenters] = useState([]);
    const [relevantAssignments, setRelevantAssignments] = useState([]);
    const [upcomingExamsItem, setUpcomingExamsItem] = useState({});
    const [appointment, setAppointment] = useState({});
    const [relInfo, setRelInfo] = useState({});
    const [learningPlanOptions, setLearningPlanOptions] = useState([]);
    const [originalLPItemMap, setOriginalLPItemMap] = useState({}); // To check what has changed

    const { selectedAppointment, onSubmitCallback, fetchMpCentersAll, fetchRelevantInfoMemberDaterangeStudentName,
        fetchLearningPlansStudent, fetchLessonsAll, fetchStampParams, fetchAdminUsersAll,
        fetchUpcomingExamsStudents, fetchAppointmentsIds, fetchStudentsUserIds, createStampsLog,
        updateAppointmentDetails, updateLearningPlanItems, updateUpcomingExams, createFlag,
        updateStudentStamps } = props;
    
    useEffect(() => {
        async function init(){            
            const centerRes = await fetchMpCentersAll();
            const stampsRes = await fetchStampParams();
            const appointmentRes = await fetchAppointmentsIds({ ids: [selectedAppointment.id] });
            const instructorsRes = await fetchAdminUsersAll();
            const studentRes = await fetchStudentsUserIds({ userIds: [selectedAppointment.student] });
            const upcomingExamsRes = await fetchUpcomingExamsStudents({ studentIds: [selectedAppointment.student] });
            const lessonsRes = await fetchLessonsAll();
            const learningPlansRes = await fetchLearningPlansStudent({ studentId: selectedAppointment.student, active: 1 });
            const newCenters = centerRes.data || [];
            const newStampParams = stampsRes.data?.[0] || {};
            const newAppointment = appointmentRes.data?.appointments?.[0] || {};
            const newRelevantAssignments = appointmentRes.data?.assignments || [];
            const newInstructors = instructorsRes.data || [];
            const newStudent = studentRes.data?.[0] || {};
            const newUpcomingExamsItem = upcomingExamsRes.data?.[0] || {};
            const newLessons = lessonsRes.data || [];
            const newLearningPlans = learningPlansRes.data?.learningPlans || [];
            const newLearningPlanItems = learningPlansRes.data?.learningPlanItems || [];

            const studentName = `${newStudent.first_name} ${newStudent.last_name}`;

            newInstructors.forEach(i => i.fullName = `${i.first_name} ${i.last_name}`);
            const newInstructorOptions = newInstructors.filter(i => {
                return (newRelevantAssignments.some(a => a.instructor === i.id) ||
                    (i.mp_permissions !== 'None' && parseInt(i.account_active) === 1));
            }).sort((a, b) => {
                if(a.fullName < b.fullName) return -1;
                else if(a.fullName > b.fullName) return 1;
                else return 0;
            }).map(i => ({ value: i.id, label: i.fullName}));

            const centerMap = {};
            newCenters.forEach(c => centerMap[c.id] = c.name);
            newAppointment.centerName = centerMap[parseInt(newAppointment.center || -1)] || `Unknown center (ID: ${newAppointment.center})`;
            newAppointment.studentName = studentName;
            newAppointment.studentObject = newStudent;
            const aptDateTime = new Date(newAppointment.date_time);
            newAppointment.startTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
            newAppointment.endTime = newAppointment.startTime + parseInt(newAppointment.duration);

            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 3);
            const endDate = new Date();
            const relevantInfoRes = await fetchRelevantInfoMemberDaterangeStudentName({
                startDate: formatDateApi(startDate),
                endDate: formatDateApi(endDate),
                searchTerm: studentName
            });
            const newRelInfo = relevantInfoRes.data || {};

            const processedRelInfo = processRelInfo(newRelInfo, newCenters, newInstructors, studentName);

            const lessonMap = {};
            newLessons.forEach(l => lessonMap[parseInt(l.id)] = l);

            const newLearningPlanItemMap = {}; // To be used in formik
            const newOriginalLPItemMap = {}; // To check what has changed in handleSubmit
            newLearningPlanItems.forEach(lpi => {
                const lessonId = parseInt(lpi.lesson_id);
                
                lpi.dateAssigned = lpi.date_assigned ? formatDateApi(lpi.date_assigned) : '';
                lpi.dateCompleted = lpi.date_completed ? formatDateApi(lpi.date_completed) : '';
                const relevantLesson = lessonMap[lessonId];
                lpi.name = relevantLesson ? `${relevantLesson.lesson_id} - ${relevantLesson.name}` :
                    `Unknown lesson (${lpi.lesson_id})`;
                lpi.hasAnswerKey = parseInt(relevantLesson.has_answer_key);

                const lpId = parseInt(lpi.learning_plan_id);

                if(!newLearningPlanItemMap[lpId]) newLearningPlanItemMap[lpId] = [];
                lpi.orderIndex = newLearningPlanItemMap[lpId].length;
                lpi.lessonObj = lessonMap[lessonId] || {};
                newLearningPlanItemMap[lpId].push(lpi);

                newOriginalLPItemMap[parseInt(lpi.id)] = { ...lpi };
            });
            
            newLearningPlans.sort((a, b) => {
                return parseInt(a.sort_order) - parseInt(b.sort_order);
            }).forEach(lp => {
                lp.items = (newLearningPlanItemMap[parseInt(lp.id)] || [])
                .sort((a, b) => {
                    return parseInt(a.sort_order) - parseInt(b.sort_order);
                });
            });

            const newLearningPlanOptions = newLearningPlans.length ? newLearningPlans.map(lp => {
                    return { value: parseInt(lp.id), label: `${lp.name} (${formatDate(lp.date_created)})`, obj: lp };
                }) : [{ value: -1, label: 'Please select...', obj: {} }];

            if(mounted.current){
                setCenters(newCenters);
                setStampParams(newStampParams);
                setInstructorOptions(newInstructorOptions);
                setRelevantAssignments(newRelevantAssignments);
                setUpcomingExamsItem(newUpcomingExamsItem);
                setAppointment(newAppointment);
                setRelInfo(processedRelInfo);
                setLearningPlanOptions(newLearningPlanOptions);
                setOriginalLPItemMap(newOriginalLPItemMap);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes, forceClose = false) => {
        if(!forceClose){
            if(changes !== true) changes = false;
            if(closureSubmitting) return;
            if(!attemptingClose){
                setAttemptingClose(true);
                return;
            }
        }
        (async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        })();
    }, [onSubmitCallback, attemptingClose, closureSubmitting, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current){
                setSubmitting(true);
                setClosureSubmitting(true);
            }

            // Validate dynamic fields //
            let isValid = true;
            let erroredLessonName = '';
            const learningPlans = values.learningPlanOptions;
            const lpItems = [];
            learningPlans.forEach(lp => lp.obj.items?.forEach(lpi => lpItems.push(lpi)));
            lpItems.forEach(lpi => {
                if(!checkLessonDatesValid(lpi.dateAssigned, lpi.dateCompleted)){
                    isValid = false;
                    erroredLessonName = lpi.name;
                    return;
                }
            });
            if(!isValid){
                setStatus(`The following lesson has an invalid date assigned or date completed: ${erroredLessonName}. You might need to uncheck "Hide Completed" under "Active Learning Plans" in order to find the issue.`);
                setSubmitting(false);
                return;
            }
            // END - Validate dynamic field //

            // PREPARE DATA //
            const detailsParams = {
                id: appointment.id,
                instructors: values.instructors.map(si => si.value),
                specialNotesSchedule: values.specialNotesSchedule,
                nPages: values.nPages,
                nLessons: values.nLessons,
                hwNotes: values.hwNotes,
                sessionNotes: values.sessionNotes,
                stampsGiven: (values.stampsAmount > 0 || values.stampsGiven) ? 1 : 0,
                status: values.status.value,
            };

            // Check what has changed and add them to the array to be processed
            const learningPlanItemParams = [];
            lpItems.forEach(lpi => {
                const originalLpi = originalLPItemMap[parseInt(lpi.id)] || {};

                const datesChanged = (
                    lpi.dateAssigned !== originalLpi.dateAssigned ||
                    lpi.dateCompleted !== originalLpi.dateCompleted
                );
                if(datesChanged){
                    learningPlanItemParams.push({
                        id: parseInt(lpi.id),
                        lessonId: lpi.lesson_id,
                        dateAssigned: lpi.dateAssigned,
                        dateCompleted: lpi.dateCompleted,
                        sortOrder: parseInt(lpi.sort_order)
                    });
                }
            });
            
            const upcomingExamsParams = {
                id: upcomingExamsItem.id,
                hasExams: values.hasExams ? 1 : 0,
                upcomingExamsNotes: values.upcomingExamsNotes,
            }

            const {
                newTotalCards,
                newCurrentCards,
                newCurrentStamps,
            } = getStampChanges(appointment.studentObject, { value: 'Give Stamps' }, values.stampsAmount, stampParams);
            let adjustedCurrentStamps = newTotalCards < 0 || newCurrentCards < 0 ? 0 : newCurrentStamps;

            const stampsParams = {
                student: appointment.student,
                currentStamps: adjustedCurrentStamps,
                currentCards: Math.max(newCurrentCards, 0),
                totalCards: Math.max(newTotalCards, 0),
            };

            const stampsLogParams = {
                student: appointment.student ,
                type: 'Give Stamps',
                amount: values.stampsAmount,
                notes: values.stampsNotes
            }

            const flagsParams = {
                student: appointment.student,
                tag: values.flagTag.value,
                center: values.flagCenter.value,
                additionalNotes: values.flagNotes,
                adminNotes: '',
                status: 'Pending'
            };
            // END-PREPARE DATA//

            // SEND DATA //
            const nss = { errored: false, completed: false };
            nss.appointmentDetails = { name: 'Appointment Details', completed: false, message: '' };
            if(learningPlanItemParams.length){
                nss.learningPlanItems = { name: 'Learning Plan Items', completed: false, message: '' };
            }
            const ueHasExamsMatch = parseInt(upcomingExamsParams.hasExams) === parseInt(upcomingExamsItem.has_exams);
            const ueNotesMatch = upcomingExamsParams.upcomingExamsNotes === upcomingExamsItem.notes;
            if(!ueHasExamsMatch || !ueNotesMatch){
                nss.upcomingExams = { name: 'Upcoming Exams', completed: false, message: '' };
            }
            if(values.stampsAmount !== 0){
                nss.stamps = { name: 'Give Stamps', completed: false, message: '' };
                nss.stampsLog = { name: 'Create Stamps Log', completed: false, message: '' };
            }
            if(values.createFlag) nss.flag = { name: 'Create Flag', completed: false, message: '' };
            setSubmissionStatus({...nss});

            const uadResponse = await updateAppointmentDetails(detailsParams);
            nss.appointmentDetails.completed = true;
            nss.errored = !checkResponse(uadResponse, mounted, (resp) => nss.appointmentDetails.message = resp) || nss.errored;
            setSubmissionStatus({...nss});

            if(learningPlanItemParams.length){
                const lpiResponse = await updateLearningPlanItems({ learningPlanItems: learningPlanItemParams });
                nss.learningPlanItems.completed = true;
                nss.errored = !checkResponse(lpiResponse, mounted, (resp) => nss.learningPlanItems.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }

            if(!ueHasExamsMatch || !ueNotesMatch){
                const ueResponse = await updateUpcomingExams(upcomingExamsParams);
                nss.upcomingExams.completed = true;
                nss.errored = !checkResponse(ueResponse, mounted, (resp) => nss.upcomingExams.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }

            if(values.stampsAmount !== 0){
                const ussResponse = await updateStudentStamps(stampsParams);
                nss.stamps.completed = true;
                nss.errored = !checkResponse(ussResponse, mounted, (resp) => nss.stamps.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
                
                const cslResponse = await createStampsLog(stampsLogParams);
                nss.stampsLog.completed = true;
                nss.errored = !checkResponse(cslResponse, mounted, (resp) => nss.stamps.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }

            if(values.createFlag){
                const cfResponse = await createFlag(flagsParams);
                nss.flag.completed = true;
                nss.errored = !checkResponse(cfResponse, mounted, (resp) => nss.flag.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }
            // END-SEND DATA //
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setClosureSubmitting(false);
                setStatus(
                    `One or more errors occurred during submission.
                    Please be careful! Resubmitting this form will re-attempt all actions.
                    This includes updating the appointment,
                    giving student stamps, and creating a flag (if applicable).`
                );
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setClosureSubmitting(false);
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [handleClose, appointment, stampParams, upcomingExamsItem, updateAppointmentDetails,
        updateLearningPlanItems, createStampsLog, updateUpcomingExams, createFlag,
        updateStudentStamps, oneSuccess, originalLPItemMap]);

    return (
        <Modal className="w-9/12" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Appointment Details Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <DetailsModalBodyFooter
                    loaded={loaded}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    centers={centers}
                    selectedAppointment={appointment}
                    stampParams={stampParams}
                    instructorOptions={instructorOptions}
                    relevantAssignments={relevantAssignments}
                    upcomingExamsItem={upcomingExamsItem}
                    relInfo={relInfo}
                    learningPlanOptions={learningPlanOptions}
                    submitted={submitted}
                    submissionStatus={submissionStatus}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMpCentersAll,
    fetchStampParams,
    fetchAdminUsersAll,
    fetchUpcomingExamsStudents,
    fetchAppointmentsIds,
    fetchStudentsUserIds,
    fetchRelevantInfoMemberDaterangeStudentName,
    fetchLessonsAll,
    fetchLearningPlansStudent,
    updateAppointmentDetails,
    updateLearningPlanItems,
    updateUpcomingExams,
    createFlag,
    updateStudentStamps,
    createStampsLog
})(DetailsModal);