import { getymd } from '.';

function parseRelevantNotes(entry, userName){
    let matchedTexts = '';
    for(let field in entry){
        if(['operation_notes', 'issues', 'student_notes', 'admin_notes'].includes(field)){
            const text = entry[field];
            const textLower = text.toLowerCase();
            const userNameLower = '!~' + userName.toLowerCase() + '~!';
    
            let searchStartIndex = 0;
            let lastNewlineIndex = 0;
            const startIndices = [];
            const endIndices = [];
    
            while(true){
                const startIndex = textLower.indexOf(userNameLower, searchStartIndex);
                if(startIndex === -1) break;
                let endIndex = textLower.indexOf(`\n`, searchStartIndex);
                if(endIndex === -1) endIndex = textLower.length;
    
                if(startIndex <= endIndex - 1){
                    startIndices.push(lastNewlineIndex);
                    endIndices.push(endIndex);
                }
    
                searchStartIndex = endIndex + 1;
                lastNewlineIndex = searchStartIndex;
            }
    
            let i = 0;
            while(i < startIndices.length){
                let matchedText = text.slice(startIndices[i], endIndices[i]).replace(/!~/g, '').replace(/~!/g, '');
    
                matchedTexts += `${matchedText}, `;
                i++;
            }
            // reportMatchedInfo[field] = matchedTexts;
        }
    }

    return matchedTexts.slice(0, matchedTexts.length - 2);
}

function getCombinedNotes(relInfo, employeeMap, studentName){
    const notesMap = {};

    relInfo.appointments.forEach(a => {
        if(!a.session_notes.trim()) return;
        // Get notes
        const [year, month, date] = getymd(a.date_time);
        const dateKey = `${year}_${month}_${date}`;
        if(!notesMap[dateKey]) notesMap[dateKey] = { sessionNotes: ``, reports: ``, workCompleted: `` };
        notesMap[dateKey].sessionNotes += a.session_notes.trim();
        notesMap[dateKey].workCompleted += `${a.n_pages} pages, ${a.n_lessons} lessons`;

        // Add instructor names
        if(notesMap[dateKey].sessionNotes){
            notesMap[dateKey].sessionNotes += ` (${a.instructorNames || 'No instructors assigned'})`;
        }
    });

    relInfo.dailyReports.forEach(r => {
        const [year, month, date] = getymd(r.date);
        const dateKey = `${year}_${month}_${date}`;
        if(!notesMap[dateKey]) notesMap[dateKey] = { sessionNotes: ``, reports: `` }
        notesMap[dateKey].reports += parseRelevantNotes(r, studentName);

        // Add LI name
        if(notesMap[dateKey].reports){
            const addContent = employeeMap[r.original_author] || `Unknown author (ID: ${r.original_author})`;
            notesMap[dateKey].reports += ` (${addContent})`;
        }
    });

    let keyIndex = 0;
    const combinedNotes = Object.keys(notesMap).map(dateKey => {
        const notesGroup = notesMap[dateKey];
        const [year, month, date] = dateKey.split('_');
        notesGroup.date = new Date(year, parseInt(month) - 1, date);
        notesGroup.key = `rel-info-combined-notes-${keyIndex++}`;
        return notesGroup;
    }).sort((a, b) => b.date - a.date);

    return combinedNotes || [];
}

export function processRelInfo(relInfo, centers, employees, studentName, mapsGiven = []){
    const newRelInfo = {...relInfo};

    let employeeMap = {};
    if(mapsGiven.includes('employees')) employeeMap = employees;
    else employees.forEach(i => employeeMap[i.id] = `${i.first_name} ${i.last_name}`);
    
    let centerMap = {};
    if(mapsGiven.includes('centers')) centerMap = centers;
    else centers.forEach(c => centerMap[c.id] = c.name);

    if(newRelInfo.assignments && newRelInfo.appointments){
        const assignmentMap = {};
        newRelInfo.assignments.forEach(a => {
            const aptId = a.appointment_id;
            if(!assignmentMap[aptId]) assignmentMap[aptId] = [];
            assignmentMap[aptId].push(a.instructor);
        });

        newRelInfo.appointments.forEach(a => {
            a.dateTime = new Date(a.date_time);
            a.startTime = a.dateTime.getHours() * 60 + a.dateTime.getMinutes() * 1;
            a.endTime = a.startTime + parseInt(a.duration);

            a.centerName = centerMap[a.center] || `Unknown center (ID: ${a.center})`;
            a.instructorNames = '';

            const assignedInstructors = assignmentMap[parseInt(a.id)] || [];
            for(let instructor of assignedInstructors){
                a.instructorNames += (employeeMap[instructor] || `Unknown author (ID: ${instructor})`) + ', ';
            }
            a.instructorNames = a.instructorNames.slice(0, a.instructorNames.length - 2);
        });
    }
    if(newRelInfo.appointments && newRelInfo.dailyReports){
        newRelInfo.combinedNotes = getCombinedNotes(newRelInfo, employeeMap, studentName);
    };
    if(newRelInfo.flags){
        newRelInfo.flags.forEach(f => {
            f.centerName = centerMap[f.center] || `Unknown center (ID: ${f.center})`;
            f.createdByName = employeeMap[f.created_by] || `Unknown user (ID: ${f.created_by})`;
            if(f.updated_by) f.updatedByName = employeeMap[f.updated_by] || `Unknown user (ID: ${f.updated_by})`;
        });
    }
    if(newRelInfo.upcomingExams){
        const userId = newRelInfo.upcomingExams.updated_by;
        if(!userId) newRelInfo.upcomingExams.updatedByName = 'NA';
        else newRelInfo.upcomingExams.updatedByName = employeeMap[userId] || `Unknown user (ID: ${userId})`;
    }
    if(newRelInfo.student){
        const centerId = newRelInfo.student.mp_primary_center;
        newRelInfo.student.primaryCenterName = centerMap[centerId] || `Unknown center (ID: ${centerId})`;
    }

    return newRelInfo;
}