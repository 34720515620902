import { formatTime } from '../../../../../../components/functions';

export function getSeriesOptions(availabilityBlocks, appointments, switches){
    // PHASE 1: Get the data into a format that can easily be sorted.
    // The process of putting the availabilities into an object before placing them
    // into arrays is a bit redundant, but makes for easier readability since it
    // matches the format that needs to be used for appointments, as appointments
    // can start and end at any time and thus require key-value mapping

    const availabilityInfo = {};
    const appointmentInfo = {};
    let shouldWarn = false;
    let firstTime = 1440;
    let lastTime = 0;

    availabilityBlocks.forEach(ab => {
        const abTime = parseInt(ab.time);
        firstTime = Math.min(abTime, firstTime);
        lastTime = Math.max(abTime, lastTime);

        const availObject = {
            time: formatTime(abTime),
            maxS: parseInt(ab.students || 0),
            maxW: parseInt(ab.seats || 0),
            maxStartS: parseInt(ab.start_students || 0),
            maxStartW: parseInt(ab.start_seats || 0)
        };
        
        const closed = parseInt(ab.closed) === 1;
        if(closed){
            availObject.maxS = 0;
            availObject.maxW = 0;
            availObject.maxStartS = 0;
            availObject.maxStartW = 0; 
        }
        availabilityInfo[abTime] = availObject;

        // APPOINTMENTS
        appointmentInfo[abTime] = {
            scheduledW: 0,
            scheduledS: 0,
            startingW: 0,
            startingS: 0,
            leavingW: 0,
            leavingS: 0
        }
    });

    // Need to add these so that the chart renders through closing
    const firstDisplayTime = Math.max(firstTime - 30, 0);
    const secondLastDisplayTime = Math.min(lastTime + 30, 1440);
    const lastDisplayTime = Math.min(lastTime + 60, 1440);
    const blocksToAdd = [firstDisplayTime, secondLastDisplayTime, lastDisplayTime];
    
    // If appointments are scheduled before or after opening hours, include these blocks as well
    let firstAppointment = 1440;
    let lastAppointment = 0;
    const filteredAppointments = appointments.filter(a => {
        return !['Cancelled', 'Missed'].includes(a.status);
    });
    filteredAppointments.forEach(a => {
        firstAppointment = Math.min(a.startTime, firstAppointment);
        lastAppointment = Math.max(a.endTime, lastAppointment);
    });

    // Add extra blocks to availabilityInfo and appointmentInfo, if needed.
    // Extra block added before/after to make chart edges look nice
    const displayStart = Math.max(firstAppointment - 30, 0);
    const displayEnd = Math.min(lastAppointment + 30, 1440);
    for(let time = displayStart; time <= displayEnd; time += 30) blocksToAdd.push(time);
    for(let time of blocksToAdd){
        // If one of these does not exist, both should not exist, and vice versa
        if(!availabilityInfo[time]){
            availabilityInfo[time] = {
                time: formatTime(lastTime + 30),
                maxS: 0,
                maxW: 0,
                maxStartS: 0,
                maxStartW: 0
            };
            if(![firstDisplayTime, secondLastDisplayTime, lastDisplayTime].includes(time)){
                shouldWarn = true;
            }
        }
        if(!appointmentInfo[time]){
            appointmentInfo[time] = {
                scheduledW: 0,
                scheduledS: 0,
                startingW: 0,
                startingS: 0,
                leavingW: 0,
                leavingS: 0
            }
            if(![firstDisplayTime, secondLastDisplayTime, lastDisplayTime].includes(time)){
                shouldWarn = true;
            }
        }
    }

    filteredAppointments.forEach(a => {        
        const { startTime, endTime } = a;
        const weight = parseInt(a.weight);

        for(let time = startTime; time <= endTime; time += 30){
            const relAptInfo = appointmentInfo[time];
            if(!relAptInfo || (time !== endTime && !appointmentInfo[time + 30])){
                continue;
            }

            if(time === startTime){
                relAptInfo.scheduledW += weight;
                relAptInfo.scheduledS++;
                relAptInfo.startingW += weight;
                relAptInfo.startingS++;
            } else if(time === endTime){
                relAptInfo.leavingW += weight;
                relAptInfo.leavingS++;
            } else {
                relAptInfo.scheduledW += weight;
                relAptInfo.scheduledS++;
            }
        }
    });

    // PHASE 2: Transfer data and add appropriate sets to table

    // Series data
    const series = [];

    const times = [];
    Object.keys(availabilityInfo).forEach(k => times.push(formatTime(k)));
    const maxW = [];
    const maxS = [];
    const maxStartW = [];
    const maxStartS = [];
    Object.values(availabilityInfo).forEach(a => {
        maxW.push(a.maxW);
        maxS.push(a.maxS);
        maxStartW.push(a.maxStartW);
        maxStartS.push(a.maxStartS);
    });
    const scheduledW = [];
    const scheduledS = [];
    const startingW = [];
    const startingS = [];
    const leavingW = [];
    const leavingS = [];
    Object.values(appointmentInfo).forEach(a => {
        scheduledW.push(a.scheduledW);
        scheduledS.push(a.scheduledS);
        startingW.push(a.startingW);
        startingS.push(a.startingS);
        leavingW.push(a.leavingW);
        leavingS.push(a.leavingS);
    });

    // Options data
    // maxW, maxS, maxStartW, maxStartS, scheduledW, scheduledS, startingW, startingS, leavingW, leavingS
    const colorsBase = ['#8950FC', '#8950FC55', '#00c0f5', '#00c0f555', '#8950FC',  '#8950FC88', '#00c0f5', '#00c0f588', '#ff8529', '#ff852988'];
    const strokeCurvesBase = ['straight', 'straight', 'straight', 'straight', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth'];
    const strokeDashArraysBase = [1, 2, 1, 2, 0, 5, 0, 5, 0, 5];
    const strokeWidthsBase = [1.5, 1.5, 1.5, 1.5, 2, 2, 2, 2, 2, 2];
    const colors = [];
    const strokeCurves = [];
    const strokeDashArrays = [];
    const strokeWidths = [];

    let i = 0;
    if(switches.maxW){
        series.push({
            name: 'Max. (W)',
            data: maxW
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.maxS){
        series.push({
            name: 'Max. (S)',
            data: maxS
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.maxStartW){
        series.push({
            name: 'Max. Start (W)',
            data: maxStartW
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.maxStartS){
        series.push({
            name: 'Max. Start (S)',
            data: maxStartS
        });

        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.scheduledW){
        series.push({
            name: 'Scheduled (W)',
            data: scheduledW
        });

        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.scheduledS){
        series.push({
            name: 'Scheduled (S)',
            data: scheduledS
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.startingW){
        series.push({
            name: 'Starting (W)',
            data: startingW
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.startingS){
        series.push({
            name: 'Starting (S)',
            data: startingS
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.leavingW){
        series.push({
            name: 'Leaving (W)',
            data: leavingW
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }
    i++;
    if(switches.leavingS){
        series.push({
            name: 'Leaving (S)',
            data: leavingS
        });
        colors.push(colorsBase[i]);
        strokeCurves.push(strokeCurvesBase[i]);
        strokeDashArrays.push(strokeDashArraysBase[i]);
        strokeWidths.push(strokeWidthsBase[i]);
    }

    const options = {
        colors: colors,
        chart: {
            height: "400px",
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: strokeCurves,
            dashArray: strokeDashArrays,
            width: strokeWidths,
        },
        title: {
            text: `Today's Appointments`,
            align: 'left'
        },
        grid: {
            row: {
                // ultraLightA class
                colors: ['#ffe7e7', '#fdfdfc'], // takes an array which will be repeated on columns
                opacity: 0.8
            },
        },
        xaxis: {
            categories: times,
        }
    };

    return  { series, options, shouldWarn };
}