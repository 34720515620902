import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { UpcomingExamsModal } from '../../../../components/modal';
import { Table } from '../../../../components/table';
import { getColumns } from './table/getColumns';

import {

} from '../../../../actions';

const defaultSorted = { dataField: 'has_exams', order: 'desc' };

function Component(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(null);
    const [selectedUE, setSelectedUE] = useState(null);

    const { refreshData, users, upcomingExams } = props;

    const handleShowModal = useCallback((upcomingExams) => {
        if(mounted.current){
            setShowModal(true);
            setSelectedUE(upcomingExams);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setShowModal(false);
            setSelectedUE(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumns(handleShowModal, users), [users, handleShowModal]);

    return (
        <div>
            {showModal && 
                <UpcomingExamsModal
                    selectedUE={selectedUE}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="upcomingExams"
                data={upcomingExams}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No upcoming exams were found"
            />
        </div>
    );
}

export default connect(null, {

})(Component);