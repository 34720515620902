import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { formatDateFull, formatTime, generateStringBlocks, getymd, downloadFile } from '../functions';

export async function renderAppointmentsAsPDF(appointments, centerName, selectedDate){
    let reportPdf = await PDFDocument.create();
    const helveticaFont = await reportPdf.embedFont(StandardFonts.Helvetica);

    let currentPage = reportPdf.addPage();

    const appointmentsByTime = {};
    let dateString = selectedDate ? formatDateFull(selectedDate) : 'Date undefined';
    Object.values(appointments).forEach(a => {
        if(['Cancelled', 'Missed'].includes(a.status)) return;
        
        const aptDateTime = new Date(a.date_time);
        const aptTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;

        if(appointmentsByTime[aptTime]) appointmentsByTime[aptTime].push(a);
        else appointmentsByTime[aptTime] = [a];
    });

    // A - TOTAL STAT TRACKERS //
    let totalAppointments = 0;

    // B - MASTER VARIABLES //
    const { width, height } = currentPage.getSize();
    const blocksToDraw = []; // Tracks items to be drawn on the page

    // width 595.28 = 70.03 pt per inch, height 841.89 = 76.55 pt per inch
    // 44 quarter inches (11 inches) tall, 34 quarter inches (8 1/2 inches) wide
    const yMarginQuarterInches = 3;
    const yMin = height * (yMarginQuarterInches/44) + 60; // + 60 so that the logo on the bottom left does not collide with text
    const yMax = height - (height * (yMarginQuarterInches/44));
    const xMarginQuarterInches = 3;
    const xMin = width * (xMarginQuarterInches/34);
    const xMax = width - (width * (xMarginQuarterInches/34));

    let yPos = yMax;

    // Render by time slot
    let isFirstTime = true; // For everything after the first time, add some yOffsetBefore
    for(let timeSlot of Object.keys(appointmentsByTime)){
        let currentBlock = [];
        const timeSlotText = formatTime(timeSlot);
        const timeSlotSize = 15;
        const timeSlotHeight = helveticaFont.heightAtSize(timeSlotSize);
        const timeSlotXOffset = 0;
        currentBlock.push({
            text: timeSlotText,
            xOffset: timeSlotXOffset,
            yOffsetBefore: isFirstTime ? 0 : 15,
            yOffsetAfter: 3,
            size: timeSlotSize,
            height: timeSlotHeight,
            font: helveticaFont,
        });
        isFirstTime = false;
        
        const appointmentSize = 10;
        const appointmentXOffset = 15;
        if(!appointmentsByTime[timeSlot]){
            // "No appointments" if no appointments in the time slot
            currentBlock.push({
                text: 'No appointments',
                color: rgb(255/255, 96/255, 0),
                yOffsetAfter: 5,
                size: appointmentSize,
                xOffset: appointmentXOffset
            });
            blocksToDraw.push(currentBlock);
        } else {
            // Otherwise, render appointments
            for(let appointment of appointmentsByTime[timeSlot]){
                const studentName = `${appointment.studentInfo.first_name} ${appointment.studentInfo.last_name}`;
                const appointmentText = `${studentName}: ${appointment.instructorNames || 'None Assigned'}`;

                let specialMessageText = appointment.special_notes_schedule ? ` ${appointment.special_notes_schedule}` : '';
                const appointmentYOffsetAfter = specialMessageText ? 3 : 5;
                currentBlock.push({
                    text: appointmentText,
                    size: appointmentSize,
                    xOffset: appointmentXOffset,
                    yOffsetAfter: appointmentYOffsetAfter,
                });

                if(specialMessageText){
                    const specialMessageXOffset = appointmentXOffset + 15;
                    const textMaxWidth = xMax - xMin - specialMessageXOffset;
                    const additionalArgs = { beforeText: 'Scheduling Notes: ' };
                    const specialMessageBlocks = generateStringBlocks(helveticaFont, appointmentSize,
                        specialMessageText, textMaxWidth, additionalArgs);

                    for(let block of specialMessageBlocks){
                        currentBlock.push({
                            text: block,
                            size: appointmentSize,
                            xOffset: specialMessageXOffset,
                            color: rgb(255/255, 95/255, 0),
                            yOffsetAfter: 3,
                        });
                    }
                }

                blocksToDraw.push(currentBlock);
                currentBlock = [];
                totalAppointments++;
            }
        }

        // Add some whitespace after each time section
        blocksToDraw.push([{
            text: '',
            size: 0,
            yOffsetAfter: 3
        }]);
    }

    // Render items
    // HEADERS //
    const mainHeaderText = `MathPlex ${centerName}`;
    const mainHeaderSize = 30;
    const mainHeaderWidth = helveticaFont.widthOfTextAtSize(mainHeaderText, mainHeaderSize);
    const mainHeaderHeight = helveticaFont.heightAtSize(mainHeaderSize);
    const mainHeaderXPos = xMin + ((xMax - xMin - mainHeaderWidth) / 2);
    currentPage.drawText(mainHeaderText, {
        x: mainHeaderXPos,
        y: yPos,
        size: mainHeaderSize,
        font: helveticaFont
    });
    yPos -= mainHeaderHeight;

    const subHeaderText = `${totalAppointments} Appointments for ${dateString}`;
    const subHeaderSize = 18;
    const subHeaderWidth = helveticaFont.widthOfTextAtSize(subHeaderText, subHeaderSize);
    const subHeaderHeight = helveticaFont.heightAtSize(subHeaderSize);
    const subHeaderXPos = xMin + ((xMax - xMin - subHeaderWidth) / 2);
    currentPage.drawText(subHeaderText, {
        x: subHeaderXPos,
        y: yPos,
        size: subHeaderSize,
        font: helveticaFont
    });
    yPos -= (subHeaderHeight + 15);

    for(let currentBlock of blocksToDraw){
        let blockHeight = 0;
        // Check that blocks have the needed properties.
        for(let entry of currentBlock){
            if(entry.text === undefined){
                console.error('A render block item is missing the text property');
                continue;
            }
            if(entry.size === undefined){
                console.error('A render block item is missing the size property');
                continue;
            }

            entry.height = helveticaFont.heightAtSize(entry.size);
            blockHeight += (entry.height +  (entry.yOffsetBefore || 0) + (entry.yOffsetAfter || 0));
        }

        // Add a page if the current block is too tall
        if(yPos - blockHeight < yMin){
            currentPage = reportPdf.addPage();
            yPos = yMax;
        }

        // Draw items
        for(let entry of currentBlock){
            if(entry.yOffsetBefore) yPos -= entry.yOffsetBefore;

            const entryProperties = {
                x: xMin + entry.xOffset || 0,
                y: yPos,
                size: entry.size,
                font: helveticaFont
            }
            if(entry.color) entryProperties.color = entry.color;
            currentPage.drawText(entry.text, entryProperties);

            yPos -= entry.height;
            if(entry.yOffsetAfter) yPos -= entry.yOffsetAfter;
        }
    }

    const pdfBytes = await reportPdf.save();

    const [year, month, date] = getymd(selectedDate);

    downloadFile(pdfBytes, `${centerName} ${year}-${month}-${date}.pdf`);
}