import { basicPath } from '../basicPath';

export const fetchAdminUsersAll = (params) => async (dispatch) => {
    const path = '/admin-users/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAdminUsersActive = (params) => async (dispatch) => {
    const path = '/admin-users/active';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAdminUsersIds = (params) => async (dispatch) => {
    const path = '/admin-users/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createAdminUser = (params) => async (dispatch) => {
    const path = '/admin-users';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAdminUser = (params) => async (dispatch) => {
    const path = '/admin-users/all';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateAdminUserPassword = (params) => async (dispatch) => {
    const path = '/admin-users/password';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAdminUser = (params) => async (dispatch) => {
    const path = '/admin-users';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}