import React from 'react';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper, renderPhone } from '../../../../../components/display';

export function getColumns(handleShowModal, userPerms){
    const permissionsMap = {
        "root": 1,
        "admin": 2,
        "lead instructor": 3,
        "instructor": 4,
        "none": 5
    };
    return(
        [
            {
                dataField: 'id',
                text: 'UUID',
                sort: true,
                formatter: (row) => {
                    return(
                        <TooltipWrapper
                            tooltipText={row.id}
                        >
                            <div className="text-mpLBlue">
                                [ID]
                            </div>
                        </TooltipWrapper>
                    )
                }
            }, {
                dataField: 'name',
                text: 'Name',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const aName = `${rowA.first_name} ${rowA.last_name}`;
                    const bName = `${rowB.first_name} ${rowB.last_name}`;
                    const swap = order === 'asc' ? -1 : 1;
        
                    if(aName < bName) return -1 * swap;
                    else if(aName > bName) return 1 * swap;
                    return 0;
                },
                formatter: (row) => `${row.first_name} ${row.last_name}`
            }, {
                dataField: 'email',
                text: 'Email',
                sort: true,
            }, {
                dataField: 'phone',
                text: 'Phone Number',
                sort: true,
                formatter: (row) => {
                    const cell = row.phone;
                    let number = cell.toString();
                    if(!number.length || number === '0' || number === '0000000000'){
                        return <Label color="mpLRed">None</Label>;
                    }
                    return renderPhone(number);
                }
            }, {
                dataField: 'primary_center',
                text: 'Primary Center',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const sortBy = order === 'asc' ? 'mp' : 'rp';
                    if(sortBy === 'mp'){
                        if(a.mpCenterName < b.mpCenterName) return -1;
                        else if(a.mpCenterName > b.mpCenterName) return 1;
                        else return 0;
                    } else if (sortBy === 'rp'){
                        if(a.rpCenterName < b.rpCenterName) return -1;
                        else if(a.rpCenterName > b.rpCenterName) return 1;
                        else return 0;
                    }
                },
                formatter: (row) => {
                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {row.mpCenterName}</div>
                            <div>RP: {row.rpCenterName}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'permissions',
                text: 'Permissions',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? -1 : 1;
                    const mpPermissionsA = rowA.mp_permissions;
                    const mpPermissionsB = rowB.mp_permissions;
                    const rpPermissionsA = rowA.rp_permissions;
                    const rpPermissionsB = rowB.rp_permissions;

                    const priorityA = permissionsMap[mpPermissionsA.toLowerCase()] || 99 + permissionsMap[rpPermissionsA.toLowerCase()] || 99;
                    const priorityB = permissionsMap[mpPermissionsB.toLowerCase()] || 99 + permissionsMap[rpPermissionsB.toLowerCase()] || 99;
                    return (priorityA - priorityB) * swap;
                },
                formatter: (row) => {
                    const { mp_permissions, rp_permissions } = row;

                    let mpColor = 'mpLGrey';
                    if(mp_permissions === 'Root') mpColor = 'mpPurple';
                    else if(mp_permissions === 'Admin') mpColor = 'mpLBlue';
                    else if(mp_permissions === 'Lead Instructor') mpColor = 'mpYellow';
                    else if(mp_permissions === 'Instructor') mpColor = 'mpOrange';
                    const mpPermissions = <Label color={mpColor}>{mp_permissions}</Label>;

                    let rpColor = 'mpLGrey';
                    if(rp_permissions === 'Root') rpColor = 'mpPurple';
                    else if(rp_permissions === 'Admin') rpColor = 'mpLBlue';
                    else if(rp_permissions === 'Lead Instructor') rpColor = 'mpYellow';
                    else if(rp_permissions === 'Instructor') rpColor = 'mpOrange';
                    const rpPermissions = <Label color={rpColor}>{rp_permissions}</Label>;

                    return (
                        <div className="grid grid-cols-1 gap-y-2">
                            <div>MP: {mpPermissions}</div>
                            <div>RP: {rpPermissions}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'account_active',
                text: 'Active',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? -1 : 1;
                    return (b - a) * swap;
                },
                formatter: (row) => {
                    if(parseInt(row.account_active) === 1) return <Label color="mpGreen">Active</Label>;
                    else return <Label color="mpLRed">Inactive</Label>;
                }
            }, {
                dataField: 'actions',
                text: 'Actions',
                formatter: (row) => {
                    const { mp_permissions, rp_permissions } = row;
                    if(mp_permissions === 'Root' || rp_permissions === 'Root') return '-';
                    if(userPerms !== 'Root' && mp_permissions === 'Admin') return '-';
                    return (
                        <div className="flex flex-row gap-x-2">
                            <TooltipWrapper
                                tooltipText={`Edit ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowModal('edit', row)}
                                    style={{ marginRight: "10px" }}
                                >
                                    <SVGIcon fileName="pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Delete ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowModal('delete', row)}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                    );
                }
            }
        ]
    );
}