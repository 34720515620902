import React, { useState } from "react";

import { Table } from '../../../../../components/table';
import { getLeadColumns } from '../table/getColumns';
import { LeadsModal } from '../../../../../components/modal';

const leadsDefaultSorted = { dataField: 'name', order: 'desc' };

export default function LeadTable(props){
    const { leadData, refreshData } = props;

    const [leadModalMode, setLeadModalMode] = useState(null);
    const [selectedLead, setSelectedLead] = useState(null);
    function handleShowLeadModal(mode, lead){
        setLeadModalMode(mode);
        setSelectedLead(lead);
    }
    const leadColumns = getLeadColumns(handleShowLeadModal);
    function leadOnSubmitCallback(changes = true){
        if(changes) refreshData();
        setLeadModalMode(null);
        setSelectedLead(null);
    }

    return (
        <>
            {leadModalMode && <LeadsModal
                mode={leadModalMode}
                selectedLead={selectedLead}
                onSubmitCallback={leadOnSubmitCallback}
            />}
            <Table
                tableName="leads"
                data={leadData}
                columns={leadColumns}
                defaultSorted={leadsDefaultSorted}
                sizePerPageInitial={20}
                sizePerPageList={[10, 20, 40]}
                emptyMessage="No leads were found"
            />
        </>
    );
}