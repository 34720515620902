import { formatDate } from '../../../../../components/functions';

export function getColumns(){
    return [
        {
            dataField: 'studentName',
            text: 'Student Name',
        },
        {
            dataField: 'cycleDates',
            text: 'Cycle Dates',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(rowA.cycle_start);
                const bDate = new Date(rowB.cycle_start);
                return (bDate - aDate) * swap;
            },
            formatter: (row) => {
                return `${formatDate(row.cycle_start)} to ${formatDate(row.cycle_end)}`;
            }
        },
        {
            dataField: 'hoursUsed',
            text: 'Hours Used',
            formatter: (row) => {
                const hoursStarting = Math.floor(parseInt(row.minutes_starting) / 60);
                const hoursRemaining = Math.floor(parseInt(row.minutes_remaining) / 60);
                const hoursUsed = hoursStarting - hoursRemaining;
                return `${hoursUsed} of ${hoursStarting} (${hoursRemaining} unused)`;
            }
        },
        {
            dataField: 'reserveHoursUsed',
            text: 'Reserve Hours Used',
            formatter: (row) => {
                const reserveUsed = Math.floor(parseInt(row.reserve_used) / 60);
                return `${reserveUsed} hours`;
            }
        },
        {
            dataField: 'reserveHours',
            text: 'Reserve Hours Balance',
            formatter: (row) => {
                const reserveBalance = Math.floor(parseInt(row.reserve_balance_ending) / 60);
                const reserveCredited = Math.floor(parseInt(row.reserve_autocredited) / 60);
                const sign = reserveCredited >= 0 ? '+' : '';
                return `${reserveBalance} (${sign}${reserveCredited} automatically credited)`;
            }
        }
    ]
}