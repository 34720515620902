import React from 'react';

import { formatDateFull, formatTime } from '../../../../../components/functions';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumnsNotes(){
    return [
        {
            dataField: 'id',
            text: 'Relevant Notes',
            formatter: (row) => {
                const sessionNotes = row.sessionNotes ?
                    <div>
                        <b>Session Notes: </b>
                        <span className="text-mpBlue">{row.sessionNotes}</span>
                    </div> : null;
                const workCompleted = row.workCompleted ?
                    <div>
                        <b>Work Completed: </b>
                        <span className="text-mpOrange">{row.workCompleted}</span>
                    </div> : null;
                const reports = row.reports ?
                    <div>
                        <b>Reports: </b>
                        <span className="text-mpLRed">{row.reports}</span>
                    </div> : null;
                return (
                    <>
                        <h4>{formatDateFull(row.date)}</h4>
                        {sessionNotes}
                        {workCompleted}
                        {reports}
                    </>
                );
            }
        },
    ];
}

export function getColumnsAppointments(){
    return [
        {
            dataField: 'id',
            text: 'Appointment Info',
            formatter: (row) => {
                return (
                    <>
                        <div><b>Date: </b> {formatDateFull(row.dateTime)}</div>
                        <div><b>Time: </b> {formatTime(row.startTime)} to {formatTime(row.endTime)}</div>
                        <div><b>Center: </b> {row.centerName}</div>
                        <div><b>Pages/LC: </b> {row.n_pages || 0}/{row.n_lessons || 0}</div>
                        <div>
                            <b>Session Notes: </b>
                            <span className={row.session_notes ? '' : 'text-mpOrange'}>
                                {row.session_notes || 'None'}
                            </span>
                        </div>
                        <div><b>Status: </b> {row.status}</div>
                        <div>
                            <b>Instructors: </b>
                            <span className={row.instructorNames ? '' : 'text-mpOrange'}>
                                {row.instructorNames || 'None'}
                            </span>
                        </div>
                    </>
                );
            }
        },
    ];
}

export function getColumnsFlags(){
    return [
        {
            dataField: 'id',
            text: 'Flag Details',
            formatter: (row) => {
                const created = row.createdByName ?
                    <TooltipWrapper
                        tooltipText={<span>{row.createdByName}</span>}
                    >
                        <span className="text-mpLBlue">{formatDateFull(row.date_created)}</span>
                    </TooltipWrapper> :
                    <span>{formatDateFull(row.date_created)}</span>;

                const updated = row.updatedByName ?
                    <TooltipWrapper
                        tooltipText={<span>{row.updatedByName}</span>}
                    >
                        <span className="text-mpLBlue">{formatDateFull(row.date_updated)}</span>
                    </TooltipWrapper> :
                    <span>{formatDateFull(row.date_updated)}</span>;

                return (
                    <>
                        <div className="flex flex-row"><b>Created: </b>&nbsp;{created}</div>
                        <div className="flex flex-row"><b>Updated: </b>&nbsp;{updated}</div>
                        <div><b>Center: </b> {row.centerName}</div>
                        <div><b>PK/WOB: </b> {row.n_pkfo || 0}/{row.n_wob || 0}</div>
                        <div>
                            <b>Notes: </b>
                            <span className={row.notes ? '' : 'text-mpOrange'}>
                                {row.notes || 'None'}
                            </span>
                        </div>
                        <div>
                            <b>Admin Notes: </b>
                            <span className={row.admin_notes ? '' : 'text-mpOrange'}>
                                {row.admin_notes || 'None'}
                            </span>
                        </div>
                    </>
                );
            }
        },
    ];
}