export function getLessons(questions, lessonList){
    const lessonScores = {};
    for(let q of questions){
        const questionNumber = parseInt(q.question_number);
        const lId1 = parseInt(q.on_incorrect_1);
        const lId2 = parseInt(q.on_incorrect_2);
        const lId3 = parseInt(q.on_incorrect_3);
        const isCorrect = parseInt(q.is_correct) === 1;
        if(lId1 !== -1){
            if(!lessonScores[lId1]){
                lessonScores[lId1] = {
                    questionsCorrect: 0,
                    questionsTotal: 0,
                    questionNumbers: [],
                };
            }
            const ls1 = lessonScores[lId1];
            ls1.questionsTotal++;
            ls1.questionNumbers.push(questionNumber);
            if(isCorrect)ls1.questionsCorrect++;
        }
        if(lId2 !== -1){
            if(!lessonScores[lId2]){
                lessonScores[lId2] = {
                    questionsCorrect: 0,
                    questionsTotal: 0,
                    questionNumbers: [],
                };
            }
            const ls2 = lessonScores[lId2];
            ls2.questionsTotal++;
            ls2.questionNumbers.push(questionNumber);
            if(isCorrect)ls2.questionsCorrect++;
        }
        if(lId3 !== -1){
            if(!lessonScores[lId3]){
                lessonScores[lId3] = {
                    questionsCorrect: 0,
                    questionsTotal: 0,
                    questionNumbers: [],
                };
            }
            const ls3 = lessonScores[lId3];
            ls3.questionsTotal++;
            ls3.questionNumbers.push(questionNumber);
            if(isCorrect)ls3.questionsCorrect++;
        }
    }

    const lessonMap = {};
    lessonList.forEach(l => lessonMap[parseInt(l.id)] = l);

    const lessonsMissed = [];
    Object.entries(lessonScores).forEach(([lessonId, lessonStats]) => {
        const ls = lessonStats;
        ls.score = (100 * ls.questionsCorrect / ls.questionsTotal).toLocaleString(undefined, { maximumFractionDigits: 2 });
        if(parseInt(ls.score) < 99) lessonsMissed.push(lessonMap[parseInt(lessonId)]);
    });

    lessonsMissed.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order));

    return { lessonScores, lessonsMissed };
}