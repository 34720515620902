import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import CustomDownloadModal from './customDownload/_CustomDownloadModal';
import { LearningPlanModal, LearningPlanCompleteModal } from '../../../../components/modal';
import LearningPlanTable from './LearningPlanTable';
import { downloadReport } from './helpers/downloadReport';
import { convertApiToDate, formatDate, formatDateApi } from '../../../../components/functions';
import { Check, SelectSingle, checkResponses, DatePicker, SelectMulti } from '../../../../components/form';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { Socket } from '../../../../components/ws';

import { getFileInfos } from './helpers/helpers';

import {
    fetchMpCentersAll,
    fetchStudentsActive,
    fetchLearningPlansStudent,
    fetchLessonsAll,
} from '../../../../actions';
import { 
    fetchMPLessonsMultiND,
    fetchGraphicND
} from '../../../../actions-nd';

const pageTitle = 'LP Manager';

function LPManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [firstSearch, setFirstSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);
    const [apiError, setApiError] = useState(false);

    const [students, setStudents] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [learningPlanOptions, setLearningPlanOptions] = useState([]);

    const [logoData, setLogoData] = useState(null);

    const [modalMode, setModalMode] = useState(null);

    const { fetchMpCentersAll, fetchStudentsActive, fetchLearningPlansStudent, fetchLessonsAll } = props;

    const filterStudents = useCallback((selectedCenter) => {
        const isAll = parseInt(selectedCenter.value) === 0;
        
        const newStudentOptions = students.filter(s => isAll || parseInt(s.mp_primary_center) === selectedCenter.value)
            .map(s => ({ value: s.user_id, label: `${s.first_name} ${s.last_name}` }));
        
        if(mounted.current){
            setStudentOptions(newStudentOptions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentOptions]);
    const refreshData = useCallback((formik, selectedStudent = {}) => {
        // Need to use the weird argument syntax here because selectedStudent needs to be passed directly in the call
        // in the selectedStudent onChange function, but cannot be passed from refreshData. If not passed directly,
        // it will use the previous selectedStudent value
        (async function refresh(){
            if(loading) return;
            if(mounted.current){
                setLoading(true);
                setFirstSearch(true);
            }

            const selectedStudentId = selectedStudent.value || formik.values.selectedStudent.value;

            const learningPlansRes = await fetchLearningPlansStudent({ studentId: selectedStudentId });
            const newLearningPlans = learningPlansRes.data?.learningPlans || [];
            const newLearningPlanItems = learningPlansRes.data?.learningPlanItems || [];

            const lessonMap = {};
            lessons.forEach(l => lessonMap[parseInt(l.id)] = l);

            const lpItemMap = {};
            newLearningPlanItems.forEach(lpi => {
                lpi.lessonObj = lessonMap[parseInt(lpi.lesson_id)] || {};
                const learningPlanId = parseInt(lpi.learning_plan_id);
                if(!lpItemMap[learningPlanId]) lpItemMap[learningPlanId] = [];
                lpItemMap[learningPlanId].push(lpi);
            });

            newLearningPlans.sort((a, b) => {
                const aIsActive = parseInt(a.active) === 1;
                const bIsActive = parseInt(b.active) === 1;
                if(aIsActive && !bIsActive) return -1;
                else if(!aIsActive && bIsActive) return 1;
                else return parseInt(a.sort_order) - parseInt(b.sort_order);
            }).forEach(lp => {
                const lpItemList = lpItemMap[parseInt(lp.id)] || [];

                if(parseInt(lp.is_review) === 1){
                    lpItemList.forEach(lpi => lpi.isReview = true);
                }
                lp.items = lpItemList;
            });

            const newLearningPlanOptions = newLearningPlans.map(lp => {
                const isInactive = parseInt(lp.active) === 0 ? ' (Inactive)' : '';
                const isReview = parseInt(lp.is_review) === 1 ? ' (Review)' : '';
                return {
                    value: parseInt(lp.id),
                    label: `${lp.name} (${formatDate(lp.date_created)})${isReview}${isInactive}`,
                    obj: lp
                };
            });

            // Reset learningPlanOptions with new and updated LPs.
            const selectedLearningPlanIds = formik.values.selectedLearningPlans.map(lp => parseInt(lp.value));
            const newSelectedLearningPlans = newLearningPlanOptions.filter(lpo => {
                return selectedLearningPlanIds.includes(parseInt(lpo.value));
            });
            await formik.setFieldValue('selectedLearningPlans', newSelectedLearningPlans);
            
            if(mounted.current){
                setLearningPlanOptions(newLearningPlanOptions);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, fetchLearningPlansStudent, lessons]);
    useEffect(() => {
        async function init(){
            setLoading(true);

            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsActive();
            const lessonsRes = await fetchLessonsAll();
            const logoRes = await fetchGraphicND({ fileName: 'MP Long Light@4x.png' });

            const isApiError = checkResponses(centersRes, studentsRes, lessonsRes, logoRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                }
            }

            const newCenters = centersRes.data || [];
            const newCenterOptions = [{ value: 0, label: 'All' }];
            newCenters.forEach(c => newCenterOptions.push({ value: c.id, label: c.name }));
            const newStudents = studentsRes.data || [];
            const newStudentsSorted = newStudents.sort((a, b) => {
                const aIsLead = parseInt(a.is_lead);
                const bIsLead = parseInt(b.is_lead);
                if(aIsLead > bIsLead) return -1;
                else if(aIsLead < bIsLead) return 1;
                else {
                    const aName = `${a.first_name} ${a.last_name}`;
                    const bName = `${b.first_name} ${b.last_name}`;
                    if(aName < bName) return -1;
                    else if(aName > bName) return 1;
                    else return 0;
                }
            });
            const newStudentOptions = newStudentsSorted.filter(s => {
                return parseInt(s.mp_active) === 1 && parseInt(s.is_mp_student) === 1;
            }).map(s => {
                const isLeadText = parseInt(s.is_lead) === 1 ? ' (Lead)' : '';
                return { value: s.user_id, label: `${s.first_name} ${s.last_name}${isLeadText}` };
            });
            const newLessons = lessonsRes.data;

            

            if(mounted.current){
                setCenterOptions(newCenterOptions);
                setStudents(newStudentsSorted);
                setStudentOptions(newStudentOptions);
                setLessons(newLessons);
                setLogoData(logoRes.data || null);

                setLoading(false);
            }
        }

        init();
    }, [fetchMpCentersAll, fetchStudentsActive, fetchLessonsAll]);
    const downloadMultiple = useCallback((downloadDate, selectedLearningPlans, studentName) => {
        (async function(){
            if(loading) return;
            if(mounted.current) setLoading(true);

            const learningPlanItems = [];
            selectedLearningPlans.forEach(lp => {
                const items = lp.obj?.items || [];
                items.forEach(i => learningPlanItems.push(i));
            });
            const relevantDate = convertApiToDate(downloadDate);
            
            const filteredLearningPlanItems = learningPlanItems.filter((lpi) => {
                const lpiDate = new Date(lpi.date_assigned);
                return relevantDate - lpiDate === 0
            })
            const fileInfos = getFileInfos(filteredLearningPlanItems, studentName);
            
            const fetchStatus = await fetchMPLessonsMultiND(fileInfos);
            if(fetchStatus !== true) setDownloadError(fetchStatus);
            else setDownloadError(false);

            if(mounted.current) setLoading(false);
        })();
    }, [loading]);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData(formRef?.current);
        }
    }, [mounted, refreshData, formRef]);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        selectedStudent: studentOptions[0] ? { value: -1, label: 'Please select...' } : { value: -1, label: 'Loading students...' },
                        selectedLearningPlans: [],
                        downloadDate: formatDateApi(new Date()),
                        quickDownload: true,
                        quickDownloadDate: formatDateApi(new Date())
                    }}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            { modalMode === 'complete' ?
                                <LearningPlanCompleteModal
                                    onSubmitCallback={onSubmitCallback}
                                    selectedStudent={formik.values.selectedStudent}
                                    selectedLearningPlan={formik.values.selectedLearningPlans[0]?.obj}
                                />
                                : modalMode === 'customDownload' ? 
                                <CustomDownloadModal
                                    onSubmitCallback={onSubmitCallback}
                                    studentName={formik.values.selectedStudent.label}
                                    selectedLearningPlans={formik.values.selectedLearningPlans}
                                />
                                : modalMode ? <LearningPlanModal
                                    mode={modalMode}
                                    onSubmitCallback={onSubmitCallback}
                                    selectedStudent={formik.values.selectedStudent}
                                    selectedLearningPlan={formik.values.selectedLearningPlans[0]?.obj}
                                /> : null
                            }
                            <h2>Learning Plan Manager</h2>
                            
                            <br/>

                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                    <SelectSingle
                                        id="lp-manager-center"
                                        value={formik.values.selectedCenter}
                                        name="selectedCenter"
                                        label="Filter Students by Center"
                                        onChange={async (e) => {
                                            await formik.handleChange(e);
                                            filterStudents(e.target.value);
                                        }}
                                        options={centerOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="lp-manager-student"
                                        value={formik.values.selectedStudent}
                                        name="selectedStudent"
                                        label="Student"
                                        onChange={async (e) => {
                                            await formik.handleChange(e);
                                            await formik.setFieldValue('selectedLearningPlans', []);
                                            refreshData(formik, e.target.value);
                                        }}
                                        options={studentOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <SelectMulti
                                        id="lp-manager-selectedLearningPlans"
                                        value={formik.values.selectedLearningPlans}
                                        name="selectedLearningPlans"
                                        label="Learning Plans"
                                        onChange={(e) => {
                                            if(e.target.value.length > 5) return;
                                            else formik.handleChange(e)
                                        }}
                                        options={learningPlanOptions}
                                    />
                                    {formik.values.selectedLearningPlans.length === 5 ? 
                                        <div className="text-mpOrange">
                                            Maximum 5 learning plans can be selected at once.
                                        </div> : null
                                    }
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                        disabled={formik.values.selectedStudent.value === -1}
                                    >
                                        + Add LP
                                    </Button>
                                </div>
                                { formik.values.selectedLearningPlans.length ?
                                    <>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => setModalMode('edit')}
                                                disabled={formik.values.selectedStudent.value === -1 ||
                                                    formik.values.selectedLearningPlans.length > 1}
                                            >
                                                Edit LP
                                            </Button>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLRed"
                                                onClick={() => setModalMode('delete')}
                                                disabled={formik.values.selectedStudent.value === -1 ||
                                                    formik.values.selectedLearningPlans.length > 1}
                                            >
                                                Delete LP
                                            </Button>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpEGreen"
                                                onClick={async () => await downloadReport(formik, logoData, loading, setLoading)}
                                                disabled={formik.values.selectedStudent.value === -1}
                                            >
                                                Download LP Report
                                            </Button>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <TooltipWrapper
                                                tooltipText="Download report without opening a popup window"
                                            >
                                                <Check
                                                    id="report-generator-quickDownload"
                                                    name="quickDownload"
                                                    color="mpLRed"
                                                    label={
                                                        <div className="text-mpLBlue">
                                                            Quick Download
                                                        </div>
                                                    }
                                                    checked={formik.values.quickDownload}
                                                    onChange={formik.handleChange}
                                                />
                                            </TooltipWrapper>
                                        </div>
                                        {formik.values.quickDownload ? 
                                            <div className="grid grid-cols-1 gap-y-2">
                                                <DatePicker
                                                    id="report-generator-quickDownloadDate"
                                                    name="quickDownloadDate"
                                                    label="File Name Date"
                                                    value={formik.values.quickDownloadDate}
                                                    onChange={formik.handleChange}
                                                    disabled={loading}
                                                />
                                            </div> : null
                                        }
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => setModalMode('complete')}
                                                disabled={formik.values.selectedStudent.value === -1 ||
                                                    formik.values.selectedLearningPlans.length > 1 ||
                                                    parseInt(formik.values.selectedLearningPlans[0]?.obj?.active) === 0
                                                }
                                            >
                                                Mark LP Complete
                                            </Button>
                                        </div>
                                    </> : null
                                }
                            </div>

                            <br/>

                            { formik.values.selectedLearningPlans.length ? 
                                <>
                                    <div className="flex flex-row gap-x-4 items-end">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>Merges all lessons assigned on the selected date into one PDF.</div>
                                                </>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Group Download</h4>
                                        </TooltipWrapper>
                                    </div>

                                    <div className="flex flex-row gap-x-4 items-end">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <DatePicker
                                                id="lp-manager-download-date"
                                                name="downloadDate"
                                                value={formik.values.downloadDate}
                                                onChange={formik.handleChange}
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpEGreen"
                                                onClick={() => downloadMultiple(
                                                    formik.values.downloadDate,
                                                    formik.values.selectedLearningPlans,
                                                    formik.values.selectedStudent.label
                                                )}
                                                disabled={loading}
                                            >
                                                Download Lessons
                                            </Button>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => setModalMode('customDownload')}
                                                disabled={loading}
                                            >
                                                Custom Download
                                            </Button>
                                        </div>
                                    </div>
                                </> : null
                            }
                            {downloadError ? (
                                <>
                                    <br/>

                                    <div className="text-mpLRed">{downloadError}</div>
                                </>
                            ) : null
                            }
                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                <>
                                    <br/>
                                    <hr/>
                                    <br/>

                                    { firstSearch && formik.values.selectedLearningPlans.length ? 
                                        <LearningPlanTable
                                            studentName={formik.values.selectedStudent.label}
                                            selectedLearningPlans={formik.values.selectedLearningPlans}
                                        /> : null
                                    }
                                </>
                            }
                        </>
                    )}
                </Formik>
            </div>
            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsActive,
    fetchLearningPlansStudent,
    fetchLessonsAll,
})(LPManager)