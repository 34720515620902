import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumnsAA } from '../table/getColumnsAppointments';
import { Table } from '../../../../../components/table';

const defaultSorted=[{ dataField: 'start', order: 'asc' }];

function AATabContent(props){
    const { setInProgress, handleShowModal, refreshRelInfo, nextTime, tableName } = props;

    const columns = useMemo(() => {
        return getColumnsAA(setInProgress, handleShowModal, refreshRelInfo, nextTime);
    }, [setInProgress, handleShowModal, refreshRelInfo, nextTime]);
    return (
        <>
            <Table
                tableName={tableName}
                data={props.appointments}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No appointments were found"
            />
        </>
    );
}

export default connect(null, {

})(AATabContent);