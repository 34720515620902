import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalProcessing, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { getTimeObject, formatDateApi, formatDateFull, formatTime } from '../../functions';
import { DatePicker, SelectSingle, FormikControl, TimePicker } from '../../form';
import { TooltipWrapper } from '../../display';

import { validationSchema, getStudentHeader,
    getInitStudentOption, getInitCenterOption, durationOptions, getInitDurationOption,
    weightOptions, getInitWeightOption, statusOptions, getInitStatusOption    
} from './helpers';

function AppointmentsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedCenter, selectedDate, centerOptions, selectedAppointment,
        studentOptions, parents, loaded, submitted, handleClose, handleSubmit, forceDelete } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        {mode === 'create' ? 'Created' : mode === 'edit' ?
                        'Updated' : mode === 'delete' ? 'Deleted' : 'Unexpected mode'} successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const sa = selectedAppointment;
        const student = studentOptions.find(s => s.object.user_id === sa.student)?.object || {};
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this appointment?</h4>
                            <div className="grid grid-cols-1">
                                <div>
                                    <b>Student: </b>{student ? `${student.first_name} ${student.last_name}` :
                                    `Unknown student (UID: ${sa.student})`}
                                </div>
                                <div><b>Time: </b>{formatDateFull(sa.date)} at {formatTime(sa.time)}</div>
                                <div><b>Center: </b>{sa.centerName}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>                         
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-sm mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                {formik.status ?
                                    <TooltipWrapper
                                        tooltipText="Deletes the appointment without updating hours, overriding most errors."
                                    >
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => forceDelete(formik)}
                                        >
                                            Force Delete
                                        </Button>
                                    </TooltipWrapper>
                                : null}
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    date: formatDateApi(selectedAppointment.date || selectedDate.date || new Date()),
                    time: getTimeObject(parseInt(selectedAppointment.time || 840)),
                    center: getInitCenterOption(selectedAppointment.center || selectedCenter.value, centerOptions),
                    student: getInitStudentOption(selectedAppointment.student, studentOptions),
                    duration: getInitDurationOption(selectedAppointment.duration),
                    weight: getInitWeightOption(selectedAppointment.weight),
                    status: getInitStatusOption(selectedAppointment.status),
                    specialNotes: selectedAppointment.special_notes || '',
                    specialNotes2: selectedAppointment.special_notes_2 || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Appointment Date</h4>
                                    <DatePicker
                                        id="appointment-form-datepicker"
                                        name="date"
                                        value={formik.values.date}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.date ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.date}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <TooltipWrapper
                                        tooltipText="Must be an increment of 30 minutes"
                                    >
                                        <h4 className="text-mpLBlue">Time</h4>
                                    </TooltipWrapper>
                                    <TimePicker
                                        id="appointment-form-time"
                                        name="time"
                                        value={formik.values.time?.formatted24 || ''}
                                        onChange={formik.handleChange}
                                        step={1800} // 30 min
                                    />
                                    {formik.errors.time ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.time}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    {getStudentHeader(formik.values.student.object, parents)}
                                    <SelectSingle
                                        id="appointments-student"
                                        name="student"
                                        disabled={mode !== 'create'}
                                        value={formik.values.student}
                                        onChange={(e) => {
                                            const student = e.target.value;
                                            const studentObj = student.object;
                                            const newValues = { ...formik.values };

                                            // Adjust weight to match student weight
                                            const weightOption = getInitWeightOption(studentObj.mp_weight);

                                            newValues.student = student;
                                            newValues.weight = weightOption;
                                            newValues.specialNotes = studentObj.mp_special_notes || '';
                                            newValues.specialNotes2 = studentObj.mp_special_notes_2 || '';


                                            formik.setValues(newValues);
                                        }}
                                        options={studentOptions}
                                    />
                                    {formik.errors.student ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.student}
                                        </ErrorMessage>
                                    ) : null}
                                    {mode !== 'create' && (
                                        <div className="text-mpOrange" style={{ fontSize: "8pt" }} >
                                            Field disabled. Create a new appointment if you would like to select a different student.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br/>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="appointments-center"
                                        name="center"
                                        value={formik.values.center}
                                        onChange={formik.handleChange}
                                        options={centerOptions}                                        
                                    />
                                    {formik.errors.center ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.center}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Duration</h4>
                                    <SelectSingle
                                        id="appointments-duration"
                                        name="duration"
                                        value={formik.values.duration}
                                        onChange={formik.handleChange}
                                        options={durationOptions}
                                    />
                                    {formik.errors.duration ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.duration}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                            <br/>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <TooltipWrapper
                                        tooltipText={`This will ONLY update the weight for the selected appointment.`}
                                    >
                                        <h4 className="text-mpLBlue">Weight</h4>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id="appointments-weight"
                                        name="weight"
                                        value={formik.values.weight}
                                        onChange={formik.handleChange}
                                        options={weightOptions}
                                    />
                                    {formik.errors.weight ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.weight}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <TooltipWrapper
                                        tooltipText={
                                            <>
                                                <div>Scheduled, In Progress, Completed: Hours charged</div>
                                                <br/>
                                                <div>
                                                    Missed (hours charged) &amp; Cancelled (not charged):
                                                    Some filters can be used to remove these entries
                                                </div>
                                                <br/>
                                                <div>
                                                    Not Charged: Session completed, but due to an issue,
                                                    the hours were refunded
                                                </div>
                                            </>
                                        }
                                    >
                                        <h4 className="text-mpLBlue">Status</h4>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id="appointments-status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={statusOptions}
                                    />
                                    {formik.errors.status ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.status}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <TooltipWrapper
                                        tooltipText={`This will ONLY update the "special notes" (red !) for the selected appointment.`}
                                    >
                                        <h4 className="text-mpLBlue">Special Notes</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="appointments-special-notes"
                                        as="textarea"
                                        name="specialNotes"
                                        value={formik.values.specialNotes}
                                        onChange={formik.handleChange}
                                        placeholder="This will only update the special notes for this session. To adjust future notes for all of a student's future sessions, please update the student's profile."
                                    />
                                    {formik.errors.specialNotes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.specialNotes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <TooltipWrapper
                                        tooltipText={`This will ONLY update the "special notes instructional" (blue !) for the selected appointment.`}
                                    >
                                        <h4 className="text-mpLBlue">Special Notes Instructional</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="appointments-special-notes-2"
                                        as="textarea"
                                        name="specialNotes2"
                                        value={formik.values.specialNotes2}
                                        onChange={formik.handleChange}
                                        placeholder="This will only update the special notes instructional for this session. To adjust future notes for all of a student's future sessions, please update the student's profile."
                                    />
                                    {formik.errors.specialNotes2 ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.specialNotes2}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed mr-1">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed" style={{ marginRight: "2rem" }}>
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    onClick={handleClose}
                                    disabled={formik.isSubmitting}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(AppointmentsBodyFooter);