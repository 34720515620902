import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, SelectSingle, FormikControl, checkResponses, Check } from '../../../../components/form';
import { formatDateApi, convertApiToDate, getDateObject } from '../../../../components/functions';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import AppointmentsTable from "./AppointmentsTable";
import { Socket } from '../../../../components/ws';

import {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchMembersAll,
    fetchAdminUsersAll,
    fetchAppointmentsDaterangeCenterStudentName,
} from '../../../../actions';

const pageTitle = 'Appointment Search';

const start = new Date();
start.setDate(start.getDate() - 7);
const startApi = formatDateApi(start);
const end = new Date();
end.setDate(end.getDate() + 14);
const endApi = formatDateApi(end);

function AppointmentSearch(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Form
    const [drsValid, setDrsValid] = useState(true);
    // Data
    const [students, setStudents] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [members, setMembers] = useState([]);
    const [centers, setCenters] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);

    const { fetchMpCentersAll, fetchStudentsAll, fetchMembersAll, fetchAdminUsersAll,
        fetchAppointmentsDaterangeCenterStudentName } = props;

    const hoursInfo = useMemo(() => {
        let scheduled = 0;
        let scheduledH = 0;
        let inProgress = 0;
        let inProgressH = 0;
        let completed = 0;
        let completedH = 0;
        let missed = 0;
        let missedH = 0;
        let cancelled = 0;
        let cancelledH = 0;
        let notCharged = 0;
        let notChargedH = 0;

        appointments.forEach(a => {
            switch(a.status){
                case 'Scheduled':
                    scheduled++;
                    scheduledH += parseInt(a.duration);
                    break;
                case 'In Progress':
                    inProgress++;
                    inProgressH += parseInt(a.duration);
                    break;
                case 'Completed':
                    completed++;
                    completedH += parseInt(a.duration);
                    break;
                case 'Missed':
                    missed++;
                    missedH += parseInt(a.duration);
                    break;
                case 'Cancelled':
                    cancelled++;
                    cancelledH += parseInt(a.duration);
                    break;
                case 'Not Charged':
                    notCharged++;
                    notChargedH += parseInt(a.duration);
                    break;
                default:
                    break;
            }
        });

        scheduledH /= 60;
        inProgressH /= 60;
        completedH /= 60;
        missedH /= 60;
        cancelledH /= 60;
        notChargedH /= 60;

        const total = scheduled + inProgress + completed + missed + notCharged;
        const totalH = scheduledH + inProgressH + completedH + missedH + notChargedH;
        const totalCharged = scheduled + inProgress + completed + missed;
        const totalChargedH = scheduledH + inProgressH + completedH + missedH;

        return { scheduled, scheduledH, inProgress, inProgressH, completed, completedH,
            missed, missedH, cancelled, cancelledH, notCharged, notChargedH, total, totalH,
            totalCharged, totalChargedH };
    }, [appointments]);

    const filterAppointments = useCallback((newAppointments = appointments, hideCancelled = true) => {
        let newFilteredAppointments = [];
        if(hideCancelled === false) newFilteredAppointments = [...newAppointments];
        else newFilteredAppointments = newAppointments.filter(a => !['Cancelled'].includes(a.status));

        if(mounted.current) setFilteredAppointments(newFilteredAppointments);
    }, [appointments]);
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading || !drsValid || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate, selectedCenter, searchQuery, hideCancelled } = formRef.current.values;

            const startDateObj = getDateObject(convertApiToDate(startDate));
            const endDateObj = getDateObject(convertApiToDate(endDate));
    
            const aaRes = await fetchAppointmentsDaterangeCenterStudentName({
                startDate: startDateObj.raw,
                endDate: endDateObj.raw,
                center: selectedCenter.value,
                searchQuery
            });
            const isApiError = checkResponses(aaRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointments = aaRes.data?.appointments || [];
            const newInstructorAssignments = aaRes.data?.assignments || [];

            const appointmentToIaMap = {};
            newInstructorAssignments.forEach(ia => {
                if(!appointmentToIaMap[parseInt(ia.appointment_id)]) appointmentToIaMap[parseInt(ia.appointment_id)] = [];
                appointmentToIaMap[parseInt(ia.appointment_id)].push(ia.instructor);
            });
    
            const studentToNameMap = {};
            students.forEach(s => studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const userToNameMap = {};
            employees.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            members.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);

            newAppointments.forEach(a => {
                const aptDateTime = new Date(a.date_time);
                a.startTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
                a.endTime = a.startTime + parseInt(a.duration);

                a.studentName = studentToNameMap[a.student] || `Unknown student (UID: ${a.student})`;
    
                const assignmentList = appointmentToIaMap[parseInt(a.id)] || [];
                if(!assignmentList.length) a.instructorNames = 'None';
                else {
                    let names = '';
                    assignmentList.forEach(a => {
                        if(names.length) names += ', ';
                        const newName = userToNameMap[a];
                        names += newName || '??';
                    });
                    a.instructorNames = names;
                }

                a.createdByName = userToNameMap[a.created_by] || `Unknown user (UUID: ${a.created_by})`;
                a.updatedByName = userToNameMap[a.updated_by] || `Unknown user (UUID: ${a.updated_by})`;
            });
    
            const centerMap = {};
            centers.forEach(c => centerMap[parseInt(c.id)] = c.name);
            newAppointments.forEach(a => {
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
            });
            
            if(mounted.current){
                setAppointments(newAppointments);
                filterAppointments(newAppointments, hideCancelled);
                setLoading(false);
            }
        })();
    }, [mounted, loading, centers, employees, members, students, drsValid,
        filterAppointments, fetchAppointmentsDaterangeCenterStudentName]);
    useEffect(() => {
        async function init(){
            if(mounted.current) setLoading(true);

            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const instructorsRes = await fetchAdminUsersAll();
            const membersRes = await fetchMembersAll();
            const isApiError = checkResponses(centersRes, studentsRes, instructorsRes, membersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newEmployees = instructorsRes.data || [];
            const newMembers = membersRes.data || [];

            const newCenterOptions = [
                { value: 'all', label: 'All'},
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name })) 
            ];            

            if(mounted.current){
                await setLoading(false);

                await setCenters(newCenters);
                await setCenterOptions(newCenterOptions);
                await setStudents(newStudents);
                await setEmployees(newEmployees);
                await setMembers(newMembers);
            
                setHasLoaded(true);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <h2>Appointment Search ({filteredAppointments.length})</h2>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        searchQuery: '',
                        hideCancelled: true
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div>
                                    <DateRangeSelector
                                        id="appointmentSearch-drs-1"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <SelectSingle
                                        id="appointmentSearch-center"
                                        label="Center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                </div>
                            </div>

                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                <>
                                    <div className="flex flex-row gap-x-4 items-end items-center">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <FormikControl
                                                id="appointmentSearch-query"
                                                name="searchQuery"
                                                placeholder="Search by student name..."
                                                value={formik.values.searchQuery}
                                                onChange={formik.handleChange}
                                                shouldHandleSubmit={true}
                                                onSubmit={formik.handleSubmit}
                                            />
                                        </div>
                                        <div className="flex">
                                            <Button
                                                color="lte-mpTeal"
                                                onClick={formik.handleSubmit}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 ml-auto items-end">
                                            <TooltipWrapper
                                                tooltipText={
                                                    <div>
                                                        <div>
                                                            What gets exported?
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            All appointments that are currently filtered ({filteredAppointments.length} items).
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <CSVExport
                                                    title="Appointments"
                                                    label="Export Appointments to CSV"
                                                    data={filteredAppointments}
                                                />
                                            </TooltipWrapper>
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Check
                                                id="appointmentSearch-hideCancelled"
                                                name="hideCancelled"
                                                label="Hide Cancelled"
                                                color="mpLRed"
                                                checked={formik.values.hideCancelled}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    filterAppointments(appointments, e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </form>
                    )}
                </Formik>

                {
                    appointments.length >= 2000 ? 
                    <>
                        <br/>
                        <div className="text-mpLRed">Only the first 2000 results were returned.</div>
                    </>
                    : null
                }
                { apiError ? null : 
                    <>
                        <br/>

                        <h4>Appointments Summary</h4>
                        <br/>
                        <div className="flex flex-row gap-x-2 text-sm">
                            <div>
                                <b>Scheduled:</b> {hoursInfo.scheduled} ({hoursInfo.scheduledH} h)
                            </div>||
                            <div>
                                <b>In Progress:</b> {hoursInfo.inProgress} ({hoursInfo.inProgressH} h)
                            </div>||
                            <div>
                                <b>Completed:</b> {hoursInfo.completed} ({hoursInfo.completedH} h)
                            </div>||
                            <TooltipWrapper
                                tooltipText="Includes everything but cancelled and no charge appointments."
                            >
                                <div><span className="text-mpLBlue" ><b>Total Charged:</b></span> {hoursInfo.totalCharged} ({hoursInfo.totalChargedH} h)</div>
                            </TooltipWrapper>
                        </div>
                        <div className="h-2 clear-both"/>
                        <div className="flex flex-row gap-x-2 text-sm">
                            <TooltipWrapper
                                tooltipText="Includes no-shows. These count towards a student's used hours."
                            >
                                <div><span className="text-mpLBlue" ><b>Missed:</b></span> {hoursInfo.missed} ({hoursInfo.missedH} h)</div>
                            </TooltipWrapper>||
                            <TooltipWrapper
                                tooltipText="These do not count towards a student's used hours."
                            >
                                <div><span className="text-mpLBlue" ><b>Cancelled:</b></span> {hoursInfo.cancelled} ({hoursInfo.cancelledH} h)</div>
                            </TooltipWrapper>||
                            <TooltipWrapper
                                tooltipText="Appointments where the student attended, but their hours were not charged."
                            >
                                <div><span className="text-mpLBlue" ><b>Not Charged:</b></span> {hoursInfo.notCharged} ({hoursInfo.notChargedH} h)</div>
                            </TooltipWrapper>||
                            <TooltipWrapper
                                tooltipText="Includes everything but cancelled appointments."
                            >
                                <div><span className="text-mpLBlue" ><b>Total:</b></span> {hoursInfo.total} ({hoursInfo.totalH} h)</div>
                            </TooltipWrapper>
                        </div>

                        <br/>

                        {hasLoaded && 
                            <AppointmentsTable
                                appointments={filteredAppointments}
                                refreshData={refreshData}
                                permissions={props.auth.permissions}
                            />
                        }
                    </>
                }
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchMembersAll,
    fetchAdminUsersAll,
    fetchAppointmentsDaterangeCenterStudentName,
})(AppointmentSearch);