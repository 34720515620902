import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from "../../../../components/display";
import { SelectSingle, checkResponses } from "../../../../components/form";
import { CSVExport } from '../../../../components/export';
import AttendanceTable from './AttendanceTable';
import { Socket } from '../../../../components/ws';

import {
    fetchStudentsCenter,
    fetchMpCentersAll,
    fetchStudentAttendanceHistory,
} from '../../../../actions';

const pageTitle = 'Student Attendance';

function StudentAttendance(props){
    const mounted = useRef();
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [students, setStudents] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);
    const [attendances, setAttendances] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const { fetchStudentsCenter, fetchMpCentersAll, fetchStudentAttendanceHistory } = props;

    const handleCenter = useCallback((selectedCenter) => {
        (async function(){
            if(loading || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
            
            const studentsRes = await fetchStudentsCenter({ center: selectedCenter.value });
            const isApiError = checkResponses(studentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newStudents = (studentsRes.data || []).filter(s => {
                return parseInt(s.is_mp_student) === 1 && parseInt(s.mp_active) === 1;
            });
            const newStudentOptions = [{ value: selectedCenter.label, label: 'Please select a student...' },
                ...newStudents.map(s => ({ value: s.user_id, label: `${s.first_name} ${s.last_name}`}))]
    
            if(mounted.current) {
                setStudents(newStudents);
                setStudentOptions(newStudentOptions);
                setLoading(false);
            }
        })()
    }, [loading, mounted, fetchStudentsCenter]);
    const handleStudent = useCallback((selectedStudent) => {
        (async function(){
            if(loading || !formRef.current.values) return;
            if(mounted.current) setLoading(true);

            const attendancesRes = await fetchStudentAttendanceHistory({ studentId: selectedStudent.value });
            const isApiError = checkResponses(attendancesRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAttendances = attendancesRes.data || [];
    
            const studentMap = {};
            students.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const newAttendancesAppended = newAttendances.map(a => {
                a.studentName = studentMap[a.student] || `Unknown student (UID: ${a.student})`;
                return a;
            });
    
            if(mounted.current){
                setAttendances(newAttendancesAppended);
                setLoading(false);
            }
        })()
    }, [loading, mounted, students, fetchStudentAttendanceHistory]);
    useEffect(() => {
        async function init(){
            const centersRes = await fetchMpCentersAll();
            const isApiError = checkResponses(centersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCenters = centersRes.data || [];

            const newCenterOptions = [
                { value: 'all', label: 'All'},
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name }))
            ];

            if(mounted.current){
                await setCenterOptions(newCenterOptions);
    
                await handleCenter(newCenterOptions[0]);
    
                setHasLoaded(true);
            }
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <h2>Attendance History</h2>
                <br/>
                <Formik
                    initialValues={{
                        selectedCenter: { value: 'all', label: 'All'},
                        selectedStudent: { value: -1, label: 'Please select a student...' }
                    }}
                    innerRef={formRef}
                >
                    {formik => (
                        <div className="flex flex-row gap-x-4 items-end items-center">
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <SelectSingle
                                    id="studentAttendance-select-1"
                                    name="selectedCenter"
                                    label="Center (this just simplifies the list of student options)"
                                    value={formik.values.selectedCenter}
                                    options={centerOptions}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleCenter(e.target.value);
                                        formik.setFieldValue('selectedStudent',
                                            formik.initialValues.selectedStudent);
                                        setAttendances([]);
                                    }}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <SelectSingle
                                    id="studentAttendance-select-2"
                                    name="selectedStudent"
                                    label="Student"
                                    value={formik.values.selectedStudent}
                                    options={studentOptions}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleStudent(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="flex items-center ml-auto">
                                <TooltipWrapper
                                    tooltipText={
                                        <div>
                                            <div>
                                                What gets exported?
                                            </div>
                                            <br/>
                                            <div>
                                                All attendances that have been filtered ({attendances.length} items).
                                            </div>
                                        </div>
                                    }
                                >
                                    <CSVExport
                                        title="Student_Attendance"
                                        label="Export Attendance to CSV"
                                        data={attendances}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>
                    )}
                </Formik>

                <br/>
                
                {apiError ? <div className="text-mpLRed">{apiError}</div> :
                    hasLoaded &&
                    <AttendanceTable
                        attendances={attendances}
                    />
                }
            </div>

            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    fetchStudentsCenter,
    fetchMpCentersAll,
    fetchStudentAttendanceHistory,
})(StudentAttendance);