import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import StampsModalBodyFooter from './StampsModalBF';
import { checkResponse } from '../../form';
import { getStampChanges } from './helpers';

import {
    fetchStudentsAll,
    fetchStampParams,
    fetchAppointmentsIds,
    updateStudentStamps,
    updateAppointmentStampsGiven,
    createStampsLog
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function StampsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false)
    const [studentOptions, setStudentOptions] = useState([]);
    const [stampParams, setStampParams] = useState([]);
    const [appointment, setAppointment] = useState({});

    const { selectedAppointment, onSubmitCallback, fetchStudentsAll, fetchStampParams, fetchAppointmentsIds,
        createStampsLog, updateStudentStamps, updateAppointmentStampsGiven } = props;
    
    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            const stampsRes = await fetchStampParams();
            const appointmentsRes = await fetchAppointmentsIds({ ids: [selectedAppointment?.id] });
            const newStudents = studentsRes.data || [];
            const newStampParams = stampsRes.data?.[0] || {};
            const newAppointment = appointmentsRes.data?.appointments?.[0] || {};
            const aptDateTime = new Date(newAppointment.date_time);
            newAppointment.startTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
            newAppointment.endTime = newAppointment.startTime + parseInt(newAppointment.duration);

            const studentOptions = newStudents.filter(s => {
                return parseInt(s.is_mp_student) === 1 && parseInt(s.mp_active) === 1;
            }).map(s => ({ value: s.user_id, label: `${s.first_name} ${s.last_name}`, object: s }));

            if(mounted.current){
                setStudentOptions(studentOptions);
                setStampParams(newStampParams);
                setAppointment(newAppointment);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);

            // PREPARE DATA //
            const nss = { errored: false, completed: false };
            nss.updateStudent = { name: 'Update student stamps', completed: false, message: '' };
            nss.createStampsLog = { name: 'Create stamps log', completed: false, message: '' };

            const {
                newTotalCards,
                newCurrentCards,
                newCurrentStamps,
            } = getStampChanges(values.selectedStudent.object, values.selectedType, values.amount, stampParams);
            // Set stamps to 0 if we have gone negative.
            let adjustedCurrentStamps = newTotalCards < 0 || newCurrentCards < 0 ? 0 : newCurrentStamps;
            const studentParams = {
                student: values.selectedStudent.value,
                currentStamps: adjustedCurrentStamps,
                currentCards: Math.max(newCurrentCards, 0),
                totalCards: Math.max(newTotalCards, 0),
            };
            const stampsLogParams = {
                student: values.selectedStudent.value,
                type: values.selectedType.value,
                amount: values.amount,
                notes: values.stampsNotes
            }

            let updateAppointmentStamps = false;
            const stampsGiven = values.stampsGiven ? 1 : 0;
            let valueToSet = stampsGiven;
            const shouldUpdateAppointment = selectedAppointment && stampsGiven !== parseInt(selectedAppointment.stamps_given);
            const validTypeAmount = ['Give Stamps', 'Give Cards'].includes(values.selectedType.value) && values.amount > 0;
            if(shouldUpdateAppointment || validTypeAmount){
                updateAppointmentStamps = true;
                nss.updateAppointmentStamps = { name: 'Update appointment stamps given', completed: false, message: '' };
                if(validTypeAmount) valueToSet = 1;
            }
            // END - PREPARE DATA //

            // SEND DATA //
            const ussResponse = await updateStudentStamps(studentParams);
            nss.updateStudent.completed = true;
            nss.errored = !checkResponse(ussResponse, mounted, (resp) => nss.updateStudent.message = resp) || nss.errored;
            setSubmissionStatus({...nss});

            const cslResponse = await createStampsLog(stampsLogParams);
            nss.createStampsLog.completed = true;
            nss.errored = !checkResponse(cslResponse, mounted, (resp) => nss.updateStudent.message = resp) || nss.errored;
            setSubmissionStatus({...nss});

            // selectedAppointment does not work if this form is opened from ActionButtons
            if(selectedAppointment && updateAppointmentStamps){
                const updateAppointmentParams = { id: selectedAppointment.id, stampsGiven: valueToSet }
                const uasgResponse = await updateAppointmentStampsGiven(updateAppointmentParams);
                nss.updateAppointmentStamps.completed = true;
                nss.errored = !checkResponse(uasgResponse, mounted, (resp) => nss.updateAppointmentStamps.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }
            // END - SEND DATA
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus(`Error updating stamps.`);
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [handleClose, stampParams, updateStudentStamps, updateAppointmentStampsGiven,
        createStampsLog, selectedAppointment, oneSuccess]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Stamps Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <StampsModalBodyFooter
                    selectedAppointment={appointment}
                    studentOptions={studentOptions}
                    stampParams={stampParams}
                    loaded={loaded}
                    submitted={submitted}
                    submissionStatus={submissionStatus}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsAll,
    fetchStampParams,
    fetchAppointmentsIds,
    updateStudentStamps,
    updateAppointmentStampsGiven,
    createStampsLog
})(StampsModal);