import { formatDateTimeFull } from '../../../../../components/functions';
import { renderPhone } from '../../../../../components/display';

export function getColumnsTexts(){
    return [
        {
            dataField: 'textLogId', // Needs to be id for key mapping in table
            text: 'Timestamp',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(rowA.timestamp);
                const bDate = new Date(rowB.timestamp);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDateTimeFull(row.timestamp),
            headerStyle: () => ({ width: "20%" })
        }, {
            dataField: 'number',
            text: 'Sent To',
            sort: true,
            formatter: (row) => renderPhone(row.number),
            headerStyle: () => ({ width: "15%" })
        }, {
            dataField: 'textBody',
            text: 'Body',
            headerStyle: () => ({ width: "75%" })
        }
    ];
}

export function getColumnsEmails(){
    return [
        {
            dataField: 'emailLogId', // Needs to be id for key mapping in table
            text: 'Timestamp',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(rowA.timestamp);
                const bDate = new Date(rowB.timestamp);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDateTimeFull(row.timestamp),
            headerStyle: () => ({ width: "15%" })
        }, {
            dataField: 'recipients',
            text: 'Recipients',
            sort: true,
            headerStyle: () => ({ width: "20%" })
        }, {
        }, {
            dataField: 'subject',
            text: 'Subject',
            sort: true,
            headerStyle: () => ({ width: "10%" })
        }, {
            dataField: 'emailBody',
            text: 'Body',
            headerStyle: () => ({ width: "55%" })
        }
    ];
}