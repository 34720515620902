import { basicPathDownloadND } from '../basicPathND';
import { PDFDocument } from 'pdf-lib';
import { appendLogo, appendSquare, appendStudentName, addFlagWarningPage, splitAndZip } from '../../components/pdf';
import { openPopup } from '../../components/functions';


// function downloadItem(url, fileName){
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', `${fileName}.pdf`);

//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// }

export const fetchMPLessonsSingleND = async (fileInfo) => {
    const path = '/mp-files';
    const reqType = 'get';

    const options = fileInfo.options;
    const params = { fileInfo };

    const graphicParams = {
        fileInfo: {
            fileName: 'MP Outline Light@4x.png',
            type: 'graphic'
        }
    };

    const res = await basicPathDownloadND(params, path, reqType);
    const resGraphic = await basicPathDownloadND(graphicParams, path, reqType);

    if(res.data){
        let pdfDoc = await PDFDocument.load(res.data);
        if(options.appendName) pdfDoc = await appendStudentName(pdfDoc, options.studentName);
        if(options.appendLogo) pdfDoc = await appendLogo(pdfDoc, resGraphic.data);
        if(options.appendSquare) pdfDoc = await appendSquare(pdfDoc, options.squareColor);

        const pdfData = await pdfDoc.save();

        openPopup(pdfData);
    }
}

export const fetchMPLessonsMultiND = async (fileInfos, additionalOptions = {}) => {
    const path = '/mp-files';
    const reqType = 'get';

    const fileData = [];

    if(fileInfos.length > 10){
        return 'Maximum 10 files can be combined at once.';
    } else if(!fileInfos.length) {
        return 'No files were found';
    }

    for(let fileInfo of fileInfos){
        const params = { fileInfo };
        const options = fileInfo.options;

        const res = await basicPathDownloadND(params, path, reqType);
        if(!res.data){
            return `No data returned for file with ID ${fileInfo.lesson_id}`;
        }
        fileData.push({ data: res.data, options });
    }    

    const graphicParams = {
        fileInfo: {
            fileName: 'MP Outline Light@4x.png',
            type: 'graphic'
        }
    };
    const resGraphic = await basicPathDownloadND(graphicParams, path, reqType);
    
    // Track current lesson in order to insert Flag Warning Page appropriately
    let flagPageLessonsOffset = parseInt(additionalOptions.flagPageLessonsOffset);
    let currentLessonIndex = fileData.length - 1;

    if(fileData.length){
        let combinedPdf = await PDFDocument.create();
        for(let file of fileData){
            const { data, options } = file;
            let newPages = await PDFDocument.load(data);
            if(options.appendName) newPages = await appendStudentName(newPages, options.studentName);
            if(options.appendLogo) newPages = await appendLogo(newPages, resGraphic.data);
            if(options.appendSquare) newPages = await appendSquare(newPages, options.squareColor);

            const copiedPages = await combinedPdf.copyPages(newPages, newPages.getPageIndices());
            for(let page of copiedPages){
                combinedPdf.addPage(page);
            }

            // Append Flag Warning Page if requested
            if(currentLessonIndex === flagPageLessonsOffset){
                const graphicParams2 = {
                    fileInfo: {
                        fileName: 'flag.png',
                        type: 'graphic'
                    }
                };
                const flagGraphic = await basicPathDownloadND(graphicParams2, path, reqType);
                combinedPdf = await addFlagWarningPage(combinedPdf, flagGraphic.data);
            }
            currentLessonIndex--;
        }
        
        
        if(additionalOptions.splitInFours){
            await splitAndZip(combinedPdf, 4);
        } else {
            const pdfData = await combinedPdf.save();
            openPopup(pdfData);
        }

        return true;
    } else return 'Unexpected error.'
}