import React from 'react';

export function SVGIcon(props){
    const { fileName, opacity = 0.7, height = "1.5rem", width = "1.5rem" } = props;
    
    let srcPath = '';
    switch(fileName){
        case 'answer-key': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092586/Custom%20SVG/answer-key.svg'; break;
        case 'arrow-down': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092586/Custom%20SVG/arrow-down.svg'; break;
        case 'arrow-up': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092586/Custom%20SVG/arrow-up.svg'; break;
        case 'calculator': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092586/Custom%20SVG/calculator.svg'; break;
        case 'calculator-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092587/Custom%20SVG/calculator-blue.svg'; break;
        case 'calendar': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092587/Custom%20SVG/calendar.svg'; break;
        case 'chart-positive': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092588/Custom%20SVG/chart-positive.svg'; break;
        case 'chart-positive-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092588/Custom%20SVG/chart-positive-blue.svg'; break;
        case 'check': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092588/Custom%20SVG/check.svg'; break;
        case 'check-green': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1683826558/Custom%20SVG/check-green.svg'; break;
        case 'clock': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092588/Custom%20SVG/clock.svg'; break;
        case 'clock-green': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092588/Custom%20SVG/clock-green.svg'; break;
        case 'clock-red': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092588/Custom%20SVG/clock-red.svg'; break;
        case 'computer': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092590/Custom%20SVG/computer.svg'; break;
        case 'computer-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092590/Custom%20SVG/computer-blue.svg'; break;
        case 'contract': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092590/Custom%20SVG/contract.svg'; break;
        case 'convert': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092590/Custom%20SVG/convert.svg'; break;
        case 'ellipsis': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092590/Custom%20SVG/ellipsis.svg'; break;
        case 'eraser': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092590/Custom%20SVG/eraser.svg'; break;
        case 'exclamation': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092591/Custom%20SVG/exclamation.svg'; break;
        case 'exclamation-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092591/Custom%20SVG/exclamation-blue.svg'; break;
        case 'exclamation-green': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092591/Custom%20SVG/exclamation-green.svg'; break;
        case 'exclamation-red': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092591/Custom%20SVG/exclamation-red.svg'; break;
        case 'external-link': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092591/Custom%20SVG/external-link.svg'; break;
        case 'finger-counting': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092592/Custom%20SVG/finger-counting.svg'; break;
        case 'finger-counting-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092592/Custom%20SVG/finger-counting-blue.svg'; break;
        case 'flag': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092593/Custom%20SVG/flag.svg'; break;
        case 'flag-color': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092593/Custom%20SVG/flag-color.svg'; break;
        case 'flag-red': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092593/Custom%20SVG/flag-red.svg'; break;
        case 'folder-open': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092593/Custom%20SVG/folder-open.svg'; break;
        case 'folder-open-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092593/Custom%20SVG/folder-open-blue.svg'; break;
        case 'hand': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092594/Custom%20SVG/hand.svg'; break;
        case 'magnifying-glass': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1683826557/Custom%20SVG/magnifying-glass.svg'; break;
        case 'pencil': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092594/Custom%20SVG/pencil.svg'; break;
        case 'plus': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092594/Custom%20SVG/plus.svg'; break;
        case 'print': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/print.svg'; break;
        case 'question': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/question.svg'; break;
        case 'shapes': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/shapes.svg'; break;
        case 'shapes-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/shapes-blue.svg'; break;
        case 'spinner': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/spinner.svg'; break;
        case 'spinner-red': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/spinner-red.svg'; break;
        case 'spinner-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092581/Custom%20SVG/spinner-blue.svg'; break;
        case 'star': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092583/Custom%20SVG/star.svg'; break;
        case 'sync': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092583/Custom%20SVG/sync.svg'; break;
        case 'sync-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092583/Custom%20SVG/sync-blue.svg'; break;
        case 'times': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092583/Custom%20SVG/times.svg'; break;
        case 'times-red': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1683826557/Custom%20SVG/times-red.svg'; break;
        case 'trash': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092583/Custom%20SVG/trash.svg'; break;
        case 'undo': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092584/Custom%20SVG/undo.svg'; break;
        case 'unlink': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092585/Custom%20SVG/unlink.svg'; break;
        case 'upcoming-exams': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092585/Custom%20SVG/upcoming-exams.svg'; break;
        case 'upcoming-exams-blue': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092585/Custom%20SVG/upcoming-exams-blue.svg'; break;
        case 'weight': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092585/Custom%20SVG/weight.svg'; break;
        case 'weight-green': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092586/Custom%20SVG/weight-green.svg'; break;
        case 'weight-red': srcPath = 'https://res.cloudinary.com/mathplextutoring/image/upload/v1677092586/Custom%20SVG/weight-red.svg'; break;
        default: break;
    }

    return <img style={{ opacity, height, width, minHeight: height, minWidth: width }} src={srcPath} alt="svgIcon"/>;
}