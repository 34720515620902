import { basicPath } from '../basicPath';

export const fetchSchedulerSettings = (params) => async (dispatch) => {
    const path = '/mp/scheduler-settings/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateSchedulerSettings = (params) => async (dispatch) => {
    const path = '/mp/scheduler-settings';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}