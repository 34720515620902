import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatDateTime, formatDate } from '../../../../../components/functions';
import { TooltipWrapper } from '../../../../../components/display';
import AssessmentResultsButton from '../helpers/AssessmentResultsButton';

export function getColumns(handleShowModal, downloadFile){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true
        },
        {
            dataField: 'name',
            text: 'Assessment Name',
            sort: true
        },
        {
            dataField: 'n_questions',
            text: '# Questions',
            sort: true
        },
        {
            dataField: 'score',
            text: 'Score',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aPerc = (100 * a.n_correct / a.n_questions).toLocaleString(undefined, { maximumFractionDigits: 2 }) * 1;
                const bPerc = (100 * b.n_correct / b.n_questions).toLocaleString(undefined, { maximumFractionDigits: 2 }) * 1;
                return (aPerc - bPerc) * swap;
            },
            formatter: (row) => {
                const perc = (100 * row.n_correct / row.n_questions) * 1;
                const percFormatted = `${perc.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`;
                if(perc === 100) return <Label color="mpLBlue">{percFormatted}</Label>;
                else if(perc >= 80) return <Label color="mpGreen">{percFormatted}</Label>;
                else if(perc >= 60) return <Label color="mpYellow">{percFormatted}</Label>;
                else if(perc >= 40) return <Label color="mpOrange">{percFormatted}</Label>;
                else return <Label color="mpLRed">{percFormatted}</Label>;
            }
        },
        {
            dataField: 'date_updated',
            text: 'Created',
            sort: true,
            sortFunc: (a, b, order, orderBy, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a || rowA.date_created);
                const bDate = new Date(b || rowB.date_created);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                return row.date_updated ? 
                    <div>{formatDateTime(row.date_updated)}</div> :
                    <div>Created: {formatDateTime(row.date_created)}</div>;
            }
        },
        {
            dataField: 'date_taken',
            text: 'Date Taken',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                if(!a || !b){
                    if(!a && !b) return 0;
                    else if(!a) return -1 * swap;
                    else if(!b) return 1 * swap;
                }

                const aDate = new Date(a);
                const bDate = new Date(b);
                if(isNaN(aDate) || isNaN(bDate)){
                    if(isNaN(aDate) && isNaN(bDate)) return 0;
                    else if(isNaN(aDate)) return -1 * swap;
                    else if (isNaN(bDate)) return 1 * swap;
                }

                return (bDate - aDate) * swap;                
            },
            formatter: (row) => {
                return(
                    <>
                        <div>{row.date_taken ? formatDate(row.date_taken) : '-'}</div>
                    </>
                );
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(a) - parseInt(b)) * swap;
            },
            formatter: (row) => {
                const isActive = parseInt(row.active);
                if(isActive) return <Label color="mpGreen">Yes</Label>;
                else return <Label color="mpLRed">No</Label>;
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit this assessment`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        {row.has_file ? 
                            <TooltipWrapper
                                tooltipText={`View/Download a blank version of this assessment`}
                            >
                                <Button
                                    color="lte-mpEGreen"
                                    variant="icon"
                                    onClick={() => downloadFile(row, 'assessment', {
                                        studentName: row.studentName,
                                        appendName: true,
                                        appendLogo: true,
                                        appendSquare: false,
                                    })}
                                >
                                    <SVGIcon fileName="print"/>
                                </Button>
                            </TooltipWrapper>
                            : null
                        }
                        {row.has_answer_key ? 
                            <TooltipWrapper
                                tooltipText={`View answer key for this assessment`}
                            >
                                <Button
                                    color="lte-mpEGreen"
                                    variant="icon"
                                    onClick={() => downloadFile(row, 'assessmentAnswerKey', {
                                        appendName: false,
                                        appendLogo: false,
                                        appendSquare: false
                                    })}
                                >
                                    <SVGIcon fileName="answer-key"/>
                                </Button>
                            </TooltipWrapper>
                            : null
                        }
                        {row.date_taken ? 
                            <AssessmentResultsButton
                                assessment={row}
                            />
                            : null
                        }
                        <TooltipWrapper
                            tooltipText={`Delete this assessment`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}