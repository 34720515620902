import { basicPath } from '../basicPath';

export const fetchHoldsLogsDaterangeCenter = (params) => async (dispatch) => {
    const path = '/mp/holds-logs/daterange-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchHoldsLogsIds = (params) => async (dispatch) => {
    const path = '/mp/holds-logs/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createHoldsLog = (params) => async (dispatch) => {
    const path = '/mp/holds-logs';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateHoldsLog = (params) => async (dispatch) => {
    const path = '/mp/holds-logs';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteHoldsLog = (params) => async (dispatch) => {
    const path = '/mp/holds-logs';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}