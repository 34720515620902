export function getFileInfos(learningPlanItems, studentName){
    const fileInfos = [];

    const baseOptions = {
        studentName,
        appendName: true,
        appendLogo: true,
        appendSquare: true
    };
    learningPlanItems.forEach(lpi => {
        const lessonType = lpi.lessonObj.lesson_id.includes('_EP1') ? 'epLesson' : 'lesson';
        lpi.lessonObj.type = lessonType;
        
        const squareColor = {
            r: 0,
            g: 0,
            b: 0
        };
        if(lessonType === 'epLesson' || lpi.isReview){
            squareColor.g = 122;
            squareColor.b = 157;
        } else if(lessonType === 'lesson'){
            squareColor.r = 215;
        } 
        lpi.lessonObj.options = {
            ...baseOptions,
            squareColor
        };
        fileInfos.push(lpi.lessonObj);
    });

    return fileInfos;
}