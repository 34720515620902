import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import UpcomingExamsBodyFooter from './UpcomingExamsBF';
import { checkResponse } from '../../form';

import {
    fetchStudentsUserIds,
    fetchUpcomingExamsIds,
    updateUpcomingExams
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function UpcomingExams(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [upcomingExam, setUpcomingExam] = useState({});

    const { selectedUE, onSubmitCallback, fetchUpcomingExamsIds, fetchStudentsUserIds, updateUpcomingExams } = props;

    useEffect(() => {
        async function init(){
            const upcomingExamRes = await fetchUpcomingExamsIds({ ids: [selectedUE.id] });
            const newUpcomingExam = upcomingExamRes.data?.[0] || {};

            const studentRes = await fetchStudentsUserIds({ userIds: [newUpcomingExam.student] });
            const newStudent = studentRes.data?.[0] ||{};

            newUpcomingExam.studentName = newStudent.first_name ? `${newStudent.first_name} ${newStudent.last_name}` :
            `Unknown student (UID: ${newUpcomingExam.student})`;

            if(mounted.current){
                setUpcomingExam(newUpcomingExam);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);

            const ueParams = {
                id: selectedUE.id,
                hasExams: values.hasExams.value ? 1 : 0,
                upcomingExamsNotes: values.upcomingExamsNotes,
            };

            let response = { status: 999 };
            response = await updateUpcomingExams(ueParams);
            
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }

            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [selectedUE, handleClose, updateUpcomingExams]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Upcoming Exams Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <UpcomingExamsBodyFooter
                    loaded={loaded}
                    submitted={submitted}
                    selectedUE={upcomingExam}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchUpcomingExamsIds,
    fetchStudentsUserIds,
    updateUpcomingExams
})(UpcomingExams);