import React from 'react';

export function getHours(times){
    let minutes = 0;
    for(let t of times){
        if(t.time_out) minutes += Math.floor((new Date(t.time_out) - new Date(t.time_in))/(60*1000));
    }

    const hours = (minutes / 60).toLocaleString(undefined, { maximumFractionDigits: 2 });

    return <div>Minutes: {minutes}, Hours: {hours}</div>;
}

export function getHoursFromFormatted(timesFormatted){
    let minutes = 0;
    for(let group of Object.values(timesFormatted)){
        for(let t of group){
            if(t.time_out) minutes += Math.floor((new Date(t.time_out) - new Date(t.time_in))/(60*1000));
        }
    }
    const hours = (minutes / 60).toLocaleString(undefined, { maximumFractionDigits: 2 });

    return <div>Minutes: {minutes}, Hours: {hours}</div>;
}