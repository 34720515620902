import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatTime, formatDateFull, getAlerts } from '../../../../../components/functions';
import { renderStatus, TooltipWrapper, renderPhone } from '../../../../../components/display';

export function getColumnsAA(setInProgress, handleShowModal, handleSearchTerm, nextTime){
    const statusMap = {
        "cancelled": 6,
        "missed": 5,
        "not charged": 4,
        "scheduled": 3,
        "in progress": 2,
        "completed": 1,
    };
    return [
        {
            dataField: 'student',
            text: 'Student',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(rowA.studentName < rowB.studentName) return -1 * swap;
                else if(rowA.studentName > rowB.studentName) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const student = row.studentInfo;
                const parent = row.parentObject;
                const firstGuardianContent = student.first_guardian_notes ? (
                    <>
                        <br/>
                        <u>Additional Contacts</u>
                        <div>First Guardian Notes: {student.first_guardian_notes}</div>
                    </>
                ) : null;
                const secondGuardianContent = student.second_guardian_notes ? (
                    <>
                        <br/>
                        <div>Second Guardian Notes: {student.second_guardian_notes}</div>
                    </>
                ) : null;
                return (
                    <TooltipWrapper
                        placement="right"
                        tooltipText={
                            <>
                                <u>School Info</u>
                                <div>School: {student.school || 'Not Specified'}</div>
                                <div>Teacher: {student.mp_teacher || 'Not Specified'}</div>
                                <div>Grade: {student.grade || 'Not Specified'}</div>
            
                                <br/>
            
                                <u>Account Info</u>
                                <div>Weight: {student.mp_weight}</div>
                                <div>Next Month Begins:</div>
                                <div>{formatDateFull(student.mp_next_month_start)}</div>
                                <div>Hours This Month:&nbsp;
                                    {(student.mp_time_left_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>Hours Next Month:&nbsp;
                                    {(student.mp_time_left_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>Reserve Hours Left:&nbsp;
                                    {(student.mp_reserve_time_left / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Default Hours:&nbsp;
                                    {(student.mp_default_time_per_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
            
                                <br/>
            
                                <u>Student Contact:</u>
                                <div>Phone: {renderPhone(student.phone)}</div>
                                <div>Email: {student.email}</div>
            
                                <br/>
            
                                <u>Parent Contact:</u>
                                <div>Name: {parent.first_name} {parent.last_name}</div>
                                <div>Phone: {renderPhone(parent.phone)}</div>
                                <div>Email: {parent.email}</div>
            
                                {firstGuardianContent}
                                {secondGuardianContent}
                            </>
                        }
                    >
                        <div className="text-mpLBlue">{student.first_name} {student.last_name}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'startTime',
            text: 'Start At',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(rowA.time < rowB.time) return -1 * swap;
                else if(rowA.time > rowB.time) return 1 * swap;
                else {
                    if(rowA.studentName < rowB.studentName) return -1;
                    else if(rowA.studentName > rowB.studentName) return 1;
                }
                return 0;
            },
            formatter: (row) => {
                const timeFormatted = formatTime(row.startTime);
                if(parseInt(row.startTime) === nextTime){
                    return <Label color="mpLRed">{timeFormatted}</Label>;
                }
                return timeFormatted;
            }
        },
        {
            dataField: 'endTime',
            text: 'End At',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(rowA.end < rowB.end) return -1 * swap;
                else if(rowA.end > rowB.end) return 1 * swap;
                else {
                    if(rowA.studentName < rowB.studentName) return -1;
                    else if(rowA.studentName > rowB.studentName) return 1;
                }
                return 0;
            },
            formatter: (row) => {
                const timeFormatted = formatTime(row.endTime);
                if(parseInt(row.endTime) === nextTime){
                    return <Label color="mpLRed">{timeFormatted}</Label>;
                }
                return timeFormatted;
            }
        },
        {
            dataField: 'alerts',
            text: 'Alerts',
            formatter: (row) => getAlerts(row)
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return ((statusMap[a.toLowerCase()] - statusMap[b.toLowerCase()]) * swap);
            },
            formatter: (row) => renderStatus(row)
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Mark appointment as "In Progress"`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => setInProgress(row.id)}
                            >
                                <SVGIcon fileName="ellipsis"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Update appointment details`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('details', row)}
                            >
                                <SVGIcon fileName="plus"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={row.stamps_given ? 'Give stamps' : 'Give stamps (not yet given)'}
                        >
                            <Button
                                color={row.stamps_given ? 'lte-mpDBlue' : 'lte-mpPurple'}
                                variant="icon"
                                onClick={() => handleShowModal('stamps', row)}
                            >
                                <SVGIcon fileName="star"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Search relevant info for ${row.studentName}`}
                        >
                            <Button
                                color="lte-mpOrange"
                                variant="icon"
                                onClick={() => handleSearchTerm(row.studentName)}
                            >
                                <SVGIcon fileName="magnifying-glass"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}