import React, { useState, useRef, useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay } from '../../../../components/display';
import { getDateObject, formatDate, formatDateApi, convertApiToDate } from "../../../../components/functions";
import { DatePicker, SelectSingle, FormikControl, Check, checkResponses } from "../../../../components/form";
import Overview from './Overview';
import ActionButtons from './ActionButtons';
import AppointmentsTable from "./AppointmentsTable";
import { AvailabilityModal } from "../../../../components/modal";
import { Socket } from '../../../../components/ws';
import { parseSchedulingNotes } from './overview/instructors/helpers';

import {
    fetchMembersAll,
    fetchStudentsAll,
    fetchMpCentersAll,
    fetchAdminUsersAll,
    fetchFlagsStatus,
    fetchUpcomingExamsAll,
    fetchAppointmentsDateCenter,
    fetchAvailabilityDateCenter,
} from '../../../../actions';

const pageTitle = 'Scheduling';

const lsSaveValues = [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_save_values')));
const defaultDate = lsSaveValues ? formatDateApi(convertApiToDate(localStorage.getItem('admin_scheduling_selected_date'))) : formatDateApi(new Date());
const lsSelectedCenter = lsSaveValues ? JSON.parse(localStorage.getItem('admin_scheduling_selected_center')) : null;
const lsFilterQuery = lsSaveValues ? JSON.parse(localStorage.getItem('admin_scheduling_filter_query')) : null;
const defaultFilterQuery = lsFilterQuery === null ? '' : lsFilterQuery;
const lsHideCancelled = lsSaveValues ? [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_hide_cancelled'))) : true;
const lsLegacyView = lsSaveValues ? [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_legacy_view'))) : true;

async function toggleShow(setShow){
    await setShow(false);
    await setShow(true);
}

function getInitCenterOption(center, centerOptions){
    if(!centerOptions[0]) return { value: -1, label: 'Loading centers...' };
    if(!center || isNaN(center)) return centerOptions[0];
    return centerOptions.find(s => s.value === center) || { value: -1, label: `Unknown center (${center})` };
}

function Scheduling(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [centerOptions, setCenterOptions] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [members, setMembers] = useState([]);
    const [students, setStudents] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [filteredSplitAppointments, setFilteredSplitAppointments] = useState({});
    const [availability, setAvailability] = useState({});
    const [availabilityBlocks, setAvailabilityBlocks] = useState([]);
    const [assignmentMap, setAssignmentMap] = useState({});
    const [searchedDate, setSearchedDate] = useState(formatDate(new Date()));
    const [searchedCenter, setSearchedCenter] = useState(null);
    const [show, setShow] = useState(true);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    
    const { fetchMembersAll, fetchStudentsAll, fetchMpCentersAll, fetchAdminUsersAll, fetchAppointmentsDateCenter,
        fetchAvailabilityDateCenter, fetchFlagsStatus, fetchUpcomingExamsAll } = props;

    const filterAppointments = useCallback((newAppointments, filterQuery, hideCancelled) => {
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        const filteredAppointments = newAppointments.filter(a => {
            return !hideCancelled || !['Cancelled'].includes(a.status);
        }).filter(a => {
            return a.studentName.toLowerCase().trim().replace(/ /g, '').includes(formattedFQ);
        });

        const splitAppointments = {};
        filteredAppointments.forEach(a => {
            if(!splitAppointments[a.startTime]) splitAppointments[a.startTime] = [];
            splitAppointments[a.startTime].push(a);
        });

        if(mounted.current){
            setFilteredAppointments(filteredAppointments);
            setFilteredSplitAppointments(splitAppointments);
        }
    }, [setFilteredAppointments]);
    const refreshData = useCallback((selectedDate = formRef.current.values.selectedDate,
        selectedCenter = formRef.current.values.selectedCenter, newCenterOptions = centerOptions, 
        newMembers = members, newAdminUsers = adminUsers,
        filterQuery = formRef.current.values.filterQuery, hideCancelled = formRef.current.values.hideCancelled) => {
        (async function refresh(){
            if(loading || isNaN(new Date(selectedDate))) return;
            if(mounted.current) setLoading(true);
            const selectedDateObj = getDateObject(convertApiToDate(selectedDate));

            const studentsRes = await fetchStudentsAll();
            const upcomingExamsRes = await fetchUpcomingExamsAll();
            const appointmentsRes = await fetchAppointmentsDateCenter({ date: selectedDateObj.raw, center: selectedCenter.value });
            const availabilityRes = await fetchAvailabilityDateCenter({ date: selectedDateObj.api, center: selectedCenter.value }); 
            const pendingFlagsRes = await fetchFlagsStatus({ status: 'Pending' });
            const isApiError = checkResponses(appointmentsRes, availabilityRes, pendingFlagsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newStudents = studentsRes.data || [];
            const newUpcomingExams = upcomingExamsRes.data || [];
            const newAppointmentsData = appointmentsRes.data || [];
            const newAvailabilityData = availabilityRes.data || {};
            const newFlags = pendingFlagsRes.data || [];
            
            const newAppointments = newAppointmentsData.appointments || {};
            const newInstructorAssignments = newAppointmentsData.assignments || {};
            const newAvailability = newAvailabilityData.availabilities?.[0] || {};
            const newAvailabilityBlocks = newAvailabilityData.blocks || [];

            const upcomingExamsMap = {};
            newUpcomingExams.forEach(ue => upcomingExamsMap[ue.student] = ue);
            newStudents.forEach(s => s.upcomingExams = upcomingExamsMap[s.user_id]);

            if(Object.values(newAvailability).length) newAvailability.centerName = selectedCenter.label;
            
            const userToNameMap = {};
            newAdminUsers.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            newMembers.forEach(m => userToNameMap[m.id] = `${m.first_name} ${m.last_name}`);
            const appointmentToAssignmentMap = {};
            const appointmentToAssignmentObjectMap = {};
            newInstructorAssignments.forEach(ia => {
                const iaApt = parseInt(ia.appointment_id);
                const instructorName = userToNameMap[ia.instructor] || `Unable to find user (ID ${ia.instructor})`;
                if(appointmentToAssignmentMap[iaApt]){
                    appointmentToAssignmentMap[iaApt] += `, ${instructorName}`;
                    appointmentToAssignmentObjectMap[iaApt].push(ia);
                } else {
                    appointmentToAssignmentMap[iaApt] = instructorName;
                    appointmentToAssignmentObjectMap[iaApt] = [ia];
                }
            });

            // To use when parsing scheduling notes
            const instructorNameToIdMap = {};
            newAdminUsers.forEach(u => {
                const name = `${u.first_name}${u.last_name}`.trim().toLowerCase();
                instructorNameToIdMap[name] = u.id;
            });

            // Track instructors' assignment saturation at each time block. Ignore missed/cancelled
            const newAssignmentMap = {};
            let colorIndex = 0;
            newAppointments.forEach(a => {
                if(['Cancelled', 'Missed'].includes(a.status)) return;
                const assignments = appointmentToAssignmentObjectMap[a.id] || [];

                const appointmentGroups = parseSchedulingNotes(a, assignments, instructorNameToIdMap);
                appointmentGroups.forEach(g => {
                    const instructorName = userToNameMap[g.instructor] || `Unable to find user (ID ${g.instructor})`;
                    const instructorId = g.instructor;
                    if(!newAssignmentMap[instructorId]){
                        newAssignmentMap[instructorId] = {
                            id: instructorId,
                            name: instructorName,
                            colorIndex: colorIndex++
                        };
                    }
                    for(let i = parseInt(g.start); i < parseInt(g.end); i += 30){
                        const seatsTimeKey = `s-${i}`;
                        if(!newAssignmentMap[instructorId][seatsTimeKey]) newAssignmentMap[instructorId][seatsTimeKey] = 1;
                        else newAssignmentMap[instructorId][seatsTimeKey] += 1;

                        const weightsTimeKey = `w-${i}`;
                        const weightInt = parseInt(a.weight);
                        if(!newAssignmentMap[instructorId][weightsTimeKey]) newAssignmentMap[instructorId][weightsTimeKey] = weightInt;
                        else newAssignmentMap[instructorId][weightsTimeKey] += weightInt;
                    }
                });
            });
            
            const centerToNameMap = {};
            newCenterOptions.forEach(c => centerToNameMap[parseInt(c.value)] = c.label);
            const studentToNameMap = {};
            newStudents.forEach(s => studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const studentToObjectMap = {};
            newStudents.forEach(s => {
                s.binderLocationName = centerToNameMap[parseInt(s.mp_binder_location)];
                studentToObjectMap[s.user_id] = s;
            });
            const memberToObjectMap = {};
            newMembers.forEach(m => {
                memberToObjectMap[m.id] = m;
            });
            const appointmentToFlagMap = {};
            newFlags.forEach(f => {
                f.updatedByName = f.updated_by ? (userToNameMap[f.updated_by] || `Unknown user (UUID: ${f.updated_by})`) : 'Updated never';
                f.createdByName = userToNameMap[f.created_by] || `Unknown user (UUID: ${f.created_by})`;
                f.centerName = centerToNameMap[f.center];
                // Only append the most recent flag (already sorted by date desc)
                if(!appointmentToFlagMap[f.student]) appointmentToFlagMap[f.student] = f;
            });
            const appointmentsAppended = newAppointments.map(a => {
                const aptDateTime = new Date(a.date_time);
                a.startTime = aptDateTime.getHours() * 60 + aptDateTime.getMinutes() * 1;
                a.endTime = parseInt(a.startTime) + parseInt(a.duration);

                a.centerName = centerToNameMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
                a.studentName = studentToNameMap[a.student] || `Unknown student (User ID: ${a.student})`;
                a.instructorNames = appointmentToAssignmentMap[parseInt(a.id)] || 'None';
                a.studentInfo = studentToObjectMap[a.student] || {};
                a.parentObject = memberToObjectMap[a.parent] || {};
                a.createdByName = userToNameMap[a.created_by] || `Unknown user (UUID: ${a.created_by})`;
                a.updatedByName = a.updated_by ? (userToNameMap[a.updated_by] || `Unknown user (UUID: ${a.updated_by})`) : 'Updated never';
                a.pendingFlag = appointmentToFlagMap[a.student] || {};
                return a;
            });
    
            if(mounted.current){
                setStudents(newStudents);
                setAppointments(appointmentsAppended);
                filterAppointments(appointmentsAppended, filterQuery, hideCancelled);
                setAvailability(newAvailability);
                setAvailabilityBlocks(newAvailabilityBlocks);
                setAssignmentMap(newAssignmentMap);
                setSearchedDate(selectedDateObj);
                setSearchedCenter(selectedCenter.label);
                await toggleShow(setShow);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, loading, adminUsers, centerOptions, members, students]);
    // AVAILABILITY MODAL ONLY //
    const handleShowModal = useCallback((mode) => {
        if(mounted.current) setModalMode(mode);
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    useEffect(() => {
        async function init(){
            const membersRes = await fetchMembersAll();
            const centersRes = await fetchMpCentersAll();
            const adminUsersRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(membersRes, centersRes, adminUsersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newMembers = membersRes.data || [];
            const newCenters = centersRes.data || [];
            const newAdminUsers = adminUsersRes.data || [];

            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));
            newCenterOptions.push({ value: 'all', label: 'All Centers' });
            const prevSelectedCenter = newCenterOptions.find(c => c.value === parseInt(lsSelectedCenter));

            if(mounted.current){
                setHasLoaded(true);
                setMembers(newMembers);
                setAdminUsers(newAdminUsers);
                setCenterOptions(newCenterOptions);
                refreshData(defaultDate, prevSelectedCenter || newCenterOptions[0], newCenterOptions,
                newMembers, newAdminUsers);
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Need some method for booking multiple students at once (mainly for parents with more than 1 student).
    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {!setHasLoaded || loading ? <LoadingOverlay/> : null}
            <div className="card">
                <h2>Appointment Scheduling</h2>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedDate: defaultDate,
                        selectedCenter: getInitCenterOption(lsSelectedCenter, centerOptions) || { value: -1, label: 'Loading centers...' },
                        saveValues: lsSaveValues,
                        filterQuery: defaultFilterQuery,
                        hideCancelled: lsHideCancelled,
                        legacyView: lsLegacyView,
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                    <>
                        {modalMode && 
                            <AvailabilityModal
                                selectedDate={searchedDate}
                                selectedCenter={formik.values.selectedCenter}
                                onSubmitCallback={onSubmitCallback}
                            />
                        }
                        <div className="flex flex-row gap-x-4 items-center">
                            <div className="grid grid-cols-1 gap-y-2">
                                {show && 
                                    <DatePicker
                                        id="scheduling-select-1"
                                        name="selectedDate"
                                        value={formik.values.selectedDate || formik.initialValues.selectedDate}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if(formik.values.saveValues) localStorage.setItem('admin_scheduling_selected_date',
                                                isNaN(new Date(e.target.value)) ? formatDateApi(new Date()) : e.target.value);
                                        }}
                                        disabled={loading}
                                    />
                                }
                            </div>
                            <div className="grid grid-cols-1 w-1/4 gap-y-2">
                                <SelectSingle
                                    id="scheduling-control-1"
                                    name="selectedCenter"
                                    value={formik.values.selectedCenter}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if(formik.values.saveValues) localStorage.setItem('admin_scheduling_selected_center', JSON.stringify(e.target.value.value));
                                        refreshData(formik.values.selectedDate, e.target.value);
                                    }}
                                    disabled={loading}
                                    options={centerOptions}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2">
                                <Check
                                    id="scheduling-save-values"
                                    name="saveValues"
                                    label="Save Search Settings"
                                    color="mpLRed"
                                    checked={formik.values.saveValues}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        localStorage.setItem('admin_scheduling_save_values', JSON.stringify(e.target.value));
                                        if(e.target.value === true){
                                            localStorage.setItem('admin_scheduling_selected_date',
                                                isNaN(new Date(formik.values.selectedDate)) ? formatDateApi(new Date()) : formik.values.selectedDate);
                                            localStorage.setItem('admin_scheduling_selected_center', JSON.stringify(formik.values.selectedCenter.value));
                                            localStorage.setItem('admin_scheduling_filter_query', JSON.stringify(formik.values.filterQuery));
                                            localStorage.setItem('admin_scheduling_hide_cancelled', JSON.stringify(formik.values.hideCancelled));
                                            localStorage.setItem('admin_scheduling_legacy_view', JSON.stringify(formik.values.legacyView));
                                        }
                                    }}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2">
                                <Button
                                    color="lte-mpTeal"
                                    onClick={() => refreshData()}
                                >
                                    Search
                                </Button>
                            </div>
                            <div className="grid grid-cols-1 gap-y-2">
                                {hasLoaded && ['Root', 'Admin', 'Lead Instructor'].includes(props.auth.permissions) ?
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => handleShowModal(
                                            Object.values(availability).length ? 'edit' : 'create',
                                            availability
                                        )}
                                        style={{ alignSelf: "center" }}
                                    >
                                        { Object.values(availability).length ?
                                            'Edit' : 'Add' } availability
                                    </Button> : null
                                }
                            </div>
                        </div>
                        <br/>
                        {apiError ? <div className="text-mpLRed">{apiError}</div> :
                            hasLoaded &&
                            <>
                                { formik.values.selectedCenter.value === 'all' ? null :
                                    <>
                                        <hr/>
                                        <br/>
                                        <Overview
                                            show={show}
                                            availability={availability}
                                            availabilityBlocks={availabilityBlocks}
                                            appointments={appointments}
                                            assignmentMap={assignmentMap}
                                        />
                                    </>
                                }
                                
                                <br/>
                                <hr/>
                                <br/>

                                <ActionButtons
                                    refreshData={() => refreshData(
                                        formik.values.selectedDate, formik.values.selectedCenter)}
                                    selectedCenter={formik.values.selectedCenter}
                                    selectedDate={formik.values.selectedDate}
                                    searchedDate={searchedDate}
                                    appointments={appointments}
                                    permissions={props.auth.permissions}
                                />

                                <br/>
                                <hr/>
                                <br/>

                                <h4>Appointment Search ({filteredAppointments.length})</h4>
                                <br/>

                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2">
                                        {show && 
                                            <DatePicker
                                                id="scheduling-select-2"
                                                name="selectedDate"
                                                value={formik.values.selectedDate || formik.initialValues.selectedDate}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    if(formik.values.saveValues) localStorage.setItem('admin_scheduling_selected_date', e.target.value || '');
                                                }}
                                                disabled={loading}
                                            />
                                        }
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <SelectSingle
                                            id="scheduling-control-2"
                                            name="selectedCenter"
                                            value={formik.values.selectedCenter}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_selected_center', JSON.stringify(e.target.value.value));
                                                refreshData(formik.values.selectedDate, e.target.value);
                                            }}
                                            disabled={loading}
                                            options={centerOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Check
                                            id="scheduling-save-values-2"
                                            name="saveValues"
                                            label="Save Search Settings"
                                            color="mpLRed"
                                            checked={formik.values.saveValues}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                localStorage.setItem('admin_scheduling_save_values', JSON.stringify(e.target.value));
                                                if(e.target.value === true){
                                                    localStorage.setItem('admin_scheduling_selected_date',
                                                        isNaN(new Date(formik.values.selectedDate)) ? formatDateApi(new Date()) : formik.values.selectedDate);
                                                    localStorage.setItem('admin_scheduling_selected_center', JSON.stringify(formik.values.selectedCenter.value));
                                                    localStorage.setItem('admin_scheduling_filter_query', JSON.stringify(formik.values.filterQuery));
                                                    localStorage.setItem('admin_scheduling_hide_cancelled', JSON.stringify(formik.values.hideCancelled));
                                                    localStorage.setItem('admin_scheduling_legacy_view', JSON.stringify(formik.values.legacyView));
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Button
                                            color="lte-mpTeal"
                                            onClick={() => refreshData()}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <FormikControl
                                            id="members-query-1"
                                            name="filterQuery"
                                            placeholder="Filter appointments by student name..."
                                            value={formik.values.filterQuery}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_filter_query', JSON.stringify(e.target.value));
                                                filterAppointments(appointments, e.target.value, formik.values.hideCancelled);
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Check
                                            id="scheduling-hideCancelled"
                                            name="hideCancelled"
                                            label="Hide Cancelled"
                                            color="mpLRed"
                                            checked={formik.values.hideCancelled}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_hide_cancelled', JSON.stringify(e.target.value));
                                                filterAppointments(appointments, formik.values.filterQuery, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <Check
                                            id="scheduling-legacy"
                                            name="legacyView"
                                            label="Split Tables by Times"
                                            color="mpLRed"
                                            checked={formik.values.legacyView}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_legacy_view', JSON.stringify(e.target.value));
                                            }}
                                        />
                                    </div>
                                </div>

                                <br/>
                                <br/>

                                <h4>Showing appointments for {searchedDate.formattedFull} {searchedCenter ? `at ${searchedCenter}` : ``}</h4>

                                {show && <AppointmentsTable
                                    legacyView={formik.values.legacyView}
                                    refreshData={() => refreshData()}
                                    centerOptions={centerOptions}
                                    selectedCenter={formik.values.selectedCenter.value}
                                    selectedDate={formik.values.selectedDate}
                                    students={students}
                                    appointments={filteredAppointments}
                                    splitAppointments={filteredSplitAppointments}
                                    permissions={props.auth.permissions}
                                />}
                            </>
                        }
                    </>
                    )}
                </Formik>
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMembersAll,
    fetchStudentsAll,
    fetchMpCentersAll,
    fetchAdminUsersAll,
    fetchFlagsStatus,
    fetchUpcomingExamsAll,
    fetchAppointmentsDateCenter,
    fetchAvailabilityDateCenter,
})(Scheduling);