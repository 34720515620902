import React from 'react';

import { Button, SVGIcon } from '../../../../../components/custom-essentials';
import { formatDate } from '../../../../../components/functions';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumns(downloadFile){
    return [
        {
            dataField: 'combinedSortOrder',
            text: 'Sort Order',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aLPIndex = parseInt(a.split('_')[0]);
                const aItemIndex = parseInt(a.split('_')[1]);
                const bLPIndex = parseInt(b.split('_')[0]);
                const bItemIndex = parseInt(b.split('_')[1]);

                let sortValue = 0;
                if(aLPIndex < bLPIndex) sortValue = -1;
                else if(aLPIndex > bLPIndex) sortValue = 1;
                else {
                    if(aItemIndex < bItemIndex) sortValue = -1;
                    else if(aItemIndex > bItemIndex) sortValue = 1;
                    else sortValue = 0;
                }
                return swap * sortValue;
            }
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            formatter: (row) => {
                return `${row.lessonObj.lesson_id}: ${row.lessonObj.name}`;
            }
        },
        {
            dataField: 'has_file',
            text: 'Download',
            formatter: (row) => {
                const lesson = { ...row.lessonObj };
                lesson.type = lesson.lesson_id.includes('_EP1') ? 'epLesson' : 'lesson';
                const appendLogo = true;
                if(lesson.has_file){
                    return(
                        <TooltipWrapper
                            tooltipText="View/Download this lesson"
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => downloadFile(lesson, row.isReview, appendLogo)}
                            >
                                <SVGIcon fileName="print"/>
                            </Button>
                        </TooltipWrapper>
                    );
                } else {
                    return(
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            disabled
                            onClick={() => null}
                        >
                            <TooltipWrapper
                                tooltipText="No file path provided"
                            >
                                <SVGIcon fileName="unlink"/>
                            </TooltipWrapper>
                        </Button>
                    );
                }
            }
        },
        {
            dataField: 'answer_key',
            text: 'Answer Key',
            formatter: (row) => {
                const lesson = { ...row.lessonObj };
                lesson.type = lesson.lesson_id.includes('_EP1') ? 'epAnswerKey' : 'answerKey';
                const appendLogo = false;
                if(lesson.has_answer_key){
                    return(
                        <TooltipWrapper
                            tooltipText="View answer key"
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => downloadFile(lesson, row.isReview, appendLogo)}
                            >
                                <SVGIcon fileName="answer-key"/>
                            </Button>
                        </TooltipWrapper>
                    );
                } else {
                    return(
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            disabled
                            onClick={() => null}
                        >
                            <TooltipWrapper
                                tooltipText="No answer key link provided"
                            >
                                <SVGIcon fileName="unlink"/>
                            </TooltipWrapper>
                        </Button>
                    );
                }
            }
        },
        {
            dataField: 'date_assigned',
            text: 'Date Assigned',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                if(!row.date_assigned) return <div className="text-mpOrange">Never</div>;
                return <div>{formatDate(row.date_assigned)}</div>;
            }
        },
        {
            dataField: 'date_completed',
            text: 'Date Completed',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                if(!row.date_completed) return <div className="text-mpOrange">Never</div>;
                return <div>{formatDate(row.date_completed)}</div>;
            }
        },
    ];
}