import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { TooltipWrapper, tableColors } from '../../../../../components/display';
import { formatDateTimeFull } from '../../../../../components/functions';

export function getColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
        },
        {
            dataField: 'previousAppointment',
            text: 'Last Appointment',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a.date);
                const bDate = new Date(b.date);
                if(aDate < bDate) return -1 * swap;
                else if(aDate > bDate) return 1 * swap;
                else {
                    if(a.time < b.time) return -1 * swap;
                    else if(a.time > b.time) return 1 * swap;
                    return 0;
                }
            },
            formatter: (row) => {
                const pa = row.previousAppointment;
                if(!pa){
                    return(
                        <Label color="mpLRed">
                            None within 14 days
                        </Label>
                    );
                };
                const prevAptDateTime = new Date(pa.date_time);
                const currentAptDateTime = new Date(row.date_time);
                const diff = (currentAptDateTime.getDate() - prevAptDateTime.getDate());

                // NaN if previous appointment does not exist
                if(isNaN(diff)) return <div>More than 14 days ago</div>

                const daysText = `${diff} day${diff === 1? '' : 's'} prior`;

                const center = parseInt(pa.center);
                const prevCenterName = pa.centerName || 'NA';
                let color = '';
                if(colorMap[center]) color = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    color = colorMap[center];
                }
                const centerLabel= (
                    <Label color={color}>
                        {prevCenterName}
                    </Label>
                );

                return(
                    <div>
                        <TooltipWrapper
                            tooltipText={
                                <div>
                                    {formatDateTimeFull(prevAptDateTime)}
                                </div>
                            }
                        >
                            <div className="flex flex-row text-mpLBlue">
                                <div style={{ marginRight: "0.5rem" }}>
                                    {daysText} at
                                </div>
                                <div>
                                    {centerLabel}
                                </div>
                            </div>
                        </TooltipWrapper>
                    </div>
                )
            }
        },
        {
            dataField: 'currentCenter',
            text: `Binder Location / Today's Appointment`,
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const binderLocation = parseInt(row.binderLocation);
                let binderColor = '';
                if(colorMap[binderLocation]) binderColor = colorMap[binderLocation];
                else {
                    colorMap[binderLocation] = colors[(colorIndex++) % 5];
                    binderColor = colorMap[binderLocation];
                }

                const center = parseInt(row.center);
                let centerColor = '';
                if(colorMap[center]) centerColor = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    centerColor = colorMap[center];
                }

                return (
                    <div className="flex flex-row">
                        <div>
                            <Label color={binderColor}>
                                {row.binderLocationName}
                            </Label>
                        </div>
                        <div className="mx-1">/</div>
                        <div>
                            <Label color={centerColor}>
                                {row.currentCenterName}
                            </Label>
                        </div>
                    </div>
                );
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aPrevAppointment = rowA.previousAppointment;
                const bPrevAppointment = rowB.previousAppointment;
                if(!aPrevAppointment) return -1 * swap;
                if(!bPrevAppointment) return 1 * swap;

                const aMatches = parseInt(rowA.center) === parseInt(rowA.binderLocation);
                const bMatches = parseInt(rowB.center) === parseInt(rowB.binderLocation);
                if(!aMatches) return -1 * swap;
                if(!bMatches) return 1 * swap;
                return 0;
            },
            formatter: (row) => {
                const returningContent = !row.previousAppointment ? 
                    <div className="mr-2">
                        <TooltipWrapper
                            tooltipText={
                                <div>
                                    Student has not attended in the last 14 days.
                                </div>
                            }
                        >
                            <Label color="mpYellow">
                                New/Returning
                            </Label>
                        </TooltipWrapper>
                    </div> : null;
                const matches = parseInt(row.center) === parseInt(row.binderLocation);
                const matchesContent = matches ? 
                    <div>
                        <TooltipWrapper
                            tooltipText={
                                <div>
                                    Student's binder location matches the appointment location
                                </div>
                            }
                        >
                            <Label color="mpGreen">
                                Ready!
                            </Label>
                        </TooltipWrapper>
                    </div>
                    :
                    <div>
                        <TooltipWrapper
                            tooltipText={`Today's appointment is at ${row.currentCenterName || 'NA'}
                                but the binder is at ${row.binderLocationName || 'NA'}`}
                        >
                            <Label color="mpLRed">
                                Not Ready!
                            </Label>
                        </TooltipWrapper>
                    </div>;
                
                return(
                    <div className="grid grid-cols-1 gap-y-2">
                        {returningContent}
                        {matchesContent} 
                    </div>
                );
            }
        },
        {
            dataField: 'actions',
            text:'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row">
                        <TooltipWrapper
                            tooltipText={`Move Binder`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row.student)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="folder-open"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ]
}