import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { FormikControl } from '../../form';
import { TooltipWrapper } from '../../display';
import { validationSchema } from './helpers';

function LearningPlanCompleteBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { loaded, submitted, attemptingClose,
        setAttemptingClose, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    additionalMessage: '',
                    confirmText: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <>
                        {attemptingClose ? (
                            <ModalBodyFooter>
                                <Modal.Body>
                                    <h4>
                                        Are you sure you would like to close the form?
                                    </h4>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="flex flex-row gap-x-2 flex-wrap">
                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                        >
                                            Close Form
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setAttemptingClose(false)}
                                        >
                                            Back to Form
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </ModalBodyFooter>
                        ) :
                        (
                            <ModalBodyFooter>
                                <Modal.Body>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <h4>About This Form</h4>
                                            Submitting will mark this LP as complete and send an email to the parent and student.
                                        </div>
                                    </div>

                                    <br/>
                                    
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <TooltipWrapper
                                                tooltipText="Additional message to include in the email."
                                            >
                                                <h4 className="text-mpLBlue">Additional Message</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="lp-complete-additionalMessage"
                                                as="textarea"
                                                name="additionalMessage"
                                                value={formik.values.additionalMessage}
                                                onChange={formik.handleChange}
                                                placeholder="Max 2,000 characters."
                                            />
                                            {formik.errors.additionalMessage && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.additionalMessage}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <TooltipWrapper
                                                tooltipText="This form requires confirmation since suibmitting will send an email."
                                            >
                                                <h4 className="text-mpLBlue">Confirm Action</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="lp-complete-confirmText"
                                                name="confirmText"
                                                value={formik.values.confirmText}
                                                onChange={formik.handleChange}
                                                placeholder={`Type "Confirm" (case sensitive).`}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="flex flex-row gap-x-2 flex-wrap">
                                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                            (
                                                <div className="text-mpLRed">
                                                    One or more fields needs to be corrected.
                                                </div>
                                            ) : null
                                        }
                                        {formik.isSubmitting ?
                                            <ModalProcessing/> : null
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed">
                                                {formik.status}
                                            </div> : null
                                        }
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => formik.handleSubmit(formik)}
                                            disabled={formik.isSubmitting || formik.values.confirmText !== 'Confirm'}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </ModalBodyFooter>
                        )}
                    </>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(LearningPlanCompleteBodyFooter);