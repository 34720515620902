import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import FlagsBodyFooter from './FlagsBF';
import { checkResponse } from '../../form';

import {
    fetchFlagsIds,
    fetchMpCentersAll,
    fetchAdminUsersIds,
    fetchStudentsAll,
    createFlag,
    updateFlag,
    deleteFlag,
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function FlagModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [flag, setFlag] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);

    const { mode, selectedFlag, onSubmitCallback, fetchFlagsIds, fetchMpCentersAll, 
        fetchAdminUsersIds, fetchStudentsAll, createFlag, updateFlag, deleteFlag } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            const newStudents = studentsRes.data || [];
            const newStudentOptions = newStudents.filter(s => {
                return (parseInt(s.mp_active) === 1 && parseInt(s.is_mp_student) === 1) || selectedFlag?.student === s.user_id;
            }).map(ns => ({ value: ns.user_id, label: `${ns.first_name} ${ns.last_name}`, obj: ns }));

            if(mode !== 'create'){
                const flagRes = await fetchFlagsIds({ ids: [selectedFlag.id] });
                const newFlag = flagRes.data?.[0] || {};
                const flagStudent = newStudents.find(s => s.user_id === selectedFlag.student);
    
                const flagMeta = [];
                if(newFlag.created_by) flagMeta.push(newFlag.created_by);
                if(newFlag.updated_by) flagMeta.push(newFlag.updated_by);

                const employeesRes = await fetchAdminUsersIds({ ids: flagMeta });
                const newEmployees = employeesRes.data || [];

                const cbInstructor = newEmployees.find(ni => ni.id === newFlag.created_by);
                const ubInstructor = newEmployees.find(ni => ni.id === newFlag.updated_by);

                newFlag.studentName = flagStudent ? `${flagStudent.first_name} ${flagStudent.last_name}` :
                    `Unknown student (UID ${newFlag.student})`;
                if(cbInstructor) {
                    newFlag.createdByName = cbInstructor ?
                    `${cbInstructor.first_name} ${cbInstructor.last_name}` :
                    `Unknown admin user (ID: ${newFlag.created_by})`;
                }
                if(ubInstructor) {
                    newFlag.updatedByName = ubInstructor ?
                    `${ubInstructor.first_name} ${ubInstructor.last_name}` :
                    `Unknown admin user (ID: ${newFlag.updated_by})`;
                }

                if(mounted.current) setFlag(newFlag);
            }

            const centersRes = await fetchMpCentersAll();
            const newCenters = centersRes.data || [];
            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));

            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setCenterOptions(newCenterOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const flagParams = {
                    student: values.student.value,
                    tag: values.tag.value,
                    center: values.center.value,
                    additionalNotes: values.additionalNotes,
                    adminNotes: values.adminNotes,
                    status: values.status.value,
                };
                
                if(mode === 'create'){
                    response = await createFlag(flagParams);
                } else if(mode === 'edit') {
                    flagParams.id = flag.id;
                    response = await updateFlag(flagParams);
                }
            } else if(mode === 'delete') {
                response = await deleteFlag({ id: flag.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, flag, createFlag, updateFlag, deleteFlag]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Flag Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <FlagsBodyFooter
                    mode={mode}
                    selectedFlag={flag}
                    studentOptions={studentOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchFlagsIds,
    fetchMpCentersAll,
    fetchAdminUsersIds,
    fetchStudentsAll,
    createFlag,
    updateFlag,
    deleteFlag
})(FlagModal);