export function checkResponse(response, mounted, setStatus, customErr = {}){
    const hideCode = customErr.hideResponseCode;

    if((!response || !response.status || response.status >= 400) && mounted.current){
        if(!response || !response.status){
            setStatus(customErr.noResponse || 'Unable to reach the server at this time.');
            return false;
        }
        
        const message = response.data?.message;
        if(response.status === 400){
            const errCode = hideCode ? '' : 'Bad request (400): ';
            setStatus(customErr[400] || `${errCode}${message}`);
        } else if(response.status === 401){
            const errCode = hideCode ? '' : 'Unauthorized (401): ';
            setStatus(customErr[401] || `${errCode}${message}`);
        } else if(response.status === 403){
            const errCode = hideCode ? '' : 'Forbidden (403): ';
            setStatus(customErr[403] || `${errCode}${message}`);
        } else if(response.status === 404){
            const errCode = hideCode ? '' : 'Not Found (404): ';
            setStatus(customErr[404] || `${errCode}${message}`);
        } else if(response.status === 409){
            const errCode = hideCode ? '' : 'Conflict (409): ';
            setStatus(customErr[409] || `${errCode}${message}`);
        } else if(response.status === 428){
            const errCode = hideCode ? '' : 'Failed precondition (428): ';
            setStatus(customErr[428] || `${errCode}${message}`);
        } else if(response.status === 500){
            const errCode = hideCode ? '' : 'Internal server error (500): ';
            setStatus(customErr[500] || `${errCode}${message}`);
        } else {
            const errCode = hideCode ? '' : `Unexpected error (${response.status}): `;
            setStatus(`${errCode}${message}`);
        }
        return false;
    } else {
        setStatus('');
        return true;
    }
}

export function checkResponses(){
    for(let response of arguments){
        if(!response || !response.status || response.status >= 400){
            return true;
        }
    }

    return false;
}