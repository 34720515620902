import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Alert, Button, FormValidation } from '../../../components/custom-essentials';
import { BrowserTabTitle } from "../../../components/display";

import {
    resetPassword
} from '../../../actions';
import { FormikControl } from "../../../components/form";

function ResetPassword(props) {
    const urlParams = new URLSearchParams(window.location.search);

    const [wasRequested, setWasRequested] = useState(false);

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        return () => (mounted.current = false);
    });

    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
    const ForgotPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Minimum 8 characters')
            .matches(passRegex, 'Password must contain at least one lower case letter, one upper case letter, and one number')
            .required('Password is required'),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Field is required')
    });

    const onSubmit = async (values, { setStatus, setSubmitting }) => {
        await props.resetPassword(urlParams.get('email'), formik.values.password, urlParams.get('key'))
            .then((response) => {
                if(!response){
                    if(mounted.current) setStatus('Unexpected error (null response)');
                } else if(response.status === 403){
                    if(mounted.current) setStatus('403');
                } else if(response.status === 500){
                    if(mounted.current) setStatus('Internal server error (500).');
                } else if(response.status === 200){
                    if(mounted.current){
                        setStatus('Success!')
                        setWasRequested(true);
                    }
                } else {
                    if(mounted.current) setStatus(`Unexpected error (${response.status}).`);
                }
            })
            .catch((response) => {
                setSubmitting(false);
                if(mounted.current) setStatus(`Unexpected error (${response && response.status})`);
            });
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit
    })

    return (
        <>
            <BrowserTabTitle content="Reset Password"/>
            <div className="text-3xl">
                Forgot Password?
            </div>
            <div className="text-md text-center relative top-2 text-mpLGrey-3">
                Enter your email to get a password reset link.
            </div>
            <div className="relative top-12">
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                    {formik.status && !['403', 'Success!'].includes(formik.status) ? (
                        <Alert
                            color="mpLRed"
                            content={formik.status}
                        />
                    ) : formik.status === '403' ? (
                        <Alert
                            color="mpLRed"
                            content={<>This link is invalid or has expired. Please request a&nbsp;<a href="/auth/forgot-password" className="text-mpBlue"><u>new link</u></a>.</>}
                        />
                    ) : formik.status === 'Success!' && (
                        <Alert color="mpLBlue" content="Password successfully reset!"/>
                    )}
                    <div className="grid place-items-center">
                        <div className="w-1/2 mt-10">
                            <h4>Password</h4>
                            <FormikControl
                                id="reset-password-pass"
                                name="password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <FormValidation
                                    color="mpLRed"
                                    content={formik.errors.password}
                                />
                            ) : null}
                        </div>

                        <br/>

                        <div className="w-1/2">
                            <h4>Confirm Password</h4>
                            <FormikControl
                                id="reset-password-confirm-pass"
                                name="passwordConfirm"
                                type="password"
                                value={formik.values.passwordConfirm}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
                                <FormValidation
                                    color="mpLRed"
                                    content={formik.errors.passwordConfirm}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group d-flex flex-wrap flex-center">
                        {wasRequested ? 
                            
                            <Button
                                color="inv-mpLRed"
                                onClick={() => null}
                            >
                                <Link to="/auth/login">Go to Login</Link>
                            </Button>
                            :
                            <Button
                                color="lte-mpLBlue"
                                className="font-weight-bold px-9 py-4 my-3 mx-4"
                                disabled={formik.isSubmitting || !formik.isValid}
                                onClick={formik.handleSubmit}
                            >
                                {wasRequested ? 'Email Sent!' : formik.isSubmitting ? 'Submitting...' : ' Submit'}
                            </Button>
                        }
                    </div>
                </form>
            </div>
        </>
    );
}

export default connect(null, {
    resetPassword
})(ResetPassword);
