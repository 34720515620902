import { useState, useEffect } from 'react';

export function FormValidation(props){
    const {
        color,
        className = '',
        content = '',
    } = props;

    const [fullClassName, setFullClassName] = useState(className);
    
    useEffect(() => {
        let newFullClassName = `${className} mt-1 text-sm rounded-md font-normal px-1 py-0.5`;

        switch(color){
            case 'mpLRed':
                newFullClassName += ' text-mpLRed bg-mpLRed-4';
                break;
            case 'mpOrange':
                newFullClassName += ' text-mpOrange bg-mpOrange-4';
                break;
            case 'mpYellow':
                newFullClassName += ' text-black bg-mpYellow-4';
                break;
            case 'mpGreen':
                newFullClassName += ' text-mpGreen bg-mpGreen-4';
                break;
            case 'mpEGreen':
                newFullClassName += ' text-black bg-mpEGreen-4';
                break;
             case 'mpTeal':
                newFullClassName += ' text-mpTeal bg-mpTeal-4';
                break;
            case 'mpLBlue':
                newFullClassName += ' text-mpLBlue bg-mpLBlue-4';
                break;
            case 'mpBlue':
                newFullClassName += ' text-mpBlue bg-mpBlue-4';
                break;
            case 'mpDBlue':
                newFullClassName += ' text-black bg-mpDBlue-3';
                break;
            case 'mpPurple':
                newFullClassName += ' text-mpPurple bg-mpPurple-4';
                break;
            case 'custom': break;
            default: break;
        }
        
        setFullClassName(newFullClassName.trim());
    }, [color, className]);

    if(!color || !content){
        return(
            <div className="text-mpLRed">
                FormValidation missing props: {!color && 'color'} {!content && 'content'}
            </div>
        );
    }

    return (
        <span className={fullClassName}> {content} </span>
    );
}