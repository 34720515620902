import React from 'react';
import * as Yup from 'yup';

import { Modal, ModalBodyFooter, Button, ModalProcessing } from '../../custom-essentials';
import { formatDate, convertApiToDate } from '../../functions';
import { renderSubmittingDefault } from '../../form';

export const validationSchema = Yup.object().shape({
    drsValid: Yup.mixed().when(['holdStart', 'holdEnd', 'noHold'],
        ([holdStart, holdEnd]) => {
            const isValid = (convertApiToDate(holdStart).getFullYear() < 2100 && convertApiToDate(holdEnd).getFullYear() < 2100);
            const errorText = `Years must be less than 2100`
            if(isValid) return Yup.mixed().test('drsValid', '', () => true);
            else return Yup.mixed().test('drsValid', errorText, () => false);
        }).when(['holdStart', 'holdEnd', 'noHold'],
        ([holdStart, holdEnd, noHold]) => {
            const holdStartDate = convertApiToDate(holdStart);
            const holdEndDate = convertApiToDate(holdEnd);
            const isValid1 = !isNaN(holdStartDate.getTime()) && !isNaN(holdEndDate.getTime());
            if(!isValid1) return Yup.mixed().test('drsValid', `At least one date is invalid`, () => false);
            
            const isValid2 = holdStartDate <= holdEndDate || noHold;
            if(!isValid2) return Yup.mixed().test('drsValid', `Start Date must come on or before End Date`, () => false);

            return Yup.mixed().test('drsValid', '', () => true);
        })
});

export function renderSubmitting(submissionStatus, setSubmitting, selectedStudent, manualEntryMessage){
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="grid grid-cols-1">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>
                
                <div className="grid grid-cols-1">
                    {renderSubmittingDefault(submissionStatus)}
                </div>

                {submissionStatus.completed ? 
                    <div className="grid grid-cols-1">
                        <br/>
                        <div className="text-mpLRed">
                            Note: If the student info updated successfully, but the holds log entry did not, consider
                            creating a manual entry in the holds log.
                        </div>
                        <br/>
                        <div className="text-mpOrange">
                            Student: {selectedStudent.first_name} {selectedStudent.last_name}
                        </div>
                        <div className="text-mpOrange">
                            Notes: {manualEntryMessage}
                        </div>
                    </div> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => {
                        setSubmitting(false)
                    }}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}

export function renderStudentInfo(student, contract){
    const currentNextMonthStart = new Date(student.mp_next_month_start);
    const currentContractStartDate = new Date(contract.start_date);
    const currentContractEndDate = new Date(contract.end_date);
    const currentInactiveDate = new Date(student.mp_inactive_date);

    return(
        <div className="grid grid-cols-1">
            <h4>{student.first_name} {student.last_name}</h4>
            <div>Next Month Start: {formatDate(currentNextMonthStart)}</div>
            <div>Current Contract: {formatDate(currentContractStartDate)} to {formatDate(currentContractEndDate)}</div>
            <div>Inactive Date: {formatDate(currentInactiveDate)}</div>
        </div>
    );
}

export function getHoldLength(startDate, endDate){
    if((new Date(startDate)).getFullYear() >= 2100 && (new Date(endDate)).getFullYear() >= 2100) return 0;
    return Math.floor((new Date(endDate) - new Date(startDate)) / (24 * 60 * 60 * 1000)) + 1;
}

export function checkValuesAreSame(values){
    const { originalHoldStart, holdStart, originalHoldEnd, holdEnd, originalNoHold, noHold } = values;
    const valuesAreSame = (
        (
            originalHoldStart.date - holdStart.date === 0
            && originalHoldEnd.date - holdEnd.date === 0
            && originalNoHold === noHold
        ) || (
            originalNoHold && noHold
        )
    );
    return valuesAreSame;
}

export function getNewDates(values, student, contract){
    const holdLengthDiff = values.holdLength - values.originalHoldLength;

    const currentNextMonthStart = new Date(student.mp_next_month_start);
    const newNextMonthStart = new Date(currentNextMonthStart);
    newNextMonthStart.setDate(newNextMonthStart.getDate() + holdLengthDiff);

    const currentContractEndDate = new Date(contract.end_date);
    const newContractEndDate = new Date(currentContractEndDate);
    newContractEndDate.setDate(newContractEndDate.getDate() + holdLengthDiff);

    const currentInactiveDate = new Date(student.mp_inactive_date);
    const newInactiveDate = new Date(currentInactiveDate);
    newInactiveDate.setDate(newInactiveDate.getDate() + holdLengthDiff);

    return {
        currentNextMonthStart,
        newNextMonthStart,
        currentContractEndDate,
        newContractEndDate,
        currentInactiveDate,
        newInactiveDate
    };
}
export function renderDateChanges(values, student, contract){
    const { originalHoldLength, holdLength } = values;

    if(checkValuesAreSame(values)){
        return <h4>No changes have been made</h4>;
    }

    const {
        currentNextMonthStart,
        newNextMonthStart,
        currentContractEndDate,
        newContractEndDate,
        currentInactiveDate,
        newInactiveDate
    } = getNewDates(values, student, contract);

    const inactiveDateMessage = currentInactiveDate.getFullYear() >= 3000 ? 'Never' : 
        `${formatDate(currentInactiveDate)} --> ${formatDate(newInactiveDate)}`;

    return(
        <>
            <h4>Submitting will update the following:</h4>
            <div className="h-2 clear-both"/>
            <div>Hold Length: {`${originalHoldLength} days`} --&gt; {`${holdLength} days`}</div>
            <div>Next Month Start Date: {formatDate(currentNextMonthStart)} --&gt; {formatDate(newNextMonthStart)}</div>
            <div>Contract End Date: {formatDate(currentContractEndDate)} --&gt; {formatDate(newContractEndDate)}</div>
            <div>Inactive Date: {inactiveDateMessage}</div>
        </>
    );
}