import { basicPath } from '../basicPath';

export const fetchLessonsAll = (params) => async (dispatch) => {
    const path = '/mp/lessons/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchLessonsId = (params) => async (dispatch) => {
    const path = '/mp/lessons/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createLesson = (params) => async (dispatch) => {
    const path = '/mp/lessons';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateLesson = (params) => async (dispatch) => {
    const path = '/mp/lessons';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteLesson = (params) => async (dispatch) => {
    const path = '/mp/lessons';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}