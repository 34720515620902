import React from 'react';
import { formatDate } from '../../../../../components/functions';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumnsContracts(handleShowModal){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
            formatter: (row) => {
                const { studentName } = row;
                if(row.isCurrent){
                    const check = (
                        <TooltipWrapper
                            tooltipText={`This is ${studentName}'s current contract`}
                        >
                            <SVGIcon fileName="check"/>
                        </TooltipWrapper>
                    )
                    return (
                        <div className="row no-mar">
                            {studentName}&nbsp;{check}
                        </div>
                    );
                }
                else return studentName;
            }
        },
        {
            dataField: 'type',
            text: 'Contract Type',
            sort: true,
            formatter: (row) => {
                const { type } = row;
                switch(type){
                    case 'Contract':
                        return (
                            <Label color="mpGreen">
                                Contract
                            </Label>
                        );
                    case 'Extra':
                        return (
                            <Label color="mpPurple">
                                Extra
                            </Label>
                        );
                    case 'Month to Month':
                        return (
                            <Label color="mpLBlue">
                                M2M
                            </Label>
                        );
                    case 'Sessions':
                        return (
                            <Label color="mpOrange">
                                Sessions
                            </Label>
                        );
                    case 'Up Front':
                        return (
                            <Label color="mpLRed">
                                Up Front
                            </Label>
                        );
                    default:
                        return (
                            <Label color="mpLGrey">
                                {type}
                            </Label>
                        );
                }
            }
        },
        {
            dataField: 'start_date',
            text: 'Contract Start',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDate(row.start_date)
        },
        {
            dataField: 'end_date',
            text: 'Contract End',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                
                const aSortBy = parseInt(rowA.expires) === 0 ? 0 : aDate;
                const bSortBy = parseInt(rowB.expires) === 0 ? 0 : bDate;
                return (aSortBy - bSortBy) * swap;
            },
            formatter: (row) => {
                if(parseInt(row.expires) === 0){
                    return(
                        <Label color="mpYellow">
                            Never
                        </Label>
                    )
                } else return formatDate(row.end_date);
            }
        },
        { 
            dataField: 'amount_per_payment',
            text: 'Amount Per Payment',
            sort: true,
            formatter: (row) => `$${row.amount_per_payment} (${row.payment_frequency})`
        },
        { 
            dataField: 'payment_date_offset',
            text: 'Days Offset',
            sort: true,
        },
        {
            dataField: 'paymentStatus',
            text: 'Payment Status',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                // Sort 'extra' contracts last (asc) or first (desc)
                const swap = order === 'asc' ? -1 : 1;
                const aIsExtra = rowA.type === 'Extra' ? 1 : 0;
                const bIsExtra = rowB.type === 'Extra' ? 1 : 0;
                if(aIsExtra || bIsExtra) return (aIsExtra - bIsExtra) * swap;

                // Negative is underpaid. Sort these first (asc) or last (desc)
                const aBalanceDiff = rowA.balancePaid - rowA.balanceOwed;
                const bBalanceDiff = rowB.balancePaid - rowB.balanceOwed;
                return (aBalanceDiff - bBalanceDiff) * swap;
            },
            formatter: (row) => {
                const balanceDiff = row.balancePaid - row.balanceOwed;
                let paymentLabel = null;
                // if(row.type === 'Extra') {
                //     paymentLabel = (
                //         <Label color="mpPurple">
                //             NA - extra
                //         </Label>
                //     );
                // } else 
                if(balanceDiff < 0){
                    paymentLabel = (
                        <Label color="mpLRed">
                            Under (${Math.abs(balanceDiff)})
                        </Label>
                    );
                } else if(balanceDiff > 0){
                    paymentLabel = (
                        <Label color="mpYellow">
                            Over (${Math.abs(balanceDiff)})
                        </Label>
                    );
                } else {
                    paymentLabel = (
                        <Label color="mpGreen">
                            Matching
                        </Label>
                    );
                }
                const expectedText = <div>Expected: ${row.balanceOwed}</div>;
                const paidText = <div>Paid: ${row.balancePaid}</div>;
                const diffText = row.balanceOwed === row.balancePaid ? null : <div>Difference: ${row.balanceOwed - row.balancePaid}</div>
                let index = 0;
                return(
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                {expectedText}
                                {paidText}
                                {diffText}
                                { row.relevantPayments?.length !== 0 ? (
                                        <>
                                            <br/>
                                            Summary:
                                            {row.relevantPayments.map(rp => {
                                                return (
                                                    <div key={`c-${row.id}-p-${rp.id}-${index++}`}>
                                                        {formatDate(rp.date)}: ${rp.amount}
                                                    </div>
                                                )
                                            })}
                                        </>
                                    ) : null
                                }
                            </div>
                        }
                    >
                        {paymentLabel}
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'notes',
            text: 'Notes',
            headerStyle: () => ({ width: "30%" }),
            formatter: (row) => row.notes || 'None'
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit contract`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete contract`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}

export function getColumnsPayments(handleShowPaymentModal, handleShowContractModal){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
        },
        {
            dataField: 'date',
            text: 'Date Paid',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDate(row.date)
        }, 
        {
            dataField: 'method',
            text: 'Method',
            sort: true,
            formatter: (row) => {
                const { method } = row;
                if(method === 'Credit') return <Label color="mpOrange">Credit</Label>;
                else if(method === 'Venmo') return <Label color="mpLBlue">Venmo</Label>;
                else if(method === 'Zelle') return <Label color="mpPurple">Zelle</Label>;
                else return <Label color="mpLGrey">{method}</Label>;
            }
        },
        {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            formatter: (row) => `$${row.amount}`
        }, {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => row.notes || 'None'
        }, {
            dataField: 'contractDetails',
            text: 'Contract',
            formatter: (row) => {
                const cd = row.contractDetails;
                if(!cd.id) return 'None'; // No relevant contract exists (only happens if contract was deleted)
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                <div>Type: {cd.type}</div>
                                <div>Start: {formatDate(cd.start_date)}</div>
                                <div>End: {parseInt(cd.expires) ? formatDate(cd.end_date) : 'Never'}</div>
                                <div>Payment Freq.: {cd.payment_frequency}</div>
                                <div>Amount Per Payment: ${cd.amount_per_payment}</div>
                                <div>Notes: {cd.notes}</div>
                            </div>
                        }
                    >
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => handleShowContractModal('edit', cd)}
                        >
                            <SVGIcon fileName="contract"/>
                        </Button>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit payment`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowPaymentModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete payment`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowPaymentModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}

export function getColumnsWarnings(handleShowModalSpecialCreate){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true
        }, {
            dataField: 'warningType',
            text: 'Warning Type',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                
                const rowAPriority = rowA.noContract ? 1 : rowA.contractExpired ? 2 : rowA.endingSoon ? 3 : 0;
                const rowBPriority = rowB.noContract ? 1 : rowB.contractExpired ? 2 : rowB.endingSoon ? 3 : 0;
                return swap * (rowAPriority - rowBPriority);
            },
            formatter: (row) => {
                if(row.noContract){
                    return (
                        <Label color="mpLRed">
                            No Contract
                        </Label>
                    );
                } else if(row.contractExpired){
                    return (
                        <Label color="mpYellow">
                            Expired Contract
                        </Label>
                    );
                } else if(row.endingSoon){
                    return (
                        <Label color="mpLBlue">
                            Ending Soon
                        </Label>
                    );
                }
            }
        }, {
            dataField: 'message',
            text: 'Message',
            formatter: (row) => {
                if(row.noContract){
                    return 'Student is currently active but does not have an assigned contract.'
                } else if(row.contractExpired){
                    const contract = row.currentContract || {};
                    return (
                        `Student is currently active but has an expired contract. 
                        Contract expired on ${formatDate(contract.end_date)}.`
                    );
                } else if(row.endingSoon){
                    const contract = row.currentContract || {};
                    return (
                        `Contract is ending within the next month on ${formatDate(contract.end_date)}.`
                    );
                }
            },
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                
                return swap * (rowA.messageSortIndex - rowB.messageSortIndex)
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <TooltipWrapper
                        tooltipText={`Add contract for ${row.first_name} ${row.last_name}`}
                    >
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => handleShowModalSpecialCreate('create-special', row)}
                        >
                            + New Contract
                        </Button>
                    </TooltipWrapper>
                );
            }
        },
    ];
}