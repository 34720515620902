import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import RITabContent from './relevantInfo/RITabContent';
import { MentionsInput, DateRangeSelector } from '../../../../components/form';

function RelevantInfo(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [searchTerm, setSearchTerm] = useState('');

    const { parentApiError, relInfo, formik, refreshRelInfo, searchTermTrigger, tagsData } = props;

    useEffect(() => {
        setSearchTerm(searchTermTrigger);
    }, [searchTermTrigger]);

    return (
        <div>
            <h2>Relvant Info Search</h2>

            <br/>

            <DateRangeSelector
                id="relevant-info-drs"
                startName="startDate"
                endName="endDate"
                startLabel="Start Date"
                endLabel="End Date"
                startValue={formik.values.startDate}
                endValue={formik.values.endDate}
                defaultValid={true}
                onStartChange={formik.handleChange}
                onEndChange={formik.handleChange}
            />

            <br/>

            <MentionsInput
                id="relevantInfoSearch"
                name="searchTerm"
                tags={tagsData}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                updateLastTagged={(item) => refreshRelInfo(item.value)}
                triggerKeyCode={50}
                shiftKey={true}
                placeholder="Type @ to search for a user. Select an item to search."
            />
            <br/>
            {parentApiError ? <div className="text-mpLRed">{parentApiError}</div> :
            Object.keys(relInfo).length ?
                <RITabContent
                    relInfo={relInfo}
                />
                : null
            }
        </div>
    );
}

export default connect(null, {

})(RelevantInfo);