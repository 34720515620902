import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay } from "../../../../components/display";
import { DateRangeSelector, SelectSingle, FormikControl, checkResponses } from '../../../../components/form';
import { formatDateApi } from '../../../../components/functions';
import { StampsLogModal } from '../../../../components/modal';
import { TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import StampsTable from './StampsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchStudentsAll,
    fetchAdminUsersAll,
    fetchStampsLogsDaterange
} from '../../../../actions';

const pageTitle = 'Stamps Logs';

const start = new Date();
start.setDate(start.getDate() - 7);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'student name', label: 'Student Name' },
    { value: 'instructor name', label: 'Instructor Name' },
    { value: 'type', label: 'Type' },
    { value: 'amount', label: 'Amount' },
    { value: 'notes', label: 'Notes' },
];

function StampsLogs(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [drsValid, setDrsValid] = useState(true);
    const [apiError, setApiError] = useState(false);
    // Static data
    const [students, setStudents] = useState([]);
    const [employees, setEmployees] = useState([]);
    // Data
    const [stampsLogs, setStampsLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    // Modal
    const [modalMode, setModalMode] = useState(null);

    const { fetchStudentsAll, fetchAdminUsersAll, fetchStampsLogsDaterange } = props;

    const filterLogs = useCallback((unfilteredLogs, filterType, filterQuery) => {
        filterQuery = filterQuery.toLowerCase();
        const checkStudentNames = (hl) => hl.studentName.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkInstructorNames = (hl) => hl.updatedByName.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkType = (hl) => hl.type.toLowerCase().includes(filterQuery);
        const checkAmount = (hl) => `${hl.amount}`.toLowerCase().includes(filterQuery);
        const checkNotes = (hl) => hl.notes.toLowerCase().includes(filterQuery);

        const newLogs = unfilteredLogs.filter(hl => {
            switch(filterType.value){
                case 'all':
                    return checkStudentNames(hl) || checkInstructorNames(hl) || checkType(hl) || checkAmount(hl) || checkNotes(hl);
                case 'student name':
                    return checkStudentNames(hl);
                case 'instructor name':
                    return checkInstructorNames(hl);
                case 'type':
                    return checkType(hl);
                case 'amount':
                    return checkAmount(hl);
                case 'notes':
                    return checkNotes(hl);
                default:
                    return false;
            }
        });

        if(mounted.current) setFilteredLogs(newLogs);
    }, [mounted]);
    const refreshData = useCallback((newStudents = students, newEmployees = employees) => {
        (async function refresh(){
            if(loading || !drsValid || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
            
            const { startDate, endDate, filterType, filterQuery } = formRef.current.values;
            
            const stampsLogsRes = await fetchStampsLogsDaterange({
                startDate: startDate,
                endDate: endDate,
            });
            const isApiError = checkResponses(stampsLogsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newStampsLogs = stampsLogsRes.data || [];
    
            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const employeeMap = {};
            newEmployees.forEach(e => employeeMap[e.id] = `${e.first_name} ${e.last_name}`);
    
            const stampsLogsAppended = newStampsLogs.map(sl => {
                sl.studentName = studentMap[sl.student] || `Unknown student (UID: ${sl.student})`;
                sl.updatedByName = employeeMap[sl.instructor] || `Unknown employee (ID: ${sl.instructor})`;
                return sl;
            });
            
            if(mounted.current){
                setStampsLogs(stampsLogsAppended);
                filterLogs(stampsLogsAppended, filterType, filterQuery);
                setLoading(false);
            }
        })();
    }, [loading, mounted, drsValid, students, employees, filterLogs, fetchStampsLogsDaterange]);
    useEffect(() => {
        const init = async () => {
            const studentsRes = await fetchStudentsAll();
            const employeesRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(studentsRes, employeesRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newStudents = studentsRes.data || [];
            const newEmployees = employeesRes.data || [];
            
            if(mounted.current){
                setStudents(newStudents);
                setEmployees(newEmployees);
                refreshData(newStudents, newEmployees);
    
                setHasLoaded(true);
            } 
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleShowModal(){
        setModalMode('create');
    }
    function onSubmitCallback(changes){
        setModalMode(null);
        if(changes) refreshData();
    }

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {(loading || !hasLoaded) &&
                <LoadingOverlay/>
            }
            {modalMode && 
                <StampsLogModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        filterType: { value: 'all', label: 'All' },
                        filterQuery: '',
                    }}
                    innerRef={formRef}
                    onSubmit={() => refreshData()}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <h2>Search for Stamps Logs Entries</h2>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <DateRangeSelector
                                        id="stamps-log-drs-1"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="flex self-center">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                            
                            <br/>
                            
                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                            <>
                                <hr/>
                                <br/>

                                <h2>Filter Results ({filteredLogs.length})</h2>
                                <br/>
                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <SelectSingle
                                            id="stamps-logs-searchType"
                                            name="filterType"
                                            label="Filter by"
                                            value={formik.values.filterType}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterLogs(stampsLogs, e.target.value, formik.values.filterQuery);
                                            }}
                                            options={filterTypeOptions}
                                        />
                                    </div>
                                    <div className="flex w-1/4">
                                        <FormikControl
                                            id="flag-manager-searchQuery"
                                            name="filterQuery"
                                            placeholder="Enter a filter query..."
                                            value={formik.values.filterQuery}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterLogs(stampsLogs, formik.values.filterType, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => handleShowModal('create')}
                                            style={{ alignSelf: "flex-end" }}
                                        >
                                            + Add Log Entry
                                        </Button>
                                    </div>
                                    <div className="flex ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    <div>
                                                        What gets exported?
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        All stamps logs that are currently filtered ({filteredLogs.length} items).
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <CSVExport
                                                title="Stamps_Logs"
                                                label="Export Logs to CSV"
                                                data={filteredLogs}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                            </>
                            }
                        </form>
                    )}
                </Formik>

                <br/>
                {apiError ? null : hasLoaded &&
                    <StampsTable
                        stampsLogs={filteredLogs}
                        refreshData={refreshData}
                    />
                }
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchStudentsAll,
    fetchAdminUsersAll,
    fetchStampsLogsDaterange
})(StampsLogs);