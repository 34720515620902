import { rgb, PDFDocument, StandardFonts } from 'pdf-lib';

import { openPopup, downloadFile, getymd, formatDateFull,
    formatDate, generateStringBlocks, appendLogo, appendStudentName } from '../../../../../components/functions';

async function printLearningPlanReport(reportPdf, fontObj, logoData, learningPlan, studentName){
    let currentPage = reportPdf.addPage();

    // A - MASTER VARIABLES //
        const { width, height } = currentPage.getSize();
        const yMarginQuarterInches = 3;
        const yMin = height * (yMarginQuarterInches/44);
        const yMax = height - (height * (yMarginQuarterInches/44));
        const xMarginQuarterInches = 3;
        const xMin = width * (xMarginQuarterInches/34);
        const xMax = width - (width * (xMarginQuarterInches/34));
    
        // let xPos = xMin;
        let yPos = yMax;
    
        const lpObj = learningPlan;
    // 0 - HEADERS //
    // 0a - Student and Date (corner text) -- This is now done using appendStudentName at the end //
        // const cornerText = `${studentName}, ${formatDate(new Date())}`;
        // const cornerTextSize = 12;
        // const cornerTextWidth = fontObj.widthOfTextAtSize(cornerText, cornerTextSize);
        // const cornerTextXPos = xMax - cornerTextWidth;
        // currentPage.drawText(cornerText, {
        //     x: cornerTextXPos,
        //     y: yMax + 30,
        //     size: cornerTextSize,
        //     font: fontObj
        // });
    // 0b - Name //
        const lpHeaderText = `${lpObj.name}`;
        const lpHeaderSize = 20;
        const lpHeaderWidth = fontObj.widthOfTextAtSize(lpHeaderText, lpHeaderSize);
        const lpHeaderHeight = fontObj.heightAtSize(lpHeaderSize);
        const lpHeaderXPos = xMin + ((xMax - xMin - lpHeaderWidth) / 2);
        const lpHeaderAfterMargin = 5;
        currentPage.drawText(lpHeaderText, {
            x: lpHeaderXPos,
            y: yPos,
            size: lpHeaderSize,
            font: fontObj
        });
        yPos -= (lpHeaderHeight + lpHeaderAfterMargin);
    // 0c - Date //
        const lpDateText = `LP Created ${formatDateFull(lpObj.date_created)}`;
        const lpDateSize = 15;
        const lpDateWidth = fontObj.widthOfTextAtSize(lpDateText, lpDateSize);
        const lpDateHeight = fontObj.heightAtSize(lpDateSize);
        const lpDateXPos = xMin + ((xMax - xMin - lpDateWidth) / 2);
        const lpDateAfterMargin = 25;
        currentPage.drawText(lpDateText, {
            x: lpDateXPos,
            y: yPos,
            size: lpDateSize,
            font: fontObj
        });
        yPos -= (lpDateHeight + lpDateAfterMargin);

    // B - LPI Constants
        const itemNameXPos = xMin;
        const itemDateAssignedXPos = xMax - 220;
        const itemDateCompletedXPos = xMax - 110;

    // 1 - Headers //
        const lpiHeaderSize = 12;
        const lpiHeaderHeight = fontObj.heightAtSize(lpiHeaderSize);
        const lpiHeaderAfterMargin = 20;
    // 1a - Name //
        const lpiHeaderNameText = 'Lesson Name';
        currentPage.drawText(lpiHeaderNameText, {
            x: itemNameXPos,
            y: yPos,
            size: lpiHeaderSize,
            font: fontObj
        });

    // 1b - Date Assigned //
        const lpiHeaderDateAssignedText = 'Date Assigned';
        currentPage.drawText(lpiHeaderDateAssignedText, {
            x: itemDateAssignedXPos,
            y: yPos,
            size: lpiHeaderSize,
            font: fontObj
        });
    // 1c - Date Completed //
        const lpiHeaderDateCompletedText = 'Date Completed';
        currentPage.drawText(lpiHeaderDateCompletedText, {
            x: itemDateCompletedXPos,
            y: yPos,
            size: lpiHeaderSize,
            font: fontObj
        });
        yPos -= (lpiHeaderHeight + lpiHeaderAfterMargin);

    // 2 - Learning Plan Items //
        const lpItems = lpObj.items || [];

        const itemSize = 10;
        const itemHeight = fontObj.heightAtSize(itemSize);
        const itemInterMargin = 5; // Between lines within the name field
        const itemAfterMargin = 10; // Between items
        const itemNameTextMaxWidth = itemDateAssignedXPos - itemNameXPos - 50;
        lpItems.forEach(lpi => {
            if(yPos - itemHeight < yMin){
                currentPage = reportPdf.addPage();
                yPos = yMax;
            }

            const itemNameText = lpi.lessonObj?.name || `Unknown lesson (Lesson ID: ${lpi.lesson_id})`;
            const itemNameBlocks = generateStringBlocks(fontObj, itemSize,
                itemNameText, itemNameTextMaxWidth);
            const originalYPos = yPos;
            for(let block of itemNameBlocks){
                currentPage.drawText(block, {
                    x: itemNameXPos,
                    y: yPos,
                    size: itemSize,
                    font: fontObj
                });
                yPos -= (itemSize + itemInterMargin);
            }
            yPos = originalYPos;
            const isAssigned = lpi.date_assigned ? true : false;
            const itemDateAssignedText = isAssigned ? formatDate(lpi.date_assigned) : 'Never';
            const itemDateAssignedColor = itemDateAssignedText === 'Never' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0); // Orange or black
            currentPage.drawText(itemDateAssignedText, {
                x: itemDateAssignedXPos,
                y: yPos,
                size: itemSize,
                font: fontObj,
                color: itemDateAssignedColor
            });
            const isCompleted = lpi.date_completed ? true : false;
            const itemDateCompletedText = isCompleted ? formatDate(lpi.date_completed) : 'Never';
            const itemDateCompletedColor = itemDateCompletedText === 'Never' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0); // Orange or black
            currentPage.drawText(itemDateCompletedText, {
                x: itemDateCompletedXPos,
                y: yPos,
                size: itemSize,
                font: fontObj,
                color: itemDateCompletedColor
            });

            yPos -= ((itemHeight + itemInterMargin) * itemNameBlocks.length + itemAfterMargin);
        });
    reportPdf = await appendLogo(reportPdf, logoData);
    reportPdf = await appendStudentName(reportPdf, studentName, fontObj);
    return reportPdf;
}

export async function downloadReport(formik, logoData, loading, setLoading){
    if(loading) return;
    setLoading(true);

    const { selectedLearningPlans, selectedStudent, quickDownload, quickDownloadDate } = formik.values;
    if(!selectedLearningPlans.length){
        formik.setStatus('Please select at least one learning plan.');
        setLoading(false);
        return;
    }

    let reportPdf = await PDFDocument.create();
    const helveticaFont = await reportPdf.embedFont(StandardFonts.Helvetica);

    for(let learningPlan of selectedLearningPlans){
        const learningPlanObj = learningPlan.obj || {};
        const lpItems = learningPlanObj.items;
        if(!lpItems || !lpItems.length){
            formik.setStatus('The selected learning plan has no items.');
            setLoading(false);
            return;
        }
        reportPdf = await printLearningPlanReport(reportPdf, helveticaFont, logoData, learningPlanObj, selectedStudent.label);
    }

    const pdfBytes = await reportPdf.save();
    if(quickDownload){
        const [year, month, date] = getymd(`${quickDownloadDate} 00:00:00`);
        downloadFile(pdfBytes, `${year}-${month}-${date} ${selectedStudent.label} LP.pdf`);
    } else {
        openPopup(pdfBytes);
    }

    setLoading(false);
    return;
};