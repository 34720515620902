import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { Button, ListGroup } from '../../custom-essentials';
import { formatDateFull, formatTime, getAvailabilities, getymd } from '../../functions';
import { SelectSingle } from '../../form';

const defaultOption = { value: -1, label: 'Please select...' };
const notFoundOption = { value: -1, label: 'No times are available '};

function BodyLowerHalf(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { formik, today, centerOptions, appointments, availabilities, blocks } = props;

    const availableAppointments = useMemo(() => {
        const availableAppointments = {};

        // Dates where selectedStudents have appointments
        const excludedDates = {};
        // Initialize
        const newToday = new Date(today);
        for(let i = 0; i < 28; i++){
            const [year, month, date] = getymd(newToday);
            const dateKey = `${year}_${month}_${date}`;
            excludedDates[dateKey] = false;

            newToday.setDate(newToday.getDate() + 1);
        }

        if(formik.values.overrideMode){
            // OVERRIDE MODE

            for(let dateKey of Object.keys(excludedDates)){
                availableAppointments[dateKey] = {};
                for(let center of centerOptions){
                    const options = [defaultOption];
                    const centerId = parseInt(center.value);
                    for(let timeKey = formik.values.minimumTime.raw; timeKey <= formik.values.maximumTime.raw; timeKey += 30){
                        for(let duration = 30; duration <= 120; duration += 30){
                            options.push({ 
                                value: `${centerId}.${timeKey}.${duration}`,
                                label: `${formatTime(timeKey)} (${duration} minutes)`
                            });
                        }
                    }
                    availableAppointments[dateKey][centerId] = options;
                }
            }
        } else {
            let totalWeight = 0;
            // React doesn't like the following to be done within .forEach
            for(let s of formik.values.selectedStudents) totalWeight += parseInt(s.object.mp_weight);
            const nStudents = formik.values.selectedStudents.length;

            const groupedAvailabilities = getAvailabilities(appointments, availabilities, blocks);

            // Map of selectedStudents
            const selectedStudentIds = {};
            formik.values.selectedStudents.forEach(s => selectedStudentIds[s.value] = true);
            // Set excludedDates values true where appointments exist
            for(let appointment of appointments){
                if(selectedStudentIds[appointment.student]){
                    const [year, month, date] = getymd(new Date(appointment.date_time));
                    const dateKey = `${year}_${month}_${date}`;
                    excludedDates[dateKey] = true;
                }
            }
    
            // Check all 28 days
            for(let dateKey of Object.keys(excludedDates)){
                availableAppointments[dateKey] = {};
    
                const centerGroup = groupedAvailabilities[dateKey];
                if(centerGroup && !excludedDates[dateKey]){
                    // Check each center
                    Object.keys(centerGroup).forEach((centerId) => {
                        const options = [defaultOption];
                        const seatingInfo = centerGroup[parseInt(centerId)]?.seatingInfo;
                        if(!seatingInfo) return; // Should never happen
    
                        // Check each timkey
                        Object.entries(seatingInfo).forEach(([timeKey, info]) => {
                            // Check each duration
                            for(let i = 30; i <= 120; i += 30){
                                const maxS = `maxS${i}`;
                                const maxW = `maxW${i}`;
                                const msS = `msS30`;
                                const msW = `msW30`;
                                // Add option if seats are available
                                const isStartValid = info[msS] >= nStudents && info[msW] >= totalWeight;
                                if(info[maxS] >= nStudents && info[maxW] >= totalWeight && isStartValid){
                                    const duration = i;
                                    options.push({
                                        value: `${centerId}.${timeKey}.${duration}`,
                                        label: `${formatTime(timeKey)} (${duration} minutes)`
                                    });
                                }
                            }
                        });
    
                        if(options.length === 1) options[0] = notFoundOption;
    
                        availableAppointments[dateKey][parseInt(centerId)] = options;
                    })
                }
    
                newToday.setDate(newToday.getDate() + 1);
            }
        }

        return availableAppointments;

        // Need this to allow reloadCount to work as intended
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.selectedStudents, formik.values.overrideMode, formik.values.reloadCount,
        formik.values.minimumTime, formik.values.maximumTime, centerOptions,
        availabilities, blocks, appointments, today]);

    const renderAvailableAppointments = useCallback(() => {
        const selectedDates = Object.keys(formik.values.selectedAppointments);

        return(
            <ListGroup>
                {Object.entries(availableAppointments).map(([dateKey, options]) => {
                    // Skip if no options are present (no availabilities exist, or centers are closed)
                    // OR if this item has already been selected
                    if(!Object.keys(options).length || selectedDates.includes(dateKey)) return null;
                    const formikCenterKey = `${dateKey}_center`;
                    const formikTimeKey = `${dateKey}_time_duration`;
                    const timeOptions = options[formik.values[formikCenterKey]?.value] || [notFoundOption];
                    return(
                        <div key={`booking-assistant-available-appointments-${dateKey}`}>
                            <h4>{formatDateFull(dateKey.replace(/_/g, '/'))}</h4>
                            <div className="h-4 clear-both"/>
                            <SelectSingle
                                id={`booking-assistant-available-appointments-${formikCenterKey}`}
                                name={formikCenterKey}
                                value={formik.values[formikCenterKey]}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    formik.setFieldValue(formikTimeKey,
                                        options[e.target.value.value]?.[0] || notFoundOption)
                                }}
                                options={centerOptions}
                            />
                            <div className="h-4 clear-both"/>
                            <SelectSingle
                                id={`booking-assistant-available-appointments-${formikTimeKey}`}
                                name={formikTimeKey}
                                value={formik.values[formikTimeKey]}
                                onChange={formik.handleChange}
                                options={timeOptions}
                            />
                            <div className="h-4 clear-both"/>
                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="ml-auto">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={() => {
                                            const selected = {...formik.values.selectedAppointments};
                                            selected[dateKey] = formik.values[formikTimeKey];
                                            formik.setFieldValue('selectedAppointments', selected);
                                        }}
                                        disabled={formik.values[formikTimeKey]?.value === -1}
                                    >
                                        + Add Appointment
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </ListGroup>
        )
    }, [availableAppointments, centerOptions, formik]);

    const selectedTotalHours = useMemo(() => {
        let minutes = 0;
        Object.values(formik.values.selectedAppointments).forEach(entry => {
            const addMinutes = entry.value.split('.')[2];
            minutes += parseInt(addMinutes);
        });
    
        return (minutes / 60).toLocaleString(undefined, { maximumFractionDigits: 1 });
    }, [formik.values.selectedAppointments]);

    const renderSelectedAppointments = useCallback(() => {
        const selectedAppointments = formik.values.selectedAppointments;

        const centerMap = {};
        centerOptions.forEach(c => centerMap[parseInt(c.value)] = c.label);
        return(
            <ListGroup>
                {Object.entries(selectedAppointments).map(([dateKey, selectedItem]) => {
                    const [center, time, length] = selectedItem.value.split('.');
                    return(
                        <div key={`booking-assistant-selected-appointments-${dateKey}`}>
                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 w-2/3">
                                    <h4>{formatDateFull(dateKey.replace(/_/g, '/'))}</h4>
                                    <div className="h-4 clear-both"/>
                                    <div>Center: {centerMap[parseInt(center)]}</div>
                                    <div>Time: {formatTime(time)}</div>
                                    <div>Length: {length} minutes</div>
                                </div>
                                <div className="ml-auto">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={() => {
                                            const selected = {...formik.values.selectedAppointments};
                                            delete selected[dateKey]
                                            formik.setFieldValue('selectedAppointments', selected);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </ListGroup>
        )
    }, [centerOptions, formik]);

    return(
        <div className="flex flex-row gap-x-4">
            {formik.values.showAvailable ? 
                <div className="w-1/2">
                    <h4
                        className="text-mpLBlue table cursor-pointer"
                        onClick={() => formik.setFieldValue('showAvailable', false)}
                    >
                        <u>Available Appointments</u>
                    </h4>
                    <div className="h-4 clear-both"/>
                    <div className="max-h-[70vh] overflow-y-auto">
                        {renderAvailableAppointments()}
                    </div>
                </div>
                :
                <div className="w-1/2">
                    <h4
                        className="text-mpOrange table cursor-pointer"
                        onClick={() => formik.setFieldValue('showAvailable', true)}
                    >
                        <u>Available Appointments (hidden)</u>
                    </h4>
                </div>
            }
            {formik.values.showSelected ?
                <div className="w-1/2">
                    <h4
                        className="text-mpLBlue table cursor-pointer"
                        onClick={() => formik.setFieldValue('showSelected', false)}
                    >
                        <u>Selected Appointments ({selectedTotalHours} hours)</u>
                    </h4>
                    <div className="h-4 clear-both"/>
                    <div className="max-h-[70vh] overflow-y-auto">
                        {renderSelectedAppointments()}
                    </div>
                </div>
                :
                <div className="w-1/2">
                    <h4
                        className="text-mpOrange table cursor-pointer"
                        onClick={() => formik.setFieldValue('showSelected', true)}
                    >
                        <u>Selected Appointments (hidden)</u>
                    </h4>
                </div>
            }
        </div>
    );
}

export default connect(null, {

})(BodyLowerHalf);