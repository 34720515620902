import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function BrowserTabTitle(props){
    const { children } = props;
    return (
        <HelmetProvider><Helmet><title>{children}</title></Helmet></HelmetProvider>
    );
}

export default BrowserTabTitle;