import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import { Formik } from 'formik';

import { Switch } from '../../../../../../components/form';

import { getSeriesOptions } from './getSeries';

const lsSwitchesTotal = JSON.parse(localStorage.getItem('admin_scheduling_overview_appointments_switches'));
const defaultSwitchesTotal = ['null', null].includes(lsSwitchesTotal) ? {
    maxW: true,
    maxStartW: true,
    maxS: true,
    maxStartS: true,
    scheduledW: true,
    scheduledS: true,
    startingW: true,
    startingS: true,
    leavingW: true,
    leavingS: true
} : lsSwitchesTotal;

function OverviewAppointments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [afterCloseWarning, setAfterCloseWarning] = useState(false);
    const [switchesTotal, setSwitchesTotal] = useState(defaultSwitchesTotal);

    const { show, availabilityBlocks, appointments } = props;

    const chartOptions = useMemo(() => {
        if(!Object.keys(switchesTotal).length) {
            setAfterCloseWarning(false);
            return { series: [], options: {} };
        }

        const returnVals = getSeriesOptions(availabilityBlocks,
            appointments, switchesTotal);
        setAfterCloseWarning(returnVals.shouldWarn);

        return { series: returnVals.series, options: returnVals.options };
    }, [availabilityBlocks, appointments, switchesTotal]);
    
    const handleSwitchesTotal = useCallback((formik, mode, event) => {
        let newSwitchesTotal = {...switchesTotal};
        
        if(mode === 'single'){
            const { name, value } = event.target;
            const trueValue = value && value !== 'false' ? true : false;
            newSwitchesTotal[name] = trueValue;
        } else if(mode === 'showAll'){
            Object.keys(newSwitchesTotal).forEach(s => newSwitchesTotal[s] = true);
        } else if(mode === 'hideAll'){
            Object.keys(newSwitchesTotal).forEach(s => newSwitchesTotal[s] = false);
        } else if(mode === 'weightsOnly'){
            newSwitchesTotal = {
                maxW: true,
                maxStartW: true,
                maxS: false,
                maxStartS: false,
                scheduledW: true,
                scheduledS: false,
                startingW: true,
                startingS: false,
                leavingW: true,
                leavingS: false
            };
        } else if(mode === 'studentsOnly'){
            newSwitchesTotal = {
                maxW: false,
                maxStartW: false,
                maxS: true,
                maxStartS: true,
                scheduledW: false,
                scheduledS: true,
                startingW: false,
                startingS: true,
                leavingW: false,
                leavingS: true
            };
        }

        localStorage.setItem('admin_scheduling_overview_appointments_switches', JSON.stringify(newSwitchesTotal));

        if(mounted.current){
            setSwitchesTotal(newSwitchesTotal);
            formik.setValues(newSwitchesTotal);
        }
    }, [mounted, switchesTotal]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={defaultSwitchesTotal}
            >
                
                {formik => (
                    <>
                        {show ? 
                            <>
                                <div className="w-2/3">
                                    <Chart series={chartOptions.series} options={chartOptions.options} type="line"/>
                                    <br/>
                                    {afterCloseWarning ? 
                                        <div className="text-mpLRed">
                                            Warning! An appointment is scheduled outside of opening hours.
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="grid grid-cols-2 gap-y-2 w-1/6">
                                    <div className="col">
                                        <h4>Availabilities:</h4>

                                        <div
                                            onClick={() => handleSwitchesTotal(formik, 'showAll')}
                                            className="text-mpLBlue cursor-pointer table text-sm"
                                        >
                                            Show All
                                        </div>
                                        <div
                                            onClick={() => handleSwitchesTotal(formik, 'hideAll')}
                                            className="text-mpLBlue cursor-pointer table text-sm"
                                        >
                                            Hide All
                                        </div>
                                        <div
                                            onClick={() => handleSwitchesTotal(formik, 'weightsOnly')}
                                            className="text-mpLBlue cursor-pointer table text-sm"
                                        >
                                            Weights Only (W)
                                        </div>
                                        <div
                                            onClick={() => handleSwitchesTotal(formik, 'studentsOnly')}
                                            className="text-mpLBlue cursor-pointer table text-sm"
                                        >
                                            Students Only (S)
                                        </div>
                                        <br/>
                                        <Switch
                                            name="maxW"
                                            color="mpPurple"
                                            checked={formik.values.maxW}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Max. (W)"
                                        />
                                        <Switch
                                            name="maxS"
                                            color="mpPurple"
                                            checked={formik.values.maxS}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Max. (S)"
                                        />
                                        <Switch
                                            name="maxStartW"
                                            color="mpLBlue"
                                            checked={formik.values.maxStartW}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Max. Start (W)"
                                        />
                                        <Switch
                                            name="maxStartS"
                                            color="mpLBlue"
                                            checked={formik.values.maxStartS}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Max. Start (S)"
                                        />

                                        <br/>

                                        <h4>Appointments:</h4>
                                        <Switch
                                            name="scheduledW"
                                            color="mpPurple"
                                            checked={formik.values.scheduledW}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Scheduled (W)"
                                        />
                                        <Switch
                                            name="scheduledS"
                                            color="mpPurple"
                                            checked={formik.values.scheduledS}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Scheduled (S)"
                                        />
                                        <Switch
                                            name="startingW"
                                            color="mpLBlue"
                                            checked={formik.values.startingW}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Starting (W)"
                                        />
                                        <Switch
                                            name="startingS"
                                            color="mpLBlue"
                                            checked={formik.values.startingS}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Starting (S)"
                                        />
                                        <Switch
                                            name="leavingW"
                                            color="mpOrange"
                                            checked={formik.values.leavingW}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Leaving (W)"
                                        />
                                        <Switch
                                            name="leavingS"
                                            color="mpOrange"
                                            checked={formik.values.leavingS}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleSwitchesTotal(formik, 'single', e);
                                            }}
                                            textPosition="after"
                                            label="Leaving (S)"
                                        />
                                    </div>
                                </div>
                            </> : null
                        }
                    </>
                )}
            </Formik>
        </>
    );
}

export default connect(null, {

})(OverviewAppointments);