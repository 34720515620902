import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from "../../../../components/custom-essentials";
import { BrowserTabTitle, LoadingOverlay } from "../../../../components/display";
import { DateRangeSelector, SelectSingle, FormikControl, checkResponses } from '../../../../components/form';
import { formatDateApi } from '../../../../components/functions';
import { HoldsLogModal } from '../../../../components/modal';
import { TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import HoldsTable from './HoldsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAdminUsersAll,
    fetchHoldsLogsDaterangeCenter
} from '../../../../actions';

const pageTitle = 'Holds Logs';

const start = new Date();
start.setMonth(start.getMonth() - 3);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'student name', label: 'Student Name' },
    { value: 'instructor name', label: 'Instructor Name' },
    { value: 'notes', label: 'Notes' },
];

function HoldsLogs(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [drsValid, setDrsValid] = useState(true);
    const [apiError, setApiError] = useState(false);
    // Static data
    const [centers, setCenters] = useState([]);
    const [students, setStudents] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    // Data
    const [holdsLogs, setHoldsLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    // Modal
    const [modalMode, setModalMode] = useState(null);

    const { fetchMpCentersAll, fetchStudentsAll, fetchAdminUsersAll, fetchHoldsLogsDaterangeCenter } = props;

    const filterLogs = useCallback((unfilteredLogs, filterType, filterQuery) => {
        filterQuery = filterQuery.replace(/ /g, '').toLowerCase();
        const checkStudentNames = (hl) => hl.studentName.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkInstructorNames = (hl) => hl.updatedByName.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkNotes = (hl) => hl.notes.toLowerCase().includes(filterQuery);

        const newLogs = unfilteredLogs.filter(hl => {
            switch(filterType.value){
                case 'all':
                    return checkStudentNames(hl) || checkInstructorNames(hl) || checkNotes(hl);
                case 'student name':
                    return checkStudentNames(hl);
                case 'instructor name':
                    return checkInstructorNames(hl);
                case 'notes':
                    return checkNotes(hl);
                default:
                    return false;
            }
        });

        if(mounted.current) setFilteredLogs(newLogs);
    }, [mounted]);
    const refreshData = useCallback((newCenters = centers, newStudents = students, newEmployees = employees) => {
        (async function refresh(){
            if(loading || !drsValid || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
            
            const { startDate, endDate, selectedCenter, filterType, filterQuery } = formRef.current.values;
            
            const holdsLogsRes = await fetchHoldsLogsDaterangeCenter({
                startDate: startDate,
                endDate: endDate,
                center: selectedCenter.value,
            });
            const isApiError = checkResponses(holdsLogsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newHoldsLogs = holdsLogsRes.data || [];
    
            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const centerMap = {};
            newCenters.forEach(c => centerMap[parseInt(c.id)] = c.name);
            const employeeMap = {};
            newEmployees.forEach(e => employeeMap[e.id] = `${e.first_name} ${e.last_name}`);
    
            const holdsLogsAppended = newHoldsLogs.map(hl => {
                hl.studentName = studentMap[hl.student] || `Unknown student (UID: ${hl.student})`;
                hl.centerName = centerMap[parseInt(hl.center)] || `Unknown center (ID: ${hl.center})`;
                hl.updatedByName = employeeMap[hl.updated_by] || `Unknown employee (ID: ${hl.updated_by})`;
                return hl;
            });
            
            if(mounted.current){
                setHoldsLogs(holdsLogsAppended);
                filterLogs(newHoldsLogs, filterType, filterQuery);
                setLoading(false);
            }
        })();
    }, [loading, mounted, drsValid, students, centers, employees, filterLogs, fetchHoldsLogsDaterangeCenter]);
    useEffect(() => {
        const init = async () => {
            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const employeesRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(centersRes, studentsRes, employeesRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newEmployees = employeesRes.data || [];
    
            const centerOptions = [ { value: 'all', label: 'All' },
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name }))];
            
            if(mounted.current){
                setCenters(newCenters);
                setStudents(newStudents);
                setEmployees(newEmployees);
                setCenterOptions(centerOptions);
                refreshData(newCenters, newStudents, newEmployees);
    
                setHasLoaded(true);
            } 
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleShowModal(){
        setModalMode('create');
    }
    function onSubmitCallback(changes){
        setModalMode(null);
        if(changes) refreshData();
    }

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {modalMode && 
                <HoldsLogModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {(loading || !hasLoaded) &&
                <LoadingOverlay/>
            }
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        filterType: { value: 'all', label: 'All' },
                        filterQuery: '',
                    }}
                    innerRef={formRef}
                    onSubmit={() => refreshData()}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <h2>Search for Holds Logs Entries</h2>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <DateRangeSelector
                                        id="holds-drs-1"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="holds-selectedCenter"
                                        name="selectedCenter"
                                        label="Center"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                </div>
                                <div>
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                            
                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                                <>
                                    <hr/>
                                    <br/>

                                    <h2>Filter Results ({filteredLogs.length})</h2>

                                    <br/>

                                    <div className="flex flex-row gap-x-2 items-center">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <SelectSingle
                                                id="holds-searchType"
                                                name="filterType"
                                                label="Filter by"
                                                value={formik.values.filterType}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    filterLogs(holdsLogs, e.target.value, formik.values.filterQuery);
                                                }}
                                                options={filterTypeOptions}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                            <FormikControl
                                                id="flag-manager-searchQuery"
                                                name="filterQuery"
                                                placeholder="Enter a filter query..."
                                                value={formik.values.filterQuery}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    filterLogs(holdsLogs, formik.values.filterType, e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => handleShowModal('create')}
                                                style={{ alignSelf: "flex-end" }}
                                            >
                                                + Add Log Entry
                                            </Button>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 ml-auto">
                                            <TooltipWrapper
                                                tooltipText={
                                                    <div>
                                                        <div>
                                                            What gets exported?
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            All holds logs that are currently filtered ({filteredLogs.length} items).
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <CSVExport
                                                    title="Holds_Logs"
                                                    label="Export Logs to CSV"
                                                    data={filteredLogs}
                                                />
                                            </TooltipWrapper>
                                        </div>
                                    </div>
                                </>
                            }
                            
                        </form>
                    )}
                </Formik>

                <br/>
                {apiError ? null : hasLoaded &&
                    <HoldsTable
                        holdsLogs={filteredLogs}
                        refreshData={refreshData}
                    />
                }
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMpCentersAll,
    fetchStudentsAll,
    fetchAdminUsersAll,
    fetchHoldsLogsDaterangeCenter
})(HoldsLogs);