import React from 'react';

export function TabPanel(props){
    const { children, activeKey, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={activeKey !== index}
            className="px-3 py-6"
        >
            {children}
        </div>
    );
}