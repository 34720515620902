import { Button, SVGIcon } from '../../custom-essentials';
import { DatePicker } from '../../form';
import { TooltipWrapper } from '../../display';
import { formatDateApi, convertApiToDate, checkLessonDatesValid } from '../../functions';

export function modifyLessonList(formik, learningPlanItem, mode){
    if(!learningPlanItem) return;
    const lpItems = [ ...formik.values.learningPlanItems ];

    let newlpiUniqueId = 0;
    for(let lpi of lpItems) if(lpi.uniqueId >= newlpiUniqueId) newlpiUniqueId = lpi.uniqueId + 1;
    if(mode === 'add'){
        lpItems.push({
            id: parseInt(learningPlanItem.id),
            uniqueId: newlpiUniqueId,
            dateAssigned: '',
            dateCompleted: '',
            lessonName: `${learningPlanItem.lesson_id}: ${learningPlanItem.name}`,
            sortOrder: lpItems.length + 1
        });
    } else {
        const currentIndex = lpItems.findIndex(i => parseInt(i.uniqueId) === parseInt(learningPlanItem.uniqueId));
        if(mode === 'shiftUp'){
            if(currentIndex === 0) return;
    
            lpItems[currentIndex].sortOrder--;
            lpItems[currentIndex - 1].sortOrder++;
        } else if(mode === 'shiftDown'){
            if(currentIndex >= lpItems.length - 1) return;
    
            lpItems[currentIndex].sortOrder++;
            lpItems[currentIndex + 1].sortOrder--;
        } else if(mode === 'delete'){
            lpItems.splice(currentIndex, 1);
        }
    }


    lpItems.sort((a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder));
    formik.setFieldValue('learningPlanItems', lpItems);
}

export function renderLpi(lpi, formik, lessonsToRender){
    const assignedDate = lpi.dateAssigned ? convertApiToDate(lpi.dateAssigned) : '';
    const completedDate = lpi.dateCompleted ? convertApiToDate(lpi.dateCompleted) : '';
    const shouldWarn = !(checkLessonDatesValid(assignedDate, completedDate));
    const datesWarning = shouldWarn ?
        <div className="text-mpLRed">
            Date completed cannot be before date assigned.
        </div>
        : null;
    const stagedToRemove = parseInt(formik.values.stagedToRemoveIndex) === parseInt(lpi.sortOrder);
    const formikIndexToUpdate = formik.values.learningPlanItems.findIndex(i => parseInt(i.uniqueId) === parseInt(lpi.uniqueId));
    return (
        <div key={`learning-plans-lesson-id-${lpi.uniqueId}`}>
            <div className="flex flex-row gap-x-4">
                <div className="grid grid-cols-1 gap-y-2 w-full">
                    <h4><u>{lpi.lessonName}</u></h4>
                </div>
            </div>
            <div className="flex flex-row gap-x-4">
                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                    <div className="flex flex-row gap-x-4">
                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                            <h5>Date Assigned</h5>
                            <div className="flex flex-row gap-x-4">
                                <DatePicker
                                    id="learning-plans-date-assigned"
                                    name="dateAssigned"
                                    value={lpi.dateAssigned}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        newValues.learningPlanItems[formikIndexToUpdate].dateAssigned = e.target.value;
                                        formik.setValues(newValues);
                                    }}
                                />
                                <div className="ml-4">
                                    <TooltipWrapper
                                        tooltipText="Use today's date"
                                    >
                                        <Button
                                            color="lte-mpLBlue"
                                            variant="icon"
                                            // Disable any non-blank to avoid accidental modification
                                            disabled={lpi.dateAssigned !== ''}
                                            onClick={() => {
                                                const newDate = formatDateApi(new Date());
                                                const newValues = { ...formik.values };
                                                newValues.learningPlanItems[formikIndexToUpdate].dateAssigned = newDate;
                                                formik.setValues(newValues);
                                            }}
                                        >
                                            <SVGIcon fileName="calendar"/>
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                                <TooltipWrapper
                                    tooltipText="Remove date"
                                >
                                    <Button
                                        color="lte-mpLRed"
                                        variant="icon"
                                        onClick={() => {
                                            const newDate = '';
                                            const newValues = { ...formik.values };
                                            newValues.learningPlanItems[formikIndexToUpdate].dateAssigned = newDate;
                                            formik.setValues(newValues);
                                        }}
                                    >
                                        <SVGIcon fileName="eraser"/>
                                    </Button>
                                </TooltipWrapper>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                            <h5>Date Completed</h5>
                            <div className="flex flex-row gap-x-4">
                                <DatePicker
                                    id="learning-plans-date-completed"
                                    name="dateCompleted"
                                    value={lpi.dateCompleted}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        newValues.learningPlanItems[formikIndexToUpdate].dateCompleted = e.target.value;
                                        formik.setValues(newValues);
                                    }}
                                    />
                                <div className="ml-4">
                                    <TooltipWrapper
                                        tooltipText="Use today's date"
                                    >
                                        <Button
                                            color="lte-mpLBlue"
                                            variant="icon"
                                            // Disable any non-blank to avoid accidental modification
                                            disabled={lpi.dateCompleted !== ''}
                                            onClick={() => {
                                                const newDate = formatDateApi(new Date());
                                                const newValues = { ...formik.values };
                                                newValues.learningPlanItems[formikIndexToUpdate].dateCompleted = newDate;
                                                formik.setValues(newValues);
                                            }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            <SVGIcon fileName="calendar"/>
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                                <TooltipWrapper
                                    tooltipText="Remove date"
                                >
                                    <Button
                                        color="lte-mpLRed"
                                        variant="icon"
                                        onClick={() => {
                                            const newDate = '';
                                            const newValues = { ...formik.values };
                                            newValues.learningPlanItems[formikIndexToUpdate].dateCompleted = newDate;
                                            formik.setValues(newValues);
                                        }}
                                    >
                                        <SVGIcon fileName="eraser"/>
                                    </Button>
                                </TooltipWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-x-4 w-1/4 ml-4">
                    <div className="flex flex-row gap-x-4">
                        <div>
                            <div className="mb-1">
                                <TooltipWrapper
                                    tooltipText="Shift lesson up"
                                >
                                    <Button
                                        color="lte-mpYellow"
                                        variant="icon"
                                        disabled={formikIndexToUpdate === 0}
                                        onClick={() => modifyLessonList(formik, lpi, 'shiftUp')}
                                    >
                                        <SVGIcon fileName="arrow-up"/>
                                    </Button>
                                </TooltipWrapper>
                            </div>
                            <TooltipWrapper
                                tooltipText="Shift lesson down"
                            >
                                <Button
                                    color="lte-mpYellow"
                                    variant="icon"
                                    disabled={formikIndexToUpdate >= lessonsToRender.length - 1}
                                    position="bottom"
                                    onClick={() => modifyLessonList(formik, lpi, 'shiftDown')}
                                >
                                    <SVGIcon fileName="arrow-down"/>
                                </Button>
                            </TooltipWrapper>
                        </div>

                        <div className="flex items-center ml-1">
                            <div className="flex flex-row gap-x-4">
                                { stagedToRemove ? 
                                    <>
                                        <TooltipWrapper
                                            tooltipText="Cancel remove"
                                        >
                                            <Button
                                                color="lte-mpLBlue"
                                                variant="icon"
                                                onClick={() => formik.setFieldValue('stagedToRemoveIndex', -1)}
                                            >
                                                <SVGIcon fileName="undo"/>
                                            </Button>
                                        </TooltipWrapper>
                                        <div className="ml-1">
                                            <TooltipWrapper
                                                tooltipText="Confirm remove"
                                            >
                                                <Button
                                                    color="lte-mpLRed"
                                                    variant="icon"
                                                    onClick={() => {
                                                        modifyLessonList(formik, lpi, 'delete');
                                                        formik.setFieldValue('stagedToRemoveIndex', -1);
                                                    }}
                                                >
                                                    <SVGIcon fileName="trash"/>
                                                </Button>
                                            </TooltipWrapper>
                                        </div>
                                    </>
                                    : 
                                    <TooltipWrapper
                                        tooltipText="Remove lesson"
                                    >
                                        <Button
                                            color="lte-mpLRed"
                                            variant="icon"
                                            onClick={() => formik.setFieldValue('stagedToRemoveIndex', lpi.sortOrder)}
                                        >
                                            <SVGIcon fileName="trash"/>
                                        </Button>
                                    </TooltipWrapper>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-4">
                {datesWarning}
            </div>
            <br/>
        </div>
    )
}