import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from '@mui/material';

import { TabPanel } from '../../../../../components/custom-essentials';
import { getColumnsNotes, getColumnsFlags, getColumnsAppointments } from '../table/getColumnsRelevantInfo';
import { Table } from '../../../../../components/table';
import { formatDateFull } from '../../../../../components/functions';

const defaultSorted=[{ dataField: 'start', order: 'asc' }];
const sizePerPageList = [10];
const sizePerPageInitial = 10;

function RITabContent(props){
    const { relInfo } = props;

    const [activeTabKey, setActiveTabKey] = useState(0)

    const columns = useMemo(() => {
        return {
            notes: getColumnsNotes(),
            appointments: getColumnsAppointments(),
            flags: getColumnsFlags(),
        };
    }, []);

    const { combinedNotes, appointments, flags, upcomingExams, student } = relInfo;
    return (
        <>
            <Tabs
                id="relevantInfo"
                value={activeTabKey}
                onChange={(e, newValue) => setActiveTabKey(newValue)}
            >
                <Tab value={`Notes (${combinedNotes?.length})`}/>
            </Tabs>
            <TabPanel activeKey={activeTabKey} index={0}>
                <Table
                    tableName="assignedAppointments-relevantInfo"
                    sizePerPageList={sizePerPageList}
                    sizePerPageInitial={sizePerPageInitial}
                    data={combinedNotes}
                    columns={columns.notes}
                    defaultSorted={defaultSorted}
                    emptyMessage="No notes were found"
                />
            </TabPanel>
            <TabPanel activeKey={activeTabKey} index={1}>
                <Table
                    sizePerPageList={sizePerPageList}
                    sizePerPageInitial={sizePerPageInitial}
                    data={appointments}
                    columns={columns.appointments}
                    defaultSorted={defaultSorted}
                    emptyMessage="No appointments were found"
                />
            </TabPanel>
            <TabPanel activeKey={activeTabKey} index={2}>
                <Table
                    sizePerPageList={sizePerPageList}
                    sizePerPageInitial={sizePerPageInitial}
                    data={flags}
                    columns={columns.flags}
                    defaultSorted={defaultSorted}
                    emptyMessage="No flags were found"
                />
            </TabPanel>
            <TabPanel activeKey={activeTabKey} index={3}>
                <h4>Upcoming Exams Info</h4>
                <div><b>Has Exams? </b> {parseInt(upcomingExams.has_exams) === 1 ? 'Yes' : 'No'}</div>
                <div>
                    <b>Notes: </b>
                    <span className={upcomingExams.notes ? '' : 'text-mpOrange'}>
                        {upcomingExams.notes || 'None'}
                    </span>
                </div>
                <div><b>Last Updated: </b> {formatDateFull(upcomingExams.date_updated)} by {upcomingExams.updatedByName}</div>
            </TabPanel>
            <TabPanel activeKey={activeTabKey} index={4}>
                <h4>Student Info</h4>
                <div><b>Name: </b> {student.first_name} {student.last_name}</div>
                <div><b>Primary Center: </b> {student.primaryCenterName}</div>
                <div><b>Seating Weight: </b> {student.mp_weight}</div>
                <div><b>Grade: </b> {student.grade}</div>
                <div><b>School: </b>
                    <span className={student.school ? '' : 'text-mpOrange'}>{student.school || 'Not Listed'}</span>
                </div>
                <div><b>Teacher: </b>
                    <span className={student.mp_teacher ? '' : 'text-mpOrange'}>{student.mp_teacher || 'Not Listed'}</span>
                </div>
                <div><b>This Month Start: </b> {formatDateFull(student.mp_this_month_start)}</div>
                <div><b>Next Month Start: </b> {formatDateFull(student.mp_next_month_start)}</div>
            </TabPanel>
        </>
    );
}

export default connect(null, {

})(RITabContent);