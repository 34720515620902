import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import { Formik } from 'formik';

import { Switch } from '../../../form';
import { parseSchedulingNotes } from '../../../../app/pages/appointments/scheduling/overview/instructors/helpers';
import { getSeriesOptions } from './getSeries';

function OverviewInstructors(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { show, formik, chartMaps } = props;
    
    const [weightMode, setWeightMode] = useState(false);
    const [assignmentMap, setAssignmentMap] = useState(false);

    useEffect(() => {
        const { appointments } = formik.values;

        const {
            userToNameMap = {},
            instructorNameToIdMap = {}
        } = chartMaps;
        // Track instructors' assignment saturation at each time block. Ignore missed/cancelled
        const newAssignmentMap = {};
        let colorIndex = 0;
        const chartAppointments = [];
        Object.values(appointments).forEach(appointmentGroup => {
            Object.values(appointmentGroup).forEach(appointment => {
                chartAppointments.push(appointment)
            });
        });

        chartAppointments.forEach(a => {
            if(['Cancelled', 'Missed'].includes(a.status)) return;
            const assignments = a.assignments || [];

            const appointmentGroups = parseSchedulingNotes(a, assignments, instructorNameToIdMap);
            appointmentGroups.forEach(g => {
                const instructorName = userToNameMap[g.instructor] || `Unable to find user (ID ${g.instructor})`;
                const instructorId = g.instructor;
                if(!newAssignmentMap[instructorId]){
                    newAssignmentMap[instructorId] = {
                        id: instructorId,
                        name: instructorName,
                        colorIndex: colorIndex++
                    };
                }
                for(let i = parseInt(g.start); i < parseInt(g.end); i += 30){
                    const seatsTimeKey = `s-${i}`;
                    if(!newAssignmentMap[instructorId][seatsTimeKey]) newAssignmentMap[instructorId][seatsTimeKey] = 1;
                    else newAssignmentMap[instructorId][seatsTimeKey] += 1;

                    const weightsTimeKey = `w-${i}`;
                    const weightInt = parseInt(a.weight);
                    if(!newAssignmentMap[instructorId][weightsTimeKey]) newAssignmentMap[instructorId][weightsTimeKey] = weightInt;
                    else newAssignmentMap[instructorId][weightsTimeKey] += weightInt;
                }
            });
        });

        if(mounted.current){
            setAssignmentMap(newAssignmentMap);
        }
    }, [formik, chartMaps])
    
    const chartOptions = useMemo(() => {
        return getSeriesOptions(assignmentMap, weightMode);
    }, [assignmentMap, weightMode]);
    
    const handleWeightMode = useCallback((event) => {
        const trueValue = [true, 'true'].includes(event.target.value);
        if(mounted.current){
            setWeightMode(trueValue);
        }
    }, [mounted]);

    if(!show) return null;

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    weightMode: false
                }}
            >
                
                {formik => (
                    <>
                        {show ? 
                            <div className="grid grid-cols-1 w-full">
                                <Chart series={chartOptions.series} options={chartOptions.options} type="line"/>
                                <div className="flex flex-row gap-x-2">
                                    <h4 className="mr-4">Seats/Weights:</h4>
                                    <div className="mt-0 mb-2">
                                        <Switch
                                            name="weightMode"
                                            color="mpDGrey"
                                            checked={formik.values.weightMode}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleWeightMode(e);
                                            }}
                                            textPosition="after"
                                            label={formik.values.weightMode ? 'Weights' : 'Seats'}
                                        />
                                    </div>
                                </div>
                            </div> : null
                        }
                    </>
                )}
            </Formik>
        </>
    );
}

export default connect(null, {

})(OverviewInstructors);