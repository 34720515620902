import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, EllipsisLoader } from '../../../../components/custom-essentials';
import { FormikControl, SelectSingle, checkResponse } from '../../../../components/form';
import { TooltipWrapper } from '../../../../components/display';
import { formatDateTime } from '../../../../components/functions';

import {
    fetchStudentsUserIds,
    createAppointmentsBulk
} from '../../../../actions';

function CreateAppointments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [firstConfirm, setFirstConfirm] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const { hasLoaded, weekOptions, autoScheduleItems, fetchStudentsUserIds, createAppointmentsBulk } = props;

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            setStatus('');
            if(mounted.current){
                setSubmitting(true);
            }

            const studentUserIds = [];
            const filteredAsi = autoScheduleItems.filter(asi => parseInt(asi.active) === 1);
            filteredAsi.forEach(asi => studentUserIds.push(asi.student));

            const studentsRes = await fetchStudentsUserIds({ userIds: studentUserIds });
            const newStudents = studentsRes.data || [];

            const responseValid = checkResponse(studentsRes, mounted, setStatus);
            if(!responseValid){
                if(mounted.current){
                    setStatus('Error fetching data from the server. Please try again later.');
                    setSubmitting(false);
                }
                return;
            }
            
            const studentObjMap = {};
            newStudents.forEach(s => studentObjMap[s.user_id] = s);

            filteredAsi.forEach(asi =>{
                const studentObj = studentObjMap[asi.student] || {};
                asi.parent = studentObj.parent;
                asi.specialNotes = studentObj.mp_special_notes;
                asi.specialNotes2 = studentObj.mp_special_notes_2;
            });

            const appointmentsToCreate = [];
            // appointmentBase contains some preset values for each selected day
            // Pushed appointments are a copy of this object
            for(let asi of filteredAsi){
                const { dow, time } = asi;

                const appointmentDate = new Date(`${values.selectedWeek.value} 00:00:00`);
                appointmentDate.setDate(appointmentDate.getDate() + parseInt(dow));
                
                const hours = Math.floor(parseInt(time) / 60);
                const minutes = parseInt(time) % 60;
                appointmentDate.setHours(hours, minutes, 0, 0);

                const appointmentBase = {
                    dateTime: appointmentDate,
                    parent: asi.parent,
                    student: asi.student,
                    weight: asi.weight,
                    center: asi.center,
                    duration: asi.duration,
                    specialNotes: asi.specialNotes,
                    specialNotes2: asi.specialNotes2,
                    status: 'Scheduled',
                };

                appointmentsToCreate.push(appointmentBase);
            }

            const response = await createAppointmentsBulk({ appointments: appointmentsToCreate });
            const responseValid2 = checkResponse(response, mounted, setStatus);
            if(!responseValid2 && mounted.current){
                setSubmitting(false);
                return;
            }

            if(mounted.current){
                setSubmitting(false);
                setSuccessMessage(`Appointments created! (${formatDateTime(new Date())})`);
                setFirstConfirm(false);
            }
        }
        submit();
    }, [autoScheduleItems, createAppointmentsBulk, fetchStudentsUserIds]);

    return (
        <div>
            {hasLoaded && 
                <Formik
                    enableReinitialize={!hasLoaded}
                    initialValues={{
                        selectedWeek: { value: -1, label: 'Please select...' },
                        confirmText: ''
                    }}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <>
                            <br/>

                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <TooltipWrapper
                                        tooltipText="Only the appointments that are visible on the table will be created. Inactive appointments will be ignored, regardless of whether they show up on the table or not."
                                    >
                                        <h4 className="text-mpLBlue">Week to Schedule</h4>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id="autoSchedule-createAppointments-weekOptions"
                                        name="selectedWeek"
                                        value={formik.values.selectedWeek}
                                        options={weekOptions}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                {firstConfirm ?
                                    <>
                                        <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                            <h4>Confirm Submit</h4>
                                            <div className="text-mpOrange">
                                                Warning! Appointments will be created once "Add Appointments" is clicked.
                                            </div>
                                            <div>
                                                Type the selected week's starting date to continue (yyyy-mm-dd).
                                            </div>
                                            <div className="h-2 clear-both"/>
                                            <div className="flex flex-row gap-x-2">
                                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                                    <div className="flex items-end">
                                                        <FormikControl
                                                            id="autoSchedule-createAppointments-weekOptions"
                                                            name="confirmText"
                                                            placeholder={formik.values.selectedWeek.value}
                                                            value={formik.values.confirmText}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="items-end">
                                                    <Button
                                                        color="hol-mpLRed"
                                                        onClick={() => setFirstConfirm(false)}
                                                    >
                                                        Back
                                                    </Button>
                                                </div>
                                                <div className="items-end">
                                                    <Button
                                                        color="lte-mpLBlue"
                                                        disabled={(formik.values.confirmText !== formik.values.selectedWeek.value) ||
                                                        (!autoScheduleItems.length)}
                                                        onClick={async (values, actions) => {
                                                            await formik.handleSubmit(values, actions);
                                                            formik.setFieldValue('confirmText', '');
                                                        }}
                                                    >
                                                        Add Appointments
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className="flex items-end">
                                            <Button
                                                color="lte-mpLBlue"
                                                disabled={formik.values.selectedWeek.value === -1}
                                                onClick={() => {
                                                    setFirstConfirm(true);
                                                    setSuccessMessage('');
                                                }}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </>
                                }
                                {formik.isSubmitting ?
                                    <div className="flex items-center">
                                        <span className="mr-8"><EllipsisLoader/></span>
                                        Creating Appointments...
                                    </div> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <>
                                        <br/>
                                        <div className="text-mpLRed d-flex align-items-center" style={{ marginLeft: "2rem" }}>
                                            {formik.status}. No appointments were created.
                                        </div>
                                    </> : null
                                }
                                {successMessage && !formik.isSubmitting ? 
                                    <>
                                        <br/>
                                        <div className="text-mpGreen d-flex align-items-center" style={{ marginLeft: "2rem" }}>
                                            {successMessage}
                                        </div>
                                    </> : null
                                }
                            </div>
                        </>
                    )}
                </Formik>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchStudentsUserIds,
    createAppointmentsBulk
})(CreateAppointments);