import { basicPath } from '../basicPath';

export const fetchPaymentsContractIds = (params) => async (dispatch) => {
    const path = '/mp/payments/contract-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchPaymentsDaterangeCenter = (params) => async (dispatch) => {
    const path = '/mp/payments/daterange-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchPaymentsIds = (params) => async (dispatch) => {
    const path = '/mp/payments/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createPayment = (params) => async (dispatch) => {
    const path = '/mp/payments';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updatePayment = (params) => async (dispatch) => {
    const path = '/mp/payments';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deletePayment = (params) => async (dispatch) => {
    const path = '/mp/payments';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}