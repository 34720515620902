import React from 'react';
import * as Yup from 'yup';

import { formatDateFull } from '../../functions';
import { TooltipWrapper, renderPhone } from '../../display';

export const validationSchema = Yup.object().shape({
    date: Yup.mixed().test(
        'dateIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
    time: Yup.mixed().test(
        'timeIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
    ).test(
        'isHalfHour',
        'Minutes must be either 00 or 30',
        (value) => value.raw % 30 === 0
    ),
    student: Yup.mixed().test(
        'studentIsSelected',
        'Student is required',
        (value) => value.value !== -1
    ),
    center: Yup.mixed().test(
        'centerIsSelected',
        'Center is required',
        (value) => value.value !== -1
    ),
    duration: Yup.mixed().test(
        'durationIsSelected',
        'Duration is required',
        (value) => value.value !== -1
    ),
    weight: Yup.mixed().test(
        'weightIsSelected',
        'Weight is required',
        (value) => value.value !== -1
    ),
    status: Yup.mixed().test(
        'statusIsSelected',
        'Status is required',
        (value) => value.value !== -1
    ),
    specialNotes: Yup.string()
        .max(300, 'Max 300 characters'),
    specialNotes2: Yup.string()
        .max(300, 'Max 300 characters'),
});

// Header for the 'Student' field //
export function getStudentHeader(student, parents){
    if(!student || typeof(student) !== 'object') return <h5>Student</h5>
    const parent = parents.find(p => p.id === student.parent) || {};

    const firstGuardianContent = student.first_guardian_notes ? (
        <>
            <br/>
            <u>Additional Contacts</u>
            <div>First Guardian Notes: {student.first_guardian_notes}</div>
        </>
    ) : null;
    const secondGuardianContent = student.second_guardian_notes ? (
        <>
            <br/>
            <div>Second Guardian Notes: {student.second_guardian_notes}</div>
        </>
    ) : null;
    return (
        <TooltipWrapper
            placement="bottom"
            tooltipText={
                <>
                    <u>School Info</u>
                    <div>School: {student.school || 'Not Specified'}</div>
                    <div>Teacher: {student.mp_teacher || 'Not Specified'}</div>
                    <div>Grade: {student.grade || 'Not Specified'}</div>

                    <br/>

                    <u>Account Info</u>
                    <div>Weight: {student.mp_weight}</div>
                    <div>Next Month Begins:</div>
                    <div>{formatDateFull(student.mp_next_month_start)}</div>
                    <div>Hours This Month:&nbsp;
                        {(student.mp_time_left_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                    </div>
                    <div>Hours Next Month:&nbsp;
                        {(student.mp_time_left_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                    </div>
                    <div>Reserve Hours Left:&nbsp;
                        {(student.mp_reserve_time_left / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                    </div>

                    <br/>

                    <u>Student Contact:</u>
                    <div>Phone: {renderPhone(student.phone)}</div>
                    <div>Email: {student.email || 'None'}</div>

                    <br/>

                    <u>Parent Contact:</u>
                    <div>Phone: {renderPhone(parent.phone)}</div>
                    <div>Email: {parent.email || 'None'}</div>

                    {firstGuardianContent}
                    {secondGuardianContent}
                </>
            }
        >
            <h4 className="text-mpLBlue">Student</h4>
        </TooltipWrapper>
    );
}

export function getInitStudentOption(student, studentOptions){
    if(!student) return { value: -1, label: 'Please select...' };
    return studentOptions.find(s => s.value === student) || { value: -1, label: `Unknown student (${student})` };
}

export function getInitCenterOption(center, centerOptions){
    if(!center || center === 'all') return { value: -1, label: 'Please select...' };
    return centerOptions.find(s => s.value === center) || { value: -1, label: `Unknown center (${center})` };
}


export const durationOptions = [30, 60, 90, 120].map(o => ({ value: o, label: `${o} minutes` }));
export function getInitDurationOption(duration){
    if(!duration) return durationOptions[1];
    return durationOptions.find(o => o.value === duration) || { value: -1, label: `Unknown duration (${duration})` };
}

export const weightOptions = [1, 2, 3, 4].map(i => ({ value: i, label: i }));
export function getInitWeightOption(weight){
    if(!weight) return { value: -1, label: 'Please select...' };
    return weightOptions.find(o => parseInt(o.value) === parseInt(weight)) || { value: -1, label: `Unknown weight (${weight})` };
}

export const statusOptions = ['Scheduled', 'In Progress', 'Completed', 'Missed', 'Cancelled', 'Not Charged']
    .map(i => ({ value: i, label: i }));
export function getInitStatusOption(status){
    if(!status) return statusOptions[0];
    return statusOptions.find(o => o.value === status) || { value: -1, label: `Unknown status (${status})` };
}