import app from '../api/app';
import { attemptAction } from './index';

export const basicPath = async(dispatch, params, path, type) => {
    async function action(params){
        let response = null;
        try {
            response = await app[type](path, {
                params
            });
        } catch (e){
            response = e.response;
            console.error(e);
        } finally {
            if(response){
                return response;
            } else {
                return {};
            }
        }
    };
    return await attemptAction(action, dispatch, params);
}