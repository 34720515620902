import { basicPath } from '../basicPath';

export const fetchMpCentersAll = (params) => async (dispatch) => {
    const path = '/mp-centers/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}
export const fetchRpCentersAll = (params) => async (dispatch) => {
    const path = '/rp-centers/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}