import { basicPath } from '../basicPath';

export const fetchAvailabilityDateCenter = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/date-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAvailabilityDaterange = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/daterange-only';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAvailabilityDaterangeCenter = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/daterange-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createAvailability = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/single';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const createAvailabilityBulk = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/bulk';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAvailability = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAvailability = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/single';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAvailabilityBulk = (params) => async (dispatch) => {
    const path = '/mp/scheduler-availability/bulk';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}