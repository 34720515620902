import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { AutoScheduleItemsModal } from '../../../../components/modal';
import { getColumns } from './table/getColumns';

const defaultSorted = { dataField: 'dayOfWeek', order: 'desc' };

function AutoScheduleItemsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [modalMode, setModalMode] = useState(null);
    const [selectedAutoScheduleItem, setSelectedAutoScheduleItem] = useState(null);

    const { hasLoaded, autoScheduleItems, refreshData } = props;

    const handleShowModal = useCallback((mode, autoScheduleItem) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedAutoScheduleItem(autoScheduleItem);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedAutoScheduleItem(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumns(handleShowModal), [handleShowModal]);

    return (
        <div>
            {modalMode &&
                <AutoScheduleItemsModal
                    mode={modalMode}
                    selectedAutoScheduleItem={selectedAutoScheduleItem}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {hasLoaded && 
                <Table
                    tableName="autoScheduleItems"
                    data={autoScheduleItems}
                    columns={columns}
                    sizePerPageInitial={10}
                    defaultSorted={defaultSorted}
                    emptyMessage="No auto schedule items were found"
                />
            }
        </div>
    );
}

export default connect(null, {

})(AutoScheduleItemsTable);