import React from 'react';
import * as Yup from 'yup';
import { formatDate } from '../../functions';

export const validationSchema = Yup.object().shape({
    student: Yup.mixed().test(
        'studentIsSeleceted',
        'Select a valid student',
        (value) => value?.value !== -1
    ),
    tag: Yup.mixed().test(
        'tagIsSelected',
        'Select a valid tag',
        (value) => value?.value !== -1
    ),
    center: Yup.mixed().test(
        'centerIsSelected',
        'Select a valid center',
        (value) => value?.value !== -1
    ),
    additionalNotes: Yup.string()
        .max(200, 'Max 200 characters'),
    adminNotes: Yup.string()
        .max(200, 'Max 200 characters'),
    status: Yup.mixed().test(
        'statusIsSelected',
        'Select a valid status',
        (value) => value?.value !== -1
    ),
});

export const tagOptions = ['No Tag', 'Refill Lessons', 'Assign EP', 'Assessment'].map(i => ({ value: i, label: i }));
export function getInitTagOption(tag){
    if(!tag) return tagOptions[1];
    return tagOptions.find(t => t.value === tag) || { value: -1, label: `Invalid tag (${tag})` };
}

export function getInitStudentOption(studentId, studentOptions){
    if(!studentId) return { value: -1, label: 'Please select a student...' };
    return studentOptions.find(s => s.value === studentId) || { value: -1, label: `Unknown student (UID ${studentId})`};
}

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'Please select...' };
    return centerOptions.find(c => parseInt(c.value) === parseInt(center)) || { value: -1, label: `Unknown center (ID ${center})` };
}
export function getCenterName(center, centerOptions){
    const relCenter = centerOptions.find(c => parseInt(c.value) === parseInt(center));
    if(!relCenter) return `Unknown center (${center})`;
    else return relCenter.label;
}

export const statusOptions = ['Pending', 'Completed'].map(i => ({ value: i, label: i }));
export function getInitStatusOption(status){
    if(!status) return statusOptions[0];
    return statusOptions.find(s => s.value === status) || { value: -1, label: `Invalid status (${status})` };
}

export function renderMeta(flag){
    let created = flag.date_created ? (
        <div>Created on {formatDate(flag.date_created)} by {flag.createdByName || `Unknown user (${flag.created_by})`}</div>
    ) : null;
    let updated = flag.date_updated ? (
        <div>Updated on {formatDate(flag.date_updated)} by {flag.updatedByName || `Unknown user (${flag.updated_by})`}</div>
    ) : null;

    if(created || updated){
        return(
            <div className="grid grid-cols-1">
                <br/>
                {created}
                {updated}
            </div>
        )
    } else return null;
}