import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';

import { TabPanel } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay } from "../../../../components/display";
import { checkResponses } from '../../../../components/form';
import Contracts from './Contracts';
import Payments from './Payments';
import Warnings from './Warnings';
import { Socket } from '../../../../components/ws';

import {
    fetchMpCentersAll
} from '../../../../actions';

const pageTitle = 'Contracts and Payments';

function ContractsPayments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState({ contracts: false, payments: false, warnings: false });
    const [apiError, setApiError] = useState(false);
    // Data
    const [centerOptions, setCenterOptions] = useState([]);
    // Tabs display
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [nContracts, setNContracts] = useState(0);
    const [nPayments, setNPayments] = useState(0);
    const [nWarnings, setNWarnings] = useState(0);

    const { fetchMpCentersAll } = props;
    
    useEffect(() => {
        async function init(){
            const centersRes = await fetchMpCentersAll();
            const isApiError = checkResponses(centersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCenters = centersRes.data || [];
            const newCenterOptions = [ { value: 'all', label: 'All' }, 
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name })) ];

            if(mounted.current){
                setCenterOptions(newCenterOptions);

                setHasLoaded(true);
            }
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {(loading.contracts || loading.payments || loading.warnings || !hasLoaded)
                && <LoadingOverlay/>
            }
            <div className="card h-full">
                {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                    <>
                        <Tabs
                            id="contracts-payments"
                            value={activeTabKey}
                            onChange={(e, newValue) => setActiveTabKey(newValue)}
                        >
                            <Tab label={`Contracts (${nContracts})`}/>
                            <Tab label={`Payments (${nPayments})`}/>
                            <Tab label={`Warnings (${nWarnings})`}/>
                        </Tabs>
                        <TabPanel activeKey={activeTabKey} index={0}>
                            {hasLoaded && centerOptions.length && 
                                <Contracts
                                    setNContracts={setNContracts}
                                    loading={loading}
                                    setLoading={setLoading}
                                    centerOptions={centerOptions}
                                />
                            }
                        </TabPanel>
                        <TabPanel activeKey={activeTabKey} index={1}>
                            {hasLoaded && centerOptions.length &&
                                <Payments
                                    setNPayments={setNPayments}
                                    loading={loading}
                                    setLoading={setLoading}
                                    centerOptions={centerOptions}
                                />
                            }
                        </TabPanel>
                        <TabPanel activeKey={activeTabKey} index={2}>
                            {hasLoaded && centerOptions.length &&
                                <Warnings
                                    setNWarnings={setNWarnings}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            }
                        </TabPanel>
                    </>
                }
            </div>
            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    fetchMpCentersAll
})(ContractsPayments);