import { useState, useEffect } from 'react';

export function Label(props){
    const {
        color,
        children,
        className = '',
        breakable = false
    } = props;

    const [fullClassName, setFullClassName] = useState(className);
    
    useEffect(() => {
        let newFullClassName = `${className} px-2 py-0.5 rounded-md`;

        // Should line breaks within a label be allowed?
        if(!breakable) newFullClassName += ' whitespace-nowrap';

        switch(color){
            case 'mpLRed':
                newFullClassName += ' text-mpLRed bg-mpLRed-4';
                break;
            case 'mpOrange':
                newFullClassName += ' text-mpOrange bg-mpOrange-4';
                break;
            case 'mpYellow':
                newFullClassName += ' text-black bg-mpYellow-4';
                break;
            case 'mpGreen':
                newFullClassName += ' text-mpGreen bg-mpGreen-4';
                break;
             case 'mpTeal':
                newFullClassName += ' text-mpTeal bg-mpTeal-4';
                break;
            case 'mpLBlue':
                newFullClassName += ' text-mpLBlue bg-mpLBlue-4';
                break;
            case 'mpBlue':
                newFullClassName += ' text-mpBlue bg-mpBlue-4';
                break;
            case 'mpDBlue':
                newFullClassName += ' text-black bg-mpDBlue-3';
                break;
            case 'mpPurple':
                newFullClassName += ' text-mpPurple bg-mpPurple-4';
                break;
            case 'mpLGrey':
                newFullClassName += ' text-mpLGrey bg-mpLGrey-4';
                break;
            case 'custom': break;
            default: break;
        }
        
        setFullClassName(newFullClassName.trim());
    }, [color, breakable, className]);

    if(!color){
        return(
            <div className="text-mpLRed">
                Label missing props: {!color && 'color'}
            </div>
        );
    }

    return (
        <span className={fullClassName}>{children}</span>
    );
}