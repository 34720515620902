import { useRef, useState, useEffect } from 'react';
import { Wait } from '../wait';

import { formatTimeFromDate } from '../functions';

async function startTimer(setTime, mounted){
    while(true){
        await Wait(1000);
        if(mounted.current) setTime(new Date());
        else break;
    }
}

export function Clock(props){
    const mounted = useRef(false);

    const { skipSeconds } = props;

    useEffect(() => {
        mounted.current = true;

        return () => (mounted.current = false);
    });
    
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        startTimer(setTime, mounted);
    }, []);

    return formatTimeFromDate(time, !skipSeconds);
}