function formatYear(year){
    let newYear = year.toString();
    while(newYear.length < 4) newYear = '0' + newYear;
    return newYear;
}
function parseDateString(date){
    if(typeof(date) === 'string' || typeof(date) === 'number'){
        if(isNaN(Date.parse(date))) date = date.replace(/-/g, '/');
        date = Date.parse(date);

        if(isNaN(new Date(date))) date = 'Invalid Date';
        else date = new Date(date);
    } 

    if(!date || date === 'Invalid Date') return 'Invalid date';
    return date;
}

export function getDoW(date){
    const dow = date.getDay();
    switch(dow){
        case 0: return 'Sunday';
        case 1: return 'Monday';
        case 2: return 'Tuesday';
        case 3: return 'Wednesday';
        case 4: return 'Thursday';
        case 5: return 'Friday';
        case 6: return 'Saturday';
        default: return null;
    }
}

export function getymd(date){
    date = parseDateString(date);
    if(!date) return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return [year, month, day];
}

export function getymdhms(date){
    date = parseDateString(date);
    if(!date) return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const hours = date.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const minutes = date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const seconds = date.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return [year, month, day, hours, minutes, seconds];
}

export function gethms(date){
    date = parseDateString(date);
    if(!date) return '';
    const hours = date.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const minutes = date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const seconds = date.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return [hours, minutes, seconds];
}

export function formatTime(time, use24h = false){
    if(typeof(time) === 'string') time = parseInt(time);
    let hours = 0;
    let minutes = 0;
    if(typeof(time) === 'number'){
        hours = Math.floor(time / 60);
        minutes = (time % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    } else return 'Invalid time';

    if(use24h){
        hours = hours.toLocaleString(undefined, { minimumIntegerDigits: 2 });
        return `${hours}:${minutes}`;
    }
    else {        
        let hoursAMPM = hours > 12 ? hours - 12 : hours;
        let ampm = hours >= 12 ? 'PM' : 'AM';
        if(hoursAMPM === 0) hoursAMPM = 12;
        return `${hoursAMPM}:${minutes} ${ampm}`;
    }
}

export function formatTimeFromDate(time, showSeconds = false){
    if(!time) return 'Invalid date';
    if(typeof(time) === 'string') time = new Date(time);
    let hours = time.getHours();
    let minutes = time.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    let secondsText = '';
    if(showSeconds) {
        const seconds = time.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
        secondsText = `:${seconds}`;
    }

    let hoursAMPM = hours > 12 ? hours - 12 : hours;
    let ampm = hours >= 12 ? 'PM' : 'AM';
    if(hoursAMPM === 0) hoursAMPM = 12;
    return `${hoursAMPM}:${minutes}${secondsText} ${ampm}`;
}

export function formatDate(date){
    date = parseDateString(date);
    const year = formatYear(date.getFullYear());
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });

    return `${month}/${day}/${year}`;
}

export function formatDateTime(date){
    date = parseDateString(date);
    if(!date || date === 'Invalid Date') return 'Invalid date';
    const apiTime = date.getHours() * 60 + date.getMinutes() * 1;
    return `${formatDate(date)} ${formatTime(apiTime)}`;
}
export function formatDateTimeDefault(date){
    date = parseDateString(date);
    if(!date || date === 'Invalid Date') return 'Invalid date';
    const apiTime = date.getHours() * 60 + date.getMinutes() * 1;
    return `${formatDateApi(date)}T${formatTimeApi(apiTime).split(':').slice(0, 2).join(':')}`;
}

export function formatDateTimeFull(date){
    date = parseDateString(date);
    if(!date || date === 'Invalid Date') return 'Invalid date';
    const apiTime = date.getHours() * 60 + date.getMinutes() * 1;

    return `${formatDateFull(date)} ${formatTime(apiTime)}`;
}

export function formatDateTimeSeconds(date){
    date = parseDateString(date);
    if(!date || date === 'Invalid Date') return 'Invalid date';

    let hours = date.getHours().toLocaleString(undefined, { minimumIntegerDigits : 2 });
    let minutes = date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    let seconds = date.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    seconds = hours >= 12 ? `${seconds} PM` : `${seconds} AM`;
    hours = hours > 12 ? hours - 12 : hours;
    return `${formatDate(date)} ${hours}:${minutes}:${seconds}`;
}

export function formatTimeApi(timeInt, secondsToZero = true){
    if(typeof(timeInt) === 'string') timeInt = parseInt(timeInt);
    let hours = Math.floor(timeInt / 60);
    let minutes = (timeInt % 60).toLocaleString(undefined, {minimumIntegerDigits: 2});
    // return `${hours}:${minutes} (${hoursAMPM}:${minutes} ${ampm})`;
    if(secondsToZero) return `${hours}:${minutes}:00`;
    else return `${hours}:${minutes}`;
}

export function formatDateApi(date){
    date = parseDateString(date);
    if(!date || date === 'Invalid Date') return 'Invalid date';
    const year = formatYear(date.getFullYear());
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });

    return `${year}-${month}-${day}`;
}

export function formatDateTimeApi(date){
    date = parseDateString(date);
    if(!date || date === 'Invalid Date') return 'Invalid date';
    const apiTime = date.getHours() * 60 + date.getMinutes() * 1;
    return `${formatDateApi(date)} ${formatTimeApi(apiTime)}`;
}

export function getTimeObject(time, isDate = false){
    if(isDate) {
        const dateObject = new Date(time);
        time = dateObject.getHours() * 60 + dateObject.getMinutes() * 1;
    }
    if(typeof(time) !== 'number') time = parseInt(time);
    if(isNaN(time)) return { raw: 'Invalid date' };

    return {
        raw: time,
        api: formatTimeApi(time, false),
        api0s: formatTimeApi(time, true),
        formatted: formatTime(time, false),
        formatted24: formatTime(time, true)
    };
}

export function getDateObject(date){
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return {
        raw: date,
        date: newDate,
        api: formatDateApi(newDate),
        formatted: formatDate(newDate),
        formattedFull: formatDateFull(newDate),
    };
}

export function getDateTimeObject(date){
    const newDate = new Date(date);
    return {
        raw: date,
        date: newDate,
        api: formatDateTimeApi(newDate),
        formatted: formatDateTime(newDate),
        formattedFull: formatDateTimeFull(newDate),
        default: formatDateTimeDefault(newDate)
    };
}

export function formatDateFull(date){
    date = parseDateString(date);
    if(!date || date === null) return 'Invalid Date';
    const year = formatYear(date.getFullYear());
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const dow = getDoW(date);

    return `${dow}, ${month}/${day}/${year}`;
}

export function convertApiToDate(date){
    const [year, month, day] = date.split('-');
    return new Date(year, month - 1, day, 0, 0, 0, 0);
}

export function checkLessonDatesValid(assignedDate, completedDate){
    if(assignedDate === null) assignedDate = '';
    if(completedDate === null) completedDate = '';
    return (
        // Check that dates are valid
        (
            (assignedDate === '' || !isNaN(new Date(assignedDate))) &&
            (completedDate === '' || !isNaN(new Date(completedDate)))
        ) &&
        // Cases
        (
            // If both dates given, assigned date must be less
            (assignedDate !== '' && completedDate !== '' && assignedDate <= completedDate) ||
            // Date assigned is given and date completed is not given
            (assignedDate !== '' && completedDate === '') ||
            // No date is given
            (assignedDate === '' && completedDate === '')
        )
    );
}

export function getPayPeriod(date){
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    const end = new Date(date);
    end.setHours(0, 0, 0, 0);
    const today = start.getDate();
    if(today <= 15){
        start.setDate(1);
        end.setDate(15);
    } else {
        start.setDate(16);
        end.setMonth(end.getMonth() + 1);
        end.setDate(1);
        end.setDate(end.getDate() - 1);
    }

    return [start, end];
}