import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, SpinnerLoader } from "../../../../components/custom-essentials";
import { BrowserTabTitle } from "../../../../components/display";
import { DatePicker, SelectSingle, FormikControl, checkResponses } from '../../../../components/form';
import { convertApiToDate, formatDateApi, getDateObject } from "../../../../components/functions";
import TransitionsTable from './TransitionsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchStudentsAll,
    fetchMpCentersAll,
    fetchAppointmentsDateOnly,
    fetchAppointmentsDaterange
} from '../../../../actions';

const pageTitle = 'Transitions';

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'studentName', label: 'Student Name'},
    { value: 'currentCenterName', label: `Today's Appointment` },
    { value: 'previousCenterName', label: 'Last Appointment' },
];

const now = formatDateApi(new Date());

function Transitions(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [pairedAppointments, setPairedAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);

    const { fetchStudentsAll, fetchMpCentersAll, fetchAppointmentsDateOnly, fetchAppointmentsDaterange } = props;

    const filterAppointments = useCallback((unfilteredAppointments, filterType, filterQuery) => {
        filterQuery = filterQuery.toLowerCase();
        const checkStudentName = (a) => a.studentName.replace(/ /g, '').toLowerCase().includes(filterQuery);
        const checkCurrentCenterName = (a) => a.currentCenterName.replace(/ /g, '').toLowerCase().includes(filterQuery);
        const checkPreviousCenterName = (a) => a.previousCenterName.replace(/ /g, '').toLowerCase().includes(filterQuery);
        const newAppointments = unfilteredAppointments.filter(a => {
            switch(filterType.value){
                case 'all':
                    return checkStudentName(a) || checkCurrentCenterName(a) || checkPreviousCenterName(a);
                case 'studentName':
                    return checkStudentName(a);
                case 'currentCenterName':
                    return checkCurrentCenterName(a);
                case 'previousCenterName':
                    return checkPreviousCenterName(a);
                default:
                    return false;
            }
        });

        if(mounted.current) setFilteredAppointments(newAppointments);
    }, [mounted]);
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
    
            const { selectedDate, filterType, filterQuery } = formRef.current.values;
    
            const centersRes = await fetchMpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const isApiError = checkResponses(centersRes, studentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
    
            // Get today's appointments
            const selectedDateObj = getDateObject(convertApiToDate(selectedDate));
            const currentAppointmentsRes = await fetchAppointmentsDateOnly({
                date: selectedDateObj.raw
            });

            // Get prior appointments up to two weeks ago
            const [year, month, day] = selectedDate.split('-');
            const twoWeeksAgo = new Date(year, month - 1, day, 0, 0, 0 ,0);
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
            const yesterday = new Date(year, month - 1, day, 0, 0, 0 ,0);
            yesterday.setDate(yesterday.getDate() - 1);
            const previousAppointmentsRes = await fetchAppointmentsDaterange({
                startDate: twoWeeksAgo,
                endDate: yesterday
            });
            const isApiError2 = checkResponses(currentAppointmentsRes, previousAppointmentsRes);
            if(isApiError2){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newCurrentAppointments = currentAppointmentsRes.data?.appointments || [];
            const currentAppointmentsFiltered = newCurrentAppointments.filter(a => {
                return !['Cancelled', 'Missed'].includes(a.status);
            });
            const newPreviousAppointments = previousAppointmentsRes.data?.appointments || [];
            const previousAppointmentsFiltered = newPreviousAppointments.filter(a => {
                return !['Cancelled', 'Missed'].includes(a.status);
            });
    
            // Append center names and student names
            const centerMap = {};
            newCenters.forEach(c => centerMap[parseInt(c.id)] = c.name);
            const studentMap = {};
            newStudents.forEach(s => {
                studentMap[s.user_id] = {
                    studentName: `${s.first_name} ${s.last_name}`,
                    binderLocation: parseInt(s.mp_binder_location)
                };
            });
            currentAppointmentsFiltered.forEach(a => {
                a.currentCenterName = centerMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
                
                const { binderLocation, studentName } = studentMap[a.student];
                a.studentName = studentName || `Unknown student (UID: ${a.student})`;
                a.binderLocation = binderLocation || `Unable to find binder location (UID: ${a.student})`;
                a.binderLocationName = centerMap[binderLocation] || `Unknown center (ID: ${binderLocation})`;
            });

            previousAppointmentsFiltered.forEach(a => {
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
            });
    
            const newPairedAppointments = currentAppointmentsFiltered.map(a => {
                const aptStudent = a.student;
                const previousAppointment = previousAppointmentsFiltered.find(apt =>
                    apt.student === aptStudent && !['Cancelled', 'Missed'].includes(apt.status)) || {};
                a.previousAppointment = previousAppointment;
                a.previousCenterName = centerMap[parseInt(previousAppointment.center)] || `Unknown center (${previousAppointment.center})`;
                return a;
            });
    
            if(mounted.current){
                setPairedAppointments(newPairedAppointments);
                filterAppointments(newPairedAppointments, filterType, filterQuery);
                setLoading(false);
            }
        })();
    }, [mounted, filterAppointments, loading, fetchStudentsAll, fetchMpCentersAll,
        fetchAppointmentsDateOnly, fetchAppointmentsDaterange]);
    useEffect(() => {
        async function init(){
            await refreshData();
            setHasLoaded(true);
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            <div className="card">
                <h2>Student Transitions ({pairedAppointments.length})</h2>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedDate: now,
                        filterType: { value: 'all', label: 'All' },
                        filterQuery: '',
                    }}
                    onSubmit={() => refreshData()}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <DatePicker
                                        id="transition-datepicker-1"
                                        name="selectedDate"
                                        label="Select a date"
                                        value={formik.values.selectedDate}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>

                            <br/>
                            
                            {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                            <>
                                <hr/>
                                <br/>

                                <h2>Filter Results ({filteredAppointments.length})</h2>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <SelectSingle
                                            id="transition-select-1"
                                            name="filterType"
                                            value={formik.values.filterType}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterAppointments(pairedAppointments, e.target.value,
                                                    formik.values.filterQuery);
                                            }}
                                            options={filterTypeOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <FormikControl
                                            id="transition-control-1"
                                            name="filterQuery"
                                            placeholder="Enter a filter query..."
                                            value={formik.values.filterQuery}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterAppointments(pairedAppointments, formik.values.filterType,
                                                    e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                            }
                        </>
                    )}
                </Formik>

                <br/>

                {loading && 
                    <>
                        <SpinnerLoader/> Loading...
                    </>
                }

                {apiError ? null : hasLoaded && !loading &&
                    <TransitionsTable
                        refreshData={refreshData}
                        appointments={filteredAppointments}
                    />
                }
            </div>
            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchStudentsAll,
    fetchMpCentersAll,
    fetchAppointmentsDateOnly,
    fetchAppointmentsDaterange
})(Transitions);