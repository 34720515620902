import React from 'react';
import { Button, Modal } from '../custom-essentials';

export default function OutOfDate(props){
    const { latest, current } = props;
    return (
        <>
        <Modal className="w-5/12" show={true} onHide={() => null}>
            <Modal.Header>
                <h2>Client out of Date</h2>
            </Modal.Header>
            <Modal.Body>
                <h6>Current: {current}</h6>
                <h6>Latest: {latest}</h6>
                <br/>
                <h4>Please refresh to get the latest site version.</h4>
                <h4>If this message persists, use the button below.</h4>
                <br/>
                <Button
                    color="lte-mpTeal"
                    onClick={() => window.location.reload(true)}
                >
                    Update!
                </Button>
            </Modal.Body>
        </Modal>
        </>
    );
}