import React, { useRef, useEffect, useState } from "react";

import { Wait } from '../../components/wait';
import { CircularProgress } from "@mui/material"; 

export function LoadingWatermark(){
    const [timer, setTimer] = useState(0);
  
    const mounted = useRef(false);
    useEffect(() => {
      mounted.current = true;
  
      return () => (mounted.current = false);
    });
  
    useEffect(() => {
        async function increaseCounter(){
            await Wait(1000);
            if(mounted.current) setTimer(timer + 1);
            else return;
        }
    
        increaseCounter();
    });
  
    return (
        <div className="fixed flex right-6 bottom-6 z-50 items-center">
            <CircularProgress size={16}/>&nbsp;
            <div className="text-sm text-mpLGrey-2">Reconnecting...</div>
        </div>
    );
  }
  