import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

function TableCell(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { cellKey, children } = props;

    return (
        <td key={cellKey}>
            {children}
        </td>
    );
}

export default connect(null, {

})(TableCell);