import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl } from '../../form';
import { validationSchema, tagOptions, getInitTagOption, getInitStudentOption,
    getInitCenterOption, getCenterName, statusOptions, getInitStatusOption, renderMeta } from './helpers';
import { formatDate } from '../../functions';

import {

} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function FlagsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedFlag, studentOptions, centerOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this flag?</h4>
                            <div className="grid grid-cols-1">
                                <div>Student: {selectedFlag.studentName}</div>
                                <div>Center: {getCenterName(selectedFlag.center, centerOptions)}</div>
                                <div>Tag: {selectedFlag.tag}</div>
                                <div>Status: {selectedFlag.status}</div>
                                <div>Created: {formatDate(selectedFlag.date_created)}</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    student: getInitStudentOption(selectedFlag.student, studentOptions),
                    tag: getInitTagOption(selectedFlag.tag),
                    center: getInitCenterOption(selectedFlag.center, centerOptions),
                    additionalNotes: selectedFlag.notes || '',
                    adminNotes: selectedFlag.admin_notes || '',
                    status: getInitStatusOption(selectedFlag.status)
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Student</h4>
                                    <SelectSingle
                                        id="flags-student"
                                        name="student"
                                        value={formik.values.student}
                                        onChange={(e) => {
                                            const s = e.target.value;
                                            const newValues = { ...formik.values };
                                            newValues.student = s;
                                            newValues.center = getInitCenterOption(s.obj?.mp_primary_center, centerOptions);
                                            formik.setValues(newValues);
                                        }}
                                        options={studentOptions}
                                    />
                                    {formik.errors.student ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.student}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Tag</h4>
                                    <SelectSingle
                                        id="flags-tag"
                                        name="tag"
                                        value={formik.values.tag}
                                        onChange={formik.handleChange}
                                        options={tagOptions}
                                    />
                                    {formik.errors.tag ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.tag}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="flags-center"
                                        name="center"
                                        value={formik.values.center}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                    {formik.errors.center ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.center}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Additional Notes</h4>
                                    <FormikControl
                                        id="flags-additional-notes"
                                        name="additionalNotes"
                                        value={formik.values.additionalNotes}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Extra comments about the flag in case the tag doesn't suffice. Max 200 characters."
                                    />
                                    {formik.errors.additionalNotes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.additionalNotes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Admin Notes</h4>
                                    <FormikControl
                                        id="flags-admin-notes"
                                        name="adminNotes"
                                        value={formik.values.adminNotes}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Notes to be left by the person handling the flag. Max 200 characters."
                                    />
                                    {formik.errors.adminNotes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.adminNotes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Status</h4>
                                    <SelectSingle
                                        id="flags-status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={statusOptions}
                                    />
                                    {formik.errors.status ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.status}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            {renderMeta(selectedFlag)}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                                {mode === 'edit' ?     
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={(e) => {
                                            formik.setFieldValue('status', { value: 'Completed', label: 'Completed' });
                                            formik.handleSubmit(e);
                                        }}
                                        disabled={formik.isSubmitting}
                                    >
                                        Mark Completed &amp; Submit
                                    </Button>
                                    : null
                                }
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(FlagsBodyFooter);