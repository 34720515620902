import React from 'react';
import { formatTime, formatDate } from '../../../../../components/functions';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { renderStatus, TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(handleShowModal, switches){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    const { showAll, showWorkCompleted, showInstructors, showSessionNotes,
        showHomeworkNotes, showStatus } = switches;
    const noneTag = <span className="text-mpOrange">None</span>;

    // Columns to return, based off of switch options
    const baseColumns = [
        // default columns
        {
            dataField: 'date_time',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (bDate - aDate) * swap;
            },
            formatter: (row) => {
                const endTime = parseInt(row.time) + parseInt(row.duration);
                return (
                    <div className="grid grid-cols-1 gap-y-2">
                        <div>
                            {formatDate(row.date_time)}
                        </div>
                        <div>
                            {formatTime(row.time)} to {formatTime(endTime)}
                        </div>
                    </div>
                );
            },
        },
        {
            dataField: 'center',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(rowA.centerName < rowB.centerName) return -1 * swap;
                else if(rowA.centerName > rowB.centerName) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(colorMap[center]) centerColor = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    centerColor = colorMap[center];
                }
                return (
                    <div>
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        },
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
        }
    ];

    if(showAll){
        const detailsField = {
            dataField: 'details',
            text: 'Details',
            formatter: (row) => {
                return (
                    <>
                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h6>Work Completed</h6>
                                <div className="col no-pad">
                                    <div>
                                        Pages/Lessons: {row.n_pages}/{row.n_lessons}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h6>Instructors</h6>
                                <div>
                                    {row.instructorNames === 'None' ? noneTag : row.instructorNames}
                                </div>
                            </div>
                        </div>

                        <br/>

                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h6>Session Notes</h6>
                                <div>{row.session_notes || noneTag}</div>
                            </div>
                        </div>

                        <br/>

                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h6>Homework Notes</h6>
                                <div>{row.homework_notes || noneTag}</div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                <h6>Status</h6>
                                <div>{renderStatus(row)}</div>
                            </div>
                        </div>
                    </>
                )
            },
            headerStyle: () => ({ width: "60%" })
        };
        baseColumns.push(detailsField);
    } else {
        if(showWorkCompleted){
            baseColumns.push({
                dataField: 'workCompleted',
                text: 'Work Completed',
                formatter: (row) => {
                    return (
                        <div className="col no-pad">
                            <div>
                                Pages/Lessons: {row.n_pages}/{row.n_lessons}
                            </div>
                        </div>
                    );
                }
            });
        }
        if(showInstructors){
            baseColumns.push({
                dataField: 'instructorNames',
                text: 'Instructors',
                formatter: (row) => row.instructorNames || noneTag
            });
        }
        if(showSessionNotes){
            baseColumns.push({
                dataField: 'sessionNotes',
                text: 'Session Notes',
                formatter: (row) => row.session_notes || noneTag
            });
        }
        if(showHomeworkNotes){
            baseColumns.push({
                dataField: 'homeworkNotes',
                text: 'Homework Notes',
                formatter: (row) => row.homework_notes || noneTag
            });
        }
        if(showStatus){
            const statusMap = {
                "cancelled": 6,
                "missed": 5,
                "not charged": 4,
                "scheduled": 3,
                "in progress": 2,
                "completed": 1,
            };
            baseColumns.push({
                dataField: 'status',
                text: 'Status',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? -1 : 1;
                    return ((statusMap[a.toLowerCase()] - statusMap[b.toLowerCase()]) * swap);
                },
                formatter: (row) => {
                    const { status } = row;
                    if(status === 'Cancelled' || status === 'Missed'){
                        return <Label color="mpLRed">{status}</Label>;
                    } else if(status === 'Not Charged'){
                        return <Label color="mpOrange">{status}</Label>;
                    } else if(status === 'Scheduled'){
                        return <Label color="mpLGrey">{status}</Label>;
                    } else if(status === 'In Progress'){
                        return <Label color="mpYellow">{status}</Label>;
                    } else if(status === 'Completed'){
                        return <Label color="mpGreen">{status}</Label>;
                    } return null;
                }
            })
        }
    }

    const actionsField = {
        dataField: 'actions',
        text: 'Actions',
        formatter: (row) => {
            return (
                <div className="flex flex-row gap-x-2">
                    <TooltipWrapper
                        tooltipText={`Edit appointment details`}
                    >
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            onClick={() => handleShowModal('edit', row)}
                            style={{ marginRight: "10px" }}
                        >
                            <SVGIcon fileName="plus"/>
                        </Button>
                    </TooltipWrapper>
                </div>
            );
        }
    };

    baseColumns.push(actionsField);

    return baseColumns;
}