import React from 'react';
import * as Yup from 'yup';

import { Modal, ModalBodyFooter, Button, ListGroup, SVGIcon, ModalProcessing } from '../../custom-essentials';
import { DatePicker, renderSubmittingDefault } from '../../form';
import { TooltipWrapper } from '../../display';
import { convertApiToDate, formatDateFull, formatDateApi, checkLessonDatesValid } from '../../functions';
import { fetchMPLessonsSingleND } from '../../../actions-nd';

export const validationSchema = Yup.object().shape({
    specialNotesSchedule: Yup.string()
        .max(250, 'Max 250 characters'),
    nPages: Yup.number()
        .typeError('# Pages must be a number')
        .min(0, 'Min 0')
        .max(50, 'Max 50')
        .required('# Pages is required')
        .integer('# Pages must be a whole number'),
    nLessons: Yup.number()
        .typeError('# Lessons must be a number')
        .min(0, 'Min 0')
        .max(10, 'Max 10')
        .required('# LC Pages is required')
        .integer('# LC Pages must be a whole number'),
    hwNotes: Yup.string()
        .max(300, 'Max 300 characters'),
    upcomingExamsNotes: Yup.string()
        .max(300, 'Max 300 characters'),
    sessionNotes: Yup.string()
        .max(1500, 'Max 1,500 characters'),
    stampsAmount: Yup.number()
        .typeError('Stamps Amount must be a number')
        .min(0, 'Min 0')
        .max(100, 'Max 100')
        .required('Stamps Amount is required (it can be 0!)'),
    stampsNotes: Yup.string()
        .max(300, 'Max 300 characters'),
    status: Yup.mixed().test(
        'statusIsValid',
        'Please select a status',
        (value) => value.value !== -1
    ),
    flagCenterValid: Yup.mixed().when(['flagCenter', 'createFlag'],
        ([flagCenter, createFlag]) => {
            const isValid = !createFlag || flagCenter.value !== -1;
            const errorText = `Tag is required if "Create Flag" is checked.`
            if(isValid) return Yup.mixed().test('checkFlagCenter', '', () => true);
            else return Yup.mixed().test('checkFlagCenter', errorText, () => false);
        }
    ),
    flagTagValid: Yup.mixed().when(['flagTag', 'createFlag'],
        ([flagTag, createFlag]) => {
            const isValid = !createFlag || flagTag.value !== -1;
            const errorText = `Tag is required if "Create Flag" is checked.`
            if(isValid) return Yup.mixed().test('checkFlagTag', '', () => true);
            else return Yup.mixed().test('checkFlagTag', errorText, () => false);
        }
    ),
    flagNotes: Yup.string()
        .min(0, 'Min length 0')
        .max(200, 'Max length 200')
});

export function renderRecommendedStamps(values){
    const nPages = parseInt(values.nPages);
    const nLessons = parseInt(values.nLessons);
    return(
        <div className="col" style={{ fontSize: "8pt" }}>
            <div>Pages: {nPages} pages = {nPages * 2} stamps&nbsp;&nbsp;</div>
            <div>Lessons: {nLessons} = {nLessons * 10} stamps&nbsp;</div>
            <div>Rec. total: {nPages * 2 + nLessons * 10} stamps</div>
        </div>
    )
}

export function getSelectedInstructors(assignments, instructorOptions){
    return assignments
        .map(a => {
            const defaultOpt = { value: a.instructor, label: `Unknown instructor (ID: ${a.instructor})` };
            return instructorOptions.find(i => a.instructor === i.value) || defaultOpt;
        })
        .sort((a, b) => {
            if(a.label < b.label) return -1;
            else if(a.label > b.label) return 1;
            return 0;
        });
}

export const statusOptions = ['Scheduled', 'In Progress', 'Completed', 'Missed', 'Cancelled', 'Not Charged']
    .map(i => ({ value: i, label: i }));

export function getInitSelectedStatus(status){
    if(['Scheduled', 'In Progress', 'Completed', 'Missed', 'Cancelled', 'Not Charged'].includes(status)){
        return { value: status, label: status };
    } else {
        return { value: -1, label: `Unknown Status ${status}`}
    }
}

export function getCenterOptions(centers){
    return centers.map(c => ({ value: parseInt(c.id), label: c.name }));
}

export function getInitSelectedCenter(center, centers){
    const relCenter = centers.find(c => parseInt(c.id) === parseInt(center));
    if(relCenter) return { value: relCenter.id, label: relCenter.name };
    else return { value: -1, label: `Unknown Center (ID ${center})`};
}

export function renderSubmitting(submissionStatus, setSubmitting){
    return (
        <ModalBodyFooter>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="grid grid-cols-1">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>
                
                <div className="col">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => setSubmitting(false)}
                >
                    Back
                </Button>
            </Modal.Footer>
        </ModalBodyFooter>
    );
}

export function renderRelevantNotes(combinedNotes){
    const notesToShow = combinedNotes?.length ? (
        <div className="max-h-[40rem] overflow-y-auto">
            <ListGroup>
                {combinedNotes.map(cn => {
                    const sessionNotes = cn.sessionNotes ?
                    <div>
                        <b>Session Notes: </b>
                        <span className="text-mpBlue">{cn.sessionNotes}</span>
                    </div> : null;
                    const workCompleted = cn.workCompleted ?
                    <div>
                        <b>Work Completed: </b>
                        <span className="text-mpOrange">{cn.workCompleted}</span>
                    </div> : null;
                    const reports = cn.reports ?
                        <div>
                            <b>Reports: </b>
                            <span className="text-mpLRed">{cn.reports}</span>
                        </div> : null;
                    return (
                        <div key={cn.key}>
                            <h4>{formatDateFull(cn.date)}</h4>
                            {sessionNotes}
                            {workCompleted}
                            {reports}
                        </div>
                    );
                })}
            </ListGroup>
        </div>
    ) : (
        <h5 className="text-mpLRed">None</h5>
    );
    return(
        <>
            <h4>Relevant Notes (Last 3 Months)</h4>
            {notesToShow}
        </>
    );
}

export function renderPastFlags(flags){
    let index = 0;
    return(
        <>
            <h4>Past Flags (3 months)</h4>
            <div className="text-mpOrange">
                Please ensure you are not creating a redundant flag!
            </div>
            <div className="max-h-[30rem] overflow-y-auto">
                <ListGroup>
                    {flags.map(flag => {
                        const color = flag.status === 'Completed' ? 'text-mpGreen' : 'text-mpOrange';
                        return (
                            <div key={`appointments-past-flags-${index++}`}>
                                <h4 className={color}>{formatDateFull(flag.date_created)} ({flag.status})</h4>
                                <div>
                                    <b>Updated: </b>
                                    {flag.date_updated ? 
                                        <span>{formatDateFull(flag.date_updated)}</span> :
                                        <span className="text-mpOrange">Never</span>
                                    }
                                </div>
                                <div>
                                    <b>Tag: </b>
                                    {flag.tag ? <span>{flag.tag}</span> : <span className="text-mpOrange">None</span>}
                                </div>
                                <div>
                                    <b>Notes: </b>
                                    {flag.notes ? <span>{flag.notes}</span> : <span className="text-mpOrange">None</span>}
                                </div>
                                <div>
                                    <b>Admin Notes: </b>
                                    {flag.admin_notes ? <span>{flag.admin_notes}</span> : <span className="text-mpOrange">None</span>}
                                </div>
                            </div>
                        );
                    })}
                </ListGroup>
            </div>
        </>
    );
}

function downloadFile(fileInfo, appendLogo){
    fileInfo.options = {
        appendName: false,
        appendLogo,
        appendSquare: appendLogo
    };
    (async function doDownload(){
        await fetchMPLessonsSingleND(fileInfo);
    })();
}
function renderAnswerKeyButton(lpi){
    const lesson = lpi.lessonObj;
    lesson.type = lesson.lesson_id.includes('_EP1') ? 'epAnswerKey' : 'answerKey';
    if(lesson.has_answer_key){
        return(
            <TooltipWrapper
                tooltipText="View answer key"
            >
                <Button
                    color="lte-mpEGreen"
                    variant="icon"
                    onClick={() => downloadFile(lesson, false)}
                >
                    <SVGIcon fileName="answer-key"/>
                </Button>
            </TooltipWrapper>
        );
    } else {
        return(
            <TooltipWrapper
                tooltipText="No answer key link provided"
            >
                <Button
                    color="lte-mpLBlue"
                    variant="icon"
                    disabled
                    onClick={() => null}
                >
                    <SVGIcon fileName="unlink"/>
                </Button>
            </TooltipWrapper>
        );
    }
}

export function renderLPFields(formik){
    const selectedLP = formik.values.selectedLearningPlan.obj;
    const lpItems = selectedLP.items || [];

    if(!lpItems?.length) return <div className="text-mpOrange">No active items</div>;
    let currentIndex = 0;

    return(
        <div className="max-h-[40rem] overflow-y-auto">
            {lpItems.filter(lpi => {
                let shouldShow = true;
                if(formik.values.hideCompleted && lpi.date_completed) shouldShow = false;
                return shouldShow;
            }).map(lpi => {
                const assignedDate = lpi.dateAssigned ? convertApiToDate(lpi.dateAssigned) : '';
                const completedDate = lpi.dateCompleted ? convertApiToDate(lpi.dateCompleted) : '';
                const shouldWarn = !(checkLessonDatesValid(assignedDate, completedDate));
                const datesWarning = shouldWarn ?
                    <div className="text-mpLRed">
                        Date completed cannot be before date assigned.
                    </div>
                : null;
        
                return (
                    <div key={`details-learning-plan-item-${currentIndex++}`}>
                        <h5>{lpi.name}</h5>
                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <h5>Assigned</h5>
                                <div className="flex flex-row gap-x-4">
                                    <DatePicker
                                        id="learning-plans-date-assigned"
                                        name="dateAssigned"
                                        value={lpi.dateAssigned}
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                <h5>Completed</h5>
                                <div className="flex flex-row gap-x-2">
                                    <DatePicker
                                        id="learning-plans-date-completed"
                                        name="dateCompleted"
                                        value={lpi.dateCompleted}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.selectedLearningPlan.obj.items[lpi.orderIndex].dateCompleted = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                    />
                                    <div>
                                        <TooltipWrapper
                                            tooltipText="Use today's date"
                                        >
                                            <Button
                                                color="lte-mpLBlue"
                                                variant="icon"
                                                onClick={() => {
                                                    const newDate = formatDateApi(new Date());
                                                    const newValues = { ...formik.values };
                                                    newValues.selectedLearningPlan.obj.items[lpi.orderIndex].dateCompleted = newDate;
                                                    formik.setValues(newValues);
                                                }}
                                            >
                                                <SVGIcon fileName="calendar"/>
                                            </Button>
                                        </TooltipWrapper>
                                    </div>
                                    <div>
                                        <TooltipWrapper
                                            tooltipText="Remove date"
                                        >
                                            <Button
                                                color="lte-mpLRed"
                                                variant="icon"
                                                onClick={() => {
                                                    const newDate = '';
                                                    const newValues = { ...formik.values };
                                                    newValues.selectedLearningPlan.obj.items[lpi.orderIndex].dateCompleted = newDate;
                                                    formik.setValues(newValues);
                                                }}
                                            >
                                                <SVGIcon fileName="eraser"/>
                                            </Button>
                                        </TooltipWrapper>
                                    </div>
                                    <div>
                                        {renderAnswerKeyButton(lpi)}
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-x-4">
                            {datesWarning}
                        </div>

                        <br/>
                    </div>
                );
            })}
        </div>
    )
}