import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumns(permissions, handleShowModal, downloadFile){
    const isRootAdmin = ['Root', 'Admin'].includes(permissions);
    const columns = [
        {
            dataField: 'default_sort_order',
            text: 'Sort Order',
            sort: true
        },
        {
            dataField: 'lesson_id',
            text: 'ID',
            sort: true
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true
        },
        {
            dataField: 'lesson_length',
            text: 'Length',
            sort: true
        },
        {
            dataField: 'has_file',
            text: 'Download',
            formatter: (row) => {
                const lesson = { ...row };
                lesson.type = row.lesson_id.includes('_EP1') ? 'epLesson' : 'lesson';
                if(row.has_file){
                    return(
                        <TooltipWrapper
                            tooltipText="View/Download this lesson"
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => downloadFile(lesson, true)}
                            >
                                <SVGIcon fileName="print"/>
                            </Button>
                        </TooltipWrapper>
                    );
                } else {
                    return(
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            disabled
                            onClick={() => null}
                        >
                            <TooltipWrapper
                                tooltipText="No file path provided"
                            >
                                <SVGIcon fileName="unlink"/>
                            </TooltipWrapper>
                        </Button>
                    );
                }
            }
        },
        {
            dataField: 'has_answer_key',
            text: 'Answer Key',
            formatter: (row) => {
                const lesson = { ...row };
                lesson.type = row.lesson_id.includes('_EP1') ? 'epAnswerKey' : 'answerKey';
                if(row.has_answer_key){
                    return(
                        <TooltipWrapper
                            tooltipText="View Answer Key"
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => downloadFile(lesson, false)}
                            >
                                <SVGIcon fileName="answer-key"/>
                            </Button>
                        </TooltipWrapper>
                    );
                } else {
                    return(
                        <Button
                            color="lte-mpLBlue"
                            variant="icon"
                            disabled
                            onClick={() => null}
                        >
                            <TooltipWrapper
                                tooltipText="No file path provided"
                            >
                                <SVGIcon fileName="unlink"/>
                            </TooltipWrapper>
                        </Button>
                    );
                }
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(a) - parseInt(b)) * swap;
            },
            formatter: (row) => {
                const isActive = parseInt(row.active);
                if(isActive) return <Label color="mpGreen">Yes</Label>;
                else return <Label color="mpLRed">No</Label>;
            }
        }
    ];

    if(isRootAdmin){
        columns.push({
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit this lesson`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete this lesson`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        })
    }
    return columns;
}