import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

function TablePagination(props){

    const { tableName, count, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage, rowsPerPageList } = props;

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const maxPage = Math.ceil(count / rowsPerPage.value) - 1; // -1 since page 0 is the first
    const rowsPerPageOptions = rowsPerPageList.map(i => ({ value: i, label: i }));

    const currentViewMin = currentPage * rowsPerPage.value + 1;
    const currentViewMax = Math.min(((currentPage + 1) * rowsPerPage.value), count);

    return (
        <div className="px-4 py-4 flex flex-row bg-mpDBlue text-white w-full">
            <div className="grid grid-cols-2 w-full">
                <div className="flex flex-row">
                    <div className="w-fit">
                        Rows Per Page: &nbsp;
                    </div>
                    <div className="text-black">
                        <select
                            id={`table-${tableName}-pagination`}
                            name="rowsPerPage"
                            onChange={setRowsPerPage}
                        >
                            {rowsPerPageOptions.map(o => <option value={o.value}>{o.label}</option>)}
                        </select>
                    </div>
                </div>
                <div className="grid grid-rows-1 grid-flow-col gap-2 justify-self-end">
                    <div className="flex flex-row gap-x-4 whitespace-nowrap">
                        <span
                            onClick={() => setCurrentPage(0)}
                            className={`${currentPage === 0 ? 'text-mpLGrey-4' : 'cursor-pointer'} text-xl`}
                        >
                            |&lt;
                        </span>
                        <span
                            onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
                            className={`${currentPage === 0 ? 'text-mpLGrey-4' : 'cursor-pointer'} text-xl`}
                        >
                            &lt;
                        </span>
                        <span
                            onClick={() => setCurrentPage(Math.min(maxPage, currentPage + 1))}
                            className={`${currentPage >= Math.ceil(count / rowsPerPage.value) - 1 ? 'text-mpLGrey-4' : 'cursor-pointer'} text-xl`}
                        >
                            &gt;
                        </span>
                        <span
                            onClick={() => setCurrentPage(maxPage)}
                            className={`${currentPage >= Math.ceil(count / rowsPerPage.value) - 1 ? 'text-mpLGrey-4' : 'cursor-pointer'} text-xl`}
                        >
                            &gt;|
                        </span>
                    </div>
                    <div className="ml-2">
                        {currentViewMin}-{currentViewMax} of {count}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, {

})(TablePagination);