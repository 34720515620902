import React from 'react';
import { Button } from '../custom-essentials';

class CSVExport extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {

        };
    }

    componentDidMount(){
        this._isMounted = true;
    }

    handleExport = () => {
        const arrData = this.props.data;
        const title = this.props.title;

        let CSV = '';

        let firstItem = arrData[0];
        let headers = '';
        for(let key in firstItem){
            headers += `"${key}",`
        }
        headers = headers.slice(0, -1); // Remove trailing comma

        CSV += headers + '\r\n';

        for(let i = 0; i < arrData.length; i++){
            // Parse single entry and add to CSV var
            let row = arrData[i];
            let values = '';
            for(let col of Object.values(row)){
                values += `"${col}",`;
            }
            values = values.slice(0, -1); // Remove trailing comma

            CSV += values + '\r\n';
        }

        if(CSV === ''){
            alert('No data is selected');
            return;
        }

        const fileName = title.replace(/ /g, '_');
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

        let link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render = () => {
        const data = this.props.data;
        let row = {};
        if(data) row = data[0];
        let keys = [];
        if(row) keys = Object.keys(row);
        return(
            <Button
                color="lte-mpEGreen"
                disabled={!keys.length}
                onClick={this.handleExport}
            >
                {this.props.label}
            </Button>
        );
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
}

export default CSVExport;