import React from 'react';
import Select from 'react-select';

export function SelectSingle(props){
    const {
        id,
        name, // name must match formik field name
        value,
        onChange,
        options,
        disabled = false,
        label = '',
        placeholder = ''
    } = props;

    if(!id || !name || value === undefined || !onChange || !options){
        return(
            <div className="text-mpLRed">
                SelectSingle missing props: {!id && 'id'} {!name && 'name'} {value === undefined && 'value'} {!onChange && 'onChange'} {!options && 'options'} 
            </div>
        );
    }

    const patchedOnChange = (newValue) => {
        onChange({ target: { value: newValue, name }});
    }

    return (
        <Select
            className="basic-single w-full h-min"
            classNamePrefix="select"
            isSearchable
            TextFieldProps={{
                label,
                InputLabelProps: {
                    htmlFor: 'react-select-single',
                    shrink: true,
                },
                placeholder,
            }}
            inputId={id}
            options={options}
            value={value} // { value: value, label: label }
            onChange={patchedOnChange}
            menuPlacement="auto"
            // menuPortalTarget={document.getElementById('root')}
            styles={{ menuPortal: base => ({
                ...base,
                zIndex: 10000
            })}}
            isDisabled={disabled}
        />
    );
}

export function SelectMulti(props){
    const {
        id,
        name, // name must match formik field name
        value,
        onChange,
        maxLength = 0,
        options,
        disabled = false,
        label = '',
        placeholder = '',
        closeMenuOnSelect = true,
    } = props;

    function handleChangeMulti(newValue) {
        if(!newValue) newValue = [];
        if(!maxLength || newValue.length <= maxLength){
            onChange({ target: { value: newValue, name }});
        }
    }

    if(!id || !name || value === undefined || !onChange || !options){
        return(
            <div className="text-mpLRed">
                SelectMulti missing props: {!id && 'id'} {!name && 'name'} {value === undefined && 'value'} {!onChange && 'onChange'} {!options && 'options'} 
            </div>
        );
    }

    return (
        <Select
            className="basic-multi-select w-full h-min"
            classNamePrefix="select"
            isSearchable
            TextFieldProps={{
                label,
                InputLabelProps: {
                    htmlFor: 'react-select-multiple',
                    shrink: true,
                },
                placeholder,
            }}
            options={options}
            value={value}
            onChange={handleChangeMulti} // [ { value: value, label: label }, { ... }, ...]
            menuPlacement="auto"
            menuPortalTarget={document.getElementById('root')}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti
            styles={{ menuPortal: base => ({
                ...base,
                zIndex: 10000
            })}}
            isDisabled={disabled}
        />
    );
}