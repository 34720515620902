export const colorPalette = {
    white: { main: "#ffffff" },
    black: { main: "#000000" },
    transparent: { main: "transparent" },
    // Standard (0%)
    mpLRed: {
        main: "#d80000",
        light1: "#ff2222",
        light2: "#ff3f3f",
        light3: "#ff6b6b",
        light4: "#ffb5b5",
        light5: "#ffd2d2",
        light6: "#ffe7e7",
        light7: "#fff3f3"
    },
    mpDRed: {
        main: "#990000",
        light1: "#f20000",
        light2: "#ff1616",
        light3: "#ff4c4c",
        light4: "#ffa5a5",
        light5: "#ffc9c9",
        light6: "#ffe2e2",
        light7: "#fff0f0"
    },
    mpOrange: {
        main: "#e26100",
        light1: "#ff8529",
        light2: "#ff9546",
        light3: "#ffad71",
        light4: "#ffd6b8",
        light5: "#ffe6d4",
        light6: "#fff2e8",
        light7: "#fff2e8"
    },
    mpSand: {
        main: "#edeadd",
        light1: "#f1efe5",
        light2: "#f3f1e8",
        light3: "#f5f4ee",
        light4: "#faf9f6",
        light5: "#fcfbf9",
        light6: "#fdfdfc",
        light7: "#fefefd"
    },
    mpLYellow: {
        main: "#ffeea6",
        light1: "#fff2bc",
        light2: "#fff3c5",
        light3: "#fef6d2",
        light4: "#fffae8",
        light5: "#fffcf1",
        light6: "#fffdf7",
        light7: "#fffefb"
    },
    mpYellow: {
        main: "#eabc00",
        light1: "#fed52d",
        light2: "#fed838",
        light3: "#ffdd54",
        light4: "#ffe67f",
        light5: "#fff0b2",
        light6: "#fef4cc",
        light7: "#fffae5"
    },
    mpEGreen: {
        main: "#adce70",
        light1: "#adce70",
        light2: "#adce70",
        light3: "#adce70",
        light4: "#adce70",
        light5: "#cbe0a5",
        light6: "#e5efd2",
        light7: "#fafcf7"
    },
    mpGreen: {
        main: "#028a0f",
        light1: "#03e518",
        light2: "#11fb28",
        light3: "#48fc59",
        light4: "#a3fdac",
        light5: "#c8fecd",
        light6: "#e1fee4",
        light7: "#f0fef1"
    },
    mpTeal: {
        main: "#009285",
        light1: "#00edd7",
        light2: "#12ffe9",
        light3: "#48ffee",
        light4: "#a3fff6",
        light5: "#c8fffa",
        light6: "#e1fffc",
        light7: "#f0fefd"
    },
    mpLBlue: {
        main: "#007b9d",
        light1: "#00c0f5",
        light2: "#19cdff",
        light3: "#4ed8fe",
        light4: "#a6ebff",
        light5: "#caf3fe",
        light6: "#e2f8ff",
        light7: "#f0fbff"
    },
    mpBlue: {
        main: "#00529e",
        light1: "#007ff6",
        light2: "#1a90fe",
        light3: "#4faafe",
        light4: "#a6d4ff",
        light5: "#cae5ff",
        light6: "#e2f1ff",
        light7: "#f0f8ff"
    },
    mpDBlue: {
        main: "#00366b",
        light1: "#0068cf",
        light2: "#007df8",
        light3: "#359bff",
        light4: "#9acdff",
        light5: "#c2e1fe",
        light6: "#deefff",
        light7: "#eef7ff"
    },
    mpPurple: {
        main: "#8950FC",
        light1: "#a67bfc",
        light2: "#b28dfd",
        light3: "#c3a7fd",
        light4: "#cfb8fd",
        light5: "#ede4fe",
        light6: "#f5f1fe",
        light7: "#faf8fe"
    },
    mpLGrey: {
        main: "#404449",
        light1: "#6b727a",
        light2: "#7d848d",
        light3: "#9ba1a7",
        light4: "#cdd0d3",
        light5: "#e1e2e4",
        light6: "#e1e2e4",
        light7: "#f7f7f8"
    },
    mpDGrey: {
        main: "#091823",
        light1: "#2a70a4",
        light3: "#4595cf",
        light4: "#a2cae7",
        light5: "#c7dff0",
        light6: "#c7dff0",
        light7: "#c7dff0"
    },
};