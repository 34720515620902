import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    database: null
}

export const serverSlice = createSlice({
    name: 'server',
    initialState,
    reducers: {
        setServerDatabase(state, action){
            state.database = action.payload.database || null;
        },
    }
});