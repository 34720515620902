import * as Yup from 'yup';

import { convertApiToDate, formatDate } from '../../functions';

export const validationSchema = (() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return (
        Yup.object().shape({
            teacher: Yup.string()
                .max(50, 'Maximum 50 characters'),
            school: Yup.string()
                .max(100, 'Maximum 100 characters'),
            address: Yup.string()
                .max(200, 'Maximum 200 characters'),
            parentNotes: Yup.string()
                .max(500, 'Maximum 500 characters'),
            firstGuardianNotes: Yup.string()
                .max(500, 'Maximum 500 characters'),
            secondGuardianNotes: Yup.string()
                .max(500, 'Maximum 500 characters'),
            weight: Yup.mixed().test(
                'weightIsSelected',
                'Select a valid weight',
                (value) => value?.value !== -1
            ),
            specialNotes: Yup.string()
                .max(300, 'Maximum 300 characters'),
            specialNotes2: Yup.string()
                .max(300, 'Maximum 300 characters'),
            // thisMonthStart: Yup.mixed()
            //     .test(
            //         'thisMonthStartNot293031',
            //         'Month start dates cannot be on the 29th, 30th, or 31st',
            //         (value) => ![29, 30, 31].includes(convertApiToDate(value).getDate())
            //     ),
            timeUsedThisMonth: Yup.number()
                .typeError('Time Used This Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Max 500')
                .test(
                    'timeUsedThisMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            timeLeftThisMonth: Yup.number()
                .typeError('Time Left This Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Max 500')
                .test(
                    'timeLeftThisMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            timeGivenThisMonth: Yup.number()
                .typeError('Time Given This Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Max 500')
                .test(
                    'timeGivenThisMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            checkThisMonth: Yup.mixed().when(['timeUsedThisMonth', 'timeLeftThisMonth', 'timeGivenThisMonth'],
                ([timeUsedThisMonth, timeLeftThisMonth, timeGivenThisMonth]) => {
                    const isValid = timeUsedThisMonth + timeLeftThisMonth === timeGivenThisMonth;
                    const errorText = `Time Used and Time Left must equal Total Time.`
                    if(isValid) return Yup.mixed().test('checkThisMonth', '', () => true);
                    else return Yup.mixed().test('checkThisMonth', errorText, () => false);
                }
            ),
            reserveUsedThisMonth: Yup.number()
                .typeError('Reserve Used This Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Maximum 500')
                .test(
                    'reserveUsedThisMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            // nextMonthStart: Yup.mixed()
            //     .test(
            //         'nextMonthStartNot293031',
            //         'Month start dates cannot be on the 29th, 30th, or 31st',
            //         (value) => ![29, 30, 31].includes(convertApiToDate(value).getDate())
            //     ),
            timeUsedNextMonth: Yup.number()
                .typeError('Time Used Next Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Max 500')
                .test(
                    'timeUsedNextMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            timeLeftNextMonth: Yup.number()
                .typeError('Time Left Next Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Max 500')
                .test(
                    'timeLeftNextMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            timeGivenNextMonth: Yup.number()
                .typeError('Time Given Next Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Max 500')
                .test(
                    'timeGivenNextMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            checkNextMonth: Yup.mixed().when(['timeUsedNextMonth', 'timeLeftNextMonth', 'timeGivenNextMonth'],
                ([timeUsedNextMonth, timeLeftNextMonth, timeGivenNextMonth]) => {
                    const isValid = timeUsedNextMonth + timeLeftNextMonth === timeGivenNextMonth;
                    const errorText = `Time Used and Time Left must equal Total Time.`
                    if(isValid) return Yup.mixed().test('checkNextMonth', '', () => true);
                    else return Yup.mixed().test('checkNextMonth', errorText, () => false);
                }
            ),
            checkMonthStarts: Yup.mixed().when(['thisMonthStart', 'nextMonthStart'],
                ([thisMonthStart, nextMonthStart]) => {
                    const thisMonthStartDate = convertApiToDate(thisMonthStart);
                    const nextMonthStartDate = convertApiToDate(nextMonthStart);

                    const isValid1 = !isNaN(thisMonthStartDate.getTime()) && !isNaN(nextMonthStartDate.getTime());
                    if(!isValid1) return Yup.mixed().test('monthStartsValid', `At least one date is invalid`, () => false);
                    
                    const isValid2 = thisMonthStartDate < nextMonthStartDate;
                    if(!isValid2) return Yup.mixed().test('monthStartsValid', `This Month Start must come before Next Month Start`, () => false);
        
                    return Yup.mixed().test('monthStartsValid', '', () => true);
                }
            ),
            reserveUsedNextMonth: Yup.number()
                .typeError('Reserve Used Next Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Maximum 500')
                .test(
                    'reserveNextMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            defaultTimePerMonth: Yup.number()
                .typeError('Default Time Per Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Maximum 500')
                .test(
                    'defaultTimePerMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            thresholdTimePerMonth: Yup.number()
                .typeError('Threshold Time Per Month must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Maximum 500')
                .test(
                    'thresholdTimePerMonthIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            checkGeneral: Yup.mixed().when(['defaultTimePerMonth', 'thresholdTimePerMonth'],
                ([defaultTimePerMonth, thresholdTimePerMonth]) => {
                    const isValid = defaultTimePerMonth >= thresholdTimePerMonth;
                    const errorText = `Default Hours cannot exceed Threshold Hours.`
                    if(isValid) return Yup.mixed().test('checkGeneral', '', () => true);
                    else return Yup.mixed().test('checkGeneral', errorText, () => false);
                }
            ),
            reserveTimeLeft: Yup.number()
                .typeError('Reserve Time Left must be a number')
                .min(0, 'Minimum 0')
                .max(500, 'Maximum 500')
                .test(
                    'reserveTimeLeftIsMultipleOfHalf',
                    'Must be an increment of 0.5',
                    (value) => value % 0.5 === 0
                ),
            currentCards: Yup.number()
                .typeError('Current Cards must be a number')
                .min(0, 'Minimum 0')
                .max(10000, 'Maximum 10000')
                .integer('Must be a whole number')
                .required('Current Cards is required'),
            totalCards: Yup.number()
                .typeError('Total Cards must be a number')
                .min(0, 'Minimum 0')
                .max(10000, 'Maximum 10000')
                .integer('Must be a whole number')
                .required('Total Cards'),
            binderLocation: Yup.mixed().test(
                'binderLocationIsSeleceted',
                'Select a valid center',
                (value) => value?.value !== -1
            ),
            inactiveDate: Yup.mixed().test(
                'inactiveDateIsValid',
                'Invalid Date',
                (value) => !isNaN(new Date(value).getTime())
            ),
            monthlyReportLink: Yup.string()
                .max(300, 'Maximum 300 characters'),
            monthlyReportText: Yup.string()
                .max(3000, 'Maximum 3000 characters'),
            drsValid: Yup.mixed().when(['holdStart', 'holdEnd', 'updateHold'],
                ([holdStart, holdEnd]) => {
                    const isValid = (convertApiToDate(holdStart).getFullYear() < 3000 && convertApiToDate(holdEnd).getFullYear() < 3000);
                    const errorText = `Years must be less than 3000`
                    if(isValid) return Yup.mixed().test('drsValid', '', () => true);
                    else return Yup.mixed().test('drsValid', errorText, () => false);
                }).when(['holdStart', 'holdEnd', 'updateHold'],
                ([holdStart, holdEnd, updateHold]) => {
                    const holdStartDate = convertApiToDate(holdStart);
                    const holdEndDate = convertApiToDate(holdEnd);

                    const isValid1 = !isNaN(holdStartDate.getTime()) && !isNaN(holdEndDate.getTime());
                    if(!isValid1) return Yup.mixed().test('drsValid', `At least one date is invalid`, () => false);
                    
                    const isValid2 = !updateHold ? true : holdStartDate <= holdEndDate;
                    if(!isValid2) return Yup.mixed().test('drsValid', `Start Date must come before End Date`, () => false);

                    return Yup.mixed().test('drsValid', '', () => true);
                })
        })
    );
})();
export function getHoldLength(startDate, endDate){
    return Math.floor((new Date(endDate) - new Date(startDate)) / (24 * 60 * 60 * 1000)) + 1;
}

export const subjectOptions = [{ value: '', label: 'None' }, { value: 'Geometry', label: 'Geometry' },
{ value: 'Calculus', label: 'Calculus' }];
export function getInitSubjectOption(subject){
    const option = subjectOptions.find(s => s.value === subject);
    return option || subjectOptions[0];
}

export function getContractOption(contract){
    return {
        value: parseInt(contract.id),
        label: `${contract.type}: ${formatDate(contract.start_date)} to ${formatDate(contract.end_date)}`
    };
}

const defaultOption = { value: -1, label: 'None selected' };
export function getInitParent(parentId, parentOptions){
    const parent = parentOptions.find(p => p.value === parentId);

    return parent || defaultOption;
}
export function getInitContract(contractId, contractOptions){
    const contract = contractOptions.find(c => parseInt(c.value) === parseInt(contractId));

    return contract || defaultOption;
}


export const gradeOptions = [
    { value: -1, label: 'None'},
    { value: 0, label: 'Pre-K/Kindergarten'},
    { value: 1, label: '1'},
    { value: 2, label: '2'},
    { value: 3, label: '3'},
    { value: 4, label: '4'},
    { value: 5, label: '5'},
    { value: 6, label: '6'},
    { value: 7, label: '7'},
    { value: 8, label: '8'},
    { value: 9, label: '9'},
    { value: 10, label: '10'},
    { value: 11, label: '11'},
    { value: 12, label: '12'},
    { value: 13, label: 'HS Grad' }
];
export function getInitGradeOption(grade){
    const gradeOption = gradeOptions.find(g => parseInt(g.value) === parseInt(grade));

    return gradeOption || { value: -1, label: 'Invalid grade...' };
}

export function getSchoolYearOptions(){
    const now = new Date();
    const currentYear = (now.getFullYear()) % 100;

    const yesterYesterYear = (currentYear - 2) % 100;
    const yesterYear = (currentYear - 1) % 100;
    const tomorrowYear = (currentYear + 1) % 100;

    const twoSchoolYearsAgo = `${yesterYesterYear}-${yesterYear}`;
    const lastSchoolYear = `${yesterYear}-${currentYear}`;
    const thisSchoolYear = `${currentYear}-${tomorrowYear}`;

    return [
        { value: '', label: 'None' },
        { value: twoSchoolYearsAgo, label: twoSchoolYearsAgo },
        { value: lastSchoolYear, label: lastSchoolYear },
        { value: thisSchoolYear, label: thisSchoolYear }
    ]
}
export function getInitSchoolYearOption(year){
    const syOptions = getSchoolYearOptions();
    const syOption = syOptions.find(sy => parseInt(sy.value) === parseInt(year));

    return syOption || { value: '', label: 'None' };
}

export const weightOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' }
];
export function getInitWeightOption(weight){
    const weightOption = weightOptions.find(w => parseInt(w.value) === parseInt(weight));

    return weightOption || { value: -1, label: 'Invalid weight...' };
}

export function getInitBinderLocationOption(location, centerOptions){
    const centerOption = centerOptions.find(c => parseInt(c.value) === parseInt(location));

    return centerOption || { value: -1, label: 'Invalid center...' };
}