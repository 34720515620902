import { basicPath } from '../basicPath';

export const fetchStampsLogsIds = (params) => async (dispatch) => {
    const path = '/mp/stamps-logs/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchStampsLogsDaterange = (params) => async (dispatch) => {
    const path = '/mp/stamps-logs/daterange';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createStampsLog = (params) => async (dispatch) => {
    const path = '/mp/stamps-logs';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateStampsLog = (params) => async (dispatch) => {
    const path = '/mp/stamps-logs';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteStampsLog = (params) => async (dispatch) => {
    const path = '/mp/stamps-logs';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}