import * as Yup from 'yup';
import { convertApiToDate } from '../../functions';

export function getValidationSchema(){
    return(
        Yup.object().shape({
            selectedDate: Yup.mixed().test(
                'selectedDateIsDate',
                'Invalid date',
                (value) => !isNaN(new Date(value).getTime())
            ).test(
                'selectedDateIsValid',
                'Selected date cannot be in the future',
                (value) => {
                    const date = convertApiToDate(value);
                    const today = new Date();
                    return date <= today;
                }
            ),
            checkTimesValid: Yup.mixed().when(['timeIn', 'timeOut', 'timeOutNull'],
                ([timeIn, timeOut, timeOutNull]) => {
                    const isValid1 = !isNaN(timeIn.raw) && !isNaN(timeOut.raw);
                    if(!isValid1) return Yup.mixed().test('checkTimesValid', `At least one time is invalid`, () => false);
                    
                    const isValid2 = (timeIn.raw < timeOut.raw) || timeOutNull;
                    if(!isValid2) return Yup.mixed().test('checkTimesValid', `Time in must come before time out`, () => false);
                    
                    return Yup.mixed().test('checkTimesValid', '', () => true);
                }
            ),
        })
    );
}