import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Wait } from '../wait';

const controlClassNames = 'py-3 px-4 rounded-md bg-white border border-mpLGrey-4 w-full disabled:bg-mpLGrey-7 h-min';
function formatStr(str){
    if(!str) return '';
    return str.toLowerCase().replace(/ /g, '');
}
export function MentionsInput(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { id, as, label, placeholder, disabled, tags, triggerKeyCode, shiftKey, name, onChange, updateLastTagged } = props;
    const currentFieldValue = props.value;

    const [top, setTop] = useState(null);
    const [left, setLeft] = useState(null);

    const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentlyMatching, setCurrentlyMatching] = useState(false);
    const [matchedText, setMatchedText] = useState('');
    const [matchedSuggestions, setMatchedSuggestions] = useState([]);

    const [currentSelection, setCurrentSelection] = useState(0);
    const [previousFieldValue, setPreviousFieldValue] = useState('');
    const [startPosition, setStartPosition] = useState(0);

    const handleKeyDown = useCallback((event) => {
        (async function keyDown(){
            if(mounted.current) setPreviousFieldValue(currentFieldValue); // Save this value before it is overwritten by onChange

            const { keyCode } = event;
            if(keyCode === 16) setShiftKeyPressed(true);
        })();
    }, [currentFieldValue]);
    const handleKeyUp = useCallback((event, id) => {
        (async function keyUp(){
            if(!currentSelection && currentSelection !== 0) return;

            const { key, keyCode } = event;
            const newFieldValue = document.getElementById(id).value;

            if(mounted.current){
                if(keyCode === triggerKeyCode && (shiftKeyPressed || !shiftKey)){
                    setShowSuggestions(true);
                    setCurrentlyMatching(true);
                    setMatchedText('');
                    setMatchedSuggestions(tags);
                    
                    let newStartPosition = 0;
                    for(let i = 0; i < Math.min(previousFieldValue.length, newFieldValue.length); i++){
                        if(previousFieldValue[i] !== newFieldValue[i]){
                            newStartPosition = i;
                            break;
                        }
                    }
                    setStartPosition(newStartPosition);
                } else if(keyCode === 16){ // shift key
                    await Wait(10);
                    setShiftKeyPressed(false);
                } else if([8, 27, 37, 39].includes(keyCode) || !tags.length){ // backspace key, escape key, left key, down key
                    setShowSuggestions(false);
                    setCurrentlyMatching(false);
                } else if(keyCode === 40){ // down key
                    event.preventDefault();
                    setCurrentSelection((currentSelection + 1) % matchedSuggestions.length);
                } else if(keyCode === 38){ // up key
                    event.preventDefault();
                    setCurrentSelection(currentSelection === 0 ? matchedSuggestions.length - 1 : currentSelection - 1);
                } else if(keyCode === 13) { // enter key
                    const selectedSuggestion = matchedSuggestions[currentSelection];
    
                    event.preventDefault();
    
                    if(currentSelection !== null && selectedSuggestion){             
                        const beginning = `${newFieldValue.slice(0, startPosition)}`;
                        const middle = `!~${selectedSuggestion.value}~!`;
                        const end = `${newFieldValue.slice(startPosition + matchedText.length + 1, newFieldValue.length)}`;
                        const newText = `${beginning}${middle}${end}`;
            
                        onChange({ target: { value: newText, name: name || '' }});
                        if(typeof(updateLastTagged) === 'function') updateLastTagged(selectedSuggestion);
                    }
    
                    if(mounted.current){
                        setShowSuggestions(false);
                        setLeft(null);
                        setTop(null);
                        setStartPosition(0);
                        setCurrentSelection(0);
                        setMatchedText('');
                    }
                } else if((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90)){ // 0-9 and a-z
                    let newMatchedText = '';
                    let newMatchedSuggestions = tags;
                    if(currentlyMatching){
                        newMatchedText = matchedText + key;
                        newMatchedSuggestions = tags.filter(t => formatStr(t.value).includes(formatStr(newMatchedText)));
                    }
                    setMatchedText(newMatchedText);
                    setMatchedSuggestions(newMatchedSuggestions);
                    setCurrentSelection(0);
                }
            }
        })();
    }, [currentSelection, name, onChange, startPosition, tags, updateLastTagged, previousFieldValue,
        matchedSuggestions, currentlyMatching, matchedText, shiftKey, shiftKeyPressed, triggerKeyCode]);
    const renderSuggestions = useCallback(() => {
        if(!showSuggestions) return null;

        return(
            <div
                id="dropdown"
                className={`absolute z-[100] w-48 max-h-48 rounded-md block top-[${top}] left-[${left}] overflow-y-auto`}
                style={{ boxShadow: "rgba(0, 0, 0, 0.4) 0px 1px 4px" }}
            >
                {matchedSuggestions.map((suggestion, index) => {
                    return(
                        <div
                            className={`${index === currentSelection ? 'bg-mpLGrey-7' : 'bg-white'} px-2.5 py-5`}
                            style={{
                                padding: "10px 20px",
                            }}
                            onClick={async () => {
                                await setCurrentSelection(index);
                                handleKeyUp({ keyCode: 13, preventDefault: () => null });
                            }}
                            key={suggestion.id}
                        >
                            {suggestion.value}
                        </div>
                    );
                })}
            </div>
        );
    }, [showSuggestions, matchedSuggestions, currentSelection, handleKeyUp, left, top]);

    return (
        <div>
            {label ? <div className="text-[#0000008A] text-[10pt]">{label}</div> : null}
            {as === 'textarea' ? 
                <textarea
                    id={id}
                    className={`form-control ${controlClassNames}`}
                    name={name}
                    value={currentFieldValue}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={(e) => handleKeyUp(e, id)}
                /> :
                <input
                    id={id}
                    className={`form-control ${controlClassNames}`}
                    name={name}
                    value={currentFieldValue}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={(e) => handleKeyUp(e, id)}
                />
            }
            {renderSuggestions()}
        </div>
    )
}