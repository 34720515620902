import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Button } from '../../../../components/custom-essentials';
import { Clock } from '../../../../components/display';
import { CheckInModal } from '../../../../components/modal';
import { formatDateTime } from '../../../../components/functions';

import {
    fetchInstructorAttendanceCurrentId,
    fetchInstructorAttendanceLatestId,
} from '../../../../actions';

function CheckIn(props){
    const mounted = useRef(false);
    const [signedIn, setSignedIn] = useState(false);
    const [latestAttendance, setLatestAttendance] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState(null);

    useEffect(() => {
        mounted.current = true;

        return () => (mounted.current = false);
    });

    const { userId, refreshCount, setRefreshCount,
        fetchInstructorAttendanceCurrentId, fetchInstructorAttendanceLatestId } = props;

    const refreshData = useCallback((isOrigin = false) => {
        (async function refresh(){
            const currentAttendanceRes = await fetchInstructorAttendanceCurrentId({ userId });
            const latestAttendanceRes = await fetchInstructorAttendanceLatestId({ userId });
            const newCurrentAttendance = currentAttendanceRes.data?.[0] || null;
            const newLatestAttendance = latestAttendanceRes.data?.[0] || {};
            
            const latestAttendance = newCurrentAttendance || newLatestAttendance;
            
            if(mounted.current){
                if(isOrigin){
                    const { checkIn, attendanceHistory, timesheetTables } = refreshCount;
                    setRefreshCount({
                        checkIn,
                        attendanceHistory: attendanceHistory + 1,
                        timesheetTables: timesheetTables + 1,
                    });
                }
                if(latestAttendance.time_in && !latestAttendance.time_out){
                    setSignedIn(true);
                } else {
                    setSignedIn(false);
                }
                setLatestAttendance(latestAttendance);
            }
        })();
    }, [userId, refreshCount, setRefreshCount, fetchInstructorAttendanceCurrentId, fetchInstructorAttendanceLatestId]);
    useEffect(() => {
        // Monitor for changes on the other two components
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCount.checkIn]);

    function handleSignIn(){
        setShowModal(true);
        setModalMode('in');
    }
    function handleSignOut(){
        setShowModal(true);
        setModalMode('out');
    }
    function onSubmitCallback(changes = false){
        setShowModal(false);
        setModalMode(null);

        if(changes) refreshData(true);
    }

    return (
        <div>
            <h2>Welcome, {props.auth.userName}!</h2>
            <br/>
            <h4>The current time is <Clock skipSeconds={false}/>.</h4>
            <br/>
            { signedIn ?
                <h4>Signed in since: {latestAttendance.time_in ? formatDateTime(latestAttendance.time_in) : 'Never'}</h4> : 
                <h4>Last sign out: {latestAttendance.time_out ? formatDateTime(latestAttendance.time_out) : 'Never'}</h4>
            }
            <br/>
            {
                signedIn ? (
                    <Button
                        color="lte-mpLRed"
                        onClick={handleSignOut}
                    >
                        Sign Out
                    </Button>
                ) : (
                    <Button
                        color="lte-mpLBlue"
                        onClick={handleSignIn}
                    >
                        Sign In
                    </Button>
                )
            } { showModal &&
                <CheckInModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
        </div>
    );
}

export default connect(state => ({ auth: state.auth }), {
    fetchInstructorAttendanceCurrentId,
    fetchInstructorAttendanceLatestId,
})(CheckIn);