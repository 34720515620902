import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, SelectSingle, FormikControl, checkResponses } from '../../../../components/form';
import { formatDateApi } from '../../../../components/functions';
import { PaymentsModal } from '../../../../components/modal';
import { TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import PaymentsTable from './payments/PaymentsTable';

import {
    fetchPaymentsDaterangeCenter,
    fetchStudentsEssential
} from '../../../../actions';


const start = new Date();
start.setMonth(start.getMonth() - 1);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'studentName', label: 'Student Name' },
    { value: 'notes', label: 'Notes' },
];

function Payments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [payments, setPayments] = useState([]);
    const [paymentSum, setPaymentSum] = useState(0);
    // Form
    const [drsValid, setDrsValid] = useState(true);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    function onSubmitCallback(changes = false){
        setModalMode(null);
        if(changes) refreshData();
    }
    
    const { centerOptions, loading, setLoading, setNPayments, fetchPaymentsDaterangeCenter,
        fetchStudentsEssential } = props;

    function filterPayments(unfilteredPayments, filterType, filterQuery){
        filterQuery = filterQuery.replace(/ /g, '').toLowerCase();
        const checkStudentNames = (p) => p.studentName.replace(/ /g, '').toLowerCase().includes(filterQuery);
        const checkNotes = (p) => p.notes.toLowerCase().includes(filterQuery);

        let newPayments = unfilteredPayments.filter(p => {
            switch(filterType.value){
                case 'all':
                    return checkStudentNames(p) || checkNotes(p);
                case 'studentName':
                    return checkStudentNames(p);
                case 'notes':
                    return checkNotes(p);
                default:
                    return false;
            }
        });

        let newPaymentSum = 0;
        newPayments.forEach(p => newPaymentSum += parseInt(p.amount));

        if(mounted.current){
            setFilteredPayments(newPayments);
            setPaymentSum(newPaymentSum);
            setHasLoaded(true);
        }
    };
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading.payments || !drsValid || !formRef.current?.values) return;
            if(mounted.current) setLoading({ ...loading, payments: true });
            const { startDate, endDate, selectedCenter, filterType, filterQuery } = formRef.current.values;
    
            // Get payments
            const paymentsRes = await fetchPaymentsDaterangeCenter({
                startDate: startDate,
                endDate: endDate,
                center: selectedCenter.value
            });
            const studentsRes = await fetchStudentsEssential();
            const isApiError = checkResponses(paymentsRes, studentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading({ ...loading, payments: false });
                }
                return;
            } else setApiError(false);

            const newPayments = paymentsRes.data?.payments || [];
            const newContracts = paymentsRes.data?.contracts || [];
            const newStudents = studentsRes.data || [];
            
            // Get relevant contracts and students

            
            const contractMap = {};
            newContracts.forEach(c => contractMap[parseInt(c.id)] = c);
            const studentToNameMap = {};
            newStudents.forEach(s => studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const paymentsAppended = newPayments.map(p => {
                p.contractDetails = contractMap[parseInt(p.contract)] || {};
                p.studentName = studentToNameMap[p.contractDetails.student] || `Unknown student (UID: ${p.contractDetails.student})`;
                return p;
            });
    
            if(mounted.current){
                setPayments(paymentsAppended);
                setNPayments(paymentsAppended.length);
                filterPayments(newPayments, filterType, filterQuery);
                setLoading({ ...loading, payments: false });
            }
        })()
    }, [loading, setLoading, formRef, drsValid, setNPayments, fetchPaymentsDaterangeCenter, fetchStudentsEssential]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {modalMode && 
                <PaymentsModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Formik
                enableReinitialize
                initialValues={{
                    startDate: startApi,
                    endDate: endApi,
                    selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                    filterType: { value: 'all', label: 'All' },
                    filterQuery: '',
                }}
                innerRef={formRef}
                onSubmit={refreshData}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <h2>Payments ({payments.length})</h2>
                        <br/>
                        <div className="flex flex-row gap-x-4 items-center">
                            <div className="grid grid-cols-1 gap-y-2">
                                <DateRangeSelector
                                    id="payments-drs-1"
                                    startName="startDate"
                                    endName="endDate"
                                    startLabel="Start Date"
                                    endLabel="End Date"
                                    startValue={formik.values.startDate}
                                    endValue={formik.values.endDate}
                                    defaultValid={true}
                                    onStartChange={formik.handleChange}
                                    onEndChange={formik.handleChange}
                                    onChangeValidation={setDrsValid}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                <SelectSingle
                                    id="payments-selectedCenter"
                                    name="selectedCenter"
                                    label="Center"
                                    value={formik.values.selectedCenter}
                                    onChange={formik.handleChange}
                                    options={centerOptions}
                                />
                            </div>
                            <div className="flex">
                                <Button
                                    color="lte-mpTeal"
                                    onClick={formik.handleSubmit}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>

                        <br/>
                        
                        {apiError ? <div className="text-mpLRed">{apiError}</div> :
                            <>
                                <hr/>
                                <br/>

                                <h2>Filter Results ({filteredPayments.length} payments, ${paymentSum})</h2>
                                
                                <br/>

                                <div className="flex flex-row gap-x-4 items-center">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <SelectSingle
                                            id="payments-searchType"
                                            name="filterType"
                                            label="Filter by"
                                            value={formik.values.filterType}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterPayments(payments, e.target.value, formik.values.filterQuery);
                                            }}
                                            options={filterTypeOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <FormikControl
                                            id="flag-manager-searchQuery"
                                            name="filterQuery"
                                            placeholder="Enter a filter query..."
                                            value={formik.values.filterQuery}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterPayments(payments, formik.values.filterType, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="flex">
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setModalMode('create')}
                                        >
                                            + New Payment
                                        </Button>
                                    </div>
                                    <div className="flex ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    <div>
                                                        What gets exported?
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        All payments that are currently filtered ({filteredPayments.length} items).
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <CSVExport
                                                title="Payments"
                                                label="Export Payments to CSV"
                                                data={payments}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                            </>
                        }
                        
                    </form>
                )}
            </Formik>

            <br/>

            {hasLoaded && 
                <PaymentsTable
                    tableName="payments"
                    payments={filteredPayments}
                    refreshData={refreshData}
                />
            }
        </div>
    );
}

export default connect(null, {
    fetchPaymentsDaterangeCenter,
    fetchStudentsEssential
})(Payments);