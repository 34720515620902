import { basicPath } from '../basicPath';

export const fetchLearningPlanItemsStudentDaterangeCompleted = (params) => async (dispatch) => {
    const path = '/mp/learning-plan-items/student-daterange-completed';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateLearningPlanItems = (params) => async (dispatch) => {
    const path = '/mp/learning-plan-items';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}