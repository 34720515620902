import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { Tab, Tabs } from '@mui/material';

import { Button, Label, TabPanel } from '../../../../components/custom-essentials';
import { DateRangeSelector, Switch, Check, checkResponses } from '../../../../components/form';
import { convertApiToDate, formatDateApi, formatTime } from '../../../../components/functions';
import { DetailsModal, StampsModal } from '../../../../components/modal';
import { Wait } from '../../../../components/wait';
import AATabContent from './assignedAppointments/AATabContent';

import {
    fetchAppointmentsDaterange,
    fetchStudentsAll,
    fetchUpcomingExamsAll,
    fetchAdminUsersAll,
    fetchFlagsStatus,
    updateAppointmentStatus
} from '../../../../actions';

const start = new Date();
start.setDate(start.getDate() - 7);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

const cookies = new Cookies();

async function startTimer(nextTime, setNextTime, mounted){
    while(true){
        const now = new Date();
        
        const hours = now.getHours() + (now.getMinutes() < 30 ? 0 : 1);
        const minutes = now.getMinutes() < 30 ? 30 : 0;
        const newNextTime = hours * 60 + minutes * 1;
        
        if(mounted.current){
            if(newNextTime !== nextTime){
                nextTime = newNextTime;
                setNextTime(newNextTime);
            }
        }
        else break;

        await Wait(30000);
    }
}

function AssignedAppointments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [apiError, setApiError] = useState(false);
    // DateRangeSelector
    const [drsValid, setDrsValid] = useState(true);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    // Data and Form
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState({ dateKeys: [] });
    const [appointmentInstructorMap, setAppointmentInstructorMap] = useState({});

    const [activeTabKey, setActiveTabKey] = useState(0);

    // Render 'start time' and 'end time' columns with red highlight for upcoming entries/exits
    const [nextTime, setNextTime] = useState((new Date()).getHours() * 60 + (new Date()).getMinutes() * 1);
    useEffect(() => {
        startTimer(nextTime, setNextTime, mounted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Get and sort data
    const { loading, setLoading, parentApiError, refreshRelInfo, centerMap, members,
        fetchAppointmentsDaterange, fetchStudentsAll, fetchUpcomingExamsAll,
        fetchAdminUsersAll, updateAppointmentStatus, fetchFlagsStatus } = props;

    const filterAppointments = useCallback((appointments, appointmentInstructorMap, showAll, hideCancelled) => {
        const aToken = cookies.get('aToken');
        const tokenPayload = jwt_decode(aToken);
        const userId = tokenPayload.userId;

        const showAllTrue = showAll === 'true' || showAll === true;
        const hideCancelledTrue = hideCancelled === 'true' || hideCancelled === true;
        const filteredAppointments = appointments.filter(a => {
            return showAllTrue || (appointmentInstructorMap[parseInt(a.id)] || []).includes(userId);
        }).filter(a => {
            return !hideCancelledTrue || !['Cancelled'].includes(a.status);
        });

        const dateKeys = [];
        const filteredAppointmentsFormatted = {};
        filteredAppointments.forEach(apt => {
            // Append properties needed for rendering
            apt.dateTime = new Date(apt.date_time);
            apt.startTime = apt.dateTime.getHours() * 60 + apt.dateTime.getMinutes() * 1;
            apt.endTime = apt.startTime + parseInt(apt.duration);

            // Group appointments by date
            const dateKey = formatDateApi(apt.dateTime).replace(/-/g, '_');
            if(!filteredAppointmentsFormatted[dateKey]) filteredAppointmentsFormatted[dateKey] = [];
            filteredAppointmentsFormatted[dateKey].push(apt);
            
            // Store dateKeys to easily display appointment tabs in order during rendering
            if(!dateKeys.includes(dateKey)) dateKeys.push(dateKey);
            dateKeys.sort((a, b) => (new Date(b.replace(/_/g, '-')) - new Date(a.replace(/_/g, '-'))));
        });
        filteredAppointmentsFormatted.dateKeys = dateKeys;

        setFilteredAppointments(filteredAppointmentsFormatted);
    }, [])
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(!drsValid || loading) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate, showAll, hideCancelled } = formRef.current.values;
    
            const aaRes = await fetchAppointmentsDaterange({
                startDate: convertApiToDate(startDate),
                endDate: convertApiToDate(endDate)
            });
            const studentsRes = await fetchStudentsAll();
            const upcomingExamsRes = await fetchUpcomingExamsAll();
            const employeesRes = await fetchAdminUsersAll();
            const pendingFlagsRes = await fetchFlagsStatus({ status: 'Pending' });
            const isApiError = checkResponses(studentsRes, upcomingExamsRes, employeesRes, pendingFlagsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointments = aaRes.data?.appointments || [];
            const newInstructorAssignments = aaRes.data?.assignments || [];
            const newStudents = studentsRes.data || [];
            const newUpcomingExams = upcomingExamsRes.data || [];
            const newEmployees = employeesRes.data || [];
            const newFlags = pendingFlagsRes.data || [];
            
            const userToNameMap = {};
            newEmployees.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            members.forEach(m => userToNameMap[m.id] = `${m.first_name} ${m.last_name}`);
            const appointmentToInstructorNameMap = {};
            const appointmentToInstructorIdMap = {};
            newInstructorAssignments.forEach(ia => {
                const aptId = parseInt(ia.appointment_id);
    
                const instructorName = userToNameMap[ia.instructor] || `Unknown instructor (ID: ${ia.instructor})`;
                if(!appointmentToInstructorNameMap[aptId]) appointmentToInstructorNameMap[aptId] = instructorName;
                else appointmentToInstructorNameMap[aptId] += `, ${instructorName}`;
    
                const instructorId = ia.instructor;
                if(!appointmentToInstructorIdMap[aptId]) appointmentToInstructorIdMap[aptId] = [instructorId];
                else appointmentToInstructorIdMap[aptId].push(instructorId);
            });

            const upcomingExamsMap = {};
            newUpcomingExams.forEach(ue => upcomingExamsMap[ue.student] = ue);
            newStudents.forEach(s => s.upcomingExams = upcomingExamsMap[s.user_id]);
    
            const studentToNameMap = {};
            const studentToObjectMap = {};
            newStudents.forEach(s => {
                studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`;
                studentToObjectMap[s.user_id] = s;
            });

            const appointmentToFlagMap = {};
            newFlags.forEach(f => {
                f.createdByName = userToNameMap[f.created_by];
                f.updatedByName = userToNameMap[f.updated_by];
                f.centerName = centerMap[f.center];
                // Only append the most recent flag (already sorted by date desc)
                if(!appointmentToFlagMap[f.student]) appointmentToFlagMap[f.student] = f;
            });
            
            const parentToObjectMap = {};
            members.filter(m => m.mpPermissions = 'Parent').forEach(p => parentToObjectMap[p.id] = p);
            
            const appointmentsAppended = newAppointments.map(a => {
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
                a.studentName = studentToNameMap[a.student] || `Unknown student (UID: ${a.student})`;
                a.instructorNames = appointmentToInstructorNameMap[parseInt(a.id)] || 'None';
                a.studentInfo = studentToObjectMap[a.student] || {};
                a.end = parseInt(a.time) + parseInt(a.duration);
                a.parentObject = parentToObjectMap[a.parent] || {};
                a.createdByName = userToNameMap[a.created_by] || `Unknown user (UUID: ${a.created_by})`;
                a.updatedByName = userToNameMap[a.updated_by] || `Unknown user (UUID: ${a.updated_by})`;
                a.pendingFlag = appointmentToFlagMap[a.student] || {};
                return a;
            });

            
            if(mounted.current) {
                setAppointments(appointmentsAppended);
                setAppointmentInstructorMap(appointmentToInstructorIdMap);
                filterAppointments(appointmentsAppended, appointmentToInstructorIdMap, showAll, hideCancelled);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, centerMap, members, drsValid, formRef, fetchAppointmentsDaterange, fetchFlagsStatus,
        fetchStudentsAll, fetchUpcomingExamsAll, fetchAdminUsersAll, filterAppointments]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Modal
    // "Loading" would not update properly without useCallback, resulting in the function only executing once
    // 5/28/22: Could not get loading to update properly as a dependency, causing the buttons not to work. Removed 'loading' check
    const handleShowModal = useCallback((mode, item) => {
        // if(loading) return;
        setModalMode(mode);
        setSelectedItem(item);
    }, [])
    const onSubmitCallback = useCallback((changes = false) => {
        setModalMode(null);
        setSelectedItem(null);
        if(changes) refreshData();
        setLoading(false);
    }, [refreshData, setLoading])
    const setInProgress = useCallback((appointmentId) => {
        (async function(){
            // if(loading) return;
            setLoading(true);
    
            const updateParams = {
                id: appointmentId,
                status: 'In Progress'
            };
            const response = await updateAppointmentStatus(updateParams);
            const isApiError = checkResponses(response);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error reaching the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);
    
            if(mounted.current){
                refreshData();
                setLoading(false);
            }
        })()
    }, [setLoading, refreshData, updateAppointmentStatus]);

    let tabIndex = 0;
    return (
        <div>
            <h2>Assigned Appointments</h2>

            <br/>

            {nextTime !== null &&
                <>
                    <h4>Next time block begins at: {formatTime(nextTime)}</h4>
                    <div>
                        Upcoming events will have their times highlighted in&nbsp;
                        <Label color="mpLRed">red</Label>
                    </div>
                    <br/>
                </>
            }

            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        showAll: false,
                        hideCancelled: true
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                        <div className="flex flex-row gap-x-4 items-center">
                            <div>
                                <DateRangeSelector
                                    id="assigned-appointments-drs"
                                    startName="startDate"
                                    endName="endDate"
                                    startLabel="Start Date"
                                    endLabel="End Date"
                                    startValue={formik.values.startDate}
                                    endValue={formik.values.endDate}
                                    defaultValid={true}
                                    onStartChange={formik.handleChange}
                                    onEndChange={formik.handleChange}
                                    onChangeValidation={setDrsValid}
                                />
                            </div>
                            <div>
                                <Button
                                    color="lte-mpTeal"
                                    onClick={formik.handleSubmit}
                                >
                                    Search
                                </Button>
                            </div>
                            <div>
                                <Switch
                                    id="assigned-appointments-show-all"
                                    name="showAll"
                                    label="Show All"
                                    color="mpLRed"
                                    checked={formik.values.showAll}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        filterAppointments(appointments, appointmentInstructorMap,
                                            e.target.value, formik.values.hideCancelled);
                                    }}
                                />
                            </div>
                            <div>
                                <Check
                                    id="assigned-appointments-hideCancelled"
                                    name="hideCancelled"
                                    label="Hide Cancelled"
                                    color="mpLRed"
                                    checked={formik.values.hideCancelled}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        filterAppointments(appointments, appointmentInstructorMap,
                                            formik.values.showAll, e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            </div>

            <br/>

            {parentApiError || apiError ? <div className="text-mpLRed">{parentApiError || apiError}</div> : 
                <>
                    <Tabs
                        id="assignedAppointments"
                        value={activeTabKey}
                        variant="scrollable"
                        onChange={(e, newValue) => setActiveTabKey(newValue)}
                    >
                        {filteredAppointments.dateKeys.map(dateKey => {
                            const relAppointments = filteredAppointments[dateKey];
                            const [year, month, day] = dateKey.split('_');
                            return(<Tab label={`${month}/${day}/${year} (${relAppointments.length})`}/>);
                        })}    
                    </Tabs>
                    {filteredAppointments.dateKeys.map(dateKey => {
                        const relAppointments = filteredAppointments[dateKey];
                        return (
                            <TabPanel activeKey={activeTabKey} index={tabIndex}>
                                <AATabContent
                                    tableName={`aaTabContent-${tabIndex++}`}
                                    appointments={relAppointments}
                                    studentData={props.studentData}
                                    centerData={props.centerData}
                                    nextTime={nextTime}
                                    setInProgress={setInProgress}
                                    handleShowModal={handleShowModal}
                                    refreshRelInfo={refreshRelInfo}
                                />
                            </TabPanel>
                        );
                    })}
                </>
            }
            { modalMode === 'stamps' ?
                <StampsModal
                    selectedAppointment={selectedItem}
                    onSubmitCallback={onSubmitCallback}
                /> : null
            } { modalMode === 'details' ?
                <DetailsModal
                    selectedAppointment={selectedItem}
                    onSubmitCallback={onSubmitCallback}
                /> : null
            }
        </div>
    );
}

export default connect(null, {
    fetchAppointmentsDaterange,
    fetchStudentsAll,
    fetchUpcomingExamsAll,
    fetchAdminUsersAll,
    fetchFlagsStatus,
    updateAppointmentStatus
})(AssignedAppointments);