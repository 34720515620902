import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import ContractModalBodyFooter from './ContractsBF';
import { checkResponse } from '../../form';

import {
    fetchStudentsAll,
    fetchContractsIds,
    createContract,
    updateContract,
    deleteContract
} from '../../../actions';

function ContractsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    
    const [contract, setContract] = useState({});
    // Only to be used to display the notification in edit mode ("This is the studen'ts current contract")
    const [currentContractId, setCurrentContractId] = useState(null);
    const [studentOptions, setStudentOptions] = useState([]);
    const [student, setStudent] = useState({});

    // selectedStudent is passed from the 'create-special' mode, activated on the warnings tab of the Contracts page
    // The only difference from 'create' is that the student should be pre-initialized
    const { mode, selectedContract, selectedStudent, onSubmitCallback, fetchContractsIds,
        fetchStudentsAll, createContract, updateContract, deleteContract } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            const newStudents = studentsRes.data || [];
            const newStudentOptions = newStudents.filter(s => {
                return (parseInt(s.mp_active) === 1 && parseInt(s.is_mp_student) === 1) || selectedContract?.student === s.user_id;
            }).map(s => ({ value: s.user_id, label: `${s.first_name} ${s.last_name}` }));

            if(!['create', 'create-special'].includes(mode)){
                const contractRes = await fetchContractsIds({ ids: [selectedContract.id] });
                const newContract = contractRes.data?.[0] || {};
                const relStudent = newStudentOptions.find(s => s.value === newContract.student);
                newContract.studentName = relStudent ? relStudent.label : `Unknown student (UID: ${newContract.student})`;
                const newCurrentContractId = newStudents.find(s => s.user_id === selectedContract.student)?.mp_current_contract || null;

                if(mounted.current){
                    setContract(newContract);
                    setCurrentContractId(newCurrentContractId);
                }
            }

            if(mode === 'create-special'){
                const newStudent = newStudents.find(s => s.user_id === selectedStudent.user_id) || {};

                if(mounted.current) setStudent(newStudent);
            }


            if(mounted.current){
                setLoaded(true);
                setStudentOptions(newStudentOptions);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);
            
            const nss = { errored: false, completed: false };
            if(['create', 'create-special', 'edit'].includes(mode)){
                const contractParams = {
                    student: values.student.value,
                    setAsCurrent: values.setAsCurrent && values.type.value !== 'Extra' ? 1 : 0,
                    type: values.type.value,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    expires: values.expires ? 1 : 0,
                    paymentFrequency: values.type.value === 'Extra' ? "One Time" : values.paymentFrequency.value,
                    paymentDateOffset: values.type.value === 'Extra' ? 0 : values.paymentDateOffset,
                    initialPayment: values.initialPayment,
                    amountPerPayment: values.type.value === 'Extra' ? 0 : values.amountPerPayment,
                    notes: values.notes,
                    showWarnings: values.showWarnings ? 1 : 0,
                    warnMembers: values.warnMembers ? 1 : 0,
                };
                if(['create', 'create-special'].includes(mode)){
                    nss.createContract = { name: 'Create contract', completed: false, message: '' };
                    setSubmissionStatus({...nss});

                    const ccResponse = await createContract(contractParams);
                    nss.createContract.completed = true;
                    nss.errored = !checkResponse(ccResponse, mounted, (resp) => nss.createContract.message = resp) || nss.errored;
                    setSubmissionStatus({...nss});
                } else if(mode === 'edit') {
                    nss.updateContract = { name: 'Update contract', completed: false, message: '' };
                    setSubmissionStatus({...nss});

                    contractParams.id = contract.id;
                    const ucResponse = await updateContract(contractParams);
                    nss.updateContract.completed = true;
                    nss.errored = !checkResponse(ucResponse, mounted, (resp) => nss.updateContract.message = resp) || nss.errored;
                    setSubmissionStatus({...nss});
                }
            } else if(mode === 'delete') {
                nss.deleteContract = { name: 'Delete contract', completed: false, message: '' };
                setSubmissionStatus({...nss});

                const ucResponse = await deleteContract({ id: contract.id });
                nss.deleteContract.completed = true;
                nss.errored = !checkResponse(ucResponse, mounted, (resp) => nss.deleteContract.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus(`One or more errors occurred during submission.`);
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [handleClose, contract, mode, createContract, updateContract, deleteContract, oneSuccess]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Contract Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <ContractModalBodyFooter
                    mode={mode}
                    selectedStudent={student}
                    selectedContract={contract}
                    currentContractId={currentContractId}
                    studentOptions={studentOptions}
                    loaded={loaded}
                    submitted={submitted}
                    submissionStatus={submissionStatus}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsAll,
    fetchContractsIds,
    createContract,
    updateContract,
    deleteContract
})(ContractsModal);