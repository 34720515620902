import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumns } from './table/getColumns';
import { Table } from '../../../../components/table';
import { FlagModal } from '../../../../components/modal';

import {

} from '../../../../actions';

const defaultSorted = { dataField: 'date_submitted', order: 'asc' };

function FlagsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedFlag, setSelectedFlag] = useState(null);

    const { refreshData, flags, tableName } = props;
    const handleShowModal = useCallback((mode, flag) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedFlag(flag);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedFlag(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumns(handleShowModal), [handleShowModal]);

    return (
        <>
            <Table
                tableName={tableName}
                data={flags}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No flags were found"
            />
            { modalMode &&
                <FlagModal
                    mode={modalMode}
                    selectedFlag={selectedFlag}
                    onSubmitCallback={onSubmitCallback}
                />
            }
        </>
    );
}

export default connect(null, {

})(FlagsTable);