import { basicPathDownloadND } from '../basicPathND';

export const fetchGraphicND = async (params) => {
    const path = '/mp-files';
    const reqType = 'get';

    const fileParams = {
        fileInfo: {
            fileName: params.fileName,
            type: 'graphic'
        }
    };
    return await basicPathDownloadND(fileParams, path, reqType);
}