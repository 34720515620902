import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumnsWarnings } from '../table/getColumns';
import { Table } from '../../../../../components/table';
import { ContractsModal } from '../../../../../components/modal';

const defaultSorted = { dataField: 'warningType', order: 'desc' };

function WarningsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [modalMode, setModalMode] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedContract, setSelectedContract] = useState(null);

    const { students, refreshData } = props;

    const handleShowModalSpecialCreate = useCallback((mode, student) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedStudent(student);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedStudent(null);
            setSelectedContract(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumnsWarnings(handleShowModalSpecialCreate),
        [handleShowModalSpecialCreate]);

    return (
        <div>
            {modalMode === 'create-special' && 
                <ContractsModal
                    mode="create-special"
                    selectedStudent={selectedStudent}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {modalMode === 'edit' && 
                <ContractsModal
                    mode="edit"
                    selectedContract={selectedContract}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="warnings"
                data={students}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No warnings!"
            />
        </div>
    );
}

export default connect(null, {

})(WarningsTable);