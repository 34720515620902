import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function MenuDropdown(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { active, label, items, handleClick } = props;

    const itemsSorted = items.sort((a, b) => a.order - b.order);

    let index = 0;
    return (
        <>
            <div 
                className="text-white self-center duration-300 cursor-pointer hover:text-mpDBlue-5
                    hover:bg-mpDGrey-1 rounded-lg px-3 py-2"
                onClick={handleClick}
            >
                {label}
            </div>
            <div className={`${active ? '' : 'hidden'} absolute top-14 flex items-center grid grid-cols-1`} onClick={(e) => e.stopPropagation}>
                {itemsSorted.map(i => {
                    const { label, to } = i;
                    return (
                        <div
                            key={`nav-menu-${label}-${index++}`}
                            className='bg-black text-white p-1 min-w-48 flex items-center'
                        >
                            <Link className="flex" to={to}>
                                <div
                                    className="rounded-lg px-3 py-2 mx-1 my-1 hover:text-mpDBlue-5 hover:bg-mpDGrey-1 w-full"
                                >
                                    {label}
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default connect(null, {

})(MenuDropdown);