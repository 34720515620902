import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { generateStringBlocks, openPopup, downloadFile, convertApiToDate, formatDate,
    formatDateFull, formatTime, getymd, getymdhms, appendLogo } from '../../../../../components/functions';

export async function generateStandard(values, newAppointments, logoData, quickDownload, quickDownloadDate){
    const { selectedStudent, startDate, endDate } = values;
    const filteredAppointments = newAppointments.filter(a => {
        return a.status === 'Completed'
    }).sort((a, b) => {
        const aDate = new Date(a.date_time);
        const bDate = new Date(b.date_time);
        if(aDate - bDate === 0){
            return parseInt(a.time) - parseInt(b.time);
        }
        return aDate - bDate;
    });

    const studentObj = selectedStudent.obj;
    const studentName = `${studentObj.first_name} ${studentObj.last_name}`;

    let reportPdf = await PDFDocument.create();
    const helveticaFont = await reportPdf.embedFont(StandardFonts.Helvetica);

    let currentPage = reportPdf.addPage();

// A - TOTAL STAT TRACKERS //
    let totalPagesCompleted = 0;
    const allLessonsCompleted = [];

// B - MASTER VARIABLES //
    const { width, height } = currentPage.getSize();
    // width 595.28 = 70.03 pt per inch, height 841.89 = 76.55 pt per inch
    // 44 quarter inches (11 inches) tall, 34 quarter inches (8 1/2 inches) wide
    const yMarginQuarterInches = 3;
    const yMin = height * (yMarginQuarterInches/44) + 60; // + 60 so that the logo on the bottom left does not collide with text
    const yMax = height - (height * (yMarginQuarterInches/44));
    const xMarginQuarterInches = 3;
    const xMin = width * (xMarginQuarterInches/34);
    const xMax = width - (width * (xMarginQuarterInches/34));

    let yPos = yMax;

    const textMaxWidth = xMax - xMin;
    
// 0 - HEADERS //
// 0a - Header: Student
    const studentHeaderText = `Progress Report for ${studentName}`;
    const studentHeaderSize = 20;
    const studentHeaderWidth = helveticaFont.widthOfTextAtSize(studentHeaderText, studentHeaderSize);
    const studentHeaderHeight = helveticaFont.heightAtSize(studentHeaderSize);
    const studentHeaderXPos = xMin + ((xMax - xMin - studentHeaderWidth) / 2);
    if(yPos - studentHeaderHeight < yMin){
        currentPage = reportPdf.addPage();
        yPos = yMax;
    }
    currentPage.drawText(studentHeaderText, {
        x: studentHeaderXPos,
        y: yPos,
        size: studentHeaderSize,
        font: helveticaFont
    });
    yPos -= (studentHeaderHeight + 5);

// 0b - Header: Dates
    const startDateFormatted = formatDate(convertApiToDate(startDate));
    const endDateFormatted = formatDate(convertApiToDate(endDate));
    const dateHeaderText = `${startDateFormatted} to ${endDateFormatted}`;
    const dateHeaderSize = 15;
    const dateHeaderWidth = helveticaFont.widthOfTextAtSize(dateHeaderText, dateHeaderSize);
    const dateHeaderHeight = helveticaFont.heightAtSize(dateHeaderSize);
    const dateHeaderXPos = xMin + ((xMax - xMin - dateHeaderWidth) / 2);
    if(yPos - dateHeaderHeight < yMin){
        currentPage = reportPdf.addPage();
        yPos = yMax;
    }
    currentPage.drawText(dateHeaderText, {
        x: dateHeaderXPos,
        y: yPos,
        size: dateHeaderSize,
        font: helveticaFont
    });
    yPos -= (dateHeaderHeight + 5);

// 0c - Header: Appointment Count
    let totalHours = 0;
    filteredAppointments.forEach(a => totalHours += (parseInt(a.duration) / 60));

    const aptHeaderText = `${filteredAppointments.length} sessions (${totalHours} hours)`;
    const aptHeaderSize = 15;
    const aptHeaderWidth = helveticaFont.widthOfTextAtSize(aptHeaderText, aptHeaderSize);
    const aptHeaderHeight = helveticaFont.heightAtSize(aptHeaderSize);
    const aptHeaderXPos = xMin + ((xMax - xMin - aptHeaderWidth) / 2);
    if(yPos - aptHeaderHeight < yMin){
        currentPage = reportPdf.addPage();
        yPos = yMax;
    }
    currentPage.drawText(aptHeaderText, {
        x: aptHeaderXPos,
        y: yPos,
        size: aptHeaderSize,
        font: helveticaFont
    });
    yPos -= (aptHeaderHeight + 25);

    for(let a of filteredAppointments){
        const itemsToDraw = [];
    // 0a - Total stat tracking
        totalPagesCompleted += parseInt(a.n_pages);
        
        // 1 - Appointment Header
        const [year, month, date, hours, minutes] = getymdhms(new Date(a.date_time));
        const aptDateApi = `${year}-${month}-${date} 00:00:00`;
        const startTime = hours * 60 + minutes * 1;
        const endTime = startTime + parseInt(a.duration);
        const ahSize = 15;
        itemsToDraw.push({
            xOffset: 0,
            text: `${formatDateFull(aptDateApi)}, ${formatTime(startTime)} to ${formatTime(endTime)}`,
            size: ahSize,
            height: helveticaFont.heightAtSize(ahSize),
            afterMargin: 10,
        });

    // 2 - Work Completed
        const wcSize = 12;
        itemsToDraw.push({
            xOffset: 10,
            text: `Pages Completed: ${a.n_pages}`,
            size: wcSize,
            height: helveticaFont.heightAtSize(wcSize),
            afterMargin: 10,
        });

    // 3 - Lessons Completed=
        allLessonsCompleted.push(...a.lessonsCompleted);

        const lcStringBlocks = a.lessonsCompleted || [];
        const showLCSection = lcStringBlocks.length ? true : false;

        if(showLCSection){
            // a: header
            const lcHeadSize = 12;
            const lcSize = 12;
            itemsToDraw.push({
                xOffset: 10,
                text: `Names of Lessons Completed:`,
                size: lcHeadSize,
                height: helveticaFont.heightAtSize(lcHeadSize),
                afterMargin: 8
            });
            
            // b: body
            for(let block of lcStringBlocks){
                itemsToDraw.push({
                    xOffset: 20,
                    text: block,
                    size: lcSize,
                    height: helveticaFont.heightAtSize(lcSize),
                    afterMargin: 3
                });
            }

            itemsToDraw.push({
                afterMargin: 10
            })
        }

    // 4 - Notes
        const snXOffset = 10;
        const snText = a.session_notes || 'None';
        const snSize = 12;
        const snHeight = helveticaFont.heightAtSize(snSize);
        const snAfterMargin = 2;
        // If notes are too long, will need to wrap
        const snFirstText = 'Session Notes: ';
        const snFirstTextWidth = helveticaFont.widthOfTextAtSize(snFirstText, snSize);
        const snTextMaxWidth = textMaxWidth - snXOffset;
        const snStringBlocks = generateStringBlocks(helveticaFont, snSize, snText, snTextMaxWidth, { beforeText: snFirstText });

        // Add additional text separately so it doesn't turn orange
        itemsToDraw.push({
            xOffset: snXOffset,
            text: snFirstText,
            size: snSize,
            height: 0,
        });
        itemsToDraw.push({
            xOffset: 10 + snFirstTextWidth,
            text: snStringBlocks[0],
            color: snText === 'None' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0), // Orange or black
            size: snSize,
            height: snHeight,
            afterMargin: snAfterMargin
        });
        for(let i = 1; i < snStringBlocks.length; i++){
            itemsToDraw.push({
                xOffset: 10,
                text: snStringBlocks[i],
                color: snText === 'None' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0), // Orange or black
                size: snSize,
                height: snHeight,
                afterMargin: snAfterMargin
            });
        }
        itemsToDraw.push({
            afterMargin: 10
        });

    // 5 - Instructors
        const aiXOffset = 10;
        const aiText = a.assignedInstructorNames || 'None';
        const aiSize = 12;
        const aiHeight = helveticaFont.heightAtSize(aiSize);
        const aiAfterMargin = 2;
        // If instructor list is too long, will need to wrap
        const aiFirstText = 'Instructors: ';
        const aiFirstTextWidth = helveticaFont.widthOfTextAtSize(aiFirstText, aiSize);
        const aiTextMaxWidth = textMaxWidth - aiXOffset;
        const aiStringBlocks = generateStringBlocks(helveticaFont, aiSize, aiText, aiTextMaxWidth, { beforeText: aiFirstText });

        // Add additional text separately so it doesn't turn orange
        itemsToDraw.push({
            xOffset: 10,
            text: aiFirstText,
            size: aiSize,
            height: 0,
        });
        itemsToDraw.push({
            xOffset: 10 + aiFirstTextWidth,
            text: aiStringBlocks[0],
            color: aiText === 'None' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0), // Orange or black
            size: aiSize,
            height: aiHeight,
            afterMargin: aiAfterMargin
        });
        for(let i = 1; i < aiStringBlocks.length; i++){
            itemsToDraw.push({
                xOffset: 10,
                text: aiStringBlocks[i],
                color: aiText === 'None' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0), // Orange or black
                size: aiSize,
                height: aiHeight,
                afterMargin: aiAfterMargin
            });
        }
        itemsToDraw.push({
            afterMargin: 10
        });
    
    // 99 - Others
    // Intra-lesson margins
        itemsToDraw.push({
            afterMargin: 10,
        });

    // Draw everything
    // 1 - Reset page if needed
        let totalHeight = 0;
        for(let item of itemsToDraw){
            totalHeight += item.height || 0;
        }
        if(yPos - totalHeight < yMin){
            currentPage = reportPdf.addPage();
            yPos = yMax;
        }

        // 2 - Draw items
        for(let item of itemsToDraw){
            currentPage.drawText(item.text || '', {
                x: xMin + item.xOffset || 0,
                y: yPos,
                size: item.size || 0,
                font: item.font || helveticaFont
            });
            yPos -= ((item.height || 0) + (item.afterMargin || 0));
        }
    }

    // 99 - Summary
    currentPage = reportPdf.addPage();
    yPos = yMax;
    const itemsToDraw = [];

    // 99a - Main Header
    const summaryHeaderText = `Progress Report Summary`;
    const summaryHeaderSize = 20;
    const summaryHeaderWidth = helveticaFont.widthOfTextAtSize(summaryHeaderText, summaryHeaderSize);
    const summaryHeaderHeight = helveticaFont.heightAtSize(summaryHeaderSize);
    itemsToDraw.push({
        xOffset: (xMax - xMin - summaryHeaderWidth) / 2,
        text: summaryHeaderText,
        size: summaryHeaderSize,
        height: summaryHeaderHeight,
        afterMargin: 25
    });

    // 99b - Pages header
    const summaryPagesHeaderText = `${totalPagesCompleted} pages completed`;
    const summaryPagesHeaderSize = 15;
    const summaryPagesHeaderHeight = helveticaFont.heightAtSize(summaryPagesHeaderSize);
    itemsToDraw.push({
        xOffset: 0,
        text: summaryPagesHeaderText,
        size: summaryPagesHeaderSize,
        height: summaryPagesHeaderHeight,
        afterMargin: 5
    });

    // 99c - Pages per hour/session
    const pagesPerHour = (totalPagesCompleted / totalHours).toLocaleString(undefined, { maximumFractionDigits : 1 });
    const pagesPerSession = (totalPagesCompleted / filteredAppointments.length).toLocaleString(undefined, { maximumFractionDigits : 1 });
    const summaryPpText = `This is equivalent to ${pagesPerHour} pages per hour or ${pagesPerSession} pages per session`;
    const summaryPpSize = 12;
    const summaryPpHeight = helveticaFont.heightAtSize(summaryPpSize);
    itemsToDraw.push({
        xOffset: 15,
        text: summaryPpText,
        size: summaryPpSize,
        height: summaryPpHeight,
        afterMargin: 15
    });

    // 99d - Lessons completed header
    const summaryLcHeaderText = `Lessons Completed:`;
    const summaryLcHeaderSize = 15;
    const summaryLcHeaderHeight = helveticaFont.heightAtSize(summaryLcHeaderSize);
    itemsToDraw.push({
        xOffset: 0,
        text: summaryLcHeaderText,
        size: summaryLcHeaderSize,
        height: summaryLcHeaderHeight,
        afterMargin: 5
    });

    // 99e - Lessons completed list
    const summaryLcSize = 12;
    const summaryLcHeight = helveticaFont.heightAtSize(summaryLcSize);
    if(allLessonsCompleted.length){
        for(let lesson of allLessonsCompleted){
            const summaryLcText = lesson;
            if(yPos - summaryLcHeight < yMin){
                currentPage = reportPdf.addPage();
                yPos = yMax;
            }
            itemsToDraw.push({
                xOffset: 15,
                text: summaryLcText,
                size: summaryLcSize,
                height: summaryLcHeaderHeight,
                afterMargin: 2
            });
        }
    } else {
        itemsToDraw.push({
            xOffset: 15,
            text: 'None',
            color: rgb(225/255, 96/255, 0),
            size: summaryLcSize,
            height: summaryLcHeaderHeight,
            afterMargin: 2
        });
    }

    // 99f - Draw items
    for(let item of itemsToDraw){
        if(yPos < yMin){
            currentPage = reportPdf.addPage();
            yPos = yMax;
        }

        currentPage.drawText(item.text || '', {
            x: xMin + (item.xOffset || 0),
            y: yPos,
            color: item.color || rgb(0, 0, 0),
            size: item.size || 0,
            font: item.font || helveticaFont
        });
        yPos -= ((item.height || 0) + (item.afterMargin || 0));
    }

    reportPdf = await appendLogo(reportPdf, logoData);

    // Append copyright text
    const copyrightText = `© ${(new Date()).getFullYear()} MathPlex Tutoring LLC`;
    const copyrightSize = 8;
    const copyrightWidth = helveticaFont.widthOfTextAtSize(copyrightText, copyrightSize);
    const copyrightXPos = xMax - copyrightWidth + 20; // + 20 is a manual adjustment
    const pages = reportPdf.getPages();
    for(let page of pages){
        page.drawText(copyrightText, {
            x: copyrightXPos,
            y: 30,
            size: copyrightSize,
            font: helveticaFont,
        });
    }

    const pdfBytes = await reportPdf.save();
    if(quickDownload){
        const [year, month, date] = getymd(`${quickDownloadDate} 00:00:00`);
        downloadFile(pdfBytes, `${year}-${month}-${date} ${studentName} PR.pdf`);
    } else {
        openPopup(pdfBytes);
    }

    return;
}