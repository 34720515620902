import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import AutoScheduleItemsModalBodyFooter from './AutoScheduleItemsModalBF';
import { checkResponse } from '../../form';

import {
    fetchStudentsActive,
    fetchMpCentersAll,
    fetchAutoScheduleItemsIds,
    createAutoScheduleItem,
    updateAutoScheduleItem,
    deleteAutoScheduleItem
} from '../../../actions';

function AutoScheduleItemsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [autoScheduleItem, setAutoScheduleItem] = useState({});
    const [studentOptions, setStudentOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const { mode, selectedAutoScheduleItem, onSubmitCallback, fetchMpCentersAll, fetchStudentsActive,
        fetchAutoScheduleItemsIds, createAutoScheduleItem, updateAutoScheduleItem, deleteAutoScheduleItem } = props;

    useEffect(() => {
        async function init(){           
            const studentsRes = await fetchStudentsActive();
            const centersRes = await fetchMpCentersAll();
            const newStudents = studentsRes.data || [];
            const newCenters = centersRes.data || [];
            const newStudentOptions = newStudents.map(s => ({ value: s.user_id, label: `${s.first_name} ${s.last_name}`, obj: s }));
            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));

            if(mode !== 'create'){
                const autoScheduleItemRes = await fetchAutoScheduleItemsIds({ ids: [selectedAutoScheduleItem.id] });
                
                const newAutoScheduleItem = autoScheduleItemRes.data?.[0] || {};
                
                if(mounted.current) setAutoScheduleItem(newAutoScheduleItem);
            }
            
            if(mounted.current){
                setLoaded(true);
                setStudentOptions(newStudentOptions);
                setCenterOptions(newCenterOptions);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const autoScheduleItemParams = {
                    student: values.student.value,
                    weight: values.weight.value,
                    center: values.center.value,
                    dow: values.dow.value,
                    time: values.time.raw,
                    duration: values.duration.value,
                    active: values.active ? 1 : 0
                };
                
                if(mode === 'create'){
                    response = await createAutoScheduleItem(autoScheduleItemParams);
                } else if(mode === 'edit') {
                    autoScheduleItemParams.id = autoScheduleItem.id;
                    response = await updateAutoScheduleItem(autoScheduleItemParams);
                }
            } else if(mode === 'delete') {
                response = await deleteAutoScheduleItem({ id: autoScheduleItem.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, autoScheduleItem, createAutoScheduleItem, updateAutoScheduleItem, deleteAutoScheduleItem]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Auto Schedule Item Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <AutoScheduleItemsModalBodyFooter
                    mode={mode}
                    autoScheduleItem={autoScheduleItem}
                    studentOptions={studentOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsActive,
    fetchMpCentersAll,
    fetchAutoScheduleItemsIds,
    createAutoScheduleItem,
    updateAutoScheduleItem,
    deleteAutoScheduleItem
})(AutoScheduleItemsModal);