import React, { useCallback } from 'react';
import { FormControlLabel, Checkbox, Radio, RadioGroup, Switch } from '@mui/material';

export function CustomSwitch(props){
    const {
        label = '',
        textPosition = 'after',
        disabled = false,
        name,
        color,
        onChange,
        checked,
    } = props;

    const onChangeWrapper = useCallback(() => {
        const newE = { target: { } };
        newE.target.name = name;
        newE.target.value = !checked;
        onChange(newE);
    }, [onChange, checked, name]);

    if(!color || !name || !onChange || checked === undefined){
        return(
            <div className="text-mpLRed">
                Switch missing props: {!color && 'color'} {!name && 'name'} {!onChange && 'onChange'} {checked === undefined && 'checked'}
            </div>
        );
    }

    return (
        <FormControlLabel
            checked={checked}
            control={<Switch color={color}/>}
            disabled={disabled}
            label={label}
            labelPlacement={`${textPosition === 'before' ? 'start' : 'end'}`}
            onChange={onChangeWrapper}
        />
    );
}

export function Check(props){
    const {
        label = '',
        disabled = false,
        textPosition = 'after',
        color,
        name,
        onChange,
        checked,
    } = props;

    const onChangeWrapper = useCallback(() => {
        const newE = { target: { } };
        newE.target.name = name;
        newE.target.value = !checked;
        onChange(newE);
    }, [onChange, checked, name]);

    if(!name || !color || !onChange || checked === undefined){
        return(
            <div className="text-mpLRed">
                Checkbox missing props: {!name && 'name'} {!color && 'color'} {!onChange && 'onChange'} {checked === undefined && 'checked'}
            </div>
        );
    }

    return (
        <FormControlLabel
            checked={checked}
            control={<Checkbox color={color}/>}
            disabled={disabled}
            label={label}
            labelPlacement={`${textPosition === 'before' ? 'start' : 'end'}`}
            onChange={onChangeWrapper}
        />
    );
}

export function RadioSet(props){
    const {
        name,
        onChange,
        value,
        options,
        orientation = "vertical",
        maxHeight = 0,
        labelPlacement = 'end',
        groupLabel = null,
        disabled = false
    } = props;

    const onChangeWrapper = useCallback((e) => {
        const newE = { target: {} };
        newE.target.value = isNaN(parseInt(e.target.value)) ? newE.target.value : parseInt(e.target.value);
        newE.target.name = name;
        onChange(newE);
    }, [onChange, name]);

    if(!name || !onChange || value === undefined || !options){
        return(
            <div className="text-mpLRed">
                RadioSet missing props: {!name && 'name'} {!onChange && 'onChange'} {value === undefined && 'value'} {!options && 'options'}
            </div>
        );
    }

    // CHANGE COLOR THEMES IN components/metronic/layout/_core/MaterialThemeProvider.js

    const heightStyles = maxHeight ? {
        overflowY: "auto",
        maxHeight
    } : {};

    let keyIndex = 0;
    if(orientation === 'vertical'){
        return(
            <div>
                {groupLabel ? 
                    <>
                        <div>{groupLabel}</div>
                        <br/>
                    </>
                : null}
                <RadioGroup
                    name={name}
                    onChange={onChangeWrapper}
                    disabled={disabled}
                    value={value}
                >
                    <div style={heightStyles}>
                        {options.map(o => {
                            const optionValue = o.value;
                            const optionLabel = o.label;
                            return (
                                <div
                                    key={`${name}-radio-option-${keyIndex++}`}
                                    style={{ display: "table" }}
                                >
                                    <FormControlLabel
                                        control={<Radio color="primary"/>}
                                        value={optionValue}
                                        label={optionLabel}
                                        labelPlacement={labelPlacement}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </RadioGroup>
            </div>
        );
    } else if(orientation === 'horizontal'){
        return(
            <div>
                {groupLabel ? 
                    <>
                        <div>{groupLabel}</div>
                        <br/>
                    </>
                : null}
                <RadioGroup
                    name={name}
                    onChange={onChangeWrapper}
                    disabled={disabled}
                    value={value}
                >
                    <div style={heightStyles} className="flex flex-row gap-x-4">
                        {options.map(o => {
                            const optionValue = o.value;
                            const optionLabel = o.label;
                            return (
                                <div
                                    key={`${name}-radio-option-${keyIndex++}`}
                                    style={{ display: "table", marginRight: "1rem" }}
                                >
                                    <FormControlLabel
                                        control={<Radio color="primary"/>}
                                        value={optionValue}
                                        label={optionLabel}
                                        labelPlacement={labelPlacement}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </RadioGroup>
            </div>
        );
    } else {
        return (
            <div className="text-mpLRed">
                Invalid orientation (must be "vertical" or "horizontal")
            </div>
        )
    }
}