import * as Yup from 'yup';

const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

export const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .max(30, 'Max 30 characters')
        .required('First Name is required'),
    lastName: Yup.string()
        .max(30, 'Max 30 characters')
        .required('Last Name is required'),
    phone: Yup.string()
        .test(
            'phoneIsValid',
            'Please enter a valid phone number',
            (value) => {
                if(!value) return true;
                const truncated = value.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '');
                if(isNaN(truncated) || truncated % 1 !== 0 || truncated.toString().length !== 10) return false;
                else return true;
            }
        ),
    email: Yup.string()
        .email('Valid email required')
        .max(50, 'Max 50 characters')
        .required('Email is required'),
    passwordReset: Yup.string()
        .min(8, 'Minimum 8 characters')
        .matches(passRegex, 'Password must contain at least one lower case letter, one upper case letter, and one number'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('passwordReset')], 'Passwords must match')
});

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'None' };
    return centerOptions.find(c => parseInt(c.value) === parseInt(center)) || { value: -1, label: `Unknown center (ID ${center})` };
}

export const mpPermissionsOptions = ['Admin', 'Lead Instructor', 'Instructor', 'None'].map(i => ({ value: i, label: i }));
export const rpPermissionsOptions = ['Admin', 'Lead Instructor', 'Instructor', 'None'].map(i => ({ value: i, label: i }));
export function getInitPermissionsOption(permissions, permissionsOptions){
    if(!permissions) return { value: "None", label: 'None' };
    return permissionsOptions.find(p => p.value === permissions) || { value: permissions, label: `Unknown group (${permissions})` };
}