import React from 'react';
import * as Yup from 'yup';

import { Modal, Button, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { TooltipWrapper } from '../../display';
import { FormikControl, SelectSingle, Check, Switch, DatePicker } from '../../form';
import { formatDateTime } from '../../functions';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .max(50, 'Max 50 characteres')
        .required('Name is required'),
    nQuestions: Yup.number()
        .min(1, 'Min 1')
        .max(200, 'Max 200')
        .typeError('Number required')
        .required('Number of Questions is required'),
    notes: Yup.string()
        .max(1000, 'Max 1,000 characters'),
    student: Yup.mixed().test(
        'studentIsSelected',
        'Student is required.',
        (value) => value.value !== -1
    ),
    fileName: Yup.string()
        .required('File Name is required')
        .max(20, 'Max 20 characters'),
    dateTaken: Yup.mixed().test(
        'dateTakenIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
});

const letterMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export function renderMeta(assessmentTemplate){
    let created = assessmentTemplate.date_created ? (
        <div>Created on {formatDateTime(assessmentTemplate.date_created)}</div>
    ) : null;
    let updated = assessmentTemplate.date_updated ? (
        <div>Updated on {formatDateTime(assessmentTemplate.date_updated)}</div>
    ) : null;

    if(created || updated){
        return(
            <div className="col">
                <br/>
                {created}
                {updated}
            </div>
        )
    } else return null;
}

function renderConfirmation(formik){
    const { name, student, nQuestions, notes, questions } = formik.values;

    const relevantQuestions = {};
    for(let i = 1; i <= nQuestions; i++){
        relevantQuestions[i] = questions[i];
    }
    return (
        <>
            <Modal.Body>
                <h5>Please confirm your selections.</h5>

                <br/>

                <h2>Assessment</h2>
                <div className="h-4 clear-both"/>

                <div className="grid grid-cols-1">
                    <h5>Student: {student.label}</h5>
                    <h5>Name: {name}</h5>
                    <h5>Total Questions: {nQuestions}</h5>
                    <h5>Notes: {notes}</h5>
                </div>

                <div className="h-4 clear-both"/>
                <hr/>
                <div className="h-4 clear-both"/>

                <h2>Questions</h2>
                <div className="h-4 clear-both"/>

                {Object.keys(relevantQuestions).map(q => {
                    const { onIncorrect1, onIncorrect2, onIncorrect3, isCorrect } = questions[q];
                    const head = isCorrect ? <h4 className="text-mpGreen">Question {q} (Correct)</h4> :
                        <h4 className="text-mpLRed">Question {q} (Incorrect)</h4>
                    const row1 = <div>Lesson #1: {onIncorrect1.label}</div>;
                    const row2 = onIncorrect2.value === -1 ? null : <div>Lesson #2: {onIncorrect2.label}</div>;
                    const row3 = onIncorrect3.value === -1 ? null : <div>Lesson #3: {onIncorrect3.label}</div>;
                    return (
                        <div key={`assessments-confirm-question${q}`}>
                            {head}
                            <div className="h-1 clear-both"/>
                            <div className="grid grid-cols-1 ml-2">
                                {row1}
                                {row2}
                                {row3}
                            </div>
                            <br/>
                        </div>
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <div className="flex flex-row gap-x-2 flex-wrap">
                    {formik.status && !formik.isSubmitting ? 
                        <div className="text-mpLRed">
                            {formik.status}
                        </div> : null
                    }
                    <Button
                        color="lte-mpLRed"
                        onClick={() => {
                            formik.setFieldValue('firstConfirm', false);
                            formik.setSubmitting(false);
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        color="lte-mpLBlue"
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>  
            </Modal.Footer>
        </>
    );
}

function checkFieldsValid(formik, lessonOptions){
    const viewMode = formik.values.viewMode;
    if(viewMode === 1){
        const { assessmentTemplate, student } = formik.values;
        if(student.value === -1){
            formik.setStatus('Student is required.');
            return;
        }
        if(assessmentTemplate.value !== -1){
            const newValues = { ...formik.values };

            const template = assessmentTemplate.obj;
            
            newValues.level = template.level;
            newValues.name = template.name;
            newValues.nQuestions = parseInt(template.n_questions);
            newValues.fileName = template.file_name;
            newValues.hasFile = parseInt(template.has_file) === 1;
            newValues.hasAnswerKey = parseInt(template.has_answer_key) === 1;

            const lessonMap = {};
            lessonOptions.forEach(l => lessonMap[parseInt(l.value)] = l);

            function findOption(id){
                return lessonMap[id] || { value: -1, label: `Unknown lesson (ID ${id})` };
            }

            const newQuestions = {};
            template.questions.forEach(q => {
                const questionNumber = parseInt(q.question_number);
                newQuestions[questionNumber] = {
                    questionNumber,
                    onIncorrect1: findOption(q.on_incorrect_1),
                    onIncorrect2: findOption(q.on_incorrect_2),
                    onIncorrect3: findOption(q.on_incorrect_3),
                    isCorrect: false
                }
            });
            for(let i = Object.keys(newQuestions).length + 1; i <= 200; i++){
                newQuestions[i] = {
                    questionNumber: i,
                    onIncorrect1: findOption(-1),
                    onIncorrect2: findOption(-1),
                    onIncorrect3: findOption(-1),
                    isCorrect: false
                }
            }
            newValues.questions = newQuestions;

            formik.setValues(newValues);
        }
        formik.setStatus('');
        formik.setFieldValue('viewMode', 2);
    } else if(viewMode === 2){
        if(Object.keys(formik.errors).length){
            formik.setStatus('One or more fields needs to be corrected.');
            return;
        } else {
            formik.setStatus('');
            formik.setFieldValue('viewMode', 3);
            return;
        }
    } else if(viewMode === 3){
        const { questions, nQuestions } = formik.values;
    
        let isValid = true;
        for(let i = 1; i <= nQuestions; i++){
            const { onIncorrect1, onIncorrect2, onIncorrect3 } = questions[i];
            const val1 = onIncorrect1.value;
            const val2 = onIncorrect2.value;
            const val3 = onIncorrect3.value;

            if(val1 === -1 || val2 === val1 || val3 === val1 || (val2 === val3 && val2 !== -1 && val3 !== -1)){
                isValid = false;
                break;
            }
        }
    
        if(!isValid){
            formik.setStatus('One or more fields needs to be corrected.');
            return;
        } else {
            formik.setStatus('');
            formik.setFieldValue('firstConfirm', true);
        }
    }
}

function setAll(formik, mode){
    const newValues = formik.values;
    const newQuestions = {};
    const valueToSet = mode === 'correct' ? true : false;

    Object.keys(newValues.questions).forEach(n => {
        newQuestions[n] = {...newValues.questions[n]};
        newQuestions[n].isCorrect = valueToSet;
    });

    newValues.questions = newQuestions;

    formik.setValues(newValues);
}
export function renderBody(formik, mode, handleClose,
    assessmentTemplateOptions, studentOptions, lessonOptions){
    if(formik.values.firstConfirm) return renderConfirmation(formik);
    
    const viewMode = formik.values.viewMode;
    if(viewMode === 1){
        const at = formik.values.assessmentTemplate;
        const rightSide = !at.value ? null : at.value === -1 ? <div>Custom parameters will be used.</div> :
            (
                <div className="grid grid-cols-1">
                    <div><b>Name:</b> {at.obj.name}</div>
                    <div><b>Level:</b> {at.obj.level}</div>
                    <div><b>Total Questions:</b> {at.obj.n_questions}</div>
                    <div><b>Notes:</b> {at.obj.notes}</div>
                </div>
            );
        return (
            <>
                <Modal.Body>
                    <div className="flex flex-row gap-x-4 items-start">
                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                            <h4>Student</h4>
                            <SelectSingle
                                id="assessments-student"
                                name="student"
                                value={formik.values.student}
                                onChange={formik.handleChange}
                                options={studentOptions}
                                disabled={mode !== 'create'}
                            />
                            {mode !== 'create' ?
                                <div className="text-mpOrange">
                                    Student can't be modified.
                                </div>
                            : <div className="text-mpOrange">
                                    Warning! Student can't be changed later.
                                </div>
                            }
                            {formik.errors.student && (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.student}
                                </ErrorMessage>
                            )}
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                            <h4>Template</h4>
                            <SelectSingle
                                id="assessment-form-template"
                                name="assessmentTemplate"
                                value={formik.values.assessmentTemplate}
                                onChange={formik.handleChange}
                                options={assessmentTemplateOptions}
                            />
                            {formik.errors.assessmentTemplate && (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.assessmentTemplate}
                                </ErrorMessage>
                            )}
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                            <h4>Selected Template:</h4>

                            {rightSide}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed" style={{ marginRight: "2rem" }}>
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => checkFieldsValid(formik, lessonOptions)}
                            disabled={formik.values.student.value === -1 || formik.isSubmitting}
                        >
                            Next
                        </Button>
                    </div>
                </Modal.Footer>
            </>
        );
    } else if(viewMode === 2){
        const vals = formik.values;
        return (
            <>
                <Modal.Body
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <>
                        <div className="flex flex-row gap-x-4 items-start">
                            <div className="grid grid-cols-1 gap-y-2 w-1/6">
                            <div className="clear-both h-6"/>
                                {<TooltipWrapper
                                    tooltipText="Check this if the assessment has not yet been completed."
                                >
                                    <Check
                                        id="assessments-notTaken"
                                        name="notTaken"
                                        color="mpLRed"
                                        label={<div className="text-mpLBlue">Not Taken?</div>}
                                        checked={formik.values.notTaken}
                                        onChange={formik.handleChange}
                                    />
                                </TooltipWrapper>}
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/6 mr-4">
                                <div className="clear-both h-6"/>
                                <DatePicker
                                    id="assessments-date-taken"
                                    name="dateTaken"
                                    label="Date Taken"
                                    disabled={formik.values.notTaken}
                                    value={formik.values.dateTaken}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                <h4>Name</h4>
                                <FormikControl
                                    id="assessments-name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder="Required. Max 50 characters."
                                />
                                {formik.errors.name && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.name}
                                    </ErrorMessage>
                                )}
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                <h4># Questions</h4>
                                <FormikControl
                                    id="assessments-nQuestions"
                                    name="nQuestions"
                                    value={formik.values.nQuestions}
                                    onChange={formik.handleChange}
                                    placeholder="Required. Max 50 characters."
                                />
                                {formik.errors.nQuestions && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.nQuestions}
                                    </ErrorMessage>
                                )}
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/6">
                                <TooltipWrapper
                                    tooltipText={"Do not modify this unless you know the correct file name."}
                                >
                                    <h4 className="text-mpLBlue">File Name</h4>
                                </TooltipWrapper>
                                <FormikControl
                                    id="assessments-fileName"
                                    name="fileName"
                                    value={formik.values.fileName}
                                    onChange={formik.handleChange}
                                    placeholder="Server file name. Required. Max 20 characters. Do not include .pdf"
                                />
                                {formik.errors.fileName && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.fileName}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>

                        <br/>

                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-w w-full">
                                <h4>Notes</h4>
                                <FormikControl
                                    id="learning-plan-notes"
                                    name="notes"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    placeholder="Max. 1,000 characters."
                                    as="textarea"
                                />
                                {formik.errors.notes && (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.notes}
                                    </ErrorMessage>
                                )}
                            </div>
                        </div>

                        <br/>

                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-w w-full">
                                <div className="flex flex-row gap-x-4">
                                    <TooltipWrapper
                                        tooltipText={
                                            <>
                                                <div>
                                                    Check true if a file has been uploaded for this assessment.
                                                </div>
                                            </>
                                        }
                                    >
                                        <Check
                                            id="assessments-hasFile"
                                            name="hasFile"
                                            color="mpLRed"
                                            label={<div className="text-mpLBlue">File?</div>}
                                            checked={formik.values.hasFile}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                    <TooltipWrapper
                                        tooltipText={
                                            <>
                                                <div>
                                                    Check true if an answer key has been uploaded for this assessment.
                                                </div>
                                            </>
                                        }
                                    >
                                        <Check
                                            id="assessments-hasAnswerKey"
                                            name="hasAnswerKey"
                                            color="mpLRed"
                                            label={<div className="text-mpLBlue">Answer Key?</div>}
                                            checked={formik.values.hasAnswerKey}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                    <div style={{ marginLeft: "auto" }}>
                                        <Check
                                            id="assessments-active"
                                            name="active"
                                            color="mpLRed"
                                            label="Active"
                                            checked={formik.values.active}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed">
                                {formik.status}
                            </div> : null
                        }
                        { mode === 'edit' ? null : 
                            <Button
                                color="lte-mpLRed"
                                disabled={formik.isSubmitting}
                                onClick={() => {
                                    formik.setStatus('');
                                    formik.setFieldValue('viewMode', 1);
                                }}
                            >
                                Back
                            </Button>
                        }
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => checkFieldsValid(formik)}
                            disabled={formik.isSubmitting || vals.name === '' ||
                                vals.nQuestions === '' || vals.fileName === ''}
                        >
                            Next
                        </Button>
                    </div>
                </Modal.Footer>
            </>
        );
    } else if(viewMode === 3){
        const numList = [];
        for(let i = 1; i <= formik.values.nQuestions; i++) numList.push(i);
        
        let { questions, nQuestions } = formik.values;
        nQuestions = parseInt(nQuestions);

        // Formats the compact view mode (answers only) neatly into rows of width maxWidth
        const compactMap = [];
        const maxWidth = 10;
        for(let i = 0; i <= Math.ceil(nQuestions / maxWidth); i++){
            const subMap = [];
            for(let j = i * maxWidth + 1; j <= Math.min(nQuestions, i * maxWidth + maxWidth); j++) subMap.push(j);
            compactMap.push(subMap);
        }

        // Last row should be rendered differently since we are using "col" and not "col-1"
        // Need to populate the empty cols so that alignment is correct
        const lastRowFirstIndex = Math.floor(nQuestions / maxWidth) * maxWidth + 1;
        const lastRowFinalIndex = Math.ceil(nQuestions / maxWidth) * maxWidth;
        const lastRowFirstEmptyIndex = parseInt(nQuestions) + 1;
        const lastRow = [];
        for(let i = lastRowFirstIndex; i <= lastRowFinalIndex; i++) lastRow.push(i);

        let index = 0;
        const letterMode = formik.values.letterMode;
        const additionalFields = formik.values.compactView ? (
            <div>
                <h4>Check all correct questions.</h4>

                <br/>

                {compactMap.map(row => {
                    if(row.length < maxWidth) return null;
                    return(
                        <div
                            className={`flex flex-row gap-x-4 grid grid-cols-${maxWidth}`}
                            key={`assessments-row-${++index}`}
                        >
                            {row.map(n => {
                                return (
                                    <div key={`assessments-question-${n}`}>
                                        <Check
                                            id={`assessments-additional-question${n}-isCorrect`}
                                            name="isCorrect"
                                            color="mpLRed"
                                            label={letterMode ? letterMap[n % 52 - 1] : n}
                                            checked={questions[n].isCorrect}
                                            onChange={(e) => {
                                                const newValues = {...formik.values};
                                                newValues.questions[n].isCorrect = e.target.value;
                                                formik.setValues(newValues);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <div className={`flex flex-row gap-x-4 grid grid-cols-${maxWidth}`}>                        
                    {lastRow.map(n => {
                        if(n >= lastRowFirstEmptyIndex){
                            return (
                                <div key={`assessments-question-${n}`}/>
                            );
                        } else {
                            return (
                                <div key={`assessments-question-${n}`}>
                                    <Check
                                        id={`assessments-additional-question${n}-isCorrect`}
                                        name="isCorrect"
                                        color="mpLRed"
                                        label={letterMode ? letterMap[n % 52 - 1] : n}
                                        checked={questions[n].isCorrect}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            newValues.questions[n].isCorrect = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        )
        :
        (
            <div className="max-h-[80rem] overflow-y-auto">
                {numList.map(n => {
                    const questionGroup = questions[n];
                    const val1 = questionGroup.onIncorrect1.value;
                    const val2 = questionGroup.onIncorrect2.value;
                    const val3 = questionGroup.onIncorrect3.value;

                    const firstIsRequired = val1 === -1 ?  <div className="text-mpLRed">First lesson is required</div> : null;
                    const secondIsRepeat = val2 === -1 ? null : val2 === val1 ? 
                        <div className="text-mpLRed">Second lesson is the same as first lesson</div> : 
                        val2 === val3 ? 
                        <div className="text-mpLRed">Second lesson is the same as third lesson</div> : null;
                    const thirdIsRepeat = val3 === -1 ? null : val3 === val1 ? 
                        <div className="text-mpLRed">Third lesson is the same as first lesson</div> : 
                        val3 === val2 ? 
                        <div className="text-mpLRed">Third lesson is the same as second lesson</div> : null;
                    return (
                        <div key={`assessments-additional-fields-${n}`}>
                            <h4>Question {letterMode ? letterMap[n % 52 - 1] : n}</h4>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-w w-1/4">
                                    <TooltipWrapper
                                        tooltipText="Lesson to assign if this questions is missed"
                                    >
                                        <h5 className="text-mpLBlue">On Incorrect - 1</h5>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id={`assessments-additional-question${n}-onIncorrect1`}
                                        name={`question${n}_onIncorrect1`}
                                        value={questionGroup.onIncorrect1}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            newValues.questions[n].onIncorrect1 = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                        options={lessonOptions}
                                    />
                                    {firstIsRequired}
                                </div>
                                <div className="grid grid-cols-1 gap-y-w w-1/4">
                                    <TooltipWrapper
                                        tooltipText="Lesson to assign if this questions is missed"
                                    >
                                        <h5 className="text-mpLBlue">On Incorrect - 2</h5>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id={`assessments-additional-question${n}-onIncorrect2`}
                                        name={`question${n}_onIncorrect2`}
                                        value={questionGroup.onIncorrect2}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            newValues.questions[n].onIncorrect2 = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                        options={lessonOptions}
                                    />
                                    {secondIsRepeat}
                                </div>
                                <div className="grid grid-cols-1 gap-y-w w-1/4">
                                    <TooltipWrapper
                                        tooltipText="Lesson to assign if this questions is missed"
                                    >
                                        <h5 className="text-mpLBlue">On Incorrect - 3</h5>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id={`assessments-additional-question${n}-onIncorrect3`}
                                        name={`question${n}_onIncorrect3`}
                                        value={questionGroup.onIncorrect3}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            newValues.questions[n].onIncorrect3 = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                        options={lessonOptions}
                                    />
                                    {thirdIsRepeat}
                                </div>
                                <div className="grid grid-cols-1 gap-y-w w-1/4">
                                    <Check
                                        id={`assessments-additional-question${n}-isCorrect`}
                                        name="isCorrect"
                                        color="mpLRed"
                                        label={`Question ${n} Correct?`}
                                        checked={questionGroup.isCorrect}
                                        onChange={(e) => {
                                            const newValues = {...formik.values};
                                            newValues.questions[n].isCorrect = e.target.value;
                                            formik.setValues(newValues);
                                        }}
                                    />
                                </div>
                            </div>
                            <br/>
                        </div>
                    );
                })}
            </div>
        );

        return(
            <>
                <Modal.Body>
                    <div className="flex flex-row gap-x-4">
                        <Switch
                            id="assessment-templates-letterMode"
                            name="letterMode"
                            label="Letter Mode"
                            color="mpLRed"
                            checked={formik.values.letterMode}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="flex flex-row gap-x-4">
                        <Switch
                            id="assessments-compact"
                            name="compactView"
                            label="Compact View (hide lessons)"
                            color="mpLRed"
                            checked={formik.values.compactView}
                            onChange={formik.handleChange}
                        />
                        <Button
                            color="lte-mpLRed"
                            onClick={() => setAll(formik, 'incorrect')}
                            style={{ marginLeft: "2rem" }}
                        >
                            All Incorrect
                        </Button>
                        <Button
                            color="lte-mpGreen"
                            onClick={() => setAll(formik, 'correct')}
                            style={{ marginLeft: "2rem" }}
                        >
                            All Correct
                        </Button>
                    </div>

                    <br/>
                    
                    {additionalFields}
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed">
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={() => {
                                formik.setStatus('');
                                formik.setFieldValue('viewMode', 2);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => checkFieldsValid(formik)}
                            disabled={formik.isSubmitting || Object.keys(formik.errors).length}
                        >
                            Review
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            onClick={formik.handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Footer>
            </>
        );
    }
}