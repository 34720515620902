import React from 'react';
import * as Yup from 'yup';

import { Modal, Button } from '../../custom-essentials';
import { formatDateFull, formatTime } from '../../functions';
import { renderSubmittingDefault } from '../../form';

export const validationSchema = Yup.object().shape({
    selectedStudent: Yup.mixed().test(
        'studentIsSelected',
        'Student is required',
        (value) => value.value !== -1
    ),
    amount: Yup.number()
        .typeError('Amount must be a number')
        .min(0, 'Min 0')
        .max(1000, 'Max 1000')
        .required('Amount is required'),
    stampsNotes: Yup.string()
        .max(300, 'Max 300 characters'),
});

export function getInitStudentOption(student, studentOptions){
    if(!student) return { value: -1, label: 'Please select...' };
    return studentOptions.find(s => s.value === student) || { value: -1, label: `Unknown student (${student})` };
}

export function getInitStampsAmount(selectedAppointment){
    if(parseInt(selectedAppointment.stamps_given) === 1) return 0;
    else if(!selectedAppointment.id) return 2;
    else {
        return (
            parseInt(selectedAppointment.n_pages) * 2 +
                parseInt(selectedAppointment.n_lessons) * 10
        );
    }
}

export const typeOptions = [
    'Give Stamps',
    'Give Cards',
    'Spend Cards',
    'Ungive Stamps',
    'Ungive Cards',
    'Give Total Cards',
    'Take Total Cards',
].map(i => ({ value: i, label: i }));

export function renderSelectedAppointment(sa){
    if(!sa.id) return null;
    return(
        <>
            <div className="grid grid-cols-1">
                <h5>Linked Appointment:</h5>
                <div>{formatDateFull(sa.date_time)} from {formatTime(sa.startTime)} to {formatTime(sa.endTime)}</div>
                <div className="grid grid-cols-1 text-sm">
                    <div>Pages: {sa.n_pages} pages = {sa.n_pages * 2} stamps&nbsp;&nbsp;</div>
                    <div>Lessons: {sa.n_lessons} = {sa.n_lessons * 10} stamps&nbsp;</div>
                </div>
            </div>

            <br/>
        </>
    )
}

export function getStampChanges(student, type, amount, stampParams){
    amount = parseInt(amount);

    const oldTotalCards = student.mp_total_cards;
    const oldCurrentCards = student.mp_current_cards;
    const oldCurrentStamps = student.mp_current_stamps;

    let newTotalCards = oldTotalCards;
    let newCurrentCards = oldCurrentCards;
    let newCurrentStamps = oldCurrentStamps;
    
    const level1spc = stampParams.level1_spc;
    const level2Threshold = stampParams.level2_threshold;
    const level2spc = stampParams.level2_spc;
    const level3Threshold = stampParams.level3_threshold;
    const level3spc = stampParams.level3_spc;
    const level4Threshold = stampParams.level4_threshold;
    const level4spc = stampParams.level4_spc;
    const level5Threshold = stampParams.level5_threshold;
    const level5spc = stampParams.level5_spc;

    function getSpc(totalCards){
        if(totalCards >= level5Threshold) return level5spc;
        else if(totalCards >= level4Threshold) return level4spc;
        else if(totalCards >= level3Threshold) return level3spc;
        else if(totalCards >= level2Threshold) return level2spc;
        else return level1spc;
    }

    type = type.value;
    if(type === 'Give Stamps' || type === 'Ungive Stamps'){
        if(type === 'Give Stamps'){
            for(let i = 0; i < amount; i++){
                const newSpc = getSpc(newTotalCards);

                newCurrentStamps++;
                if(newCurrentStamps >= newSpc){
                    newCurrentStamps = 0;
                    newCurrentCards++;
                    newTotalCards++;
                }
            }
        } else if(type === 'Ungive Stamps'){
            // 0 if there are enough remaining stamps on the card; 1 if not
            for(let i = 0; i < amount; i++){
                const newSpc = getSpc(newTotalCards);

                newCurrentStamps--;
                if(newCurrentStamps < 0){
                    newCurrentStamps = newSpc - 1;
                    newCurrentCards--;
                    newTotalCards--;
                }
            }
            // Negative values will be handled at the end
        }
    } else if(type === 'Give Cards'){
        newCurrentCards += amount;
        newTotalCards += amount;
    } else if(type === 'Ungive Cards'){
        newCurrentCards -= amount;
        newTotalCards -= amount;
    } else if(type === 'Spend Cards'){
        newCurrentCards -= amount;
    } else if(type === 'Give Total Cards'){
        newTotalCards += amount;
    } else if(type === 'Take Total Cards'){
        newTotalCards -= amount;
    }

    function getLevel(totalCards){
        if(totalCards >= level5Threshold) return 5;
        else if(totalCards >= level4Threshold) return 4;
        else if(totalCards >= level3Threshold) return 3;
        else if(totalCards >= level2Threshold) return 2;
        else return 1;
    }

    return { newTotalCards, newCurrentCards, newCurrentStamps, getLevel };
}

export function renderChanges(student, type, amount, amountValid, stampParams){
    if(!student.object){
        return <div className="text-mpLRed">No student is selected</div>;
    }

    if(!amountValid) return <div className="text-mpLRed">Please enter a valid number</div>;
    
    const studentObj = student.object;
    const {
        newTotalCards,
        newCurrentCards,
        newCurrentStamps,
        getLevel
    } = getStampChanges(studentObj, type, amount, stampParams);
    
    const oldLevel = getLevel(studentObj.mp_total_cards);
    const newLevel = getLevel(newTotalCards);

    let oldClassName = "text-mpLGrey";
    switch(oldLevel){
        case 2:
            oldClassName = "text-mpLRed";
            break;
        case 3:
            oldClassName = "text-mpBlack";
            break;
        case 4:
            oldClassName="text-mpYellow";
            break;
        default:
            break;
    }

    const oldSpcString = `level${oldLevel}_spc`;
    const newSpcString = `level${newLevel}_spc`;
    
    let isNegative = false;
    if(newTotalCards < 0 || newCurrentCards < 0) isNegative = true;

    let adjustedCurrentStamps = Math.max(newCurrentStamps, 0);
    if(newCurrentCards < 0) adjustedCurrentStamps = 0;
    
    const adjustedTotalCards = Math.max(newTotalCards, 0);
    const adjustedCurrentCards = Math.max(newCurrentCards, 0);

    const negativeWarning = isNegative ?
        <div className="text-mpLRed font-sm">
            Warning! One or more values was reduced to zero. Please double check before proceeding.
            Submitting the form will update the student's stamps as shown. Since values cannot be negative,
            attempting to spend or take away more cards than the student has available may result in
            undesired behavior.
        </div>
        : null;
        
    if(studentObj){
        return(
            <>
                <h5>{studentObj.first_name} {studentObj.last_name}'s stamps will be updated as follows:</h5>
                <div className="grid grid-cols-1">
                    <div className="flex flex-row">
                        { oldLevel === newLevel ?
                            <span className={`flex flex-row ${oldClassName}`}>
                                Level: {oldLevel} ({stampParams[oldSpcString]} stamps per card)
                            </span>
                        :
                            <span className={`flex flex-row ${oldClassName}`}>
                                Level: {oldLevel} --&gt; {newLevel} ({stampParams[oldSpcString]} --&gt; {stampParams[newSpcString]} stamps per card)
                            </span>
                        }
                    </div>
                    <div className="flex flex-row">Available cards: {studentObj.mp_current_cards} --&gt; {adjustedCurrentCards}</div>
                    <div className="flex flex-row">Stamps: {studentObj.mp_current_stamps} --&gt; {adjustedCurrentStamps}</div>
                    <div className="flex flex-row">Total Cards: {studentObj.mp_total_cards} --&gt; {adjustedTotalCards}</div>
                    {negativeWarning}
                </div>
            </>
        );
    } else {
        return(
            <h5>No student is selected.</h5>
        );
    }
}

export function renderSubmitting(submissionStatus, setSubmitting, setFieldValue){
    let completed = 0;
    Object.values(submissionStatus).forEach(ss => {
        if(ss.completed) completed++;
    });
    const total = Object.values(submissionStatus).length - 2;
    const lengthText = total > 0 ? ` (${completed}/${total})` : null;
    return (
        <>
            <Modal.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="col">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <div className="d-flex align-items-center">
                            <div className="mr-2">Processing{lengthText}...</div>
                            <div className="spinner spinner-primary mr-10"/>
                        </div>
                    }
                </h4>

                <br/>
                
                <div className="col">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => {
                        setFieldValue('firstConfirm', false);
                        setSubmitting(false);
                    }}
                >
                    Back
                </Button>
            </Modal.Footer>
        </>
    );
}