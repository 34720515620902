import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, Button, ModalProcessing, ModalBodyFooter, ErrorMessage } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';
import { FormikControl, Check } from '../../../../../components/form';
import { getFileInfos } from '../helpers/helpers';

import {
    fetchMPLessonsMultiND
} from '../../../../../actions-nd';
import { formatDate } from '../../../../../components/functions';

function renderLPItem(lpi){
    const neverTag = <span className="text-mpOrange">Never</span>;
    const lessonObj = lpi.lessonObj || {};
    const assignedText = lpi.date_assigned ? <span><b>Assigned:</b> {formatDate(lpi.date_assigned)}</span>
        : <span><b>Assigned:</b> {neverTag}</span>;
    const completedText = lpi.date_completed ? <span><b>Completed:</b> {formatDate(lpi.date_completed)}</span>
        : <span><b>Completed:</b> {neverTag}</span>;
    return (
        <div className="col no-pad">
            <div><b>{lessonObj.lesson_id}:</b> {lessonObj.name}</div>
            <div style={{ marginLeft: "2rem" }}>{assignedText} <span className="text-mpLRed">||</span> {completedText}</div>
        </div>
    )
}
function validateFlagPageLessonsOffset(fplo, nSelectedLessons){
    return !(isNaN(fplo) || fplo < 1 || fplo > 9 || fplo % 1 !== 0 || fplo >= nSelectedLessons);
}

function CustomDownloadModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [learningPlanItems, setLearningPlanItems] = useState([]);

    const { selectedLearningPlans, studentName, onSubmitCallback } = props;

    useEffect(() => {
        async function init(){
            const newLearningPlanItems = [];
            selectedLearningPlans.forEach(lp => {
                lp.obj.items.forEach(lpi => {
                    newLearningPlanItems.push(lpi);
                });
            });

            if(mounted.current){
                setLearningPlanItems(newLearningPlanItems);
                setLoaded(true);
            }
        }

        init();
    }, [selectedLearningPlans]);

    const handleClose = useCallback(() => {
        async function close(){
            await onSubmitCallback(false);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            const { selectedItemIds, setFlagPage, flagPageLessonsOffset, splitInFours } = values;

            const flpoValid = !setFlagPage || validateFlagPageLessonsOffset(flagPageLessonsOffset, selectedItemIds.length);
            if(!selectedItemIds.length || !flpoValid){
                setStatus('One or more errors needs to be corrected');
                setSubmitting(false);
                return;
            }
            
            const selectedLPItems = [];
            selectedItemIds.forEach(id => {
                const matchedItem = learningPlanItems.find(lpi => parseInt(lpi.id) === parseInt(id));
                if(matchedItem) selectedLPItems.push(matchedItem);
            });

            const fileInfos = getFileInfos(selectedLPItems, studentName);
            
            const additionalOptions = { splitInFours };
            if(setFlagPage) additionalOptions.flagPageLessonsOffset = flagPageLessonsOffset;

            const fetchStatus = await fetchMPLessonsMultiND(fileInfos, additionalOptions);
            if(fetchStatus !== true) setStatus(fetchStatus);
            else setStatus(false);

            if(mounted.current) setSubmitting(false);
        }
        submit();
    }, [learningPlanItems, studentName]);

    return (
        <Modal className="w-3/4" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Custom Lesson Download</h2>
            </Modal.Header>
            <Modal.BodyFooter>

                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedItemIds: [],
                        setFlagPage: true,
                        flagPageLessonsOffset: 1,
                        splitInFours: false
                    }}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <ModalBodyFooter>
                            <Modal.Body>
                                {loaded ? 
                                    <>
        {/* UPPER SECTION */}
                                        <div className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2">
                                                <TooltipWrapper
                                                    tooltipText="This will add a Flag Warning Page a specified number of lessons before the end of the PDF"
                                                >
                                                    <Check
                                                        id="customDownload-setFlagPage"
                                                        name="setFlagPage"
                                                        color="mpLRed"
                                                        label={<div className="text-mpLBlue">Set Flag Page</div>}
                                                        checked={formik.values.setFlagPage}
                                                        onChange={formik.handleChange}
                                                    />
                                                </TooltipWrapper>
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2">
                                                <TooltipWrapper
                                                    tooltipText="Number of lessons PRIOR to the end of the PDF to place the Flag Warning Page"
                                                >
                                                    <h5 className="text-mpLBlue">Flag Page Lesson Offset</h5>
                                                </TooltipWrapper>
                                                <div className="max-w-[15rem]">
                                                    <FormikControl
                                                        id="customDownload-flagPageLessonsOffset"
                                                        name="flagPageLessonsOffset"
                                                        value={formik.values.flagPageLessonsOffset}
                                                        onChange={formik.handleChange}
                                                        disabled={!formik.values.setFlagPage}
                                                        placeholder="Min: 1, Max: 10"
                                                    />
                                                </div>
                                                {formik.values.setFlagPage && !validateFlagPageLessonsOffset(
                                                    formik.values.flagPageLessonsOffset,
                                                    formik.values.selectedItemIds.length
                                                ) ? (
                                                    <ErrorMessage color="mpLRed">
                                                        Flag Page Lesson Offset must be an integer between 1 and 9.
                                                        It must also be less than the number of lessons selected.
                                                    </ErrorMessage>
                                                ) : null}
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2">
                                                <TooltipWrapper
                                                    tooltipText="Downloads lessons as a zip file with multiple PDFs, each containing 4 pages"
                                                >
                                                    <Check
                                                        id="customDownload-splitInFours"
                                                        name="splitInFours"
                                                        color="mpLRed"
                                                        label={<div className="text-mpLBlue">Split In Fours</div>}
                                                        checked={formik.values.splitInFours}
                                                        onChange={formik.handleChange}
                                                    />
                                                </TooltipWrapper>
                                            </div>
                                        </div>

                                        <br/>
                                        <hr/>
                                        <br/>
        {/* LOWER SECTION */}
                                        <div className="flex flex-row gap-x-4">
                                            <div className="w-1/2">
                                                <h4>Available Lessons ({learningPlanItems.length - formik.values.selectedItemIds.length})</h4>
                                                <div className="max-h-[60vh] overflow-y-auto">
                                                    {learningPlanItems.map(lpi => {
                                                        const itemId = lpi.id;
                                                        if(formik.values.selectedItemIds.includes(itemId)) return null;
                                                        
                                                        return (
                                                            <div
                                                                key={`customDownload-lp-item-${itemId}`}
                                                                className="text-mpLBlue cursor-pointer table"
                                                                onClick={() => {
                                                                    if(formik.values.selectedItemIds.length >= 10) return;
                                                                    const newSelectedItemIds = [...formik.values.selectedItemIds];
                                                                    newSelectedItemIds.push(itemId);

                                                                    formik.setFieldValue('selectedItemIds', newSelectedItemIds);
                                                                }}
                                                            >
                                                                {renderLPItem(lpi)}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="w-1/2">
                                                <h4>Lessons to Print ({formik.values.selectedItemIds.length})</h4>
                                                {!formik.values.selectedItemIds.length ? 
                                                    <div className="text-mpOrange">Click items on the left to stage them for printing.</div>
                                                    : null
                                                }
                                                <div className="max-h-[60vh] overflow-y-auto">
                                                    {formik.values.selectedItemIds.map(itemId => {
                                                        const lpi = learningPlanItems.find(lpi => parseInt(lpi.id) === itemId) || {};
                                                        if(!formik.values.selectedItemIds.includes(itemId)) return null;
                                                        
                                                        return (
                                                            <div
                                                                key={`customDownload-lp-item-${itemId}`}
                                                                className="text-mpLBlue cursor-pointer table"
                                                                onClick={() => {
                                                                    const newSelectedItemIds = [...formik.values.selectedItemIds];
                                                                    newSelectedItemIds.splice(newSelectedItemIds.indexOf(itemId), 1);

                                                                    formik.setFieldValue('selectedItemIds', newSelectedItemIds);
                                                                }}
                                                            >
                                                                {renderLPItem(lpi)}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                <br/>

                                                <div className="flex flex-row gap-x-4 w-full">
                                                    <div className="ml-auto">
                                                        <Button
                                                            color="lte-mpLBlue"
                                                            onClick={() => formik.setFieldValue('selectedItemIds', [])}
                                                            disabled={!formik.values.selectedItemIds.length}
                                                        >
                                                            Clear Selections
                                                        </Button>
                                                    </div>
                                                </div>

                                                {formik.values.selectedItemIds.length >= 10 ? (
                                                    <>
                                                        <br/>
                                                        <div className="text-mpLRed">
                                                            Maximum of 10 items can be printed at once.
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                    </> : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-row gap-x-2">
                                    {!formik.values.selectedItemIds.length ? 
                                        <div className="text-mpLRed">
                                            At least one item must be selected.
                                        </div> : null
                                    }
                                    {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                            {formik.status}
                                        </div> : null
                                    }
                                    {formik.isSubmitting ?
                                        <ModalProcessing/> : null
                                    }
                                    <Button
                                        color="lte-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="lte-mpEGreen"
                                        onClick={formik.handleSubmit}
                                        disabled={formik.isSubmitting || !formik.values.selectedItemIds.length}
                                    >
                                        Download
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </ModalBodyFooter>
                    )}
                </Formik>
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMPLessonsMultiND
})(CustomDownloadModal);