import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { Table } from '../../../../components/table';
import { DatePicker, SelectSingle, Check, checkResponses } from '../../../../components/form';
import { convertApiToDate, formatDateApi } from '../../../../components/functions';

import {
    fetchAppointmentsDateCenter,
} from '../../../../actions';
import { getColumnsNotes } from './table/getColumns';

const defaultDate = formatDateApi(new Date());
const columns = getColumnsNotes();
const defaultSorted = { dataField: 'studentName', order: 'desc' };

function NotesSummary(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);

    const { parentApiError, employees, studentUsers, centerOptions, fetchAppointmentsDateCenter } = props;

    const filterAppointments = useCallback((newAppointments, hideCancelled) => {
        const newFilteredAppointments = newAppointments.filter(a =>{
            return !hideCancelled || !['Cancelled'].includes(a.status);
        });

        if(mounted.current) setFilteredAppointments(newFilteredAppointments);
    }, [mounted, setFilteredAppointments]);
    // Data fetching
    const refreshData = useCallback((selectedDate, selectedCenter, hideCancelled) => {
        (async function refresh(){
            if(loading) return;
            setLoading(true);

            selectedDate = selectedDate || formRef.current.values.selectedDate || formatDateApi(new Date());
            selectedCenter = selectedCenter || formRef.current.values.selectedCenter;
            
            const aaRes = await fetchAppointmentsDateCenter({
                date: convertApiToDate(selectedDate),
                center: selectedCenter.value
            });
            const isApiError = checkResponses(aaRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointments = aaRes.data?.appointments || [];
            const newAssignments = aaRes.data?.assignments || [];

            const centerToNameMap = {};
            centerOptions.forEach(c => centerToNameMap[parseInt(c.value)] = c.label);
            const employeeToNameMap = {};
            employees.forEach(e => employeeToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            const studentToNameMap = {};
            studentUsers.forEach(s => studentToNameMap[s.id] = `${s.first_name} ${s.last_name}`);
            const appointmentToAssignmentsMap = {};
            newAssignments.forEach(a => {
                a.instructorName = employeeToNameMap[a.instructor] || `Unknown instructor (UID: ${a.instructor})`;
                if(!appointmentToAssignmentsMap[parseInt(a.appointment_id)]) appointmentToAssignmentsMap[parseInt(a.appointment_id)] = [a];
                else appointmentToAssignmentsMap[parseInt(a.appointment_id)].push(a);
            })

            newAppointments.forEach(a => {
                a.centerName = centerToNameMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
                a.studentName = studentToNameMap[a.student] || `Unknown student (UID: ${a.student})`;

                const assignmentList = appointmentToAssignmentsMap[parseInt(a.id)] || [];
                const instructorList = assignmentList.map(a => a.instructorName);
                a.instructorNames = instructorList.join(', ');
            });
    
            // Certain actions will be restricted if the report does not yet exist
            if(mounted.current){
                setAppointments(newAppointments);
                filterAppointments(newAppointments, hideCancelled);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, centerOptions, employees, studentUsers,
        filterAppointments, fetchAppointmentsDateCenter]);
    useEffect(() => {
        refreshData(defaultDate, { value: 1 }, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedDate: defaultDate,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...'},
                        hideCancelled: true,
                    }}
                    onSubmit={() => null}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <DatePicker
                                        id="daily-report-datepicker-1"
                                        name="selectedDate"
                                        label="Select a date"
                                        value={formik.values.selectedDate}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <SelectSingle
                                        id="report-form-1"
                                        name="selectedCenter"
                                        label="Select Center"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-center">
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => refreshData(formik.values.selectedDate, formik.values.selectedCenter)}
                                    disabled={loading}
                                >
                                    Search
                                </Button>
                                <div>
                                    <Check
                                        id="assigned-appointments-hideCancelled"
                                        name="hideCancelled"
                                        label="Hide Cancelled"
                                        color="mpLRed"
                                        checked={formik.values.hideCancelled}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterAppointments(appointments, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
            
            <br/>
            {parentApiError || apiError ? <div className="text-mpLRed">{parentApiError || apiError}</div> :
                <div className="max-h-[50rem] overflow-y-auto">
                    <Table
                        tableName="notesSummary"
                        columns={columns}
                        data={filteredAppointments}
                        defaultSorted={defaultSorted}
                        sizePerPageInitial={50}
                        sizePerPageList={[10, 25, 50]}
                        emptyMessage="No notes were found"
                    />
                </div>
            }
        </div>
    );
}

export default connect(null, {
    fetchAppointmentsDateCenter
})(NotesSummary);